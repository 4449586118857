import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, TextField } from '@mui/material';

const WeightInputTest4 = () => {
//   const [port, setPort] = useState(null);
  const [reader, setReader] = useState(null);
  const [serialData, setSerialData] = useState('');
  const [weight, setWeight] = useState('');

  const connectToSerialPort = async () => {
    try {
      const port = await navigator.serial.requestPort();
      await port.open({ baudRate: 9600 });

      const textDecoder = new TextDecoderStream('utf-8'); // Specify encoding here
      port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();

      // setPort(port);
      setReader(reader);

      document.getElementById('connectButton').classList.remove('btn-secondary');
      document.getElementById('connectButton').classList.add('btn-success');
      document.getElementById('connectButton').innerHTML = '<i class=\'fas fa-check\'></i> Connected';

      // document.querySelector('.card-body').style.display = 'block';
      document.getElementById('connectionMessage').style.display = 'none';
    } catch (error) {
      console.error('There was an error:', error);
    }
  };

  const readFromSerialPort = async () => {
    try {
      const { value, done } = await reader.read();
      if (done) {
        console.log('Stream closed');
        return;
      }
      const fullValue = value;
      setSerialData((prev) => prev + value);

      const specialWord = 'Gross'; // Replace with your special word
      const lines = fullValue.split('\n');

      for (const line of lines) {
        console.log(`Processing line: ${line}`);
        if (line.includes(specialWord)) {
          const weight = line.substring(0, 10);
          console.log(`Weight found: ${weight}`);
          setWeight(parseInt(weight));
          break;
        }
      }
    } catch (error) {
      console.error('There was an error:', error);
    }
  };

  useEffect(() => {
    if (reader) {
      const interval = setInterval(readFromSerialPort, 1000);
      return () => clearInterval(interval);
    }
  }, [reader]);

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ 
        p: 2, 
        mb: 2 
      }}>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Serial Port Connection
        </Typography>
        <Button
          id='connectButton'
          variant='contained'
          onClick={connectToSerialPort}
          className='btn-secondary'
        >
          Connect
        </Button>
        <Typography id='connectionMessage' sx={{ mt: 2 }}>
          Not Connected
        </Typography>
      </Paper>

      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Serial Monitor
        </Typography>
        <TextField
          id='serialMonitor'
          multiline
          rows={10}
          variant='outlined'
          fullWidth
          value={serialData}
          InputProps={{ readOnly: true, }}
        />
      </Paper>

      <Paper elevation={3} sx={{ 
        p: 2, 
        mt: 2 
      }}>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Scale Weight
        </Typography>
        <TextField
          id='scaleWeight'
          variant='outlined'
          fullWidth
          value={weight}
          InputProps={{ readOnly: true, }}
        />
      </Paper>
    </Box>
  );
};

export default WeightInputTest4;