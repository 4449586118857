
import React, { Component } from 'react';
import '@fontsource/nunito';
// import Header from '../components/header';
// import AdminContainer from '../components/pages/admin/index';
// import { useTheme } from '@mui/material/styles';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LoginContainer from '../components/pages/login/LoginContainer';
import Routes from './Routes';
import { appName } from 'custom/constants';
import ChangePasswordContainer from 'components/pages/login/changepassword/ChangePasswordContainer';
import ResetPasswordContainer from 'components/pages/login/resetpassword/ResetPasswordContainer';
import DocUploadContainer from 'components/pages/docUpload/DocUploadContainer';
import WeightInputTest1 from 'components/pages/weightInputTest/WeightInputTest1';
import WeightInputTest2 from 'components/pages/weightInputTest/WeightInputTest2';
import WeightInputTest3 from 'components/pages/weightInputTest/WeightInputTest3';
import WeightInputTest4 from 'components/pages/weightInputTest/WeightInputTest4';
class App extends Component {
  componentDidMount() {
    const currentPath = window.location.pathname;
    if(currentPath.includes('/login')) {
      document.title = 'Login' + ' | ' + appName;
    }
  }
  
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path='/login' component={LoginContainer} />
          <Route path='/change-password/:id?/:token?' component={ChangePasswordContainer} />
          <Route path='/reset-password' component={ResetPasswordContainer} />
          <Route path='/doc-request/:token' component={DocUploadContainer} />
          <Route path='/weight-input-tester' component={WeightInputTest2} />
          <Route path='/weight-input-tester2' component={WeightInputTest1} />
          <Route path='/weight-input-tester3' component={WeightInputTest3} />
          <Route path='/weight-input-tester4' component={WeightInputTest4} />
          <Route path='/*' component={Routes} />
        </Switch>
      </BrowserRouter>
    );
  }

  // render() {
  //   return (
  //     <>
  //       <BrowserRouter>
  //         <Routes>
  //           <Route path='/' component={AdminContainer} />
  //           <Route exact path='/login' component={LoginContainer} />
  //         </Routes>
  //       </BrowserRouter>
  //     </>
  //   );
  // }
}


export default App;