import React from 'react';
import { Text, View, StyleSheet, Image, Svg, Polygon } from '@react-pdf/renderer';
import ghaLogo from '../../../../../../assets/images/tristarLogo.png';
import lhLogo from '../../../../../../assets/images/lufthansaCargo.png';
import cookies from 'utils/cookies';
import moment from 'moment';

const textColor = '#393939';
const primaryBlue = '#092b4a';
const labelColor = 'grey';

const cellProperties = {
  fontSize: 10,
  color: textColor,
  whiteSpace: 'break-spaces',
  fontFamily: 'NunitoRegular',
};


const companyName = (company) => {
  switch (company?.toLowerCase()) {
    case 'tristar ground handling services':
      return <Image style={styles.logo} src={ghaLogo} />;
    case 'lufthansa cargo ag':
      return <Image style={styles.logo2} src={lhLogo} />;
    default:
      return '';
  }
};

const styles = StyleSheet.create({
  w50: {
    ...cellProperties,
    width: '50%',
  },
  w33: {
    ...cellProperties,
    width: '33.33%',
  },
  w40: {
    ...cellProperties,
    width: '40%',
  },
  w25: {
    ...cellProperties,
    width: '25%',
  },
  w60: {
    ...cellProperties,
    width: '60%',
  },
  w75: {
    ...cellProperties,
    width: '75%',
  },
  w100: {
    ...cellProperties,
    width: '100%',
  },
  rowContainer: { flexDirection: 'row', },
  justifySpBtw: { justifyContent: 'space-between', },
  tagContent: {
    borderRight: '1px solid grey',
    borderBottom: '1px solid grey',
  },

  rowContainerMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px',
    borderRight: '1px solid grey',
    borderLeft: '1px solid grey',
    borderTop: '1px solid grey',
  },
  logoContainer: {
    width: '50%',
    textAlign: 'left',
  },
  logoImg: {
    width: '100%',
    height: 'auto',
  },
  tagContainer: { padding: 8, },
  titleContainer: { width: '50%', },
  mainTitle: {
    fontSize: 16,
    color: primaryBlue,
    textTransform: 'uppercase',
    fontFamily: 'NunitoBold',
    textAlign: 'right',
    lineHeight: 'normal',
    width: '100%',
  },
  borderLeft: {
    width: 8,
    position: 'absolute',
    bottom: 0,
    left: 0,
    top: 0,
  },
  borderRight: {
    width: 8,
    position: 'absolute',
    bottom: 0,
    right: 0,
    top: 0,
  },
  borderTop: {
    width: '100%',
    height: 8,
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 8,
    right: 0,
  },
  borderBottom: {
    width: '100%',
    height: 8,
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    left: 8,
    right: 0,
  },
  svgContainer: {
    width: 8,
    borderRight: '1px solid #D02139',
    borderLeft: '1px solid #D02139',
  },
  svgContainerHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #D02139',
    borderBottom: '1px solid #D02139',
    paddingTop: 0.5,
    paddingBottom: 1,
  },
  smallTextItalic: { fontFamily: 'NunitoItalic' },
  tagDetails: {
    fontSize: 8,
    lineHeight: 'normal',
  },
  block: {
    padding: 2,
    paddingHorizontal: 4,
    borderTop: '1px solid grey',
    borderLeft: '1px solid grey',
    minHeight: 50,
  },
  blockTitle: {
    fontSize: 8,
    color: labelColor,
    lineHeight: 'normal',
  },
  blockData: {
    fontSize: 21,
    color: 'black',
    textAlign: 'left', 
    lineHeight: 'normal',
    fontFamily: 'NunitoBold',
  },
  blockWtData: {
    fontSize: 12,
    color: 'black',
    lineHeight: 'normal',
    fontFamily: 'NunitoBold',
  },
  remarkContainer: { height: '100px', },
  remarkData: {
    textAlign: 'left',
    fontSize: 12,
  },
  wgtContainer: { flex: 'auto' }
});

const UldTagDetails = (props) => {

  return (
    <View style={styles.tagContainerWrapper}>
      <View style={styles.tagContainer}>
        {props.uld?.dangerous === true && (
          <>
            <View style={styles.borderTop}>
              {[...Array(8)].map((_, i) => (
                <View key={i} style={styles.svgContainerHorizontal}>
                  <Svg height='8' width='12' viewBox='0 0 12 8'>
                    <Polygon points='0,0 5,6 10,6 5,0' fill='#D02139' />
                  </Svg>
                  <Svg height='8' width='32' viewBox='0 0 32 8'>
                    <Polygon points='0,0 5,6 30,6 25,0' fill='#D02139' />
                  </Svg>
                </View>
              ))}
            </View>
            <View style={styles.borderLeft}>
              {[...Array(10)].map((_, i) => (
                <View key={i} style={styles.svgContainer}>
                  <Svg viewBox='0 0 8 20'>
                    <Polygon points='0,0 8,10 8,20 0,10' fill='#D02139' />
                  </Svg>
                  <Svg viewBox='0 0 8 50'>
                    <Polygon points='0,0 8,10 8,50 0,40' fill='#D02139' />
                  </Svg>
                </View>
              ))}
            </View>
            <View style={styles.borderRight}>
              {[...Array(10)].map((_, i) => (
                <View key={i} style={styles.svgContainer}>
                  <Svg viewBox='0 0 8 20'>
                    <Polygon points='0,0 8,10 8,20 0,10' fill='#D02139' />
                  </Svg>
                  <Svg viewBox='0 0 8 50'>
                    <Polygon points='0,0 8,10 8,50 0,40' fill='#D02139' />
                  </Svg>
                </View>
              ))}
            </View>
            <View style={styles.borderBottom}>
              {[...Array(8)].map((_, i) => (
                <View key={i} style={styles.svgContainerHorizontal}>
                  <Svg height='8' width='12' viewBox='0 0 12 8'>
                    <Polygon points='0,0 5,6 10,6 5,0' fill='#D02139' />
                  </Svg>
                  <Svg height='8' width='32' viewBox='0 0 32 8'>
                    <Polygon points='0,0 5,6 30,6 25,0' fill='#D02139' />
                  </Svg>
                </View>
              ))}
            </View>
          </>
        )}
        <View style={styles.rowContainerMain}>
          <View style={styles.logoContainer}>
            <View style={styles.logoImg}>
              {companyName(cookies.get('company_name'))}
            </View>
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.mainTitle}>ULD Tag</Text>
            <View style={[styles.rowContainer, styles.justifySpBtw]}>
              <Text style={styles.tagDetails}>{moment().utc().format('DDMMMYY HHmm').toUpperCase()} UTC</Text>
              <Text style={styles.tagDetails}>{cookies.get('name')}</Text>  
            </View>
          </View>
        </View>
        <View style={styles.tagContent}>
          <View style={styles.rowContainer}>
            <View style={styles.w60}>
              <View style={styles.block}>
                <Text style={styles.blockTitle}>ULD ID</Text>
                <Text style={styles.blockData}>{props.uld?.uld_no}</Text>
              </View>
              <View style={styles.block}>
                <Text style={styles.blockTitle}>Net Weight</Text>
                <Text style={styles.blockData}>{(props.uld?.wgt) || ((props.uld?.scale_wgt || 0) - (props.uld?.dolly_tare || 0))} KG</Text>
              </View>
            </View>
            <View style={[styles.w40, styles.block]}>
            </View>
          </View>
          <View style={styles.rowContainer}>
            <View style={[styles.w50, styles.block]}>
              <Text style={styles.blockTitle}>Destination</Text>
              <Text style={styles.blockData}>{props.uld?.dest}</Text>
            </View>
            <View style={[styles.w50, styles.block]}>
              <Text style={styles.blockTitle}>Contour / Height</Text>
              <Text style={styles.blockData}>{props.uld?.contour}</Text>
            </View>
          </View>
          <View style={styles.rowContainer}>
            <View style={[styles.w25, styles.block]}>
              <Text style={styles.blockTitle}>Loaded At</Text>
              <Text style={[styles.blockData, { fontSize: 21 }]}>{cookies.get('station_code')?.toUpperCase()}</Text>
            </View>
            <View style={[styles.w75, styles.block]}>
              <Text style={styles.blockTitle}>Flight Number</Text>
              <Text style={[styles.blockData, { fontSize: 21 }]}>{props.uld?.flight_no}</Text>
            </View>
          </View>
          <View style={styles.rowContainer}>
            <View style={[styles.w25, styles.block]}>
              <Text style={styles.blockTitle}>Transfer At</Text>
              <Text style={[styles.blockData, { fontSize: 21 }]}>{props.uld?.transfer_at}</Text>
            </View>
            <View style={[styles.w75, styles.block]}>
              <Text style={styles.blockTitle}>Flight Number</Text>
              <Text style={[styles.blockData, { fontSize: 21 }]}>{props.uld?.onward_flight}</Text>
            </View>
          </View>
          <View style={[styles.block, styles.remarkContainer]}>
            <Text style={styles.blockTitle}>Remarks</Text>
            <Text style={[styles.blockData, styles.remarkData]}>{props.uld?.remark}{console.log(props.uld)}</Text>
          </View>
          <View style={[styles.block, styles.rowContainer, { minHeight: 'auto' }]}>
            <Text style={[styles.blockTitle, { marginRight: 4 }]}>Weight Details</Text>
            <View style={[styles.rowContainer, styles.justifySpBtw, styles.wgtContainer]}>
              <Text style={styles.blockWtData}>G: {props.uld?.scale_wgt || 0}</Text>
              <Text style={styles.blockWtData}>({props.uld?.dolly_prefix}) T: {props.uld?.dolly_tare || 0}</Text>
              <Text style={styles.blockWtData}>N: {(props.uld?.wgt) || ((props.uld?.scale_wgt || 0) - (props.uld?.dolly_tare || 0))}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
export default UldTagDetails;
