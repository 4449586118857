import React from 'react';
import DollyTare from './DollyTare';
import AddDollyTareModal from './AddDollyTareModal';
import http from 'utils/http';
import events from 'utils/evemts';
import { connect } from 'react-redux';
import { setDeleteAction } from 'store/actions/deleteAction';

class DollyTareContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      action: '',
      dollys: [],
      dollyFields: {},
      dollErrors: {}
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchDollyTare();
    this.eventSource = events('admin', 'dolly');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      let dollys = [...this.state.dollys];
      if (msg.data) {
        if (msg.action == 'ADD') {
          this.setState({ dollys: [...dollys, msg.data] });
        }
        else if (msg.action == 'UPDATE') {
          const index = this.state.dollys.findIndex((dolly) => dolly.id === msg.data.id);
          if (index !== -1) {
            dollys[index] = { ...msg.data };
            this.setState({ dollys: dollys });
          }
        }
        else if (msg.action == 'DELETE') {
          this.setState({ dollys: dollys.filter((dolly) => dolly.id !== msg.data.id) });
        }
      }
    };
  }

  fetchDollyTare = () => {
    http.get('dolly').then(({ data }) => {
      this.setState({ dollys: data?.data });
    });
  };

  handleOpen = (modal, action, data = {}) => {
    this.setState({
      modal,
      action,
      dollyFields: data
    });
  };

  handleClose = () => {
    this.setState({
      modal: false,
      action: ''
    });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      dollyFields: {
        ...this.state.dollyFields,
        [name]: value
      }
    });
  };

  onSubmit = () => {
    if (this.state.dollyFields.id) {
      http.put(`dolly/${this.state.dollyFields.id}`, this.state.dollyFields).then(() => {
        this.handleClose();
      });
    } else {
      http.post('dolly', this.state.dollyFields).then(() => {
        this.handleClose();
      });
    }
  };

  onDelete = (id) => {
    http.delete(`dolly/${id}`).then(() => {
      this.props.setDelete();
    });
  };

  handleDelete = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.onDelete(id),
    });
  };


  render() {
    return (
      <>
        <DollyTare
          dollys={this.state.dollys}
          handleOpen={this.handleOpen}
          handleDelete={this.handleDelete}
        />

        {this.state.modal === 'dolly_tare' &&
          <AddDollyTareModal
            modal={this.state.modal}
            action={this.state.action}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            onClose={this.handleClose}
            dollyFields={this.state.dollyFields}
            dollErrors={this.state.dollErrors}
          />
        }
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(null, mapDispatchToProps)(DollyTareContainer);
