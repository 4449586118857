import React from 'react';
import { Dialog, FormControl, FormHelperText, Grid, InputAdornment } from '@mui/material';
import styles from '../../../../../../../custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomDialogContent, CustomDatePicker } from '../../../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import dayjs from 'dayjs';

const AddDollyTareModal = (props) => {
  const { action } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'dolly_tare'} onClose={props.onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Dolly Tare
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onReset={props.onClose}>
          <CustomDialogContent>
            <StyledCard>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <CustomInputLabel htmlFor='name'>Prefix</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='name'
                    name='prefix'
                    placeholder='Prefix'
                    type='text'
                    onChange={props.onChange}
                    value={props.dollyFields?.prefix || ''}
                    error={props.dollErrors?.prefix}
                    helperText={props.dollErrors?.prefix}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <CustomInputLabel htmlFor='length'>Number</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='length'
                    name='number'
                    type='text'
                    placeholder='Number'
                    onChange={props.onChange}
                    value={props.dollyFields?.number || ''}
                    error={props.dollErrors?.number}
                    helperText={props.dollErrors?.number}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='wgt'>Weight</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='wgt'
                    name='wgt'
                    type='text'
                    placeholder='Weight'
                    onChange={props.onChange}
                    value={props.dollyFields?.wgt || ''}
                    error={props.dollErrors?.wgt}
                    helperText={props.dollErrors?.wgt}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='email'>Email</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='email'
                    name='mail'
                    type='text'
                    placeholder='Email'
                    onChange={props.onChange}
                    value={props.dollyFields?.mail || ''}
                    error={props.dollErrors?.mail}
                    helperText={props.dollErrors?.mail}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <CustomInputLabel htmlFor='expiry'>Expiry</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.dollErrors.expiry} >
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          ...InputProps,
                          placeholder: 'Expiry',
                          fullWidth: true
                        },
                      }}
                      clearable
                      format='DD MMM YYYY'
                      value={props.dollyFields.expiry ? dayjs(props.dollyFields.expiry) : null}
                      error={props.dollErrors.expiry}
                      helperText={props.dollErrors.expiry}
                      onChange={(e) => props.onChange(
                        {
                          target: {
                            name: 'expiry',
                            value: dayjs(e.$d).format('YYYY-MM-DD')
                          }
                        }
                      )}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end' >
                            <QueryBuilderIcon color='primary' />
                          </InputAdornment>
                        )
                      }}
                    />
                    <FormHelperText>{props.dollErrors.expiry}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </StyledCard>

          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset'> Close </CustomButton>
            <CustomButton variant='contained' onClick={props.onSubmit}>{action == 'add' ? 'Submit' : 'Update'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddDollyTareModal;