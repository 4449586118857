import React from 'react';
import Header from './Header';
import SideBar from './Sidebar';
import { sidebarwidth } from './headerconstants';
import cookies from '../../utils/cookies';
import http from 'utils/http';
import { timezones } from 'custom/constants';
import { getLocatDate } from 'utils/converter';
import moment from 'moment';

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      pageTitle: 'Admin',
      contractExpiry: false,
      contractExpiryIcon: false,
      contract: [],
      import: false,
    };
    this.stations = cookies.get('stations') || [];
    this.station = cookies.get('station') || null;
  }

  componentDidMount = () => {
    http.get('/settings?key=import').then(({ data }) => {
      if (data.data) {
        let Import = data.data?.find((item) => item.key === 'import');
        if (Import) {
          if (Import?.value && Import.value?.data === 'true') {
            this.setState({ import: true });
          }
        }
      }
    });
  };

  componentDidUpdate() {
  }

  handleStation = (e) => {
    cookies.set('station', e.target.value, { path: '/' });
    let currstation = this.stations.find((stn) => parseInt(stn.id) == parseInt(e.target.value))?.code;
    let currency = this.stations.find((stn) => parseInt(stn.id) == parseInt(e.target.value))?.currency;
    cookies.set('station_code', currstation, { path: '/' });
    cookies.set('currency', currency, { path: '/' });
    // Get current page URL
    let currentURL = window.location.href;
    // If URL contains '/flight_folder', redirect to '/my_flights'
    if (currentURL.includes('/flight_folder')) {
      window.location.href = '/my_flights';
    } else {
      window.location.reload();
    }

    // window.location.reload();
    cookies.set('timezone', timezones[currstation], { path: '/' });
  };

  handleDrawerOpen = () => {
    // this.setState((prevState) => ({ open: !prevState.open }));
    this.setState((prevState) => ({ open: !prevState.open }), this.onDrawerOpen);
  };

  onDrawerOpen = () => {
    // if ((document.body.scrollHeight > document.documentElement.clientHeight)) {
    //   if (this.state.open === true) {
    //     document.body.style.overflow = 'hidden';
    //     document.body.style.paddingRight = '24px';
    //   }
    //   else if (this.state.open === false) {
    //     document.body.style.overflow = 'unset';
    //     document.body.style.paddingRight = '9px';
    //   }
    //   else document.body.style.paddingRight = '24px';
    // } 
    if (this.state.open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  };

  signout = () => {
    http.get('/user/logout').then(() => {
      cookies.remove('login', { path: '/' });
      cookies.remove('company_name', { path: '/' });
      cookies.remove('company_mail', { path: '/' });
      cookies.remove('timezone', { path: '/' });
      cookies.remove('stations', { path: '/' });
      cookies.remove('role', { path: '/' });
      cookies.remove('station', { path: '/' });
      cookies.remove('company', { path: '/' });
      cookies.remove('station_code', { path: '/' });
      cookies.remove('user', { path: '/' });
      cookies.remove('name', { path: '/' });
      cookies.remove('my_flights', { path: '/' });
      cookies.remove('ismanagec', { path: '/' });
      cookies.remove('managecdt', { path: '/' });
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
    });
  };

  fetchContract = () => {
    http.get(`/contract?type=active&date=${getLocatDate('YYYY-MM-DD')}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          data.data.map((contract, index) => {
            let contract_height = 0;
            if (contract.contractrows.length > 0) {
              contract_height = contract_height + (contract.contractrows.length);
              contract.contractrows.map((controw) => {
                if (controw?.contrates?.length > 0) {
                  contract_height = contract_height + (controw.contrates.length - 1);
                }
              });
            }
            data.data[index].contract_height = contract_height;
          });
          this.setState({ contract: data.data }, () => {
            {
              this.state.contract?.map((item) => {
                const today = moment();
                const targetDate = moment(item.end_date);
                const daysDifference = targetDate.diff(today, 'days');
                if (daysDifference > 0 && daysDifference < 15) {
                  this.setState({ contractExpiryIcon: true });
                }
              });
            }
          });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  handleContractExpiry = () => {
    this.setState({ contractExpiry: true });
  };

  onClose = () => {
    this.setState({ contractExpiry: false });
  };

  render() {
    return (
      <>
        <Header
          station={this.station}
          stations={this.stations}
          handleDrawerOpen={this.handleDrawerOpen}
          open={this.state.open}
          handleStation={this.handleStation}
          pageTitle={this.state.pageTitle}
        />
        <SideBar
          handleDrawerOpen={this.handleDrawerOpen}
          open={this.state.open}
          sidebarwidth={sidebarwidth}
          theme={this.props.theme}
          signout={this.signout}
          import={this.state.import}
          handleContractExpiry={this.handleContractExpiry}
          onClose={this.onClose}
          contractExpiry={this.state.contractExpiry}
          contract={this.state.contract}
          contractExpiryIcon={this.state.contractExpiryIcon}
        />
      </>
    );
  }
}

export default HeaderContainer;