import React from 'react';
import { Badge, Button, FormControl, Grid, IconButton, InputAdornment, MenuItem, Popover, SnackbarContent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { CustomButton, CustomInputLabel, CustomSelect, CustomTextField, InputProps, SelectInputProps, StyledCard, StyledCardTitle } from '../../../../../custom/components';
import styles from 'custom/style.module.css';
import manifestStyles from './manifest.module.css';
import theme from 'custom/theme';
import { DateTimeSecFormat, DeleteIcon, EcoIcon, PageIcon } from 'custom/constants';
import { Delete, PlaylistAdd, Refresh, Save, Warning } from '@mui/icons-material';
import HistoryIcon from '@mui/icons-material/History';
import PDFSliderManifest from './PDFSliderManifest';
import cookies from 'utils/cookies';
import ManifestSummaryContainer from '../../summary/ManifestSummaryContainer';
import DuplicateAwbModal from './DuplicateAwbModal';
import Download from './downloadawb/Download';
import { dateTolocale } from 'utils/converter';
import MessageModal from './MessageModal';

const getuldweight = (awbs) => {
  let weight = 0;
  let unit = awbs[0]?.weight_unit || 'K';
  awbs.forEach(awb => {
    if (awb.wgt) {
      weight = (parseFloat(weight) + parseFloat(awb.wgt)).toFixed(1);
    }
  });
  return weight + unit;
};
const disablefields = cookies.get('role') === 'Airline Rep' ? true : false;
const Manifest = (props) => {

  return (
    <>
      {/* <CustomCheckbox
        control={
          <Checkbox inputProps={{ 'aria-label': 'manifest', }} checked={props.nil} onChange={props.onChangeNil} name='view_manifest'
            sx={{
              paddingTop: '6px',
              paddingBottom: '6px',
              color: '#fff',
              '&.Mui-checked': { color: '#fff', },
            }}
            size='small'
          />
        }
        className={`${manifestStyles.whiteCheckbox} ${manifestStyles.nilCheckbox}`}
        classes={{ label: manifestStyles.whiteCheckboxLabel }}
        label={'NIL'}
      /> */}
      <Grid container spacing={3} className={manifestStyles.hidePrint}>
        <Grid item xs={12} className={styles.pt0}>
          <ManifestSummaryContainer nil={props.nil_manifest} handleFilter={props.handleFilter} showReportModal={props.showReportModal} />
        </Grid>
        {
          props.is_empty && (
            <Grid item xs={12} className={styles.pt0}>
              <SnackbarContent
                classes={{
                  root: styles.errorSnackbar,
                  message: styles.errorSnackbarMessage,
                }}
                message={(
                  <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Typography variant='body2' component='span' color='error' textAlign={'center'} display={'flex'} alignItems={'center'} textTransform={'capitalize'} fontWeight={'bold'}>
                      <Warning sx={{ fontSize: 20 }} color='error' /> &nbsp; Required fields are missing information.</Typography>
                    <Typography variant='caption' component='span' color='#000000b5'>
                      Please review the manifest details to update the fields.
                    </Typography>
                  </Stack>
                )}
              />
            </Grid>
          )
        }

        <Grid item xs={12}>
          <StyledCard className={`${props.nil_manifest ? styles.cardOverlay : ''}`}>
            <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
              <div>
                <StyledCardTitle gutterBottom variant='div'>Manifest Details</StyledCardTitle>
                {props.message_logs && props.message_logs?.length > 0 && (<>
                  <Tooltip title='View History'>
                    <IconButton onClick={props.handleHistory} className={styles.ml3} sx={{ marginTop: '-2px' }}><HistoryIcon color='primary' fontSize='small' /></IconButton>
                  </Tooltip>
                  <Popover
                    open={Boolean(props.anchorEl)}
                    anchorEl={props.anchorEl}
                    onClose={props.handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <div className={styles.p1}>
                      {props.message_logs.map((log, index) =>
                        <Typography key={index} sx={{ p: 1 }} fontSize={'0.78rem'} borderBottom={'1px solid #ececec'} color={props.msg_id == log.id ? 'var(--redText)' : '#000'} onClick={() => props.handleOpen('msg', log)}
                          className={styles.pointer}>• &nbsp; Manifest Received {dateTolocale(log.created, DateTimeSecFormat)} </Typography>)}
                    </div>
                  </Popover>
                </>)}
                <Tooltip title='Refresh'><IconButton onClick={props.onRefresh} sx={{ marginTop: '-2px' }} disabled={!props.doc} color='primary'><Refresh fontSize='small' /></IconButton></Tooltip>
              </div>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                {props.doc && props.doc?.remark && (<Typography variant='caption' component='span' color='var(--redText)' htmlFor='' className={styles.labelStats}>{props.doc?.remark}</Typography>)}
                <CustomButton 
                  variant='contained' 
                  color='primary' 
                  onClick={() => props.handleUld(true)} 
                  disabled={props.add_uld || !props.doc} 
                  className={manifestStyles.addUldBtn}>
                  <PlaylistAdd
                    sx={{
                      fontSize: '1.4rem',
                      color: props.add_uld ? '#bdbdbd' : '#dfe3e8',
                      marginRight: '4px',
                    }}
                  /> Add ULD</CustomButton>
                <Badge badgeContent={props.noScanAwbs.length} color='error'>
                  <Download manifestData={props.manifestData} ffm={props.ffm}
                    airlineCode={props.ffm?.airline?.code}
                    filename={'Awbs List'} title='Print no scan list' disabled={!props.doc} />
                </Badge>
              </Stack>
            </div>
            <div className={`${manifestStyles.manifestWrapper} ${styles.overflowAutoWrapper}`}>
              <Grid container spacing={3}>
                {props.add_uld ? (<Grid item container spacing={2}>
                  <Grid item container xs={12} lg={12} alignItems={'center'} spacing={2} className={styles.mt1}>
                    <Grid item xs={12} sm={6} md={3} lg={1.75}>
                      <CustomInputLabel htmlFor='' aria-label='Type' className={styles.ipLabel}>Type</CustomInputLabel>
                      <FormControl fullWidth>
                        <CustomSelect
                          {...SelectInputProps}
                          id=''
                          size='small'
                          type='select'
                          name='c_type'
                          value={props.uldFields?.c_type}
                          className={`${styles.smInput}`}
                          onChange={props.onChangeUld}
                        >
                          <MenuItem value={'ULD'}>ULD</MenuItem>
                          <MenuItem value={'BULK'}>BULK</MenuItem>
                        </CustomSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={1.75}>
                      <CustomInputLabel htmlFor='' aria-label='uld_no' className={styles.ipLabel}>ULD No</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id=''
                        fullWidth
                        placeholder='ULD No'
                        type='text'
                        name='uld_no'
                        onChange={props.onChangeUld}
                        disabled={props.uldFields?.c_type == 'BULK'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={1.75}>
                      <CustomInputLabel id='handled_as' aria-label='Select handled as' className={styles.ipLabel}>Handled as</CustomInputLabel>
                      <FormControl fullWidth>
                        <CustomSelect
                          {...SelectInputProps}
                          id='handled_as'
                          name='type'
                          classes={{ input: manifestStyles.smSelect }}
                          value={props.uldFields?.type}
                          onChange={props.onChangeUld}
                        >
                          <MenuItem value={'Loose'}>Loose</MenuItem>
                          <MenuItem value={'Intact'}>Intact</MenuItem>
                        </CustomSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={1.75}>
                      <CustomInputLabel htmlFor='' aria-label='Enter Select ' className={styles.ipLabel}>ULD Type</CustomInputLabel>
                      <FormControl fullWidth>
                        <CustomSelect
                          {...SelectInputProps}
                          id=''
                          name='uld_type'
                          classes={{ input: manifestStyles.smSelect }}
                          value={props.uldFields?.uld_type}
                          onChange={props.onChangeUld}
                          disabled={props.uldFields?.type == 'Loose'}
                        >
                          <MenuItem value={'LD3'}>LD3</MenuItem>
                          <MenuItem value={'2X LD3'}>2X LD3</MenuItem>
                          <MenuItem value={'LD7'}>LD7</MenuItem>
                          <MenuItem value={'MD'}>MD</MenuItem>
                          <MenuItem value={'2X MD'}>2X MD</MenuItem>
                        </CustomSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={11} lg={4}>
                      <CustomInputLabel htmlFor='' aria-label='Enter Select ' className={styles.ipLabel}>Remarks</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id=''
                        value={props.uldFields?.remark}
                        onChange={props.onChangeUld}
                        fullWidth
                        placeholder='Remarks'
                        type='text'
                        name='remark'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={1} lg={1}>
                      <Stack direction='row' spacing={1} justifyContent='center' alignItems={'flex-end'} className={styles.mt1}>
                        <IconButton onClick={props.onAddUld}>
                          <Save color='primary' fontSize={'4rem'} />
                        </IconButton>
                        <div className={`${manifestStyles.actionIconsLg} ${styles.p2}`}>
                          <DeleteIcon onClick={() => props.handleUld(false)} />
                        </div>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>) : null
                }
                {props.manifestData && props.manifestData?.map((uld/*, uldindex*/) => {
                  return (<>
                    <Grid item container spacing={2}>
                      <Grid item xs={12} lg={7}>
                        <div className={manifestStyles.uldWrapper}>
                          <>
                            <Typography variant='h6' component='h6' color={theme.palette.primary.contrastText} fontWeight={'bold'} fontSize={'14px'} className={`${styles.ml3}`}>{`${uld.uld_no} | ${uld.flight_awbs.length} Awbs | ${getuldweight(uld.flight_awbs)}`}</Typography>
                            {(props.edit &&
                              <div className={`${styles.actionIcons} ${styles.whiteSvg} ${manifestStyles.deleteUld}`}>
                                <DeleteIcon onClick={() => props.deleteUld(uld.id)} />
                              </div>)}
                          </>
                        </div>
                        <div className={manifestStyles.manifestDetails}>
                          <TableContainer>
                            <Table className={manifestStyles.manifestTable}>
                              <TableHead>
                                <TableRow>
                                  <TableCell colSpan={8}></TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {uld.flight_awbs && uld.flight_awbs?.length > 0 && uld.flight_awbs.map((awb/*, awbindex*/) => {
                                  return (<>
                                    <TableRow classes={{ root: manifestStyles.firstRow }} >
                                      <TableCell className={manifestStyles.awbIp} align='left'>{!props.edit ? awb.awb_no : (
                                        <CustomTextField
                                          {...InputProps}
                                          key={awb.id}
                                          fullWidth
                                          placeholder='AWB No'
                                          type='text'
                                          name='awb_no'
                                          defaultValue={awb?.awb_no}
                                          onBlur={(e) => props.onChange(e, awb?.awb_no, awb.id)}
                                          className={`${styles.smInput} ${manifestStyles.orgdest}`}
                                        />
                                      )}</TableCell>
                                      <TableCell className={styles.minWidth60} align='center'>{!props.edit ? `${awb?.org}-${awb?.dest}` : (
                                        <>
                                          <input type='text' key={awb.id + 'ORG'} name='org' defaultValue={awb?.org} onBlur={(e) => props.onChange(e, awb?.org, awb.id)} className={`${styles.smInput} ${manifestStyles.orgdest} ${awb?.org ? '' : manifestStyles.emptyInput}`} />
                                          -
                                          <input type='text' key={awb.id + 'DEST'} name='dest' defaultValue={awb?.dest} onBlur={(e) => props.onChange(e, awb?.dest, awb.id)} className={`${styles.smInput} ${manifestStyles.orgdest} ${awb?.dest ? '' : manifestStyles.emptyInput}`} />
                                        </>
                                      )}</TableCell>
                                      <TableCell className={styles.minWidth40} align='center'>{!props.edit ? awb.pcs : (
                                        <input type='text' key={awb.id + 'PCS'} name='pcs' defaultValue={awb?.pcs} onBlur={(e) => props.onChange(e, awb?.pcs, awb.id)} className={`${styles.smInput} ${manifestStyles.orgdest} ${awb?.pcs ? '' : manifestStyles.emptyInput}`} />
                                      )}</TableCell>
                                      <TableCell className={styles.minWidth125} align='center'>{!props.edit ? (awb.wgt ? parseFloat(awb?.wgt) : '0') : (
                                        <input type='text' key={awb.id + 'WGT'} name='wgt' defaultValue={awb?.wgt} onBlur={(e) => props.onChange(e, awb?.wgt, awb.id)} className={`${styles.smInput} ${manifestStyles.splInput} ${awb?.wgt ? '' : manifestStyles.emptyInput}`} />
                                      )} {(awb?.wgt_unit || 'K')}</TableCell>
                                      <TableCell className={styles.minWidth135}>{!props.edit ? awb?.remark : (
                                        <CustomTextField
                                          {...InputProps}
                                          key={awb.id + 'REMARK'}
                                          fullWidth
                                          placeholder='Remarks'
                                          type='text'
                                          name='awb_remark'
                                          defaultValue={awb?.remark}
                                          onBlur={(e) => props.onChange(e, awb?.remark, awb.id)}
                                        />
                                      )}</TableCell>
                                      <TableCell className={styles.minWidth125}>{!props.edit ? awb?.spl_code : (
                                        <input type='text' key={awb.id + 'SPL'} name='spl_code' defaultValue={awb?.spl_code} onBlur={(e) => props.onChange(e, awb?.spl_code, awb.id)} className={`${manifestStyles.splInput}`} />
                                      )}</TableCell>
                                      <TableCell className={styles.minWidth100}>
                                        <CustomSelect
                                          {...SelectInputProps}
                                          id=''
                                          name='productsId'
                                          className={`${styles.smInput} ${(!awb?.productsId && awb.prod_conflict) ? styles.colorRed : ''}`}
                                          value={!awb?.productsId && awb.prod_conflict ? 0 : (!awb?.productsId && !awb.prod_conflict ? 0 : awb?.productsId)}
                                          onChange={(e) => props.onChange(e, null, awb.id)}
                                          inputProps={{ 'aria-label': 'Without label' }}
                                          disabled={disablefields || props.showReportModal}
                                        >
                                          {props.productsList.map((item) => {
                                            if ((!awb?.productsId && awb.prod_conflict && awb.prod_conflict?.includes(item.id)) || !awb.prod_conflict || awb?.productsId)
                                              return (<MenuItem key={item.id} value={item.id}>
                                                {item.name}
                                              </MenuItem>
                                              );
                                          })}
                                          {awb?.spl_code && !awb?.productsId && awb.prod_conflict && (<MenuItem key={99} value={0}>
                                            Conflict
                                          </MenuItem>)}
                                          {!awb?.productsId && !awb.prod_conflict && (
                                            <MenuItem key={99} value={0}>
                                              GENERAL
                                            </MenuItem>
                                          )}
                                        </CustomSelect>
                                      </TableCell>
                                      <TableCell style={{
                                        minWidth: '30px',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                      }}>
                                        <IconButton className={!awb?.awbId ? styles.disabledImg : ''} disabled={!awb?.awbId} onClick={() => awb?.awbId ? props.handleOpen('view_awb', awb) : (!awb?.awbId && awb?.awb_conflict ? props.handleOpen('awb', awb) : {})}>
                                          {
                                            awb?.spl_code && (awb?.spl_code?.includes('EAP') || awb?.spl_code?.includes('EAW')) ?
                                              <EcoIcon /> :
                                              <PageIcon />
                                          }
                                        </IconButton>
                                        {(props.edit && <div className={`${styles.ml3} ${manifestStyles.delIcon}`}>
                                          <DeleteIcon onClick={() => props.deleteAwb(awb.id)} color='inherit' fontSize='small' />
                                        </div>)}
                                      </TableCell>
                                    </TableRow>
                                  </>);
                                })}
                                {props.add_awb && uld.id == props.add_awb ? (<TableRow classes={{ root: manifestStyles.firstRow }} >
                                  <TableCell className={styles.minWidth100} align='left'>
                                    <CustomTextField
                                      {...InputProps}
                                      id=''
                                      fullWidth
                                      placeholder='AWB No'
                                      type='text'
                                      name='awb_no'
                                      value={props.awbFields?.awb_no}
                                      onChange={props.onChangeAwb}
                                    />
                                  </TableCell>
                                  <TableCell className={styles.minWidth60} align='center'>
                                    <input type='text' name='org' placeholder='org' className={`${styles.smInput} ${manifestStyles.orgdest}`} value={props.awbFields?.org} onChange={props.onChangeAwb} />
                                    -
                                    <input type='text' name='dest' placeholder='dest' className={`${styles.smInput} ${manifestStyles.orgdest}`} value={props.awbFields?.dest} onChange={props.onChangeAwb} />
                                  </TableCell>
                                  <TableCell className={styles.minWidth40} align='center'>
                                    <input type='text' name='pcs' placeholder='pcs' className={`${styles.smInput} ${manifestStyles.orgdest}`} value={props.awbFields?.pcs} onChange={props.onChangeAwb} />
                                  </TableCell>
                                  <TableCell className={styles.minWidth60} align='center'>
                                    <input type='text' name='wgt' placeholder='wgt' className={`${styles.smInput} ${manifestStyles.orgdest}`} value={props.awbFields?.wgt} onChange={props.onChangeAwb} />
                                  </TableCell>
                                  <TableCell className={styles.minWidth135}>
                                    <CustomTextField
                                      {...InputProps}
                                      id=''
                                      fullWidth
                                      placeholder='Remarks'
                                      type='text'
                                      name='remark'
                                      value={props.awbFields?.remark}
                                      onChange={props.onChangeAwb}
                                    />
                                  </TableCell>
                                  <TableCell className={styles.minWidth125}>
                                    <input type='text' name='spl_code' placeholder='spl' defaultValue='' className={`${manifestStyles.splInput}`} value={props.awbFields?.spl_code} onChange={props.onChangeAwb} />
                                  </TableCell>
                                  <TableCell className={styles.minWidth100}>
                                    <CustomSelect
                                      {...SelectInputProps}
                                      id=''
                                      name='productsId'
                                      className={`${styles.smInput}`}
                                      value={props.awbFields?.productsId}
                                      onChange={props.onChangeAwb}
                                      inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                      {props.productsList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                      <MenuItem key={99} value={0}>
                                        GENERAL
                                      </MenuItem>
                                    </CustomSelect>
                                  </TableCell>
                                  <TableCell style={{
                                    minWidth: '30px',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                  }}>
                                    <IconButton
                                      size='small'
                                      aria-label='send'
                                      edge='end'
                                      title='Add AWB'
                                      onClick={props.onAddAwb}
                                    >
                                      <Save fontSize='inherit' />
                                    </IconButton>
                                    <IconButton
                                      size='small'
                                      aria-label='send'
                                      edge='end'
                                      title='Add AWB'
                                      onClick={() => props.handleAwb(false)}
                                    >
                                      <Delete fontSize='inherit' />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>) : (<TableRow classes={{ root: manifestStyles.firstRow }} >
                                  <TableCell colSpan={8} className={manifestStyles.addAwbCell}>
                                    {(props.edit &&
                                      <Button variant='outlined' color='primary' onClick={() => props.handleAwb(uld.id)} className={manifestStyles.addAwbBtn}>
                                        Add AWB</Button>
                                    )}
                                  </TableCell>
                                </TableRow>)}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </Grid>
                      <Grid item container xs={12} lg={5} alignItems={'center'} spacing={2} className={styles.mt1}>
                        <Grid item xs={3}>
                          <CustomInputLabel id='handled_as' aria-label='Select handled as' className={styles.ipLabel}>Handled as</CustomInputLabel>
                          <FormControl fullWidth>
                            <CustomSelect
                              {...SelectInputProps}
                              id='handled_as'
                              name='type'
                              classes={{ input: manifestStyles.smSelect }}
                              // style={{ width: '200px' }}
                              value={uld?.type}
                              onChange={(e) => props.onChange(e, uld.id, null)}
                              disabled={disablefields || props.showReportModal}
                            >
                              <MenuItem value={'Loose'}>Loose</MenuItem>
                              <MenuItem value={'Intact'}>Intact</MenuItem>
                            </CustomSelect>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <CustomInputLabel htmlFor='' aria-label='Enter Select ' className={styles.ipLabel}>ULD Type</CustomInputLabel>
                          <FormControl fullWidth>
                            <CustomSelect
                              {...SelectInputProps}
                              id=''
                              name='uld_type'
                              classes={{ input: manifestStyles.smSelect }}
                              value={uld?.uld_type}
                              // style={{ width: '200px' }}
                              onChange={(e) => props.onChange(e, uld.id, null)}
                              disabled={disablefields || uld?.type == 'Loose' || props.showReportModal}
                            >
                              <MenuItem value={'LD3'}>LD3</MenuItem>
                              <MenuItem value={'2X LD3'}>2X LD3</MenuItem>
                              <MenuItem value={'LD7'}>LD7</MenuItem>
                              <MenuItem value={'MD'}>MD</MenuItem>
                              <MenuItem value={'2X MD'}>2X MD</MenuItem>
                            </CustomSelect>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <CustomInputLabel htmlFor='remarks' aria-label='Enter remarks' className={styles.ipLabel}>Remarks</CustomInputLabel>
                          <CustomTextField
                            {...InputProps}
                            id='remarks'
                            name='remarks'
                            fullWidth
                            // autoFocus
                            placeholder='Remarks'
                            type='text'
                            value={props?.uld_remarks?.[uld.id] ? props?.uld_remarks?.[uld.id] : uld?.remark}
                            onChange={(e) => props.onChange(e, uld.id, null)}
                            InputProps={{
                              disableUnderline: true,
                              endAdornment: <InputAdornment position='end'>
                                <IconButton
                                  size='small'
                                  aria-label='send'
                                  edge='end'
                                  onClick={() => props.onChange({
                                    target: {
                                      name: 'sub_remark',
                                      value: null,
                                    }
                                  }, uld.id, null)}
                                >
                                  <Save fontSize='inherit' />
                                </IconButton>
                              </InputAdornment>,
                            }}
                            disabled={disablefields || props.showReportModal}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>);
                })}

                {/* <Grid item container spacing={3}>
                <Grid item xs={12} lg={6}> 
                  <Typography variant='h6' component='h6' color={theme.palette.primary.main} fontWeight={'bold'} fontSize={'12px'} className={`${styles.ml3} ${styles.mb1} ${styles.mt2}`}>PMC12345LH | FRA-MIA | 3 Awbs | 1163.0K </Typography>
                  <div className={manifestStyles.manifestDetails}>
                    <TableContainer>
                      <Table className={manifestStyles.manifestTable}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={8}></TableCell>
                          </TableRow>
                        </TableHead>
              
                        <TableBody>
                          <TableRow classes={{ root: manifestStyles.firstRow }} >
                            <TableCell >020-12345675</TableCell>
                            <TableCell> MIL-MIA</TableCell>
                            <TableCell >6/12</TableCell>
                            <TableCell>652.0K</TableCell>
                            <TableCell>Parmesan Cheese</TableCell>
                            <TableCell >PER/EAT/SLY</TableCell>
                            <TableCell >
                              <CustomSelect
                                id=''
                                variant='outlined'
                                size='small'
                                type='select'
                                className={`${styles.smInput}`}
                              >
                                <MenuItem>
                              123
                                </MenuItem>
                              </CustomSelect>
                            </TableCell>
                            <TableCell>
                              <div onClick={() => props.handleOpen('view_awb')}>
                                <PageIcon onClick={() => props.handleOpen('view_awb')} />
                              </div>
                            </TableCell>
                          </TableRow>

                          <TableRow classes={{ root: manifestStyles.firstRow }} >
                            <TableCell >020-12345675</TableCell>
                            <TableCell> MIL-MIA</TableCell>
                            <TableCell >6/12</TableCell>
                            <TableCell>652.0K</TableCell>
                            <TableCell>Parmesan Cheese</TableCell>
                            <TableCell >PER/EAT/SLY</TableCell>
                            <TableCell >
                              <CustomSelect
                                id=''
                                variant='outlined'
                                size='small'
                                type='select'
                                classes={{ input: manifestStyles.smSelect }}
                                // style={{ width: '200px' }}
                              >
                                <MenuItem>
                              123
                                </MenuItem>
                              </CustomSelect>
                            </TableCell>
                            <TableCell >
                              <PageIcon />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
                <Grid item container xs={12} lg={6} alignItems={'center'} spacing={2}> 
                  <Grid item xs={3}>
                    <CustomInputLabel htmlFor='' aria-label='Enter Select ' className={styles.ipLabel}>ULD Type</CustomInputLabel>
                    <FormControl fullWidth>
                      <CustomSelect
                        id=''
                        variant='outlined'
                        size='small'
                        type='select'
                        classes={{ input: manifestStyles.smSelect }}
                        value={'age'}
                        // style={{ width: '200px' }}
                      >
                        <MenuItem> 123 </MenuItem>
                      </CustomSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <CustomInputLabel htmlFor='' aria-label='Enter Select ' className={styles.ipLabel}>Handled as</CustomInputLabel>
                    <FormControl fullWidth>
                      <CustomSelect
                        id=''
                        variant='outlined'
                        size='small'
                        type='select'
                        classes={{ input: manifestStyles.smSelect }}
                        // style={{ width: '200px' }}
                        value={'age'}
                      >
                        <MenuItem> 123 </MenuItem>
                      </CustomSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInputLabel htmlFor='' aria-label='Enter Select ' className={styles.ipLabel}>Remarks</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id=''
                      fullWidth
                      autoFocus
                      placeholder='Remarks'
                      type='text'
                      name=''
                    />
                  </Grid>
                </Grid>
              </Grid> */}
              </Grid>
            </div>
          </StyledCard>
        </Grid>
      </Grid>

      <PDFSliderManifest
        modal={props.modal}
        modal_data={props.modal_data}
        onClose={props.onClose}
      />
      <MessageModal
        modal={props.modal}
        onClose={props.onClose}
        modal_data={props.modal_data}
      />

      <DuplicateAwbModal
        modal={props.modal}
        onClose={props.onClose}
        modal_data={props.modal_data}
        awb_value={props.awb_value}
        onChange={props.onChange}
      />
    </>
  );
};

export default Manifest;