import React from 'react';
import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer';
import UldTagDetails from './uldTagDetails';
import NunitoRegular from '../../../../../../assets/fonts/Nunito-Regular.ttf';
import NunitoMedium from '../../../../../../assets/fonts/Nunito-Medium.ttf';
import NunitoBold from '../../../../../../assets/fonts/Nunito-Bold.ttf';
import NunitoItalic from '../../../../../../assets/fonts/Nunito-MediumItalic.ttf';

// Register fonts individually
Font.register({
  family: 'NunitoRegular',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fonts: [
    {
      src: NunitoRegular,
      fontWeight: 400,
    },
  ],
});
Font.register({
  family: 'NunitoMedium',
  fontStyle: 'normal',
  fontWeight: 600,
  fonts: [
    {
      src: NunitoMedium,
      fontWeight: 600,
    },
  ]
});
Font.register({
  family: 'NunitoBold',
  fontStyle: 'normal',
  fontWeight: 800,
  fonts: [
    {
      src: NunitoBold,
      fontWeight: 800,
    },
  ]
});
Font.register({
  family: 'NunitoItalic',
  fontStyle: 'italic',
  fontWeight: 600,
  fonts: [
    {
      src: NunitoItalic,
      fontWeight: 600,
    },
  ]
});


const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    lineHeight: 1.5,
    flexDirection: 'column',
  }, 
});


const TagDocument = (props) => {
  return (
    <Document>
      <Page orientation='portrait'
        size={{
          width: 4*72, //4 inch
          height: 6*72 //6 inch
        }} style={styles.page} wrap={true}>
        <UldTagDetails uld={props.uld} />
      </Page>
    </Document>
  );
};

export default TagDocument;
