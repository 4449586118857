import React from 'react';
import { Checkbox, Dialog, Stack } from '@mui/material';
import styles from '../../../../../../../custom/style.module.css';
import { CustomButton, CustomTextField, CustomDialogTitle, CustomDialogAction, CustomInputLabel, StyledCard, InputProps, CustomDialogContent, CustomCheckbox } from '../../../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';

const AddAccessoriesModal = (props) => {
  const { action, addAccessories, editAccessories, onChangeAccessories } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal === 'accessories'} onClose={props.onClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          {action == 'add' ? 'Add' : 'Edit'} Loading Accessories
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form className={styles.w100} onSubmit={action == 'add' ? addAccessories : editAccessories} onReset={props.onClose}>
          <CustomDialogContent>
            <StyledCard>
              <div>
                <Stack direction='row' spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                  <CustomInputLabel htmlFor='description'>Description</CustomInputLabel>
                  <CustomCheckbox 
                    control={
                      <Checkbox
                        checked={props.accessoriesField.billable}
                        onChange={onChangeAccessories}
                        name='billable'
                        color='primary'
                        inputProps={{ 'aria-label': 'billable' }}
                        className={`${styles.py0} ${styles.pl0}`}
                      />
                    }
                    className={`${styles.mr0} ${styles.pb1}`}
                    label='Is Billable'
                    labelPlacement='end' />
                </Stack>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  autoFocus
                  id='description'
                  className={`${styles.mb3}`}
                  placeholder='Loading Accessories'
                  type='text'
                  name='description'
                  onChange={onChangeAccessories}
                  value={props.accessoriesField.description || ''}
                  error={props.accessoriesErrors.description}
                  helperText={props.accessoriesErrors.description}
                />
              </div>
              <div>
                <CustomInputLabel htmlFor='wgt'>Weight</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='wgt'
                  className={`${styles.mb3}`}
                  placeholder='Weight'
                  type='number'
                  name='wgt'
                  onChange={onChangeAccessories}
                  value={props.accessoriesField.wgt || ''}
                  error={props.accessoriesErrors.wgt}
                  helperText={props.accessoriesErrors.wgt}
                />
              </div>
            </StyledCard>

          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset'> Close </CustomButton>
            <CustomButton variant='contained' type='submit'>{ action == 'add' ? 'Submit' : 'Update'}</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default AddAccessoriesModal;