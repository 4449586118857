import React, { Fragment } from 'react';
import { StyledCard, StyledCardTitle, CustomCheckbox, CustomSelect, SelectInputProps, StyledBlueCard, CustomRadio, CustomButton, CustomInputLabel, InputProps, CustomTextField, CustomSelectRounded, StyledDataGrid, DataGridProps } from 'custom/components';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Checkbox, FormGroup, FormLabel, Grid, MenuItem, Radio, RadioGroup, Stack, Tooltip, Typography, Box, Card, CardHeader, Divider, CardContent, Popper, IconButton } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import flightInfoStyles from './fltloadinfo.module.css';
import { BinoIcon, ExcelIcon, PDFIcon, EditIcon, DeleteIcon, DollarRateIcon } from 'custom/constants';
import { ArrowDropDown, Warning } from '@mui/icons-material';
import theme from 'custom/theme';
import ContractData from './ContractData';
import Discrepancy from './Discrepancy';
import Statistics from './Statistics';
// import BillingFilterContainer from '../../../billingFilters/BillingFilterContainer';
import Invoice from './invoice/Invoice';
import cookies from 'utils/cookies';
import FlightReportModal from './FlightReportModal';
import EncryptionContainer from '../../../common/EncryptionContainer';
import ProgressModal from 'components/pages/common/ProgressModal';
import Status from 'components/pages/common/Status';
import moment from 'moment';
import { grey } from '@mui/material/colors';

const timezone = cookies.get('timezone') || null;

const FLIGHTINFO = [
  {
    label: 'Contract Data',
    value: 'contract_data'
  },
  {
    label: 'Statistics',
    value: 'statistics',
  },
  {
    label: 'Discrepancies',
    value: 'discrepancy'
  }
];

const isAdhoc = <Tooltip title='Adhoc Flight' placement='top'>
  <code className={flightInfoStyles.adhocFlight}> i </code>
</Tooltip>;

// const status = (status) => {
//   let statusIndicator = '';
//   // let statusColor = 'var(--redText)';
//   switch(status) {
//     case 'Pending':
//       statusIndicator = 'P';
//       // return <span className={flightInfoStyles.flightStatus}>{statusIndicator}</span>;
//       break;
//     case 'Finalized':
//       statusIndicator = 'F';
//       break;
//       // return <span className={flightInfoStyles.flightStatus}>{statusIndicator}</span>;
//     case 'Approved' :
//       // statusIndicator = 'var(--secondarygreen)';
//       return ;
//     default: break;
//   }
//   return <span className={flightInfoStyles.flightStatus}>{statusIndicator}</span>;
// };

const getInvoice_No = (invoice_info, searchFields) => {
  let month = null;
  if (searchFields.period === 'first') {
    month = '-01';
  } else if (searchFields.period === 'second') {
    month = '-02';
  }
  let invoice_no = `${invoice_info?.file_name}`;
  if (month) {
    invoice_no = `${invoice_info?.file_name}${month}`;
  }
  return invoice_no;
};

const FlightLoadInfo = (props) => {

  const columns = [
    {
      field: 'label',
      headerName: 'Title',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'operation',
      headerName: 'Operation',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div>
            {row.operation === 'ADD' ? 'Add' : 'Subtract'}
          </div>
        );
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'cycle',
      headerName: 'Cycle',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div>
            {row.cycle === 'C1' ? 'Cycle1' : row.cycle === 'C2' ? 'Cycle2' : 'Cycle1 & Cycle2'}
          </div>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const { row, id } = params;
        return (
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <EditIcon onClick={() => props.editOneOff(id-1, row)} />
            <DeleteIcon onClick={() => props.deleteOneOff(id-1, row)} />
          </Stack>
        );
      }
    },
  ];

  const infoRef = React.useRef(null);
  // const statRef = React.useRef(null);
  const desRef = React.useRef(null);

  const invoiceDownload =
    (cookies.get('role') === 'Accounting' || cookies.get('role') === 'System Admin' || cookies.get('role') === 'Manager') && props.loadInfo === 'contract_data' ?
      <div className={`${flightInfoStyles.downloadInvoice}`}>
        <Invoice
          invoice_no={getInvoice_No(props.invoice_info, props.searchFields)}
          flightdata={[]}
          daylogdata={[]}
          monthlogdata={[]}
          invoiceData={props.alcontractsData}
          dayContractData={props.dayContractData}
          monthContractData={props.monthContractData}
          invoice_info={props.invoice_info}
          airlineCode={props.airlineCode}
          title='Invoice (Gross)'
          cont_item={props.cont_item}
          searchFields={props.searchFields}
          filename={`${props.airlineCode}-${props.cont_item}-${cookies.get('station_code')}-Invoice`}
          billingFields={props.billingFields}
          oneOff={props.oneOff.filter(item => item.cycle?.includes('C1') && props.searchFields.period === 'first' || item.cycle?.includes('C2') && props.searchFields.period === 'second' || props.searchFields.period === 'month')}
          progress={props.progress}      
        />
        <Invoice
          invoice_no={getInvoice_No(props.invoice_info, props.searchFields)}
          flightdata={props.flightdata.filter(flight => flight.pending !== true)}
          daylogdata={props.daylogdata.filter(item => item.pending !== true)}
          monthlogdata={props.monthlogdata}
          invoiceData={props.alcontractsData}
          dayContractData={props.dayContractData}
          monthContractData={props.monthContractData}
          invoice_info={props.invoice_info}
          airlineCode={props.airlineCode}
          title='Invoice (Itemized)'
          cont_item={props.cont_item}
          searchFields={props.searchFields}
          filename={`${props.airlineCode}-${props.cont_item}-${cookies.get('station_code')}-Invoice`}
          billingFields={props.billingFields}
          oneOff={props.oneOff.filter(item => item.cycle?.includes('C1') && props.searchFields.period === 'first' || item.cycle?.includes('C2') && props.searchFields.period === 'second' || props.searchFields.period === 'month')}
          progress={props.progress}
        />
      </div> : null;

  // const handleExport = async (e) => {
  //   console.log('props.loadInfo', e);
  //   if (props.loadInfo == 'contract_data') {
  //     let csv = await infoRef.current.getDataAsCsv();
  //     CsvToExcel(csv, 'Contract Data');
  //   }
  //   if (props.loadInfo == 'statistics') {
  //     // if (props.accord_type) {
  //     //   csv = await statRef.current.getDataAsCsv();
  //     // } else {
  //     let sData = [];
  //     props.billingData.map((column) => {
  //       let data = {
  //         'Lable': column?.ex_label,
  //         'Flight': column?.flight_count,
  //       };
  //       props.billingColumns.map((c) => {
  //         data[c.headerName] = c.headerName != 'AWBS' ? column?.[c.field] : column?.contractData?.length;
  //       });
  //       sData.push(data);
  //       data = {};
  //       data = {
  //         'Lable': column?.inbound?.ex_label,
  //         'Flight': column?.inbound?.flight_count,
  //       };
  //       props.billingColumns.map((c) => {
  //         data[c.headerName] = c.headerName != 'AWBS' ? column?.inbound?.[c.field] : column?.inbound?.contractData?.length;
  //       });
  //       sData.push(data);
  //       if (column?.inbound?.flights?.length > 0) {
  //         column?.inbound?.flights.map((flight) => {
  //           data = {};
  //           data = {
  //             'Lable': flight.label,
  //             'Flight': flight.flight_count,
  //           };
  //           props.billingColumns.map((c) => {
  //             data[c.headerName] = c.headerName != 'AWBS' ? flight[c.field] : flight.contractData?.length;
  //           });
  //           sData.push(data);
  //         });
  //       }
  //       data = {};
  //       data = {
  //         'Lable': column?.outbound?.ex_label,
  //         'Flight': column?.outbound?.flight_count,
  //       };
  //       props.billingColumns.map((c) => {
  //         data[c.headerName] = c.headerName != 'AWBS' ? column?.outbound?.[c.field] : column?.outbound?.contractData?.length;
  //       });
  //       sData.push(data);
  //       if (column?.outbound?.flights?.length > 0) {
  //         column?.outbound?.flights.map((flight) => {
  //           data = {};
  //           data = {
  //             'Lable': flight.label,
  //             'Flight': flight.flight_count,
  //           };
  //           props.billingColumns.map((c) => {
  //             data[c.headerName] = c.headerName != 'AWBS' ? flight[c.field] : flight.contractData?.length;
  //           });
  //           sData.push(data);
  //         });
  //       }
  //     });
  //     JsonToExcel(sData, 'Statistics');
  //     // }
  //   }
  //   if (props.loadInfo == 'discrepancy') {
  //     if (props.accord_type && props.accord_type != 'airline') {
  //       let csv = await desRef.current.getDataAsCsv();
  //       CsvToExcel(csv, 'Discrepancy');
  //     } else {
  //       let sData = [];
  //       discrepancyType.map((type) => {
  //         sData.push({
  //           'Type': type,
  //           'Total': props.billingData[0][type],
  //           'Inbound': props.billingData[0]?.inbound[type],
  //           'Outbound': props.billingData[0]?.outbound[type]
  //         });
  //       });
  //       JsonToExcel(sData, 'Discrepancy');
  //     }
  //   }
  // };

  return <>
    {/* <BillingFilterContainer
      onFilterMain={props.onFilterMain}
      flightFields={props.flightFields}
      searchFields={props.searchFields}
      airlines={props.airlines}
      handleContractType={props.handleContractType}
      contract_Type={props.contract_Type}
      loadInfo={props.loadInfo}
    /> */}


    {/* <div>
      <CustomCheckbox
        control={
          <Checkbox
            inputProps={{ 'aria-label': 'flight-info' }}
            checked={props.showOneOff}
            onChange={props.showHideOneOff}
            name='view_oneoff'
          />
        }
        label='Show One Off'
        className={`${styles.mr1} ${styles.fJustifyEnd}`}
      />
    </div> */}
    {props.showOneOff &&
    <StyledCard className={`${styles.mb4}`}>
      <StyledCardTitle gutterBottom>Flight Info</StyledCardTitle>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <CustomInputLabel htmlFor='routing' aria-label='Enter Label'>Label</CustomInputLabel>
          <CustomTextField
            {...InputProps}
            id='label'
            name='label'
            fullWidth
            autoFocus
            type='text'
            value={props?.oneOffEdit?.label}
            onChange={props.handleOneOffChange}
          />
        </Grid>
        
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <CustomInputLabel htmlFor='routing' aria-label='Select an Operat'>Operation</CustomInputLabel>
          <CustomSelectRounded
            id='operation'
            name='operation'
            label={false}
            value={props.oneOffEdit?.operation || 'placeholder'}
            onChange={props.handleOneOffChange}
            className={`${styles.minWidth200} ${styles.fOne}`}
          >
            <MenuItem value='placeholder' disabled>Select an operation</MenuItem>
            <MenuItem key={'ADD'} value={'ADD'}>Add</MenuItem>
            <MenuItem key={'SUB'} value={'SUB'}>Subtract</MenuItem>

          </CustomSelectRounded>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <CustomInputLabel htmlFor='routing' aria-label='Enter Amount'>Amount</CustomInputLabel>
          <CustomTextField
            {...InputProps}
            id='amount'
            name='amount'
            fullWidth
            autoFocus
            type='text'
            value={props?.oneOffEdit?.amount}
            onChange={props.handleOneOffChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <CustomInputLabel htmlFor='routing' aria-label='Select Cycle'>Cycle</CustomInputLabel>
          <CustomSelectRounded
            id='cycle'
            name='cycle'
            label={false}
            value={props.oneOffEdit?.cycle || 'placeholder'}
            onChange={props.handleOneOffChange}
            className={`${styles.minWidth200} ${styles.fOne}`}
          >
            <MenuItem value='placeholder' disabled>Select a cycle</MenuItem>
            <MenuItem key={'C1'} value={'C1'}>Cycle1</MenuItem>
            <MenuItem key={'C2'} value={'C2'}>Cycle2</MenuItem>
            <MenuItem key={'C1,C2'} value={'C1,C2'}>Cycle1 & Cycle2</MenuItem>
          </CustomSelectRounded>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={1} xl={1}>
          <CustomButton variant='contained' className={`${styles.minWidth280}`} name='reopen' onClick={() => props.updateOneOff()} > Save </CustomButton>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={1} xl={1}>
          {
            props.oneOffEdit?.index !== null &&
            <CustomButton variant='contained' className={`${styles.minWidth280}`} name='reopen' onClick={() => props.clearOneOff()} > Cancel </CustomButton>
          }
        </Grid>
      </Grid>
      <div className={styles.tableWrapper}>
        <Box className={`${styles.tableOuter}`}>
          <StyledDataGrid
            {...DataGridProps}
            rows={props.oneOff || []}
            columns={columns}
            param={props.oneOff?.length}
          />
        </Box>
      </div>
      
    </StyledCard>}

    <StyledCard>
      <Stack direction='row' justifyContent='space-between' alignItems='center' className={`${styles.mb2} ${styles.pb2}`} borderBottom={'1px solid #dfdfdf'} minHeight={'40px'}>

        <Grid container spacing={{
          xs: 2,
          lg: 0
        }}>
          <Grid item xs={6} className={flightInfoStyles.flightInfo}>
            <StyledCardTitle className={`${styles.pb0}`}>Flight Load Info</StyledCardTitle>
          </Grid>

          <Grid item xs={6}>
            <Stack direction={'row'} spacing={2} justifyContent={'flex-end'} alignItems={'center'}>
              {props.loadInfo === 'contract_data' ? (
                <>
                  {cookies.get('role') === 'System Admin' &&
                  <>
                    <CustomButton variant='contained' color='primary' size='small' sx={{ py: props.invoice_info?.lastUpdatedDt ? '2px' : '6px' }}
                      className={`${styles.tableBtn} ${flightInfoStyles.regBtn}`} onClick={() => props.triggerNewBilling('true')} disabled={cookies.get('role') === 'System Admin' ? false : true} >
                      <Stack direction='column'>
                        <Typography component={'div'} variant='body2' fontSize={'0.85rem'}>Regenerate</Typography>
                        {props.invoice_info?.lastUpdatedDt &&
                        <Typography component={'span'} variant='caption' fontSize={'9px'} color='#c8c8c8' lineHeight='normal'>
                          Last updated { props.invoice_info?.lastUpdatedDt ? moment.utc(props.invoice_info?.lastUpdatedDt).tz(timezone).format('DD MMM YYYY | HH:mm A') : '' }
                        </Typography>}
                      </Stack>
                    </CustomButton>
                  </>
                  }
                  {
                    //Show button to authenticate, if authenticated show download button
                    cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && !cookies.get('managecdt') ?
                      <Box sx={{
                        [`& .${styles.squareIcon}`]: {
                          height: '32px',
                          width: '28px',
                          marginTop: '-1px',
                          marginRight: '0px !important', 
                        },
                      }}>
                        <DollarRateIcon title='Secure Billing Access' 
                          onClick={() => props.handleOpen('encryption', null, null, null)}  /> 
                      </Box>
                      :
                      (cookies.get('role') === 'System Admin' || cookies.get('role') === 'Accounting' )
                        && cookies.get('ismanagec') && cookies.get('ismanagec') == 'true' && cookies.get('managecdt') ?
                        <PDFIcon title='Download Invoice' onClick={(event) => {
                          if (!props.genInvProgress && props.invoice_info) {
                            props.handleOpen('downloadProgress', event);
                          }
                        }} className={`${styles.tableIcon}`} disabled={props.genInvProgress || !props.invoice_info } />
                        : ''}
                  <ExcelIcon title='Download File' onClick={props.handleExport} className={styles.tableIcon} />
                </>
              ) : 
                <ExcelIcon title='Download File' onClick={props.handleExport} className={styles.tableIcon} />
              }
            </Stack>
          </Grid>
          
        </Grid>
      </Stack>

      <Grid container spacing={3}>
        <Grid item className={flightInfoStyles.flightInfo} order={{ xs: 1 }}>
          <StyledBlueCard className={flightInfoStyles.fltPaper}>
            <Typography variant='caption' component='p'
              lineHeight='normal' color={theme.palette.common.white} fontSize={'14px'} fontWeight={700} marginBottom={'14px'}>
              Drill Down
            </Typography>
            {
              props.billingData && props.billingData.length > 0 && (props.billingData.map((data, i) => {
                let totalPendingFlights = 0;
                let inboundPendingFlights = 0;
                let outboundPendingFlights = 0;
                let pendingText = 'Not Finalized';

                inboundPendingFlights = data?.inbound.flights.reduce((count, flight) => flight.status === 'Not Finalized' ? count + 1 : count, 0);
                outboundPendingFlights = data?.outbound.flights.reduce((count, flight) => flight.status === 'Not Finalized' ?  count + 1 : count, 0);
                totalPendingFlights = inboundPendingFlights + outboundPendingFlights;

                return (
                  <Accordion disableGutters className={flightInfoStyles.accordionFlight} key={i} >
                    <AccordionSummary
                      // className={flightInfoStyles.accordionSummaryFlight}
                      className={(props.accord_type === 'airline' && props.accord_id === data.id ?
                        `${flightInfoStyles.selectedFlight} ${flightInfoStyles.accordionSummaryFlight}` :
                        flightInfoStyles.accordionSummaryFlight)}
                      classes={{
                        content: flightInfoStyles.accordionContentFlight,
                        expanded: flightInfoStyles.accordionFlightExpanded
                      }}
                      expandIcon={<ArrowDropDown />}
                      aria-controls='panel1-content'
                      id='panel1-header'
                      onClick={() => props.setContTBData(data, 'airline')}
                    >
                      {
                        data.label
                      }
                      {props.loadInfo === 'contract_data' &&
                      <Typography component={'span'} fontSize={'inherit'} color={'error'} fontWeight={'600'}>
                        ({totalPendingFlights + ' ' + pendingText})</Typography>
                      }
                    </AccordionSummary>
                    <AccordionDetails className={`${flightInfoStyles.accordionDetailsFlightMain} ${flightInfoStyles.fltDetails}`}>
                      <Accordion expanded={props.expanded === 'inbound'} onChange={props.handleChange('inbound')}
                        disableGutters className={flightInfoStyles.accordionFlight}>
                        <AccordionSummary
                          // className={flightInfoStyles.accordionSummaryFlight}
                          className={props.accord_type === 'inbound' && props.accord_id === data?.inbound?.id ?
                            `${flightInfoStyles.selectedFlight} ${flightInfoStyles.accordionSummaryFlight}` :
                            flightInfoStyles.accordionSummaryFlight}
                          classes={{
                            content: flightInfoStyles.accordionContentFlight,
                            expanded: flightInfoStyles.accordionFlightExpanded
                          }}
                          onClick={() => props.setContTBData(data.inbound, 'inbound')}
                          expandIcon={<ArrowDropDown />}
                          aria-controls='panel1-content'
                          id='panel1-header'
                        >
                          {
                            data?.inbound?.label
                          }
                          {props.loadInfo === 'contract_data' &&
                          <Typography component={'span'} fontSize={'inherit'} color={'error'} fontWeight={'600'}>
                            ({inboundPendingFlights + ' ' + pendingText})
                          </Typography>}
                        </AccordionSummary>
                        <AccordionDetails className={`${flightInfoStyles.accordionDetailsFlightMain} ${flightInfoStyles.fltDetailsInner}`}>
                          {data.inbound?.flights?.map((flight, i) => (
                            <Fragment key={i} >
                              <div
                                // className={`${flightInfoStyles.accordionDetailsFlight} ${styles.positionRelative}`} 
                                className={`${(props.accord_type === 'inbound_flight' && props.accord_id === flight.id) ? flightInfoStyles.selectedFlight : ''}
                                  ${flight?.status === 'Rejected' ? flightInfoStyles.pending : ''}
                                  ${flightInfoStyles.accordionDetailsFlight} 
                                  ${styles.positionRelative}`
                                }
                                onClick={() => props.setContTBData(flight, 'inbound_flight')} >
                                <div className={flightInfoStyles.flightLabelWrapper}>
                                  <p>{flight?.status === 'Rejected' &&
                                    <>
                                      <Warning sx={{
                                        fontSize: 18,
                                        marginBottom: '-2px',
                                        marginRight: '2px'
                                      }} color='error' />
                                    </>}
                                  {flight?.label}</p>
                                  {flight?.status === 'Not Finalized' || flight?.status === 'Submitted' ?
                                    <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color={'error'} pt={0.1}>(Not Finalized)</Typography> :
                                    flight?.status === 'Finalized' || flight?.status === 'Pending' || flight?.status === 'Approved' ?
                                      <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color={'black'} pt={0.1}>(Finalized)</Typography> : 
                                      flight?.status === 'Rejected' ?
                                        <>
                                          <IconButton
                                            onMouseEnter={(e) => props.handlePopover(e, flight)}
                                            onMouseLeave={props.handlePopoverClose} 
                                            disableRipple
                                            className={`${styles.positionRelative} ${styles.p0}`}
                                          >
                                            <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color={'error'} pt={0.1}>(Rejected)</Typography>
                                          </IconButton>
                                        </>
                                        : ''
                                  }
                                </div> 
                                {/* For Adhoc Flights */}
                                {!flight.flight_schedule_id ? isAdhoc : ''} 
                                <BinoIcon title='View Flight Report' className={`${styles.smActionIcons} ${flightInfoStyles.reportIcon}`}
                                  onClick={() => props.handleFlight('inbound', flight)} />
                              </div>
                            </Fragment>
                          ))}
                        </AccordionDetails>
                      </Accordion>

                      <Accordion expanded={props.expanded === 'outbound'} onChange={props.handleChange('outbound')}
                        disableGutters className={flightInfoStyles.accordionFlight}>
                        <AccordionSummary
                          onClick={() => props.setContTBData(data.outbound, 'outbound')}
                          // className={flightInfoStyles.accordionSummaryFlight}
                          className={props.accord_type === 'outbound' && props.accord_id === data?.outbound?.id ?
                            `${flightInfoStyles.selectedFlight} ${flightInfoStyles.accordionSummaryFlight}` :
                            flightInfoStyles.accordionSummaryFlight}
                          classes={{
                            content: flightInfoStyles.accordionContentFlight,
                            expanded: flightInfoStyles.accordionFlightExpanded
                          }}
                          expandIcon={<ArrowDropDown />}
                          aria-controls='panel1-content'
                          id='panel1-header'
                        >
                          {
                            data?.outbound?.label
                          }
                          {props.loadInfo === 'contract_data' &&
                          <Typography component={'span'} fontSize={'inherit'} color={'error'} fontWeight={'600'}>
                            ({outboundPendingFlights + ' ' + pendingText})</Typography>
                          }
                        </AccordionSummary>
                        <AccordionDetails className={`${flightInfoStyles.accordionDetailsFlightMain} ${flightInfoStyles.fltDetailsInner}`}>
                          {data.outbound?.flights?.map((flight, i) => (
                            <Fragment key={i} >
                              <div
                                // className={`${flightInfoStyles.accordionDetailsFlight} ${styles.positionRelative}`}
                                className={`${(props.accord_type === 'outbound_flight' && props.accord_id === flight.id) ? 
                                  flightInfoStyles.selectedFlight : ''} 
                                  ${flight.pending ? flightInfoStyles.pending : ''} 
                                  ${flightInfoStyles.accordionDetailsFlight} ${styles.positionRelative}`}
                                onClick={() => props.setContTBData(flight, 'outbound_flight')} >
                                <div className={flightInfoStyles.flightLabelWrapper}>
                                  <p>{flight?.status === 'Rejected' &&
                                    <>
                                      <Warning sx={{
                                        fontSize: 18,
                                        marginBottom: '-2px',
                                        marginRight: '2px'
                                      }} color='error' />
                                    </>}
                                  {flight?.label}</p>
                                  {flight?.status === 'Not Finalized' || flight?.status === 'Finalized' ?
                                    <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color={'black'} pt={0.1}>({flight?.status})</Typography> : 
                                    flight?.status === 'Rejected' ?
                                      <>
                                        <IconButton
                                          onMouseEnter={(e) => props.handlePopover(e, flight)}
                                          onMouseLeave={props.handlePopoverClose} 
                                          disableRipple
                                          className={`${styles.positionRelative} ${styles.p0}`}> 
                                          <Typography component={'p'} variant='caption' fontSize={'0.7rem'} color={'error'} pt={0.1} fontWeight={600}>&nbsp;(Rejected)</Typography>
                                        </IconButton>
                                      </>
                                      : ''
                                  }
                                </div> 
                                {/* For Adhoc Flights */}
                                {!flight.flight_schedule_id ? isAdhoc : ''} 
                                <BinoIcon title='View Flight Report' className={`${styles.smActionIcons} ${flightInfoStyles.reportIcon}`}
                                  onClick={() => props.handleFlight('outbound', flight)} />
                              </div>
                            </Fragment>
                          ))}
                        </AccordionDetails>
                      </Accordion>

                    </AccordionDetails>
                  </Accordion>
                );
              }))
            }
          </StyledBlueCard>
        </Grid>

        <Grid item xs className={`${flightInfoStyles.flightsLoadInfo} ${styles.positionRelative}`} order={{
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
        }}>
          <div className={`${flightInfoStyles.infofilter} ${styles.mb2}`}>
            <Grid container spacing={{
              xs: 2,
              lg: 0
            }} alignItems={'center'} justifyContent={'space-between'}
            >
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <RadioGroup
                  aria-labelledby='contract-type-radio-buttons-group'
                  name='contract-type-radio-buttons-group'
                  value={props.loadInfo}
                  row
                  onChange={props.handleToggle}
                  className={styles.radioToggleGroup}
                >
                  {FLIGHTINFO.map((value, index) => {
                    return (
                      <Fragment key={index}>
                        <CustomRadio
                          value={value.value}
                          control={<Radio className={styles.radioToggleBtn} />}
                          label={value.label}
                          className={`${styles.radioToggle} ${props.loadInfo === value.value ? styles.radioToggleBtnChecked : ''
                          }`}
                          classes={{ label: styles.radioToggleLabel }}
                        />
                        {value.label == 'Discrepancies' && (<Badge color='error' badgeContent={props.discrepancyCount} />)}
                      </Fragment>
                    );
                  })}
                </RadioGroup>
              </Grid>

              <Grid item xs={12} md={12} lg={7} xl={8}>
                <Stack direction='row' spacing={{
                  xs: 2,
                  lg: 2,
                }}
                justifyContent={{
                  lg: 'center',
                  md: 'left'
                }}
                alignItems={'center'} flexWrap='wrap' marginTop={'-4px'}>
                  {props.loadInfo != 'discrepancy' && (
                    <>
                      <FormLabel component='small' className={`${styles.xsW100}`}><small>Filter:</small> </FormLabel>
                      <FormGroup>
                        <Stack direction='row' spacing={{
                          lg: 3,
                          xl: 3,
                        }} justifyContent={{
                          lg: 'flex-end',
                          md: 'space-between'
                        }}
                        flexWrap='wrap'>
                          <CustomCheckbox
                            control={
                              <Checkbox inputProps={{ 'aria-label': 'Friday', }} checked={props.billingFields.exclude_disc} onChange={props.changeCheck} name='exclude_disc' />
                            } label='Exclude Discrepancies' />
                          <div>
                            <Typography variant='caption' component='span' lineHeight='normal' fontSize={'0.8rem'}>
                              Equalize transit at
                            </Typography>&nbsp; &nbsp;
                            <CustomSelect
                              {...SelectInputProps}
                              id='airline'
                              labelId='airline-label'
                              fullWidth
                              onChange={props.onChange}
                              name='transit'
                              // className={billingStyles.tableTextField}
                              style={{ width: '100px' }}
                              value={!props.billingFields.transit ? 'none' : props.billingFields.transit}
                            >
                              <MenuItem value='inbound'>Inbound</MenuItem>
                              <MenuItem value='outbound'>Outbound</MenuItem>
                              <MenuItem value='none'>None</MenuItem>
                            </CustomSelect>
                          </div>
                        </Stack>
                      </FormGroup>
                    </>)}
                </Stack>
              </Grid>
            </Grid>
          </div>
          {props.loadInfo === 'contract_data' && (
            <ContractData
              contractData={props.contractData}
              flightdata={props.flightdata}
              daylogdata={props.daylogdata}
              monthlogdata={props.monthlogdata}
              dayContractData={props.dayContractData}
              monthContractData={props.monthContractData}
              airlineCode={props.airlineCode}
              accord_type={props.accord_type}
              apiRef={infoRef}
              billingFields={props.billingFields}
              handleTableCollapse={props.handleTableCollapse}
              collapse={props.collapse}    
            />
          )}
          {props.loadInfo === 'statistics' && (
            <Statistics
              billingColumns={props.billingColumns}
              contractData={props.contractData}
              summaryData={props.summaryData}
              showSummary={props.showSummary}
              billingCol={props.billingCol}
              billingData={props.billingData}
            // apiRef={statRef}
            />)}
          {props.loadInfo === 'discrepancy' && (
            <Discrepancy contractData={props.contractData}
              showSummary={props.showSummary}
              billingData={props.billingData}
              apiRef={desRef}
            />
          )}
        </Grid>

      </Grid>
    </StyledCard>

    <Popper 
      id={'id'} 
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      placement='right-start'
    >
      <Card className={flightInfoStyles.statusPopover}>
        <CardHeader
          className={flightInfoStyles.statusPopoverHeader}
          title={<>
            <Typography variant='h6' color={grey[700]} fontSize='1rem' lineHeight='normal' textTransform={'capitalize'}>
              {props.contract_Type || ''}
            </Typography>
            <Typography variant='caption' color={'error'} fontWeight={700} lineHeight='normal' marginTop={'4px'} display='block'>
              {props.popoverData?.status || ''}
            </Typography>
          </>
          }>
        </CardHeader>
        <Divider  />
        <CardContent className={styles.p3}>
          <p className={flightInfoStyles.statusDesc}> 
            <Typography variant='caption' component='span' color={'black'} lineHeight='normal' textTransform={'capitalize'}>
              {props.popoverData?.last_log?.user?.role}
            </Typography>
            <span className={flightInfoStyles.statusIndicator}>{props.popoverData?.status === 'Rejected' ? 'Correction Requested' : ''}</span> 
          </p>
          <Divider className={styles.mb2} />
          <Stack direction='column' spacing={1}>
            <Typography variant='caption' component='small' color={grey[900]} lineHeight='normal' fontWeight='bold'>
                By:&nbsp;
              <Typography variant='caption' component='small' color={grey[800]} lineHeight='normal'>
                {`${props.popoverData?.last_log?.user?.first_name || ''} ${props.popoverData?.last_log?.user?.last_name || ''}`}
              </Typography>
            </Typography>
            <Typography variant='caption' component='small' color={grey[900]} lineHeight='normal' fontWeight='bold'>
                Reason:&nbsp;
              <Typography variant='caption' component='small' color={grey[800]} lineHeight='normal'>
                {`${props.popoverData?.last_log?.remark || ''}`} 
              </Typography>
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Popper>

    <FlightReportModal showModal={props.modal} closeModal={props.onClose} iframeRef={props.iframeRef}
      handleModalOpen={props.handleModalOpen}
      modalOpen={props.modalOpen}
      handleModalClose={props.handleModalClose}
      flight={props.flightReportData}
    />

    <ProgressModal 
      handleOpen={props.handleOpen}
      onClose={props.onClose}
      modal={props.modal}
      progress={props.progress}
      invoiceDownload={invoiceDownload}
    />
    
    <EncryptionContainer
      startTimer={props.startTimer}
      stopInterval={props.stopInterval}
      handleOpen={props.handleOpen}
      modal={props.modal}
      onClose={props.onClose}
    />
    <Status
      statusModal={props.sessionExpired}
      onClose={props.onClose}
      statusMessage= {{
        type: 'warning',
        size: 'md',
        title: 'Session Expired',
        mainMessage:'Session Expired',
        firstMessage:'Please authenticate again to restart the session.',
        secondMessage:''
      }}
    />
    
  </>;

};

export default FlightLoadInfo;
