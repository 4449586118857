import React from 'react';
import Admin from './Admin';
import http from 'utils/http';
import { connect } from 'react-redux';
import { setStationAction } from 'store/actions/companyAction';
import { pageHandler } from 'custom/constants';
import cookies from 'utils/cookies';
class AdminContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.match.params.tab || 'manage_users',
      buildUp: false,
      import: false,
    };
  }
  componentDidMount() {
    const currentPath = window.location.pathname;
    pageHandler(currentPath, cookies.get('role'));
    this.getConfig();
    http.get('/station').then(({ data }) => {
      this.props.setStations(data.data);
    });
  }

  getConfig = () => {
    http.get('/settings?key=buildup').then(({ data }) => {
      if(data.data) {
        let Buildup = data.data?.find((item) => item.key === 'buildup');
        if(Buildup) {
          this.setState({ buildUp: Buildup?.value && Buildup.value?.data === 'true'? true: false });
        }
      }
    });
    http.get('/settings?key=import').then(({ data }) => {
      if(data.data) {
        let Import = data.data?.find((item) => item.key === 'import');
        if(Import) {
          this.setState({ import: Import?.value && Import.value?.data === 'true'? true: false });
        }
      }
    });
  };

  handleChange = (newValue) => {
    if (this.props.match.url.includes('admin')) {
      this.props.history.replace(`${newValue}`);
    } else {
      this.props.history.replace(`admin/${newValue}`);
    }
    this.setState({ value: newValue });
  };

  render() {
    return (
      <>
        <Admin handleChange={this.handleChange} value={this.state.value} buildUp={this.state.buildUp} import={this.state.import} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setStations: (stations) => dispatch(setStationAction(stations)) };
};

export default connect(null, mapDispatchToProps)(AdminContainer);
