import moment from 'moment';
import cookies from './cookies';
import { getLocatDate } from './converter';

export const getFee = (fee, fees, awb, info = false) => {
  let cost = 0;
  let type = fee.type == 'add' ? 1 : -1;
  let unit = fee.unit;
  if (unit == 'cost') {
    cost = type * fee.cost;
  }
  if (unit == 'percent') {
    let other_fee = fees.find(f => f.key == fee.from_service);
    if (other_fee) {
      let other_cost = 0;
      other_fee?.value?.forEach((v) => {
        other_cost += getFee(v, fees);
      });
      cost = type * ((other_cost / 100) * fee.percent);
    } else {
      cost = type * 0;
    }
  }
  if (unit == 'cost_manual') {
    cost = type * (fee?.cost_value || 0);
  }
  if (unit == 'number') {
    cost = type * (fee?.cost_per_unit * (fee?.unit_value || 0));
  }

  if (unit == 'kg') {
    cost = type * (fee?.cost_per_unit * parseFloat(awb?.wgt || 0));
  }

  if (unit == 'kg_per_day') {
    // {
    //   "type": "add",
    //   "unit": "kg_per_day",
    //   "unit_divisor": 1,
    //   "free_days": "2",
    //   "cost_per_unit": "0.1",
    //   "mim_cost_per_day": "40",
    //   "first_weekend_free": true,
    //   "all_public_holidays_counted": true,
    //   "arrival_day_counted": true,
    //   "first_weekend_holiday_free_without_day_after_free_period": true,
    //   "first_public_holiday_counted": true
    // }

    // new code
    const setNextDateString = (date) => {
      return moment(date).add(1, 'days').format('YYYY-MM-DD');
    };

    const setNextDate = (date) => {
      return moment(date).add(1, 'days').toDate();
    };

    const isFreeDaysFromHolidays = (holidays, date) => {
      return holidays.find(h => h == date) != null;
    };

    const isFreeDaysFromWeekend = (date) => {
      return date.getDay() == 0 || date.getDay() == 6;
    };

    const holidays = cookies.get('holidays') || [];
    const arrival_day_counted = fee.arrival_day_counted;
    let startDate = awb?.storage_start;
    let pickupdate = getLocatDate();
    if(!arrival_day_counted) {
      startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
    }
    const first_weekend_holiday_free_without_day_after_free_period = fee.first_weekend_holiday_free_without_day_after_free_period;
    const free_days = parseInt(fee.free_days);
    const all_public_holidays_counted = fee.all_public_holidays_counted;
    const first_public_holiday_counted = fee.first_public_holiday_counted;
    const first_weekend_free = fee.first_weekend_free;
    const storage_divisor = fee.unit_divisor;
    const cost_per_unit = fee.cost_per_unit;
    const mim_cost_per_day = fee.mim_cost_per_day;
    let totalFreeDays = parseInt(free_days);
    let dateCheckHoliday = startDate;
    let dateCheckWeekend = moment(startDate).toDate();
    if (all_public_holidays_counted || first_public_holiday_counted || first_weekend_free) {
      let count = first_weekend_holiday_free_without_day_after_free_period ? free_days : free_days + 1;
      let idIsFirstHolidayDay = false;
      for (let i = 0; i < count; i += 1) {
        const isDayHoliday = first_public_holiday_counted && !idIsFirstHolidayDay ? isFreeDaysFromHolidays(holidays, dateCheckHoliday) : false;
        const isDayWeekend = first_weekend_free ? isFreeDaysFromWeekend(dateCheckWeekend) : false;
        if (isDayHoliday || isDayWeekend) {
          totalFreeDays += 1;
          dateCheckHoliday = setNextDateString(dateCheckHoliday);
          dateCheckWeekend = setNextDate(dateCheckWeekend);
          count += 1;
          if (isDayHoliday && !all_public_holidays_counted) {
            idIsFirstHolidayDay = true;
          }
        } else if (i <= free_days + 1) {
          dateCheckHoliday = setNextDateString(dateCheckHoliday);
          dateCheckWeekend = setNextDate(dateCheckWeekend);
        } else {
          break;
        }
      }
    }
    let chargableDays = Math.ceil((moment(pickupdate).toDate().getTime() - moment(startDate).toDate().getTime()) / (24 * 60 * 60 * 1000)) + 1 - totalFreeDays;
    if(chargableDays < 0) {
      chargableDays = 0;
    }
    let storageUnits = Math.ceil(parseFloat(awb?.wgt) / parseFloat(storage_divisor));
    let storageFee = (storageUnits || 0) * cost_per_unit * chargableDays;
    let totalMinCost = mim_cost_per_day * chargableDays;
    cost = type * parseFloat(Math.max(storageFee, totalMinCost) * type);
    if(info) {
      return {
        cost,
        awb_recv : awb?.storage_start,
        storage_start : startDate,
        storageFee,
        mim_cost_per_day,
        totalMinCost,
        chargableDays,
        storageUnits,
        cost_per_unit,
        totalFreeDays,
        pickupdate,
        startDate
      };
    }
  }
  return cost;
};


const calculateFees = (fees, awb) => {
  let totalCost = 0;
  let services = [];
  fees.forEach((fee) => {
    let c = 0;
    let add_info = [];
    if (fee.value && fee.value?.length > 0) {
      fee.value.forEach((v) => {
        c += parseFloat(getFee(v, fees, awb));
        if(v.unit == 'kg_per_day') {
          add_info.push(getFee(v, fees, awb, true));
        }
      });
    }
    services.push({
      ...fee,
      cost: c,
      additional_info: add_info
    });
    totalCost += parseFloat(c);
  });
  return {
    totalCost,
    services
  };
};

export default calculateFees;