import { dateTolocale } from './converter';

export const getAwbDetails = (data) => {
  let awb_no = data?.flight_awbs?.[0]?.awb_no;
  let pcs = 0;
  let wgt = 0;
  let org = '';
  let dest = '';
  let total_pcs = 0;
  let part = false;
  let parts = [];
  let remark = '';
  let flight_no = data?.flight?.airline?.code + data?.flight?.flight_no;
  let flight_arr = data?.flight?.act_arr ? dateTolocale(data?.flight?.act_arr, 'YYYY-MM-DD') : (data?.flight?.est_arr ? dateTolocale(data?.flight?.est_arr, 'YYYY-MM-DD') : dateTolocale(data?.flight?.schdl_arr, 'YYYY-MM-DD'));
  let storage_start = flight_arr;
  if (data?.house_awb) {
    awb_no = data?.house_awb_no;
    pcs = data?.pcs;
    wgt = data?.wgt;
    org = data?.org;
    dest = data?.dest;
  } else {
    pcs = data?.flight_awbs?.reduce((acc, awb) => acc + parseInt(awb.pcs), 0);
    wgt = data?.flight_awbs?.reduce((acc, awb) => acc + parseFloat(awb.wgt), 0)?.toFixed(1);
    org = data?.flight_awbs?.[0]?.org;
    dest = data?.flight_awbs?.[0]?.dest;
    remark = data?.flight_awbs?.[0]?.remark;
    if (data?.awb) {
      total_pcs = data?.awb ? data?.awb?.pcs : 0;
      if (pcs < total_pcs && total_pcs > 0) {
        part = true;
        parts.push({
          pcs,
          flight: data?.flight
        });
      }
    }
    if (data?.other_import_awbs && data?.other_import_awbs.length > 0) {
      data?.other_import_awbs.map((awb) => {
        if (awb.next) {
          pcs += awb.pcs;
          wgt += parseFloat(awb.wgt);
          parts.push({
            pcs: awb.pcs,
            flight: awb.flight
          });
        }
      });
    }
    if (part) {
      let remaining = total_pcs - pcs;
      if (remaining > 0) {
        parts.push({
          pcs: remaining,
          flight: false
        });
      }
    }
  }
  return {
    awb_no,
    pcs,
    wgt,
    total_pcs,
    part,
    parts,
    org,
    dest,
    storage_start,
    remark,
    flight_no,
    flight_arr
  };
};
