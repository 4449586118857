import React from 'react';
import {
  Box,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import styles from '../../../custom/style.module.css';
import notificationStyles from './notification.module.css';
import { CustomDialogTitle, CustomDialogContent, StyledCard, CustomInputLabel, CustomButton, StyledCardTitle, CustomSelect, StyledDataGrid, DataGridProps, CustomTextField, InputProps } from '../../../custom/components';
import { CloseIconBtn, DeleteIcon } from '../../../custom/constants';
import CostManual from './additionalserivces/CostManual';
import calculateFees from 'utils/feeCalculator';
import Number from './additionalserivces/Number';
import Kg from './additionalserivces/Kg';
import KgDay from './additionalserivces/KgDay';
import { getAwbDetails } from 'utils/import';

const AddAdditionalServicesModal = (props) => {
  const { services, data } = props;
  console.log('data', data);
  const awbData = getAwbDetails({
    ...data,
    flight: data.flight 
  });


  const renderAdditionalServiceComponent = (index, value) => {
    const { unit } = value;

    switch (unit) {
      case 'kg_per_day': return <KgDay index={index} handleChange={props.handleChanegOperation} serviceFields={props.serviceFields} serviceErrors={props.serviceErrors} awb={awbData} fee={value} />;
      case 'kg': return <Kg index={index} handleChange={props.handleChanegOperation} serviceFields={props.serviceFields} serviceErrors={props.serviceErrors} wgt={awbData?.wgt} />;
      case 'number': return <Number index={index} handleChange={props.handleChanegOperation} serviceFields={props.serviceFields} serviceErrors={props.serviceErrors} />;
      case 'cost_manual': return <CostManual index={index} handleChange={props.handleChanegOperation} serviceFields={props.serviceFields} serviceErrors={props.serviceErrors} />;
      case 'cost': return;
      case 'percent': return;
      default: return null;
    }
  };

  const columns = [
    {
      field: 'key',
      headerName: 'Fee',
      width: 300,
    },
    {
      field: 'cost',
      headerName: 'Due',
      minWidth: 100,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography variant='inherit'>$ {row.cost?.toFixed(2)}</Typography>
        );
      }
    },
    {
      field: 'add_info_value',
      headerName: 'Additional Info',
      minWidth: 100,
      sortable: false,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      sortable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          !row.default ?
            <div className={styles.actionIcons}>
              <DeleteIcon onClick={() => props.onDeleteService(row)} />
            </div>
            : ' '
        );
      }
    }
  ];


  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title'
        open={props.modal == 'additional_services'}
        onClose={props.handleClose} maxWidth={'md'} fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          <Stack direction='column'>
            <Typography variant='inherit'>Additional Services</Typography>
            <Stack direction='row' spacing={2} justifyContent={'space-between'} marginRight={8}>
              <Typography variant='body2' fontWeight={700} color={'lightgray'}>for AWB: {awbData?.awb_no} </Typography>
              <Typography variant='body2' fontWeight={700} color={'lightgray'}> {awbData.pcs} </Typography>
              <Typography variant='body2' fontWeight={700} color={'lightgray'}> {awbData.wgt} </Typography>
              <Typography variant='body2' fontWeight={700} color={'lightgray'}> {awbData.org}-{awbData.dest} </Typography>
            </Stack>
          </Stack>
          <CloseIconBtn onClick={props.handleClose} />
        </CustomDialogTitle>
        <CustomDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack direction='row' spacing={2} alignSelf='end' alignItems={'center'}>
                <Stack direction='column' spacing={0}>
                  <CustomInputLabel id='additional-services-label' required> Additional Service</CustomInputLabel>
                  <FormControl variant='standard' fullWidth >
                    <CustomSelect
                      labelId='additional-services-label'
                      fullWidth
                      name='id'
                      value={props.serviceFields.id}
                      onChange={props.handleChange}
                      sx={{ minWidth: 200 }}
                    >
                      <MenuItem value='placeholder' disabled>Select a service</MenuItem>
                      {services.map((service, index) => (
                        <MenuItem key={index} value={service.id}>{service.key}</MenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                </Stack>
              </Stack>
            </Grid>
            {
              props.serviceFields.id &&
              <Grid item xs={12}>
                <StyledCard className={styles.modalCard}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledCardTitle className={styles.pb0}>Description</StyledCardTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2' gutterBottom fontSize={12}>
                        {props.serviceFields.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      {props.serviceFields.value && props.serviceFields.value.map((value, index) => renderAdditionalServiceComponent(index, value))}
                    </Grid>
                    {props.serviceFields.add_info && <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel htmlFor='' aria-label='Enter'>Additional information to GHA</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id=''
                        fullWidth
                        name='add_info_value'
                        value={props.serviceFields.add_info_value}
                        onChange={props.handleChange}
                      />
                    </Grid>}
                    <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
                      <CustomButton variant='contained' onClick={props.onSubmitService} color='primary' className={`${styles.mlauto} ${notificationStyles.updateButton}`}>Update</CustomButton>
                    </Grid>
                  </Grid>
                </StyledCard>
              </Grid>
            }
            <Grid item xs={12}>
              <StyledCard>
                <StyledCardTitle gutterBottom >Additional Services</StyledCardTitle>
                <div className={styles.tableWrapper}>
                  <Box className={`${styles.tableOuter} ${notificationStyles.addServicesTableOuter}`}>
                    <StyledDataGrid
                      {...DataGridProps}
                      rows={data.fee && data.fee?.length > 0 ? calculateFees(data.fee || [], {
                        ...awbData,
                        flight: data.flight 
                      }).services : []}
                      columns={columns}
                      param={data?.fee?.length}
                    />
                  </Box>
                </div>

                <Stack direction='row' spacing={2} justifyContent={'flex-end'} alignItems='flex-end' mt={0.4}>
                  <Typography variant='body1' fontWeight={700} color={'primary'} mt={2}>Total</Typography>
                  <Typography variant='body1' fontWeight={700} color={'primary'} mt={2}>$ {data.fee && data.fee?.length > 0 ? calculateFees(data.fee || [], awbData)?.totalCost?.toFixed(2) : 0}</Typography>
                </Stack>
              </StyledCard>
            </Grid>
          </Grid>
        </CustomDialogContent>
      </Dialog>
    </div>
  );
};
export default AddAdditionalServicesModal;