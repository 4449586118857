import React, { createRef } from 'react';
import ImportNotification from './ImportNotification';
import { connect } from 'react-redux';
import evemts from 'utils/evemts';
import http from 'utils/http';
import cookies from 'utils/cookies';
import { cloneDeep } from 'lodash';
class ImportNotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      modal: false,
      search: '',
      anchorEl: null,
      additionalServices: {},
      importAwbs: [],
      filteredImportAwbs: [],
      modalData: false,
      houseAwbFields: {},
      houseAwbErrors: {},
      services: [],
      scale: 1.0,
      serviceFields: {},
      serviceErrors: {},
      activeAWB: null,
      docList: [],
      activeDoc: null,
      viewPreview: '',
      docErrors: {},
      addDocType: 'AWB',
      attachTo: 'OTHER',
      docFields: {},
      columnWidth: {},
      consignees: [],
      selectedConsignee: null,
      consigneeFields: {
        name: '',
        address: '',
        address2: '',
        state: '',
        city: '',
        zip: '',
        email: '',
        phone: '',
        mails: [],
      },
      consigneeOperation: '',
      consigneeSearch: '',
      consigneRow: {},
      lastConsigneeRequest: 0,
      collapseViewer: [],
      popover: false,
      popoverAnchorEl: null,
      popoverData: false,
      editHouseAwb: false,
    };
    this.eventSource = null;
    this.columnRef = {
      id: createRef(),
      radio_btn: createRef(),
      awb_no: createRef(),
      pcs: createRef(),
      wgt: createRef(),
      org_dest: createRef(),
      commodity: createRef(),
      storage_start: createRef(),
      fee: createRef(),
      add_icon: createRef(),
      consignee: createRef(),
      action: createRef(),
    };
  }

  componentDidMount() {
    this.fetchHolidays();
    this.updateColumnWidth();
    this.fetchServices();
    if (this.props?.flightTab) {
      if (this.props.flight) {
        this.fetchImportNotification(this.props.flight.id);
        this.handleEvent(this.props.flight.id);
      }
    } else {
      this.handleEvent();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props?.flightTab && prevProps.flight?.id !== this.props.flight?.id && this.props.flight?.id) {
      this.eventSource && this.eventSource.close();
      this.handleEvent(this.props.flight.id);
      this.fetchImportNotification(this.props.flight.id);
    }

    Object.keys(this.columnRef).forEach((key) => {
      if (
        this.columnRef[key].current &&
        prevState.columnWidth[key] !== this.columnRef[key].current.offsetWidth
      ) {
        this.updateColumnWidth();
      }
    });

  }

  componentWillUnmount() {
    this.eventSource && this.eventSource.close();
  }

  fetchHolidays = () => {
    http.get('settings?key=holidays').then(({ data }) => {
      if (data.data) {
        let holidays = data.data.find((item) => item.key === 'holidays');
        if (holidays) {
          cookies.set('holidays', holidays?.value || []);
        }
      }
    });
  };

  fetchImportNotification = (id = null) => {
    let search = this.state.search && this.state.search?.replaceAll(' ', '') !== '' ? '&search=' + this.state.search : '';
    if (!this.props.flightTab && search === '') {
      this.setState({
        importAwbs: [],
        activeAWB: null,
        docList: [],
        activeDoc: null,
        viewPreview: '',
      }, () => {
        this.filterImportNotification();
      });
      return;
    }
    http.get('import_awb?flight_id=' + id + search).then(({ data }) => {
      if (data.data.length > 0) {
        this.setState({
          importAwbs: data.data,
          activeAWB: data.data[0]
        }, () => {
          this.filterImportNotification();
        });
      } else {
        this.setState({
          importAwbs: [],
          activeAWB: null,
          docList: [],
          activeDoc: null,
          viewPreview: '',
        }, () => {
          this.filterImportNotification();
        });
      }
    });
  };

  filterImportNotification = () => {
    let importAwbs = this.state.importAwbs;
    if (this.state.filter.transit) {
      importAwbs = importAwbs.filter((awb) => {
        const dest = awb.flight_awbs?.[0]?.dest;
        return dest !== cookies.get('station_code');
      });
    }
    if (this.state.filter.awb_no) {
      importAwbs = importAwbs.filter((awb) => {
        const awb_no = awb.flight_awbs?.[0]?.awb_no;
        return awb_no === this.state.filter.awb_no;
      });
    }
    this.setState({ filteredImportAwbs: importAwbs }, () => {
      this.fetchDocs(importAwbs[0]);
    });
  };

  //get awbdata and update or add to list.
  getSingleImportNotification = (id) => {
    return http.get('import_awb/' + id).then(({ data }) => {
      const newRecord = data.data && data.data.length > 0 ? data.data[0] : null;
      return new Promise((resolve) => {
        this.setState((prevState) => {
          // Create new copy of importAwbs array
          let importAwbs = [...prevState.importAwbs];
          const existingIndex = importAwbs.findIndex((awb) => awb.id === newRecord.id);

          if (existingIndex !== -1) {
            importAwbs = importAwbs.map((awb, index) =>
              index === existingIndex ? {
                ...awb,
                ...newRecord,
                consignee: {
                  ...awb.consignee,
                  ...newRecord.consignee
                }
              } : awb
            );
          }
          // else {
          //   importAwbs.push(newRecord);
          // }

          // Update activeAWB if it matches
          let activeAWB = prevState.activeAWB;
          if (activeAWB?.id === newRecord.id) {
            activeAWB = {
              ...activeAWB,
              ...newRecord,
              consignee: {
                ...activeAWB.consignee,
                ...newRecord.consignee
              }
            };
          }

          return {
            importAwbs,
            activeAWB,
            filteredImportAwbs: importAwbs
          };
        }, resolve);
      });
    });
  };

  handleEvent = (id = null) => {
    this.eventSource = evemts('import', id);
    this.eventSource.onmessage = (e) => {
      const msg = JSON.parse(e.data);
      if (msg.action === 'add' && id) {
        this.fetchImportNotification(id);
      }
      if (msg.action === 'update' && !msg.data.house_awbId) {
        let index = this.state.importAwbs.findIndex((awb) => awb.id === msg.data.id);
        let importAwbs = [...this.state.importAwbs];
        importAwbs[index] = {
          ...importAwbs[index],
          ...msg.data
        };
        this.setState({ importAwbs }, () => {
          this.filterImportNotification();
        });
        if (this.state.modalData?.id === msg.data.id) {
          this.setState({ modalData: importAwbs[index] });
        }
      }
      if (msg.action === 'update' && msg.data.house_awbId) {
        let index = this.state.importAwbs.findIndex((awb) => awb.id === msg.data.house_awbId);
        let importAwbs = [...this.state.importAwbs];
        let house_awbs = importAwbs[index].house_awbs || [];
        let houseIndex = house_awbs.findIndex((house) => house.id === msg.data.id);
        house_awbs[houseIndex] = {
          ...house_awbs[houseIndex],
          ...msg.data
        };
        importAwbs[index] = {
          ...importAwbs[index],
          house_awbs
        };
        this.setState({ importAwbs }, () => {
          this.filterImportNotification();
        });
        if (this.state.modalData?.id === msg.data.id) {
          this.setState({ modalData: importAwbs[index] });
        }
      }

      if (msg.action === 'delete') {
        let importAwbs = this.state.importAwbs.filter((awb) => awb.id !== msg.data.id);
        this.setState({ importAwbs }, () => {
          this.filterImportNotification();
        });
      }


      if (msg.action === 'house_awb') {
        let index = this.state.importAwbs.findIndex((awb) => awb.id === msg.data.id);
        if (index !== -1) {
          let importAwbs = [...this.state.importAwbs];
          let house_awbs = msg.data?.house_awbs || [];
          importAwbs[index] = {
            ...importAwbs[index],
            house_awbs
          };
          this.setState({ importAwbs }, () => {
            this.filterImportNotification();
          });
        }
      }
      if (msg.action === 'notification') {
        let index = this.state.importAwbs.findIndex((awb) => awb.id === msg.data.import_awbId);
        if (index !== -1) {
          let importAwbs = [...this.state.importAwbs];
          importAwbs[index] = {
            ...importAwbs[index],
            notifications: [
              msg.data,
              ...importAwbs[index].notifications,
            ]
          };
          this.setState({ importAwbs }, () => {
            this.filterImportNotification();
          });
        }
      }
      if (msg.action === 'assign_consignee') {
        this.getSingleImportNotification(msg?.data?.parentAwbid || msg.data.id);
      }
      if (msg.action === 'add_doc') {
        let activeAWB = this.state.activeAWB;
        if (parseInt(activeAWB?.id) === parseInt(msg.data.id)) {
          this.fetchDocs(activeAWB);
        }
      }
      if (msg.action === 'add_awb_dock') {
        let activeAWB = this.state.activeAWB;
        this.getSingleImportNotification(msg.data.id)
          .then(() => {
            if (parseInt(activeAWB?.id) === parseInt(msg.data.id)) {
              this.fetchDocs(this.state.activeAWB);
            }
          });
      }
    };
  };


  handleOpen = (modal, anchorEl = null, modalData = false) => {
    this.setState({
      modal,
      anchorEl,
      modalData
    });
  };
  handlePopoverOpen = (popover, popoverAnchorEl = null, popoverData = false) => {
    this.setState({
      popover,
      popoverAnchorEl,
      popoverData
    });
  };

  handlePopoverClose = () => {
    this.setState({
      popover: false,
      popoverAnchorEl: null,
      popoverData: false,
    });
  };


  handleClose = () => {
    this.setState({
      modal: false,
      anchorEl: null,
      modalData: false,
      docFields: { file: '' },
      serviceFields: {},
      serviceErrors: {},
      //clear modal data
      consigneeOperation: '',
      consigneeSearch: '',
      consignees: [],
      selectedConsignee: null,
      consigneRow: {},
      consigneeFields: {
        name: '',
        address: '',
        address2: '',
        state: '',
        city: '',
        zip: '',
        email: '',
        phone: '',
        mails: [],
      },
      editHouseAwb: false,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    let data = {};
    if (name == 'id') {
      let service = this.state.services.find((service) => service.id == value);
      if (service) {
        let existing = this.state.modalData?.fee?.find((fee) => fee.id == value);
        if (existing) {
          data = { add_info_value: existing.add_info_value };
          service.value = service.value.map((v, index) => {
            let existingValue = existing.value?.[index];
            if (existingValue) {
              return {
                ...existingValue,
                ...v,
              };
            }
            return v;
          });
        }
        this.setState(() => ({
          serviceFields: {
            ...service,
            ...data,
          }
        }));
      }
    } else {
      this.setState((prev) => ({
        serviceFields: {
          ...(prev.serviceFields),
          [name]: value,
          ...data
        }
      }));
    }
  };

  handleChanegOperation = (index, e) => {
    const { name, value } = e.target;
    let serviceFields = { ...this.state.serviceFields };
    if (serviceFields.value?.[index]) {
      serviceFields.value[index] = {
        ...serviceFields.value[index],
        [name]: value
      };
    }
    this.setState({ serviceFields });
  };

  validateService = () => {
    let errors = {};
    let isValid = true;
    if (!this.state.serviceFields.id) {
      errors.id = 'Service is required';
      isValid = false;
    }
    this.state.serviceFields.value?.forEach((value, index) => {
      if (value.unit == 'number' && !value.unit_value) {
        errors.value = errors.value || [];
        errors.value[index] = {
          ...errors.value[index],
          unit_value: 'Unit is required'
        };
        isValid = false;
      }
      if (value.unit == 'kg' && !value.cost_per_unit) {
        errors.value = errors.value || [];
        errors.value[index] = {
          ...errors.value[index],
          cost_per_unit: 'Cost is required'
        };
        isValid = false;
      }
    });
    this.setState({ serviceErrors: errors });
    return isValid;
  };


  onSubmitService = () => {
    if (!this.validateService()) {
      return;
    }
    http.post('import_awb/service/' + this.state.modalData.id, { fee: this.state.serviceFields });
  };

  onDeleteService = (fee) => {
    http.delete('import_awb/service/' + this.state.modalData.id + '/' + fee.id);
  };

  fetchServices = () => {
    http.get('additional_services').then((res) => {
      if (res.data) {
        this.setState({ services: res.data?.data || [] });
      }
    });
  };

  zoomIn = () => {
    this.setState((prevState) => ({ scale: prevState.scale + 0.2 }));
  };

  zoomOut = () => {
    this.setState((prevState) => ({ scale: Math.max(0.5, prevState.scale - 0.2) }));
  };

  partNotification = (id, next) => {
    http.put(`import_awb/${id}`, { next }).then(() => {
      this.handleClose();
    });
  };


  onChangeHouseAwb = (e) => {
    let { name, value } = e.target;
    let fields = {};
    if (name === 'o_d') {
      if(value.length > 3) {
        let inputValue = value.replace(/-/g, '');
        value = inputValue.slice(0, 3) + '-' + inputValue.slice(3);
      }
      if (value.length > 7) {
        return;
      }

      let [org, dest] = value.split('-');
      fields = {
        org,
        dest
      };
      if(fields.org && fields.dest) {
        fields.org = fields.org.toUpperCase();
        fields.dest= fields.dest.toUpperCase();
      }
    }
    if (name === 'house_awb_no') {
      if(value.length > 3) {
        let inputValue = value.replace(/-/g, '');
        value = inputValue.slice(0, 3) + '-' + inputValue.slice(3);
      }
    }
    this.setState((prev) => ({
      houseAwbFields: {
        ...prev.houseAwbFields,
        ...fields,
        [name]: name === 'o_d' ? value?.toUpperCase() : value
      }
    }));
  };
  validateHouseAwb = () => {
    let errors = {};
    let isValid = true;
    if (!this.state.houseAwbFields.house_awb_no) {
      errors.house_awb_no = 'House Awb# is required';
      isValid = false;
    }
    if (!this.state.houseAwbFields.pcs) {
      errors.pcs = 'Pieces is required';
      isValid = false;
    }
    if (!this.state.houseAwbFields.wgt) {
      errors.wgt = 'Weight is required';
      isValid = false;
    }
    if (!this.state.houseAwbFields.o_d) {
      errors.o_d = 'O-D is required';
      isValid = false;
    } else {
      let [org, dest] = this.state.houseAwbFields.o_d.split('-');
      if (!org || !dest) {
        errors.o_d = 'Invalid O-D';
        isValid = false;
      } else {
        if (org.length !== 3 || dest.length !== 3) {
          errors.o_d = 'Invalid O-D';
          isValid = false;
        }
      }
    }
    this.setState({ houseAwbErrors: errors });
    return isValid;
  };

  addHouseAwb = () => {
    if (!this.validateHouseAwb()) {
      return;
    }
    let house_awbs = [...this.state.modalData?.house_awbs || []];
    if (this.state.houseAwbFields.id) {
      let index = house_awbs.findIndex((house) => house.id === this.state.houseAwbFields.id);
      if (index !== -1)
        house_awbs[index] = this.state.houseAwbFields;
    } else {
      house_awbs.push(this.state.houseAwbFields);
    }
    this.setState({
      modalData: {
        ...this.state.modalData,
        house_awbs
      },
      houseAwbFields: {},
      houseAwbErrors: {}
    }, () => { this.submitHouseAwb('add'); });
  };

  onEditHouseAwb = (house_awb) => {
    let house_awbs = [...this.state.modalData?.house_awbs || []];
    if (!house_awb.id) {
      let index = house_awbs.findIndex((house) => JSON.stringify(house) === JSON.stringify(house_awb));
      if (index !== -1)
        house_awbs.splice(index, 1);
    }
    this.setState({
      modalData: {
        ...this.state.modalData,
        house_awbs
      },
      houseAwbFields: {
        ...house_awb || {},
        o_d: house_awb.org + '-' + house_awb.dest
      },
      houseAwbErrors: {},
      editHouseAwb: true
    });
  };


  onDeleteHouseAwb = (row) => {
    console.log('onDeleteHouseAwb', row); //row.id
    let house_awbs = [...this.state.modalData?.house_awbs || []];
    if (row.id) {
      let index = house_awbs.findIndex((house) => house.id === row.id);
      if (index !== -1) {
        house_awbs[index].deleted = true;
        // house_awbs.splice(index, 1);
      }
    } else {
      let index = house_awbs.findIndex((house) => JSON.stringify(house) === JSON.stringify(row));
      if (index !== -1)
        house_awbs[index].deleted = true;
        // house_awbs.splice(index, 1);
    }
    console.log('house_awbs', house_awbs);
    this.setState({
      modalData: {
        ...this.state.modalData,
        house_awbs
      }
    }
    , () => {
      this.handlePopoverClose();
    }
    );
  };


  submitHouseAwb = (type = null) => {
    http.post('import_house_awb', {
      id: this.state.modalData.id,
      house_awbs: this.state.modalData.house_awbs
    }
    ).then(() => {
      this.setState({
        modal: type === 'add' ? 'add_house_awb' : false,
        anchorEl: null,
        modalData: type === 'add' ? this.state.modalData : false,
        editHouseAwb: false,
      });
    });
  };

  handleNotification = (row) => {
    if (row.consigneeId) {
      http.post('import_noti_awb', {
        awb_id: row.awbId,
        consignee_id: row.consigneeId,
        id: row.id
      }).then(({ data }) => {
        this.setState({
          modal: 'send_notification',
          modalData: {
            ...row,
            ...data.data
          }
        });
      });
    }
  };

  handleSubmitNotification = (e) => {
    e.preventDefault();
    let docs = [];
    if (e.target.docs) {
      if (e.target.docs.length === undefined) {
        if (e.target.docs.checked)
          docs.push(e.target.docs.value);
      } else {
        for (let i = 0; i < e.target.docs.length; i++) {
          console.log(e.target.docs[i]);
          if (e.target.docs[i].checked)
            docs.push(e.target.docs[i].value);
        }
      }
    }

    let data = {
      id: this.state.modalData.id,
      flightId: this.state.modalData.flightId,
      awbId: this.state.modalData.awbId,
      consigneeId: this.state.modalData.consigneeId,
      mails: this.state.modalData.mails,
      cc: e.target.cc.value,
      subject: e.target.subject.value,
      message: e.target.message_body.value + '\n\n' + e.target.message_footer.value,
      docs
    };
    http.post('import_awb_notification', data).then(() => {
      this.handleClose();
    });
  };


  fetchDocs = (row) => {
    console.log('fetch Docs', row);
    if (row?.awbId) {
      http.get('doc_awb/' + row.awbId).then(({ data }) => {
        console.log(data);
        this.handleTableCollapse(null);
        if (data.data.length > 0) {
          this.setState({
            activeAWB: row,
            docList: data.data,
            viewPreview: '',
          }, () => {
            this.selectDoc(null, data
              .data[0] ? data.data[0] : null);
          });
        } else {
          this.setState({
            activeAWB: row,
            docList: [],
            activeDoc: null,
            viewPreview: '',
          },);
        }
      });
    } else {
      this.setState({
        activeAWB: row,
        docList: [],
        activeDoc: null,
        viewPreview: '',
      });
    }
  };

  onChangeFilter = (e) => {
    let { name, value } = e.target;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }
    this.setState((prev) => ({
      filter: {
        ...prev.filter,
        [name]: value
      }
    }), () => {
      this.filterImportNotification();
    });
  };

  onChangeSearch = (e) => {
    if (e.target.name == 'search_click') {
      this.fetchImportNotification();
    } else {
      this.setState({ search: e.target.value });
    }
  };


  selectDoc = (e, row) => {
    console.log('select Doc', e?.target?.value, row);
    const item = this.state.docList.find((doc) => {
      return doc.id == e?.target?.value ? e.target.value : row.id;
    });
    let activeDoc = e?.target?.value ? e.target.value : row.id;
    console.log(item);
    this.setState({
      activeDoc: activeDoc,
      viewPreview: item.src
    });
  };

  validateDoc = () => {
    const docFields = { ...this.state.docFields };
    const error = {};
    let formValid = true;
    if (!docFields.name || (docFields.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter name.';
    }
    if (!docFields.file) {
      formValid = false;
      error.remark = 'Please select file.';
    }
    // if (docFields.file && docFields.file.size > 1000000) {
    //   formValid = false;
    //   error.remark = 'File size cannot be more than 1mb';
    // }
    this.setState({ docErrors: error });
    return formValid;
  };

  onChangeDoc = (e) => {
    let value = e.target.value;
    this.setState({
      docFields: {
        ...this.state.docFields,
        [e.target.name]: e.target.name == 'file' ? e.target.files[0] : value
      }
    });
  };

  onhandleChange = (e) => {
    let state = {};
    if (e.target.name == 'fileType') {
      if (e.target.value == 'third') {
        state['docFields'] = {
          name: '',
          type: 'AWB',
          contact_type: 'sms',
        };
      }
    } else {
      state['docFields'] = {};
    }
    state['docErrors'] = {};
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
      ...state
    });
  };

  addDoc = (e) => {
    console.log('addDoc', e);
    e.preventDefault();
    if (this.validateDoc()) {
      const formData = new FormData();
      formData.append('importawb_id', this.state.activeAWB.id);
      formData.append('awb_id', this.state.activeAWB.awbId);
      formData.append('type', this.state.addDocType);
      formData.append('attach_to', this.state.attachTo);
      formData.append('verifiedUserId', cookies.get('user'));
      formData.append('flightId', this.state.activeAWB.flightId);
      formData.append('row', JSON.stringify(this.state.activeAWB));
      Object.keys(this.state.docFields).forEach(key => {
        formData.append(key, this.state.docFields[key]);
      });
      http.post('/doc_awb/add', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            docFields: {},
            docFieldsErrors: {},
          });
        }
      });
    }
  };

  updateColumnWidth = () => {
    let columnWidth = {};
    for (let key in this.columnRef) {
      if (this.columnRef[key].current) {
        columnWidth[key] = this.columnRef[key].current.offsetWidth;
      }
    }
    if (JSON.stringify(this.state.columnWidth) !== JSON.stringify(columnWidth)) {
      this.setState({ columnWidth: columnWidth });
    }
  };
  openConsigneeModal = (e, row) => {
    console.log('row?.consignee', row);
    e.preventDefault();
    console.log('row.awb.consignee', row);
    this.setState({
      modal: 'update_consignee',
      consigneeSearch: row?.consigneeId ? row?.consignee?.name : row?.awb?.consignee || '',
      selectedConsignee: row?.consignee || null,
      consigneeFields: row?.consignee ? cloneDeep(row?.consignee) : {},
      consigneRow: row,
    }, () => {
      this.fetchConsignee();
    });
  };

  fetchConsignee = () => {
    const requestTimestamp = Date.now();
    this.setState({ lastConsigneeRequest: requestTimestamp });

    if (this.state.consigneeSearch === '') {
      this.setState({ consignees: [] });
    } else {
      http.get('consignee?search=' + this.state.consigneeSearch, { headers: { hideloader: true } })
        .then((res) => {
          if (this.state.lastConsigneeRequest === requestTimestamp) {
            if (res.data) {
              this.setState({ consignees: res.data?.data || [] });
            }
          } else {
            console.log('delayed response...');
          }
        });
    }
  };

  onSelectedConsignee = (e) => {
    console.log('onSelectedConsignee', e.target.value);
    let selectedConsignee = this.state.consignees.find((consignee) => consignee.id == e.target.value);
    console.log('selectedConsignee', selectedConsignee);
    this.setState({
      selectedConsignee,
      consigneeFields: cloneDeep(selectedConsignee),
      consigneeOperation: 'disabled'
    });
  };

  addNewButtonHandler = () => {
    // clear consignee fields
    this.setState({
      consigneeFields: {
        name: '',
        address: '',
        address2: '',
        state: '',
        city: '',
        zip: '',
        email: '',
        phone: '',
        mails: [],
      },
      selectedConsignee: null,
      consigneeOperation: 'add'
    });
  };

  onConsigneeFieldsChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      consigneeFields: {
        ...this.state.consigneeFields,
        [name]: value
      }
    });
  };

  onConsigneeMailChange = (e, index) => {
    console.log('onConsigneeMailChange', e.target.value, index);

    // this.setState({
    //   consigneeFields: {
    //     ...this.state.consigneeFields,
    //     mails
    //   }
    // });
    //this.state.consigneeFields.mails[index].email = e.target.value
    this.setState({
      consigneeFields: {
        ...this.state.consigneeFields,
        mails: this.state.consigneeFields.mails.map((mail, i) => {
          if (i === index) {
            return {
              ...mail,
              email: e.target.value
            };
          }
          return mail;
        })
      }

    });

  };

  onAddCMail = () => {
    this.setState({
      consigneeFields: {
        ...this.state.consigneeFields,
        mails: [...this.state.consigneeFields.mails || [], {
          companyId: null,
          stationId: null,
          consigneeId: this.state.consigneeFields.id,
          deleted: false,
          email: '',
          failed: false,
          id: null
        }]
      }
    });
  };
  onDeleteCMail = (index) => {
    let mails = [...this.state.consigneeFields.mails];
    mails[index].deleted = true;
    this.setState({
      consigneeFields: {
        ...this.state.consigneeFields,
        mails,
      },
    });
  };

  addConsignee = (e) => {
    e.preventDefault();
    let phonearr = this.state.consigneeFields.phone.match(/^(\+?(\d{1,4}))(\d{10,})$/);
    let phone_code = phonearr[1];
    let phone = phonearr[3];
    http.post('consignee', {
      ...this.state.consigneeFields,
      phone: phone_code + phone,
      phone_code,
      existing: [],
      exist: true
    }).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          modal: false,
          selectedConsignee: data.data
        }, () => {
          this.assignConsignee();
        });
      } else {
        console.log(data);
        if (data.message?.includes('already exists')) {
          this.setState({
            consigneeFields: {
              ...this.state.consigneeFields,
              exist: true,
              existing: data.data
            }
          });
        }
      }
    }).catch((err) => {
      if (err?.response?.data?.message?.includes('already exists')) {
        this.setState({
          consigneeFields: {
            ...this.state.consigneeFields,
            exist: true,
            existing: err?.response?.data?.data
          }
        });
      }
    });
  };

  updateConsignee = (e) => {
    e.preventDefault();
    let phonearr = this.state.consigneeFields.phone.match(/^(\+?(\d{1,4}))(\d{10,})$/);
    let phone_code = phonearr[1];
    let phone = phonearr[3];
    console.log('this.state.consigneeFields', this.state.consigneeFields);
    http.put('consignee/' + this.state.consigneeFields.id, {
      ...this.state.consigneeFields,
      phone: phone_code + phone,
      phone_code
    }).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({ modal: false }, () => {
          this.assignConsignee();
        });
      }
    });
  };

  editConsignee = (consigneeOperation) => {
    this.setState({ consigneeOperation });
  };

  onConsigneeSearch = (e) => {
    // clearTimeout(this.consigneeSearchTimer);
    const value = e.target.value;
    this.setState({ consigneeSearch: value }, () => {
      this.fetchConsignee();
      // this.consigneeSearchTimer = setTimeout(() => {
      //   this.fetchConsignee();
      // }, 0);
    });
  };

  handleassignConsignee = (e, action) => {
    console.log('handleassignConsignee', e, action);
    console.log('this.state.activeDoc', this.state.activeDoc);
    console.log('activeAWB', this.state.activeAWB);
    //if action === 'Assign' call assignConsignee
    if (action === 'Assign') {
      this.assignConsignee();
    }
    //else if action === 'Add' call addConsignee
    else if (action === 'Add') {
      this.addConsignee(e);
    }
    //else if action === 'Update' call updateConsignee
    else if (action === 'Update') {
      this.updateConsignee(e);
    }
    //else do nothing
  };

  assignConsignee = () => {
    //code to assign consignee to awb
    let formdata = {
      consigneeId: this.state.selectedConsignee.id,
      flightId: this.state.activeAWB.flightId,
      parentAwbid: this.state.activeAWB.id,
    };
    http.put('assign_consingnee/' + this.state.consigneRow?.id, formdata).then(() => {
      this.handleClose();
    });
  };

  handleTableCollapse = (id = null) => {
    let collapseViewer = this.state.collapseViewer || [];
    if(!id){
      collapseViewer = [];
    }
    if(collapseViewer?.includes(id)) {
      collapseViewer = collapseViewer.filter(item => item === id);
      const index = collapseViewer?.indexOf(id);
      if (index > -1) { 
        collapseViewer?.splice(index, 1);
      }
    } else {
      collapseViewer.push(id);
    }
    this.setState({ collapseViewer });  
  };

  render() {
    return (
      <>
        <ImportNotification
          search={this.state.search}
          onChangeSearch={this.onChangeSearch}
          filter={this.state.filter}
          onChangeFilter={this.onChangeFilter}
          flightTab={this.props.flightTab}
          importAwbs={this.state.filteredImportAwbs}
          modal={this.state.modal}
          modalData={this.state.modalData}
          anchorEl={this.state.anchorEl}
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          handleChange={this.handleChange}
          serviceFields={this.state.serviceFields}
          serviceErrors={this.state.serviceErrors}
          submitHouseAwb={this.submitHouseAwb}
          zoomIn={this.zoomIn}
          zoomOut={this.zoomOut}
          scale={this.state.scale}
          partNotification={this.partNotification}
          onChangeHouseAwb={this.onChangeHouseAwb}
          onEditHouseAwb={this.onEditHouseAwb}
          onDeleteHouseAwb={this.onDeleteHouseAwb}
          houseAwbFields={this.state.houseAwbFields}
          houseAwbErrors={this.state.houseAwbErrors}
          addHouseAwb={this.addHouseAwb}
          handleNotification={this.handleNotification}
          handleSubmitNotification={this.handleSubmitNotification}
          services={this.state.services}
          additionalServices={this.state.additionalServices}
          handleChanegOperation={this.handleChanegOperation}
          onSubmitService={this.onSubmitService}
          onDeleteService={this.onDeleteService}
          fetchDocs={this.fetchDocs}
          activeAWB={this.state.activeAWB}
          docList={this.state.docList}
          activeDoc={this.state.activeDoc}
          selectDoc={this.selectDoc}
          viewPreview={this.state.viewPreview}
          docErrors={this.state.docErrors}
          addDoc={this.addDoc}
          onhandleChange={this.onhandleChange}
          addDocType={this.state.addDocType}
          attachTo={this.state.attachTo}
          docfields={this.state.docFields}
          onChangeDoc={this.onChangeDoc}
          columnWidth={this.state.columnWidth}
          columnRef={this.columnRef}
          openConsigneeModal={this.openConsigneeModal}
          consignees={this.state.consignees}
          consigneeFields={this.state.consigneeFields}
          addNewButtonHandler={this.addNewButtonHandler}
          onSelectedConsignee={this.onSelectedConsignee}
          selectedConsignee={this.state.selectedConsignee}
          consigneeOperation={this.state.consigneeOperation}
          consigneeSearch={this.state.consigneeSearch}
          onConsigneeSearch={this.onConsigneeSearch}
          handleassignConsignee={this.handleassignConsignee}
          editConsignee={this.editConsignee}
          onConsigneeFieldsChange={this.onConsigneeFieldsChange}
          onAddCMail={this.onAddCMail}
          onDeleteCMail={this.onDeleteCMail}
          onConsigneeMailChange={this.onConsigneeMailChange}
          handleTableCollapse={this.handleTableCollapse}
          collapseViewer={this.state.collapseViewer}
          handlePopoverOpen={this.handlePopoverOpen}
          handlePopoverClose={this.handlePopoverClose}
          popover={this.state.popover}
          popoverAnchorEl={this.state.popoverAnchorEl}
          popoverData={this.state.popoverData}
          editHouseAwb={this.state.editHouseAwb}

        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { flight: state.flight, };
};

export default connect(mapStateToProps, null)(ImportNotificationContainer);
