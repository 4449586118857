import React, { Fragment } from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from '../../../../../custom/style.module.css';
import { Grid } from '@mui/material';
import { CustomTab } from '../../../../../custom/components';
import LoadingAccessoriesContainer from './tabs/loadingAccessories/LoadingAccessoriesContainer';
import DollyTareContainer from './tabs/dollyTare/DollyTareContainer';

const ExportOperations = (props) => {
  const { value } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(path) {
    const index = path.replace('admin/export/', '');
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Tabs value={value} onChange={(_, v) => props.handleChange(v)}
            ref={props.elementRef}
            className={`${styles.customTabs} ${styles.dFlex}`}
            // variant='scrollable'
            variant='fullWidth'
            aria-label='Flight tabs'
            indicatorColor='transparent'
            classes={{ scroller: styles.tabFlexContainer }}
          >
            <CustomTab label={'Loading Accessories'} {...a11yProps('loading_acc')} />
            <CustomTab label={'Dolly Tare'} {...a11yProps('dolly_tare')} />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <TabPanel value={value} index={'loading_acc'}>
            <LoadingAccessoriesContainer />
          </TabPanel>
          <TabPanel value={value} index={'dolly_tare'}>
            <DollyTareContainer />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default ExportOperations;
