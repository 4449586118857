import { Button, Grid, Switch } from '@mui/material';
import { CustomDatePicker, InputProps } from 'custom/components';
import dayjs from 'dayjs';
import React from 'react';

const Developer = (props) => {
  const getLable = (key) => {
    switch (key) {
      case 'ffm_flight_no':
        return 'FFM Flight No';
      case 'ffm_flight_date':
        return 'FFM Flight Date';
      case 'ffm_edit':
        return 'FFM Edit';
      case 'buildup':
        return 'Buildup Tab && Loading Accessories';
      case 'import':
        return 'Import Notification';
      default:
        return key;
    }
  };
  return (
    <Grid container spacing={5} padding={5}>
      <Grid item xs={12}>
        <div>
          <h1>Flight Folder (Company Level)</h1>
          {props.config.map((item, index) => {
            if (item.companyId & !item.stationId)
              return (
                <div key={index}>
                  <h3>{getLable(item.key)} <Switch name={item.id} defaultChecked={item?.value?.data ? item?.value?.data === 'true' : false} onChange={props.onChange} /></h3>
                </div>);
          })}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>
          <h1>Flight Data API (Global)</h1>
          {props.stations.map((item, index) => {
            return (
              <div key={index}>
                <h3>{getLable(item)} <Switch name={item} defaultChecked={props.config.find((c) => c.key === 'flight_api' && c.value && c.value?.includes(item)) ? true : false} onChange={props.onChangeStation} /></h3>
              </div>);
          })}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>
          <h1>Authorized Users</h1>
          {props.userList.map((item, index) => {
            return (
              <ul key={index}>
                <li>{item.username}</li>
              </ul>);
          })}
        </div>
      </Grid>
      <Grid item xs={12}>
        <div>
          <h1>Import From Production</h1>
          <h3>Admin</h3>
          <ul>
            <li><h4>Airlines</h4> <Button size='small' variant='contained' onClick={props.importAirlines}>Import</Button> <br /> status: {props.progress.airlines}</li>
            <li><h4>Products </h4> <Button size='small' variant='contained' onClick={props.importProducts}>Import</Button> <br /> status: {props.progress.products}</li>
            <li><h4>Checklists </h4> <Button size='small' variant='contained' onClick={props.importChecklists}>Import</Button> <br /> status: {props.progress.checklists}</li>
            <li><h4>Contracts </h4> <Button size='small' variant='contained' onClick={props.importContracts}>Import</Button> <br /> status: {props.progress.contracts}</li>
          </ul>

          <h3>Flights</h3>
          <ul>
            <li style={{ width: '150px' }}>
              Date:
              <CustomDatePicker
                slotProps={{
                  textField: {
                    ...InputProps,
                    placeholder: 'Date',
                    fullWidth: true
                  },
                }}
                format='DD MMM YYYY'
                name='date'
                value={dayjs(props.searchOptions.date)}
                onChange={(e) => props.handleChange(e, 'date')}
              />
              <Button size='small' variant='contained' onClick={props.importFlights}>Import</Button> <br /> status: {props.progress.flightInfo}</li>
          </ul>
        </div>
      </Grid>
    </Grid>
  );
};

export default Developer;