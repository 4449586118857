import React, { Fragment } from 'react';
import {
  Stack, Grid,
  Box,
  Button,
  IconButton,
  Typography,
  InputAdornment,
  Checkbox,
  Radio,
  RadioGroup,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Badge,
} from '@mui/material';
import {
  StyledCard, StyledCardTitle,
  TableBadge,
  InputProps,
  CustomCheckbox,
  CustomSearch,
  CustomRadio,
  StyledTableContainer,
} from '../../../custom/components';
import { AddIcon, BinoIcon, DateFormat, DocIcon, DownloadIcon, EditIcon } from '../../../custom/constants';
import notificationStyles from './notification.module.css';
import styles from '../../../custom/style.module.css';
import SendIcon from '../../../assets/images/actionIcons/paperPlane.svg';
import { ArrowLeft, ArrowRight, KeyboardArrowDown, KeyboardArrowUp, Search } from '@mui/icons-material';
import AddHouseAwbModal from './AddHouseAwbModal';
import UpdateConsigneeModal from './UpdateConsigneeModal';
import AddAdditionalServicesModal from './AddAdditionalServicesModal';
import Slider from 'react-slick';
import SendNotificationModal from './SendNotificationModal';
import NotificationHistoryModal from './NotificationHistoryModal';
// import dummyPDF from '../../../assets/images/dummyPDF.pdf';
import ZoomIn from '../../../assets/images/actionIcons/zoomIn.svg';
import ZoomOut from '../../../assets/images/actionIcons/zoomOut.svg';
import PartArrivalInfo from './PartArrivalInfo';
import AddDocModal from './AddDocModal';
import PDFSlider from './PDFSlider';
import calculateFees, { getFee } from 'utils/feeCalculator';
import environment from '../../../custom/environments';
import { getAwbDetails } from 'utils/import';
import moment from 'moment';
import { grey } from '@mui/material/colors';

const NextArrow = (props) => {
  const { newClass, onClick } = props;
  // const isDisabled = currentSlide === slideCount - 3;

  return (
    <IconButton
      aria-label='Next'
      onClick={onClick}
      className={newClass}
      classes={{ root: notificationStyles.sliderRightArrow }}
      disableRipple
    >
      <ArrowRight fontSize='inherit' color={'secondary'} />
    </IconButton>
  );
};

const PrevArrow = (props) => {
  const { newClass, onClick, currentSlide } = props;
  const isDisabled = currentSlide === 0;
  return (
    <IconButton
      aria-label='Prev'
      onClick={onClick}
      className={newClass}
      classes={{ root: notificationStyles.sliderLeftArrow }}
      disableRipple
    >
      <ArrowLeft color={isDisabled ? 'disabled' : 'secondary'} fontSize='inherit' />
    </IconButton>
  );
};

const settings = {
  infinite: false,
  dots: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
  ]
};

const ImportNotification = (props) => {
  const getStatusColor = (mail) => {
    if (mail?.some((item) => item.status === 'pending')) {
      return 'warning';
    } else if (mail?.some((item) => item.status === 'failed')) {
      return 'error';
    } else if (mail?.every((item) => item.status === 'delivered')) {
      return 'success';
    } else {
      return '';
    }
  };

  const DocViewer =  <StyledCard className={`${styles.h100} ${styles.positionRelative} ${notificationStyles.accordionDocViewer}`}
    sx={{
      margin: 'auto',
      width: {
        xs: 400,
        lg: 600,
        xl: '100%',
      },
    }}
  >
    <Box className={`${styles.fSpaceBtw} ${styles.mb2}`} >
      <StyledCardTitle gutterBottom>Document Viewer</StyledCardTitle>
      <Stack direction='row' spacing={2}>
        <Typography variant='body2' color={'primary'}>{props.scale != 1 ? (Math.round(props.scale * 100) + '%') : ''}</Typography>
        <IconButton size='small' title='Zoom In' onClick={props.zoomIn} className={`${notificationStyles.docIcon} ${styles.p0}`}>
          <img src={ZoomIn} alt='zoom in' />
        </IconButton>
        <IconButton size='small' title='Zoom Out' onClick={props.zoomOut} className={`${notificationStyles.docIcon} ${styles.p0}`}>
          <img src={ZoomOut} alt='zoom out' />
        </IconButton>
        <DownloadIcon className={notificationStyles.docIcon} />
        <DocIcon className={notificationStyles.docIcon} />
        <AddIcon title='Add Document' className={`${notificationStyles.docIcon}`} onClick={() => props.handleOpen('add_doc')} />
      </Stack>
    </Box>
    <RadioGroup aria-labelledby='' name='' id='' value={props.activeDoc}
      onChange={props.selectDoc}
      // onChange={(event) => {
      //   const selectedItem = props.docList.find(item => item.id == event.target.value);
      //   props.selectDoc(event, selectedItem);
      // }} 
      row className={`${notificationStyles.docTypeRadioGroup} ${styles.mb2}`}>
      <Slider {...settings} className={`${notificationStyles.docTypeSlider}`}>
        {
          props.docList.map((item, index) => (
            <CustomRadio
              key={index}
              control={<Radio size='small' color='success' className={notificationStyles.docTypeRadio} />}
              value={item.id}
              label={item.type == 'AWB' ? 'Airwaybill' : item.name}
              classes={{ label: notificationStyles.sliderLabel }}
            />
          ))
        }
      </Slider>
    </RadioGroup>

    {props.viewPreview && props.viewPreview.split('.')[1] === 'pdf' ?
      <PDFSlider file={environment.apiService + 'document?key=' + props.viewPreview} className={styles.mauto} scale={props.scale} />
      :
      <div className={notificationStyles.placeholderText}>
        <div className={`${styles.fMiddle} ${styles.fColumn}`}>
          <Typography component={'h5'} variant={'h5'} color='primary'>Document Preview Window</Typography>
          <Typography component={'span'} variant={'caption'} color='text.secondary'>Please add a documernt to preview</Typography>
        </div>
      </div>}
  </StyledCard>;

  return (
    <>
      {!props?.flightTab && <div
        className={`${styles.headerFieldContainer} ${styles.headerWrapper}`}>
        <CustomSearch
          {...InputProps}
          id='awb'
          placeholder='Enter Airwaybill Number'
          type='search'
          fullWidth
          name='flight_no'
          value={props.search}
          onChange={props.onChangeSearch}
          InputProps={{
            disableUnderline: true,
            endAdornment: <InputAdornment position='end'>
              <IconButton
                size='small'
                aria-label='search'
                edge='end'>
                <Search fontSize='inherit' onClick={() => props.onChangeSearch({
                  target: {
                    name: 'search_click',
                    value: props.search
                  }
                })} />
              </IconButton>
            </InputAdornment>,
          }}
          className={`${styles.brInput}`}
        />
      </div>}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
          <StyledCard className={styles.h100}>
            <Stack direction={'row'} className={`${styles.mb2}`} alignItems={'flex-start'} justifyContent={'space-between'}>
              <StyledCardTitle gutterBottom>Airwaybills</StyledCardTitle>
              {props?.flightTab && <CustomCheckbox
                control={
                  <Checkbox inputProps={{ 'aria-label': 'show transit awb', }}
                    name='transit'
                    disableRipple
                    checked={props.filter?.transit}
                    value={props.filter?.transit}
                    onChange={props.onChangeFilter}
                  />
                }
                label='Show Transit AWBs'
                className={styles.mr0}
              />}
              {props?.flightTab && <CustomSearch
                {...InputProps}
                id='awb'
                placeholder='Enter Awb Number'
                type='search'
                name='awb_no'
                value={props.filter?.awb}
                onChange={props.onChangeFilter}
                className={`${styles.brInput}`}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <InputAdornment position='end'>
                    <IconButton
                      size='small'
                      aria-label='search'
                      edge='end'
                    >
                      <Search fontSize='inherit' />
                    </IconButton>
                  </InputAdornment>,
                }}
              />}
            </Stack>

            <StyledTableContainer className={notificationStyles.collapsibleTableContainer}>
              <Table aria-label='collapsible table' className={notificationStyles.collapsibleTable}>
                <TableHead className={notificationStyles.collapsibleTableHead}>
                  <TableRow className={notificationStyles.collapsibleHeadRow}>
                    <TableCell className={`${notificationStyles.collapsibleTableCell}`} />
                    <TableCell className={`${notificationStyles.collapsibleTableCell}`}>Airwaybill</TableCell>
                    <TableCell className={`${notificationStyles.collapsibleTableCell}`}></TableCell>
                    <TableCell className={`${notificationStyles.collapsibleTableCell}`}>Storage Starts</TableCell>
                    <TableCell className={`${notificationStyles.collapsibleTableCell}`}>
                      <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                        <Typography variant='inherit'>Fee</Typography>
                        <Typography variant='inherit'>Amount</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell className={`${notificationStyles.collapsibleTableCell}`}></TableCell>
                    <TableCell className={`${notificationStyles.collapsibleTableCell}`}> Consignee / Notify Party</TableCell>
                    <TableCell className={`${notificationStyles.collapsibleTableCell}`}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={notificationStyles.collapsibleTableBody}>
                  {props.importAwbs?.map((row, index) => {
                    const awbData = getAwbDetails(row);
                    const fees = calculateFees(row?.fee || [], awbData);
                    let storage_start = null;
                    if (row?.fee && row?.fee.length > 0) {
                      let storage = null;
                      row?.fee.forEach((fee) => {
                        fee?.value?.forEach((value) => {
                          if (value.unit === 'kg_per_day') {
                            storage = value;
                          }
                        });
                      });
                      if (storage) {
                        let storgae_info = getFee(storage, row?.fee, awbData, true);
                        storage_start = storgae_info?.storage_start || null;
                      }
                    }
                    return <Fragment key={index}>
                      <TableRow key={index} className={notificationStyles.collapsibleTableRow} onClick={() => { props.activeAWB?.id !== row?.id ? props.fetchDocs(row) : null; }}>
                        <TableCell ref={props.columnRef.radio_btn} style={{ width: props.columnWidth?.radio_btn }} className={notificationStyles.collapsibleTableCell} >
                          <Radio size='small' className={styles.p0}
                            checked={props.activeAWB?.id === row?.id}
                          />
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => props.handleTableCollapse(row.id, row)}
                            disabled={props.activeAWB?.id != row?.id}
                            className={notificationStyles.docViewerTabView}
                            sx={{ display: { xl: 'none' } }}
                          >
                            {props.collapseViewer.includes(row.id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                        <TableCell ref={props.columnRef.awb_no} style={{
                          width: props.columnWidth?.awb_no,
                          maxWidth: 'auto',
                        }} className={notificationStyles.collapsibleTableCell} >
                          <Stack direction='column' spacing={1} alignItems='flex-start'>
                            <Typography variant='inherit'>{`${awbData.awb_no} | ${awbData.part ? `${awbData.pcs}/${awbData.total_pcs}` : awbData.pcs}Pcs | ${awbData.wgt}K | ${awbData.org} - ${awbData.dest}`}</Typography>
                            <Typography variant='inherit' whiteSpace={'break-spaces'}><b>Commodity :</b> {awbData.remark}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align='center' ref={props.columnRef.add_icon}
                          style={{ width: props.columnWidth?.add_icon }}
                          className={`${notificationStyles.collapsibleTableCell} ${notificationStyles.smallIcon}`}>
                          <Stack direction='row' spacing={1} alignItems='center' justifyContent={'center'}>
                            {awbData.part && (<BinoIcon title='Add Contract Item' className={`${styles.actionIcons} ${notificationStyles.smallIcon} ${styles.p0}`}
                              onClick={(e) => props.handleOpen('part_arrival_info', e.currentTarget, {
                                id: row?.id,
                                parts: awbData.parts,
                                next: row.next
                              })}
                            />)}
                            {!row.house_awb === true &&
                              <AddIcon title='Add house airwaybill' onClick={() => props.handleOpen('add_house_awb', null, row)} />
                            }
                          </Stack>
                        </TableCell>
                        <TableCell align='center' ref={props.columnRef.storage_start} style={{ width: props.columnWidth?.storage_start }}
                          className={notificationStyles.collapsibleTableCell}>
                          {storage_start ? moment(storage_start).format(DateFormat).toUpperCase() : ''}</TableCell>
                        <TableCell ref={props.columnRef.fee} style={{ width: props.columnWidth?.fee }} className={notificationStyles.collapsibleTableCell}>
                          <Stack direction='column' spacing={1} alignItems='flex-start'>
                            {fees.services.map((item, index) => {
                              let lable = '';
                              if (item.additional_info && item.additional_info.length > 0) {
                                lable += ': ';
                                item.additional_info.forEach((info, index) => {
                                  lable += `${info.chargableDays || 0} Days @ $${info.cost_per_unit} Per Kg`;
                                  if (index < item.additional_info.length - 1) {
                                    lable += ', ';
                                  }
                                });
                              }
                              return (
                                <Stack key={index} direction='row' spacing={3} alignItems='flex-start' justifyContent={'space-between'} width='100%'>
                                  <Typography variant='inherit'>{item.key + lable}</Typography>
                                  <Stack direction='row' spacing={1} alignItems='flex-start' justifyContent={'space-between'} className={notificationStyles.amount}>
                                    <Typography variant='inherit'>$</Typography>
                                    <Typography variant='inherit'>{item.cost.toFixed(2)}</Typography>
                                  </Stack>
                                </Stack>
                              );
                            })}
                          </Stack>
                        </TableCell>
                        <TableCell ref={props.columnRef.add_icon} width={40} align='center'
                          style={{ width: props.columnWidth?.add_icon }}
                          className={`${notificationStyles.collapsibleTableCell} ${notificationStyles.smallIcon}`}>
                          <AddIcon title='Add Additional Services' onClick={() => props.handleOpen('additional_services', null, row)} />
                        </TableCell>
                        <TableCell ref={props.columnRef.consignee}
                          style={{ width: props.columnWidth?.consignee }}
                          className={notificationStyles.collapsibleTableCell}>
                          <Stack direction='column' spacing={0.5}>
                            <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' className={`${notificationStyles.smallIcon}`}>
                              {
                                row.consigneeId ? (
                                  <Typography variant='inherit' mr={2} color={row.house_awb === true ? 'error' : 'inherit'}>
                                    {row.consignee?.name}
                                  </Typography>
                                ) : row?.awb?.consignee ? (
                                  <Typography variant='inherit' mr={2} color={row.house_awb === true ? 'error' : grey[500]} >
                                    {row?.awb?.consignee}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant='inherit'
                                    mr={2}
                                    color={'error'}
                                  >
                                    Select a Consignee
                                  </Typography>
                                )
                              }
                              <EditIcon title='Update Consignee' className={`${styles.actionIcons} ${notificationStyles.smallIcon} ${styles.p0}`} onClick={(e) => props.openConsigneeModal(e, row)} />
                            </Stack>
                            {
                              row.consigneeId && row?.consignee?.mails && row?.consignee.mails.length > 0 ? (
                                row?.consignee?.mails.map((item, index) => (
                                  <Typography key={index} variant='inherit' component='div'>
                                    {item?.email}
                                  </Typography>
                                ))
                              ) : null
                            }
                          </Stack>
                        </TableCell>
                        <TableCell ref={props.columnRef.action}
                          style={{ width: props.columnWidth?.action }} className={notificationStyles.collapsibleTableCell}>
                          <Stack direction='row' spacing={1} justifyContent='flex-end' className={styles.actionIcons} mt={'2px'}>
                            <Badge color={getStatusColor(row?.notifications[0]?.mail)} variant='dot'>
                              <IconButton className={`${notificationStyles.addNotes} ${styles.p0} ${row.next || !row.consigneeId ? styles.disabledImg : ''}`}
                                disabled={row.next || !row.consigneeId} onClick={() => props.handleNotification(row)}>
                                <img src={SendIcon} alt='send' />
                              </IconButton>
                            </Badge>
                            <TableBadge badgeContent={row?.notifications?.length} color='error'>
                              <Button title='History' size='small' disabled={row.house_awb === true} disableElevation variant='contained' color='primary' className={notificationStyles.historyIcon} onClick={() => props.handleOpen('notification_history', false, row?.notifications || [])}>H</Button>
                            </TableBadge>
                          </Stack>
                        </TableCell>
                      </TableRow>

                      {row?.house_awbs.length > 0 &&
                        <TableRow className={`${notificationStyles.spRow}`}>
                          <TableCell colSpan={8} className={`${styles.p0} ${[styles.w100]}`}>
                            <Collapse in={true} timeout='auto' unmountOnExit>
                              <Box className={notificationStyles.collapsibleBox}>
                                <Table aria-label='' className={notificationStyles.collapsedTable}>
                                  <TableBody className={notificationStyles.collapsedTableBody}>
                                    {row?.house_awbs?.map((nestedRow, index) => {
                                      const hAwbData = getAwbDetails({
                                        ...nestedRow,
                                        flight: row?.flight
                                      });
                                      const hFees = calculateFees(nestedRow?.fee || [], {
                                        ...hAwbData,
                                        flight: row?.flight
                                      });
                                      let hStorage_start = null;
                                      if (nestedRow?.fee && nestedRow?.fee.length > 0) {
                                        let storage = null;
                                        nestedRow?.fee.forEach((fee) => {
                                          fee?.value?.forEach((value) => {
                                            if (value.unit === 'kg_per_day') {
                                              storage = value;
                                            }
                                          });
                                        });
                                        if (storage) {
                                          let storgae_info = getFee(storage, nestedRow?.fee, hAwbData, true);
                                          hStorage_start = storgae_info?.storage_start || null;
                                        }
                                      }
                                      return (
                                        <TableRow key={index} className={`${notificationStyles.collapsedTableRow} ${notificationStyles.selectedRow}`}>
                                          <TableCell className={notificationStyles.collapsedTableCell} style={{
                                            minWidth: props.columnWidth.radio_btn,
                                            maxWidth: props.columnWidth.radio_btn,
                                          }}></TableCell>
                                          <TableCell className={notificationStyles.collapsedTableCell} style={{
                                            minWidth: props.columnWidth.awb_no,
                                            maxWidth: props.columnWidth.awb_no,
                                          }}>
                                            <Stack direction='column' spacing={1} alignItems='flex-start'>
                                              <Typography variant='inherit'>
                                                {`${hAwbData.awb_no} | ${hAwbData.pcs}Pcs | ${hAwbData.wgt}K | ${hAwbData.org} - ${hAwbData.dest}`}
                                              </Typography>
                                              <Typography variant='inherit' whiteSpace={'break-spaces'}><b>Commodity : </b>{awbData.remark}</Typography>
                                            </Stack>
                                          </TableCell>
                                          <TableCell ref={props.columnRef.add_icon} width={40}
                                            style={{ width: props.columnWidth?.add_icon }}
                                            className={`${notificationStyles.collapsedTableCell}`}>
                                          </TableCell>
                                          <TableCell className={notificationStyles.collapsedTableCell} align='center' style={{
                                            maxWidth: props.columnWidth.storage_start,
                                            minWidth: props.columnWidth?.storage_start
                                          }}>{hStorage_start ? moment(hStorage_start).format(DateFormat).toUpperCase() : ''}</TableCell>
                                          <TableCell className={notificationStyles.collapsedTableCell}
                                            style={{
                                              maxWidth: props.columnWidth?.fee,
                                              minWidth: props.columnWidth?.fee,
                                            }}
                                          >
                                            <Stack direction='column' spacing={1} alignItems='flex-start'>
                                              {hFees.services.map((item, index) => {
                                                let lable = '';
                                                if (item.additional_info && item.additional_info.length > 0) {
                                                  lable += ': ';
                                                  item.additional_info.forEach((info, index) => {
                                                    lable += `${info.chargableDays || 0} Days @ $${info.cost_per_unit} Per Kg`;
                                                    if (index < item.additional_info.length - 1) {
                                                      lable += ', ';
                                                    }
                                                  });
                                                }
                                                return (
                                                  <Stack key={index} direction='row' spacing={3} alignItems='flex-start' justifyContent={'space-between'} width='100%'>
                                                    <Typography variant='inherit'>{item.key + lable}</Typography>
                                                    <Stack direction='row' spacing={1} alignItems='flex-start' justifyContent={'space-between'} className={notificationStyles.amount}>
                                                      <Typography variant='inherit'>$</Typography>
                                                      <Typography variant='inherit'>{item.cost.toFixed(2)}</Typography>
                                                    </Stack>
                                                  </Stack>
                                                );
                                              })}
                                            </Stack>
                                          </TableCell>
                                          <TableCell align='center' style={{
                                            minWidth: props.columnWidth?.add_icon,
                                            maxWidth: props.columnWidth?.add_icon
                                          }} className={`${notificationStyles.collapsedTableCell} ${notificationStyles.smallIcon}`}>
                                            <AddIcon title='Add Additional Services' onClick={() => props.handleOpen('additional_services', null, {
                                              ...nestedRow,
                                              flight: row?.flight 
                                            })} />
                                          </TableCell>
                                          <TableCell className={notificationStyles.collapsedTableCell}
                                            style={{
                                              minWidth: props.columnWidth?.consignee,
                                              maxWidth: props.columnWidth?.consignee
                                            }}>
                                            <Stack direction='column' spacing={0.5}>
                                              <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' className={`${notificationStyles.smallIcon}`}>
                                                {
                                                  nestedRow.consigneeId ? (
                                                    <Typography variant='inherit' mr={2} color='inherit'>
                                                      {nestedRow.consignee?.name}
                                                    </Typography>
                                                  ) : row?.awb?.consignee ? (
                                                    <Typography variant='inherit' mr={2} color='inherit'>
                                                      {row?.awb?.consignee}
                                                    </Typography>
                                                  ) : (
                                                    <Typography
                                                      variant='inherit'
                                                      mr={2}
                                                      color={'error'}
                                                    >
                                                      Select a Consignee
                                                    </Typography>
                                                  )
                                                }
                                                <EditIcon title='Update Consignee' className={`${styles.actionIcons} ${notificationStyles.smallIcon} ${styles.p0}`} onClick={(e) => props.openConsigneeModal(e, nestedRow)} />
                                              </Stack>
                                              {
                                                nestedRow.consigneeId && nestedRow?.consignee?.mails && nestedRow?.consignee.mails.length > 0 ? (
                                                  nestedRow?.consignee?.mails.map((item, index) => (
                                                    <Typography key={index} variant='inherit' component='div'>
                                                      {item?.email}
                                                    </Typography>
                                                  ))
                                                ) : null
                                              }
                                            </Stack>
                                          </TableCell>
                                          <TableCell className={notificationStyles.collapsedTableCell} width={props.columnWidth?.action}>
                                            <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
                                              <IconButton className={`${notificationStyles.addNotes} ${styles.p0}  ${nestedRow.next === true ? styles.disabledImg : ''}`} disabled={!nestedRow.consigneeId} onClick={() => props.handleNotification({
                                                ...nestedRow,
                                                flight: row?.flight,
                                                awb: row?.awb
                                              })}>
                                                <img src={SendIcon} alt='send' />
                                              </IconButton>
                                              <TableBadge badgeContent={nestedRow?.notifications?.length} color='error'>
                                                <Button size='small' title='History' disableElevation variant='contained' color='primary' className={notificationStyles.historyIcon} onClick={() => props.handleOpen('notification_history', false, nestedRow?.notifications || [])}>H</Button>
                                              </TableBadge>
                                            </Stack>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      }
                      {props.activeDoc &&
                      <TableRow className={`${notificationStyles.spRow} ${notificationStyles.docViewerTabView}`}
                        sx={{ display: { xl: 'none' } }}>
                        <TableCell colSpan={8} className={styles.p0}>
                          <Collapse in={props.collapseViewer.includes(row.id)} timeout='auto' unmountOnExit>
                            <Box className={notificationStyles.collapsibleBox}>
                              {DocViewer}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow> 
                      } 
                    </Fragment>;
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </StyledCard>
        </Grid>

        <Grid item xs={4} className={`${notificationStyles.docViewer} ${notificationStyles.desktopDocViewer}`} sx={{
          display: {
            sm: 'none',
            xl: 'block',
          },
        }}>
          {DocViewer}
        </Grid>
      </Grid>

      {props.modal === 'add_house_awb' &&
        <AddHouseAwbModal
          modal={props.modal}
          anchorEl={props.anchorEl}
          data={props.modalData}
          handleOpen={props.handleOpen}
          handleClose={props.handleClose}
          submitHouseAwb={props.submitHouseAwb}
          onChangeHouseAwb={props.onChangeHouseAwb}
          houseAwbFields={props.houseAwbFields}
          houseAwbErrors={props.houseAwbErrors}
          addHouseAwb={props.addHouseAwb}
          onEditHouseAwb={props.onEditHouseAwb}
          onDeleteHouseAwb={props.onDeleteHouseAwb}
          handlePopoverOpen={props.handlePopoverOpen}
          handlePopoverClose={props.handlePopoverClose}
          popover={props.popover}
          popoverAnchorEl={props.popoverAnchorEl}
          popoverData={props.popoverData}
          editHouseAwb={props.editHouseAwb}
        />
      }

      {props.modal === 'update_consignee' &&
        <UpdateConsigneeModal
          modal={props.modal}
          handleClose={props.handleClose}
          consignees={props.consignees}
          onSelectedConsignee={props.onSelectedConsignee}
          selectedConsignee={props.selectedConsignee}
          consigneeFields={props.consigneeFields}
          consigneeOperation={props.consigneeOperation}
          addNewButtonHandler={props.addNewButtonHandler}
          consigneeSearch={props.consigneeSearch}
          onConsigneeSearch={props.onConsigneeSearch}
          handleassignConsignee={props.handleassignConsignee}
          editConsignee={props.editConsignee}
          onConsigneeFieldsChange={props.onConsigneeFieldsChange}
          onAddCMail={props.onAddCMail}
          onDeleteCMail={props.onDeleteCMail}
          onConsigneeMailChange={props.onConsigneeMailChange}
        />
      }

      {props.modal === 'additional_services' &&
        <AddAdditionalServicesModal
          modal={props.modal}
          handleClose={props.handleClose}
          handleChange={props.handleChange}
          services={props.services}
          data={props.modalData}
          serviceFields={props.serviceFields}
          serviceErrors={props.serviceErrors}
          handleChanegOperation={props.handleChanegOperation}
          onSubmitService={props.onSubmitService}
          onDeleteService={props.onDeleteService}
        />
      }
      {props.modal === 'send_notification' &&
        <SendNotificationModal
          modal={props.modal}
          handleClose={props.handleClose}
          data={props.modalData}
          handleSubmitNotification={props.handleSubmitNotification}
        />
      }
      {props.modal === 'notification_history' &&
        <NotificationHistoryModal
          modal={props.modal}
          handleClose={props.handleClose}
          data={props.modalData}
        />
      }
      {props.modal === 'part_arrival_info' &&
        <PartArrivalInfo
          popover={props.modal}
          anchorEl={props.anchorEl}
          data={props.modalData}
          partNotification={props.partNotification}
          onClose={props.handleClose}
        />
      }

      {props.modal === 'add_doc' &&
        <AddDocModal
          modal={props.modal}
          handleClose={props.handleClose}
          errors={props.docErrors}
          onChange={props.onChangeDoc}
          onSubmit={props.addDoc}
          // onSubmitReq={this.onSubmitReq}
          fileType={props.fileType}
          addDocType={props.addDocType}
          attachTo={props.attachTo}
          onhandleChange={props.onChangeDoc}
          fields={props.docfields}
        />
      }

    </>
  );
};

export default ImportNotification;

