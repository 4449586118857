import React from 'react';
import { Checkbox, FormControl, FormHelperText, Grid, MenuItem, Stack } from '@mui/material';
import styles from '../../../../../../../custom/style.module.css';
import { CustomButton, CustomCheckbox, CustomInputLabel, CustomSelect, CustomTextField, InputProps, SelectInputProps, StyledCard } from '../../../../../../../custom/components';
import { AddIcon, DeleteIcon } from 'custom/constants';
import importStyles from './servicecatalog.module.css';

const AdditionalService = (props) => {
  const { action, serviceFields, serviceFieldsErrors, onChange, addService, editService, onChangeOperation } = props;
  return (
    <form onSubmit={action == 'add' ? addService : editService} onReset={props.onClose} className={styles.w100}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <CustomInputLabel htmlFor='service'>Service</CustomInputLabel>
          <CustomTextField
            {...InputProps}
            fullWidth
            id='service'
            placeholder='Service'
            type='text'
            onChange={onChange}
            name='key'
            value={serviceFields.key}
            error={serviceFieldsErrors.key}
            helperText={serviceFieldsErrors.key}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomInputLabel htmlFor='des'>Description</CustomInputLabel>
          <CustomTextField
            {...InputProps}
            fullWidth
            autoFocus
            id='des'
            placeholder='Description'
            type='text'
            rows={4}
            multiline
            onChange={onChange}
            name='description'
            value={serviceFields.description}
            error={serviceFieldsErrors.description}
            helperText={serviceFieldsErrors.description}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack spacing={2} direction='row'>
            <CustomInputLabel>Operations</CustomInputLabel>
            <AddIcon title='Add Operations' onClick={props.addOperation} className={`${styles.actionIcons} ${importStyles.smallIcon}`} />
          </Stack>
          <FormHelperText>{serviceFieldsErrors.value_remark}</FormHelperText>
          {serviceFields && serviceFields.value?.map((value, index) => {
            return (
              <StyledCard key={index} className={importStyles.operationCard}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={1.5}>
                    <CustomInputLabel htmlFor='type'>Type</CustomInputLabel>
                    <FormControl variant='standard' fullWidth error={props.serviceFieldsErrors.type}>
                      <CustomSelect
                        index={index}
                        fullWidth
                        id='type'
                        name='type'
                        value={value.type}
                        onChange={(e) => onChangeOperation(index, e)}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.type}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.type}
                        classes={{
                          root: importStyles.operationsIpRoot,
                          select: importStyles.operationsIpSelect,
                          input: importStyles.operationsIp
                        }}
                      >
                        <MenuItem value='' disabled>Select Type</MenuItem>
                        <MenuItem value='add'>Add</MenuItem>
                        <MenuItem value='subtract'>Subtract</MenuItem>
                      </CustomSelect>
                      <FormHelperText>{serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.type}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={1.5}>
                    <CustomInputLabel htmlFor='unit'>Unit Type</CustomInputLabel>
                    <FormControl variant='standard' fullWidth error={props.serviceFieldsErrors.unit}>
                      <CustomSelect
                        fullWidth
                        id='unit'
                        name='unit'
                        value={value.unit}
                        onChange={(e) => onChangeOperation(index, e)}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.unit}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.unit}
                        classes={{
                          root: importStyles.operationsIpRoot,
                          select: importStyles.operationsIpSelect,
                          input: importStyles.operationsIp
                        }}
                      >
                        <MenuItem disabled>Select Unit</MenuItem>
                        <MenuItem value='cost'>Cost (Fixed)</MenuItem>
                        <MenuItem value='cost_manual'>Cost (Manual)</MenuItem>
                        <MenuItem value='number'>Number (Custom)</MenuItem>
                        <MenuItem value='kg'>Kg (Auto)</MenuItem>
                        <MenuItem value='kg_per_day'>Storage (Auto)</MenuItem>
                        <MenuItem value='percent'>Percent</MenuItem>
                      </CustomSelect>
                      <FormHelperText>{serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.unit}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {value.unit == 'number' && (
                    <Grid item xs={6} md={2}>
                      <CustomInputLabel htmlFor='value'>Unit Name</CustomInputLabel>
                      <CustomTextField
                        variant='standard'
                        fullWidth
                        id='value'
                        placeholder='Unit Name'
                        type='text'
                        onChange={(e) => onChangeOperation(index, e)}
                        name='unit_key'
                        value={value.unit_key}
                        InputProps={{
                          classes: {
                            root: importStyles.operationsIpRoot,
                            input: importStyles.operationsIp
                          },
                        }}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.unit_key}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.unit_key}
                      />
                    </Grid>
                  )}
                  {['number', 'kg', 'kg_per_day']?.includes(value.unit) && (
                    <Grid item xs={6} md={1.5}>
                      <CustomInputLabel htmlFor='cost_per_unit'>Cost Per Unit</CustomInputLabel>
                      <CustomTextField
                        fullWidth
                        variant='standard'
                        id='cost_per_unit'
                        placeholder='Cost Per Unit'
                        type='number'
                        onChange={(e) => onChangeOperation(index, e)}
                        name='cost_per_unit'
                        value={value.cost_per_unit}
                        InputProps={{
                          classes: {
                            root: importStyles.operationsIpRoot,
                            input: importStyles.operationsIp
                          },
                        }}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.cost_per_unit}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.cost_per_unit}
                      />
                    </Grid>
                  )}
                  {value.unit == 'cost' && (
                    <Grid item xs={6} md={1.5}>
                      <CustomInputLabel htmlFor='cost'>Cost</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        fullWidth
                        id='cost'
                        placeholder='Cost'
                        type='number'
                        onChange={(e) => onChangeOperation(index, e)}
                        name='cost'
                        value={value.cost}
                        className={importStyles.operationsTextfield}
                        InputProps={{
                          classes: {
                            root: importStyles.operationsIpRoot,
                            input: importStyles.operationsIp
                          },
                        }}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.cost}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.cost}
                      />
                    </Grid>
                  )}
                  {value.unit == 'percent' && (
                    <Grid item xs={6} md={1.5}>
                      <CustomInputLabel htmlFor='percent'>Percent</CustomInputLabel>
                      <CustomTextField
                        variant='standard'
                        fullWidth
                        id='percent'
                        placeholder='Percent'
                        type='number'
                        onChange={(e) => onChangeOperation(index, e)}
                        name='percent'
                        value={value.percent}
                        InputProps={{
                          classes: {
                            root: importStyles.operationsIpRoot,
                            input: importStyles.operationsIp
                          },
                        }}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.percent}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.percent}
                      />
                    </Grid>
                  )}
                  {value.unit == 'percent' && (
                    <Grid item xs={6} md={1.5}>
                      <CustomInputLabel htmlFor='from_service'>From Service</CustomInputLabel>
                      <CustomSelect
                        fullWidth
                        variant='standard'
                        id='from_service'
                        name='from_service'
                        value={value.from_service}
                        onChange={(e) => onChangeOperation(index, e)}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.from_service}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.from_service}
                        classes={{
                          root: importStyles.operationsIpRoot,
                          select: importStyles.operationsIpSelect,
                          input: importStyles.operationsIp
                        }}
                      >
                        <MenuItem value='' disabled>Select Service</MenuItem>
                        {props.services?.map((service) => {
                          if (service.key != serviceFields.key)
                            return <MenuItem key={service.id} value={service.key}>{service.key}</MenuItem>;
                        })}
                      </CustomSelect>
                    </Grid>
                  )}
                  {value.unit == 'kg_per_day' && (
                    <Grid item xs={6} md={1.5}>
                      <CustomInputLabel htmlFor='unit_divisor'>Unit Divisor</CustomInputLabel>
                      <CustomTextField
                        variant='standard'
                        fullWidth
                        id='unit_divisor'
                        placeholder='Unit Divisor'
                        type='number'
                        onChange={(e) => onChangeOperation(index, e)}
                        name='unit_divisor'
                        value={value.unit_divisor}
                        InputProps={{
                          classes: {
                            root: importStyles.operationsIpRoot,
                            input: importStyles.operationsIp
                          },
                        }}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.unit_divisor}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.unit_divisor}
                      />
                    </Grid>
                  )}
                  {value.unit == 'kg_per_day' && (
                    <Grid item xs={6} md={1.5}>
                      <CustomInputLabel htmlFor='mim_cost_per_day'>Min Cost Per Day</CustomInputLabel>
                      <CustomTextField
                        variant='standard'
                        fullWidth
                        id='mim_cost_per_day'
                        placeholder='Min Cost Per Day'
                        type='number'
                        onChange={(e) => onChangeOperation(index, e)}
                        name='mim_cost_per_day'
                        value={value.mim_cost_per_day}
                        InputProps={{
                          classes: {
                            root: importStyles.operationsIpRoot,
                            input: importStyles.operationsIp
                          },
                        }}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.mim_cost_per_day}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.mim_cost_per_day}
                      />
                    </Grid>
                  )}
                  {value.unit == 'kg_per_day' && (
                    <Grid item xs={6} md={1.5}>
                      <CustomInputLabel htmlFor='free_days'>Free Days</CustomInputLabel>
                      <CustomTextField
                        variant='standard'
                        fullWidth
                        id='free_days'
                        placeholder='Free Days'
                        type='number'
                        onChange={(e) => onChangeOperation(index, e)}
                        name='free_days'
                        value={value.free_days}
                        InputProps={{
                          classes: {
                            root: importStyles.operationsIpRoot,
                            input: importStyles.operationsIp
                          },
                        }}
                        error={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.free_days}
                        helperText={serviceFieldsErrors?.value && serviceFieldsErrors.value[index]?.free_days}
                      />
                    </Grid>
                  )}
                  {/* first_weekend_free */}
                  {value.unit == 'kg_per_day' && (
                    <Grid item xs={6} md={2.5}>
                      <CustomCheckbox
                        control={
                          <Checkbox
                            name='first_weekend_free'
                            color='primary'
                            checked={value.first_weekend_free}
                            onChange={(e) => onChangeOperation(index, e)}
                            inputProps={{ 'aria-label': 'first_weekend_free' }}
                            className={styles.p1}
                          />
                        }
                        label='First Weekend Free'
                      />
                      <CustomCheckbox
                        control={
                          <Checkbox
                            name='arrival_day_counted'
                            color='primary'
                            checked={value.arrival_day_counted}
                            onChange={(e) => onChangeOperation(index, e)}
                            inputProps={{ 'aria-label': 'arrival_day_counted' }}
                            className={styles.p1}
                          />
                        }
                        label='Arrival Day Counted'
                      />
                      <CustomCheckbox
                        control={
                          <Checkbox
                            name='all_public_holidays_counted'
                            color='primary'
                            checked={value.all_public_holidays_counted}
                            onChange={(e) => onChangeOperation(index, e)}
                            inputProps={{ 'aria-label': 'all_public_holidays_counted' }}
                            className={styles.p1}
                          />
                        }
                        label='All Public Holidays Counted'
                      />
                      <CustomCheckbox
                        control={
                          <Checkbox
                            name='first_public_holiday_counted'
                            color='primary'
                            checked={value.first_public_holiday_counted}
                            onChange={(e) => onChangeOperation(index, e)}
                            inputProps={{ 'aria-label': 'first_public_holiday_counted' }}
                            className={styles.p1}
                          />
                        }
                        label='First Public Holiday Counted'
                      />
                      <CustomCheckbox
                        control={
                          <Checkbox
                            name='first_weekend_holiday_free_without_day_after_free_period'
                            color='primary'
                            checked={value.first_weekend_holiday_free_without_day_after_free_period}
                            onChange={(e) => onChangeOperation(index, e)}
                            inputProps={{ 'aria-label': 'first_weekend_holiday_free_without_day_after_free_period' }}
                            className={styles.p1}
                          />
                        }
                        className={importStyles.labelCheckbox }
                        label='First Weekend Holiday Free Without Day After Free Period'
                      />
                    </Grid>
                  )}
                  <Grid item xs className={`${styles.actionIcons}`} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-start'}>
                    <DeleteIcon onClick={() => props.removeOperation(index)} />
                  </Grid>
                </Grid>
              </StyledCard>
            );
          })}
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomInputLabel htmlFor='service'>Override Existing Service</CustomInputLabel>
          <CustomSelect
            {...SelectInputProps}
            fullWidth
            id='service'
            name='master'
            value={serviceFields.master ? serviceFields.master : false}
            onChange={onChange}
            error={serviceFieldsErrors.master}
            helperText={serviceFieldsErrors.master}
          >
            <MenuItem value={false}>None</MenuItem>
            {props.services?.map((service) => {
              if (service.key != serviceFields.key && !service.master)
                return <MenuItem key={service.id} value={service.key}>{service.key}</MenuItem>;
            })}
          </CustomSelect>
        </Grid>

        <Grid item xs={12} md={12}>
          <CustomCheckbox
            control={
              <Checkbox
                name='default'
                color='primary'
                checked={Boolean(serviceFields.default)}
                onChange={onChange}
                inputProps={{ 'aria-label': 'default' }}
              />
            }
            label='Default Service'
          />
          {/* <CustomCheckbox
                    control={
                      <Checkbox
                        name='reason'
                        color='primary'
                        checked={serviceFields.reason}
                        onChange={onChange}
                        inputProps={{ 'aria-label': 'other service reason' }}
                      />
                    }
                    label='Other Service Reason (Mandatory)'
                  /> */}
          {/* Additional information to GHA */}
          <CustomCheckbox
            control={
              <Checkbox
                name='add_info'
                color='primary'
                checked={Boolean(serviceFields.add_info)}
                onChange={onChange}
                inputProps={{ 'aria-label': 'additional information' }}
              />
            }
            label='Additional Information to GHA (Optional)'
          />
        </Grid>
        <Grid item xs={12} md={12} display={'flex'} justifyContent={'flex-end'}>
          <CustomButton variant='contained' type='submit'>{action == 'add' ? 'Submit' : 'Update'}</CustomButton>
        </Grid>
      </Grid>
    </form> 
  );
};
export default AdditionalService;