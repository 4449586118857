import React from 'react';
import { Box, Dialog, Grid, Stack, Typography } from '@mui/material';
import styles from '../../../../../custom/style.module.css';
import buildupStyles from './buildup.module.css';
import { CustomButton, CustomDatePicker, CustomDialogContent, CustomDialogTitle, CustomInputLabel, CustomTextField, DataGridProps, InputProps, StyledCard, StyledCardTitle, StyledDataGrid } from 'custom/components';
import { ClearXIcon, CloseIconBtn, DeleteIcon, EditIcon, SaveTickIcon } from 'custom/constants';
import moment from 'moment';
import dayjs from 'dayjs';

const AwbDetailModal = (props) => {

  console.log(props, 'props');

  const awbDetailsColumns = [
    {
      field: 'uld_no',
      headerName: 'ULD#',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.buildup_uld?.uld_no;
      }
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      minWidth: 60,
      flex: 1,
      renderCell: ({ row }) => {
        return props.editLoadAwb == row.id ? (
          <CustomTextField
            {...InputProps}
            variant='outlined'
            id='pcs'
            fullWidth
            value={row.pcs}
            name='pcs'
            onChange={props.onChangeLoadAwb}
          />
        ) : (
          row.pcs + ' PCS'
        );
      }
    },
    {
      field: 'wgt',
      headerName: 'Wgt',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return props.editLoadAwb == row.id ? (
          <CustomTextField
            {...InputProps}
            variant='outlined'
            id='wgt'
            fullWidth
            value={row.wgt}
            name='wgt'
            onChange={props.onChangeLoadAwb}
          />
        ) : (
          row.wgt + ' K'
        );
      }
    },

    {
      field: 'flight_no',
      headerName: 'Flight#',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.flight?.airline?.code + row?.flight?.flight_no + '/' + moment(row?.flight?.flight_date).format('DDMMMYY').toUpperCase();
      }
    },
    {
      field: 'spl_code',
      headerName: 'SPLs',
      minWidth: 100,
      flex: 1,
      cellClassName: styles.cellTextWrap,
      renderCell: ({ row }) => {
        return props.editLoadAwb == row.id ? (
          <CustomTextField
            {...InputProps}
            variant='outlined'
            id='spl_code'
            fullWidth
            value={row.spl_code}
            name='spl_code'
            onChange={props.onChangeLoadAwb}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
          />
        ) : (
          row.spl_code?.split(',').join(', ')
        );
      }
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return props.editLoadAwb == row.id ? (
          <CustomTextField
            {...InputProps}
            variant='outlined'
            id='remark'
            fullWidth
            value={row.remark}
            name='remark'
            onChange={props.onChangeLoadAwb}
            onKeyDown={(event) => {
              event.stopPropagation();
            }}
          />
        ) : (
          row.remark
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        let disabled = false;
        if (row.flight && row.flight?.schdl_dept && moment(row.flight?.schdl_dept).isBefore(moment().utc())) {
          disabled = true;
        }
        return props.editLoadAwb == row.id ?
          <Stack direction='row' spacing={1} justifyContent='center' alignItems={'center'} className={styles.actionIcons}>
            <SaveTickIcon onClick={() => props.onSaveLoadAwb()} />
            <div className={buildupStyles.closeIcon}>
              <ClearXIcon onClick={() => props.onLoadEditAwb(false)} />
            </div>
          </Stack>
          :
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <EditIcon disabled={disabled} onClick={() => props.onLoadEditAwb(row.id)} />
            <DeleteIcon disabled={disabled} onClick={(e) => props.handleDelete('load_awb', row.id, e.target)} />
          </Stack >;
      }
    },
  ];

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal == 'awb_details'} onClose={props.onClose} maxWidth='md' fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          Airwaybill Details
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <CustomDialogContent>

          <Grid container spacing={3}>
            {!props.searchAwb?.id && <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography variant='body2' fontWeight={500} color={'white'} textAlign={'center'} bgcolor={'var(--redText)'} p={0.4} mx={'-24px'} mt={'-23px'}>Awb not found. Please add a awb manually.</Typography>
            </Grid>}
            <Grid item container rowSpacing={2} spacing={2} sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
            }} className={!props.searchAwb.edit ? buildupStyles.addAwbDetails : ''}>
              <Grid item xs={12} sm={!props.searchAwb.edit ? 'auto' : 4} md={!props.searchAwb.edit ? 'auto' : 1.8}>
                {!props.searchAwb.edit &&
                  <Stack direction={'column'} spacing={0}>
                    <CustomInputLabel htmlFor='awb' aria-label='Enter pieces'>Awb#</CustomInputLabel>
                    <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.code}-{props.searchAwb?.number}</Typography>
                  </Stack>
                }
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='awb' aria-label='Enter pieces'>Awb#</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='awb'
                      fullWidth
                      value={props.searchAwb?.code + '-' + props.searchAwb?.number}
                      disabled
                      className={buildupStyles.awbIpTextfield}
                      InputProps={{
                        classes: {
                          root: buildupStyles.awbIpRoot,
                          input: buildupStyles.awbIp
                        },
                      }}
                    />
                  </>}
              </Grid>
              <Grid item xs={12} sm={!props.searchAwb.edit ? 'auto' : 4} md={!props.searchAwb.edit ? 'auto' : 1.5}>
                {!props.searchAwb.edit &&
                  <Stack direction={'column'} spacing={0}>
                    <CustomInputLabel htmlFor='awb' aria-label='Enter pieces'>Pcs</CustomInputLabel>
                    <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.pcs || 0}</Typography>
                  </Stack>
                }
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='pcs' aria-label='Enter pieces'>Pcs</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='pcs'
                      fullWidth
                      name='pcs'
                      value={props.searchAwb?.pcs}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          props.onChnageSearchAwb(e);
                        }
                      }}
                      className={buildupStyles.awbIpTextfield}
                      InputProps={{
                        classes: {
                          root: buildupStyles.awbIpRoot,
                          input: buildupStyles.awbIp
                        },
                        inputProps: {
                          inputMode: 'decimal',
                          pattern: '[0-9]*[.]?[0-9]*'
                        }
                      }}
                    />
                  </>}
              </Grid>
              <Grid item xs={12} sm={!props.searchAwb.edit ? 'auto' : 4} md={!props.searchAwb.edit ? 'auto' : 1.5}>
                {!props.searchAwb.edit &&
                  <Stack direction={'column'} spacing={0}>
                    <CustomInputLabel htmlFor='awb' aria-label='Enter pieces'>Wgt</CustomInputLabel>
                    <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.wgt?.toFixed(2) || 0} K</Typography>
                  </Stack>
                }
                {props.searchAwb.edit &&
                  <>
                    <CustomInputLabel htmlFor='wgt' aria-label='Enter weight'>Wgt</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='wgt'
                      fullWidth
                      name='wgt'
                      value={props.searchAwb?.wgt}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          props.onChnageSearchAwb(e);
                        }
                      }}
                      className={buildupStyles.awbIpTextfield}
                      InputProps={{
                        classes: {
                          root: buildupStyles.awbIpRoot,
                          input: buildupStyles.awbIp
                        },
                        inputProps: {
                          inputMode: 'decimal',
                          pattern: '[0-9]*[.]?[0-9]*'
                        }
                      }}
                    />
                  </>}
              </Grid>
              <Grid item container xs={12} sm={!props.searchAwb.edit ? 'auto' : 4} md={!props.searchAwb.edit ? 'auto' : 3} spacing={2}>
                {!props.searchAwb.edit && 
                <Grid item xs>
                  <Stack direction={'column'} spacing={0}>
                    <CustomInputLabel htmlFor='awb' aria-label='Enter pieces'>Org-Dest</CustomInputLabel>
                    <Typography variant='body1' fontWeight={700} color={'primary'} component={'p'}>{props.searchAwb?.org?.toUpperCase()}-{props.searchAwb?.dest?.toUpperCase()}</Typography>
                  </Stack>
                </Grid>}
                {props.searchAwb.edit &&
                  <Grid item xs={6}>
                    <CustomInputLabel htmlFor='org' aria-label='Enter org'>Org</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='org'
                      fullWidth
                      name='org'
                      value={props.searchAwb?.org?.toUpperCase()}
                      onChange={props.onChnageSearchAwb}
                      className={buildupStyles.awbIpTextfield}
                      InputProps={{
                        classes: {
                          root: buildupStyles.awbIpRoot,
                          input: buildupStyles.awbIp
                        },
                      }}
                    />
                  </Grid>}
                {props.searchAwb.edit &&
                  <Grid item xs={6}>
                    <CustomInputLabel htmlFor='dest' aria-label='Enter dest'>Dest</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='dest'
                      fullWidth
                      name='dest'
                      value={props.searchAwb?.dest?.toUpperCase()}
                      onChange={props.onChnageSearchAwb}
                      className={buildupStyles.awbIpTextfield}
                      InputProps={{
                        classes: {
                          root: buildupStyles.awbIpRoot,
                          input: buildupStyles.awbIp
                        },
                      }}
                    />
                  </Grid>}
              </Grid>
              <Grid item container xs={12} sm={!props.searchAwb.edit ? 'auto' : 8} md={!props.searchAwb.edit ? 'auto' : 3.5} spacing={2}>
                {!props.searchAwb.edit &&
                  <Grid item xs>
                    <Stack direction={'column'} spacing={0}>
                      <CustomInputLabel htmlFor='awb' aria-label='Enter pieces'>Flight Details</CustomInputLabel>
                      <Typography variant='body1' fontWeight={700} color={'primary'}>{props.searchAwb?.flight_no || '-'}/{moment(props.searchAwb?.flight_date).format('DDMMMYY')?.toUpperCase()}</Typography>
                    </Stack>
                  </Grid>}
                {props.searchAwb.edit &&
                  <Grid item xs={12} sm md={5.1}>
                    <CustomInputLabel htmlFor='flight_no' aria-label='Enter flight_no'>Flight No</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      variant='outlined'
                      id='flight_no'
                      fullWidth
                      name='flight_no'
                      value={props.searchAwb?.flight_no?.toUpperCase()}
                      onChange={props.onChnageSearchAwb}
                      className={buildupStyles.awbIpTextfield}
                      InputProps={{
                        classes: {
                          root: buildupStyles.awbIpRoot,
                          input: buildupStyles.awbIp
                        },
                      }}
                    />
                  </Grid>}
                {props.searchAwb.edit &&
                  <Grid item xs={12} sm justifyContent={'flex-end'}>
                    <CustomInputLabel htmlFor='flight_date' aria-label='Enter flight_date'>Flight Date</CustomInputLabel>
                    <CustomDatePicker
                      slotProps={{
                        textField: {
                          placeholder: 'Date',
                          fullWidth: true,
                          InputProps: {
                            classes: {
                              root: buildupStyles.awbIpRoot,
                              input: `${buildupStyles.awbIp} ${styles.pr0}`
                            }
                          }
                        },
                      }}
                      variant='outlined'
                      id='flight_date'
                      fullWidth
                      name='flight_date'
                      format='DD MMM YYYY'
                      value={dayjs(moment(props.searchAwb?.flight_date).format('YYYY-MM-DD'))}
                      onChange={(v) => props.onChnageSearchAwb({
                        target: {
                          name: 'flight_date',
                          value: moment(v.$d).format('YYYY-MM-DD')
                        }
                      })}
                      className={buildupStyles.awbIpTextfield}
                    />
                  </Grid>}
              </Grid>
              <Grid item xs md={0.5} display={'flex'} justifyContent={'flex-end'} alignItems={'flex-end'} alignSelf={'stretch'}>
                {!props.searchAwb?.edit &&
                  <Box className={buildupStyles.editIcon}>
                    <EditIcon title='Edit Awb' onClick={() => props.onChnageSearchAwb({
                      target: {
                        name: 'edit',
                        value: true
                      }
                    })} />
                  </Box>
                }
                <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} alignItems={'center'} mt={'auto'} mb={1}>
                  {props.searchAwb?.edit && <SaveTickIcon title='Save Awb' onClick={props.onSaveSearchAwb} />}
                  {props.searchAwb?.edit && <ClearXIcon title='Cancel Edit' onClick={() => props.onChnageSearchAwb({
                    target: {
                      name: 'edit',
                      value: false
                    }
                  })} />}
                </Stack>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div>
                <StyledCard className={`${styles.h100} ${styles.modalCard}`}>
                  <StyledCardTitle component='div' className={styles.modalCardTitle}>Loading History</StyledCardTitle>

                  <div className={`${styles.tableWrapper} ${buildupStyles.containerEnd}`}>
                    <Box className={`${styles.tableOuter} ${buildupStyles.awbsTableOuter}`}>
                      <StyledDataGrid
                        {...DataGridProps}
                        rows={props.searchAwb?.buildup_awbs || []}
                        columns={awbDetailsColumns}
                        classes={{
                          root: buildupStyles.dataGridRoot,
                          columnHeaders: buildupStyles.dataColumnHeaders,
                        }}
                        param={props.searchAwb?.buildup_awbs?.length}
                        columnHeaderHeight={0}                       
                      />
                    </Box>
                  </div>
                  <Grid container spacing={2} pt={1}>
                    <Grid item xs={12} sm={12} md={2} lg={2.5}>
                      <Typography variant='body2' fontWeight={400} color={'black'} pl={'4px'}>Remaining to load:</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <Typography variant='body2' fontWeight={700} color={'primary'}>{props.searchAwb?.pcs - props.searchAwb?.buildup_awbs?.reduce((a, b) => parseInt(a) + parseInt(b.pcs), 0) || 0} PCS</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                      <Typography variant='body2' fontWeight={700} color={'primary'}>{(props.searchAwb?.wgt - props.searchAwb?.buildup_awbs?.reduce((a, b) => parseFloat(a) + parseFloat(b.wgt), 0))?.toFixed(2) || 0} K</Typography>
                    </Grid>
                  </Grid>
                </StyledCard>
              </div>
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>
              <StyledCard className={`${styles.h100} ${styles.modalCard}`}>
                <StyledCardTitle className={styles.modalCardTitle}>Load to {props.uldNo || props.awbFields?.buildup_uld?.uld_no}</StyledCardTitle>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <CustomInputLabel htmlFor='pcs' aria-label='Enter pieces'>Pcs</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='pcs'
                      fullWidth
                      value={props?.awbFields?.pcs || ''}
                      name='pcs'
                      onChange={props.onChangeAwb}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                    <CustomInputLabel htmlFor='wgt' aria-label='Enter weight'>Wgt (Optional)</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='wgt'
                      fullWidth
                      value={props?.awbFields?.wgt || ''}
                      name='wgt'
                      onChange={props.onChangeAwb}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={3} xl={3} className={styles.mtauto}>
                    <CustomInputLabel htmlFor='spls' aria-label='Enter remarks'>SPLs</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='spls'
                      fullWidth
                      value={props?.awbFields?.spl_code || ''}
                      name='spl_code'
                      onChange={props.onChangeAwb}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={10} lg={3} xl={3}>
                    <CustomInputLabel htmlFor='remarks' aria-label='Enter remarks'>Remarks</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      id='remark'
                      fullWidth
                      value={props?.awbFields?.remark || ''}
                      name='remark'
                      onChange={props.onChangeAwb}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} lg={2} xl={2} className={styles.mtauto}>
                    <CustomButton variant='contained' color='primary' fullWidth onClick={props.onLoadAwb}>Load</CustomButton>
                  </Grid>
                </Grid>
              </StyledCard>
            </Grid>

          </Grid>

        </CustomDialogContent>

      </Dialog>
    </div>
  );
};
export default AwbDetailModal;