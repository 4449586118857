import React from 'react';
import { CustomInputLabel, CustomTextField, InputProps } from 'custom/components';
import { Grid } from '@mui/material';

const CostManual = (props) => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <CustomInputLabel htmlFor='' aria-label='Other Service Reason' required>Other Service Reason</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='reason'
          fullWidth
          name='reason_value'
          onChange={e=>props.handleChange(props.index, e)}
          value={props.serviceFields.value?.[props.index]?.reason_value}
          error={props.serviceErrors.value?.[props.index]?.reason_value}
          helperText={props.serviceErrors?.value?.[props.index]?.reason_value}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6}>
        <CustomInputLabel htmlFor='' aria-label='Enter Total Cost'>Total Cost</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='cost'
          fullWidth
          name='cost_value'
          onChange={e=>props.handleChange(props.index, e)}
          value={props.serviceFields.value?.[props.index]?.cost_value}
          error={props.serviceErrors.value?.[props.index]?.cost_value}
          helperText={props.serviceErrors?.value?.[props.index]?.cost_value}
        />
      </Grid>
    </Grid>
  );
};

export default CostManual;