import React from 'react';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import styles from 'custom/style.module.css';
import flightMainStyles from './flightreport.module.css';
import { CloseIconBtn } from 'custom/constants';
import DownloadIcon from '@mui/icons-material/Download';
import FlightInfoContainer from '../flights/info/FlightInfoContainer';
import FlightNotesContainer from '../flights/tabs/flightnotes/FlightNotesContainer';
import ManifestSummaryContainer from '../flights/summary/ManifestSummaryContainer';
// import ManifestContainer from '../flights/tabs/manifest/ManifestContainer';
import DiscrepancyContainer from '../flights/tabs/discrepancy/DiscrepancyContainer';
import FlightReportContainer from '../flights/tabs/flightreport/FlightReportContainer';
import Download from '../flights/downloadreport/Invoice';
import moment from 'moment';

const FlightReportPDFDownload = (props) => {
  return (
    <div>
      <Dialog
        open={props.pdfModal}  
        onClose={props.onClose}
        aria-labelledby='Flight Report'
        aria-describedby='Flight Report'
        maxWidth='md'
        fullWidth={true}
      >
        <DialogTitle className={styles.fSpaceBtw} sx={{ borderBottom: '1px solid #f0f0f0' }}>
          <Typography variant='h6' component={'div'} color={'primary'}>Download Flight Report</Typography> 
          <CloseIconBtn onClick={props.onClose} />
        </DialogTitle>
        
        <Box sx={{
          height: '0px',
          visibility: 'hidden',
          overflow: 'hidden', 
        }}>
          <FlightInfoContainer 
            showReportModal={props.showReportModal} 
            printFunction={props.printFunction}
          />
          <ManifestSummaryContainer
            printFunction={props.printFunction} 
          />
          <FlightNotesContainer showReportModal={props.showReportModal} 
            printFunction={props.printFunction} 
          />
          <DiscrepancyContainer 
            showReportModal={props.showReportModal} 
            printFunction={props.printFunction} 
          /> 
          <FlightReportContainer showReportModal={props.showReportModal} 
            printFunction={props.printFunction} />  

          {/*<ManifestContainer
            flightReportDetails={props.flightReportDetails}
            printFunction={props.printFunction} 
          />  */}
        </Box>

        <DialogContent>
          <div className={flightMainStyles.downloadWrapper}>
            <DownloadIcon className={flightMainStyles.downloadIcon} />
            <DialogContentText fontSize={'0.9rem'} textAlign={'center'}>
            Generating Report for download. Please wait...
            </DialogContentText>
            <div className={flightMainStyles.downloadBtnWrapper}>
              <Download
                flightReportDetails={props.flightReportDetails}
                flightInfo={props.flightInfo}
                note={props.note}
                discrepancy={props.discrepancy}
                products={props.products}
                manifest={props.manifest}
                history={props.history}
                statistics={props.statistics}
                flightReport={props.flightReport}
                manifestSummary={props.manifestSummary}
                flight_gate={props.flight_gate}
                nil={props.nil} 
                filename={`Flight Report - ${props.flightInfo?.airline?.code}${props.flightInfo?.flight_no}/${moment(props.flightInfo?.flight_date).format('DDMMMYY').toUpperCase()}`} 
                title='Download'
                ctype={props.ctype}
              />
            </div>
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );
};
export default FlightReportPDFDownload;