import React, { Fragment } from 'react';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from '../../../../../custom/style.module.css';
import { Grid } from '@mui/material';
import { CustomTab } from '../../../../../custom/components';
import EmailTemplateContainer from './tabs/emailTemplate/EmailTemplateContainer';
import ConsigneeContainer from './tabs/consignee/ConsigneeContainer';
import ServiceCatalogContainer from './tabs/serviceCatalog/ServiceCatalogContainer';

const ImportOperations = (props) => {
  const { value } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(path) {
    const index = path.replace('admin/export/', '');
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Tabs value={value} onChange={(_, v) => props.handleChange(v)}
            ref={props.elementRef}
            className={`${styles.customTabs} ${styles.dFlex}`}
            // variant='scrollable'
            variant='fullWidth'
            aria-label='Flight tabs'
            indicatorColor='transparent'
            classes={{ scroller: styles.tabFlexContainer }}
          >
            <CustomTab label={'Consignee'} {...a11yProps('consignee')} />
            <CustomTab label={'Notification Email Template'} {...a11yProps('notify_email_temp')} />
            <CustomTab label={'Service Catalog'} {...a11yProps('service_catalog')} />
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <TabPanel value={value} index={'consignee'}>
            <ConsigneeContainer /> 
          </TabPanel>
          <TabPanel value={value} index={'notify_email_temp'}>
            <EmailTemplateContainer /> 
          </TabPanel>
          <TabPanel value={value} index={'service_catalog'}>
            <ServiceCatalogContainer />
          </TabPanel>
        </Grid>

      </Grid>
      
    </>
  );
};

export default ImportOperations;
