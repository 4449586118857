import React from 'react';
import Box from '@mui/material/Box';
import { Tabs, Typography, IconButton, Grid, SnackbarContent, Stack, CircularProgress, Checkbox } from '@mui/material';
import styles from '../../../custom/style.module.css';
import adminStyles from '../admin/tabs/admin.module.css'; // change this
import manifestStyles from './tabs/manifest/manifest.module.css';
import flightStyles from './flightsfolder.module.css';
import { UploadIcon, flightTabs, pagelist } from 'custom/constants';
import FlightsNotesContainer from './tabs/flightnotes/FlightNotesContainer';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import theme from 'custom/theme';
import { CustomCheckbox, CustomTab } from 'custom/components';
import ManifestContainer from './tabs/manifest/ManifestContainer';
import FlightInfoContainer from './info/FlightInfoContainer';
import DiscrepancyContainer from './tabs/discrepancy/DiscrepancyContainer';
import FlightReportContainer from './tabs/flightreport/FlightReportContainer';
import cookies from 'utils/cookies';
import FlightModal from './FlightModal';
import ConfirmModalContainer from '../common/confirmModal/ConfirmModalContainer';
import History from './History';
import BuildUpContainer from './tabs/buildup/BuildUpContainer';
import ImportNotificationContainer from '../importnotification/ImportNotificationContainer';

let filteredpagelist = pagelist.filter((page) => page?.roles.includes(cookies.get('role')));

const Flights = (props) => {
  const { value, handleUpload } = props;
  function TabPanel(tabPanelProps) {
    const { children, index, ...other } = tabPanelProps;
    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {tabPanelProps.value === index && (
          <Box sx={{ pt: 0 }} className={styles.positionRelative}>
            <Typography component='span'>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(path) {
    const index = path.replace('flight_folder/', '');
    return {
      id: `simple-tab-${index}`,
      value: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <ConfirmModalContainer />
      <FlightModal
        modal={props.modal}
        modalData={props.modalData}
        closeModal={props.closeModal}
        handleFlight={props.handleFlight}
        selected_flight={props.selected_flight}
      />
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ position: 'relative' }}>
              <Box sx={{
                zIndex: 1,
                position: 'absolute',
                top: '-4px'
              }}>
                <History
                  id={props.flightData?.id}
                  printFunction={props.printFunction}
                  history_data={props.history_data}
                  fetchHistory={props.fetchHistory}
                  loadingHistory={props.loadingHistory}
                />
              </Box>
              <FlightInfoContainer handleSearch={props.fetchFlightData} showReportModal={props.showReportModal} ctype={props.ctype} handleCtype={props.handleCtype} />
            </Box>
          </Grid>
          <Grid item xs={12} className={styles.pt0}>
            <Box>
              <Tabs value={value} onChange={(_, v) => props.handleChange(v)}
                className={`${styles.customTabs} ${manifestStyles.hidePrint}`}
                variant='fullWidth'
                aria-label='Flight tabs'
                indicatorColor='transparent'
                ScrollButtonComponent={(props) => {
                  if (
                    props.direction === 'left'
                  ) {
                    return (
                      <IconButton {...props} className={adminStyles.tabArrows} disableRipple>
                        <ArrowLeftIcon
                          sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                        />
                      </IconButton>
                    );
                  } else if (
                    props.direction === 'right'
                  ) {
                    return (
                      <IconButton {...props} className={adminStyles.tabArrows} disableRipple>
                        <ArrowRightIcon
                          sx={{ color: props.disabled ? 'disabled' : theme.palette.primary.main }}
                        />
                      </IconButton>
                    );
                  } else {
                    return null;
                  }
                }}>
                {
                  filteredpagelist.find((page) => page.title === 'My Flights | Notes') &&
                  <CustomTab label={flightTabs.notes} className={flightStyles.flightTabs} {...a11yProps('flight_folder/notes')} />
                }
                {
                  filteredpagelist.find((page) => page.title === 'My Flights | Buildup') &&
                  <CustomTab label={flightTabs.buildup} className={flightStyles.flightTabs + ' ' + flightStyles.fightTabHide} {...a11yProps('flight_folder/buildup')} />
                }
                {
                  filteredpagelist.find((page) => page.title === 'My Flights | Import Notification') &&
                  <CustomTab label={flightTabs.import_notification} className={flightStyles.flightTabs + ' ' + flightStyles.fightTabHide} {...a11yProps('flight_folder/import_notification')} />
                }
                {
                  filteredpagelist.find((page) => page.title === 'My Flights | Manifest') &&
                  <CustomTab className={`${styles.positionRelative} ${flightStyles.flightTabs}`}
                    label={
                      <div>
                        <div className={styles.fColumn}>
                          <CustomCheckbox
                            control={
                              <Checkbox inputProps={{ 'aria-label': 'nil manifest', }} checked={props.nil} onChange={props.onChangeNil} name='nil_manifest'
                                sx={{
                                  paddingTop: '6px',
                                  paddingBottom: '6px',
                                  color: value === 'manifest' ? '#fff' : '#979797',
                                  '&.Mui-checked': { color: value === 'manifest' ? '#fff' : '#979797', },
                                }}
                                size='small'
                              />
                            }
                            sx={{ color: value === 'manifest' ? '#fff' : '#979797', }}
                            className={`${flightStyles.nilCheckbox}`}
                            classes={{ label: flightStyles.whiteCheckboxLabel }}
                            label={'NIL'}
                          />
                          <div>{flightTabs.manifest}</div>
                          <Typography id='ffm-note' variant='caption' component='span' className={flightStyles.manifestUpload}></Typography>
                        </div>
                        <div className={`${styles.activeTabIcon} ${styles.tabIcon}`}>
                          {props.loadingManifest ?
                            <CircularProgress size='20px' /> :
                            <>
                              <input
                                accept='application/pdf'
                                style={{ display: 'none' }}
                                type='file'
                                id='ffm-file'
                                name='file'
                                onChange={handleUpload}
                                disabled={props.nil}
                              />
                              <label htmlFor='ffm-file' className={`${styles.fs0} ${props.nil ? styles.disableIconBtn : ''}`}>
                                <UploadIcon />
                              </label>
                            </>
                          }
                        </div>
                      </div>
                    }
                    {...a11yProps('flight_folder/manifest')} />
                }
                {
                  filteredpagelist.find((page) => page.title === 'My Flights | Discrepancies') &&
                  <CustomTab label={flightTabs.discrepancies} className={flightStyles.flightTabs} {...a11yProps('flight_folder/discrepancies')} />
                }
                {
                  filteredpagelist.find((page) => page.title === 'My Flights | Flight Report') &&
                  <CustomTab label={flightTabs.flt_report} className={flightStyles.flightTabs} {...a11yProps('flight_folder/flt_report')} />
                }
              </Tabs>
            </Box>
          </Grid>
          {
            // props.flightData && props.flightData?.reports?.find(report => report.type === props.ctype)?.status === 'Rejected' 
            props.showRejected && (
              <Grid item xs={12}>
                <SnackbarContent
                  classes={{
                    root: styles.errorSnackbar,
                    message: styles.errorSnackbarMessage,
                  }}
                  message={(
                    <Stack direction={'column'} justifyContent={'center'}>
                      <Typography variant='body2' component='span' color='error' textAlign={'center'} fontWeight={'bold'}
                        display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                        <span>CORRECTION REQUESTED FOR THE FLIGHT REPORT</span>
                        <Typography variant='caption' component='span' color='#000000b5'>
                          By: {props.lastRejectedLog?.user?.first_name} {props.lastRejectedLog?.user?.last_name}
                        </Typography>
                        <Typography variant='caption' component='span' color='#000000b5'> Reason: {props.rejectedReason}</Typography>
                      </Typography>
                    </Stack>
                  )}
                />
              </Grid>
            )}
          {
            props.nil && (
              <Grid item xs={12}>
                <SnackbarContent
                  classes={{
                    root: styles.warningSnackbar,
                    message: styles.warningSnackbarMessage,
                  }}
                  message={(
                    <Stack direction={'column'} justifyContent={'center'}>
                      <Typography variant='body2' component='span' color='#ff8200' textAlign='center' textTransform='uppercase' fontWeight='bold'>
                        nil manifest</Typography>
                      {/* <Typography variant='caption' component='span' color='#000000b5' textAlign='center'>
                          Some text here
                      </Typography> */}
                    </Stack>
                  )}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <TabPanel value={value} index={'notes'}>
              <FlightsNotesContainer showReportModal={props.showReportModal} ctype={props.ctype} />
            </TabPanel>
            <TabPanel value={value} index={'buildup'}>
              <BuildUpContainer showReportModal={props.showReportModal} ctype={props.ctype} />
            </TabPanel>
            <TabPanel value={value} index={'import_notification'}>
              <ImportNotificationContainer flightTab={true} />
            </TabPanel>
            <TabPanel value={value} index={'manifest'}>
              <ManifestContainer fetchManifest={props.fetchManifestData} showReportModal={props.showReportModal} ctype={props.ctype} nil={props.nil} />
            </TabPanel>
            <TabPanel value={value} index={'discrepancies'} >
              <DiscrepancyContainer showReportModal={props.showReportModal} getULDData={props.getULDData} ctype={props.ctype} nil={props.nil} />
            </TabPanel>
            <TabPanel value={value} index={'flt_report'}>
              <FlightReportContainer showReportModal={props.showReportModal} handleOpen={props.handleOpen} ctype={props.ctype} nil={props.nil} history_data={props.history_data} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>

      {/* <ConfirmModalContainer /> */}
    </>
  );
};

export default Flights;
