import React from 'react';
import events from 'utils/evemts';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
// import cookies from 'utils/cookies';
import LoadingAccessories from './LoadingAccessories';
import { cloneDeep } from 'lodash';

class LoadingAccessoriesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      station: [],
      airline: [],
      accessories: [],
      splCodes: [],
      modal: false,
      action: null,
      accessoriesField: {},
      accessoriesErrors: {},
      specialsField: {},
      specialsErrors: {},
      activeAirline: null,
      activeProd: null,
      anchorEl: null,
      location: false,
      locationType: null,
    };
    this.eventSource = null;
  }

  componentDidMount() {
    console.log(process.env);
    this.fetchAirline();
    // this.fetchSpecials ();
    this.eventSource = events('admin', 'loading_accessories');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.data) {
        if (msg.action == 'ADD' && msg.data.airlineId === this.state.activeAirline?.id) {
          this.setState({ accessories: [...this.state.accessories, msg.data] });
          //do something
        }
        if (msg.action == 'UPDATE') {
          const accessories = [...this.state.accessories];
          const index = accessories.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            accessories[index] = { ...msg.data };
            this.setState({ accessories });
          }
        }
        if (msg.action == 'DELETE') {
          const accessories = [...this.state.accessories];
          const index = accessories.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            accessories.splice(index, 1);
            this.setState({ accessories });
          }
        }
      }
    };
  }

  componentWillUnmount() {
    if (this.eventSource)
      this.eventSource.close();
  }

  fetchAirline = () => {
    http.get(`/airline?active=${true}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ airline: data.data }, () => {
            if (this.state.airline.length > 0) {
              this.fetchAccessories(this.state.airline[0]);
            }
          });
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  fetchAccessories = (row = {}) => {
    this.setState({ activeAirline: row });
    let id = row?.id;
    if (row?.id === -1 || !row?.id) {
      id = 'ALL';
    }

    // http.get('/airlineaccessoriess')
    http.get(`/loading_accessories?airlineId=${id}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ accessories: data.data });

        }
      })
      .catch(error => {
        this.setState({ accessories: [] });
        console.error('Error fetching data:', error);
      });
  };


  onChangeAccessories = (e) => {
    let prev = cloneDeep(this.state.accessoriesField);
    let value = e.target.value;
    if (e.target.name === 'billable') {
      value = e.target.checked ? true : false;
    }
    this.setState({
      accessoriesField: {
        ...prev,
        [e.target.name]: value
      }
    });
  };

  validationAccessories = () => {
    const accessoriesFields = { ...this.state.accessoriesField };
    const error = {};
    let formValid = true;
    if (!accessoriesFields.description || (accessoriesFields.description.trim().length === 0)) {
      formValid = false;
      error.description = 'Please enter a description.';
    }
    this.setState({ accessoriesErrors: error });
    return formValid;
  };

  addAccessories = async (e) => {
    e.preventDefault();
    let formdata = {
      ...this.state.accessoriesField,
      airlineId: this.state.activeAirline.id === -1 ? 'ALL' : this.state.activeAirline.id
    };
    if (this.validationAccessories()) {
      http.post('/loading_accessories', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            accessoriesField: {},
            accessoriesErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  editAccessories = async (e) => {
    e.preventDefault();
    let formdata = {
      ...this.state.accessoriesField,
      airlineId: this.state.activeAirline.id === null ? null : this.state.activeAirline.id
    };
    // delete formdata.station;
    if (this.validationAccessories()) {
      http.put('/loading_accessories/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            accessoriesField: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  deleteAccessories = (id) => {
    http.delete(`/loading_accessories/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleDelete = (type, id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => {
        switch (type) {
          case 'airline':
            this.deleteAirline(id);
            break;
          case 'accessories':
            this.deleteAccessories(id);
            break;
          default:
            break;
        }
      },
    });
  };

  validationSpecials = () => {
    const specialsField = { ...this.state.specialsField };
    const error = {};
    let formValid = true;
    if (!specialsField.name || (specialsField.name.trim().length === 0)) {
      formValid = false;
      error.name = 'Please enter a special code.';
    }
    if (specialsField.name && (specialsField.name.trim().length < 3)) {
      formValid = false;
      error.name = 'Special code should not be more than 3 characters.';
    }
    if (!specialsField.product || (specialsField.product.toString().trim().length === 0)) {
      formValid = false;
      error.product = 'Please enter a product.';
    }
    if (!specialsField.airline || (specialsField.airline.toString().trim().length === 0)) {
      formValid = false;
      error.airline = 'Please select an airline.';
    }
    this.setState({ specialsErrors: error });
    return formValid;
  };

  addSpecials = async (e) => {
    e.preventDefault();
    let formdata = { ...this.state.specialsField };
    if (this.validationSpecials()) {
      http.post('/special_code', formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            specialsField: {},
            specialsErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  editSpecials = async (e) => {
    e.preventDefault();
    let formdata = {
      id: this.state.specialsField.id,
      name: this.state.specialsField.name,
      product: this.state.specialsField.productId,
      airlineId: this.state.specialsField.airlineId
    };
    // delete formdata.station;
    if (this.validationSpecials()) {
      http.put('/special_code/' + formdata.id, formdata).then(({ data }) => {
        if (data.status == 'success') {
          this.setState({
            modal: false,
            specialsField: {},
            specialsErrors: {},
            action: null,
          });
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  deleteSpecials = (id) => {
    http.delete(`/special_code/${id}`).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete();
      }
    });
  };

  handleLocation = (locationType, v, data = {}, anchorEl = null) => {
    this.setState({
      location: v,
      locationType,
      station: data,
      anchorEl
    });
  };

  handleOpen = (modal, action, data = {}) => {
    this.setState({
      modal,
      action,
      accessoriesField: { ...data, },
      specialsField: { ...data }
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
      accessoriesField: {},
      accessoriesErrors: {},
    });
  };

  render() {
    return (
      <>
        <LoadingAccessories
          stations={this.props.stations}
          station={this.state.station}
          anchorEl={this.state.anchorEl}
          modal={this.state.modal}
          action={this.state.action}
          location={this.state.location}
          locationType={this.state.locationType}
          handleOpen={this.handleOpen}
          onClose={this.onClose}
          handleLocation={this.handleLocation}
          airline={this.state.airline}
          accessories={this.state.accessories}
          splCodes={this.state.splCodes}
          accessoriesField={this.state.accessoriesField}
          accessoriesErrors={this.state.accessoriesErrors}
          addAccessories={this.addAccessories}
          editAccessories={this.editAccessories}
          onChangeAccessories={this.onChangeAccessories}
          activeAirline={this.state.activeAirline}
          handleDelete={this.handleDelete}
          fetchAccessories={this.fetchAccessories}
          specialsField={this.state.specialsField}
          specialsErrors={this.state.specialsErrors}
          addSpecials={this.addSpecials}
          editSpecials={this.editSpecials}
          onChangeSpecials={this.onChangeSpecials}
          fetchSpecials={this.fetchSpecials}
          activeProd={this.state.activeProd}

          handleChange={this.handleChange}
          value={this.state.value}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { stations: state.stations };
};

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingAccessoriesContainer);
