import React from 'react';
import { CustomInputLabel, CustomTextField, InputProps } from 'custom/components';
import { Divider, Grid } from '@mui/material';
import { getFee } from '../../../../utils/feeCalculator';
import moment from 'moment';
import { DateFormat } from 'custom/constants';

const KgDay = (props) => {
  const feeInfo = getFee(props.fee, [], props.awb, true);
  return (
    <Grid container spacing={2}>      
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='AWB Received Date' required>AWB Received Date </CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={moment(feeInfo?.awb_recv).format(DateFormat).toUpperCase()}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Scheduled Pick Up'>Scheduled Pick Up </CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={moment(feeInfo?.pickupdate).format(DateFormat).toUpperCase()}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Storage Days'>Storage Days</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={feeInfo?.chargableDays}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Cost per Unit '> Cost per Unit </CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={'$ ' + parseFloat(feeInfo?.cost_per_unit)?.toFixed(2) +' / 1 KG'}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Total AWB weight '> Total AWB weight </CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={props.awb?.wgt}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Calculated Total Cost '> Calculated Total Cost </CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={'$ ' + parseFloat(feeInfo?.storageFee)?.toFixed(2)}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='MIN Cost per Unit Per Day '> MIN Cost per Unit Per Day </CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={'$ ' + parseFloat(feeInfo?.mim_cost_per_day)?.toFixed(2) +' / AWB / Day'}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='MIN Storage Cost'> MIN Storage Cost </CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={'$ ' + parseFloat(feeInfo?.totalMinCost)?.toFixed(2)}
        />
      </Grid>
      <Grid xs={12} sm={8}>
        <Divider sx={{ marginTop: '50px' }} />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Total Cost'>Total Cost </CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={'$ ' + parseFloat(Math.max(feeInfo?.storageFee, feeInfo?.totalMinCost))?.toFixed(2)}
        />
      </Grid>
    </Grid>
  );
};

export default KgDay;