import React from 'react';

// import { withStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
// import FormControl from '@material-ui/core/FormControl';

// import Reicing from '../reicing/Reicing';
// import SkidExchange from '../skidExchange/SkidExchange';
// import PayByHouseBill from '../payByHouseBill/PayByHouseBill';
// import BupBreakdownLD3 from '../bupBreakdownLD3/BupBreakdownLD3';
// import BupBreakdownLD7 from '../bupBreakdownLD7/BupBreakdownLD7';
// import BupBreakdownMain from '../bupBreakdownMain/BupBreakdownMain';

// import AddServicesUnits from '../../../../models/AddServicesUnits';

// import commonAddServiceStyles from './commonAdditionalService.module.css';
// import commonStyles from '../../../common/common.module.css';
// import { formatCurrency } from '../../../../utils/currencyFormatter';
// import BupBreakdown20ft from '../bupBreakdown20ft/BupBreakdown20ft';
// import BupBreakdownQ7 from '../bupBreakdownQ7/BupBreakdownQ7';
// import LabelFreight from '../labelFreight/LabelFreight';
import { CustomInputLabel, CustomTextField, InputProps } from 'custom/components';
import { Grid } from '@mui/material';

// const TEXT_FIELD_PADDINGS = '8px 12px';

const Number = (props) => {

  return (
    <Grid container alignItems='flex-end' spacing={2}>

      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Enter Nbr Units'>Cost Per Unit</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          disabled
          fullWidth
          value={'$ ' + parseFloat(props.serviceFields.value?.[props.index]?.cost_per_unit)?.toFixed(2) + ' / ' + props.serviceFields.value?.[props.index]?.unit_key}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Enter Nbr Units' required>Enter Nbr Units</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          id='unit_value'
          fullWidth
          name='unit_value'
          onChange={e => props.handleChange(props.index, e)}
          value={props.serviceFields.value?.[props.index]?.unit_value}
          error={props.serviceErrors.value?.[props.index]?.unit_value}
          helperText={props.serviceErrors?.value?.[props.index]?.unit_value}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <CustomInputLabel htmlFor='' aria-label='Enter Total Cost'>Total Cost</CustomInputLabel>
        <CustomTextField
          {...InputProps}
          fullWidth
          disabled
          value={'$ ' + parseFloat((props.serviceFields.value?.[props.index]?.cost_per_unit) * (props.serviceFields.value?.[props.index]?.unit_value || 0))?.toFixed(2)}
        />
      </Grid>
    </Grid>
  );
};

export default Number;