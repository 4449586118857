import React from 'react';
import ImportOperations from './ImportOperations';

class ImportOperationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // value: this.props.match.params.tab || 'loading_accessories',
      value: 'consignee',
    };
  }


  handleChange = (newValue) => {
    // if (this.props.match.url.includes('loading_acc')) {
    //   this.props.history.replace(`${newValue}`);
    // } else {
    //   this.props.history.replace(`admin/export/${newValue}`);
    // }
    this.setState({ value: newValue });
  };

  render() {
    return (
      <>
        <ImportOperations           
          handleChange={this.handleChange}
          value={this.state.value} 
        />
      </>
    );
  }
}

export default ImportOperationContainer;
