import React from 'react';
import { Dialog, Grid, IconButton, Stack, styled, Typography } from '@mui/material';
import styles from '../../../../../../../custom/style.module.css';
import { CustomButton, CustomDialogAction, CustomDialogContent, CustomDialogTitle, StyledCard } from '../../../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';
import dayjs from 'dayjs';
import { PickersDay, StaticDatePicker } from '@mui/x-date-pickers';
import importStyles from './servicecatalog.module.css';
import { Cancel } from '@mui/icons-material';

const SelectHolidayModal = (props) => {

  const HighlightedDay = styled(PickersDay)(({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  }));
  const { action } = props;

  const renderHolidays = (selectedHolidays) => {
    // get years from selected holidays and remove duplicates
    const years = [...new Set(selectedHolidays.map(holiday => dayjs(holiday).year()))];
    const year_months = [...new Set(selectedHolidays.map(holiday => dayjs(holiday).format('YYYY-MM')))];
    return years.map((year, index) => (
      <Stack key={index} direction='column' spacing={2}>
        <Typography variant='body1' textTransform='uppercase' fontWeight={600}>{year}</Typography>
        <div className={importStyles.datesWrapper}>
          {year_months.sort((a, b) => new Date(a) - new Date(b)).filter(month => dayjs(month).year() === year).map((month, index) => (
            <Stack key={index} direction={'column'}>
              <Typography variant='body1' textTransform='uppercase' fontWeight={600} pb={0.2}>{dayjs(month).format('MMMM')}</Typography>
              <Stack direction='row' spacing={2}>
                {selectedHolidays.filter(holiday => dayjs(holiday).year() === year && dayjs(holiday).format('YYYY-MM') === month)
                  .sort((a, b) => new Date(a) - new Date(b))
                  .map((holiday, index) => (
                    <Stack key={index} direction='column' alignItems='center' spacing={0.4}>
                      <div className={styles.positionRelative}>
                        <Typography variant='body1' fontWeight={700} className={importStyles.date}>{dayjs(holiday).format('DD')}</Typography>
                        <IconButton size='small' onClick={() => props.removeHoliday(holiday)}
                          className={importStyles.removeHoliday}
                          disableRipple={true}
                        >
                          <Cancel sx={{ fontSize: 14 }} />
                        </IconButton>
                      </div>
                      <Typography variant='caption' color={'text.secondary'}>{dayjs(holiday).format('ddd')}</Typography>
                    </Stack>
                  ))}
              </Stack>
            </Stack>
          ))}
        </div>
      </Stack>
    ));
  };

  //higlight the dates in highlightedDays array
  const SelectedDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.includes(day.format('YYYY-MM-DD'));

    return (
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
      />
    );
  };

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal == 'holidays'} onClose={props.onClose} maxWidth={'lg'} fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          {action == 'add' ? 'Add' : 'Edit'} Holidays
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <form onReset={props.onClose} className={styles.w100} onSubmit={props.handleSubmitHolidays}>
          <CustomDialogContent>
            <StyledCard>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <StaticDatePicker orientation='landscape'
                    className={importStyles.datePicker}
                    onChange={props.handleHolidays}
                    slotProps={{ 
                      actionBar: { actions: [] },
                      day: { highlightedDays: props.selectedHolidays, }, 
                    }}
                    slots={{ day: SelectedDay }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Stack direction='row' spacing={2}>
                    <Stack direction='column' spacing={2}>
                      {renderHolidays(props.selectedHolidays)}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </StyledCard>
          </CustomDialogContent>
          <CustomDialogAction className={`${styles.fSpaceBtw}`}>
            <CustomButton variant='contained' type='reset'> Close</CustomButton>
            <CustomButton variant='contained' type='submit'>Submit</CustomButton>
          </CustomDialogAction>
        </form>
      </Dialog>
    </div>
  );
};
export default SelectHolidayModal;