import React from 'react';
import { Stack, Box, Grid, IconButton, Radio } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, DataGridProps } from '../../../../../../../custom/components';
import { EditIcon, DeleteIcon, AddIcon, } from '../../../../../../../custom/constants';
import styles from '../../../../../../../custom/style.module.css';
import importStyles from './servicecatalog.module.css';
import { CalendarMonth } from '@mui/icons-material';
import AdditionalService from './AdditionalService';

const ServiceCatalog = (props) => {
  const { serviceFields, handleOpen, services, handleDeleteService, handleOpenDialog } = props;

  const columns = [
    {
      field: '',
      headerName: '',
      width: 50,
      minWidth: 50,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Radio size='small' className={styles.p0}
            checked={serviceFields?.id === row?.id}
          />
        );
      }
    },
    {
      field: 'key',
      headerName: 'Service',
      minWidth: 100,
      flex: 2
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'right',
      headerAlign: 'right',
      // minWidth: 100, 
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <EditIcon onClick={() => { handleOpen('additionalservices', 'edit', row); }} />
            <DeleteIcon type='button' onClick={(e) => { handleDeleteService(row.id, e.target); }} />
          </Stack>

        );
      }
    },
  ];

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledCard className={styles.h100}>
            <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
              <StyledCardTitle gutterBottom variant='cardTitle'>Service Catalog</StyledCardTitle>
              <Stack direction='row' spacing={2} justifyContent='center'>
                <AddIcon className={styles.addIcon} title='Add Service Catalog' onClick={() => handleOpen('additionalservices', 'add')} />
              </Stack>
            </div>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${importStyles.addServicesTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={services}
                  columns={columns}
                  onRowClick={({ row }) => {
                    handleOpen('additionalservices', 'edit', row);
                  }}
                  param={services?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={8}>
          <StyledCard className={styles.h100}>
            <Stack direction='row' spacing={2} justifyContent='space-between'>
              <StyledCardTitle className={styles.pb2}>Service Details</StyledCardTitle>
              <IconButton title='Add holidays' color='primary' disableRipple onClick={() => handleOpenDialog('holidays')} className={styles.p0}>
                <CalendarMonth fontSize='1.7rem' />
              </IconButton>
            </Stack>
            <AdditionalService
              serviceFields={props.serviceFields}
              serviceFieldsErrors={props.serviceFieldsErrors}
              onChange={props.onChange}
              addService={props.addService}
              editService={props.editService}
              onClose={props.onClose}
              services={props.services}
              addOperation={props.addOperation}
              removeOperation={props.removeOperation}
              onChangeOperation={props.onChangeOperation}
            />

          </StyledCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceCatalog;