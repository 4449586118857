import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Admin from '../assets/images/menuIcons/settings.svg';
import Docs from '../assets/images/menuIcons/docs.svg';
import DocArchive from '../assets/images/menuIcons/docArchive.svg';
// import Reports from '../assets/images/menuIcons/reports.svg';
import Flights from '../assets/images/menuIcons/flights.svg';
import Add from '../assets/images/actionIcons/add.svg';
import Lock from '../assets/images/actionIcons/lock.svg';
import UnLock from '../assets/images/actionIcons/unlock.svg';
import Key from '../assets/images/actionIcons/key.svg';
import Location from '../assets/images/actionIcons/globe.svg';
import Edit from '../assets/images/actionIcons/edit.svg';
import Delete from '../assets/images/actionIcons/delete.svg';
import SaveTick from '../assets/images/actionIcons/tick.svg';
import Tick from '../assets/images/actionIcons/greentick.svg';
import UnTick from '../assets/images/actionIcons/roundeduntick.svg';
import Doc from '../assets/images/actionIcons/doc.svg';
import ViewGreen from '../assets/images/actionIcons/show.svg';
import View from '../assets/images/actionIcons/showBlue.svg';
import ArrowTop from '../assets/images/actionIcons/arrowTop.svg';
import WhiteCamera from '../assets/images/actionIcons/whiteCamera.svg';
import WhiteEdit from '../assets/images/actionIcons/whiteEdit.svg';
import WhiteDoc from '../assets/images/actionIcons/whiteDoc.svg';
import Bell from '../assets/images/actionIcons/bell.svg';
import Page from '../assets/images/actionIcons/page.svg';
import Camera from '../assets/images/actionIcons/camera.svg';
import Eco from '../assets/images/actionIcons/eco.svg';
import StickyNote from '../assets/images/actionIcons/stickyNote.svg';
import Excel from '../assets/images/excel.svg';
import PDF from '../assets/images/pdf.svg';
import Download from '../assets/images/actionIcons/download.svg';
import Upload from '../assets/images/actionIcons/upload.svg';
import Bino from '../assets/images/actionIcons/bino.svg';
import DollarRate from '../assets/images/actionIcons/dollar.svg';
import Exclamation from '../assets/images/exclamation.svg';
import { CloseIconProps } from './components';
import CloseIcon from '@mui/icons-material/Close';
import http from '../utils/http';
import cookies from '../utils/cookies';

import styles from './style.module.css';
import { AttachFile, MoreHoriz, Receipt, Verified, WebStories } from '@mui/icons-material';

export const appName = 'CABA';

// country code reference >> https://gist.github.com/anubhavshrimal/75f6183458db8c453306f93521e93d37
export const countryCode = [
  {
    'name': 'Afghanistan',
    'dial_code': '+93',
    'code': 'AF'
  },
  {
    'name': 'Aland Islands',
    'dial_code': '+358',
    'code': 'AX'
  },
  {
    'name': 'Albania',
    'dial_code': '+355',
    'code': 'AL'
  },
  {
    'name': 'Algeria',
    'dial_code': '+213',
    'code': 'DZ'
  },
  {
    'name': 'AmericanSamoa',
    'dial_code': '+1684',
    'code': 'AS'
  },
  {
    'name': 'Andorra',
    'dial_code': '+376',
    'code': 'AD'
  },
  {
    'name': 'Angola',
    'dial_code': '+244',
    'code': 'AO'
  },
  {
    'name': 'Anguilla',
    'dial_code': '+1264',
    'code': 'AI'
  },
  {
    'name': 'Antarctica',
    'dial_code': '+672',
    'code': 'AQ'
  },
  {
    'name': 'Antigua and Barbuda',
    'dial_code': '+1268',
    'code': 'AG'
  },
  {
    'name': 'Argentina',
    'dial_code': '+54',
    'code': 'AR'
  },
  {
    'name': 'Armenia',
    'dial_code': '+374',
    'code': 'AM'
  },
  {
    'name': 'Aruba',
    'dial_code': '+297',
    'code': 'AW'
  },
  {
    'name': 'Australia',
    'dial_code': '+61',
    'code': 'AU'
  },
  {
    'name': 'Austria',
    'dial_code': '+43',
    'code': 'AT'
  },
  {
    'name': 'Azerbaijan',
    'dial_code': '+994',
    'code': 'AZ'
  },
  {
    'name': 'Bahamas',
    'dial_code': '+1242',
    'code': 'BS'
  },
  {
    'name': 'Bahrain',
    'dial_code': '+973',
    'code': 'BH'
  },
  {
    'name': 'Bangladesh',
    'dial_code': '+880',
    'code': 'BD'
  },
  {
    'name': 'Barbados',
    'dial_code': '+1246',
    'code': 'BB'
  },
  {
    'name': 'Belarus',
    'dial_code': '+375',
    'code': 'BY'
  },
  {
    'name': 'Belgium',
    'dial_code': '+32',
    'code': 'BE'
  },
  {
    'name': 'Belize',
    'dial_code': '+501',
    'code': 'BZ'
  },
  {
    'name': 'Benin',
    'dial_code': '+229',
    'code': 'BJ'
  },
  {
    'name': 'Bermuda',
    'dial_code': '+1441',
    'code': 'BM'
  },
  {
    'name': 'Bhutan',
    'dial_code': '+975',
    'code': 'BT'
  },
  {
    'name': 'Bolivia, Plurinational State of',
    'dial_code': '+591',
    'code': 'BO'
  },
  {
    'name': 'Bosnia and Herzegovina',
    'dial_code': '+387',
    'code': 'BA'
  },
  {
    'name': 'Botswana',
    'dial_code': '+267',
    'code': 'BW'
  },
  {
    'name': 'Brazil',
    'dial_code': '+55',
    'code': 'BR'
  },
  {
    'name': 'British Indian Ocean Territory',
    'dial_code': '+246',
    'code': 'IO'
  },
  {
    'name': 'Brunei Darussalam',
    'dial_code': '+673',
    'code': 'BN'
  },
  {
    'name': 'Bulgaria',
    'dial_code': '+359',
    'code': 'BG'
  },
  {
    'name': 'Burkina Faso',
    'dial_code': '+226',
    'code': 'BF'
  },
  {
    'name': 'Burundi',
    'dial_code': '+257',
    'code': 'BI'
  },
  {
    'name': 'Cambodia',
    'dial_code': '+855',
    'code': 'KH'
  },
  {
    'name': 'Cameroon',
    'dial_code': '+237',
    'code': 'CM'
  },
  {
    'name': 'Canada',
    'dial_code': '+1',
    'code': 'CA'
  },
  {
    'name': 'Cape Verde',
    'dial_code': '+238',
    'code': 'CV'
  },
  {
    'name': 'Cayman Islands',
    'dial_code': '+ 345',
    'code': 'KY'
  },
  {
    'name': 'Central African Republic',
    'dial_code': '+236',
    'code': 'CF'
  },
  {
    'name': 'Chad',
    'dial_code': '+235',
    'code': 'TD'
  },
  {
    'name': 'Chile',
    'dial_code': '+56',
    'code': 'CL'
  },
  {
    'name': 'China',
    'dial_code': '+86',
    'code': 'CN'
  },
  {
    'name': 'Christmas Island',
    'dial_code': '+61',
    'code': 'CX'
  },
  {
    'name': 'Cocos (Keeling) Islands',
    'dial_code': '+61',
    'code': 'CC'
  },
  {
    'name': 'Colombia',
    'dial_code': '+57',
    'code': 'CO'
  },
  {
    'name': 'Comoros',
    'dial_code': '+269',
    'code': 'KM'
  },
  {
    'name': 'Congo',
    'dial_code': '+242',
    'code': 'CG'
  },
  {
    'name': 'Congo, The Democratic Republic of the Congo',
    'dial_code': '+243',
    'code': 'CD'
  },
  {
    'name': 'Cook Islands',
    'dial_code': '+682',
    'code': 'CK'
  },
  {
    'name': 'Costa Rica',
    'dial_code': '+506',
    'code': 'CR'
  },
  {
    'name': 'Cote d\'Ivoire',
    'dial_code': '+225',
    'code': 'CI'
  },
  {
    'name': 'Croatia',
    'dial_code': '+385',
    'code': 'HR'
  },
  {
    'name': 'Cuba',
    'dial_code': '+53',
    'code': 'CU'
  },
  {
    'name': 'Cyprus',
    'dial_code': '+357',
    'code': 'CY'
  },
  {
    'name': 'Czech Republic',
    'dial_code': '+420',
    'code': 'CZ'
  },
  {
    'name': 'Denmark',
    'dial_code': '+45',
    'code': 'DK'
  },
  {
    'name': 'Djibouti',
    'dial_code': '+253',
    'code': 'DJ'
  },
  {
    'name': 'Dominica',
    'dial_code': '+1767',
    'code': 'DM'
  },
  {
    'name': 'Dominican Republic',
    'dial_code': '+1849',
    'code': 'DO'
  },
  {
    'name': 'Ecuador',
    'dial_code': '+593',
    'code': 'EC'
  },
  {
    'name': 'Egypt',
    'dial_code': '+20',
    'code': 'EG'
  },
  {
    'name': 'El Salvador',
    'dial_code': '+503',
    'code': 'SV'
  },
  {
    'name': 'Equatorial Guinea',
    'dial_code': '+240',
    'code': 'GQ'
  },
  {
    'name': 'Eritrea',
    'dial_code': '+291',
    'code': 'ER'
  },
  {
    'name': 'Estonia',
    'dial_code': '+372',
    'code': 'EE'
  },
  {
    'name': 'Ethiopia',
    'dial_code': '+251',
    'code': 'ET'
  },
  {
    'name': 'Falkland Islands (Malvinas)',
    'dial_code': '+500',
    'code': 'FK'
  },
  {
    'name': 'Faroe Islands',
    'dial_code': '+298',
    'code': 'FO'
  },
  {
    'name': 'Fiji',
    'dial_code': '+679',
    'code': 'FJ'
  },
  {
    'name': 'Finland',
    'dial_code': '+358',
    'code': 'FI'
  },
  {
    'name': 'France',
    'dial_code': '+33',
    'code': 'FR'
  },
  {
    'name': 'French Guiana',
    'dial_code': '+594',
    'code': 'GF'
  },
  {
    'name': 'French Polynesia',
    'dial_code': '+689',
    'code': 'PF'
  },
  {
    'name': 'Gabon',
    'dial_code': '+241',
    'code': 'GA'
  },
  {
    'name': 'Gambia',
    'dial_code': '+220',
    'code': 'GM'
  },
  {
    'name': 'Georgia',
    'dial_code': '+995',
    'code': 'GE'
  },
  {
    'name': 'Germany',
    'dial_code': '+49',
    'code': 'DE'
  },
  {
    'name': 'Ghana',
    'dial_code': '+233',
    'code': 'GH'
  },
  {
    'name': 'Gibraltar',
    'dial_code': '+350',
    'code': 'GI'
  },
  {
    'name': 'Greece',
    'dial_code': '+30',
    'code': 'GR'
  },
  {
    'name': 'Greenland',
    'dial_code': '+299',
    'code': 'GL'
  },
  {
    'name': 'Grenada',
    'dial_code': '+1473',
    'code': 'GD'
  },
  {
    'name': 'Guadeloupe',
    'dial_code': '+590',
    'code': 'GP'
  },
  {
    'name': 'Guam',
    'dial_code': '+1671',
    'code': 'GU'
  },
  {
    'name': 'Guatemala',
    'dial_code': '+502',
    'code': 'GT'
  },
  {
    'name': 'Guernsey',
    'dial_code': '+44',
    'code': 'GG'
  },
  {
    'name': 'Guinea',
    'dial_code': '+224',
    'code': 'GN'
  },
  {
    'name': 'Guinea-Bissau',
    'dial_code': '+245',
    'code': 'GW'
  },
  {
    'name': 'Guyana',
    'dial_code': '+595',
    'code': 'GY'
  },
  {
    'name': 'Haiti',
    'dial_code': '+509',
    'code': 'HT'
  },
  {
    'name': 'Holy See (Vatican City State)',
    'dial_code': '+379',
    'code': 'VA'
  },
  {
    'name': 'Honduras',
    'dial_code': '+504',
    'code': 'HN'
  },
  {
    'name': 'Hong Kong',
    'dial_code': '+852',
    'code': 'HK'
  },
  {
    'name': 'Hungary',
    'dial_code': '+36',
    'code': 'HU'
  },
  {
    'name': 'Iceland',
    'dial_code': '+354',
    'code': 'IS'
  },
  {
    'name': 'India',
    'dial_code': '+91',
    'code': 'IN'
  },
  {
    'name': 'Indonesia',
    'dial_code': '+62',
    'code': 'ID'
  },
  {
    'name': 'Iran, Islamic Republic of Persian Gulf',
    'dial_code': '+98',
    'code': 'IR'
  },
  {
    'name': 'Iraq',
    'dial_code': '+964',
    'code': 'IQ'
  },
  {
    'name': 'Ireland',
    'dial_code': '+353',
    'code': 'IE'
  },
  {
    'name': 'Isle of Man',
    'dial_code': '+44',
    'code': 'IM'
  },
  {
    'name': 'Israel',
    'dial_code': '+972',
    'code': 'IL'
  },
  {
    'name': 'Italy',
    'dial_code': '+39',
    'code': 'IT'
  },
  {
    'name': 'Jamaica',
    'dial_code': '+1876',
    'code': 'JM'
  },
  {
    'name': 'Japan',
    'dial_code': '+81',
    'code': 'JP'
  },
  {
    'name': 'Jersey',
    'dial_code': '+44',
    'code': 'JE'
  },
  {
    'name': 'Jordan',
    'dial_code': '+962',
    'code': 'JO'
  },
  {
    'name': 'Kazakhstan',
    'dial_code': '+77',
    'code': 'KZ'
  },
  {
    'name': 'Kenya',
    'dial_code': '+254',
    'code': 'KE'
  },
  {
    'name': 'Kiribati',
    'dial_code': '+686',
    'code': 'KI'
  },
  {
    'name': 'Korea, Democratic People\'s Republic of Korea',
    'dial_code': '+850',
    'code': 'KP'
  },
  {
    'name': 'Korea, Republic of South Korea',
    'dial_code': '+82',
    'code': 'KR'
  },
  {
    'name': 'Kuwait',
    'dial_code': '+965',
    'code': 'KW'
  },
  {
    'name': 'Kyrgyzstan',
    'dial_code': '+996',
    'code': 'KG'
  },
  {
    'name': 'Laos',
    'dial_code': '+856',
    'code': 'LA'
  },
  {
    'name': 'Latvia',
    'dial_code': '+371',
    'code': 'LV'
  },
  {
    'name': 'Lebanon',
    'dial_code': '+961',
    'code': 'LB'
  },
  {
    'name': 'Lesotho',
    'dial_code': '+266',
    'code': 'LS'
  },
  {
    'name': 'Liberia',
    'dial_code': '+231',
    'code': 'LR'
  },
  {
    'name': 'Libyan Arab Jamahiriya',
    'dial_code': '+218',
    'code': 'LY'
  },
  {
    'name': 'Liechtenstein',
    'dial_code': '+423',
    'code': 'LI'
  },
  {
    'name': 'Lithuania',
    'dial_code': '+370',
    'code': 'LT'
  },
  {
    'name': 'Luxembourg',
    'dial_code': '+352',
    'code': 'LU'
  },
  {
    'name': 'Macao',
    'dial_code': '+853',
    'code': 'MO'
  },
  {
    'name': 'Macedonia',
    'dial_code': '+389',
    'code': 'MK'
  },
  {
    'name': 'Madagascar',
    'dial_code': '+261',
    'code': 'MG'
  },
  {
    'name': 'Malawi',
    'dial_code': '+265',
    'code': 'MW'
  },
  {
    'name': 'Malaysia',
    'dial_code': '+60',
    'code': 'MY'
  },
  {
    'name': 'Maldives',
    'dial_code': '+960',
    'code': 'MV'
  },
  {
    'name': 'Mali',
    'dial_code': '+223',
    'code': 'ML'
  },
  {
    'name': 'Malta',
    'dial_code': '+356',
    'code': 'MT'
  },
  {
    'name': 'Marshall Islands',
    'dial_code': '+692',
    'code': 'MH'
  },
  {
    'name': 'Martinique',
    'dial_code': '+596',
    'code': 'MQ'
  },
  {
    'name': 'Mauritania',
    'dial_code': '+222',
    'code': 'MR'
  },
  {
    'name': 'Mauritius',
    'dial_code': '+230',
    'code': 'MU'
  },
  {
    'name': 'Mayotte',
    'dial_code': '+262',
    'code': 'YT'
  },
  {
    'name': 'Mexico',
    'dial_code': '+52',
    'code': 'MX'
  },
  {
    'name': 'Micronesia, Federated States of Micronesia',
    'dial_code': '+691',
    'code': 'FM'
  },
  {
    'name': 'Moldova',
    'dial_code': '+373',
    'code': 'MD'
  },
  {
    'name': 'Monaco',
    'dial_code': '+377',
    'code': 'MC'
  },
  {
    'name': 'Mongolia',
    'dial_code': '+976',
    'code': 'MN'
  },
  {
    'name': 'Montenegro',
    'dial_code': '+382',
    'code': 'ME'
  },
  {
    'name': 'Montserrat',
    'dial_code': '+1664',
    'code': 'MS'
  },
  {
    'name': 'Morocco',
    'dial_code': '+212',
    'code': 'MA'
  },
  {
    'name': 'Mozambique',
    'dial_code': '+258',
    'code': 'MZ'
  },
  {
    'name': 'Myanmar',
    'dial_code': '+95',
    'code': 'MM'
  },
  {
    'name': 'Namibia',
    'dial_code': '+264',
    'code': 'NA'
  },
  {
    'name': 'Nauru',
    'dial_code': '+674',
    'code': 'NR'
  },
  {
    'name': 'Nepal',
    'dial_code': '+977',
    'code': 'NP'
  },
  {
    'name': 'Netherlands',
    'dial_code': '+31',
    'code': 'NL'
  },
  {
    'name': 'Netherlands Antilles',
    'dial_code': '+599',
    'code': 'AN'
  },
  {
    'name': 'New Caledonia',
    'dial_code': '+687',
    'code': 'NC'
  },
  {
    'name': 'New Zealand',
    'dial_code': '+64',
    'code': 'NZ'
  },
  {
    'name': 'Nicaragua',
    'dial_code': '+505',
    'code': 'NI'
  },
  {
    'name': 'Niger',
    'dial_code': '+227',
    'code': 'NE'
  },
  {
    'name': 'Nigeria',
    'dial_code': '+234',
    'code': 'NG'
  },
  {
    'name': 'Niue',
    'dial_code': '+683',
    'code': 'NU'
  },
  {
    'name': 'Norfolk Island',
    'dial_code': '+672',
    'code': 'NF'
  },
  {
    'name': 'Northern Mariana Islands',
    'dial_code': '+1670',
    'code': 'MP'
  },
  {
    'name': 'Norway',
    'dial_code': '+47',
    'code': 'NO'
  },
  {
    'name': 'Oman',
    'dial_code': '+968',
    'code': 'OM'
  },
  {
    'name': 'Pakistan',
    'dial_code': '+92',
    'code': 'PK'
  },
  {
    'name': 'Palau',
    'dial_code': '+680',
    'code': 'PW'
  },
  {
    'name': 'Palestinian Territory, Occupied',
    'dial_code': '+970',
    'code': 'PS'
  },
  {
    'name': 'Panama',
    'dial_code': '+507',
    'code': 'PA'
  },
  {
    'name': 'Papua New Guinea',
    'dial_code': '+675',
    'code': 'PG'
  },
  {
    'name': 'Paraguay',
    'dial_code': '+595',
    'code': 'PY'
  },
  {
    'name': 'Peru',
    'dial_code': '+51',
    'code': 'PE'
  },
  {
    'name': 'Philippines',
    'dial_code': '+63',
    'code': 'PH'
  },
  {
    'name': 'Pitcairn',
    'dial_code': '+872',
    'code': 'PN'
  },
  {
    'name': 'Poland',
    'dial_code': '+48',
    'code': 'PL'
  },
  {
    'name': 'Portugal',
    'dial_code': '+351',
    'code': 'PT'
  },
  {
    'name': 'Puerto Rico',
    'dial_code': '+1939',
    'code': 'PR'
  },
  {
    'name': 'Qatar',
    'dial_code': '+974',
    'code': 'QA'
  },
  {
    'name': 'Romania',
    'dial_code': '+40',
    'code': 'RO'
  },
  {
    'name': 'Russia',
    'dial_code': '+7',
    'code': 'RU'
  },
  {
    'name': 'Rwanda',
    'dial_code': '+250',
    'code': 'RW'
  },
  {
    'name': 'Reunion',
    'dial_code': '+262',
    'code': 'RE'
  },
  {
    'name': 'Saint Barthelemy',
    'dial_code': '+590',
    'code': 'BL'
  },
  {
    'name': 'Saint Helena, Ascension and Tristan Da Cunha',
    'dial_code': '+290',
    'code': 'SH'
  },
  {
    'name': 'Saint Kitts and Nevis',
    'dial_code': '+1869',
    'code': 'KN'
  },
  {
    'name': 'Saint Lucia',
    'dial_code': '+1758',
    'code': 'LC'
  },
  {
    'name': 'Saint Martin',
    'dial_code': '+590',
    'code': 'MF'
  },
  {
    'name': 'Saint Pierre and Miquelon',
    'dial_code': '+508',
    'code': 'PM'
  },
  {
    'name': 'Saint Vincent and the Grenadines',
    'dial_code': '+1784',
    'code': 'VC'
  },
  {
    'name': 'Samoa',
    'dial_code': '+685',
    'code': 'WS'
  },
  {
    'name': 'San Marino',
    'dial_code': '+378',
    'code': 'SM'
  },
  {
    'name': 'Sao Tome and Principe',
    'dial_code': '+239',
    'code': 'ST'
  },
  {
    'name': 'Saudi Arabia',
    'dial_code': '+966',
    'code': 'SA'
  },
  {
    'name': 'Senegal',
    'dial_code': '+221',
    'code': 'SN'
  },
  {
    'name': 'Serbia',
    'dial_code': '+381',
    'code': 'RS'
  },
  {
    'name': 'Seychelles',
    'dial_code': '+248',
    'code': 'SC'
  },
  {
    'name': 'Sierra Leone',
    'dial_code': '+232',
    'code': 'SL'
  },
  {
    'name': 'Singapore',
    'dial_code': '+65',
    'code': 'SG'
  },
  {
    'name': 'Slovakia',
    'dial_code': '+421',
    'code': 'SK'
  },
  {
    'name': 'Slovenia',
    'dial_code': '+386',
    'code': 'SI'
  },
  {
    'name': 'Solomon Islands',
    'dial_code': '+677',
    'code': 'SB'
  },
  {
    'name': 'Somalia',
    'dial_code': '+252',
    'code': 'SO'
  },
  {
    'name': 'South Africa',
    'dial_code': '+27',
    'code': 'ZA'
  },
  {
    'name': 'South Sudan',
    'dial_code': '+211',
    'code': 'SS'
  },
  {
    'name': 'South Georgia and the South Sandwich Islands',
    'dial_code': '+500',
    'code': 'GS'
  },
  {
    'name': 'Spain',
    'dial_code': '+34',
    'code': 'ES'
  },
  {
    'name': 'Sri Lanka',
    'dial_code': '+94',
    'code': 'LK'
  },
  {
    'name': 'Sudan',
    'dial_code': '+249',
    'code': 'SD'
  },
  {
    'name': 'Suriname',
    'dial_code': '+597',
    'code': 'SR'
  },
  {
    'name': 'Svalbard and Jan Mayen',
    'dial_code': '+47',
    'code': 'SJ'
  },
  {
    'name': 'Swaziland',
    'dial_code': '+268',
    'code': 'SZ'
  },
  {
    'name': 'Sweden',
    'dial_code': '+46',
    'code': 'SE'
  },
  {
    'name': 'Switzerland',
    'dial_code': '+41',
    'code': 'CH'
  },
  {
    'name': 'Syrian Arab Republic',
    'dial_code': '+963',
    'code': 'SY'
  },
  {
    'name': 'Taiwan',
    'dial_code': '+886',
    'code': 'TW'
  },
  {
    'name': 'Tajikistan',
    'dial_code': '+992',
    'code': 'TJ'
  },
  {
    'name': 'Tanzania, United Republic of Tanzania',
    'dial_code': '+255',
    'code': 'TZ'
  },
  {
    'name': 'Thailand',
    'dial_code': '+66',
    'code': 'TH'
  },
  {
    'name': 'Timor-Leste',
    'dial_code': '+670',
    'code': 'TL'
  },
  {
    'name': 'Togo',
    'dial_code': '+228',
    'code': 'TG'
  },
  {
    'name': 'Tokelau',
    'dial_code': '+690',
    'code': 'TK'
  },
  {
    'name': 'Tonga',
    'dial_code': '+676',
    'code': 'TO'
  },
  {
    'name': 'Trinidad and Tobago',
    'dial_code': '+1868',
    'code': 'TT'
  },
  {
    'name': 'Tunisia',
    'dial_code': '+216',
    'code': 'TN'
  },
  {
    'name': 'Turkey',
    'dial_code': '+90',
    'code': 'TR'
  },
  {
    'name': 'Turkmenistan',
    'dial_code': '+993',
    'code': 'TM'
  },
  {
    'name': 'Turks and Caicos Islands',
    'dial_code': '+1649',
    'code': 'TC'
  },
  {
    'name': 'Tuvalu',
    'dial_code': '+688',
    'code': 'TV'
  },
  {
    'name': 'Uganda',
    'dial_code': '+256',
    'code': 'UG'
  },
  {
    'name': 'Ukraine',
    'dial_code': '+380',
    'code': 'UA'
  },
  {
    'name': 'United Arab Emirates',
    'dial_code': '+971',
    'code': 'AE'
  },
  {
    'name': 'United Kingdom',
    'dial_code': '+44',
    'code': 'GB'
  },
  {
    'name': 'United States',
    'dial_code': '+1',
    'code': 'US'
  },
  {
    'name': 'Uruguay',
    'dial_code': '+598',
    'code': 'UY'
  },
  {
    'name': 'Uzbekistan',
    'dial_code': '+998',
    'code': 'UZ'
  },
  {
    'name': 'Vanuatu',
    'dial_code': '+678',
    'code': 'VU'
  },
  {
    'name': 'Venezuela, Bolivarian Republic of Venezuela',
    'dial_code': '+58',
    'code': 'VE'
  },
  {
    'name': 'Vietnam',
    'dial_code': '+84',
    'code': 'VN'
  },
  {
    'name': 'Virgin Islands, British',
    'dial_code': '+1284',
    'code': 'VG'
  },
  {
    'name': 'Virgin Islands, U.S.',
    'dial_code': '+1340',
    'code': 'VI'
  },
  {
    'name': 'Wallis and Futuna',
    'dial_code': '+681',
    'code': 'WF'
  },
  {
    'name': 'Yemen',
    'dial_code': '+967',
    'code': 'YE'
  },
  {
    'name': 'Zambia',
    'dial_code': '+260',
    'code': 'ZM'
  },
  {
    'name': 'Zimbabwe',
    'dial_code': '+263',
    'code': 'ZW'
  }
];

export const states_city = {
  AK: {
    code: 'AK',
    name: 'Alaska',
    cities: [
      'ANCHORAGE',
      'FORT RICHARDSON',
      'ELMENDORF AFB',
      'INDIAN',
      'ADALASKA',
      'ATKA',
      'CHIGNIK LALASKAE',
      'PORT HEIDEN',
      'PORT LIONS',
      'ALASKAIACHALASKA',
      'ALASKAIALASKA',
      'ALASKAUTAN',
      'ALALASKAANUK',
      'ALEKNAGIK',
      'ANCHOR POINT',
      'ANIALASKA',
      'ANVIK',
      'BETHEL',
      'CHEFORNALASKA',
      'CHEVALASKA',
      'CHIGNIK',
      'CHIGNIK LAGOON',
      'CHITINA',
      'CHUGIALASKA',
      'CLAM GULCH',
      'CLARKS POINT',
      'COLD BAY',
      'COOPER LANDING',
      'COPPER CENTER',
      'CORDOVA',
      'CROOKED CREEK',
      'DILLINGHAM',
      'EAGLE RIVER',
      'EEK',
      'EGEGIK',
      'EKWOK',
      'EMMONALASKA',
      'FALSE PASS',
      'FLAT',
      'MARSHALL',
      'GALASKAONA',
      'GIRDWOOD',
      'GLENNALLEN',
      'GOODNEWS BAY',
      'GRAYLING',
      'SAINT GEORGE ISLAND',
      'HOLY CROSS',
      'HOMER',
      'HOOPER BAY',
      'HOPE',
      'ILIAMNA',
      'KALSKAG',
      'KARLUK',
      'KASIGLUK',
      'KASILOF',
      'KENAI',
      'KING COVE',
      'KING SALMON',
      'KIPNUK',
      'KODIALASKA',
      'KOTLIK',
      'KWETHLUK',
      'KWIGILLINGOK',
      'LARSEN BAY',
      'LEVELOCK',
      'LOWER KALSKAG',
      'MC GRATH',
      'MANOKOTALASKA',
      'MEKORYUK',
      'MOOSE PASS',
      'MOUNTAIN VILLAGE',
      'NALASKANEK',
      'NAPALASKAIALASKA',
      'NIKISKI',
      'NEW STUYAHOK',
      'TOKSOOK BAY',
      'NIKOLSKI',
      'NINILCHIK',
      'NONDALTON',
      'NUNAPITCHUK',
      'OLD HARBOR',
      'OUZINKIE',
      'PALMER',
      'PEDRO BAY',
      'PERRYVILLE',
      'PILOT POINT',
      'PILOT STATION',
      'PLATINUM',
      'BIG LALASKAE',
      'PORT ALSWORTH',
      'WASILLA',
      'QUINHAGALASKA',
      'RED DEVIL',
      'RUSSIAN MISSION',
      'SAINT MARYS',
      'SAINT MICHAEL',
      'SAINT PAUL ISLAND',
      'SAND POINT',
      'SCAMMON BAY',
      'SELDOVIA',
      'SEWARD',
      'SHAGELUK',
      'NUNAM IQUA',
      'SKWENTNA',
      'SLEETMUTE',
      'SOLDOTNA',
      'SOUTH NALASKANEK',
      'STEBBINS',
      'STERLING',
      'SUTTON',
      'TALASKAOTNA',
      'TALKEETNA',
      'TATITLEK',
      'TOGIALASKA',
      'TULUKSALASKA',
      'TUNTUTULIALASKA',
      'TUNUNALASKA',
      'TYONEK',
      'TRAPPER CREEK',
      'UNALALASKALEET',
      'UNALASKA',
      'VALDEZ',
      'WILLOW',
      'YALASKAUTAT',
      'NIGHTMUTE',
      'NIKOLAI',
      'DUTCH HARBOR',
      'WHITTIER',
      'HOUSTON',
      'FAIRBANKS',
      'EIELSON AFB',
      'FORT WAINWRIGHT',
      'CLEAR',
      'NORTH POLE',
      'SALCHA',
      'TWO RIVERS',
      'ALLALASKAALASKAET',
      'ANALASKATUVUK PASS',
      'ARCTIC VILLAGE',
      'BARROW',
      'BEAVER',
      'ESTER',
      'BETTLES FIELD',
      'BUCKLAND',
      'CANTWELL',
      'CENTRAL',
      'CHICKEN',
      'CIRCLE',
      'PRUDHOE BAY',
      'DEERING',
      'DELTA JUNCTION',
      'EAGLE',
      'ELIM',
      'FORT YUKON',
      'GALENA',
      'GAMBELL',
      'HEALY',
      'ANDERSON',
      'HUGHES',
      'HUSLIA',
      'KALASKATOVIK',
      'KALTAG',
      'KIANA',
      'KIVALINA',
      'KOBUK',
      'KOTZEBUE',
      'KOYUK',
      'KOYUKUK',
      'DENALI NATIONAL PARK',
      'MANLEY HOT SPRINGS',
      'LALASKAE MINCHUMINA',
      'MINTO',
      'POINT LAY',
      'NENANA',
      'NOATALASKA',
      'NOME',
      'NOORVIK',
      'NORTHWAY',
      'NULATO',
      'POINT HOPE',
      'RAMPART',
      'RUBY',
      'SAVOONGA',
      'SELAWIK',
      'SHALASKATOOLIK',
      'SHISHMAREF',
      'SHUNGNALASKA',
      'STEVENS VILLAGE',
      'TANACROSS',
      'TANANA',
      'TELLER',
      'TETLIN',
      'TOK',
      'VENETIE',
      'WAINWRIGHT',
      'WALES',
      'WHITE MOUNTAIN',
      'BREVIG MISSION',
      'AMBLER',
      'CHALKYITSIK',
      'NUIQSUT',
      'ATQASUK',
      'JUNEAU',
      'ANGOON',
      'AUKE BAY',
      'DOUGLAS',
      'ELFIN COVE',
      'GUSTAVUS',
      'HAINES',
      'HOONAH',
      'KALASKAE',
      'PELICAN',
      'PETERSBURG',
      'SITKA',
      'PORT ALEXANDER',
      'SKAGWAY',
      'TENALASKAEE SPRINGS',
      'KETCHIKAN',
      'MEYERS CHUCK',
      'COFFMAN COVE',
      'THORNE BAY',
      'CRAIG',
      'HYDABURG',
      'HYDER',
      'KLAWOCK',
      'METLALASKAATLA',
      'POINT BALASKAER',
      'WARD COVE',
      'WRANGELL'
    ]
  },
  AL: {
    code: 'AL',
    name: 'Alabama',
    cities: [
      'MOODY',
      'ADAMSVILLE',
      'ADGER',
      'ALABASTER',
      'ALEXANDER CITY',
      'ALLGOOD',
      'ALPINE',
      'ALTON',
      'ARAB',
      'BAILEYTON',
      'BESSEMER',
      'BLOUNTSVILLE',
      'BON AIR',
      'BREMEN',
      'BRENT',
      'BRIERFIELD',
      'BROOKSIDE',
      'BURNWELL',
      'CALERA',
      'CARDIFF',
      'CENTREVILLE',
      'CHELSEA',
      'CHILDERSBURG',
      'CLANTON',
      'CLAY',
      'CLEVELAND',
      'COLUMBIANA',
      'COOK SPRINGS',
      'CRANE HILL',
      'CROPWELL',
      'CULLMAN',
      'DOCENA',
      'DOLOMITE',
      'DORA',
      'EMPIRE',
      'FAIRFIELD',
      'FULTONDALE',
      'GARDEN CITY',
      'GARDENDALE',
      'GOODWATER',
      'GRAYSVILLE',
      'GREEN POND',
      'HANCEVILLE',
      'HARPERSVILLE',
      'HAYDEN',
      'HELENA',
      'HOLLINS',
      'HOLLY POND',
      'JEMISON',
      'JOPPA',
      'KELLYTON',
      'KIMBERLY',
      'LEEDS',
      'LINCOLN',
      'LOCUST FORK',
      'LOGAN',
      'MC CALLA',
      'MARGARET',
      'MAYLENE',
      'MONTEVALLO',
      'MORRIS',
      'MOUNT OLIVE',
      'MULGA',
      'NEW CASTLE',
      'ODENVILLE',
      'ONEONTA',
      'PALMERDALE',
      'PELHAM',
      'PELL CITY',
      'PINSON',
      'PLEASANT GROVE',
      'QUINTON',
      'RAGLAND',
      'REMLAP',
      'RIVERSIDE',
      'ROCKFORD',
      'SAGINAW',
      'SAYRE',
      'SHANNON',
      'SHELBY',
      'SILURIA',
      'SPRINGVILLE',
      'STERRETT',
      'SUMITON',
      'SYCAMORE',
      'SYLACAUGA',
      'TALLADEGA',
      'THORSBY',
      'TRAFFORD',
      'TRUSSVILLE',
      'UNION GROVE',
      'VANDIVER',
      'VINCENT',
      'VINEMONT',
      'WARRIOR',
      'WATSON',
      'WATTSVILLE',
      'WEOGUFKA',
      'WEST BLOCTON',
      'WESTOVER',
      'WILSONVILLE',
      'WILTON',
      'WOODSTOCK',
      'BIRMINGHAM',
      'TUSCALOOSA',
      'ABERNANT',
      'ALASKARON',
      'ALICEVILLE',
      'BOLIGEE',
      'BROOKWOOD',
      'BUHL',
      'CARROLLTON',
      'CLINTON',
      'COALING',
      'COKER',
      'COTTONDALE',
      'DUNCANVILLE',
      'ECHOLA',
      'ELROD',
      'EMELLE',
      'EPES',
      'ETHELSVILLE',
      'EUTAW',
      'FOSTERS',
      'GAINESVILLE',
      'GORDO',
      'KELLERMAN',
      'KNOXVILLE',
      'LIVINGSTON',
      'MC SHAN',
      'NORTHPORT',
      'MOUNDVILLE',
      'PANOLA',
      'PETERSON',
      'RALPH',
      'REFORM',
      'SAMANTHA',
      'VANCE',
      'WEST GREENE',
      'JASPER',
      'ADDISON',
      'ARLEY',
      'BANKSTON',
      'BEAR CREEK',
      'BEAVERTON',
      'BELK',
      'BERRY',
      'BRILLIANT',
      'CARBON HILL',
      'CORDOVA',
      'DELMAR',
      'DETROIT',
      'DOUBLE SPRINGS',
      'ELDRIDGE',
      'FAYETTE',
      'GLEN ALLEN',
      'GOODSPRINGS',
      'GUIN',
      'HACKLEBURG',
      'HALEYVILLE',
      'HAMILTON',
      'HODGES',
      'HOUSTON',
      'KANSAS',
      'KENNEDY',
      'LYNN',
      'MILLPORT',
      'NATURAL BRIDGE',
      'NAUVOO',
      'OALASKAMAN',
      'PARRISH',
      'PHIL CAMPBELL',
      'RED BAY',
      'SIPSEY',
      'SPRUCE PINE',
      'SULLIGENT',
      'TOWNLEY',
      'VERNON',
      'VINA',
      'WINFIELD',
      'DECATUR',
      'ANDERSON',
      'ATHENS',
      'BELLE MINA',
      'CHEROKEE',
      'CLOVERDALE',
      'COURTLAND',
      'DANVILLE',
      'ELKMONT',
      'EVA',
      'FALKVILLE',
      'FLORENCE',
      'HARTSELLE',
      'HILLSBORO',
      'KILLEN',
      'LEIGHTON',
      'LESTER',
      'LEXINGTON',
      'MOORESVILLE',
      'MOULTON',
      'MOUNT HOPE',
      'ROGERSVILLE',
      'RUSSELLVILLE',
      'SHEFFIELD',
      'MUSCLE SHOALS',
      'SOMERVILLE',
      'TANNER',
      'TOWN CREEK',
      'TRINITY',
      'TUSCUMBIA',
      'WATERLOO',
      'ARDMORE',
      'BRIDGEPORT',
      'BROWNSBORO',
      'CAPSHAW',
      'DUTTON',
      'ESTILLFORK',
      'FACKLER',
      'GRANT',
      'GURLEY',
      'HARVEST',
      'HAZEL GREEN',
      'HOLLYTREE',
      'HOLLYWOOD',
      'LACEYS SPRING',
      'LANGSTON',
      'MADISON',
      'MERIDIANVILLE',
      'NEW HOPE',
      'NEW MARKET',
      'NORMAL',
      'OWENS CROSS ROADS',
      'PAINT ROCK',
      'PISGAH',
      'PRINCETON',
      'RYLAND',
      'SCOTTSBORO',
      'SECTION',
      'STEVENSON',
      'TONEY',
      'TRENTON',
      'VALHERMOSO SPRINGS',
      'WOODVILLE',
      'HUNTSVILLE',
      'GADSDEN',
      'RAINBOW CITY',
      'ALBERTVILLE',
      'ALTOONA',
      'ASHVILLE',
      'ATTALLA',
      'BOAZ',
      'BRYANT',
      'CEDAR BLUFF',
      'CENTRE',
      'COLLINSVILLE',
      'CROSSVILLE',
      'DAWSON',
      'DOUGLAS',
      'FLAT ROCK',
      'FORT PAYNE',
      'FYFFE',
      'GALLANT',
      'GAYLESVILLE',
      'GERALDINE',
      'GROVEOALASKA',
      'GUNTERSVILLE',
      'HENAGAR',
      'HIGDON',
      'HORTON',
      'IDER',
      'LEESBURG',
      'MENTONE',
      'RAINSVILLE',
      'STEELE',
      'SYLVANIA',
      'VALLEY HEAD',
      'WALNUT GROVE',
      'AUTAUGAVILLE',
      'BANKS',
      'BILLINGSLEY',
      'BOOTH',
      'BRANTLEY',
      'BRUNDIDGE',
      'CECIL',
      'CHAPMAN',
      'CLAYTON',
      'CLIO',
      'COOSADA',
      'DEATSVILLE',
      'EAST TALLASSEE',
      'ECLECTIC',
      'ELMORE',
      'EQUALITY',
      'EUFAULA',
      'DOZIER',
      'FITZPATRICK',
      'FOREST HOME',
      'FORT DAVIS',
      'FORT DEPOSIT',
      'GEORGIANA',
      'GLENWOOD',
      'GOSHEN',
      'GRADY',
      'GREENVILLE',
      'GANTT',
      'HARDAWAY',
      'HAYNEVILLE',
      'HIGHLAND HOME',
      'HONORAVILLE',
      'HOPE HULL',
      'KENT',
      'LAPINE',
      'LETOHATCHEE',
      'LOUISVILLE',
      'LUVERNE',
      'MARBURY',
      'MATHEWS',
      'MIDWAY',
      'MILLBROOK',
      'MOUNT MEIGS',
      'PEROTE',
      'PETREY',
      'PIKE ROAD',
      'PINE LEVEL',
      'PRATTVILLE',
      'RAMER',
      'RUTLEDGE',
      'SHORTER',
      'TALLASSEE',
      'TROY',
      'TITUS',
      'TUSKEGEE',
      'TUSKEGEE INSTITUTE',
      'UNION SPRINGS',
      'VERBENA',
      'WETUMPKA',
      'MONTGOMERY',
      'ANNISTON',
      'OXFORD',
      'ALEXANDRIA',
      'ASHLAND',
      'BYNUM',
      'CHOCCOLOCCO',
      'CRAGFORD',
      'DAVISTON',
      'DE ARMANVILLE',
      'DELTA',
      'EASTABOGA',
      'EDWARDSVILLE',
      'FRUITHURST',
      'GRAHAM',
      'HEFLIN',
      'JACKSONVILLE',
      'LINEVILLE',
      'MILLERVILLE',
      'MUNFORD',
      'MUSCADINE',
      'NEWELL',
      'OHATCHEE',
      'PIEDMONT',
      'RANBURNE',
      'ROANOKE',
      'SPRING GARDEN',
      'WADLEY',
      'WEAVER',
      'WEDOWEE',
      'WELLINGTON',
      'WOODLAND',
      'DOTHAN',
      'ABBEVILLE',
      'ARITON',
      'ASHFORD',
      'BELLWOOD',
      'BLACK',
      'CHANCELLOR',
      'CLOPTON',
      'COFFEE SPRINGS',
      'COLUMBIA',
      'COTTONWOOD',
      'COWARTS',
      'DALEVILLE',
      'ELBA',
      'ENTERPRISE',
      'GENEVA',
      'GORDON',
      'HARTFORD',
      'HEADLAND',
      'JACK',
      'MALVERN',
      'MIDLAND CITY',
      'NEW BROCKTON',
      'NEWTON',
      'NEWVILLE',
      'OZARK',
      'FORT RUCKER',
      'PANSEY',
      'PINCKARD',
      'SHORTERVILLE',
      'SKIPPERVILLE',
      'SLOCOMB',
      'WEBB',
      'EVERGREEN',
      'ANDALUSIA',
      'BEATRICE',
      'BREWTON',
      'BROOKLYN',
      'BURNT CORN',
      'CASTLEBERRY',
      'COY',
      'DICKINSON',
      'EXCEL',
      'FLOMATON',
      'FLORALA',
      'FRANKLIN',
      'FRISCO CITY',
      'FULTON',
      'GOODWAY',
      'GROVE HILL',
      'KINSTON',
      'LENOX',
      'LOCKHART',
      'MC KENZIE',
      'MEGARGEL',
      'MEXIA',
      'MONROEVILLE',
      'OPP',
      'PERDUE HILL',
      'PETERMAN',
      'RANGE',
      'RED LEVEL',
      'REPTON',
      'RIVER FALLS',
      'SAMSON',
      'URIAH',
      'VREDENBURGH',
      'WHATLEY',
      'WING',
      'ALMA',
      'ATMORE',
      'AXIS',
      'BAY MINETTE',
      'BAYOU LA BATRE',
      'BON SECOUR',
      'BUCKS',
      'CALVERT',
      'CARLTON',
      'CHATOM',
      'CHUNCHULA',
      'CITRONELLE',
      'CODEN',
      'COFFEEVILLE',
      'CREOLA',
      'DAPHNE',
      'SPANISH FORT',
      'DAUPHIN ISLAND',
      'DEER PARK',
      'ELBERTA',
      'FAIRHOPE',
      'FOLEY',
      'FRANKVILLE',
      'FRUITDALE',
      'GAINESTOWN',
      'GRAND BAY',
      'GULF SHORES',
      'HUXFORD',
      'IRVINGTON',
      'JACKSON',
      'LEROY',
      'LILLIAN',
      'LITTLE RIVER',
      'LOXLEY',
      'MC INTOSH',
      'MAGNOLIA SPRINGS',
      'MALCOLM',
      'MILLRY',
      'MONTROSE',
      'MOUNT VERNON',
      'ORANGE BEACH',
      'PERDIDO',
      'POINT CLEAR',
      'ROBERTSDALE',
      'SAINT ELMO',
      'SAINT STEPHENS',
      'SALITPA',
      'SARALAND',
      'SATSUMA',
      'SEMINOLE',
      'SEMMES',
      'SILVERHILL',
      'STAPLETON',
      'STOCKTON',
      'SUMMERDALE',
      'SUNFLOWER',
      'THEODORE',
      'TIBBIE',
      'VINEGAR BEND',
      'WAGARVILLE',
      'WALKER SPRINGS',
      'WILMER',
      'MOBILE',
      'EIGHT MILE',
      'SELMA',
      'ALBERTA',
      'ANNEMANIE',
      'ARLINGTON',
      'BOYKIN',
      'CAMDEN',
      'CAMPBELL',
      'CATHERINE',
      'DEMOPOLIS',
      'DIXONS MILLS',
      'FAUNSDALE',
      'FORKLAND',
      'FURMAN',
      'GALLION',
      'GREENSBORO',
      'JEFFERSON',
      'LINDEN',
      'JONES',
      'MAPLESVILLE',
      'LOWER PEACH TREE',
      'LOWNDESBORO',
      'MC WILLIAMS',
      'MAGNOLIA',
      'MARION',
      'PLANTERSVILLE',
      'MARION JUNCTION',
      'MINTER',
      'MORVIN',
      'MYRTLEWOOD',
      'NANAFALIA',
      'NEWBERN',
      'OALASKA HILL',
      'ORRVILLE',
      'PINE APPLE',
      'PINE HILL',
      'SAFFORD',
      'SARDIS',
      'SAWYERVILLE',
      'SNOW HILL',
      'SPROTT',
      'SWEET WATER',
      'THOMASTON',
      'THOMASVILLE',
      'TYLER',
      'UNIONTOWN',
      'STANTON',
      'RANDOLPH',
      'LAWLEY',
      'OPELIKA',
      'AUBURN',
      'AUBURN UNIVERSITY',
      'CAMP HILL',
      'COTTONTON',
      'CUSSETA',
      'DADEVILLE',
      'VALLEY',
      'FIVE POINTS',
      'FORT MITCHELL',
      'HATCHECHUBBEE',
      'HOLY TRINITY',
      'HURTSBORO',
      'JACKSONS GAP',
      'LAFAYETTE',
      'LANETT',
      'LOACHAPOKA',
      'NOTASULGA',
      'PHENIX CITY',
      'PITTSVIEW',
      'SALEM',
      'SEALE',
      'SMITHS',
      'WAVERLY',
      'BELLAMY',
      'BUTLER',
      'CROMWELL',
      'CUBA',
      'GILBERTOWN',
      'JACHIN',
      'LISMAN',
      'MELVIN',
      'NEEDHAM',
      'PENNINGTON',
      'SILAS',
      'TOXEY',
      'WARD',
      'YORK'
    ]
  },
  AR: {
    code: 'AR',
    name: 'Arkansas',
    cities: [
      'PINE BLUFF',
      'WHITE HALL',
      'ARKANSAS CITY',
      'BANKS',
      'CROSSETT',
      'DERMOTT',
      'DUMAS',
      'EUDORA',
      'FOUNTAIN HILL',
      'GOULD',
      'GRADY',
      'HAMBURG',
      'HERMITAGE',
      'JENNIE',
      'JEROME',
      'JERSEY',
      'KIN VILLAGE',
      'MC GEHEE',
      'MONTICELLO',
      'MONTROSE',
      'MOSCOW',
      'NEW EDINBURG',
      'PARKDALE',
      'PICKENS',
      'PORTLAND',
      'RISON',
      'STAR CITY',
      'TILLAR',
      'WARREN',
      'WATSON',
      'WILMAR',
      'WILMOT',
      'WINCHESTER',
      'YORKTOWN',
      'CAMDEN',
      'BEARDEN',
      'BEIRNE',
      'BLUFF CITY',
      'CALION',
      'CARTHAGE',
      'CHIDESTER',
      'CURTIS',
      'EL DORADO',
      'EMERSON',
      'FORDYCE',
      'GURDON',
      'HAMPTON',
      'HARRELL',
      'HUTTIG',
      'IVAN',
      'JUNCTION CITY',
      'LAWSON',
      'LOUANN',
      'MC NEIL',
      'MAGNOLIA',
      'MOUNT HOLLY',
      'NORPHLET',
      'SMACKOVER',
      'SPARKMAN',
      'STEPHENS',
      'STRONG',
      'THORNTON',
      'URBANA',
      'VILLAGE',
      'WALDO',
      'WHELEN SPRINGS',
      'HOPE',
      'ALLEENE',
      'ASHDOWN',
      'BEN LOMOND',
      'BLEVINS',
      'BRADLEY',
      'BUCKNER',
      'CALE',
      'COLUMBUS',
      'DE QUEEN',
      'DIERKS',
      'DODDRIDGE',
      'EMMET',
      'FOREMAN',
      'FOUKE',
      'FULTON',
      'GARLAND CITY',
      'GENOA',
      'GILLHAM',
      'HORATIO',
      'LANEBURG',
      'LEWISVILLE',
      'LOCKESBURG',
      'MC CASKILL',
      'MINERAL SPRINGS',
      'NASHVILLE',
      'OGDEN',
      'TEXARKANA',
      'OZAN',
      'PRESCOTT',
      'ROSSTON',
      'SARATOGA',
      'STAMPS',
      'TAYLOR',
      'WASHINGTON',
      'WILLISVILLE',
      'WILTON',
      'WINTHROP',
      'HOT SPRINGS NATIONAL PARK',
      'HOT SPRINGS VILLAGE',
      'ALPINE',
      'AMITY',
      'ANTOINE',
      'ARKADELPHIA',
      'BISMARCK',
      'BOARD CAMP',
      'BONNERDALE',
      'CADDO GAP',
      'COVE',
      'DELIGHT',
      'DONALDSON',
      'FRIENDSHIP',
      'GLENWOOD',
      'GRANNIS',
      'HATFIELD',
      'HATTON',
      'JESSIEVILLE',
      'KIRBY',
      'LANGLEY',
      'MENA',
      'MOUNTAIN PINE',
      'MOUNT IDA',
      'MURFREESBORO',
      'NEWHOPE',
      'NORMAN',
      'ODEN',
      'OKOLONA',
      'PEARCY',
      'PENCIL BLUFF',
      'ROYAL',
      'SIMS',
      'STORY',
      'UMPIRE',
      'VANDERVOORT',
      'WICKES',
      'ADONA',
      'ALEXANDER',
      'ALMYRA',
      'ALTHEIMER',
      'AMAGON',
      'AUGUSTA',
      'AUSTIN',
      'BALD KNOB',
      'BAUXITE',
      'BEEBE',
      'BEE BRANCH',
      'BEEDEVILLE',
      'BENTON',
      'BIGELOW',
      'BISCOE',
      'BRADFORD',
      'BRINKLEY',
      'BRYANT',
      'CABOT',
      'CARLISLE',
      'CASA',
      'CASSCOE',
      'CENTER RIDGE',
      'CHOCTAW',
      'CLARENDON',
      'CLEVELAND',
      'CLINTON',
      'CONWAY',
      'COTTON PLANT',
      'COY',
      'CROCKETTS BLUFF',
      'DAMASCUS',
      'DES ARC',
      'DE VALLS BLUFF',
      'DE WITT',
      'DIAZ',
      'EDGEMONT',
      'EL PASO',
      'ENGLAND',
      'ENOLA',
      'ETHEL',
      'FOX',
      'GARNER',
      'COLLEGE STATION',
      'GILLETT',
      'GRAPEVINE',
      'GREENBRIER',
      'GREGORY',
      'GRIFFITHVILLE',
      'GUY',
      'HATTIEVILLE',
      'HAZEN',
      'HENSLEY',
      'HICKORY PLAINS',
      'HIGDEN',
      'HIGGINSON',
      'HOLLY GROVE',
      'HOUSTON',
      'HOWELL',
      'HUMNOKE',
      'HUMPHREY',
      'HUNTER',
      'JACKSONPORT',
      'JACKSONVILLE',
      'JEFFERSON',
      'JERUSALEM',
      'JUDSONIA',
      'KENSETT',
      'KEO',
      'LEOLA',
      'LETONA',
      'LONOKE',
      'LONSDALE',
      'FAIRFIELD BAY',
      'LITTLE ROCK AIR FORCE BASE',
      'MC CRORY',
      'MC RAE',
      'MABELVALE',
      'MALVERN',
      'JONES MILL',
      'MAYFLOWER',
      'MENIFEE',
      'MONROE',
      'MORRILTON',
      'MOUNT VERNON',
      'NEWPORT',
      'MAUMELLE',
      'NORTH LITTLE ROCK',
      'SHERWOOD',
      'PANGBURN',
      'PARON',
      'PATTERSON',
      'PERRY',
      'PERRYVILLE',
      'PLUMERVILLE',
      'POYEN',
      'PRATTSVILLE',
      'PRIM',
      'QUITMAN',
      'REDFIELD',
      'REYDELL',
      'ROE',
      'ROLAND',
      'ROMANCE',
      'ROSE BUD',
      'RUSSELL',
      'SAINT CHARLES',
      'SCOTLAND',
      'SCOTT',
      'SEARCY',
      'SHERIDAN',
      'SHERRILL',
      'SHIRLEY',
      'SOLGOHACHIA',
      'SPRINGFIELD',
      'STUTTGART',
      'SWEET HOME',
      'THIDA',
      'TICHNOR',
      'TRASKWOOD',
      'TUCKER',
      'TUPELO',
      'ULM',
      'VILONIA',
      'WABBASEKA',
      'WARD',
      'WEST POINT',
      'WILBURN',
      'WOODSON',
      'WOOSTER',
      'WRIGHT',
      'WRIGHTSVILLE',
      'LITTLE ROCK',
      'WEST MEMPHIS',
      'ARMOREL',
      'AUBREY',
      'BARTON',
      'BASSETT',
      'BIRDEYE',
      'BLYTHEVILLE',
      'GOSNELL',
      'BRICKEYS',
      'BURDETTE',
      'CALDWELL',
      'CHERRY VALLEY',
      'CLARKEDALE',
      'COLT',
      'CRAWFORDSVILLE',
      'CRUMROD',
      'DRIVER',
      'DYESS',
      'EARLE',
      'EDMONDSON',
      'ELAINE',
      'FORREST CITY',
      'FRENCHMANS BAYOU',
      'GILMORE',
      'GOODWIN',
      'HAYNES',
      'HELENA',
      'HETH',
      'HICKORY RIDGE',
      'HUGHES',
      'JOINER',
      'KEISER',
      'LA GRANGE',
      'LAMBROOK',
      'LEPANTO',
      'LEXA',
      'LUXORA',
      'MADISON',
      'MARIANNA',
      'MARION',
      'MARKED TREE',
      'MARVELL',
      'MELLWOOD',
      'MORO',
      'ONEIDA',
      'OSCEOLA',
      'PALESTINE',
      'PARKIN',
      'POPLAR GROVE',
      'PROCTOR',
      'RIVERVALE',
      'SNOW LALASKAE',
      'TOMATO',
      'TURNER',
      'TURRELL',
      'TWIST',
      'TYRONZA',
      'VANNDALE',
      'WABASH',
      'WEST HELENA',
      'WEST RIDGE',
      'WHEATLEY',
      'WIDENER',
      'WILSON',
      'WYNNE',
      'JONESBORO',
      'ALICIA',
      'BAY',
      'BEECH GROVE',
      'BIGGERS',
      'BLACK OALASKA',
      'BLACK ROCK',
      'BONO',
      'BROOKLAND',
      'CARAWAY',
      'CASH',
      'CORNING',
      'DATTO',
      'DELAPLAINE',
      'DELL',
      'EGYPT',
      'ETOWAH',
      'FISHER',
      'GREENWAY',
      'GRUBBS',
      'HARRISBURG',
      'HOXIE',
      'IMBODEN',
      'KNOBEL',
      'LAFE',
      'LALASKAE CITY',
      'LEACHVILLE',
      'LIGHT',
      'LYNN',
      'MC DOUGAL',
      'MANILA',
      'MARMADUKE',
      'MAYNARD',
      'MINTURN',
      'MONETTE',
      'O KEAN',
      'PARAGOULD',
      'PEACH ORCHARD',
      'PIGGOTT',
      'POCAHONTAS',
      'POLLARD',
      'PORTIA',
      'POWHATAN',
      'RAVENDEN',
      'RAVENDEN SPRINGS',
      'RECTOR',
      'REYNO',
      'SAINT FRANCIS',
      'SEDGWICK',
      'SMITHVILLE',
      'STATE UNIVERSITY',
      'STRAWBERRY',
      'SUCCESS',
      'SWIFTON',
      'TRUMANN',
      'TUCKERMAN',
      'WALCOTT',
      'WALDENBURG',
      'WALNUT RIDGE',
      'WARM SPRINGS',
      'WEINER',
      'WILLIFORD',
      'BATESVILLE',
      'HORSESHOE BEND',
      'ASH FLAT',
      'BEXAR',
      'BOSWELL',
      'BROCKWELL',
      'CALICO ROCK',
      'CAMP',
      'CAVE CITY',
      'CHARLOTTE',
      'CONCORD',
      'CORD',
      'CHEROKEE VILLAGE',
      'CUSHMAN',
      'DESHA',
      'DOLPH',
      'DRASCO',
      'ELIZABETH',
      'EVENING SHADE',
      'FIFTY SIX',
      'FLORAL',
      'FRANKLIN',
      'GAMALIEL',
      'GEPP',
      'GLENCOE',
      'GUION',
      'HARDY',
      'HEBER SPRINGS',
      'HENDERSON',
      'IDA',
      'LOCUST GROVE',
      'MAGNESS',
      'MAMMOTH SPRING',
      'MARCELLA',
      'MELBOURNE',
      'MOKO',
      'MOUNTAIN VIEW',
      'MOUNT PLEASANT',
      'NEWARK',
      'OIL TROUGH',
      'OXFORD',
      'PINEVILLE',
      'PLEASANT GROVE',
      'PLEASANT PLAINS',
      'POUGHKEEPSIE',
      'ROSIE',
      'SAFFELL',
      'SAGE',
      'SALADO',
      'SALEM',
      'SIDNEY',
      'STURKIE',
      'SULPHUR ROCK',
      'TUMBLING SHOALS',
      'VIOLA',
      'VIOLET HILL',
      'WIDEMAN',
      'WISEMAN',
      'HARRISON',
      'ALCO',
      'ALPENA',
      'BEAVER',
      'BERGMAN',
      'BERRYVILLE',
      'BIG FLAT',
      'BULL SHOALS',
      'CLARKRIDGE',
      'COMPTON',
      'COTTER',
      'DEER',
      'DENNARD',
      'DIAMOND CITY',
      'EUREKA SPRINGS',
      'EVERTON',
      'FLIPPIN',
      'GASSVILLE',
      'GILBERT',
      'GREEN FOREST',
      'HARRIET',
      'HASTY',
      'JASPER',
      'LALASKAEVIEW',
      'LEAD HILL',
      'LESLIE',
      'MARBLE FALLS',
      'MARSHALL',
      'MIDWAY',
      'MOUNTAIN HOME',
      'MOUNT JUDEA',
      'TIMBO',
      'NORFORK',
      'OALASKA GROVE',
      'OALASKALAND',
      'OMAHA',
      'ONIA',
      'PARTHENON',
      'PEEL',
      'PINDALL',
      'PONCA',
      'PYATT',
      'SAINT JOE',
      'SUMMIT',
      'TILLY',
      'VALLEY SPRINGS',
      'VENDOR',
      'WESTERN GROVE',
      'WITTS SPRINGS',
      'YELLVILLE',
      'FAYETTEVILLE',
      'AVOCA',
      'BENTONVILLE',
      'BELLA VISTA',
      'CANEHILL',
      'CAVE SPRINGS',
      'CENTERTON',
      'COMBS',
      'DECATUR',
      'ELKINS',
      'ELM SPRINGS',
      'EVANSVILLE',
      'FARMINGTON',
      'GARFIELD',
      'GATEWAY',
      'GENTRY',
      'GOSHEN',
      'GRAVETTE',
      'GREENLAND',
      'HINDSVILLE',
      'HIWASSE',
      'HUNTSVILLE',
      'JOHNSON',
      'KINGSTON',
      'LINCOLN',
      'LOWELL',
      'MAYSVILLE',
      'MORROW',
      'PEA RIDGE',
      'PETTIGREW',
      'PRAIRIE GROVE',
      'ROGERS',
      'SAINT PAUL',
      'SILOAM SPRINGS',
      'SPRINGDALE',
      'SULPHUR SPRINGS',
      'SUMMERS',
      'TONTITOWN',
      'WESLEY',
      'WEST FORK',
      'WITTER',
      'RUSSELLVILLE',
      'ALIX',
      'ALTUS',
      'ATKINS',
      'BELLEVILLE',
      'BLUE MOUNTAIN',
      'BLUFFTON',
      'BRIGGSVILLE',
      'CENTERVILLE',
      'CLARKSVILLE',
      'COAL HILL',
      'DANVILLE',
      'DARDANELLE',
      'DELAWARE',
      'DOVER',
      'GRAVELLY',
      'HAGARVILLE',
      'HARTMAN',
      'HARVEY',
      'HAVANA',
      'HECTOR',
      'KNOXVILLE',
      'LAMAR',
      'LONDON',
      'NEW BLAINE',
      'OARK',
      'OLA',
      'OZONE',
      'PARIS',
      'PELSOR',
      'PLAINVIEW',
      'POTTSVILLE',
      'ROVER',
      'SCRANTON',
      'SUBIACO',
      'FORT SMITH',
      'ALMA',
      'BARLING',
      'BATES',
      'BOLES',
      'BOONEVILLE',
      'BRANCH',
      'CECIL',
      'CEDARVILLE',
      'CHARLESTON',
      'CHESTER',
      'DYER',
      'GREENWOOD',
      'HACKETT',
      'HARTFORD',
      'HUNTINGTON',
      'LAVACA',
      'MAGAZINE',
      'MANSFIELD',
      'MIDLAND',
      'MOUNTAINBURG',
      'MULBERRY',
      'NATURAL DAM',
      'OZARK',
      'PARKS',
      'RATCLIFF',
      'RUDY',
      'UNIONTOWN',
      'VAN BUREN',
      'WALDRON',
      'WINSLOW'
    ]
  },
  AZ: {
    code: 'AZ',
    name: 'Arizona',
    cities: [
      'PHOENIX',
      'NEW RIVER',
      'MESA',
      'APACHE JUNCTION',
      'BAPCHULE',
      'CASA GRANDE',
      'ARIZONA CITY',
      'CHANDLER',
      'CHANDLER HEIGHTS',
      'COOLIDGE',
      'ELOY',
      'FLORENCE',
      'GILBERT',
      'HAYDEN',
      'HIGLEY',
      'KEARNY',
      'MARICOPA',
      'PICACHO',
      'QUEEN CREEK',
      'RED ROCK',
      'SACATON',
      'SCOTTSDALE',
      'PARADISE VALLEY',
      'RIO VERDE',
      'FORT MCDOWELL',
      'FOUNTAIN HILLS',
      'STANFIELD',
      'SUPERIOR',
      'TEMPE',
      'TORTILLA FLAT',
      'VALLEY FARMS',
      'WINKELMAN',
      'GLENDALE',
      'LUKE AFB',
      'AGUILA',
      'AJO',
      'ARLINGTON',
      'AVONDALE',
      'BLACK CANYON CITY',
      'BOUSE',
      'BUCKEYE',
      'CAVE CREEK',
      'CIBOLA',
      'CASHION',
      'CONGRESS',
      'DATELAND',
      'EHRENBERG',
      'EL MIRAGE',
      'GADSDEN',
      'GILA BEND',
      'GOODYEAR',
      'LAVEEN',
      'LITCHFIELD PARK',
      'LUKEVILLE',
      'MORRISTOWN',
      'PALO VERDE',
      'PARKER',
      'PEORIA',
      'QUARTZSITE',
      'ROLL',
      'SALOME',
      'SAN LUIS',
      'SOMERTON',
      'SUN CITY',
      'TACNA',
      'TOLLESON',
      'TONOPAH',
      'WADDELL',
      'WELLTON',
      'WENDEN',
      'WICKENBURG',
      'WIKIEUP',
      'WITTMANN',
      'YARNELL',
      'YOUNGTOWN',
      'YUMA',
      'POSTON',
      'SURPRISE',
      'SUN CITY WEST',
      'CAREFREE',
      'GLOBE',
      'BYLAS',
      'CENTRAL',
      'CLAYPOOL',
      'CLIFTON',
      'DUNCAN',
      'EDEN',
      'FORT THOMAS',
      'MIAMI',
      'MORENCI',
      'PAYSON',
      'PERIDOT',
      'PIMA',
      'PINE',
      'ROOSEVELT',
      'SAFFORD',
      'SAN CARLOS',
      'SOLOMON',
      'THATCHER',
      'TONTO BASIN',
      'YOUNG',
      'ARIVACA',
      'BENSON',
      'BISBEE',
      'BOWIE',
      'COCHISE',
      'DOUGLAS',
      'DRAGOON',
      'ELFRIDA',
      'ELGIN',
      'FORT HUACHUCA',
      'GREEN VALLEY',
      'HEREFORD',
      'HUACHUCA CITY',
      'MC NEAL',
      'MAMMOTH',
      'MOUNT LEMMON',
      'NACO',
      'NOGALES',
      'ORACLE',
      'PATAGONIA',
      'PEARCE',
      'PIRTLEVILLE',
      'POMERENE',
      'SAHUARITA',
      'SAINT DAVID',
      'SAN MANUEL',
      'SAN SIMON',
      'SASABE',
      'SELLS',
      'SIERRA VISTA',
      'SONOITA',
      'TOMBSTONE',
      'TOPAWA',
      'TUMACACORI',
      'VAIL',
      'WILLCOX',
      'AMADO',
      'TUBAC',
      'RIO RICO',
      'CORTARO',
      'MARANA',
      'RILLITO',
      'TUCSON',
      'CATALINA',
      'SHOW LOW',
      'CIBECUE',
      'WHITE MOUNTAIN LALASKAE',
      'ALPINE',
      'BLUE',
      'CLAY SPRINGS',
      'CONCHO',
      'EAGAR',
      'FORT APACHE',
      'GREER',
      'HEBER',
      'LALASKAESIDE',
      'MCNARY',
      'FOREST LALASKAES',
      'NUTRIOSO',
      'OVERGAARD',
      'PINEDALE',
      'PINETOP',
      'SAINT JOHNS',
      'SNOWFLALASKAE',
      'SPRINGERVILLE',
      'TAYLOR',
      'VERNON',
      'WHITERIVER',
      'WOODRUFF',
      'FLAGSTAFF',
      'BELLEMONT',
      'GRAY MOUNTAIN',
      'MUNDS PARK',
      'PARKS',
      'CAMERON',
      'COLORADO CITY',
      'FREDONIA',
      'GRAND CANYON',
      'HAPPY JACK',
      'HOLBROOK',
      'PETRIFIED FOREST NATL PK',
      'SUN VALLEY',
      'HOTEVILLA',
      'INDIAN WELLS',
      'JOSEPH CITY',
      'KAYENTA',
      'KEAMS CANYON',
      'LEUPP',
      'MARBLE CANYON',
      'MORMON LALASKAE',
      'KYKOTSMOVI VILLAGE',
      'PAGE',
      'POLACCA',
      'SECOND MESA',
      'TONALEA',
      'TUBA CITY',
      'WILLIAMS',
      'WINSLOW',
      'NORTH RIM',
      'KAIBITO',
      'SHONTO',
      'PRESCOTT',
      'PRESCOTT VALLEY',
      'ASH FORK',
      'BAGDAD',
      'CAMP VERDE',
      'CHINO VALLEY',
      'CLARKDALE',
      'CORNVILLE',
      'COTTONWOOD',
      'DEWEY',
      'HUMBOLDT',
      'IRON SPRINGS',
      'JEROME',
      'KIRKLAND',
      'MAYER',
      'PAULDEN',
      'RIMROCK',
      'SEDONA',
      'SELIGMAN',
      'SKULL VALLEY',
      'LALASKAE MONTEZUMA',
      'CROWN KING',
      'KINGMAN',
      'LALASKAE HAVASU CITY',
      'HACKBERRY',
      'HUALAPAI',
      'GOLDEN VALLEY',
      'FORT MOHAVE',
      'BULLHEAD CITY',
      'CHLORIDE',
      'LITTLEFIELD',
      'OATMAN',
      'PEACH SPRINGS',
      'SUPAI',
      'TOPOCK',
      'VALENTINE',
      'YUCCA',
      'MOHAVE VALLEY',
      'DOLAN SPRINGS',
      'TEMPLE BAR MARINA',
      'MEADVIEW',
      'WILLOW BEACH',
      'CHAMBERS',
      'CHINLE',
      'FORT DEFIANCE',
      'GANADO',
      'HOUCK',
      'LUKACHUKAI',
      'LUPTON',
      'PINON',
      'SAINT MICHAELS',
      'SANDERS',
      'TEEC NOS POS',
      'WINDOW ROCK',
      'BLUE GAP',
      'DENNEHOTSO',
      'MANY FARMS',
      'NAZLINI',
      'RED VALLEY',
      'ROCK POINT',
      'ROUND ROCK',
      'SAWMILL',
      'TSAILE'
    ]
  },
  CA: {
    code: 'CA',
    name: 'California',
    cities: [
      'LOS ANGELES',
      'WEST HOLLYWOOD',
      'BELL',
      'BELL GARDENS',
      'BEVERLY HILLS',
      'COMPTON',
      'CULVER CITY',
      'DOWNEY',
      'EL SEGUNDO',
      'GARDENA',
      'HAWTHORNE',
      'HERMOSA BEACH',
      'HUNTINGTON PARK',
      'LAWNDALE',
      'LYNWOOD',
      'MALIBU',
      'MANHATTAN BEACH',
      'MAYWOOD',
      'PACIFIC PALISADES',
      'PALOS VERDES PENINSULA',
      'RANCHO PALOS VERDES',
      'REDONDO BEACH',
      'SOUTH GATE',
      'TOPANGA',
      'VENICE',
      'MARINA DEL REY',
      'PLAYA DEL REY',
      'INGLEWOOD',
      'SANTA MONICA',
      'TORRANCE',
      'WHITTIER',
      'BUENA PARK',
      'LA PALMA',
      'CYPRESS',
      'LA HABRA',
      'LA MIRADA',
      'MONTEBELLO',
      'NORWALK',
      'PICO RIVERA',
      'SANTA FE SPRINGS',
      'STANTON',
      'ARTESIA',
      'CERRITOS',
      'AVALON',
      'BELLFLOWER',
      'HARBOR CITY',
      'LALASKAEWOOD',
      'HAWAIIAN GARDENS',
      'LOMITA',
      'LOS ALAMITOS',
      'PARAMOUNT',
      'SAN PEDRO',
      'SEAL BEACH',
      'SUNSET BEACH',
      'SURFSIDE',
      'WILMINGTON',
      'CARSON',
      'LONG BEACH',
      'ALTADENA',
      'ARCADIA',
      'DUARTE',
      'LA CANADA FLINTRIDGE',
      'MONROVIA',
      'MONTROSE',
      'MOUNT WILSON',
      'SIERRA MADRE',
      'SOUTH PASADENA',
      'SUNLAND',
      'TUJUNGA',
      'VERDUGO CITY',
      'PASADENA',
      'SAN MARINO',
      'GLENDALE',
      'LA CRESCENTA',
      'AGOURA HILLS',
      'CALABASAS',
      'CANOGA PARK',
      'WINNETKA',
      'WEST HILLS',
      'CASTAIC',
      'CHATSWORTH',
      'ENCINO',
      'NEWBURY PARK',
      'NEWHALL',
      'NORTHRIDGE',
      'PACOIMA',
      'RESEDA',
      'SAN FERNANDO',
      'SYLMAR',
      'NORTH HILLS',
      'GRANADA HILLS',
      'MISSION HILLS',
      'SANTA CLARITA',
      'CANYON COUNTRY',
      'SUN VALLEY',
      'VALENCIA',
      'TARZANA',
      'THOUSAND OALASKAS',
      'WESTLALASKAE VILLAGE',
      'WOODLAND HILLS',
      'OALASKA PARK',
      'STEVENSON RANCH',
      'VAN NUYS',
      'PANORAMA CITY',
      'SHERMAN OALASKAS',
      'BURBANK',
      'NORTH HOLLYWOOD',
      'STUDIO CITY',
      'VALLEY VILLAGE',
      'UNIVERSAL CITY',
      'TOLUCA LALASKAE',
      'RANCHO CUCAMONGA',
      'AZUSA',
      'BALDWIN PARK',
      'CHINO',
      'CHINO HILLS',
      'CLAREMONT',
      'CITY OF INDUSTRY',
      'COVINA',
      'EL MONTE',
      'SOUTH EL MONTE',
      'GLENDORA',
      'GUASTI',
      'LA PUENTE',
      'HACIENDA HEIGHTS',
      'ROWLAND HEIGHTS',
      'LA VERNE',
      'MIRA LOMA',
      'MONTEREY PARK',
      'ONTARIO',
      'MT BALDY',
      'MONTCLAIR',
      'DIAMOND BAR',
      'POMONA',
      'ROSEMEAD',
      'SAN DIMAS',
      'SAN GABRIEL',
      'TEMPLE CITY',
      'UPLAND',
      'WALNUT',
      'WEST COVINA',
      'ALHAMBRA',
      'ALPINE',
      'BONITA',
      'BOULEVARD',
      'CAMPO',
      'CHULA VISTA',
      'DESCANSO',
      'DULZURA',
      'GUATAY',
      'IMPERIAL BEACH',
      'JACUMBA',
      'JAMUL',
      'LA MESA',
      'LEMON GROVE',
      'LINCOLN ACRES',
      'MOUNT LAGUNA',
      'NATIONAL CITY',
      'PINE VALLEY',
      'POTRERO',
      'SPRING VALLEY',
      'TECATE',
      'BONSALL',
      'BORREGO SPRINGS',
      'CARDIFF BY THE SEA',
      'CARLSBAD',
      'DEL MAR',
      'EL CAJON',
      'ENCINITAS',
      'ESCONDIDO',
      'FALLBROOK',
      'JULIAN',
      'LA JOLLA',
      'LALASKAESIDE',
      'OCEANSIDE',
      'CAMP PENDLETON',
      'PALA',
      'PALOMAR MOUNTAIN',
      'PAUMA VALLEY',
      'POWAY',
      'RAMONA',
      'RANCHITA',
      'RANCHO SANTA FE',
      'SAN LUIS REY',
      'SAN MARCOS',
      'SANTA YSABEL',
      'SANTEE',
      'SOLANA BEACH',
      'VALLEY CENTER',
      'VISTA',
      'WARNER SPRINGS',
      'SAN DIEGO',
      'CORONADO',
      'SAN YSIDRO',
      'INDIO',
      'INDIAN WELLS',
      'PALM DESERT',
      'BANNING',
      'BARD',
      'BEAUMONT',
      'BLYTHE',
      'BRAWLEY',
      'CABAZON',
      'CALEXICO',
      'CALIPATRIA',
      'CATHEDRAL CITY',
      'COACHELLA',
      'DESERT CENTER',
      'DESERT HOT SPRINGS',
      'EARP',
      'EL CENTRO',
      'HEBER',
      'HOLTVILLE',
      'IMPERIAL',
      'JOSHUA TREE',
      'LA QUINTA',
      'MECCA',
      'MORONGO VALLEY',
      'NILAND',
      'NORTH PALM SPRINGS',
      'OCOTILLO',
      'PALM SPRINGS',
      'PALO VERDE',
      'PARKER DAM',
      'PIONEERTOWN',
      'RANCHO MIRAGE',
      'SEELEY',
      'THERMAL',
      'SALTON CITY',
      'THOUSAND PALMS',
      'TWENTYNINE PALMS',
      'VIDAL',
      'WESTMORLAND',
      'WHITE WATER',
      'WINTERHAVEN',
      'YUCCA VALLEY',
      'LANDERS',
      'ADELANTO',
      'AMBOY',
      'ANGELUS OALASKAS',
      'APPLE VALLEY',
      'BALASKAER',
      'FORT IRWIN',
      'BARSTOW',
      'GRAND TERRACE',
      'BIG BEAR CITY',
      'BIG BEAR LALASKAE',
      'BLOOMINGTON',
      'BLUE JAY',
      'BRYN MAWR',
      'CALIMESA',
      'CEDAR GLEN',
      'CEDARPINES PARK',
      'CIMA',
      'COLTON',
      'CRESTLINE',
      'CREST PARK',
      'DAGGETT',
      'DEATH VALLEY',
      'PHELAN',
      'ESSEX',
      'FAWNSKIN',
      'FONTANA',
      'LUDLOW',
      'FOREST FALLS',
      'HESPERIA',
      'GREEN VALLEY LALASKAE',
      'HELENDALE',
      'HIGHLAND',
      'HINKLEY',
      'LOMA LINDA',
      'LALASKAE ARROWHEAD',
      'LUCERNE VALLEY',
      'LYTLE CREEK',
      'MENTONE',
      'NEEDLES',
      'NIPTON',
      'NEWBERRY SPRINGS',
      'MOUNTAIN PASS',
      'ORO GRANDE',
      'PATTON',
      'PINON HILLS',
      'REDLANDS',
      'RIALTO',
      'RIMFOREST',
      'RUNNING SPRINGS',
      'SHOSHONE',
      'SKYFOREST',
      'SUGARLOAF',
      'TECOPA',
      'TWIN PEALASKAS',
      'VICTORVILLE',
      'WRIGHTWOOD',
      'YERMO',
      'YUCAIPA',
      'SAN BERNARDINO',
      'RIVERSIDE',
      'MARCH AIR FORCE BASE',
      'LALASKAE ELSINORE',
      'AGUANGA',
      'ANZA',
      'HEMET',
      'HOMELAND',
      'IDYLLWILD',
      'MORENO VALLEY',
      'MOUNTAIN CENTER',
      'MURRIETA',
      'NUEVO',
      'PERRIS',
      'SAN JACINTO',
      'MENIFEE',
      'SUN CITY',
      'TEMECULA',
      'WILDOMAR',
      'WINCHESTER',
      'IRVINE',
      'HUNTINGTON BEACH',
      'LAGUNA NIGUEL',
      'EL TORO',
      'FOOTHILL RANCH',
      'CAPISTRANO BEACH',
      'CORONA DEL MAR',
      'COSTA MESA',
      'DANA POINT',
      'LALASKAE FOREST',
      'LAGUNA HILLS',
      'EAST IRVINE',
      'LAGUNA BEACH',
      'MIDWAY CITY',
      'ALISO VIEJO',
      'NEWPORT COAST',
      'NEWPORT BEACH',
      'SAN CLEMENTE',
      'SAN JUAN CAPISTRANO',
      'SILVERADO',
      'TRABUCO CANYON',
      'WESTMINSTER',
      'RANCHO SANTA MARGARITA',
      'MISSION VIEJO',
      'LADERA RANCH',
      'SANTA ANA',
      'FOUNTAIN VALLEY',
      'TUSTIN',
      'ANAHEIM',
      'ATWOOD',
      'BREA',
      'FULLERTON',
      'GARDEN GROVE',
      'ORANGE',
      'NORCO',
      'VILLA PARK',
      'PLACENTIA',
      'CORONA',
      'YORBA LINDA',
      'VENTURA',
      'CAMARILLO',
      'CARPINTERIA',
      'FILLMORE',
      'MOORPARK',
      'OALASKA VIEW',
      'OJAI',
      'OXNARD',
      'PIRU',
      'PORT HUENEME',
      'POINT MUGU NAWC',
      'PORT HUENEME CBC BASE',
      'SANTA PAULA',
      'SIMI VALLEY',
      'BRANDEIS',
      'SOMIS',
      'SUMMERLAND',
      'SANTA BARBARA',
      'GOLETA',
      'ALPAUGH',
      'ARMONA',
      'ARVIN',
      'AVENAL',
      'BODFISH',
      'BUTTONWILLOW',
      'CALIFORNIA HOT SPRINGS',
      'CAMP NELSON',
      'COALINGA',
      'CORCORAN',
      'DELANO',
      'DUCOR',
      'EARLIMART',
      'EDISON',
      'EXETER',
      'FRAZIER PARK',
      'FARMERSVILLE',
      'FELLOWS',
      'GLENNVILLE',
      'GOSHEN',
      'HANFORD',
      'HURON',
      'IVANHOE',
      'KAWEAH',
      'KERNVILLE',
      'KETTLEMAN CITY',
      'LALASKAE ISABELLA',
      'LAMONT',
      'LATON',
      'LEBEC',
      'LEMON COVE',
      'LEMOORE',
      'LINDSAY',
      'LOST HILLS',
      'MC FARLAND',
      'MC KITTRICK',
      'MARICOPA',
      'NEW CUYAMA',
      'ONYX',
      'PIXLEY',
      'PORTERVILLE',
      'POSEY',
      'RICHGROVE',
      'SEQUOIA NATIONAL PARK',
      'SHAFTER',
      'SPRINGVILLE',
      'STRATFORD',
      'STRATHMORE',
      'TAFT',
      'TERRA BELLA',
      'THREE RIVERS',
      'TIPTON',
      'TULARE',
      'TUPMAN',
      'VISALIA',
      'WASCO',
      'WAUKENA',
      'WELDON',
      'WOFFORD HEIGHTS',
      'WOODLALASKAE',
      'WOODY',
      'BALASKAERSFIELD',
      'SAN LUIS OBISPO',
      'LOS OSOS',
      'ARROYO GRANDE',
      'ATASCADERO',
      'AVILA BEACH',
      'BRADLEY',
      'BUELLTON',
      'CAMBRIA',
      'CASMALIA',
      'CAYUCOS',
      'CRESTON',
      'GROVER BEACH',
      'GUADALUPE',
      'HARMONY',
      'LOMPOC',
      'LOS ALAMOS',
      'LOS OLIVOS',
      'MORRO BAY',
      'NIPOMO',
      'OCEANO',
      'PASO ROBLES',
      'PISMO BEACH',
      'SAN ARDO',
      'SAN MIGUEL',
      'SAN SIMEON',
      'SANTA MARGARITA',
      'SANTA MARIA',
      'SANTA YNEZ',
      'SHANDON',
      'SOLVANG',
      'TEMPLETON',
      'MOJAVE',
      'CALIFORNIA CITY',
      'ACTON',
      'BENTON',
      'BIG PINE',
      'BISHOP',
      'BORON',
      'BRIDGEPORT',
      'CALIENTE',
      'CANTIL',
      'DARWIN',
      'EDWARDS',
      'INDEPENDENCE',
      'INYOKERN',
      'JOHANNESBURG',
      'JUNE LALASKAE',
      'KEELER',
      'KEENE',
      'LALASKAE HUGHES',
      'LANCASTER',
      'LEE VINING',
      'LITTLE LALASKAE',
      'LITTLEROCK',
      'LLANO',
      'LONE PINE',
      'MAMMOTH LALASKAES',
      'OLANCHA',
      'PALMDALE',
      'PEARBLOSSOM',
      'RANDSBURG',
      'RIDGECREST',
      'RED MOUNTAIN',
      'ROSAMOND',
      'TEHACHAPI',
      'TRONA',
      'VALYERMO',
      'AHWAHNEE',
      'AUBERRY',
      'BADGER',
      'BASS LALASKAE',
      'BIG CREEK',
      'BIOLA',
      'BURREL',
      'CANTUA CREEK',
      'CARUTHERS',
      'CHOWCHILLA',
      'CLOVIS',
      'COARSEGOLD',
      'CUTLER',
      'DEL REY',
      'DINUBA',
      'DOS PALOS',
      'DUNLAP',
      'FIREBAUGH',
      'FISH CAMP',
      'FIVE POINTS',
      'FOWLER',
      'FRIANT',
      'HELM',
      'HUME',
      'KERMAN',
      'KINGSBURG',
      'KINGS CANYON NATIONAL PK',
      'LALASKAESHORE',
      'LOS BANOS',
      'MADERA',
      'MENDOTA',
      'MIRAMONTE',
      'MONO HOT SPRINGS',
      'NORTH FORK',
      'OALASKAHURST',
      'O NEALS',
      'ORANGE COVE',
      'OROSI',
      'PARLIER',
      'PIEDRA',
      'FRESNO',
      'PRATHER',
      'RAISIN',
      'RAYMOND',
      'REEDLEY',
      'RIVERDALE',
      'SANGER',
      'SAN JOAQUIN',
      'SANTA RITA PARK',
      'SELMA',
      'SHAVER LALASKAE',
      'SOUTH DOS PALOS',
      'SULTANA',
      'TOLLHOUSE',
      'TRANQUILLITY',
      'WISHON',
      'YETTEM',
      'TRAVER',
      'SQUAW VALLEY',
      'SALINAS',
      'BIG SUR',
      'CARMEL',
      'CARMEL VALLEY',
      'CHUALAR',
      'GONZALES',
      'GREENFIELD',
      'JOLON',
      'KING CITY',
      'LOCKWOOD',
      'MARINA',
      'MONTEREY',
      'PACIFIC GROVE',
      'PEBBLE BEACH',
      'SAN LUCAS',
      'SEASIDE',
      'SOLEDAD',
      'SPRECKELS',
      'BELMONT',
      'BRISBANE',
      'BURLINGAME',
      'DALY CITY',
      'EL GRANADA',
      'HALF MOON BAY',
      'LA HONDA',
      'LOMA MAR',
      'LOS ALTOS',
      'MENLO PARK',
      'ATHERTON',
      'PORTOLA VALLEY',
      'MILLBRAE',
      'MOUNTAIN VIEW',
      'MONTARA',
      'MOSS BEACH',
      'PACIFICA',
      'REDWOOD CITY',
      'PESCADERO',
      'SAN BRUNO',
      'SAN CARLOS',
      'SAN GREGORIO',
      'SOUTH SAN FRANCISCO',
      'SUNNYVALE',
      'SAN FRANCISCO',
      'SACRAMENTO',
      'PALO ALTO',
      'STANFORD',
      'SAN MATEO',
      'ALAMEDA',
      'AMERICAN CANYON',
      'DANVILLE',
      'ALAMO',
      'ANGWIN',
      'ANTIOCH',
      'BENICIA',
      'BETHEL ISLAND',
      'BIRDS LANDING',
      'BRENTWOOD',
      'BYRON',
      'CALISTOGA',
      'CANYON',
      'CLAYTON',
      'CONCORD',
      'PLEASANT HILL',
      'CROCKETT',
      'DIABLO',
      'EL CERRITO',
      'FAIRFIELD',
      'TRAVIS AFB',
      'FREMONT',
      'HAYWARD',
      'CASTRO VALLEY',
      'HERCULES',
      'KNIGHTSEN',
      'LAFAYETTE',
      'LIVERMORE',
      'MARTINEZ',
      'MORAGA',
      'NAPA',
      'NEWARK',
      'OALASKALEY',
      'OALASKAVILLE',
      'ORINDA',
      'PINOLE',
      'PITTSBURG',
      'PLEASANTON',
      'POPE VALLEY',
      'DUBLIN',
      'PORT COSTA',
      'RIO VISTA',
      'RODEO',
      'RUTHERFORD',
      'SAINT HELENA',
      'DEER PARK',
      'SAN LEANDRO',
      'SAN LORENZO',
      'SAN RAMON',
      'SUISUN CITY',
      'SUNOL',
      'UNION CITY',
      'VALLEJO',
      'WALNUT CREEK',
      'YOUNTVILLE',
      'OALASKALAND',
      'EMERYVILLE',
      'PIEDMONT',
      'BERKELEY',
      'ALBANY',
      'RICHMOND',
      'EL SOBRANTE',
      'SAN PABLO',
      'SAN RAFAEL',
      'GREENBRAE',
      'KENTFIELD',
      'BELVEDERE TIBURON',
      'BODEGA',
      'BODEGA BAY',
      'BOLINAS',
      'CORTE MADERA',
      'COTATI',
      'ROHNERT PARK',
      'DILLON BEACH',
      'FAIRFAX',
      'FOREST KNOLLS',
      'INVERNESS',
      'LAGUNITAS',
      'LARKSPUR',
      'MARSHALL',
      'MILL VALLEY',
      'NOVATO',
      'NICASIO',
      'OLEMA',
      'PENNGROVE',
      'PETALUMA',
      'POINT REYES STATION',
      'ROSS',
      'SAN ANSELMO',
      'SAN GERONIMO',
      'SAN QUENTIN',
      'SAUSALITO',
      'STINSON BEACH',
      'TOMALES',
      'VALLEY FORD',
      'WOODACRE',
      'APTOS',
      'ALVISO',
      'AROMAS',
      'BEN LOMOND',
      'BOULDER CREEK',
      'BROOKDALE',
      'CAMPBELL',
      'CAPITOLA',
      'CASTROVILLE',
      'COYOTE',
      'CUPERTINO',
      'DAVENPORT',
      'FELTON',
      'FREEDOM',
      'GILROY',
      'HOLLISTER',
      'HOLY CITY',
      'LOS GATOS',
      'MILPITAS',
      'MORGAN HILL',
      'MOSS LANDING',
      'MOUNT HERMON',
      'NEW ALMADEN',
      'PAICINES',
      'REDWOOD ESTATES',
      'SAN JUAN BAUTISTA',
      'SAN MARTIN',
      'SANTA CLARA',
      'SANTA CRUZ',
      'SCOTTS VALLEY',
      'SARATOGA',
      'SOQUEL',
      'TRES PINOS',
      'WATSONVILLE',
      'SAN JOSE',
      'MOUNT HAMILTON',
      'STOCKTON',
      'ACAMPO',
      'ALTAVILLE',
      'ANGELS CAMP',
      'ARNOLD',
      'AVERY',
      'BURSON',
      'CAMPO SECO',
      'CLEMENTS',
      'COPPEROPOLIS',
      'DOUGLAS FLAT',
      'FARMINGTON',
      'FRENCH CAMP',
      'GLENCOE',
      'HATHAWAY PINES',
      'HOLT',
      'LINDEN',
      'LOCKEFORD',
      'LODI',
      'MOKELUMNE HILL',
      'MOUNTAIN RANCH',
      'MURPHYS',
      'RAIL ROAD FLAT',
      'SAN ANDREAS',
      'SHEEP RANCH',
      'VALLECITO',
      'VALLEY SPRINGS',
      'VICTOR',
      'WALLACE',
      'WEST POINT',
      'WILSEYVILLE',
      'WOODBRIDGE',
      'LYOTH',
      'ATWATER',
      'BALLICO',
      'TRACY',
      'BIG OALASKA FLAT',
      'CATHEYS VALLEY',
      'CERES',
      'CHINESE CAMP',
      'COLUMBIA',
      'COULTERVILLE',
      'CRESSEY',
      'CROWS LANDING',
      'DARDANELLE',
      'DELHI',
      'DENAIR',
      'EL NIDO',
      'EL PORTAL',
      'EMPIRE',
      'ESCALON',
      'GROVELAND',
      'GUSTINE',
      'HICKMAN',
      'HILMAR',
      'HORNITOS',
      'HUGHSON',
      'JAMESTOWN',
      'KEYES',
      'LA GRANGE',
      'LATHROP',
      'LE GRAND',
      'LIVINGSTON',
      'LONG BARN',
      'MANTECA',
      'MARIPOSA',
      'MERCED',
      'MIDPINES',
      'MI WUK VILLAGE',
      'MOCCASIN',
      'MODESTO',
      'NEWMAN',
      'OALASKADALE',
      'PATTERSON',
      'PINECREST',
      'PLANADA',
      'RIPON',
      'RIVERBANK',
      'SALIDA',
      'SNELLING',
      'SONORA',
      'SOULSBYVILLE',
      'STANDARD',
      'STEVINSON',
      'STRAWBERRY',
      'TUOLUMNE',
      'TURLOCK',
      'TWAIN HARTE',
      'VERNALIS',
      'WATERFORD',
      'WESTLEY',
      'WINTON',
      'YOSEMITE NATIONAL PARK',
      'SANTA ROSA',
      'ALBION',
      'ANNAPOLIS',
      'BOONVILLE',
      'BOYES HOT SPRINGS',
      'BRANSCOMB',
      'CALPELLA',
      'CAMP MEEKER',
      'CASPAR',
      'CAZADERO',
      'CLEARLALASKAE',
      'CLEARLALASKAE OALASKAS',
      'CLEARLALASKAE PARK',
      'CLOVERDALE',
      'COBB',
      'COMPTCHE',
      'COVELO',
      'DOS RIOS',
      'DUNCANS MILLS',
      'ELDRIDGE',
      'ELK',
      'EL VERANO',
      'FINLEY',
      'FORESTVILLE',
      'FORT BRAGG',
      'FULTON',
      'GEYSERVILLE',
      'GLEN ELLEN',
      'GLENHAVEN',
      'GRATON',
      'GUALALA',
      'GUERNEVILLE',
      'HEALDSBURG',
      'HOPLAND',
      'JENNER',
      'KELSEYVILLE',
      'KENWOOD',
      'LALASKAEPORT',
      'LAYTONVILLE',
      'LITTLERIVER',
      'LOWER LALASKAE',
      'LUCERNE',
      'MANCHESTER',
      'MENDOCINO',
      'MIDDLETOWN',
      'MONTE RIO',
      'NAVARRO',
      'NICE',
      'OCCIDENTAL',
      'PHILO',
      'POINT ARENA',
      'POTTER VALLEY',
      'REDWOOD VALLEY',
      'RIO NIDO',
      'SEBASTOPOL',
      'SONOMA',
      'STEWARTS POINT',
      'TALMAGE',
      'UKIAH',
      'UPPER LALASKAE',
      'VILLA GRANDE',
      'VINEBURG',
      'WESTPORT',
      'WILLITS',
      'WINDSOR',
      'WITTER SPRINGS',
      'YORKVILLE',
      'THE SEA RANCH',
      'EUREKA',
      'ALDERPOINT',
      'BLOCKSBURG',
      'ARCATA',
      'MCKINLEYVILLE',
      'BAYSIDE',
      'BLUE LALASKAE',
      'BRIDGEVILLE',
      'BURNT RANCH',
      'CARLOTTA',
      'CRESCENT CITY',
      'CUTTEN',
      'FERNDALE',
      'FIELDS LANDING',
      'FORT DICK',
      'FORTUNA',
      'GARBERVILLE',
      'GASQUET',
      'HONEYDEW',
      'HOOPA',
      'HYDESVILLE',
      'KLAMATH',
      'KNEELAND',
      'KORBEL',
      'LOLETA',
      'MAD RIVER',
      'MIRANDA',
      'MYERS FLAT',
      'ORICK',
      'ORLEANS',
      'PETROLIA',
      'PHILLIPSVILLE',
      'REDWAY',
      'RIO DELL',
      'SALYER',
      'SAMOA',
      'SCOTIA',
      'SMITH RIVER',
      'SOMES BAR',
      'REDCREST',
      'TRINIDAD',
      'WEOTT',
      'WILLOW CREEK',
      'LEGGETT',
      'PIERCY',
      'WHITETHORN',
      'ZENIA',
      'AMADOR CITY',
      'AUBURN',
      'WEST SACRAMENTO',
      'BROOKS',
      'CAPAY',
      'CARMICHAEL',
      'CITRUS HEIGHTS',
      'CLARKSBURG',
      'COLOMA',
      'COOL',
      'COURTLAND',
      'DAVIS',
      'EL MACERO',
      'DIAMOND SPRINGS',
      'DIXON',
      'EL DORADO',
      'ELK GROVE',
      'ELMIRA',
      'ELVERTA',
      'ESPARTO',
      'FAIR OALASKAS',
      'FIDDLETOWN',
      'FOLSOM',
      'FORESTHILL',
      'GALT',
      'GARDEN VALLEY',
      'GEORGETOWN',
      'GREENWOOD',
      'GRIZZLY FLATS',
      'GUINDA',
      'HERALD',
      'HOOD',
      'IONE',
      'ISLETON',
      'JACKSON',
      'KIT CARSON',
      'KNIGHTS LANDING',
      'KIRKWOOD',
      'LINCOLN',
      'LOOMIS',
      'LOTUS',
      'MCCLELLAN',
      'MADISON',
      'MARTELL',
      'MATHER',
      'MOUNT AUKUM',
      'NEWCASTLE',
      'NICOLAUS',
      'NORTH HIGHLANDS',
      'ROSEVILLE',
      'ORANGEVALE',
      'PENRYN',
      'PILOT HILL',
      'PINE GROVE',
      'PIONEER',
      'PLACERVILLE',
      'PLEASANT GROVE',
      'PLYMOUTH',
      'RANCHO CORDOVA',
      'REPRESA',
      'RESCUE',
      'RIO LINDA',
      'RIO OSO',
      'RIVER PINES',
      'ROBBINS',
      'ROCKLIN',
      'RUMSEY',
      'RYDE',
      'SHERIDAN',
      'SHINGLE SPRINGS',
      'SLOUGHHOUSE',
      'SOMERSET',
      'SUTTER CREEK',
      'THORNTON',
      'VACAVILLE',
      'VOLCANO',
      'WALNUT GROVE',
      'WHEATLAND',
      'WILTON',
      'WINTERS',
      'WOODLAND',
      'YOLO',
      'ZAMORA',
      'DRYTOWN',
      'ALTA',
      'APPLEGATE',
      'CAMINO',
      'CHICAGO PARK',
      'COLFAX',
      'DUTCH FLAT',
      'EMIGRANT GAP',
      'GOLD RUN',
      'KYBURZ',
      'ECHO LALASKAE',
      'MEADOW VISTA',
      'NORDEN',
      'POLLOCK PINES',
      'SODA SPRINGS',
      'TWIN BRIDGES',
      'WEIMAR',
      'GRANITE BAY',
      'EL DORADO HILLS',
      'ANTELOPE',
      'MARYSVILLE',
      'BEALE AFB',
      'ALLEGHANY',
      'ARBUCKLE',
      'ARTOIS',
      'BANGOR',
      'BELDEN',
      'BERRY CREEK',
      'BIGGS',
      'BROWNS VALLEY',
      'BROWNSVILLE',
      'BUTTE CITY',
      'CAMPTONVILLE',
      'CANYONDAM',
      'CEDAR RIDGE',
      'CHALLENGE',
      'CHICO',
      'CLIPPER MILLS',
      'COLLEGE CITY',
      'COLUSA',
      'CRESCENT MILLS',
      'DOBBINS',
      'DOWNIEVILLE',
      'DUNNIGAN',
      'DURHAM',
      'ELK CREEK',
      'FEATHER FALLS',
      'FORBESTOWN',
      'FOREST RANCH',
      'GLENN',
      'GOODYEARS BAR',
      'GRASS VALLEY',
      'PENN VALLEY',
      'GREENVILLE',
      'GRIDLEY',
      'GRIMES',
      'HAMILTON CITY',
      'LIVE OALASKA',
      'MAGALIA',
      'MAXWELL',
      'MEADOW VALLEY',
      'MERIDIAN',
      'NELSON',
      'NEVADA CITY',
      'NORTH SAN JUAN',
      'OLIVEHURST',
      'OREGON HOUSE',
      'ORLAND',
      'OROVILLE',
      'PARADISE',
      'PALERMO',
      'PRINCETON',
      'QUINCY',
      'RACKERBY',
      'RICHVALE',
      'ROUGH AND READY',
      'SMARTVILLE',
      'STIRLING CITY',
      'STONYFORD',
      'STORRIE',
      'STRAWBERRY VALLEY',
      'SUTTER',
      'TAYLORSVILLE',
      'TWAIN',
      'WASHINGTON',
      'WILLIAMS',
      'WILLOWS',
      'YUBA CITY',
      'REDDING',
      'ADIN',
      'ANDERSON',
      'BELLA VISTA',
      'BIEBER',
      'BIG BAR',
      'BIG BEND',
      'BURNEY',
      'CALLAHAN',
      'CANBY',
      'CASSEL',
      'CASTELLA',
      'SHASTA LALASKAE',
      'CHESTER',
      'CORNING',
      'COTTONWOOD',
      'DORRIS',
      'DOUGLAS CITY',
      'DUNSMUIR',
      'ETNA',
      'FALL RIVER MILLS',
      'FLOURNOY',
      'FORKS OF SALMON',
      'FORT JONES',
      'FRENCH GULCH',
      'GAZELLE',
      'GERBER',
      'GREENVIEW',
      'GRENADA',
      'HAPPY CAMP',
      'HAT CREEK',
      'HAYFORK',
      'HORNBROOK',
      'HYAMPOM',
      'IGO',
      'JUNCTION CITY',
      'KLAMATH RIVER',
      'LALASKAEHEAD',
      'LEWISTON',
      'LOOKOUT',
      'LOS MOLINOS',
      'MCARTHUR',
      'MCCLOUD',
      'MACDOEL',
      'MANTON',
      'MILL CREEK',
      'MILLVILLE',
      'MINERAL',
      'MONTAGUE',
      'MONTGOMERY CREEK',
      'MOUNT SHASTA',
      'NUBIEBER',
      'OALASKA RUN',
      'OBRIEN',
      'OLD STATION',
      'PALO CEDRO',
      'PASKENTA',
      'PAYNES CREEK',
      'PLATINA',
      'PROBERTA',
      'RED BLUFF',
      'ROUND MOUNTAIN',
      'SCOTT BAR',
      'SEIAD VALLEY',
      'SHASTA',
      'SHINGLETOWN',
      'TEHAMA',
      'TRINITY CENTER',
      'VINA',
      'WEAVERVILLE',
      'WEED',
      'WHISKEYTOWN',
      'WHITMORE',
      'YREKA',
      'ALTURAS',
      'BLAIRSDEN-GRAEAGLE',
      'CEDARVILLE',
      'CHILCOOT',
      'CLIO',
      'COLEVILLE',
      'DAVIS CREEK',
      'DOYLE',
      'EAGLEVILLE',
      'FLORISTON',
      'FORT BIDWELL',
      'HERLONG',
      'JANESVILLE',
      'LALASKAE CITY',
      'LIKELY',
      'LITCHFIELD',
      'LOYALTON',
      'MADELINE',
      'MARKLEEVILLE',
      'MILFORD',
      'PORTOLA',
      'RAVENDALE',
      'CALPINE',
      'SIERRA CITY',
      'SIERRAVILLE',
      'SUSANVILLE',
      'STANDISH',
      'BECKWOURTH',
      'TERMO',
      'TOPAZ',
      'TULELALASKAE',
      'VINTON',
      'WENDEL',
      'WESTWOOD',
      'CARNELIAN BAY',
      'HOMEWOOD',
      'TAHOMA',
      'KINGS BEACH',
      'TAHOE CITY',
      'OLYMPIC VALLEY',
      'TAHOE VISTA',
      'SOUTH LALASKAE TAHOE',
      'TRUCKEE'
    ]
  },
  CO: {
    code: 'CO',
    name: 'COLORADO',
    cities: [
      'ARVADA',
      'AURORA',
      'BROOMFIELD',
      'COMMERCE CITY',
      'DUPONT',
      'ELDORADO SPRINGS',
      'LAFAYETTE',
      'LOUISVILLE',
      'WESTMINSTER',
      'WHEAT RIDGE',
      'AGATE',
      'BENNETT',
      'BYERS',
      'CASTLE ROCK',
      'DEER TRAIL',
      'ELBERT',
      'ELIZABETH',
      'ENGLEWOOD',
      'FRANKTOWN',
      'KIOWA',
      'LARKSPUR',
      'LITTLETON',
      'LOUVIERS',
      'MONUMENT',
      'PALMER LALASKAE',
      'PARKER',
      'SEDALIA',
      'STRASBURG',
      'WATKINS',
      'DENVER',
      'BOULDER',
      'GOLDEN',
      'ALMA',
      'BAILEY',
      'BLACK HAWK',
      'BOND',
      'BRECKENRIDGE',
      'BUFFALO CREEK',
      'BURNS',
      'CENTRAL CITY',
      'CLARK',
      'CLIMAX',
      'COALMONT',
      'COMO',
      'CONIFER',
      'COWDREY',
      'DILLON',
      'DUMONT',
      'EVERGREEN',
      'EMPIRE',
      'FAIRPLAY',
      'FRASER',
      'FRISCO',
      'GEORGETOWN',
      'GRANBY',
      'GRAND LALASKAE',
      'GRANT',
      'HARTSEL',
      'HOT SULPHUR SPRINGS',
      'IDAHO SPRINGS',
      'IDLEDALE',
      'INDIAN HILLS',
      'JAMESTOWN',
      'JEFFERSON',
      'KITTREDGE',
      'KREMMLING',
      'LEADVILLE',
      'MC COY',
      'MORRISON',
      'NEDERLAND',
      'OALASKA CREEK',
      'PARSHALL',
      'PHIPPSBURG',
      'PINE',
      'PINECLIFFE',
      'RAND',
      'ROLLINSVILLE',
      'SHAWNEE',
      'SILVER PLUME',
      'STEAMBOAT SPRINGS',
      'TABERNASH',
      'TOPONAS',
      'WALDEN',
      'WARD',
      'WINTER PARK',
      'YAMPA',
      'SILVERTHORNE',
      'LONGMONT',
      'ALLENSPARK',
      'ESTES PARK',
      'BELLVUE',
      'BERTHOUD',
      'DACONO',
      'DRALASKAE',
      'ERIE',
      'FIRESTONE',
      'FORT COLLINS',
      'FREDERICK',
      'GLEN HAVEN',
      'HYGIENE',
      'JOHNSTOWN',
      'LAPORTE',
      'LIVERMORE',
      'LOVELAND',
      'LYONS',
      'MASONVILLE',
      'MEAD',
      'MILLIKEN',
      'NIWOT',
      'RED FEATHER LALASKAES',
      'SEVERANCE',
      'TIMNATH',
      'WELLINGTON',
      'WINDSOR',
      'BRIGHTON',
      'AULT',
      'BRIGGSDALE',
      'CARR',
      'EASTLALASKAE',
      'EATON',
      'EVANS',
      'FORT LUPTON',
      'GALETON',
      'GILCREST',
      'GILL',
      'GREELEY',
      'HENDERSON',
      'HUDSON',
      'KEENESBURG',
      'KERSEY',
      'LA SALLE',
      'LUCERNE',
      'NUNN',
      'ORCHARD',
      'PIERCE',
      'PLATTEVILLE',
      'ROGGEN',
      'WELDONA',
      'WIGGINS',
      'FORT MORGAN',
      'LOG LANE VILLAGE',
      'ALASKARON',
      'AMHERST',
      'ATWOOD',
      'BRUSH',
      'CROOK',
      'ECKLEY',
      'FLEMING',
      'GROVER',
      'HAXTUN',
      'HEREFORD',
      'HILLROSE',
      'HOLYOKE',
      'IDALIA',
      'ILIFF',
      'JULESBURG',
      'LINDON',
      'MERINO',
      'NEW RAYMER',
      'OTIS',
      'OVID',
      'PADRONI',
      'PAOLI',
      'PEETZ',
      'SEDGWICK',
      'SNYDER',
      'STERLING',
      'STONEHAM',
      'VERNON',
      'WOODROW',
      'WRAY',
      'YUMA',
      'ANTON',
      'ARAPAHOE',
      'ARRIBA',
      'BETHUNE',
      'BURLINGTON',
      'CALHAN',
      'CASCADE',
      'CHEYENNE WELLS',
      'COPE',
      'CRIPPLE CREEK',
      'DIVIDE',
      'FLAGLER',
      'FLORISSANT',
      'FOUNTAIN',
      'GENOA',
      'GREEN MOUNTAIN FALLS',
      'GUFFEY',
      'HUGO',
      'JOES',
      'KARVAL',
      'KIRK',
      'KIT CARSON',
      'LIMON',
      'LALASKAE GEORGE',
      'MANITOU SPRINGS',
      'MATHESON',
      'PEYTON',
      'RAMAH',
      'RUSH',
      'SEIBERT',
      'SIMLA',
      'STRATTON',
      'U S A F ACADEMY',
      'VICTOR',
      'VONA',
      'WILD HORSE',
      'WOODLAND PARK',
      'YODER',
      'COLORADO SPRINGS',
      'PUEBLO',
      'COLORADO CITY',
      'AGUILAR',
      'ARLINGTON',
      'AVONDALE',
      'BEULAH',
      'BONCARBO',
      'BOONE',
      'BRANSON',
      'CAMPO',
      'CHERAW',
      'CROWLEY',
      'EADS',
      'FORT LYON',
      'FOWLER',
      'GARDNER',
      'GRANADA',
      'GULNARE',
      'HARTMAN',
      'HASTY',
      'HASWELL',
      'HOEHNE',
      'HOLLY',
      'KIM',
      'LA JUNTA',
      'LAMAR',
      'LAS ANIMAS',
      'LA VETA',
      'MC CLAVE',
      'MANZANOLA',
      'MODEL',
      'OLNEY SPRINGS',
      'ORDWAY',
      'PRITCHETT',
      'WALSENBURG',
      'ROCKY FORD',
      'RYE',
      'SHERIDAN LALASKAE',
      'SPRINGFIELD',
      'STARKVILLE',
      'SUGAR CITY',
      'SWINK',
      'TRINCHERA',
      'TRINIDAD',
      'TWO BUTTES',
      'VILAS',
      'WALSH',
      'WESTON',
      'WILEY',
      'ALAMOSA',
      'ANTONITO',
      'ARBOLES',
      'BAYFIELD',
      'BLANCA',
      'CAPULIN',
      'CENTER',
      'CHAMA',
      'CHIMNEY ROCK',
      'CHROMO',
      'CONEJOS',
      'CREEDE',
      'CRESTONE',
      'DEL NORTE',
      'FORT GARLAND',
      'GARCIA',
      'HOMELALASKAE',
      'HOOPER',
      'IGNACIO',
      'JAROSO',
      'LA JARA',
      'MANASSA',
      'MOFFAT',
      'MONTE VISTA',
      'MOSCA',
      'PAGOSA SPRINGS',
      'ROMEO',
      'SAGUACHE',
      'SANFORD',
      'SAN LUIS',
      'SAN PABLO',
      'SOUTH FORK',
      'VILLA GROVE',
      'SALIDA',
      'ALMONT',
      'BUENA VISTA',
      'CANON CITY',
      'CIMARRON',
      'COAL CREEK',
      'COALDALE',
      'COTOPAXI',
      'CRESTED BUTTE',
      'FLORENCE',
      'MONARCH',
      'GRANITE',
      'GUNNISON',
      'HILLSIDE',
      'HOWARD',
      'LALASKAE CITY',
      'NATHROP',
      'OHIO CITY',
      'PARLIN',
      'PENROSE',
      'PITKIN',
      'PONCHA SPRINGS',
      'POWDERHORN',
      'ROCKVALE',
      'SARGENTS',
      'TWIN LALASKAES',
      'WESTCLIFFE',
      'WETMORE',
      'DURANGO',
      'CAHONE',
      'CORTEZ',
      'DOLORES',
      'DOVE CREEK',
      'EGNAR',
      'HESPERUS',
      'LEWIS',
      'MANCOS',
      'MARVEL',
      'MESA VERDE NATIONAL PARK',
      'PLEASANT VIEW',
      'RICO',
      'TOWAOC',
      'YELLOW JACKET',
      'MONTROSE',
      'AUSTIN',
      'BEDROCK',
      'CEDAREDGE',
      'CORY',
      'CRAWFORD',
      'DELTA',
      'ECKERT',
      'HOTCHKISS',
      'LAZEAR',
      'MAHER',
      'NATURITA',
      'NORWOOD',
      'NUCLA',
      'OLATHE',
      'OPHIR',
      'OURAY',
      'PAONIA',
      'PARADOX',
      'PLACERVILLE',
      'REDVALE',
      'RIDGWAY',
      'SILVERTON',
      'SOMERSET',
      'TELLURIDE',
      'GRAND JUNCTION',
      'CLIFTON',
      'FRUITA',
      'GATEWAY',
      'GLADE PARK',
      'LOMA',
      'MACK',
      'PALISADE',
      'WHITEWATER',
      'GLENWOOD SPRINGS',
      'DINOSAUR',
      'ASPEN',
      'SNOWMASS VILLAGE',
      'AVON',
      'BASALT',
      'CARBONDALE',
      'COLLBRAN',
      'CRAIG',
      'DE BEQUE',
      'EAGLE',
      'EDWARDS',
      'PARACHUTE',
      'BATTLEMENT MESA',
      'GYPSUM',
      'HAMILTON',
      'HAYDEN',
      'MAYBELL',
      'MEEKER',
      'MEREDITH',
      'MESA',
      'MINTURN',
      'MOLINA',
      'NEW CASTLE',
      'RANGELY',
      'RED CLIFF',
      'RIFLE',
      'SILT',
      'SLATER',
      'SNOWMASS',
      'WOLCOTT',
      'WOODY CREEK',
      'VAIL'
    ]
  },
  CT: {
    code: 'CT',
    name: 'CONNECTICUT',
    cities: [
      'AVON',
      'BLOOMFIELD',
      'WINDSOR',
      'BRISTOL',
      'BURLINGTON',
      'BROAD BROOK',
      'CANAAN',
      'CANTON',
      'CANTON CENTER',
      'COLEBROOK',
      'COLLINSVILLE',
      'EAST BERLIN',
      'EAST CANAAN',
      'EAST GLASTONBURY',
      'EAST GRANBY',
      'EAST HARTLAND',
      'EAST WINDSOR HILL',
      'ELLINGTON',
      'FARMINGTON',
      'FALLS VILLAGE',
      'GLASTONBURY',
      'GRANBY',
      'BERLIN',
      'LALASKAEVILLE',
      'MANCHESTER',
      'BOLTON',
      'MELROSE',
      'NEW BRITAIN',
      'NEW HARTFORD',
      'NORFOLK',
      'NORTH CANTON',
      'NORTH GRANBY',
      'PINE MEADOW',
      'PLAINVILLE',
      'BARKHAMSTED',
      'POQUONOCK',
      'RIVERTON',
      'VERNON ROCKVILLE',
      'ROCKY HILL',
      'SALISBURY',
      'SHARON',
      'SIMSBURY',
      'SOMERS',
      'SOMERSVILLE',
      'SOUTH GLASTONBURY',
      'SOUTH WINDSOR',
      'STAFFORD',
      'STAFFORD SPRINGS',
      'STAFFORDVILLE',
      'SUFFIELD',
      'TACONIC',
      'TARIFFVILLE',
      'ENFIELD',
      'TOLLAND',
      'UNIONVILLE',
      'EAST WINDSOR',
      'WEATOGUE',
      'WEST GRANBY',
      'WEST HARTLAND',
      'WEST SIMSBURY',
      'WEST SUFFIELD',
      'WINCHESTER CENTER',
      'WINDSOR LOCKS',
      'WINSTED',
      'HARTFORD',
      'W HARTFORD',
      'EAST HARTFORD',
      'WETHERSFIELD',
      'NEWINGTON',
      'WILLIMANTIC',
      'ABINGTON',
      'AMSTON',
      'ANDOVER',
      'BALLOUVILLE',
      'BROOKLYN',
      'CHAPLIN',
      'COLUMBIA',
      'COVENTRY',
      'DANIELSON',
      'DAYVILLE',
      'EASTFORD',
      'EAST KILLINGLY',
      'EAST WOODSTOCK',
      'FABYAN',
      'GROSVENOR DALE',
      'HAMPTON',
      'HEBRON',
      'LEBANON',
      'MANSFIELD CENTER',
      'MANSFIELD DEPOT',
      'NORTH FRANKLIN',
      'NORTH GROSVENORDALE',
      'NORTH WINDHAM',
      'POMFRET',
      'POMFRET CENTER',
      'PUTNAM',
      'QUINEBAUG',
      'ROGERS',
      'SCOTLAND',
      'SOUTH WILLINGTON',
      'SOUTH WINDHAM',
      'SOUTH WOODSTOCK',
      'STORRS MANSFIELD',
      'THOMPSON',
      'ASHFORD',
      'WILLINGTON',
      'WINDHAM',
      'WOODSTOCK',
      'WOODSTOCK VALLEY',
      'NEW LONDON',
      'BALTIC',
      'CANTERBURY',
      'CENTRAL VILLAGE',
      'EAST LYME',
      'BOZRAH',
      'GALES FERRY',
      'GILMAN',
      'GLASGO',
      'LEDYARD',
      'GROTON',
      'HANOVER',
      'JEWETT CITY',
      'MONTVILLE',
      'MOOSUP',
      'MYSTIC',
      'NIANTIC',
      'NORTH STONINGTON',
      'NORWICH',
      'PRESTON',
      'OALASKADALE',
      'OLD LYME',
      'OLD MYSTIC',
      'ONECO',
      'PLAINFIELD',
      'QUALASKAER HILL',
      'SOUTH LYME',
      'STERLING',
      'STONINGTON',
      'PAWCATUCK',
      'TAFTVILLE',
      'UNCASVILLE',
      'VERSAILLES',
      'VOLUNTOWN',
      'WATERFORD',
      'WAUREGAN',
      'WEST MYSTIC',
      'YANTIC',
      'ANSONIA',
      'BEACON FALLS',
      'BOTSFORD',
      'BRANFORD',
      'CHESHIRE',
      'CENTERBROOK',
      'CHESTER',
      'CLINTON',
      'COBALT',
      'COLCHESTER',
      'CROMWELL',
      'DEEP RIVER',
      'DERBY',
      'KILLINGWORTH',
      'SALEM',
      'DURHAM',
      'EAST HADDAM',
      'EAST HAMPTON',
      'ESSEX',
      'FAIRFIELD',
      'GREENS FARMS',
      'GUILFORD',
      'HADDAM',
      'HADLYME',
      'HAWLEYVILLE',
      'HIGGANUM',
      'IVORYTON',
      'MADISON',
      'MARION',
      'MARLBOROUGH',
      'MERIDEN',
      'MIDDLEFIELD',
      'MIDDLE HADDAM',
      'MIDDLETOWN',
      'MILFORD',
      'MILLDALE',
      'MONROE',
      'MOODUS',
      'NEWTOWN',
      'NORTH BRANFORD',
      'NORTHFORD',
      'NORTH HAVEN',
      'NORTH WESTCHESTER',
      'OLD SAYBROOK',
      'ORANGE',
      'OXFORD',
      'PLANTSVILLE',
      'PORTLAND',
      'ROCKFALL',
      'SANDY HOOK',
      'SEYMOUR',
      'SHELTON',
      'SOUTH BRITAIN',
      'SOUTHBURY',
      'SOUTHINGTON',
      'SOUTHPORT',
      'STEVENSON',
      'WALLINGFORD',
      'STRATFORD',
      'WESTBROOK',
      'NEW HAVEN',
      'EAST HAVEN',
      'HAMDEN',
      'WEST HAVEN',
      'BETHANY',
      'WOODBRIDGE',
      'BRIDGEPORT',
      'TRUMBULL',
      'EASTON',
      'WATERBURY',
      'PROSPECT',
      'WOLCOTT',
      'BANTAM',
      'BETHLEHEM',
      'BRIDGEWATER',
      'CORNWALL',
      'CORNWALL BRIDGE',
      'GAYLORDSVILLE',
      'GOSHEN',
      'KENT',
      'LALASKAESIDE',
      'LITCHFIELD',
      'MIDDLEBURY',
      'MORRIS',
      'NAUGATUCK',
      'NEW MILFORD',
      'NEW PRESTON MARBLE DALE',
      'NORTHFIELD',
      'OALASKAVILLE',
      'PEQUABUCK',
      'PLYMOUTH',
      'ROXBURY',
      'SHERMAN',
      'SOUTH KENT',
      'TERRYVILLE',
      'THOMASTON',
      'TORRINGTON',
      'HARWINTON',
      'WASHINGTON',
      'WASHINGTON DEPOT',
      'WATERTOWN',
      'WEST CORNWALL',
      'WOODBURY',
      'BETHEL',
      'BROOKFIELD',
      'COS COB',
      'DANBURY',
      'NEW FAIRFIELD',
      'DARIEN',
      'GEORGETOWN',
      'GREENWICH',
      'NEW CANAAN',
      'NORWALK',
      'OLD GREENWICH',
      'REDDING CENTER',
      'REDDING RIDGE',
      'RIDGEFIELD',
      'RIVERSIDE',
      'WESTPORT',
      'WESTON',
      'REDDING',
      'WILTON',
      'STAMFORD'
    ]
  },
  DE: {
    code: 'DE',
    name: 'DELAWARE',
    cities: [
      'BEAR',
      'NEWARK',
      'CLAYMONT',
      'DELAWARE CITY',
      'HOCKESSIN',
      'KIRKWOOD',
      'MIDDLETOWN',
      'MONTCHANIN',
      'NEW CASTLE',
      'ODESSA',
      'PORT PENN',
      'ROCKLAND',
      'SAINT GEORGES',
      'TOWNSEND',
      'WINTERTHUR',
      'YORKLYN',
      'WILMINGTON',
      'DOVER',
      'DOVER AFB',
      'BETHANY BEACH',
      'BETHEL',
      'BRIDGEVILLE',
      'CAMDEN WYOMING',
      'CHESWOLD',
      'CLAYTON',
      'DAGSBORO',
      'DELMAR',
      'ELLENDALE',
      'FELTON',
      'FENWICK ISLAND',
      'FRANKFORD',
      'FREDERICA',
      'GEORGETOWN',
      'GREENWOOD',
      'HARBESON',
      'HARRINGTON',
      'HARTLY',
      'HOUSTON',
      'KENTON',
      'LAUREL',
      'LEWES',
      'LINCOLN',
      'LITTLE CREEK',
      'MAGNOLIA',
      'MILFORD',
      'MARYDEL',
      'MILLSBORO',
      'MILLVILLE',
      'MILTON',
      'NASSAU',
      'OCEAN VIEW',
      'REHOBOTH BEACH',
      'SEAFORD',
      'SELBYVILLE',
      'SMYRNA',
      'VIOLA',
      'WOODSIDE'
    ]
  },
  FL: {
    code: 'FL',
    name: 'FLORIDA',
    cities: [
      'ORANGE PARK',
      'PONTE VEDRA BEACH',
      'BOSTWICK',
      'BRANFORD',
      'BRYCEVILLE',
      'CALLAHAN',
      'DAY',
      'LALASKAE CITY',
      'DOCTORS INLET',
      'ELKTON',
      'FERNANDINA BEACH',
      'FORT WHITE',
      'GLEN SAINT MARY',
      'YULEE',
      'GRAHAM',
      'GREEN COVE SPRINGS',
      'HAMPTON',
      'HILLIARD',
      'MIDDLEBURG',
      'JASPER',
      'JENNINGS',
      'LALASKAE BUTLER',
      'LAWTEY',
      'LEE',
      'LIVE OALASKA',
      'LULU',
      'MC ALPIN',
      'MACCLENNY',
      'MAYO',
      'O BRIEN',
      'OLUSTEE',
      'PENNEY FARMS',
      'SAINT AUGUSTINE',
      'RAIFORD',
      'SANDERSON',
      'STARKE',
      'WELLBORN',
      'WHITE SPRINGS',
      'JACKSONVILLE',
      'ASTOR',
      'BARBERVILLE',
      'BUNNELL',
      'CANDLER',
      'CRESCENT CITY',
      'CITRA',
      'DAYTONA BEACH',
      'PORT ORANGE',
      'DE LEON SPRINGS',
      'EAST PALATKA',
      'EDGEWATER',
      'EASTLALASKAE WEIR',
      'FORT MC COY',
      'PALM COAST',
      'FLAGLER BEACH',
      'GRANDIN',
      'GEORGETOWN',
      'FLORAHOME',
      'HASTINGS',
      'HOLLISTER',
      'INTERLACHEN',
      'LALASKAE COMO',
      'LADY LALASKAE',
      'LALASKAE GENEVA',
      'NEW SMYRNA BEACH',
      'ORMOND BEACH',
      'PALATKA',
      'OCKLAWAHA',
      'PIERSON',
      'POMONA PARK',
      'ORANGE SPRINGS',
      'PUTNAM HALL',
      'SAN MATEO',
      'SATSUMA',
      'SEVILLE',
      'SPARR',
      'WELALASKAA',
      'WEIRSDALE',
      'ATLANTIC BEACH',
      'JACKSONVILLE BEACH',
      'NEPTUNE BEACH',
      'TALLAHASSEE',
      'APALACHICOLA',
      'BRISTOL',
      'CARRABELLE',
      'LANARK VILLAGE',
      'CHATTAHOOCHEE',
      'CRAWFORDVILLE',
      'EASTPOINT',
      'GREENSBORO',
      'GREENVILLE',
      'GRETNA',
      'HAVANA',
      'HOSFORD',
      'SUMATRA',
      'LAMONT',
      'LLOYD',
      'MADISON',
      'MIDWAY',
      'MONTICELLO',
      'PANACEA',
      'PERRY',
      'PINETTA',
      'QUINCY',
      'SAINT MARKS',
      'SALEM',
      'SHADY GROVE',
      'SOPCHOPPY',
      'STEINHATCHEE',
      'TELOGIA',
      'WACISSA',
      'WOODVILLE',
      'PANAMA CITY',
      'PANAMA CITY BEACH',
      'MEXICO BEACH',
      'ALFORD',
      'ALTHA',
      'ARGYLE',
      'BASCOM',
      'BLOUNTSTOWN',
      'BONIFAY',
      'CAMPBELLTON',
      'CARYVILLE',
      'CHIPLEY',
      'CLARKSVILLE',
      'COTTONDALE',
      'CYPRESS',
      'DEFUNIALASKA SPRINGS',
      'MOSSY HEAD',
      'EBRO',
      'FOUNTAIN',
      'FREEPORT',
      'GRACEVILLE',
      'GRAND RIDGE',
      'GREENWOOD',
      'LYNN HAVEN',
      'MALONE',
      'MARIANNA',
      'KINARD',
      'NOMA',
      'POINT WASHINGTON',
      'PONCE DE LEON',
      'PORT SAINT JOE',
      'SANTA ROSA BEACH',
      'SNEADS',
      'ROSEMARY BCH',
      'VERNON',
      'WAUSAU',
      'WESTVILLE',
      'WEWAHITCHKA',
      'YOUNGSTOWN',
      'PENSACOLA',
      'BAGDAD',
      'BALASKAER',
      'CANTONMENT',
      'CENTURY',
      'CRESTVIEW',
      'MILLIGAN',
      'PAXTON',
      'DESTIN',
      'EGLIN AFB',
      'HURLBURT FIELD',
      'FORT WALTON BEACH',
      'GONZALEZ',
      'GULF BREEZE',
      'HOLT',
      'JAY',
      'NAVARRE',
      'LAUREL HILL',
      'MC DAVID',
      'MARY ESTHER',
      'MILTON',
      'MOLINO',
      'NICEVILLE',
      'SHALIMAR',
      'VALPARAISO',
      'GAINESVILLE',
      'ALACHUA',
      'ANTHONY',
      'ARCHER',
      'BELL',
      'BRONSON',
      'BROOKER',
      'CEDAR KEY',
      'CHIEFLAND',
      'CROSS CITY',
      'EARLETON',
      'EVINSTON',
      'FAIRFIELD',
      'GULF HAMMOCK',
      'HAWTHORNE',
      'HIGH SPRINGS',
      'HORSESHOE BEACH',
      'ISLAND GROVE',
      'KEYSTONE HEIGHTS',
      'LA CROSSE',
      'LOCHLOOSA',
      'LOWELL',
      'MC INTOSH',
      'MELROSE',
      'MICANOPY',
      'MORRISTON',
      'NEWBERRY',
      'OLD TOWN',
      'ORANGE LALASKAE',
      'OTTER CREEK',
      'REDDICK',
      'SUWANNEE',
      'TRENTON',
      'WALDO',
      'WILLISTON',
      'WORTHINGTON SPRINGS',
      'ALTAMONTE SPRINGS',
      'ALTOONA',
      'APOPKA',
      'CASSADAGA',
      'CASSELBERRY',
      'WINTER SPRINGS',
      'CHRISTMAS',
      'CLARCONA',
      'DEBARY',
      'DELAND',
      'GLENWOOD',
      'DELTONA',
      'EUSTIS',
      'GENEVA',
      'GOLDENROD',
      'GRAND ISLAND',
      'LALASKAE HELEN',
      'LALASKAE MARY',
      'LALASKAE MONROE',
      'LONGWOOD',
      'MAITLAND',
      'MIMS',
      'MOUNT DORA',
      'OALASKA HILL',
      'OVIEDO',
      'ORANGE CITY',
      'OSTEEN',
      'PAISLEY',
      'PLYMOUTH',
      'SANFORD',
      'SCOTTSMOOR',
      'SORRENTO',
      'TANGERINE',
      'TAVARES',
      'TITUSVILLE',
      'UMATILLA',
      'WINTER PARK',
      'ZELLWOOD',
      'MID FLORIDA',
      'ORLANDO',
      'MELBOURNE',
      'INDIALANTIC',
      'PALM BAY',
      'CAPE CANAVERAL',
      'COCOA',
      'PATRICK AFB',
      'COCOA BEACH',
      'SATELLITE BEACH',
      'FELLSMERE',
      'GRANT',
      'MALABAR',
      'MELBOURNE BEACH',
      'MERRITT ISLAND',
      'ROCKLEDGE',
      'ROSELAND',
      'SEBASTIAN',
      'SHARPES',
      'VERO BEACH',
      'WABASSO',
      'WINTER BEACH',
      'LONG KEY',
      'HIALEAH',
      'DANIA',
      'HALLANDALE',
      'HOLLYWOOD',
      'HOMESTEAD',
      'ISLAMORADA',
      'KEY LARGO',
      'KEY WEST',
      'SUMMERLAND KEY',
      'BIG PINE KEY',
      'SUGARLOAF SHORES',
      'MARATHON',
      'KEY COLONY BEACH',
      'MARATHON SHORES',
      'OPA LOCKA',
      'POMPANO BEACH',
      'TAVERNIER',
      'PEMBROKE PINES',
      'MIAMI',
      'MIAMI BEACH',
      'KEY BISCAYNE',
      'NORTH MIAMI BEACH',
      'FORT LAUDERDALE',
      'WEST PALM BEACH',
      'NORTH PALM BEACH',
      'BOYNTON BEACH',
      'BOCA RATON',
      'BELLE GLADE',
      'CANAL POINT',
      'BRYANT',
      'CLEWISTON',
      'DEERFIELD BEACH',
      'DELRAY BEACH',
      'LALASKAE WORTH',
      'HOBE SOUND',
      'JUPITER',
      'LALASKAE HARBOR',
      'LOXAHATCHEE',
      'MOORE HAVEN',
      'PAHOKEE',
      'PALM BEACH',
      'SOUTH BAY',
      'BALM',
      'BRANDON',
      'BUSHNELL',
      'CENTER HILL',
      'COLEMAN',
      'DADE CITY',
      'CRYSTAL SPRINGS',
      'DOVER',
      'DURANT',
      'GIBSONTON',
      'LACOOCHEE',
      'LALASKAE PANASOFFKEE',
      'ZEPHYRHILLS',
      'LITHIA',
      'LUTZ',
      'MANGO',
      'ODESSA',
      'PLANT CITY',
      'RIVERVIEW',
      'RUSKIN',
      'SUN CITY CENTER',
      'APOLLO BEACH',
      'SAINT LEO',
      'SAN ANTONIO',
      'SEFFNER',
      'SUMTERVILLE',
      'SUN CITY',
      'SYDNEY',
      'THONOTOSASSA',
      'TRILBY',
      'VALRICO',
      'WEBSTER',
      'WIMAUMA',
      'TAMPA',
      'SAINT PETERSBURG',
      'BAY PINES',
      'CLEARWATER',
      'CLEARWATER BEACH',
      'LARGO',
      'SEMINOLE',
      'PINELLAS PARK',
      'INDIAN ROCKS BEACH',
      'BELLEAIR BEACH',
      'LALASKAELAND',
      'ALTURAS',
      'AUBURNDALE',
      'AVON PARK',
      'BABSON PARK',
      'BARTOW',
      'BOWLING GREEN',
      'BRADLEY',
      'DAVENPORT',
      'DUNDEE',
      'EAGLE LALASKAE',
      'EATON PARK',
      'FORT MEADE',
      'FROSTPROOF',
      'HAINES CITY',
      'HIGHLAND CITY',
      'HOMELAND',
      'INTERCESSION CITY',
      'KATHLEEN',
      'LALASKAE ALFRED',
      'LALASKAE HAMILTON',
      'LALASKAE PLACID',
      'LALASKAE WALES',
      'FEDHAVEN',
      'INDIAN LALASKAE ESTATES',
      'NALCREST',
      'LORIDA',
      'LOUGHMAN',
      'MULBERRY',
      'NICHOLS',
      'ONA',
      'RIVER RANCH',
      'POLK CITY',
      'SEBRING',
      'WAUCHULA',
      'WAVERLY',
      'WINTER HAVEN',
      'ZOLFO SPRINGS',
      'FORT MYERS',
      'NORTH FORT MYERS',
      'CAPE CORAL',
      'ALVA',
      'BOCA GRANDE',
      'BOKEELIA',
      'CAPTIVA',
      'EL JOBEAN',
      'ESTERO',
      'FELDA',
      'FORT MYERS BEACH',
      'LABELLE',
      'LEHIGH ACRES',
      'MURDOCK',
      'PALMDALE',
      'PINELAND',
      'PLACIDA',
      'ROTONDA WEST',
      'PORT CHARLOTTE',
      'PUNTA GORDA',
      'SAINT JAMES CITY',
      'SANIBEL',
      'VENUS',
      'NAPLES',
      'VANDERBILT BEACH',
      'BONITA SPRINGS',
      'COPELAND',
      'CHOKOLOSKEE',
      'EVERGLADES CITY',
      'GOODLAND',
      'OCHOPEE',
      'IMMOKALEE',
      'MARCO ISLAND',
      'BRADENTON',
      'CORTEZ',
      'ANNA MARIA',
      'BRADENTON BEACH',
      'HOLMES BEACH',
      'PARRISH',
      'PALMETTO',
      'ELLENTON',
      'ENGLEWOOD',
      'LONGBOAT KEY',
      'OSPREY',
      'SARASOTA',
      'TERRA CEIA',
      'MYALASKAKA CITY',
      'MANASOTA',
      'ONECO',
      'ARCADIA',
      'FORT OGDEN',
      'NOCATEE',
      'TALLEVAST',
      'LAUREL',
      'NOKOMIS',
      'VENICE',
      'NORTH PORT',
      'BELLEVIEW',
      'CRYSTAL RIVER',
      'DUNNELLON',
      'FLORAL CITY',
      'HERNANDO',
      'HOLDER',
      'HOMOSASSA',
      'HOMOSASSA SPRINGS',
      'INGLIS',
      'INVERNESS',
      'LECANTO',
      'BEVERLY HILLS',
      'OCALA',
      'OXFORD',
      'SILVER SPRINGS',
      'SUMMERFIELD',
      'YANKEETOWN',
      'BROOKSVILLE',
      'SPRING HILL',
      'ISTACHATTA',
      'LAND O LALASKAES',
      'NEW PORT RICHEY',
      'OZONA',
      'NOBLETON',
      'HUDSON',
      'PORT RICHEY',
      'OLDSMAR',
      'ARIPEKA',
      'ELFERS',
      'CRYSTAL BEACH',
      'PALM HARBOR',
      'TARPON SPRINGS',
      'HOLIDAY',
      'SAFETY HARBOR',
      'DUNEDIN',
      'ASTATULA',
      'CLERMONT',
      'FERNDALE',
      'FRUITLAND PARK',
      'GOTHA',
      'GROVELAND',
      'HOWEY IN THE HILLS',
      'KENANSVILLE',
      'KILLARNEY',
      'KISSIMMEE',
      'LEESBURG',
      'MASCOTTE',
      'MINNEOLA',
      'MONTVERDE',
      'OALASKALAND',
      'OCOEE',
      'OKAHUMPKA',
      'SAINT CLOUD',
      'WINTER GARDEN',
      'WILDWOOD',
      'WINDERMERE',
      'YALAHA',
      'FORT PIERCE',
      'PORT SAINT LUCIE',
      'INDIANTOWN',
      'JENSEN BEACH',
      'OKEECHOBEE',
      'PALM CITY',
      'PORT SALERNO',
      'STUART'
    ]
  },
  GA: {
    code: 'GA',
    name: 'GEORGIA',
    cities: [
      'AVONDALE ESTATES',
      'NORCROSS',
      'ALPHARETTA',
      'MARIETTA',
      'AUBURN',
      'CONYERS',
      'COVINGTON',
      'GRAYSON',
      'JERSEY',
      'DACULA',
      'CLARKSTON',
      'SUWANEE',
      'SOCIAL CIRCLE',
      'NORTH METRO',
      'CUMMING',
      'DECATUR',
      'LITHONIA',
      'SNELLVILLE',
      'LAWRENCEVILLE',
      'LILBURN',
      'LOGANVILLE',
      'OXFORD',
      'MANSFIELD',
      'NEWBORN',
      'PORTERDALE',
      'PINE LALASKAE',
      'REDAN',
      'ROSWELL',
      'SCOTTDALE',
      'SMYRNA',
      'STONE MOUNTAIN',
      'TUCKER',
      'DULUTH',
      'ACWORTH',
      'ADAIRSVILLE',
      'ARAGON',
      'ARMUCHEE',
      'AUSTELL',
      'BALL GROUND',
      'BOWDON',
      'BOWDON JUNCTION',
      'BREMEN',
      'CLARKDALE',
      'BUCHANAN',
      'CANTON',
      'CARROLLTON',
      'CARTERSVILLE',
      'LITHIA SPRINGS',
      'CASSVILLE',
      'CAVE SPRING',
      'CEDARTOWN',
      'MABLETON',
      'POWDER SPRINGS',
      'COOSA',
      'DALLAS',
      'DOUGLASVILLE',
      'EMERSON',
      'ESOM HILL',
      'FAIRMOUNT',
      'FELTON',
      'HIRAM',
      'HOLLY SPRINGS',
      'JASPER',
      'KENNESAW',
      'KINGSTON',
      'LEBANON',
      'LINDALE',
      'MARBLE HILL',
      'MOUNT BERRY',
      'MOUNT ZION',
      'NELSON',
      'ROCKMART',
      'ROME',
      'ROOPVILLE',
      'RYDAL',
      'SHANNON',
      'SILVER CREEK',
      'TALKING ROCK',
      'TALLAPOOSA',
      'TATE',
      'TAYLORSVILLE',
      'TEMPLE',
      'VILLA RICA',
      'WACO',
      'WALESKA',
      'WHITE',
      'WHITESBURG',
      'WINSTON',
      'WOODSTOCK',
      'BARNESVILLE',
      'BROOKS',
      'CONCORD',
      'EXPERIMENT',
      'FAIRBURN',
      'FAYETTEVILLE',
      'FLOVILLA',
      'FRANKLIN',
      'GAY',
      'GLENN',
      'GRANTVILLE',
      'GREENVILLE',
      'GRIFFIN',
      'HAMPTON',
      'HARALSON',
      'HOGANSVILLE',
      'INMAN',
      'JACKSON',
      'JENKINSBURG',
      'JONESBORO',
      'LAGRANGE',
      'LOCUST GROVE',
      'LOVEJOY',
      'LUTHERSVILLE',
      'MCDONOUGH',
      'MEANSVILLE',
      'MILNER',
      'MOLENA',
      'MORELAND',
      'MORROW',
      'NEWNAN',
      'ORCHARD HILL',
      'PALMETTO',
      'PEACHTREE CITY',
      'RED OALASKA',
      'REX',
      'RIVERDALE',
      'SARGENT',
      'SENOIA',
      'SHARPSBURG',
      'STOCKBRIDGE',
      'SUNNY SIDE',
      'THE ROCK',
      'THOMASTON',
      'CONLEY',
      'TURIN',
      'TYRONE',
      'UNION CITY',
      'WILLIAMSON',
      'WOODBURY',
      'ELLENWOOD',
      'ZEBULON',
      'FOREST PARK',
      'ATLANTA',
      'SWAINSBORO',
      'AILEY',
      'ALAMO',
      'ALSTON',
      'BARTOW',
      'BELLVILLE',
      'BROOKLET',
      'CLAXTON',
      'COBBTOWN',
      'COLLINS',
      'DAISY',
      'DOVER',
      'GARFIELD',
      'GIRARD',
      'GLENNVILLE',
      'GLENWOOD',
      'HAGAN',
      'LOUISVILLE',
      'LYONS',
      'MANASSAS',
      'METTER',
      'MIDVILLE',
      'MILLEN',
      'MOUNT VERNON',
      'NEWINGTON',
      'NORRISTOWN',
      'NUNEZ',
      'OLIVER',
      'PORTAL',
      'PULASKI',
      'REGISTER',
      'REIDSVILLE',
      'ROCKLEDGE',
      'ROCKY FORD',
      'SARDIS',
      'SOPERTON',
      'STATESBORO',
      'STILLMORE',
      'SYLVANIA',
      'TARRYTOWN',
      'TWIN CITY',
      'UVALDA',
      'VIDALIA',
      'WADLEY',
      'GAINESVILLE',
      'CHESTNUT MOUNTAIN',
      'ALTO',
      'BALDWIN',
      'BLAIRSVILLE',
      'BLUE RIDGE',
      'BUFORD',
      'BOWERSVILLE',
      'BRASELTON',
      'CANON',
      'CARNESVILLE',
      'CHERRYLOG',
      'CLARKESVILLE',
      'CLAYTON',
      'CLERMONT',
      'CLEVELAND',
      'COMMERCE',
      'CORNELIA',
      'DAHLONEGA',
      'DAWSONVILLE',
      'DEMOREST',
      'DILLARD',
      'EASTANOLLEE',
      'EAST ELLIJAY',
      'ELLIJAY',
      'EPWORTH',
      'FLOWERY BRANCH',
      'GILLSVILLE',
      'HELEN',
      'HIAWASSEE',
      'HOMER',
      'HOSCHTON',
      'JEFFERSON',
      'LALASKAEMONT',
      'LAVONIA',
      'LULA',
      'MC CAYSVILLE',
      'MARTIN',
      'MAYSVILLE',
      'MINERAL BLUFF',
      'MORGANTON',
      'MOUNTAIN CITY',
      'MOUNT AIRY',
      'MURRAYVILLE',
      'NICHOLSON',
      'OALASKAWOOD',
      'PENDERGRASS',
      'RABUN GAP',
      'SAUTEE NACOOCHEE',
      'SUCHES',
      'TALLULAH FALLS',
      'TALMO',
      'TIGER',
      'TOCCOA',
      'TURNERVILLE',
      'WILEY',
      'YOUNG HARRIS',
      'TOCCOA FALLS',
      'ATHENS',
      'ARNOLDSVILLE',
      'BETHLEHEM',
      'BISHOP',
      'BOGART',
      'BOSTWICK',
      'BOWMAN',
      'BUCKHEAD',
      'CARLTON',
      'COLBERT',
      'COMER',
      'CRAWFORD',
      'CRAWFORDVILLE',
      'DANIELSVILLE',
      'DEWY ROSE',
      'ELBERTON',
      'FARMINGTON',
      'FRANKLIN SPRINGS',
      'GOOD HOPE',
      'GREENSBORO',
      'HARTWELL',
      'HIGH SHOALS',
      'HULL',
      'ILA',
      'LEXINGTON',
      'MADISON',
      'MONROE',
      'RAYLE',
      'ROYSTON',
      'RUTLEDGE',
      'SHARON',
      'SILOAM',
      'STATHAM',
      'STEPHENS',
      'TIGNALL',
      'UNION POINT',
      'MAXEYS',
      'WASHINGTON',
      'WATKINSVILLE',
      'WHITE PLAINS',
      'WINDER',
      'WINTERVILLE',
      'CALHOUN',
      'CHATSWORTH',
      'CHICKAMAUGA',
      'CISCO',
      'COHUTTA',
      'CRANDALL',
      'DALTON',
      'ETON',
      'FLINTSTONE',
      'GRAYSVILLE',
      'LA FAYETTE',
      'LYERLY',
      'MENLO',
      'OALASKAMAN',
      'PLAINVILLE',
      'RANGER',
      'RESACA',
      'RINGGOLD',
      'RISING FAWN',
      'ROCK SPRING',
      'ROCKY FACE',
      'ROSSVILLE',
      'FORT OGLETHORPE',
      'SUGAR VALLEY',
      'SUMMERVILLE',
      'LOOKOUT MOUNTAIN',
      'TENNGA',
      'TRENTON',
      'TRION',
      'TUNNEL HILL',
      'VARNELL',
      'WILDWOOD',
      'APPLING',
      'AVERA',
      'BLYTHE',
      'BONEVILLE',
      'CAMALASKA',
      'DEARING',
      'EVANS',
      'GIBSON',
      'GOUGH',
      'GRACEWOOD',
      'GROVETOWN',
      'HARLEM',
      'HEPHZIBAH',
      'KEYSVILLE',
      'LINCOLNTON',
      'MATTHEWS',
      'MESENA',
      'MITCHELL',
      'NORWOOD',
      'PERKINS',
      'STAPLETON',
      'THOMSON',
      'WARRENTON',
      'WAYNESBORO',
      'WRENS',
      'AUGUSTA',
      'ABBEVILLE',
      'ADRIAN',
      'ALLENTOWN',
      'BOLINGBROKE',
      'BONAIRE',
      'BUTLER',
      'BYROMVILLE',
      'BYRON',
      'CADWELL',
      'CORDELE',
      'CHAUNCEY',
      'CHESTER',
      'CLINCHFIELD',
      'COCHRAN',
      'CULLODEN',
      'DANVILLE',
      'DAVISBORO',
      'DEXTER',
      'DRY BRANCH',
      'DUBLIN',
      'DUDLEY',
      'EASTMAN',
      'EATONTON',
      'ELKO',
      'CENTERVILLE',
      'FORSYTH',
      'FORT VALLEY',
      'GORDON',
      'GRAY',
      'HADDOCK',
      'HARDWICK',
      'HARRISON',
      'HAWKINSVILLE',
      'HELENA',
      'HILLSBORO',
      'HOWARD',
      'IDEAL',
      'IRWINTON',
      'JEFFERSONVILLE',
      'JEWELL',
      'JULIETTE',
      'KATHLEEN',
      'KITE',
      'KNOXVILLE',
      'LILLY',
      'LIZELLA',
      'MC INTYRE',
      'MC RAE',
      'MARSHALLVILLE',
      'MAUK',
      'MILLEDGEVILLE',
      'MILAN',
      'MONTEZUMA',
      'MONTICELLO',
      'MONTROSE',
      'MUSELLA',
      'OCONEE',
      'OGLETHORPE',
      'PERRY',
      'PINEHURST',
      'PINEVIEW',
      'PITTS',
      'PLAINFIELD',
      'RENTZ',
      'REYNOLDS',
      'RHINE',
      'ROBERTA',
      'ROCHELLE',
      'RUPERT',
      'SANDERSVILLE',
      'SCOTLAND',
      'SEVILLE',
      'SHADY DALE',
      'SMARR',
      'SPARTA',
      'WARNER ROBINS',
      'TENNILLE',
      'TOOMSBORO',
      'UNADILLA',
      'VIENNA',
      'WARTHEN',
      'WRIGHTSVILLE',
      'YATESVILLE',
      'MACON',
      'ALLENHURST',
      'BLOOMINGDALE',
      'CLYO',
      'CRESCENT',
      'DARIEN',
      'EDEN',
      'ELLABELL',
      'FLEMING',
      'HINESVILLE',
      'GUYTON',
      'FORT STEWART',
      'LUDOWICI',
      'MELDRIM',
      'MERIDIAN',
      'MIDWAY',
      'PEMBROKE',
      'POOLER',
      'RICEBORO',
      'RICHMOND HILL',
      'RINCON',
      'SAPELO ISLAND',
      'TYBEE ISLAND',
      'SPRINGFIELD',
      'TOWNSEND',
      'WALTHOURVILLE',
      'SAVANNAH',
      'WAYCROSS',
      'ALMA',
      'AMBROSE',
      'BAXLEY',
      'BLACKSHEAR',
      'BRISTOL',
      'BROXTON',
      'BRUNSWICK',
      'SAINT SIMONS ISLAND',
      'JEKYLL ISLAND',
      'DENTON',
      'DOUGLAS',
      'FOLKSTON',
      'HAZLEHURST',
      'HOBOKEN',
      'HORTENSE',
      'JACKSONVILLE',
      'JESUP',
      'KINGS BAY',
      'KINGSLAND',
      'LUMBER CITY',
      'MANOR',
      'MERSHON',
      'MILLWOOD',
      'NAHUNTA',
      'NICHOLLS',
      'ODUM',
      'OFFERMAN',
      'PATTERSON',
      'SAINT MARYS',
      'SCREVEN',
      'SEA ISLAND',
      'SURRENCY',
      'WARESBORO',
      'WAVERLY',
      'WAYNESVILLE',
      'WEST GREEN',
      'WHITE OALASKA',
      'WOODBINE',
      'VALDOSTA',
      'ADEL',
      'ALAPAHA',
      'ARGYLE',
      'AXSON',
      'BARNEY',
      'BOSTON',
      'CECIL',
      'DIXIE',
      'DU PONT',
      'FARGO',
      'HAHIRA',
      'HOMERVILLE',
      'LALASKAELAND',
      'LALASKAE PARK',
      'LENOX',
      'MORVEN',
      'NASHVILLE',
      'NAYLOR',
      'PEARSON',
      'QUITMAN',
      'RAY CITY',
      'SAINT GEORGE',
      'SPARKS',
      'STATENVILLE',
      'STOCKTON',
      'WILLACOOCHEE',
      'ALBANY',
      'AMERICUS',
      'ANDERSONVILLE',
      'ARABI',
      'ARLINGTON',
      'ASHBURN',
      'ATTAPULGUS',
      'BACONTON',
      'BAINBRIDGE',
      'BARWICK',
      'BERLIN',
      'BLALASKAELY',
      'BLUFFTON',
      'BRINSON',
      'BRONWOOD',
      'BROOKFIELD',
      'CAIRO',
      'CALVARY',
      'CAMILLA',
      'CEDAR SPRINGS',
      'CHULA',
      'CLIMAX',
      'COBB',
      'COLEMAN',
      'COLQUITT',
      'COOLIDGE',
      'COTTON',
      'CUTHBERT',
      'DAMASCUS',
      'DAWSON',
      'DE SOTO',
      'DOERUN',
      'DONALSONVILLE',
      'EDISON',
      'ELLENTON',
      'ENIGMA',
      'FITZGERALD',
      'FORT GAINES',
      'FOWLSTOWN',
      'FUNSTON',
      'GEORGETOWN',
      'HARTSFIELD',
      'THOMASVILLE',
      'IRON CITY',
      'IRWINVILLE',
      'JALASKAIN',
      'LEARY',
      'LEESBURG',
      'LESLIE',
      'MEIGS',
      'MORGAN',
      'MORRIS',
      'MOULTRIE',
      'MYSTIC',
      'NEWTON',
      'NORMAN PARK',
      'OALASKAFIELD',
      'OCHLOCKNEE',
      'OCILLA',
      'OMEGA',
      'PARROTT',
      'PAVO',
      'PELHAM',
      'PLAINS',
      'POULAN',
      'PUTNEY',
      'REBECCA',
      'SALE CITY',
      'SASSER',
      'SHELLMAN',
      'SMITHVILLE',
      'SUMNER',
      'SYCAMORE',
      'SYLVESTER',
      'TIFTON',
      'TY TY',
      'WARWICK',
      'WHIGHAM',
      'WRAY',
      'BOX SPRINGS',
      'BUENA VISTA',
      'CATAULA',
      'CUSSETA',
      'ELLAVILLE',
      'ELLERSLIE',
      'FORTSON',
      'GENEVA',
      'HAMILTON',
      'JUNCTION CITY',
      'LOUVALE',
      'LUMPKIN',
      'MANCHESTER',
      'MIDLAND',
      'OMAHA',
      'PINE MOUNTAIN',
      'PINE MOUNTAIN VALLEY',
      'PRESTON',
      'RICHLAND',
      'SHILOH',
      'TALBOTTON',
      'UPATOI',
      'WARM SPRINGS',
      'WAVERLY HALL',
      'WESTON',
      'WEST POINT',
      'WOODLAND',
      'COLUMBUS',
      'FORT BENNING'
    ]
  },
  HI: {
    code: 'HI',
    name: 'HAWAII',
    cities: [
      'AIEA',
      'ANAHOLA',
      'CAPTAIN COOK',
      'ELEELE',
      'EWA BEACH',
      'KAPOLEI',
      'HAIKU',
      'HALASKAALAU',
      'HALEIWA',
      'HANA',
      'HANALEI',
      'HANAMAULU',
      'HANAPEPE',
      'HAUULA',
      'HAWAII NATIONAL PARK',
      'HAWI',
      'HILO',
      'PRINCEVILLE',
      'HOLUALOA',
      'HONAUNAU',
      'HONOKAA',
      'HONOMU',
      'HOOLEHUA',
      'KAAAWA',
      'KAHUKU',
      'KAHULUI',
      'KAILUA',
      'OCEAN VIEW',
      'WAIKOLOA',
      'KEAUHOU',
      'KAILUA KONA',
      'KALAHEO',
      'KALAUPAPA',
      'KAMUELA',
      'KANEOHE',
      'KAPAA',
      'KAUMALASKAANI',
      'KAUNALASKAALASKAAI',
      'KEAAU',
      'KEALALASKAEKUA',
      'KEALIA',
      'KEKAHA',
      'KIHEI',
      'KILAUEA',
      'KAPAAU',
      'KOLOA',
      'KUALAPUU',
      'KUNIA',
      'KURTISTOWN',
      'LAHAINA',
      'LAIE',
      'LANAI CITY',
      'LAUPAHOEHOE',
      'LAWAI',
      'LIHUE',
      'MALASKAAWAO',
      'MALASKAAWELI',
      'MAUNALOA',
      'MOUNTAIN VIEW',
      'NAALEHU',
      'NINOLE',
      'OOKALA',
      'PAAUHAU',
      'PAAUILO',
      'PAHALA',
      'PAHOA',
      'PAIA',
      'PAPAALOA',
      'PAPAIKOU',
      'PEARL CITY',
      'PEPEEKEO',
      'PUUNENE',
      'VOLCANO',
      'WAHIAWA',
      'PUKALANI',
      'MILILANI',
      'KULA',
      'WAIALUA',
      'WAIANAE',
      'WAILUKU',
      'WAIMANALO',
      'WAIMEA',
      'WAIPAHU',
      'HONOLULU',
      'HICKAM AFB',
      'WHEELER ARMY AIRFIELD',
      'SCHOFIELD BARRACKS',
      'FORT SHAFTER',
      'TRIPLER ARMY MEDICAL CTR',
      'PEARL HARBOR',
      'CAMP H M SMITH',
      'BARBERS POINT',
      'M C B H KANEOHE BAY',
      'WALASKAE ISLAND'
    ]
  },
  IA: {
    code: 'IA',
    name: 'IOWA',
    cities: [
      'ACKWORTH',
      'ADAIR',
      'ADEL',
      'ALBION',
      'ALDEN',
      'ALLEMAN',
      'ALLERTON',
      'ALTOONA',
      'AMES',
      'ANKENY',
      'ANITA',
      'ATLANTIC',
      'AUDUBON',
      'BAGLEY',
      'BARNES CITY',
      'BAXTER',
      'BAYARD',
      'BEAVER',
      'BERWICK',
      'BEVINGTON',
      'BLAIRSBURG',
      'BONDURANT',
      'BOONE',
      'BOONEVILLE',
      'BOUTON',
      'BOXHOLM',
      'BRADFORD',
      'BRAYTON',
      'BUCKEYE',
      'BUSSEY',
      'CAMBRIDGE',
      'CARLISLE',
      'CASEY',
      'CHARITON',
      'CHURDAN',
      'CLEMONS',
      'CLIO',
      'COLFAX',
      'COLLINS',
      'COLO',
      'COLUMBIA',
      'COON RAPIDS',
      'COOPER',
      'CORYDON',
      'CUMMING',
      'DALLAS',
      'DALLAS CENTER',
      'DANA',
      'DAVIS CITY',
      'DAWSON',
      'DECATUR',
      'DERBY',
      'DE SOTO',
      'DEXTER',
      'DOWS',
      'EARLHAM',
      'ELKHART',
      'ELLSTON',
      'ELLSWORTH',
      'EXIRA',
      'FERGUSON',
      'GALT',
      'GARDEN CITY',
      'GARDEN GROVE',
      'GIBSON',
      'GILBERT',
      'GILMAN',
      'GRAND JUNCTION',
      'GRAND RIVER',
      'GRANGER',
      'GRAY',
      'GRIMES',
      'GRINNELL',
      'GUTHRIE CENTER',
      'HAMILTON',
      'HAMLIN',
      'HARTFORD',
      'HARVEY',
      'HAVERHILL',
      'HUBBARD',
      'HUMESTON',
      'HUXLEY',
      'INDIANOLA',
      'IOWA FALLS',
      'IRA',
      'JAMAICA',
      'JEFFERSON',
      'JEWELL',
      'JOHNSTON',
      'KAMRAR',
      'KELLERTON',
      'KELLEY',
      'KELLOGG',
      'KESWICK',
      'KILLDUFF',
      'KNOXVILLE',
      'LACONA',
      'LAMONI',
      'LAUREL',
      'LE GRAND',
      'LEIGHTON',
      'LEON',
      'LIBERTY CENTER',
      'LINDEN',
      'LINEVILLE',
      'LISCOMB',
      'LORIMOR',
      'LOVILIA',
      'LUCAS',
      'LUTHER',
      'LYNNVILLE',
      'MC CALLSBURG',
      'MACKSBURG',
      'MADRID',
      'MALCOM',
      'MARSHALLTOWN',
      'MARTENSDALE',
      'MAXWELL',
      'MELBOURNE',
      'MELCHER',
      'MENLO',
      'MILLERTON',
      'MILO',
      'MINBURN',
      'MINGO',
      'MITCHELLVILLE',
      'MONROE',
      'MONTEZUMA',
      'MONTOUR',
      'MURRAY',
      'NEVADA',
      'NEW PROVIDENCE',
      'NEW SHARON',
      'NEWTON',
      'NEW VIRGINIA',
      'NORWALK',
      'OGDEN',
      'OSCEOLA',
      'OTLEY',
      'PANORA',
      'PATON',
      'PATTERSON',
      'PELLA',
      'PERRY',
      'PERU',
      'PILOT MOUND',
      'PLEASANTVILLE',
      'POLK CITY',
      'POPEJOY',
      'PRAIRIE CITY',
      'PROLE',
      'RADCLIFFE',
      'RANDALL',
      'REASNOR',
      'REDFIELD',
      'RHODES',
      'RIPPEY',
      'ROLAND',
      'RUNNELLS',
      'RUSSELL',
      'SAINT ANTHONY',
      'SAINT CHARLES',
      'SAINT MARYS',
      'SEARSBORO',
      'SHELDAHL',
      'SLATER',
      'STANHOPE',
      'STATE CENTER',
      'STORY CITY',
      'STRATFORD',
      'STUART',
      'SULLY',
      'SWAN',
      'THAYER',
      'THORNBURG',
      'TRACY',
      'TRURO',
      'UNION',
      'GIFFORD',
      'VAN METER',
      'VAN WERT',
      'WAUKEE',
      'WELDON',
      'WEST DES MOINES',
      'WHAT CHEER',
      'WHITTEN',
      'WILLIAMS',
      'WILLIAMSON',
      'WINTERSET',
      'WIOTA',
      'WOODBURN',
      'WOODWARD',
      'YALE',
      'ZEARING',
      'DES MOINES',
      'URBANDALE',
      'CLIVE',
      'MASON CITY',
      'ALEXANDER',
      'BELMOND',
      'BRITT',
      'BUFFALO CENTER',
      'CARPENTER',
      'CHAPIN',
      'CLEAR LALASKAE',
      'CORWITH',
      'COULTER',
      'CRYSTAL LALASKAE',
      'DOUGHERTY',
      'FERTILE',
      'FLOYD',
      'FOREST CITY',
      'GARNER',
      'GOODELL',
      'GRAFTON',
      'HAMPTON',
      'HANLONTOWN',
      'JOICE',
      'KANAWHA',
      'KENSETT',
      'KLEMME',
      'LALASKAE MILLS',
      'LALASKAOTA',
      'LATIMER',
      'LELAND',
      'LITTLE CEDAR',
      'MC INTIRE',
      'MANLY',
      'MESERVEY',
      'NORA SPRINGS',
      'NORTHWOOD',
      'ORCHARD',
      'OSAGE',
      'PLYMOUTH',
      'RALASKAE',
      'RICEVILLE',
      'ROCK FALLS',
      'ROCKFORD',
      'ROCKWELL',
      'ROWAN',
      'RUDD',
      'SAINT ANSGAR',
      'SCARVILLE',
      'SHEFFIELD',
      'STACYVILLE',
      'SWALEDALE',
      'THOMPSON',
      'THORNTON',
      'TITONKA',
      'TOETERVILLE',
      'VENTURA',
      'WESLEY',
      'WODEN',
      'FORT DODGE',
      'ALBERT CITY',
      'ALGONA',
      'ARMSTRONG',
      'AYRSHIRE',
      'BADGER',
      'BANCROFT',
      'BARNUM',
      'BODE',
      'BRADGATE',
      'BURNSIDE',
      'BURT',
      'CALLENDER',
      'CLARE',
      'CLARION',
      'CURLEW',
      'CYLINDER',
      'DALASKAOTA CITY',
      'DAYTON',
      'DOLLIVER',
      'DUNCOMBE',
      'EAGLE GROVE',
      'EARLY',
      'EMMETSBURG',
      'FARNHAMVILLE',
      'FENTON',
      'FONDA',
      'GILMORE CITY',
      'GOLDFIELD',
      'GOWRIE',
      'HARCOURT',
      'HARDY',
      'HAVELOCK',
      'HUMBOLDT',
      'JOLLEY',
      'KNIERIM',
      'LAURENS',
      'LEDYARD',
      'LEHIGH',
      'LIVERMORE',
      'LONE ROCK',
      'LU VERNE',
      'LYTTON',
      'MALLARD',
      'MANSON',
      'MARATHON',
      'MOORLAND',
      'NEMAHA',
      'NEWELL',
      'OTHO',
      'OTTOSEN',
      'PALMER',
      'PLOVER',
      'POCAHONTAS',
      'POMEROY',
      'REMBRANDT',
      'RENWICK',
      'RINGSTED',
      'ROCKWELL CITY',
      'ROLFE',
      'RUTLAND',
      'SAC CITY',
      'SIOUX RAPIDS',
      'SOMERS',
      'STORM LALASKAE',
      'SWEA CITY',
      'THOR',
      'TRUESDALE',
      'VARINA',
      'VINCENT',
      'WEBSTER CITY',
      'WEST BEND',
      'WHITTEMORE',
      'WOOLSTOCK',
      'ACKLEY',
      'ALLISON',
      'ALTA VISTA',
      'APLINGTON',
      'AREDALE',
      'ARLINGTON',
      'AURORA',
      'AUSTINVILLE',
      'BEAMAN',
      'BRISTOW',
      'BUCKINGHAM',
      'CEDAR FALLS',
      'CHARLES CITY',
      'CLARKSVILLE',
      'COLWELL',
      'CONRAD',
      'DENVER',
      'DEWAR',
      'DIKE',
      'DUMONT',
      'DUNKERTON',
      'ELDORA',
      'ELMA',
      'FAIRBANK',
      'FREDERICKSBURG',
      'FREDERIKA',
      'GARWIN',
      'GENEVA',
      'GILBERTVILLE',
      'GLADBROOK',
      'GREENE',
      'GRUNDY CENTER',
      'HAZLETON',
      'HOLLAND',
      'HUDSON',
      'INDEPENDENCE',
      'IONIA',
      'JANESVILLE',
      'JESUP',
      'KESLEY',
      'LAMONT',
      'LA PORTE CITY',
      'LINCOLN',
      'MARBLE ROCK',
      'MASONVILLE',
      'MAYNARD',
      'MORRISON',
      'NASHUA',
      'NEW HAMPTON',
      'NEW HARTFORD',
      'NORTH WASHINGTON',
      'OELWEIN',
      'ORAN',
      'PARKERSBURG',
      'PLAINFIELD',
      'RAYMOND',
      'READLYN',
      'REINBECK',
      'SHELL ROCK',
      'STANLEY',
      'STEAMBOAT ROCK',
      'STOUT',
      'SUMNER',
      'TRAER',
      'TRIPOLI',
      'WAVERLY',
      'WELLSBURG',
      'WESTGATE',
      'WINTHROP',
      'WATERLOO',
      'EVANSDALE',
      'CRESTON',
      'AFTON',
      'ARISPE',
      'BEDFORD',
      'BENTON',
      'BLOCKTON',
      'BRIDGEWATER',
      'CARBON',
      'CLEARFIELD',
      'CORNING',
      'CROMWELL',
      'CUMBERLAND',
      'DIAGONAL',
      'FONTANELLE',
      'GRANT',
      'GRAVITY',
      'GREENFIELD',
      'LENOX',
      'MASSENA',
      'MOUNT AYR',
      'NODAWAY',
      'ORIENT',
      'PRESCOTT',
      'REDDING',
      'SHANNON CITY',
      'SHARPSBURG',
      'TINGLEY',
      'VILLISCA',
      'ALASKARON',
      'ALTA',
      'ALTON',
      'ANTHON',
      'AURELIA',
      'BATTLE CREEK',
      'BRONSON',
      'BRUNSVILLE',
      'CALUMET',
      'CASTANA',
      'CHATSWORTH',
      'CHEROKEE',
      'CLEGHORN',
      'CLIMBING HILL',
      'CORRECTIONVILLE',
      'CRAIG',
      'CUSHING',
      'DANBURY',
      'GALVA',
      'GRANVILLE',
      'HAWARDEN',
      'HINTON',
      'HOLSTEIN',
      'HORNICK',
      'IRETON',
      'KINGSLEY',
      'LARRABEE',
      'LAWTON',
      'LE MARS',
      'LINN GROVE',
      'MAPLETON',
      'MARCUS',
      'MAURICE',
      'MERIDEN',
      'MERRILL',
      'MOVILLE',
      'ONAWA',
      'ORANGE CITY',
      'OTO',
      'OYENS',
      'PAULLINA',
      'PETERSON',
      'PIERSON',
      'QUIMBY',
      'REMSEN',
      'RODNEY',
      'SALIX',
      'SCHALLER',
      'SERGEANT BLUFF',
      'SLOAN',
      'SMITHLAND',
      'SUTHERLAND',
      'TURIN',
      'UTE',
      'WASHTA',
      'WESTFIELD',
      'WHITING',
      'SIOUX CITY',
      'SHELDON',
      'ALVORD',
      'ARCHER',
      'ASHTON',
      'BOYDEN',
      'DOON',
      'GEORGE',
      'HOSPERS',
      'HULL',
      'INWOOD',
      'LARCHWOOD',
      'LESTER',
      'LITTLE ROCK',
      'MATLOCK',
      'PRIMGHAR',
      'ROCK RAPIDS',
      'ROCK VALLEY',
      'SANBORN',
      'SIBLEY',
      'SIOUX CENTER',
      'SPENCER',
      'ALLENDORF',
      'ARNOLDS PARK',
      'DICKENS',
      'ESTHERVILLE',
      'EVERLY',
      'FOSTORIA',
      'GILLETT GROVE',
      'GRAETTINGER',
      'GREENVILLE',
      'GRUVER',
      'HARRIS',
      'HARTLEY',
      'LALASKAE PARK',
      'MAY CITY',
      'MELVIN',
      'MILFORD',
      'OCHEYEDAN',
      'OKOBOJI',
      'ROYAL',
      'RUTHVEN',
      'SPIRIT LALASKAE',
      'SUPERIOR',
      'TERRIL',
      'WALLINGFORD',
      'WEBB',
      'CARROLL',
      'ARCADIA',
      'ARTHUR',
      'ASPINWALL',
      'AUBURN',
      'BREDA',
      'CHARTER OALASKA',
      'DEDHAM',
      'DELOIT',
      'DENISON',
      'GLIDDEN',
      'HALBUR',
      'IDA GROVE',
      'IRWIN',
      'KIRKMAN',
      'KIRON',
      'LALASKAE CITY',
      'LALASKAE VIEW',
      'LANESBORO',
      'LIDDERDALE',
      'LOHRVILLE',
      'MANILLA',
      'MANNING',
      'ODEBOLT',
      'RALSTON',
      'RICKETTS',
      'SCHLESWIG',
      'SCRANTON',
      'TEMPLETON',
      'VAIL',
      'WALL LALASKAE',
      'WESTSIDE',
      'COUNCIL BLUFFS',
      'CARTER LALASKAE',
      'ARION',
      'AVOCA',
      'BLENCOE',
      'CARSON',
      'CRESCENT',
      'DEFIANCE',
      'DOW CITY',
      'DUNLAP',
      'EARLING',
      'ELK HORN',
      'ELLIOTT',
      'EMERSON',
      'GLENWOOD',
      'GRISWOLD',
      'HANCOCK',
      'HARLAN',
      'HASTINGS',
      'HENDERSON',
      'HONEY CREEK',
      'KIMBALLTON',
      'LEWIS',
      'LITTLE SIOUX',
      'LOGAN',
      'MC CLELLAND',
      'MACEDONIA',
      'MAGNOLIA',
      'MALVERN',
      'MARNE',
      'MINDEN',
      'MINEOLA',
      'MISSOURI VALLEY',
      'MODALE',
      'MONDAMIN',
      'MOORHEAD',
      'NEOLA',
      'OALASKALAND',
      'PACIFIC JUNCTION',
      'PANAMA',
      'PERSIA',
      'PISGAH',
      'PORTSMOUTH',
      'RED OALASKA',
      'SHELBY',
      'SILVER CITY',
      'SOLDIER',
      'STANTON',
      'TENNANT',
      'TREYNOR',
      'UNDERWOOD',
      'WALNUT',
      'WESTPHALIA',
      'WOODBINE',
      'SHENANDOAH',
      'BLANCHARD',
      'BRADDYVILLE',
      'CLARINDA',
      'COIN',
      'COLLEGE SPRINGS',
      'ESSEX',
      'FARRAGUT',
      'HAMBURG',
      'IMOGENE',
      'NEW MARKET',
      'NORTHBORO',
      'PERCIVAL',
      'RANDOLPH',
      'RIVERTON',
      'SHAMBAUGH',
      'SIDNEY',
      'TABOR',
      'THURMAN',
      'YORKTOWN',
      'DUBUQUE',
      'ANDREW',
      'BELLEVUE',
      'BERNARD',
      'CASCADE',
      'COLESBURG',
      'DELAWARE',
      'DELMAR',
      'DUNDEE',
      'DURANGO',
      'DYERSVILLE',
      'EARLVILLE',
      'EDGEWOOD',
      'ELKADER',
      'ELKPORT',
      'EPWORTH',
      'FARLEY',
      'FARMERSBURG',
      'GARBER',
      'GARNAVILLO',
      'GREELEY',
      'GUTTENBERG',
      'HOLY CROSS',
      'LA MOTTE',
      'LITTLEPORT',
      'LUXEMBURG',
      'MANCHESTER',
      'MAQUOKETA',
      'MILES',
      'NEW VIENNA',
      'NORTH BUENA VISTA',
      'PEOSTA',
      'PRESTON',
      'SABULA',
      'SAINT DONATUS',
      'SAINT OLAF',
      'SHERRILL',
      'SPRAGUEVILLE',
      'SPRINGBROOK',
      'STRAWBERRY POINT',
      'VOLGA',
      'WORTHINGTON',
      'ZWINGLE',
      'DECORAH',
      'CALMAR',
      'CASTALIA',
      'CHESTER',
      'CLERMONT',
      'CRESCO',
      'DORCHESTER',
      'ELGIN',
      'FAYETTE',
      'FORT ATKINSON',
      'HARPERS FERRY',
      'HAWKEYE',
      'HIGHLANDVILLE',
      'LANSING',
      'LAWLER',
      'LIME SPRINGS',
      'LUANA',
      'MC GREGOR',
      'MARQUETTE',
      'MONONA',
      'NEW ALBIN',
      'OSSIAN',
      'POSTVILLE',
      'PROTIVIN',
      'RANDALIA',
      'RIDGEWAY',
      'SAINT LUCAS',
      'SPILLVILLE',
      'WADENA',
      'WATERVILLE',
      'WAUCOMA',
      'WAUKON',
      'WEST UNION',
      'AINSWORTH',
      'ALBURNETT',
      'AMANA',
      'ANAMOSA',
      'ATKINS',
      'BALDWIN',
      'BELLE PLAINE',
      'BLAIRSTOWN',
      'BRANDON',
      'BROOKLYN',
      'CENTER JUNCTION',
      'CENTER POINT',
      'CENTRAL CITY',
      'CHELSEA',
      'CLARENCE',
      'CLUTIER',
      'COGGON',
      'PRAIRIEBURG',
      'CONROY',
      'GUERNSEY',
      'DEEP RIVER',
      'DELHI',
      'DYSART',
      'ELBERON',
      'ELWOOD',
      'ELY',
      'FAIRFAX',
      'GARRISON',
      'HARPER',
      'HARTWICK',
      'HIAWATHA',
      'HILLS',
      'HOMESTEAD',
      'HOPKINTON',
      'IOWA CITY',
      'CORALVILLE',
      'KALONA',
      'KEOTA',
      'KEYSTONE',
      'LADORA',
      'LANGWORTHY',
      'LISBON',
      'LOST NATION',
      'LOWDEN',
      'LUZERNE',
      'MARENGO',
      'MARION',
      'MARTELLE',
      'MECHANICSVILLE',
      'MIDDLE AMANA',
      'MILLERSBURG',
      'MONMOUTH',
      'MONTICELLO',
      'MORLEY',
      'MOUNT AUBURN',
      'MOUNT VERNON',
      'NEWHALL',
      'NORTH ENGLISH',
      'NORTH LIBERTY',
      'NORWAY',
      'OALASKADALE',
      'OLIN',
      'ONSLOW',
      'OXFORD',
      'OXFORD JUNCTION',
      'PALO',
      'PARNELL',
      'QUASQUETON',
      'RIVERSIDE',
      'ROBINS',
      'ROWLEY',
      'RYAN',
      'SHELLSBURG',
      'SOLON',
      'SOUTH AMANA',
      'SOUTH ENGLISH',
      'SPRINGVILLE',
      'STANWOOD',
      'SWISHER',
      'TAMA',
      'TIFFIN',
      'TODDVILLE',
      'TOLEDO',
      'TROY MILLS',
      'URBANA',
      'VAN HORNE',
      'VICTOR',
      'VINING',
      'VINTON',
      'VIOLA',
      'WALFORD',
      'WALKER',
      'WASHINGTON',
      'WATKINS',
      'WEBSTER',
      'WELLMAN',
      'WEST BRANCH',
      'WEST CHESTER',
      'WILLIAMSBURG',
      'WYOMING',
      'CEDAR RAPIDS',
      'OTTUMWA',
      'AGENCY',
      'ALBIA',
      'BATAVIA',
      'BEACON',
      'BIRMINGHAM',
      'BLALASKAESBURG',
      'BLOOMFIELD',
      'WEST GROVE',
      'BRIGHTON',
      'CANTRIL',
      'CEDAR',
      'CENTERVILLE',
      'CHILLICOTHE',
      'CINCINNATI',
      'DELTA',
      'DOUDS',
      'DRALASKAESVILLE',
      'EDDYVILLE',
      'ELDON',
      'EXLINE',
      'FAIRFIELD',
      'FLORIS',
      'FREMONT',
      'HAYESVILLE',
      'HEDRICK',
      'KEOSAUQUA',
      'KIRKVILLE',
      'LIBERTYVILLE',
      'MARTINSBURG',
      'MELROSE',
      'MILTON',
      'MORAVIA',
      'MOULTON',
      'MOUNT STERLING',
      'MYSTIC',
      'OLLIE',
      'OSKALOOSA',
      'PACKWOOD',
      'PLANO',
      'PROMISE CITY',
      'PULASKI',
      'RICHLAND',
      'ROSE HILL',
      'SELMA',
      'SEYMOUR',
      'SIGOURNEY',
      'UDELL',
      'UNIONVILLE',
      'UNIVERSITY PARK',
      'BURLINGTON',
      'ARGYLE',
      'BONAPARTE',
      'CRAWFORDSVILLE',
      'DANVILLE',
      'DENMARK',
      'DONNELLSON',
      'FARMINGTON',
      'FORT MADISON',
      'HILLSBORO',
      'HOUGHTON',
      'KEOKUK',
      'LOCKRIDGE',
      'MEDIAPOLIS',
      'MIDDLETOWN',
      'MONTROSE',
      'MORNING SUN',
      'MOUNT PLEASANT',
      'ROME',
      'MOUNT UNION',
      'NEW LONDON',
      'OALASKAVILLE',
      'OLDS',
      'PILOT GROVE',
      'SALEM',
      'SPERRY',
      'STOCKPORT',
      'SWEDESBURG',
      'WAPELLO',
      'WAYLAND',
      'WEST BURLINGTON',
      'WEST POINT',
      'SAINT PAUL',
      'WEVER',
      'WINFIELD',
      'YARMOUTH',
      'ANDOVER',
      'ATALISSA',
      'BENNETT',
      'BETTENDORF',
      'BLUE GRASS',
      'BRYANT',
      'BUFFALO',
      'CALAMUS',
      'CAMANCHE',
      'CHARLOTTE',
      'CLINTON',
      'COLUMBUS CITY',
      'COLUMBUS JUNCTION',
      'CONESVILLE',
      'DE WITT',
      'DIXON',
      'DONAHUE',
      'DURANT',
      'ELDRIDGE',
      'FRUITLAND',
      'GOOSE LALASKAE',
      'GRAND MOUND',
      'GRANDVIEW',
      'LE CLAIRE',
      'LETTS',
      'LONE TREE',
      'LONG GROVE',
      'LOW MOOR',
      'MC CAUSLAND',
      'MONTPELIER',
      'MOSCOW',
      'MUSCATINE',
      'NEW LIBERTY',
      'NICHOLS',
      'PLEASANT VALLEY',
      'PRINCETON',
      'STOCKTON',
      'TEEDS GROVE',
      'TIPTON',
      'WALCOTT',
      'WELTON',
      'WEST LIBERTY',
      'WHEATLAND',
      'WILTON',
      'DAVENPORT'
    ]
  },
  ID: {
    code: 'ID',
    name: 'IDAHO',
    cities: [
      'POCATELLO',
      'FORT HALL',
      'ABERDEEN',
      'AMERICAN FALLS',
      'ARBON',
      'ARCO',
      'ARIMO',
      'ATOMIC CITY',
      'BANCROFT',
      'BASALT',
      'BERN',
      'BLACKFOOT',
      'BLOOMINGTON',
      'CHALLIS',
      'CLAYTON',
      'CLIFTON',
      'COBALT',
      'CONDA',
      'DAYTON',
      'DINGLE',
      'DOWNEY',
      'ELLIS',
      'FIRTH',
      'FRANKLIN',
      'GENEVA',
      'GEORGETOWN',
      'GRACE',
      'HOLBROOK',
      'HOWE',
      'INKOM',
      'LAVA HOT SPRINGS',
      'MCCAMMON',
      'MACKAY',
      'MALAD CITY',
      'MAY',
      'MONTPELIER',
      'MOORE',
      'MORELAND',
      'PARIS',
      'PINGREE',
      'PRESTON',
      'ROCKLAND',
      'SAINT CHARLES',
      'SHELLEY',
      'SODA SPRINGS',
      'SPRINGFIELD',
      'STANLEY',
      'SWANLALASKAE',
      'THATCHER',
      'WAYAN',
      'WESTON',
      'FISH HAVEN',
      'TWIN FALLS',
      'ROGERSON',
      'ALBION',
      'ALMO',
      'BELLEVUE',
      'BLISS',
      'BUHL',
      'BURLEY',
      'CAREY',
      'CASTLEFORD',
      'CORRAL',
      'DECLO',
      'DIETRICH',
      'EDEN',
      'FAIRFIELD',
      'FILER',
      'GOODING',
      'HAGERMAN',
      'HAILEY',
      'HANSEN',
      'HAZELTON',
      'HEYBURN',
      'HILL CITY',
      'JEROME',
      'KETCHUM',
      'KIMBERLY',
      'MALTA',
      'MINIDOKA',
      'MURTAUGH',
      'OALASKALEY',
      'PAUL',
      'PICABO',
      'RICHFIELD',
      'RUPERT',
      'SHOSHONE',
      'SUN VALLEY',
      'WENDELL',
      'IDAHO FALLS',
      'ASHTON',
      'CHESTER',
      'DRIGGS',
      'DUBOIS',
      'FELT',
      'HAMER',
      'IONA',
      'IRWIN',
      'ISLAND PARK',
      'LEWISVILLE',
      'MACKS INN',
      'MENAN',
      'MONTEVIEW',
      'NEWDALE',
      'PARKER',
      'REXBURG',
      'RIGBY',
      'RIRIE',
      'ROBERTS',
      'SAINT ANTHONY',
      'SPENCER',
      'SQUIRREL',
      'SUGAR CITY',
      'SWAN VALLEY',
      'TERRETON',
      'TETON',
      'TETONIA',
      'UCON',
      'VICTOR',
      'CARMEN',
      'GIBBONSVILLE',
      'LEADORE',
      'LEMHI',
      'NORTH FORK',
      'SALMON',
      'TENDOY',
      'SHOUP',
      'LEWISTON',
      'AHSAHKA',
      'COTTONWOOD',
      'CRAIGMONT',
      'CULDESAC',
      'ELK CITY',
      'FERDINAND',
      'GRANGEVILLE',
      'FENN',
      'GREENCREEK',
      'JULIAETTA',
      'KAMIAH',
      'KENDRICK',
      'KOOSKIA',
      'LAPWAI',
      'LENORE',
      'LUCILE',
      'NEZPERCE',
      'OROFINO',
      'PECK',
      'PIERCE',
      'POLLOCK',
      'REUBENS',
      'RIGGINS',
      'SPALDING',
      'STITES',
      'WEIPPE',
      'WHITE BIRD',
      'WINCHESTER',
      'ATLANTA',
      'BANKS',
      'BRUNEAU',
      'CALDWELL',
      'CAMBRIDGE',
      'CASCADE',
      'COUNCIL',
      'DONNELLY',
      'EAGLE',
      'EMMETT',
      'FRUITLAND',
      'FRUITVALE',
      'GARDEN VALLEY',
      'GLENNS FERRY',
      'GRAND VIEW',
      'GREENLEAF',
      'HAMMETT',
      'HOMEDALE',
      'HORSESHOE BEND',
      'HUSTON',
      'IDAHO CITY',
      'INDIAN VALLEY',
      'KING HILL',
      'KUNA',
      'LALASKAE FORK',
      'LETHA',
      'LOWMAN',
      'MCCALL',
      'MARSING',
      'MELBA',
      'MERIDIAN',
      'MESA',
      'MIDDLETON',
      'MIDVALE',
      'MOUNTAIN HOME',
      'MOUNTAIN HOME A F B',
      'MURPHY',
      'NAMPA',
      'NEW MEADOWS',
      'NEW PLYMOUTH',
      'NOTUS',
      'OLA',
      'PARMA',
      'PAYETTE',
      'PLACERVILLE',
      'STAR',
      'SWEET',
      'WARREN',
      'WEISER',
      'WILDER',
      'YELLOW PINE',
      'BOISE',
      'GARDEN CITY',
      'ATHOL',
      'AVERY',
      'BAYVIEW',
      'BLANCHARD',
      'BONNERS FERRY',
      'BOVILL',
      'CALDER',
      'CAREYWOOD',
      'CATALDO',
      'CLARK FORK',
      'CLARKIA',
      'COCOLALLA',
      'COEUR D ALENE',
      'COOLIN',
      'OLDTOWN',
      'DEARY',
      'DESMET',
      'DOVER',
      'EASTPORT',
      'ELK RIVER',
      'FERNWOOD',
      'GENESEE',
      'HARRISON',
      'HARVARD',
      'HAYDEN',
      'HOPE',
      'KELLOGG',
      'KINGSTON',
      'KOOTENAI',
      'LACLEDE',
      'MEDIMONT',
      'MOSCOW',
      'MOYIE SPRINGS',
      'MULLAN',
      'NAPLES',
      'NORDMAN',
      'OSBURN',
      'PINEHURST',
      'PLUMMER',
      'PONDERAY',
      'PORTHILL',
      'POST FALLS',
      'POTLATCH',
      'PRIEST RIVER',
      'PRINCETON',
      'RATHDRUM',
      'SAGLE',
      'SAINT MARIES',
      'SAMUELS',
      'SANDPOINT',
      'COLBURN',
      'SANTA',
      'SILVERTON',
      'SMELTERVILLE',
      'SPIRIT LALASKAE',
      'TENSED',
      'TROY',
      'VIOLA',
      'WALLACE',
      'MURRAY',
      'WORLEY'
    ]
  },
  IL: {
    code: 'IL',
    name: 'ILLINOIS',
    cities: [
      'ALDEN',
      'ANTIOCH',
      'ARLINGTON HEIGHTS',
      'ELK GROVE VILLAGE',
      'ROLLING MEADOWS',
      'BARRINGTON',
      'CRYSTAL LALASKAE',
      'CARY',
      'DEERFIELD',
      'DES PLAINES',
      'FOX LALASKAE',
      'FOX RIVER GROVE',
      'GLENCOE',
      'GLENVIEW',
      'GLENVIEW NAS',
      'GOLF',
      'GRAYSLALASKAE',
      'GURNEE',
      'HARVARD',
      'HEBRON',
      'HIGHLAND PARK',
      'FORT SHERIDAN',
      'PALATINE',
      'HIGHWOOD',
      'INGLESIDE',
      'ISLAND LALASKAE',
      'KENILWORTH',
      'LALASKAE BLUFF',
      'LALASKAE FOREST',
      'LALASKAE VILLA',
      'LALASKAE ZURICH',
      'LIBERTYVILLE',
      'LONG GROVE',
      'MCHENRY',
      'MORTON GROVE',
      'MOUNT PROSPECT',
      'MUNDELEIN',
      'VERNON HILLS',
      'NORTHBROOK',
      'NORTH CHICAGO',
      'PARK RIDGE',
      'LINCOLNSHIRE',
      'PROSPECT HEIGHTS',
      'RICHMOND',
      'RINGWOOD',
      'ROUND LALASKAE',
      'RUSSELL',
      'SKOKIE',
      'WAUKEGAN',
      'SOLON MILLS',
      'SPRING GROVE',
      'TECHNY',
      'WADSWORTH',
      'WAUCONDA',
      'GREAT LALASKAES',
      'BUFFALO GROVE',
      'WHEELING',
      'WILMETTE',
      'WINNETKA',
      'WINTHROP HARBOR',
      'WONDER LALASKAE',
      'WOODSTOCK',
      'ZION',
      'ADDISON',
      'ALGONQUIN',
      'BARTLETT',
      'BELLWOOD',
      'BENSENVILLE',
      'STREAMWOOD',
      'BLOOMINGDALE',
      'BURLINGTON',
      'CARPENTERSVILLE',
      'CLARE',
      'CORTLAND',
      'CRESTON',
      'DEKALB',
      'CAROL STREAM',
      'DUNDEE',
      'ELBURN',
      'ELGIN',
      'ELMHURST',
      'ESMOND',
      'FOREST PARK',
      'FRANKLIN PARK',
      'HANOVER PARK',
      'GENEVA',
      'GENOA',
      'GILBERTS',
      'GLEN ELLYN',
      'GLENDALE HEIGHTS',
      'HAMPSHIRE',
      'HINES',
      'HUNTLEY',
      'ITASCA',
      'KANEVILLE',
      'KINGSTON',
      'KIRKLAND',
      'LAFOX',
      'LOMBARD',
      'MALTA',
      'MAPLE PARK',
      'MARENGO',
      'MAYWOOD',
      'WESTCHESTER',
      'BROADVIEW',
      'LALASKAE IN THE HILLS',
      'MEDINAH',
      'SCHAUMBURG',
      'MELROSE PARK',
      'HILLSIDE',
      'BERKELEY',
      'STONE PARK',
      'PLATO CENTER',
      'RIVER GROVE',
      'ROSELLE',
      'SAINT CHARLES',
      'SCHILLER PARK',
      'SOUTH ELGIN',
      'SYCAMORE',
      'HOFFMAN ESTATES',
      'UNION',
      'VILLA PARK',
      'VIRGIL',
      'WASCO',
      'WAYNE',
      'WEST CHICAGO',
      'WHEATON',
      'WINFIELD',
      'WOOD DALE',
      'EVANSTON',
      'OALASKA PARK',
      'RIVER FOREST',
      'BEECHER',
      'BERWYN',
      'BLUE ISLAND',
      'BRACEVILLE',
      'BRAIDWOOD',
      'CALUMET CITY',
      'CHANNAHON',
      'CHICAGO HEIGHTS',
      'CHICAGO RIDGE',
      'COAL CITY',
      'CRETE',
      'DOLTON',
      'DWIGHT',
      'ELWOOD',
      'FLOSSMOOR',
      'FRANKFORT',
      'GARDNER',
      'GLENWOOD',
      'HARVEY',
      'HAZEL CREST',
      'HOMEWOOD',
      'JOLIET',
      'KINSMAN',
      'LANSING',
      'LEMONT',
      'BOLINGBROOK',
      'LOCKPORT',
      'MANHATTAN',
      'MATTESON',
      'MAZON',
      'MIDLOTHIAN',
      'ROMEOVILLE',
      'MINOOKA',
      'MOKENA',
      'MONEE',
      'MORRIS',
      'NEW LENOX',
      'OALASKA FOREST',
      'OALASKA LAWN',
      'BRIDGEVIEW',
      'HOMETOWN',
      'HICKORY HILLS',
      'JUSTICE',
      'BURBANK',
      'ODELL',
      'OLYMPIA FIELDS',
      'ORLAND PARK',
      'PALOS HEIGHTS',
      'PALOS PARK',
      'PALOS HILLS',
      'PARK FOREST',
      'PEOTONE',
      'POSEN',
      'RANSOM',
      'RICHTON PARK',
      'ROBBINS',
      'SOUTH HOLLAND',
      'SOUTH WILMINGTON',
      'STEGER',
      'THORNTON',
      'TINLEY PARK',
      'COUNTRY CLUB HILLS',
      'VERONA',
      'WILLOW SPRINGS',
      'WILMINGTON',
      'WORTH',
      'BEDFORD PARK',
      'SUMMIT ARGO',
      'AURORA',
      'BATAVIA',
      'BIG ROCK',
      'BRISTOL',
      'BROOKFIELD',
      'CLARENDON HILLS',
      'DOWNERS GROVE',
      'WOODRIDGE',
      'EARLVILLE',
      'EOLA',
      'HINCKLEY',
      'HINSDALE',
      'OALASKA BROOK',
      'LA GRANGE',
      'LA GRANGE PARK',
      'WILLOWBROOK',
      'LEE',
      'LELAND',
      'LISLE',
      'LYONS',
      'MILLBROOK',
      'MILLINGTON',
      'MONTGOMERY',
      'MOOSEHEART',
      'NAPERVILLE',
      'NEWARK',
      'NORTH AURORA',
      'OSWEGO',
      'PLAINFIELD',
      'PLANO',
      'RIVERSIDE',
      'SANDWICH',
      'SERENA',
      'SHABBONA',
      'SHERIDAN',
      'SOMONAUK',
      'STEWARD',
      'SUGAR GROVE',
      'WARRENVILLE',
      'WATERMAN',
      'WEDRON',
      'WESTERN SPRINGS',
      'WESTMONT',
      'YORKVILLE',
      'DARIEN',
      'FOX VALLEY',
      'CHICAGO',
      'AMF OHARE',
      'HARWOOD HEIGHTS',
      'ELMWOOD PARK',
      'LINCOLNWOOD',
      'NILES',
      'ALSIP',
      'CICERO',
      'EVERGREEN PARK',
      'RIVERDALE',
      'KANKALASKAEE',
      'AROMA PARK',
      'ASHKUM',
      'BEAVERVILLE',
      'BONFIELD',
      'BOURBONNAIS',
      'BRADLEY',
      'BUCKINGHAM',
      'BUCKLEY',
      'CABERY',
      'CAMPUS',
      'CHATSWORTH',
      'CHEBANSE',
      'CISSNA PARK',
      'CLAYTONVILLE',
      'CLIFTON',
      'CRESCENT CITY',
      'CULLOM',
      'DANFORTH',
      'DONOVAN',
      'EAST LYNN',
      'ELLIOTT',
      'EMINGTON',
      'ESSEX',
      'GIBSON CITY',
      'GILMAN',
      'GOODWINE',
      'GRANT PARK',
      'HERSCHER',
      'HOOPESTON',
      'HOPKINS PARK',
      'IROQUOIS',
      'KEMPTON',
      'LODA',
      'LUDLOW',
      'MANTENO',
      'MARTINTON',
      'MELVIN',
      'MILFORD',
      'MOMENCE',
      'ONARGA',
      'PAPINEAU',
      'PAXTON',
      'PIPER CITY',
      'RANKIN',
      'REDDICK',
      'ROBERTS',
      'ROSSVILLE',
      'SAINT ANNE',
      'SHELDON',
      'STOCKLAND',
      'THAWVILLE',
      'UNION HILL',
      'WATSEKA',
      'WELLINGTON',
      'WOODLAND',
      'APPLE RIVER',
      'ASHTON',
      'BAILEYVILLE',
      'BELVIDERE',
      'BYRON',
      'CALEDONIA',
      'CAPRON',
      'CEDARVILLE',
      'CHADWICK',
      'CHANA',
      'CHERRY VALLEY',
      'DALASKAOTA',
      'DAVIS',
      'DAVIS JUNCTION',
      'DIXON',
      'DURAND',
      'EAST DUBUQUE',
      'ELEROY',
      'ELIZABETH',
      'FORRESTON',
      'FRANKLIN GROVE',
      'FREEPORT',
      'GALENA',
      'GALT',
      'GARDEN PRAIRIE',
      'GERMAN VALLEY',
      'HANOVER',
      'HARMON',
      'HOLCOMB',
      'KENT',
      'LANARK',
      'LEAF RIVER',
      'LENA',
      'LINDENWOOD',
      'MC CONNELL',
      'MILLEDGEVILLE',
      'MONROE CENTER',
      'MOUNT CARROLL',
      'MOUNT MORRIS',
      'NACHUSA',
      'NELSON',
      'NORA',
      'ORANGEVILLE',
      'OREGON',
      'PEARL CITY',
      'PECATONICA',
      'POLO',
      'POPLAR GROVE',
      'RIDOTT',
      'ROCHELLE',
      'ROCK CITY',
      'ROCK FALLS',
      'ROCKTON',
      'ROSCOE',
      'SAVANNA',
      'SCALES MOUND',
      'SCIOTO MILLS',
      'SEWARD',
      'SHANNON',
      'SHIRLAND',
      'SOUTH BELOIT',
      'STERLING',
      'STILLMAN VALLEY',
      'STOCKTON',
      'WARREN',
      'WINNEBAGO',
      'WINSLOW',
      'WOOSUNG',
      'ROCKFORD',
      'LOVES PARK',
      'MACHESNEY PARK',
      'ROCK ISLAND',
      'ALBANY',
      'ALEDO',
      'ANDALUSIA',
      'ANDOVER',
      'ANNAWAN',
      'ATKINSON',
      'BARSTOW',
      'BUFFALO PRAIRIE',
      'CAMBRIDGE',
      'CARBON CLIFF',
      'COAL VALLEY',
      'COLONA',
      'CORDOVA',
      'DEER GROVE',
      'EAST MOLINE',
      'ERIE',
      'FENTON',
      'FULTON',
      'GENESEO',
      'HAMPTON',
      'HILLSDALE',
      'HOOPPOLE',
      'ILLINOIS CITY',
      'JOY',
      'LYNDON',
      'LYNN CENTER',
      'MATHERVILLE',
      'MILAN',
      'MOLINE',
      'MORRISON',
      'NEW BOSTON',
      'ORION',
      'OSCO',
      'PORT BYRON',
      'PREEMPTION',
      'PROPHETSTOWN',
      'RAPIDS CITY',
      'REYNOLDS',
      'SHERRARD',
      'SILVIS',
      'TAMPICO',
      'TAYLOR RIDGE',
      'THOMSON',
      'LA SALLE',
      'AMBOY',
      'ANCONA',
      'ARLINGTON',
      'BLACKSTONE',
      'BUDA',
      'BUREAU',
      'CEDAR POINT',
      'CHERRY',
      'COMPTON',
      'CORNELL',
      'DALZELL',
      'DANA',
      'DEPUE',
      'DOVER',
      'ELDENA',
      'GRAND RIDGE',
      'GRANVILLE',
      'HENNEPIN',
      'KASBEER',
      'LADD',
      'LA MOILLE',
      'LEE CENTER',
      'LEONORE',
      'LONG POINT',
      'LOSTANT',
      'MC NABB',
      'MAGNOLIA',
      'MALDEN',
      'MANLIUS',
      'MARK',
      'MARSEILLES',
      'MENDOTA',
      'MINERAL',
      'NEPONSET',
      'NEW BEDFORD',
      'OGLESBY',
      'OHIO',
      'OTTAWA',
      'PAW PAW',
      'PERU',
      'PRINCETON',
      'RUTLAND',
      'SEATONVILLE',
      'SENECA',
      'SHEFFIELD',
      'SPRING VALLEY',
      'STANDARD',
      'STREATOR',
      'SUBLETTE',
      'TISKILWA',
      'TOLUCA',
      'TONICA',
      'TRIUMPH',
      'TROY GROVE',
      'UTICA',
      'VAN ORIN',
      'VARNA',
      'WALNUT',
      'WENONA',
      'WEST BROOKLYN',
      'WYANET',
      'GALESBURG',
      'ABINGDON',
      'ADAIR',
      'ALEXIS',
      'ALPHA',
      'ALTONA',
      'AVON',
      'BARDOLPH',
      'BERWICK',
      'BIGGSVILLE',
      'BISHOP HILL',
      'BLANDINSVILLE',
      'BRADFORD',
      'BUSHNELL',
      'CAMERON',
      'CAMP GROVE',
      'CARMAN',
      'CASTLETON',
      'CUBA',
      'DAHINDA',
      'EAST GALESBURG',
      'ELLISVILLE',
      'FAIRVIEW',
      'FIATT',
      'GALVA',
      'GERLAW',
      'GILSON',
      'GLADSTONE',
      'GOOD HOPE',
      'HENDERSON',
      'INDUSTRY',
      'IPAVA',
      'KEITHSBURG',
      'KEWANEE',
      'KIRKWOOD',
      'KNOXVILLE',
      'LA FAYETTE',
      'LA HARPE',
      'LAURA',
      'LITTLETON',
      'LITTLE YORK',
      'LOMAX',
      'MACOMB',
      'MAQUON',
      'MARIETTA',
      'MEDIA',
      'MONMOUTH',
      'NEW WINDSOR',
      'NORTH HENDERSON',
      'ONEIDA',
      'OPHEIM',
      'OQUAWKA',
      'PRAIRIE CITY',
      'RARITAN',
      'RIO',
      'ROSEVILLE',
      'SAINT AUGUSTINE',
      'SCIOTA',
      'SEATON',
      'SMITHFIELD',
      'SMITHSHIRE',
      'SPEER',
      'STRONGHURST',
      'TABLE GROVE',
      'TOULON',
      'VERMONT',
      'VICTORIA',
      'VIOLA',
      'WATAGA',
      'WILLIAMSFIELD',
      'WOODHULL',
      'WYOMING',
      'ASTORIA',
      'BENSON',
      'BRIMFIELD',
      'BRYANT',
      'CANTON',
      'CHILLICOTHE',
      'DUNFERMLINE',
      'DUNLAP',
      'EDELSTEIN',
      'EDWARDS',
      'ELMWOOD',
      'EUREKA',
      'FARMINGTON',
      'FOREST CITY',
      'GLASFORD',
      'GREEN VALLEY',
      'GROVELAND',
      'HANNA CITY',
      'HENRY',
      'KINGSTON MINES',
      'LACON',
      'LA ROSE',
      'LEWISTOWN',
      'LIVERPOOL',
      'LONDON MILLS',
      'LOWPOINT',
      'MANITO',
      'MAPLETON',
      'METAMORA',
      'MORTON',
      'MOSSVILLE',
      'NORRIS',
      'PEKIN',
      'PRINCEVILLE',
      'PUTNAM',
      'ROANOKE',
      'ROME',
      'SAINT DAVID',
      'SOUTH PEKIN',
      'SPARLAND',
      'TOPEKA',
      'TREMONT',
      'TRIVOLI',
      'WASHBURN',
      'WASHINGTON',
      'YATES CITY',
      'PEORIA',
      'CREVE COEUR',
      'EAST PEORIA',
      'BLOOMINGTON',
      'ANCHOR',
      'ARMINGTON',
      'ARROWSMITH',
      'ATLANTA',
      'BELLFLOWER',
      'CARLOCK',
      'CHENOA',
      'CLINTON',
      'COLFAX',
      'CONGERVILLE',
      'COOKSVILLE',
      'CROPSEY',
      'DANVERS',
      'DEER CREEK',
      'DELAVAN',
      'DEWITT',
      'DOWNS',
      'ELLSWORTH',
      'EL PASO',
      'FAIRBURY',
      'FLANAGAN',
      'FORREST',
      'GOODFIELD',
      'GRAYMONT',
      'GRIDLEY',
      'HEYWORTH',
      'HOPEDALE',
      'HUDSON',
      'KENNEY',
      'LANE',
      'LAWNDALE',
      'LE ROY',
      'LEXINGTON',
      'MC LEAN',
      'MACKINAW',
      'MAROA',
      'MERNA',
      'MINIER',
      'MINONK',
      'NORMAL',
      'PONTIAC',
      'SAUNEMIN',
      'SAYBROOK',
      'SECOR',
      'SHIRLEY',
      'SIBLEY',
      'STANFORD',
      'STRAWN',
      'TOWANDA',
      'WAPELLA',
      'WAYNESVILLE',
      'URBANA',
      'ALLERTON',
      'ALVIN',
      'ARMSTRONG',
      'BEMENT',
      'BISMARCK',
      'BONDVILLE',
      'BROADLANDS',
      'CATLIN',
      'CERRO GORDO',
      'CHAMPAIGN',
      'CISCO',
      'COLLISON',
      'DANVILLE',
      'TILTON',
      'DE LAND',
      'DEWEY',
      'FAIRMOUNT',
      'FARMER CITY',
      'FISHER',
      'FITHIAN',
      'FOOSLAND',
      'GEORGETOWN',
      'GIFFORD',
      'HENNING',
      'HOMER',
      'INDIANOLA',
      'IVESDALE',
      'LONGVIEW',
      'MAHOMET',
      'MANSFIELD',
      'MILMINE',
      'MONTICELLO',
      'MUNCIE',
      'OALASKAWOOD',
      'OGDEN',
      'PENFIELD',
      'PESOTUM',
      'PHILO',
      'POTOMAC',
      'RANTOUL',
      'RIDGE FARM',
      'ROYAL',
      'SADORUS',
      'SAINT JOSEPH',
      'SAVOY',
      'SEYMOUR',
      'SIDELL',
      'SIDNEY',
      'THOMASBORO',
      'TOLONO',
      'WELDON',
      'WESTVILLE',
      'WHITE HEATH',
      'ARCOLA',
      'ARTHUR',
      'ASHMORE',
      'ATWOOD',
      'BETHANY',
      'BROCTON',
      'CAMARGO',
      'CHARLESTON',
      'CHRISMAN',
      'DALTON CITY',
      'GAYS',
      'HAMMOND',
      'HINDSBORO',
      'HUMBOLDT',
      'HUME',
      'KANSAS',
      'LA PLACE',
      'LOVINGTON',
      'MATTOON',
      'METCALF',
      'MURDOCK',
      'NEWMAN',
      'OALASKALAND',
      'PARIS',
      'REDMON',
      'SULLIVAN',
      'TUSCOLA',
      'VERMILION',
      'VILLA GROVE',
      'WINDSOR',
      'ALHAMBRA',
      'ALTON',
      'BATCHTOWN',
      'BENLD',
      'BETHALTO',
      'BINGHAM',
      'BRIGHTON',
      'BRUSSELS',
      'BUNKER HILL',
      'BUTLER',
      'CARROLLTON',
      'COFFEEN',
      'COTTAGE HILLS',
      'DONNELLSON',
      'DORSEY',
      'DOW',
      'EAGARVILLE',
      'EAST ALTON',
      'EDWARDSVILLE',
      'ELDRED',
      'ELSAH',
      'FIDELITY',
      'FIELDON',
      'FILLMORE',
      'GILLESPIE',
      'GLEN CARBON',
      'GODFREY',
      'GOLDEN EAGLE',
      'GRAFTON',
      'GRANITE CITY',
      'GREENFIELD',
      'HAMBURG',
      'HAMEL',
      'HARDIN',
      'HARTFORD',
      'HILLSBORO',
      'HILLVIEW',
      'IRVING',
      'JERSEYVILLE',
      'KAMPSVILLE',
      'KANE',
      'LITCHFIELD',
      'LIVINGSTON',
      'LOVEJOY',
      'MADISON',
      'MARINE',
      'MARYVILLE',
      'MEDORA',
      'MICHAEL',
      'MORO',
      'MOUNT OLIVE',
      'MOZIER',
      'NATIONAL STOCK YARDS',
      'NEW DOUGLAS',
      'NOKOMIS',
      'OHLMAN',
      'PANAMA',
      'PATTERSON',
      'PIASA',
      'RAMSEY',
      'ROCKBRIDGE',
      'ROODHOUSE',
      'ROSAMOND',
      'ROXANA',
      'SAWYERVILLE',
      'SORENTO',
      'SOUTH ROXANA',
      'STAUNTON',
      'TAYLOR SPRINGS',
      'VENICE',
      'WALSHVILLE',
      'WHITE HALL',
      'WILSONVILLE',
      'WITT',
      'WOOD RIVER',
      'WORDEN',
      'WRIGHTS',
      'EAST SAINT LOUIS',
      'FAIRVIEW HEIGHTS',
      'ADDIEVILLE',
      'ALBERS',
      'AVISTON',
      'BALDWIN',
      'BARTELSO',
      'BECKEMEYER',
      'BELLEVILLE',
      'MASCOUTAH',
      'SCOTT AIR FORCE BASE',
      'BREESE',
      'CARLYLE',
      'CASEYVILLE',
      'CHESTER',
      'COLLINSVILLE',
      'COLUMBIA',
      'COULTERVILLE',
      'CUTLER',
      'DUPO',
      'EAST CARONDELET',
      'ELLIS GROVE',
      'EVANSVILLE',
      'FREEBURG',
      'FULTS',
      'GERMANTOWN',
      'GREENVILLE',
      'HAGARSTOWN',
      'HECKER',
      'HIGHLAND',
      'HOFFMAN',
      'HUEY',
      'KEYESPORT',
      'LEBANON',
      'LENZBURG',
      'MAEYSTOWN',
      'MARISSA',
      'MENARD',
      'MILLSTADT',
      'MODOC',
      'MULBERRY GROVE',
      'NASHVILLE',
      'NEW ATHENS',
      'NEW BADEN',
      'NEW MEMPHIS',
      'OALASKADALE',
      'O FALLON',
      'OKAWVILLE',
      'PERCY',
      'PIERRON',
      'PINCKNEYVILLE',
      'POCAHONTAS',
      'PRAIRIE DU ROCHER',
      'RED BUD',
      'RENAULT',
      'ROCKWOOD',
      'SAINT JACOB',
      'SAINT LIBORY',
      'SHATTUC',
      'SMITHBORO',
      'SMITHTON',
      'SPARTA',
      'STEELEVILLE',
      'SUMMERFIELD',
      'TILDEN',
      'TRENTON',
      'TROY',
      'VALMEYER',
      'WALSH',
      'WATERLOO',
      'QUINCY',
      'ADRIAN',
      'AUGUSTA',
      'BARRY',
      'BASCO',
      'BAYLIS',
      'BOWEN',
      'BURNSIDE',
      'CAMDEN',
      'CAMP POINT',
      'CARTHAGE',
      'CHAMBERSBURG',
      'CLAYTON',
      'COATSBURG',
      'COLCHESTER',
      'COLUSA',
      'DALLAS CITY',
      'ELVASTON',
      'FERRIS',
      'FOWLER',
      'GOLDEN',
      'GRIGGSVILLE',
      'HAMILTON',
      'HULL',
      'HUNTSVILLE',
      'KINDERHOOK',
      'LA PRAIRIE',
      'LIBERTY',
      'LIMA',
      'LORAINE',
      'MENDON',
      'MILTON',
      'MOUNT STERLING',
      'NAUVOO',
      'NEBO',
      'NEW CANTON',
      'NEW SALEM',
      'NIOTA',
      'PALOMA',
      'PAYSON',
      'PEARL',
      'PERRY',
      'PITTSFIELD',
      'PLAINVILLE',
      'PLEASANT HILL',
      'PLYMOUTH',
      'ROCKPORT',
      'SUTTER',
      'TENNESSEE',
      'TIMEWELL',
      'URSA',
      'VERSAILLES',
      'WARSAW',
      'WEST POINT',
      'EFFINGHAM',
      'ALLENDALE',
      'ALTAMONT',
      'ANNAPOLIS',
      'BEECHER CITY',
      'BIRDS',
      'BRIDGEPORT',
      'BROWNSTOWN',
      'CALHOUN',
      'CASEY',
      'CLAREMONT',
      'COWDEN',
      'DENNISON',
      'DIETERICH',
      'DUNDAS',
      'EDGEWOOD',
      'FLAT ROCK',
      'GREENUP',
      'HERRICK',
      'HIDALGO',
      'HUTSONVILLE',
      'INGRAHAM',
      'JANESVILLE',
      'JEWETT',
      'LALASKAEWOOD',
      'LAWRENCEVILLE',
      'LERNA',
      'MARSHALL',
      'MARTINSVILLE',
      'MASON',
      'MODE',
      'MONTROSE',
      'MOUNT ERIE',
      'NEOGA',
      'NEWTON',
      'OBLONG',
      'OLNEY',
      'PALESTINE',
      'PARKERSBURG',
      'ROBINSON',
      'SAINT ELMO',
      'SAINTE MARIE',
      'SAINT FRANCISVILLE',
      'SHUMWAY',
      'SIGEL',
      'STEWARDSON',
      'STOY',
      'STRASBURG',
      'SUMNER',
      'TEUTOPOLIS',
      'TOLEDO',
      'TRILLA',
      'VANDALIA',
      'WATSON',
      'WESTFIELD',
      'WEST LIBERTY',
      'WEST SALEM',
      'WEST UNION',
      'WEST YORK',
      'WHEELER',
      'WILLOW HILL',
      'YALE',
      'ARGENTA',
      'ASSUMPTION',
      'ATWATER',
      'BEASON',
      'BLUE MOUND',
      'BOODY',
      'BUFFALO',
      'BULPITT',
      'CHESTNUT',
      'CORNLAND',
      'DAWSON',
      'DECATUR',
      'DIVERNON',
      'EDINBURG',
      'ELWIN',
      'FARMERSVILLE',
      'FINDLAY',
      'FORSYTH',
      'GLENARM',
      'HARRISTOWN',
      'HARVEL',
      'ILLIOPOLIS',
      'KINCAID',
      'LALASKAE FORK',
      'LATHAM',
      'MACON',
      'MECHANICSBURG',
      'MORRISONVILLE',
      'MOUNT AUBURN',
      'MOUNT PULASKI',
      'MT ZION',
      'MOWEAQUA',
      'NIANTIC',
      'OALASKALEY',
      'OCONEE',
      'OREANA',
      'OWANECO',
      'PALMER',
      'PANA',
      'PAWNEE',
      'RAYMOND',
      'RIVERTON',
      'ROCHESTER',
      'SHELBYVILLE',
      'STONINGTON',
      'TAYLORVILLE',
      'TOVEY',
      'TOWER HILL',
      'WAGGONER',
      'WARRENSBURG',
      'ALEXANDER',
      'ALSEY',
      'ARENZVILLE',
      'ASHLAND',
      'ATHENS',
      'AUBURN',
      'BATH',
      'BEARDSTOWN',
      'BLUFFS',
      'BLUFF SPRINGS',
      'BROWNING',
      'CANTRALL',
      'CARLINVILLE',
      'CHANDLERVILLE',
      'CHAPIN',
      'CHATHAM',
      'CHESTERFIELD',
      'CONCORD',
      'EASTON',
      'ELKHART',
      'EMDEN',
      'FRANKLIN',
      'FREDERICK',
      'GIRARD',
      'GREENVIEW',
      'HARTSBURG',
      'HAVANA',
      'HETTICK',
      'JACKSONVILLE',
      'KILBOURNE',
      'LINCOLN',
      'LINCOLN\'S NEW SALEM',
      'LITERBERRY',
      'LOAMI',
      'LOWDER',
      'MANCHESTER',
      'MASON CITY',
      'MEREDOSIA',
      'MIDDLETOWN',
      'MODESTO',
      'MURRAYVILLE',
      'NEW BERLIN',
      'NEW HOLLAND',
      'NILWOOD',
      'OALASKAFORD',
      'PALMYRA',
      'PETERSBURG',
      'PLEASANT PLAINS',
      'RUSHVILLE',
      'SAN JOSE',
      'SCOTTVILLE',
      'SHERMAN',
      'SHIPMAN',
      'STANDARD CITY',
      'TALLULA',
      'THAYER',
      'VIRDEN',
      'VIRGINIA',
      'WAVERLY',
      'WILLIAMSVILLE',
      'WINCHESTER',
      'WOODSON',
      'SPRINGFIELD',
      'CENTRALIA',
      'HOYLETON',
      'ALASKAIN',
      'ALBION',
      'ALMA',
      'ASHLEY',
      'BARNHILL',
      'BELLE RIVE',
      'BELLMONT',
      'BENTON',
      'BLUFORD',
      'BONE GAP',
      'BONNIE',
      'BROUGHTON',
      'BROWNS',
      'BUCKNER',
      'BURNT PRAIRIE',
      'CARMI',
      'CHRISTOPHER',
      'CISNE',
      'CLAY CITY',
      'COELLO',
      'CROSSVILLE',
      'DAHLGREN',
      'DALE',
      'DIX',
      'DU BOIS',
      'DU QUOIN',
      'ELLERY',
      'EMMA',
      'ENFIELD',
      'EWING',
      'FAIRFIELD',
      'FARINA',
      'FLORA',
      'FRANKFORT HEIGHTS',
      'FREEMAN SPUR',
      'GEFF',
      'GOLDEN GATE',
      'GRAYVILLE',
      'HERALD',
      'INA',
      'IOLA',
      'IRVINGTON',
      'IUKA',
      'JOHNSONVILLE',
      'KEENES',
      'KEENSBURG',
      'KELL',
      'KINMUNDY',
      'LANCASTER',
      'LOGAN',
      'LOOGOOTEE',
      'LOUISVILLE',
      'MC LEANSBORO',
      'MACEDONIA',
      'MAUNIE',
      'MILL SHOALS',
      'MOUNT CARMEL',
      'MOUNT VERNON',
      'MULKEYTOWN',
      'NASON',
      'NEW HAVEN',
      'NOBLE',
      'NORRIS CITY',
      'ODIN',
      'OMAHA',
      'OPDYKE',
      'ORIENT',
      'PATOKA',
      'RADOM',
      'RICHVIEW',
      'RINARD',
      'SAILOR SPRINGS',
      'SAINT PETER',
      'SALEM',
      'SANDOVAL',
      'SCHELLER',
      'SESSER',
      'SHOBONIER',
      'SIMS',
      'SPRINGERTON',
      'TAMAROA',
      'TEXICO',
      'THOMPSONVILLE',
      'VALIER',
      'VERNON',
      'WALNUT HILL',
      'WALTONVILLE',
      'WAYNE CITY',
      'WEST FRANKFORT',
      'WHITTINGTON',
      'WOODLAWN',
      'XENIA',
      'CARBONDALE',
      'ALTO PASS',
      'ANNA',
      'AVA',
      'BELKNAP',
      'BOLES',
      'BROOKPORT',
      'BUNCOMBE',
      'CACHE',
      'CAIRO',
      'CAMBRIA',
      'CAMPBELL HILL',
      'CARRIER MILLS',
      'CARTERVILLE',
      'CAVE IN ROCK',
      'COBDEN',
      'COLP',
      'CREAL SPRINGS',
      'CYPRESS',
      'DE SOTO',
      'DONGOLA',
      'DOWELL',
      'EDDYVILLE',
      'ELDORADO',
      'ELIZABETHTOWN',
      'ELKVILLE',
      'ENERGY',
      'EQUALITY',
      'GALATIA',
      'GOLCONDA',
      'GOREVILLE',
      'GORHAM',
      'GRAND CHAIN',
      'GRAND TOWER',
      'GRANTSBURG',
      'HAMLETSBURG',
      'HARRISBURG',
      'HEROD',
      'HERRIN',
      'HURST',
      'JACOB',
      'JOHNSTON CITY',
      'JONESBORO',
      'JOPPA',
      'JUNCTION',
      'KARBERS RIDGE',
      'KARNALASKA',
      'MC CLURE',
      'MALASKAANDA',
      'MARION',
      'METROPOLIS',
      'MILLCREEK',
      'MILLER CITY',
      'MOUND CITY',
      'MOUNDS',
      'MUDDY',
      'MURPHYSBORO',
      'NEW BURNSIDE',
      'OLIVE BRANCH',
      'OLMSTED',
      'ORAVILLE',
      'OZARK',
      'PERKS',
      'PITTSBURG',
      'POMONA',
      'PULASKI',
      'RALEIGH',
      'RIDGWAY',
      'ROSICLARE',
      'ROYALTON',
      'SHAWNEETOWN',
      'SIMPSON',
      'STONEFORT',
      'TAMMS',
      'THEBES',
      'TUNNEL HILL',
      'ULLIN',
      'UNITY',
      'VERGENNES',
      'VIENNA',
      'VILLA RIDGE',
      'WILLISVILLE',
      'WOLF LALASKAE',
      'ZEIGLER'
    ]
  },
  IN: {
    code: 'IN',
    name: 'INDIANA',
    cities: [
      'ALEXANDRIA',
      'ANDERSON',
      'ARCADIA',
      'ATLANTA',
      'CARMEL',
      'CICERO',
      'COLFAX',
      'ELWOOD',
      'FISHERS',
      'FOREST',
      'FORTVILLE',
      'FRANKFORT',
      'FRANKTON',
      'GOLDSMITH',
      'HILLISBURG',
      'HOBBS',
      'INGALLS',
      'KEMPTON',
      'KIRKLIN',
      'LAPEL',
      'LEBANON',
      'MC CORDSVILLE',
      'MARKLEVILLE',
      'MICHIGANTOWN',
      'MULBERRY',
      'NOBLESVILLE',
      'ORESTES',
      'PENDLETON',
      'ROSSVILLE',
      'SEDALIA',
      'SHARPSVILLE',
      'SHERIDAN',
      'SUMMITVILLE',
      'THORNTOWN',
      'TIPTON',
      'WESTFIELD',
      'WHITESTOWN',
      'WINDFALL',
      'ZIONSVILLE',
      'ADVANCE',
      'AMO',
      'ARLINGTON',
      'BAINBRIDGE',
      'BARGERSVILLE',
      'BEECH GROVE',
      'BOGGSTOWN',
      'BROOKLYN',
      'BROWNSBURG',
      'CAMBY',
      'CARTERSBURG',
      'CARTHAGE',
      'CHARLOTTESVILLE',
      'CLAYTON',
      'CLOVERDALE',
      'COATESVILLE',
      'DANVILLE',
      'AVON',
      'EDINBURGH',
      'EMINENCE',
      'FAIRLAND',
      'FALMOUTH',
      'FILLMORE',
      'FINLY',
      'FOUNTAINTOWN',
      'FRANKLIN',
      'GLENWOOD',
      'GREENCASTLE',
      'GREENFIELD',
      'GREENWOOD',
      'GWYNNEVILLE',
      'HOMER',
      'JAMESTOWN',
      'KNIGHTSTOWN',
      'LIZTON',
      'MANILLA',
      'MARTINSVILLE',
      'MAXWELL',
      'MAYS',
      'MILROY',
      'MONROVIA',
      'MOORESVILLE',
      'MORGANTOWN',
      'MORRISTOWN',
      'NEEDHAM',
      'NEW PALESTINE',
      'NINEVEH',
      'NORTH SALEM',
      'PARAGON',
      'PITTSBORO',
      'PLAINFIELD',
      'PUTNAMVILLE',
      'REELSVILLE',
      'ROACHDALE',
      'RUSHVILLE',
      'RUSSELLVILLE',
      'SHELBYVILLE',
      'STILESVILLE',
      'TRAFALGAR',
      'WALDRON',
      'WEST NEWTON',
      'WHITELAND',
      'WILKINSON',
      'INDIANAPOLIS',
      'BEVERLY SHORES',
      'BOONE GROVE',
      'CEDAR LALASKAE',
      'CHESTERTON',
      'CROWN POINT',
      'DEMOTTE',
      'DYER',
      'EAST CHICAGO',
      'GRIFFITH',
      'HAMMOND',
      'MUNSTER',
      'HIGHLAND',
      'HANNA',
      'HEBRON',
      'HOBART',
      'KINGSBURY',
      'KINGSFORD HEIGHTS',
      'KOUTS',
      'LA CROSSE',
      'LALASKAE VILLAGE',
      'LA PORTE',
      'LEROY',
      'LOWELL',
      'MICHIGAN CITY',
      'MILL CREEK',
      'NORTH JUDSON',
      'PORTAGE',
      'ROLLING PRAIRIE',
      'ROSELAWN',
      'SAINT JOHN',
      'SAN PIERRE',
      'SCHERERVILLE',
      'SCHNEIDER',
      'SHELBY',
      'SUMAVA RESORTS',
      'TEFFT',
      'THAYER',
      'UNION MILLS',
      'VALPARAISO',
      'WANATAH',
      'WESTVILLE',
      'WHEATFIELD',
      'WHEELER',
      'WHITING',
      'GARY',
      'LALASKAE STATION',
      'MERRILLVILLE',
      'ARGOS',
      'ATWOOD',
      'BOURBON',
      'BREMEN',
      'BRISTOL',
      'BURKET',
      'CLAYPOOL',
      'CULVER',
      'DONALDSON',
      'ELKHART',
      'ETNA GREEN',
      'GOSHEN',
      'GRANGER',
      'GROVERTOWN',
      'HAMLET',
      'KNOX',
      'LALASKAEVILLE',
      'LAPAZ',
      'LEESBURG',
      'MENTONE',
      'MIDDLEBURY',
      'MILFORD',
      'MILLERSBURG',
      'MISHAWALASKAA',
      'NAPPANEE',
      'NEW CARLISLE',
      'NEW PARIS',
      'NORTH LIBERTY',
      'NORTH WEBSTER',
      'NOTRE DAME',
      'OSCEOLA',
      'PIERCETON',
      'PLYMOUTH',
      'SHIPSHEWANA',
      'SIDNEY',
      'SYRACUSE',
      'TIPPECANOE',
      'TOPEKA',
      'TYNER',
      'WALASKAARUSA',
      'WALKERTON',
      'WARSAW',
      'WINONA LALASKAE',
      'WYATT',
      'SOUTH BEND',
      'ALBION',
      'ANDREWS',
      'ANGOLA',
      'ARCOLA',
      'ASHLEY',
      'AUBURN',
      'AVILLA',
      'BERNE',
      'BIPPUS',
      'BLUFFTON',
      'BRIMFIELD',
      'BUTLER',
      'CHURUBUSCO',
      'COLUMBIA CITY',
      'CORUNNA',
      'CRAIGVILLE',
      'CROMWELL',
      'DECATUR',
      'FREMONT',
      'GARRETT',
      'GENEVA',
      'GRABILL',
      'HAMILTON',
      'HARLAN',
      'HOAGLAND',
      'HOWE',
      'HUDSON',
      'HUNTERTOWN',
      'HUNTINGTON',
      'KENDALLVILLE',
      'KEYSTONE',
      'KIMMELL',
      'LAGRANGE',
      'LAOTTO',
      'LARWILL',
      'LEO',
      'LIBERTY CENTER',
      'LIGONIER',
      'LINN GROVE',
      'MARKLE',
      'MONGO',
      'MONROE',
      'MONROEVILLE',
      'NEW HAVEN',
      'ORLAND',
      'OSSIAN',
      'PETROLEUM',
      'PLEASANT LALASKAE',
      'PLEASANT MILLS',
      'PONETO',
      'PREBLE',
      'ROANOKE',
      'ROME CITY',
      'SAINT JOE',
      'SOUTH MILFORD',
      'SOUTH WHITLEY',
      'SPENCERVILLE',
      'STROH',
      'UNIONDALE',
      'WARREN',
      'WATERLOO',
      'WAWALASKAA',
      'WOLCOTTVILLE',
      'WOLFLALASKAE',
      'WOODBURN',
      'YODER',
      'ZANESVILLE',
      'FORT WAYNE',
      'KOKOMO',
      'ALASKARON',
      'AMBOY',
      'ATHENS',
      'BRINGHURST',
      'BUNKER HILL',
      'BURLINGTON',
      'BURROWS',
      'CAMDEN',
      'CONVERSE',
      'CUTLER',
      'DEEDSVILLE',
      'DELONG',
      'DELPHI',
      'DENVER',
      'FAIRMOUNT',
      'FLORA',
      'FOWLERTON',
      'FULTON',
      'GALVESTON',
      'GAS CITY',
      'GRASS CREEK',
      'GREENTOWN',
      'HEMLOCK',
      'JONESBORO',
      'KEWANNA',
      'LA FONTAINE',
      'LAGRO',
      'LALASKAE CICOTT',
      'LALASKAETON',
      'LEITERS FORD',
      'LIBERTY MILLS',
      'LOGANSPORT',
      'LUCERNE',
      'MACY',
      'MARION',
      'MATTHEWS',
      'MEXICO',
      'MIAMI',
      'MONTEREY',
      'NEW WAVERLY',
      'NORTH MANCHESTER',
      'OALASKAFORD',
      'ONWARD',
      'ORA',
      'PERU',
      'GRISSOM ARB',
      'ROANN',
      'ROCHESTER',
      'ROCKFIELD',
      'ROYAL CENTER',
      'RUSSIAVILLE',
      'SERVIA',
      'SILVER LALASKAE',
      'SOMERSET',
      'STAR CITY',
      'SWAYZEE',
      'SWEETSER',
      'TWELVE MILE',
      'UPLAND',
      'URBANA',
      'VAN BUREN',
      'WABASH',
      'WALTON',
      'WEST MIDDLETON',
      'WINAMAC',
      'YOUNG AMERICA',
      'AURORA',
      'WEST COLLEGE CORNER',
      'BATESVILLE',
      'BATH',
      'BENNINGTON',
      'BROOKVILLE',
      'CEDAR GROVE',
      'CROSS PLAINS',
      'DILLSBORO',
      'EAST ENTERPRISE',
      'FLORENCE',
      'FRIENDSHIP',
      'GUILFORD',
      'HOLTON',
      'LAUREL',
      'LAWRENCEBURG',
      'METAMORA',
      'MILAN',
      'MOORES HILL',
      'MORRIS',
      'NAPOLEON',
      'NEW TRENTON',
      'OLDENBURG',
      'OSGOOD',
      'PATRIOT',
      'PIERCEVILLE',
      'RISING SUN',
      'SUNMAN',
      'VERSAILLES',
      'VEVAY',
      'WEST HARRISON',
      'AUSTIN',
      'BETHLEHEM',
      'BORDEN',
      'BRADFORD',
      'CAMPBELLSBURG',
      'CENTRAL',
      'CHARLESTOWN',
      'CORYDON',
      'CRANDALL',
      'DEPAUW',
      'ECKERTY',
      'ELIZABETH',
      'ENGLISH',
      'FLOYDS KNOBS',
      'FREDERICKSBURG',
      'GEORGETOWN',
      'GRANTSBURG',
      'GREENVILLE',
      'HARDINSBURG',
      'HENRYVILLE',
      'CLARKSVILLE',
      'JEFFERSONVILLE',
      'LACONIA',
      'LANESVILLE',
      'LEAVENWORTH',
      'LEXINGTON',
      'LITTLE YORK',
      'MARENGO',
      'MARYSVILLE',
      'MAUCKPORT',
      'MEMPHIS',
      'MILLTOWN',
      'MOUNT SAINT FRANCIS',
      'NABB',
      'NEW ALBANY',
      'NEW MIDDLETOWN',
      'NEW SALISBURY',
      'NEW WASHINGTON',
      'OTISCO',
      'PALMYRA',
      'PEKIN',
      'RAMSEY',
      'SALEM',
      'SCOTTSBURG',
      'SELLERSBURG',
      'SULPHUR',
      'TASWELL',
      'UNDERWOOD',
      'COLUMBUS',
      'BROWNSTOWN',
      'BUTLERVILLE',
      'CANAAN',
      'CLARKSBURG',
      'CLIFFORD',
      'COMMISKEY',
      'CORTLAND',
      'CROTHERSVILLE',
      'DEPUTY',
      'DUPONT',
      'ELIZABETHTOWN',
      'FLAT ROCK',
      'FREETOWN',
      'GRAMMER',
      'GREENSBURG',
      'HANOVER',
      'HARTSVILLE',
      'HAYDEN',
      'HOPE',
      'JONESVILLE',
      'KURTZ',
      'MADISON',
      'MEDORA',
      'MILLHOUSEN',
      'NEBRASKA',
      'NEW POINT',
      'NORMAN',
      'NORTH VERNON',
      'PARIS CROSSING',
      'SAINT PAUL',
      'SCIPIO',
      'SEYMOUR',
      'TAYLORSVILLE',
      'VALLONIA',
      'VERNON',
      'WESTPORT',
      'MUNCIE',
      'ALBANY',
      'BENTONVILLE',
      'BOSTON',
      'BROWNSVILLE',
      'BRYANT',
      'CAMBRIDGE CITY',
      'CENTERVILLE',
      'CONNERSVILLE',
      'DALEVILLE',
      'DUBLIN',
      'DUNKIRK',
      'DUNREITH',
      'EATON',
      'ECONOMY',
      'FARMLAND',
      'FOUNTAIN CITY',
      'GASTON',
      'GREENSBORO',
      'GREENS FORK',
      'HAGERSTOWN',
      'HARTFORD CITY',
      'KENNARD',
      'LEWISVILLE',
      'LIBERTY',
      'LOSANTVILLE',
      'LYNN',
      'MIDDLETOWN',
      'MILTON',
      'MODOC',
      'MONTPELIER',
      'MOORELAND',
      'MOUNT SUMMIT',
      'NEW CASTLE',
      'NEW LISBON',
      'OALASKAVILLE',
      'PARKER CITY',
      'PENNVILLE',
      'PERSHING',
      'PORTLAND',
      'REDKEY',
      'RICHMOND',
      'RIDGEVILLE',
      'SALAMONIA',
      'SARATOGA',
      'SELMA',
      'SHIRLEY',
      'SPICELAND',
      'SPRINGPORT',
      'STRAUGHN',
      'SULPHUR SPRINGS',
      'UNION CITY',
      'WEBSTER',
      'WILLIAMSBURG',
      'WINCHESTER',
      'YORKTOWN',
      'BLOOMINGTON',
      'AVOCA',
      'BEDFORD',
      'BLOOMFIELD',
      'CLEAR CREEK',
      'COAL CITY',
      'ELLETTSVILLE',
      'FORT RITNER',
      'FREEDOM',
      'FRENCH LICK',
      'GOSPORT',
      'HARRODSBURG',
      'HELMSBURG',
      'HELTONVILLE',
      'HURON',
      'JASONVILLE',
      'KOLEEN',
      'LINTON',
      'LYONS',
      'MIDLAND',
      'MITCHELL',
      'NASHVILLE',
      'NEWBERRY',
      'OOLITIC',
      'ORLEANS',
      'OWENSBURG',
      'PAOLI',
      'PATRICKSBURG',
      'QUINCY',
      'SCOTLAND',
      'SMITHVILLE',
      'SOLSBERRY',
      'SPENCER',
      'SPRINGVILLE',
      'STANFORD',
      'STINESVILLE',
      'SWITZ CITY',
      'TUNNELTON',
      'UNIONVILLE',
      'WEST BADEN SPRINGS',
      'WILLIAMS',
      'WORTHINGTON',
      'WASHINGTON',
      'BICKNELL',
      'BIRDSEYE',
      'BRANCHVILLE',
      'BRISTOW',
      'BRUCEVILLE',
      'CANNELBURG',
      'CANNELTON',
      'CELESTINE',
      'CRANE',
      'DALE',
      'DECKER',
      'DERBY',
      'DUBOIS',
      'EDWARDSPORT',
      'ELNORA',
      'EVANSTON',
      'FERDINAND',
      'FREELANDVILLE',
      'FULDA',
      'GENTRYVILLE',
      'HOLLAND',
      'HUNTINGBURG',
      'IRELAND',
      'JASPER',
      'LAMAR',
      'LEOPOLD',
      'LINCOLN CITY',
      'LOOGOOTEE',
      'MARIAH HILL',
      'MONROE CITY',
      'MONTGOMERY',
      'OALASKATOWN',
      'ODON',
      'OTWELL',
      'PETERSBURG',
      'PLAINVILLE',
      'RAGSDALE',
      'ROME',
      'SAINT ANTHONY',
      'SAINT CROIX',
      'SAINT MEINRAD',
      'SANDBORN',
      'SANTA CLAUS',
      'SCHNELLVILLE',
      'SHOALS',
      'SPURGEON',
      'STENDAL',
      'TELL CITY',
      'TROY',
      'VELPEN',
      'VINCENNES',
      'WESTPHALIA',
      'WHEATLAND',
      'WINSLOW',
      'BOONVILLE',
      'CHANDLER',
      'CHRISNEY',
      'CYNTHIANA',
      'ELBERFELD',
      'FOLSOMVILLE',
      'GRANDVIEW',
      'GRIFFIN',
      'HATFIELD',
      'INGLEFIELD',
      'LYNNVILLE',
      'MOUNT VERNON',
      'NEWBURGH',
      'NEW HARMONY',
      'POSEYVILLE',
      'RICHLAND',
      'ROCKPORT',
      'TENNYSON',
      'WADESVILLE',
      'HAUBSTADT',
      'HAZLETON',
      'BUCKSKIN',
      'FORT BRANCH',
      'FRANCISCO',
      'MACKEY',
      'OALASKALAND CITY',
      'OWENSVILLE',
      'PATOKA',
      'PRINCETON',
      'SOMERVILLE',
      'EVANSVILLE',
      'TERRE HAUTE',
      'BELLMORE',
      'BLANFORD',
      'BLOOMINGDALE',
      'BOWLING GREEN',
      'BRAZIL',
      'BRIDGETON',
      'CARBON',
      'CARLISLE',
      'CENTERPOINT',
      'CLAY CITY',
      'CLINTON',
      'COALMONT',
      'CORY',
      'DANA',
      'DUGGER',
      'FAIRBANKS',
      'FARMERSBURG',
      'FONTANET',
      'GRAYSVILLE',
      'HARMONY',
      'HILLSDALE',
      'HYMERA',
      'JUDSON',
      'KNIGHTSVILLE',
      'LEWIS',
      'MARSHALL',
      'MECCA',
      'MEROM',
      'MONTEZUMA',
      'NEW GOSHEN',
      'NEW LEBANON',
      'PAXTON',
      'PIMENTO',
      'POLAND',
      'PRAIRIE CREEK',
      'PRAIRIETON',
      'RILEY',
      'ROCKVILLE',
      'ROSEDALE',
      'SAINT BERNICE',
      'SAINT MARY OF THE WOODS',
      'SEELYVILLE',
      'SHELBURN',
      'SHEPARDSVILLE',
      'STAUNTON',
      'SULLIVAN',
      'UNIVERSAL',
      'WEST TERRE HAUTE',
      'LAFAYETTE',
      'WEST LAFAYETTE',
      'ALAMO',
      'AMBIA',
      'ATTICA',
      'BATTLE GROUND',
      'BOSWELL',
      'BROOK',
      'BROOKSTON',
      'BUCK CREEK',
      'BUFFALO',
      'BURNETTSVILLE',
      'CAYUGA',
      'CHALMERS',
      'CLARKS HILL',
      'COVINGTON',
      'CRAWFORDSVILLE',
      'DARLINGTON',
      'DAYTON',
      'EARL PARK',
      'FAIR OALASKAS',
      'FOWLER',
      'FRANCESVILLE',
      'GOODLAND',
      'HILLSBORO',
      'IDAVILLE',
      'KENTLAND',
      'KINGMAN',
      'LADOGA',
      'LINDEN',
      'MEDARYVILLE',
      'MELLOTT',
      'MONON',
      'MONTICELLO',
      'MONTMORENCI',
      'MOROCCO',
      'MOUNT AYR',
      'NEW MARKET',
      'NEWPORT',
      'NEW RICHMOND',
      'NEW ROSS',
      'NEWTOWN',
      'OTTERBEIN',
      'OXFORD',
      'PERRYSVILLE',
      'PINE VILLAGE',
      'REMINGTON',
      'RENSSELAER',
      'REYNOLDS',
      'ROMNEY',
      'STATE LINE',
      'STOCKWELL',
      'TALBOT',
      'TEMPLETON',
      'VEEDERSBURG',
      'WALLACE',
      'WAVELAND',
      'WAYNETOWN',
      'WEST LEBANON',
      'WESTPOINT',
      'WILLIAMSPORT',
      'WINGATE',
      'WOLCOTT',
      'YEOMAN'
    ]
  },
  KS: {
    code: 'KS',
    name: 'KANSAS',
    cities: [
      'ATCHISON',
      'BALDWIN CITY',
      'BASEHOR',
      'BENDENA',
      'BLUE MOUND',
      'BONNER SPRINGS',
      'BUCYRUS',
      'CENTERVILLE',
      'COLONY',
      'CUMMINGS',
      'DENTON',
      'DE SOTO',
      'CLEARVIEW CITY',
      'EASTON',
      'EDGERTON',
      'EFFINGHAM',
      'ELWOOD',
      'EUDORA',
      'FONTANA',
      'FORT LEAVENWORTH',
      'GARDNER',
      'NEW CENTURY',
      'GARNETT',
      'GREELEY',
      'HIGHLAND',
      'HILLSDALE',
      'KINCAID',
      'LA CYGNE',
      'LANCASTER',
      'LANE',
      'LANSING',
      'LAWRENCE',
      'LEAVENWORTH',
      'LECOMPTON',
      'OLATHE',
      'LINWOOD',
      'LOUISBURG',
      'MC LOUTH',
      'MOUND CITY',
      'MUSCOTAH',
      'NORTONVILLE',
      'OSAWATOMIE',
      'OSKALOOSA',
      'OTTAWA',
      'OZAWKIE',
      'PAOLA',
      'PARKER',
      'PERRY',
      'PLEASANTON',
      'POMONA',
      'POTTER',
      'PRINCETON',
      'RANTOUL',
      'RICHMOND',
      'SPRING HILL',
      'STILWELL',
      'TONGANOXIE',
      'TROY',
      'VALLEY FALLS',
      'WATHENA',
      'WELDA',
      'WELLSVILLE',
      'WESTPHALIA',
      'WHITE CLOUD',
      'WILLIAMSBURG',
      'WINCHESTER',
      'KANSAS CITY',
      'EDWARDSVILLE',
      'SHAWNEE MISSION',
      'ALMA',
      'AUBURN',
      'AXTELL',
      'BAILEYVILLE',
      'BEATTIE',
      'BELVUE',
      'BERN',
      'BERRYTON',
      'BLUE RAPIDS',
      'BREMEN',
      'BURLINGAME',
      'CARBONDALE',
      'CENTRALIA',
      'CIRCLEVILLE',
      'CORNING',
      'DELIA',
      'DENISON',
      'DOVER',
      'EMMETT',
      'ESKRIDGE',
      'EVEREST',
      'FAIRVIEW',
      'FOSTORIA',
      'FRANKFORT',
      'GOFF',
      'GRANTVILLE',
      'HARVEYVILLE',
      'HAVENSVILLE',
      'HIAWATHA',
      'HOLTON',
      'HOME',
      'HORTON',
      'HOYT',
      'JUNCTION CITY',
      'FORT RILEY',
      'LEONARDVILLE',
      'LOUISVILLE',
      'LYNDON',
      'MC FARLAND',
      'MANHATTAN',
      'MAPLE HILL',
      'MARYSVILLE',
      'MAYETTA',
      'MELVERN',
      'MERIDEN',
      'MILFORD',
      'MORRILL',
      'NETAWALASKAA',
      'OGDEN',
      'OKETO',
      'OLSBURG',
      'ONAGA',
      'ONEIDA',
      'OSAGE CITY',
      'OVERBROOK',
      'PAXICO',
      'POWHATTAN',
      'QUENEMO',
      'RILEY',
      'ROBINSON',
      'ROSSVILLE',
      'SABETHA',
      'SAINT GEORGE',
      'SAINT MARYS',
      'SCRANTON',
      'SENECA',
      'SILVER LALASKAE',
      'SOLDIER',
      'SUMMERFIELD',
      'TECUMSEH',
      'VASSAR',
      'VERMILLION',
      'WALASKAARUSA',
      'WAMEGO',
      'WATERVILLE',
      'WESTMORELAND',
      'WETMORE',
      'WHEATON',
      'WHITING',
      'RANDOLPH',
      'TOPEKA',
      'FORT SCOTT',
      'ALTOONA',
      'ARCADIA',
      'ARMA',
      'BAXTER SPRINGS',
      'BENEDICT',
      'BRONSON',
      'BUFFALO',
      'CHANUTE',
      'CHEROKEE',
      'COLUMBUS',
      'COYVILLE',
      'CRESTLINE',
      'ELSMORE',
      'ERIE',
      'FARLINGTON',
      'FRANKLIN',
      'FREDONIA',
      'FULTON',
      'GALENA',
      'GALESBURG',
      'GARLAND',
      'GAS',
      'GIRARD',
      'HEPLER',
      'HUMBOLDT',
      'IOLA',
      'LA HARPE',
      'MC CUNE',
      'MAPLETON',
      'MORAN',
      'MULBERRY',
      'NEODESHA',
      'NEOSHO FALLS',
      'NEW ALBANY',
      'OPOLIS',
      'PIQUA',
      'PITTSBURG',
      'FRONTENAC',
      'PRESCOTT',
      'REDFIELD',
      'RIVERTON',
      'SAINT PAUL',
      'SAVONBURG',
      'SCAMMON',
      'STARK',
      'THAYER',
      'TORONTO',
      'TREECE',
      'UNIONTOWN',
      'WALNUT',
      'WEIR',
      'WEST MINERAL',
      'YATES CENTER',
      'EMPORIA',
      'ADMIRE',
      'ALLEN',
      'ALTA VISTA',
      'AMERICUS',
      'BURDICK',
      'BURLINGTON',
      'BURNS',
      'CASSODAY',
      'CEDAR POINT',
      'COTTONWOOD FALLS',
      'COUNCIL GROVE',
      'DWIGHT',
      'ELMDALE',
      'FLORENCE',
      'GRIDLEY',
      'HAMILTON',
      'HARTFORD',
      'LAMONT',
      'LEBO',
      'LE ROY',
      'LINCOLNVILLE',
      'LOST SPRINGS',
      'MADISON',
      'MARION',
      'MATFIELD GREEN',
      'NEAL',
      'NEOSHO RAPIDS',
      'OLPE',
      'PEABODY',
      'READING',
      'STRONG CITY',
      'VIRGIL',
      'WAVERLY',
      'WHITE CITY',
      'WILSEY',
      'CONCORDIA',
      'AGENDA',
      'ATHOL',
      'BARNES',
      'BELLEVILLE',
      'BURR OALASKA',
      'CLIFTON',
      'CLYDE',
      'COURTLAND',
      'CUBA',
      'ESBON',
      'FORMOSO',
      'GREENLEAF',
      'HADDAM',
      'HANOVER',
      'HOLLENBERG',
      'JAMESTOWN',
      'JEWELL',
      'KENSINGTON',
      'LEBANON',
      'LINN',
      'MAHASKA',
      'MANKATO',
      'MORROWVILLE',
      'MUNDEN',
      'NARKA',
      'NORWAY',
      'PALMER',
      'RANDALL',
      'REPUBLIC',
      'SCANDIA',
      'SMITH CENTER',
      'WASHINGTON',
      'WEBBER',
      'ANDALE',
      'ANDOVER',
      'ANTHONY',
      'ARGONIA',
      'ARKANSAS CITY',
      'ATLANTA',
      'ATTICA',
      'AUGUSTA',
      'BEAUMONT',
      'BELLE PLAINE',
      'BENTLEY',
      'BENTON',
      'BLUFF CITY',
      'BURDEN',
      'BURRTON',
      'BYERS',
      'CALDWELL',
      'CAMBRIDGE',
      'CEDAR VALE',
      'CHENEY',
      'CLEARWATER',
      'COATS',
      'COLDWATER',
      'COLWICH',
      'CONWAY SPRINGS',
      'CUNNINGHAM',
      'DANVILLE',
      'DERBY',
      'DEXTER',
      'DOUGLASS',
      'ELBING',
      'EL DORADO',
      'EUREKA',
      'FALL RIVER',
      'FREEPORT',
      'GARDEN PLAIN',
      'GEUDA SPRINGS',
      'GODDARD',
      'GOESSEL',
      'GREENSBURG',
      'GREENWICH',
      'HALSTEAD',
      'HARDTNER',
      'HARPER',
      'HAVILAND',
      'HAYSVILLE',
      'HAZELTON',
      'HESSTON',
      'HILLSBORO',
      'ISABEL',
      'IUKA',
      'KECHI',
      'KINGMAN',
      'KIOWA',
      'LALASKAE CITY',
      'LATHAM',
      'LEHIGH',
      'LEON',
      'MAIZE',
      'MAPLE CITY',
      'MAYFIELD',
      'MEDICINE LODGE',
      'MILAN',
      'MILTON',
      'MOUNDRIDGE',
      'MOUNT HOPE',
      'MULLINVILLE',
      'MULVANE',
      'MURDOCK',
      'NASHVILLE',
      'NEWTON',
      'NORTH NEWTON',
      'NORWICH',
      'OXFORD',
      'PECK',
      'PIEDMONT',
      'POTWIN',
      'PRATT',
      'PROTECTION',
      'RAGO',
      'ROCK',
      'ROSALIA',
      'ROSE HILL',
      'SAWYER',
      'SEDGWICK',
      'SEVERY',
      'SHARON',
      'SOUTH HAVEN',
      'SPIVEY',
      'SUN CITY',
      'TOWANDA',
      'UDALL',
      'VALLEY CENTER',
      'VIOLA',
      'WALDRON',
      'WALTON',
      'WELLINGTON',
      'WHITEWATER',
      'WILMORE',
      'WINFIELD',
      'ZENDA',
      'WICHITA',
      'MCCONNELL AFB',
      'INDEPENDENCE',
      'ALTAMONT',
      'BARTLETT',
      'CANEY',
      'CHAUTAUQUA',
      'CHERRYVALE',
      'CHETOPA',
      'COFFEYVILLE',
      'DEARING',
      'DENNIS',
      'EDNA',
      'ELK CITY',
      'ELK FALLS',
      'GRENOLA',
      'HAVANA',
      'HOWARD',
      'LIBERTY',
      'LONGTON',
      'MOLINE',
      'MOUND VALLEY',
      'NIOTAZE',
      'OSWEGO',
      'PARSONS',
      'PERU',
      'SEDAN',
      'SYCAMORE',
      'TYRO',
      'SALINA',
      'ABILENE',
      'ASSARIA',
      'AURORA',
      'BARNARD',
      'BELOIT',
      'BENNINGTON',
      'BEVERLY',
      'BROOKVILLE',
      'BUSHTON',
      'CANTON',
      'CAWKER CITY',
      'CHAPMAN',
      'CLAY CENTER',
      'DELPHOS',
      'DOWNS',
      'DURHAM',
      'ELLSWORTH',
      'ENTERPRISE',
      'FALUN',
      'GALVA',
      'GENESEO',
      'GLASCO',
      'GLEN ELDER',
      'GREEN',
      'GYPSUM',
      'HERINGTON',
      'HOLYROOD',
      'HOPE',
      'HUNTER',
      'KANOPOLIS',
      'LINCOLN',
      'LINDSBORG',
      'LITTLE RIVER',
      'LONGFORD',
      'LORRAINE',
      'MCPHERSON',
      'MARQUETTE',
      'MILTONVALE',
      'MINNEAPOLIS',
      'MORGANVILLE',
      'NEW CAMBRIA',
      'OSBORNE',
      'PORTIS',
      'RAMONA',
      'ROXBURY',
      'SIMPSON',
      'SOLOMON',
      'SYLVAN GROVE',
      'TALMAGE',
      'TAMPA',
      'TESCOTT',
      'TIPTON',
      'WALASKAEFIELD',
      'WILSON',
      'WINDOM',
      'WOODBINE',
      'HUTCHINSON',
      'SOUTH HUTCHINSON',
      'ABBYVILLE',
      'ALBERT',
      'ALDEN',
      'ALEXANDER',
      'ARLINGTON',
      'ARNOLD',
      'BAZINE',
      'BEELER',
      'BELPRE',
      'BISON',
      'BROWNELL',
      'BUHLER',
      'BURDETT',
      'CHASE',
      'CLAFLIN',
      'ELLINWOOD',
      'GARFIELD',
      'GREAT BEND',
      'HAVEN',
      'HOISINGTON',
      'HUDSON',
      'INMAN',
      'KINSLEY',
      'LA CROSSE',
      'LARNED',
      'LEWIS',
      'LIEBENTHAL',
      'LYONS',
      'MC CRACKEN',
      'MACKSVILLE',
      'NEKOMA',
      'NESS CITY',
      'NICKERSON',
      'OFFERLE',
      'OLMITZ',
      'OTIS',
      'PARTRIDGE',
      'PAWNEE ROCK',
      'PLEVNA',
      'PRETTY PRAIRIE',
      'RANSOM',
      'RAYMOND',
      'ROZEL',
      'RUSH CENTER',
      'SAINT JOHN',
      'STAFFORD',
      'STERLING',
      'SYLVIA',
      'TURON',
      'UTICA',
      'YODER',
      'HAYS',
      'AGRA',
      'ALMENA',
      'ALTON',
      'BOGUE',
      'BUNKER HILL',
      'CATHARINE',
      'CEDAR',
      'CLAYTON',
      'COLLYER',
      'DAMAR',
      'DORRANCE',
      'DRESDEN',
      'ELLIS',
      'GAYLORD',
      'GLADE',
      'GORHAM',
      'HILL CITY',
      'JENNINGS',
      'KIRWIN',
      'LENORA',
      'LOGAN',
      'LONG ISLAND',
      'LUCAS',
      'LURAY',
      'MORLAND',
      'NATOMA',
      'NORCATUR',
      'NORTON',
      'OGALLAH',
      'PALCO',
      'PARADISE',
      'PENOKEE',
      'PFEIFER',
      'PHILLIPSBURG',
      'PLAINVILLE',
      'PRAIRIE VIEW',
      'RUSSELL',
      'SCHOENCHEN',
      'STOCKTON',
      'STUTTGART',
      'VICTORIA',
      'WA KEENEY',
      'WALDO',
      'WALKER',
      'WOODSTON',
      'COLBY',
      'ATWOOD',
      'BIRD CITY',
      'BREWSTER',
      'EDSON',
      'GEM',
      'GOODLAND',
      'GOVE',
      'GRAINFIELD',
      'GRINNELL',
      'HERNDON',
      'HOXIE',
      'KANORADO',
      'LEVANT',
      'LUDELL',
      'MC DONALD',
      'MONUMENT',
      'OALASKALEY',
      'OBERLIN',
      'PARK',
      'QUINTER',
      'REXFORD',
      'SAINT FRANCIS',
      'SELDEN',
      'SHARON SPRINGS',
      'WALLACE',
      'WESKAN',
      'WINONA',
      'DODGE CITY',
      'ASHLAND',
      'BUCKLIN',
      'CIMARRON',
      'COOLIDGE',
      'COPELAND',
      'DEERFIELD',
      'DIGHTON',
      'ENGLEWOOD',
      'ENSIGN',
      'FORD',
      'FOWLER',
      'GARDEN CITY',
      'HANSTON',
      'HEALY',
      'HOLCOMB',
      'INGALLS',
      'JETMORE',
      'JOHNSON',
      'KENDALL',
      'KISMET',
      'LALASKAIN',
      'LEOTI',
      'MANTER',
      'MARIENTHAL',
      'MEADE',
      'MINNEOLA',
      'MONTEZUMA',
      'PIERCEVILLE',
      'PLAINS',
      'SATANTA',
      'SCOTT CITY',
      'SPEARVILLE',
      'SUBLETTE',
      'SYRACUSE',
      'TRIBUNE',
      'ULYSSES',
      'WRIGHT',
      'LIBERAL',
      'ELKHART',
      'HUGOTON',
      'MOSCOW',
      'RICHFIELD',
      'ROLLA'
    ]
  },
  KY: {
    code: 'KY',
    name: 'KENTUCKY',
    cities: [
      'BAGDAD',
      'BARDSTOWN',
      'BEDFORD',
      'BETHLEHEM',
      'BLOOMFIELD',
      'BRADFORDSVILLE',
      'BUCKNER',
      'CAMPBELLSBURG',
      'CHAPLIN',
      'COXS CREEK',
      'CRESTWOOD',
      'EASTWOOD',
      'EMINENCE',
      'FAIRFIELD',
      'FINCHVILLE',
      'FISHERVILLE',
      'GLENVIEW',
      'GOSHEN',
      'HARRODS CREEK',
      'LA GRANGE',
      'LEBANON',
      'LOCKPORT',
      'LORETTO',
      'MACKVILLE',
      'MASONIC HOME',
      'MILTON',
      'MOUNT EDEN',
      'MOUNT WASHINGTON',
      'NAZARETH',
      'NERINX',
      'NEW CASTLE',
      'NEW HAVEN',
      'NEW HOPE',
      'PENDLETON',
      'PEWEE VALLEY',
      'PLEASUREVILLE',
      'PORT ROYAL',
      'PROSPECT',
      'RAYWICK',
      'SAINT CATHARINE',
      'SAINT FRANCIS',
      'SAINT MARY',
      'SHELBYVILLE',
      'SIMPSONVILLE',
      'SMITHFIELD',
      'SPRINGFIELD',
      'SULPHUR',
      'TAYLORSVILLE',
      'TURNERS STATION',
      'WADDY',
      'WESTPORT',
      'WILLISBURG',
      'BATTLETOWN',
      'BOSTON',
      'BRANDENBURG',
      'BROOKS',
      'CLERMONT',
      'CLOVERPORT',
      'CUSTER',
      'EKRON',
      'FAIRDALE',
      'FALLS OF ROUGH',
      'FORT KNOX',
      'HILLVIEW',
      'GARFIELD',
      'GUSTON',
      'HARDINSBURG',
      'HARNED',
      'HUDSON',
      'IRVINGTON',
      'LEBANON JUNCTION',
      'MC DANIELS',
      'MC QUADY',
      'MULDRAUGH',
      'PAYNEVILLE',
      'RADCLIFF',
      'RHODELIA',
      'RINEYVILLE',
      'SE REE',
      'SHEPHERDSVILLE',
      'STEPHENSPORT',
      'UNION STAR',
      'VINE GROVE',
      'WEBSTER',
      'WEST POINT',
      'WESTVIEW',
      'LOUISVILLE',
      'BURGIN',
      'CARLISLE',
      'CLAY CITY',
      'CLEARFIELD',
      'DENNISTON',
      'ELLIOTTVILLE',
      'FARMERS',
      'FORD',
      'FRENCHBURG',
      'GEORGETOWN',
      'GRAVEL SWITCH',
      'HALDEMAN',
      'HARRODSBURG',
      'HOPE',
      'IRVINE',
      'JEFFERSONVILLE',
      'KEENE',
      'NICHOLASVILLE',
      'LAWRENCEBURG',
      'MEANS',
      'MIDWAY',
      'MILLERSBURG',
      'MOOREFIELD',
      'MOREHEAD',
      'MOUNT STERLING',
      'NEW LIBERTY',
      'NORTH MIDDLETOWN',
      'OLYMPIA',
      'OWENTON',
      'OWINGSVILLE',
      'PARIS',
      'PERRY PARK',
      'PRESTON',
      'SADIEVILLE',
      'SALT LICK',
      'SALVISA',
      'SHARPSBURG',
      'SLADE',
      'STAMPING GROUND',
      'STANTON',
      'VERSAILLES',
      'WACO',
      'WELLINGTON',
      'WILMORE',
      'WINCHESTER',
      'ANNVILLE',
      'BEREA',
      'BIGHILL',
      'BRODHEAD',
      'BRYANTSVILLE',
      'CRAB ORCHARD',
      'DABOLT',
      'DANVILLE',
      'GRAY HAWK',
      'HUSTONVILLE',
      'JUNCTION CITY',
      'KINGS MOUNTAIN',
      'LANCASTER',
      'LIVINGSTON',
      'MC KEE',
      'MC KINNEY',
      'MITCHELLSBURG',
      'MOUNT VERNON',
      'ORLANDO',
      'PAINT LICK',
      'PARKSVILLE',
      'PEOPLES',
      'PERRYVILLE',
      'RAVENNA',
      'RENFRO VALLEY',
      'RICHMOND',
      'SANDGAP',
      'STANFORD',
      'TYNER',
      'WANETA',
      'WAYNESBURG',
      'WILDIE',
      'WINSTON',
      'LEXINGTON',
      'FRANKFORT',
      'CORBIN',
      'BUSH',
      'EAST BERNSTADT',
      'EMLYN',
      'GRAY',
      'KEAVY',
      'LILY',
      'LONDON',
      'MARYDELL',
      'NEVISDALE',
      'PITTSBURG',
      'ROCKHOLDS',
      'SILER',
      'WILLIAMSBURG',
      'WOODBINE',
      'AGES BROOKSIDE',
      'ASHER',
      'BAXTER',
      'BENHAM',
      'BIG LAUREL',
      'BLEDSOE',
      'CALVIN',
      'CAWOOD',
      'CHAPPELL',
      'COALGOOD',
      'COLDIRON',
      'CRANKS',
      'CUMBERLAND',
      'DAYHOIT',
      'EOLIA',
      'ESSIE',
      'EVARTS',
      'GRAYS KNOB',
      'GULSTON',
      'HARLAN',
      'HELTON',
      'HOLMES MILL',
      'HOSKINSTON',
      'HULEN',
      'KENVIR',
      'LEJUNIOR',
      'LOYALL',
      'LYNCH',
      'MIRACLE',
      'MOZELLE',
      'PARTRIDGE',
      'PATHFORK',
      'PUTNEY',
      'STINNETT',
      'TOTZ',
      'WALLINS CREEK',
      'WARBRANCH',
      'ARJAY',
      'ARTEMUS',
      'BARBOURVILLE',
      'BEVERLY',
      'BIG CREEK',
      'BIMBLE',
      'BRYANTS STORE',
      'CANNON',
      'CLOSPLINT',
      'DEWITT',
      'ERILINE',
      'FALL ROCK',
      'FLAT LICK',
      'FOURMILE',
      'FRALASKAES',
      'GARRARD',
      'GIRDLER',
      'GOOSE ROCK',
      'GREEN ROAD',
      'HEIDRICK',
      'HIMA',
      'HINKLE',
      'INGRAM',
      'KETTLE ISLAND',
      'MANCHESTER',
      'MARY ALICE',
      'MIDDLESBORO',
      'ONEIDA',
      'PINEVILLE',
      'ROARK',
      'SAUL',
      'SCALF',
      'SEXTONS CREEK',
      'STONEY FORK',
      'TROSPER',
      'WALKER',
      'WOOLLUM',
      'ALEXANDRIA',
      'AUGUSTA',
      'BERRY',
      'BROOKSVILLE',
      'BURLINGTON',
      'BUTLER',
      'CALIFORNIA',
      'CARROLLTON',
      'CORINTH',
      'COVINGTON',
      'LATONIA',
      'FT MITCHELL',
      'ERLANGER',
      'FLORENCE',
      'CRITTENDEN',
      'CYNTHIANA',
      'DE MOSSVILLE',
      'DOVER',
      'DRY RIDGE',
      'ELIZAVILLE',
      'EWING',
      'FALMOUTH',
      'FLEMINGSBURG',
      'FOSTER',
      'GERMANTOWN',
      'GHENT',
      'GLENCOE',
      'HEBRON',
      'HILLSBORO',
      'INDEPENDENCE',
      'JONESVILLE',
      'KENTON',
      'MASON',
      'MAYSLICK',
      'MAYSVILLE',
      'MELBOURNE',
      'MILFORD',
      'MINERVA',
      'MORNING VIEW',
      'MOUNT OLIVET',
      'MUSES MILLS',
      'NEWPORT',
      'BELLEVUE',
      'DAYTON',
      'FORT THOMAS',
      'PETERSBURG',
      'PLUMMERS LANDING',
      'SANDERS',
      'SILVER GROVE',
      'SPARTA',
      'UNION',
      'VERONA',
      'WALLINGFORD',
      'WALTON',
      'WARSAW',
      'WASHINGTON',
      'WILLIAMSTOWN',
      'WORTHVILLE',
      'ASHLAND',
      'ARGILLITE',
      'BLAINE',
      'CAMP DIX',
      'CARTER',
      'CATLETTSBURG',
      'DENTON',
      'EMERSON',
      'FIREBRICK',
      'FLATWOODS',
      'GARRISON',
      'GRAHN',
      'GRAYSON',
      'GREENUP',
      'HITCHINS',
      'ISONVILLE',
      'JACOBS',
      'LLOYD',
      'MARTHA',
      'MAZIE',
      'OLIVE HILL',
      'QUINCY',
      'RUSH',
      'RUSSELL',
      'SAINT PAUL',
      'SANDY HOOK',
      'SOLDIER',
      'SOUTH PORTSMOUTH',
      'SOUTH SHORE',
      'VANCEBURG',
      'WEBBVILLE',
      'WILLARD',
      'WORTHINGTON',
      'TOLLESBORO',
      'ADAMS',
      'BEAUTY',
      'BOONS CAMP',
      'DEBORD',
      'DENVER',
      'EAST POINT',
      'FLATGAP',
      'HAGERHILL',
      'INEZ',
      'KEATON',
      'LEANDER',
      'LOUISA',
      'LOVELY',
      'LOWMANSVILLE',
      'MEALLY',
      'OIL SPRINGS',
      'PAINTSVILLE',
      'PILGRIM',
      'RIVER',
      'SITKA',
      'STAFFORDSVILLE',
      'STAMBAUGH',
      'THELMA',
      'TOMAHAWK',
      'TUTOR KEY',
      'ULYSSES',
      'VAN LEAR',
      'WARFIELD',
      'WEST VAN LEAR',
      'WILLIAMSPORT',
      'WITTENSVILLE',
      'CAMPTON',
      'ATHOL',
      'BAYS',
      'BEATTYVILLE',
      'BETHANY',
      'BOONEVILLE',
      'CLAYHOLE',
      'HAZEL GREEN',
      'HEIDELBERG',
      'ISLAND CITY',
      'JACKSON',
      'LEE CITY',
      'LEROSE',
      'LONE',
      'LOST CREEK',
      'MISTLETOE',
      'MIZE',
      'PINE RIDGE',
      'PRIMROSE',
      'RICETOWN',
      'ROGERS',
      'ROUSSEAU',
      'ROWDY',
      'SAINT HELENS',
      'TALBERT',
      'VANCLEVE',
      'VINCENT',
      'WHICK',
      'ZOE',
      'CANNEL CITY',
      'CISCO',
      'CROCKETT',
      'EDNA',
      'ELKFORK',
      'ELSIE',
      'EZEL',
      'FALCON',
      'GAPVILLE',
      'IVYTON',
      'MALONE',
      'OPHIR',
      'ROYALTON',
      'SALYERSVILLE',
      'WEST LIBERTY',
      'WRIGLEY',
      'PIKEVILLE',
      'SOUTH WILLIAMSON',
      'ASHCAMP',
      'BELCHER',
      'BELFRY',
      'BURDINE',
      'CANADA',
      'DORTON',
      'ELKHORN CITY',
      'FEDSCREEK',
      'FORDS BRANCH',
      'FOREST HILLS',
      'FREEBURN',
      'HARDY',
      'HELLIER',
      'HUDDY',
      'JENKINS',
      'JONANCY',
      'KIMPER',
      'LICK CREEK',
      'LOOKOUT',
      'MC ANDREWS',
      'MC CARR',
      'MC VEIGH',
      'MAJESTIC',
      'MOUTHCARD',
      'MYRA',
      'PHELPS',
      'PHYLLIS',
      'PINSONFORK',
      'RACCOON',
      'RANSOM',
      'REGINA',
      'ROBINSON CREEK',
      'ROCKHOUSE',
      'SHELBIANA',
      'SHELBY GAP',
      'SIDNEY',
      'STEELE',
      'STONE',
      'STOPOVER',
      'TOLER',
      'VARNEY',
      'VIRGIE',
      'ALLEN',
      'AUXIER',
      'BANNER',
      'BEAVER',
      'BETSY LAYNE',
      'BEVINSVILLE',
      'BLUE RIVER',
      'BYPRO',
      'DANA',
      'DAVID',
      'DRIFT',
      'DWALE',
      'EASTERN',
      'GARRETT',
      'GRETHEL',
      'GUNLOCK',
      'HAROLD',
      'HI HAT',
      'HUEYSVILLE',
      'IVEL',
      'LACKEY',
      'LANGLEY',
      'MC DOWELL',
      'MARTIN',
      'MELVIN',
      'MINNIE',
      'PRESTONSBURG',
      'PRINTER',
      'STANVILLE',
      'TEABERRY',
      'TRAM',
      'WAYLAND',
      'WEEKSBURY',
      'WHEELWRIGHT',
      'HAZARD',
      'ARY',
      'AVAWAM',
      'BEAR BRANCH',
      'BONNYMAN',
      'BUCKHORN',
      'BULAN',
      'BUSY',
      'CARRIE',
      'CHAVIES',
      'COMBS',
      'CONFLUENCE',
      'CORNETTSVILLE',
      'DELPHIA',
      'DICE',
      'DWARF',
      'EMMALENA',
      'FISTY',
      'GAYS CREEK',
      'HAPPY',
      'HARDBURLY',
      'HYDEN',
      'JEFF',
      'KRYPTON',
      'SASSAFRAS',
      'SCUDDY',
      'SIZEROCK',
      'SLEMP',
      'SMILAX',
      'THOUSANDSTICKS',
      'VEST',
      'VICCO',
      'VIPER',
      'WENDOVER',
      'WOOTON',
      'YEADDISS',
      'YERKES',
      'BLACKEY',
      'CROMONA',
      'DEANE',
      'ERMINE',
      'GARNER',
      'GORDON',
      'HALLIE',
      'HINDMAN',
      'ISOM',
      'JACKHORN',
      'JEREMIAH',
      'KITE',
      'LEBURN',
      'LETCHER',
      'LINEFORK',
      'LITTCARR',
      'MC ROBERTS',
      'MALLIE',
      'MAYKING',
      'MILLSTONE',
      'MOUSIE',
      'NEON',
      'PINE TOP',
      'PIPPA PASSES',
      'PREMIUM',
      'REDFOX',
      'ROXANA',
      'SECO',
      'THORNTON',
      'WHITESBURG',
      'DEMA',
      'RAVEN',
      'TOPMOST',
      'MIGRATE',
      'PADUCAH',
      'ALMO',
      'ARLINGTON',
      'BANDANA',
      'BARDWELL',
      'BARLOW',
      'BENTON',
      'BOAZ',
      'BURNA',
      'CALVERT CITY',
      'CLINTON',
      'COLUMBUS',
      'CRAYNE',
      'CUNNINGHAM',
      'DEXTER',
      'DYCUSBURG',
      'EDDYVILLE',
      'FANCY FARM',
      'FARMINGTON',
      'FULTON',
      'GILBERTSVILLE',
      'GRAND RIVERS',
      'HAMLIN',
      'HAMPTON',
      'HARDIN',
      'HAZEL',
      'HICKMAN',
      'HICKORY',
      'KEVIL',
      'KIRKSEY',
      'KUTTAWA',
      'LA CENTER',
      'LEDBETTER',
      'LOVELACEVILLE',
      'LOWES',
      'LYNNVILLE',
      'MARION',
      'MAYFIELD',
      'MELBER',
      'MILBURN',
      'MURRAY',
      'NEW CONCORD',
      'SALEM',
      'SEDALIA',
      'SMITHLAND',
      'SYMSONIA',
      'TILINE',
      'TOLU',
      'WATER VALLEY',
      'WEST PADUCAH',
      'WICKLIFFE',
      'WINGO',
      'BOWLING GREEN',
      'ADOLPHUS',
      'ALVATON',
      'AUSTIN',
      'BEAUMONT',
      'CAVE CITY',
      'DRALASKAE',
      'EDMONTON',
      'EIGHTY EIGHT',
      'ETOILE',
      'FOUNTAIN RUN',
      'FRANKLIN',
      'GAMALIEL',
      'GLASGOW',
      'HALFWAY',
      'HESTAND',
      'HISEVILLE',
      'HOLLAND',
      'KNOB LICK',
      'LUCAS',
      'MOUNT HERMON',
      'OALASKALAND',
      'PARK CITY',
      'ROCKY HILL',
      'SCOTTSVILLE',
      'SUMMER SHADE',
      'TOMPKINSVILLE',
      'WOODBURN',
      'SMITHS GROVE',
      'ABERDEEN',
      'ADAIRVILLE',
      'ALLEGRE',
      'ALLENSVILLE',
      'AUBURN',
      'BEE SPRING',
      'BROOKLYN',
      'BROWNSVILLE',
      'CADIZ',
      'CENTER',
      'CERULEAN',
      'CLIFTY',
      'CROFTON',
      'DUNBAR',
      'ELKTON',
      'FAIRVIEW',
      'FORT CAMPBELL',
      'GRACEY',
      'GUTHRIE',
      'HADLEY',
      'HERNDON',
      'HOPKINSVILLE',
      'HUNTSVILLE',
      'JETSON',
      'LA FAYETTE',
      'LEWISBURG',
      'LINDSEYVILLE',
      'MAMMOTH CAVE',
      'MORGANTOWN',
      'OALASKA GROVE',
      'OLMSTEAD',
      'PEMBROKE',
      'PROVO',
      'RICHARDSVILLE',
      'ROCHESTER',
      'ROCKFIELD',
      'ROUNDHILL',
      'RUSSELLVILLE',
      'SHARON GROVE',
      'SOUTH UNION',
      'SWEEDEN',
      'TRENTON',
      'WELCHS CREEK',
      'WOODBURY',
      'OWENSBORO',
      'BEAVER DAM',
      'BEECH CREEK',
      'BEECH GROVE',
      'BEECHMONT',
      'BELTON',
      'BREMEN',
      'BROWDER',
      'CALHOUN',
      'CENTERTOWN',
      'CENTRAL CITY',
      'CLEATON',
      'CROMWELL',
      'CURDSVILLE',
      'DRALASKAESBORO',
      'DUNDEE',
      'DUNMOR',
      'FORDSVILLE',
      'GRAHAM',
      'GREENVILLE',
      'HARTFORD',
      'HAWESVILLE',
      'HORSE BRANCH',
      'ISLAND',
      'LEWISPORT',
      'LIVERMORE',
      'MC HENRY',
      'MACEO',
      'MAPLE MOUNT',
      'OLATON',
      'PELLVILLE',
      'PENROD',
      'PHILPOT',
      'POWDERLY',
      'REYNOLDS STATION',
      'ROCKPORT',
      'ROSINE',
      'RUMSEY',
      'SACRAMENTO',
      'SOUTH CARROLLTON',
      'STANLEY',
      'UTICA',
      'WEST LOUISVILLE',
      'WHITESVILLE',
      'BASKETT',
      'BLACKFORD',
      'CLAY',
      'CORYDON',
      'DAWSON SPRINGS',
      'DIXON',
      'EARLINGTON',
      'FREDONIA',
      'HANSON',
      'HENDERSON',
      'MADISONVILLE',
      'MANITOU',
      'MORGANFIELD',
      'MORTONS GAP',
      'NEBO',
      'NORTONVILLE',
      'POOLE',
      'PRINCETON',
      'PROVIDENCE',
      'REED',
      'ROBARDS',
      'SAINT CHARLES',
      'SEBREE',
      'SLAUGHTERS',
      'SMITH MILLS',
      'SPOTTSVILLE',
      'STURGIS',
      'SULLIVAN',
      'UNIONTOWN',
      'WAVERLY',
      'WHEATCROFT',
      'WHITE PLAINS',
      'SOMERSET',
      'BETHELRIDGE',
      'BRONSTON',
      'BURNSIDE',
      'DUNNVILLE',
      'FERGUSON',
      'LIBERTY',
      'MIDDLEBURG',
      'NANCY',
      'SCIENCE HILL',
      'TATEVILLE',
      'WEST SOMERSET',
      'WINDSOR',
      'YOSEMITE',
      'EUBANK',
      'ALBANY',
      'ALPHA',
      'JAMESTOWN',
      'MARSHES SIDING',
      'MILL SPRINGS',
      'MONTICELLO',
      'PARKERS LALASKAE',
      'PINE KNOT',
      'REVELO',
      'RUSSELL SPRINGS',
      'STEARNS',
      'STRUNK',
      'WHITLEY CITY',
      'ELIZABETHTOWN',
      'BALASKAERTON',
      'BIG CLIFTY',
      'BONNIEVILLE',
      'BREEDING',
      'BUFFALO',
      'BURKESVILLE',
      'CAMPBELLSVILLE',
      'CANE VALLEY',
      'CANEYVILLE',
      'CANMER',
      'CECILIA',
      'CLARKSON',
      'COLUMBIA',
      'CUB RUN',
      'DUBRE',
      'EASTVIEW',
      'ELK HORN',
      'FAIRPLAY',
      'GLENDALE',
      'GLENS FORK',
      'GRADYVILLE',
      'GREENSBURG',
      'HARDYVILLE',
      'HODGENVILLE',
      'HORSE CAVE',
      'KNIFLEY',
      'LEITCHFIELD',
      'MAGNOLIA',
      'MANNSVILLE',
      'MARROWBONE',
      'MILLTOWN',
      'MILLWOOD',
      'MOUNT SHERMAN',
      'MUNFORDVILLE',
      'SONORA',
      'SUMMERSVILLE',
      'SUMMIT',
      'UPTON',
      'WATERVIEW',
      'WHITE MILLS'
    ]
  },
  LA: {
    code: 'LA',
    name: 'LOUISIANA',
    cities: [
      'METAIRIE',
      'DES ALLEMANDS',
      'AMA',
      'ARABI',
      'BARATARIA',
      'BELLE CHASSE',
      'BOOTHVILLE',
      'BOUTTE',
      'BRAITHWAITE',
      'BURAS',
      'CARLISLE',
      'CHALMETTE',
      'DAVANT',
      'DESTREHAN',
      'EDGARD',
      'EMPIRE',
      'GARYVILLE',
      'GRAMERCY',
      'GRETNA',
      'HAHNVILLE',
      'HARVEY',
      'KENNER',
      'KILLONA',
      'LAFITTE',
      'LA PLACE',
      'LULING',
      'LUTCHER',
      'MARRERO',
      'MERAUX',
      'MOUNT AIRY',
      'NEW SARPY',
      'NORCO',
      'PARADIS',
      'PILOTTOWN',
      'POINTE A LA HACHE',
      'PORT SULPHUR',
      'RESERVE',
      'SAINT BERNARD',
      'SAINT JAMES',
      'SAINT ROSE',
      'VACHERIE',
      'VENICE',
      'VIOLET',
      'WESTWEGO',
      'NEW ORLEANS',
      'THIBODAUX',
      'PIERRE PART',
      'AMELIA',
      'BELLE ROSE',
      'BERWICK',
      'BOURG',
      'CHAUVIN',
      'CUT OFF',
      'DONALDSONVILLE',
      'DONNER',
      'DULAC',
      'GALLIANO',
      'GHEENS',
      'GIBSON',
      'GOLDEN MEADOW',
      'GRAND ISLE',
      'GRAY',
      'HOUMA',
      'KRAEMER',
      'LABADIEVILLE',
      'LAROSE',
      'LOCKPORT',
      'MATHEWS',
      'MODESTE',
      'MONTEGUT',
      'MORGAN CITY',
      'NAPOLEONVILLE',
      'PAINCOURTVILLE',
      'PATTERSON',
      'PLATTENVILLE',
      'RACELAND',
      'SCHRIEVER',
      'THERIOT',
      'HAMMOND',
      'ABITA SPRINGS',
      'ALASKAERS',
      'AMITE',
      'ANGIE',
      'BOGALUSA',
      'BUSH',
      'COVINGTON',
      'FLUKER',
      'FOLSOM',
      'FRANKLINTON',
      'GREENSBURG',
      'HUSSER',
      'INDEPENDENCE',
      'KENTWOOD',
      'LACOMBE',
      'LORANGER',
      'MADISONVILLE',
      'MANDEVILLE',
      'MAUREPAS',
      'MOUNT HERMON',
      'NATALBANY',
      'PEARL RIVER',
      'PINE GROVE',
      'PONCHATOULA',
      'ROBERT',
      'ROSELAND',
      'SAINT BENEDICT',
      'SLIDELL',
      'SPRINGFIELD',
      'SUN',
      'TALISHEEK',
      'TANGIPAHOA',
      'TICKFAW',
      'LAFAYETTE',
      'ABBEVILLE',
      'ARNAUDVILLE',
      'AVERY ISLAND',
      'BALDWIN',
      'BASILE',
      'BRANCH',
      'BREAUX BRIDGE',
      'BROUSSARD',
      'CADE',
      'CARENCRO',
      'CECILIA',
      'CENTERVILLE',
      'CHARENTON',
      'CHATAIGNIER',
      'CHURCH POINT',
      'CROWLEY',
      'DELCAMBRE',
      'DUSON',
      'EGAN',
      'ELTON',
      'ERATH',
      'ESTHERWOOD',
      'EUNICE',
      'EVANGELINE',
      'FRANKLIN',
      'GARDEN CITY',
      'GRAND COTEAU',
      'GUEYDAN',
      'IOTA',
      'JEANERETTE',
      'JENNINGS',
      'KAPLAN',
      'LALASKAE ARTHUR',
      'LAWTELL',
      'LEONVILLE',
      'LOREAUVILLE',
      'MAMOU',
      'MAURICE',
      'MERMENTAU',
      'MILTON',
      'MORSE',
      'NEW IBERIA',
      'LYDIA',
      'OPELOUSAS',
      'PERRY',
      'PINE PRAIRIE',
      'PORT BARRE',
      'RAYNE',
      'REDDELL',
      'ROANOKE',
      'SAINT MARTINVILLE',
      'SCOTT',
      'SUNSET',
      'TURKEY CREEK',
      'VILLE PLATTE',
      'WASHINGTON',
      'WELSH',
      'YOUNGSVILLE',
      'LALASKAE CHARLES',
      'BELL CITY',
      'CAMERON',
      'CREOLE',
      'DEQUINCY',
      'DERIDDER',
      'DRY CREEK',
      'ELIZABETH',
      'EVANS',
      'FENTON',
      'FULLERTON',
      'GRAND CHENIER',
      'GRANT',
      'HACKBERRY',
      'HAYES',
      'IOWA',
      'KINDER',
      'LACASSINE',
      'LEBLANC',
      'LONGVILLE',
      'MERRYVILLE',
      'MITTIE',
      'OBERLIN',
      'PITKIN',
      'RAGLEY',
      'REEVES',
      'ROSEPINE',
      'SINGER',
      'STARKS',
      'SUGARTOWN',
      'SULPHUR',
      'VINTON',
      'WESTLALASKAE',
      'BALASKAER',
      'DENHAM SPRINGS',
      'GONZALES',
      'ADDIS',
      'ALBANY',
      'ANGOLA',
      'BATCHELOR',
      'BAYOU GOULA',
      'BLANKS',
      'BRITTANY',
      'BRUSLY',
      'BUECHE',
      'CARVILLE',
      'CLINTON',
      'CONVENT',
      'DARROW',
      'DUPLESSIS',
      'ERWINVILLE',
      'ETHEL',
      'FORDOCHE',
      'FRENCH SETTLEMENT',
      'GEISMAR',
      'GLYNN',
      'BURNSIDE',
      'GREENWELL SPRINGS',
      'GROSSE TETE',
      'HESTER',
      'HOLDEN',
      'INNIS',
      'JACKSON',
      'JARREAU',
      'KROTZ SPRINGS',
      'LABARRE',
      'LALASKAELAND',
      'LETTSWORTH',
      'LIVINGSTON',
      'LIVONIA',
      'LOTTIE',
      'MARINGOUIN',
      'MORGANZA',
      'NEW ROADS',
      'NORWOOD',
      'OSCAR',
      'PAULINA',
      'PLAQUEMINE',
      'PORT ALLEN',
      'PRAIRIEVILLE',
      'PRIDE',
      'ROSEDALE',
      'ROUGON',
      'SAINT AMANT',
      'SAINT FRANCISVILLE',
      'SAINT GABRIEL',
      'SLAUGHTER',
      'SORRENTO',
      'SUNSHINE',
      'TORBERT',
      'TUNICA',
      'VENTRESS',
      'WALASKAEFIELD',
      'WALKER',
      'WATSON',
      'WEYANOKE',
      'WHITE CASTLE',
      'WILSON',
      'ZACHARY',
      'UNCLE SAM',
      'BATON ROUGE',
      'ARCADIA',
      'ASHLAND',
      'ATHENS',
      'BELCHER',
      'BENTON',
      'BETHANY',
      'BIENVILLE',
      'BLANCHARD',
      'CASTOR',
      'COTTON VALLEY',
      'COUSHATTA',
      'CULLEN',
      'DOYLINE',
      'DUBBERLY',
      'EAST POINT',
      'FRIERSON',
      'GIBSLAND',
      'GILLIAM',
      'GLOSTER',
      'GOLDONNA',
      'GRAND CANE',
      'GREENWOOD',
      'HALL SUMMIT',
      'HARMON',
      'HAUGHTON',
      'HAYNESVILLE',
      'HEFLIN',
      'HOMER',
      'HOSSTON',
      'IDA',
      'JAMESTOWN',
      'KEATCHIE',
      'KEITHVILLE',
      'LISBON',
      'LOGANSPORT',
      'LONGSTREET',
      'ELM GROVE',
      'MANSFIELD',
      'MINDEN',
      'MOORINGSPORT',
      'OIL CITY',
      'PELICAN',
      'PLAIN DEALING',
      'PLEASANT HILL',
      'POWHATAN',
      'PRINCETON',
      'RINGGOLD',
      'RODESSA',
      'SALINE',
      'SAREPTA',
      'SHONGALOO',
      'SIBLEY',
      'SPRINGHILL',
      'STONEWALL',
      'SUMMERFIELD',
      'TAYLOR',
      'VIVIAN',
      'SHREVEPORT',
      'BARKSDALE AFB',
      'BOSSIER CITY',
      'MONROE',
      'ARCHIBALD',
      'BASKIN',
      'BASTROP',
      'BERNICE',
      'BONITA',
      'CALHOUN',
      'CHATHAM',
      'CHOUDRANT',
      'COLLINSTON',
      'CROWVILLE',
      'DELHI',
      'DELTA',
      'DOWNSVILLE',
      'DUBACH',
      'EPPS',
      'EROS',
      'FAIRBANKS',
      'FARMERVILLE',
      'FOREST',
      'FORT NECESSITY',
      'GRAMBLING',
      'HODGE',
      'JIGGER',
      'JONES',
      'JONESBORO',
      'KILBOURNE',
      'LALASKAE PROVIDENCE',
      'LILLIE',
      'MANGHAM',
      'MARION',
      'MER ROUGE',
      'OALASKA GROVE',
      'OALASKA RIDGE',
      'PIONEER',
      'QUITMAN',
      'RAYVILLE',
      'RUSTON',
      'SIMSBORO',
      'SONDHEIMER',
      'SPEARSVILLE',
      'START',
      'STERLINGTON',
      'SWARTZ',
      'TALLULAH',
      'TRANSYLVANIA',
      'WEST MONROE',
      'WINNSBORO',
      'ALEXANDRIA',
      'ACME',
      'BORDELONVILLE',
      'BUNKIE',
      'CENTER POINT',
      'CHASE',
      'CHENEYVILLE',
      'CLAYTON',
      'COTTONPORT',
      'DEVILLE',
      'DUPONT',
      'ECHO',
      'EFFIE',
      'EVERGREEN',
      'FERRIDAY',
      'GILBERT',
      'HAMBURG',
      'HARRISONBURG',
      'HESSMER',
      'JENA',
      'JONESVILLE',
      'LEBEAU',
      'LECOMPTE',
      'LIBUSE',
      'MANSURA',
      'MARKSVILLE',
      'MELVILLE',
      'MONTEREY',
      'MOREAUVILLE',
      'MORROW',
      'NEWELLTON',
      'PALMETTO',
      'PINEVILLE',
      'PLAUCHEVILLE',
      'RHINEHART',
      'RUBY',
      'SAINT JOSEPH',
      'SAINT LANDRY',
      'SICILY ISLAND',
      'SIMMESPORT',
      'TROUT',
      'VIDALIA',
      'WATERPROOF',
      'WILDSVILLE',
      'WISNER',
      'AIMWELL',
      'ANACOCO',
      'ATLANTA',
      'BALL',
      'BELMONT',
      'BENTLEY',
      'BOYCE',
      'CALVIN',
      'CAMPTI',
      'CLARENCE',
      'CLARKS',
      'CLOUTIERVILLE',
      'COLFAX',
      'COLUMBIA',
      'CONVERSE',
      'DODSON',
      'DRY PRONG',
      'ELMER',
      'ENTERPRISE',
      'FISHER',
      'FLATWOODS',
      'FLORA',
      'FLORIEN',
      'FOREST HILL',
      'GARDNER',
      'GEORGETOWN',
      'GLENMORA',
      'GORUM',
      'GRAYSON',
      'HINESTON',
      'HORNBECK',
      'JOYCE',
      'KELLY',
      'KURTHWOOD',
      'LACAMP',
      'LEESVILLE',
      'LENA',
      'LONGLEAF',
      'MANY',
      'MARTHAVILLE',
      'MELROSE',
      'MONTGOMERY',
      'MORA',
      'NATCHEZ',
      'NATCHITOCHES',
      'NEGREET',
      'NEWLLANO',
      'NOBLE',
      'OALASKADALE',
      'OLLA',
      'OTIS',
      'POLLOCK',
      'PROVENCAL',
      'ROBELINE',
      'SAINT MAURICE',
      'SIEPER',
      'SIKES',
      'SIMPSON',
      'SLAGLE',
      'TIOGA',
      'TULLOS',
      'URANIA',
      'VERDA',
      'WINNFIELD',
      'WOODWORTH',
      'ZWOLLE'
    ]
  },
  MA: {
    code: 'MA',
    name: 'MASSACHUSETTS',
    cities: [
      'AGAWAM',
      'AMHERST',
      'BARRE',
      'BELCHERTOWN',
      'BLANDFORD',
      'BONDSVILLE',
      'BRIMFIELD',
      'CHESTER',
      'CHESTERFIELD',
      'CHICOPEE',
      'CUMMINGTON',
      'EASTHAMPTON',
      'EAST LONGMEADOW',
      'EAST OTIS',
      'FEEDING HILLS',
      'GILBERTVILLE',
      'GOSHEN',
      'GRANBY',
      'GRANVILLE',
      'HADLEY',
      'HAMPDEN',
      'HARDWICK',
      'HATFIELD',
      'HAYDENVILLE',
      'HOLYOKE',
      'HUNTINGTON',
      'LEEDS',
      'LEVERETT',
      'LUDLOW',
      'MONSON',
      'NORTH AMHERST',
      'NORTHAMPTON',
      'FLORENCE',
      'NORTH HATFIELD',
      'OALASKAHAM',
      'PALMER',
      'PLAINFIELD',
      'RUSSELL',
      'SHUTESBURY',
      'SOUTHAMPTON',
      'SOUTH BARRE',
      'SOUTH HADLEY',
      'SOUTHWICK',
      'THORNDIKE',
      'THREE RIVERS',
      'WALES',
      'WARE',
      'WARREN',
      'WEST CHESTERFIELD',
      'WESTFIELD',
      'WEST HATFIELD',
      'WEST SPRINGFIELD',
      'WEST WARREN',
      'WHATELY',
      'WHEELWRIGHT',
      'WILBRAHAM',
      'WILLIAMSBURG',
      'WORONOCO',
      'WORTHINGTON',
      'SPRINGFIELD',
      'LONGMEADOW',
      'INDIAN ORCHARD',
      'PITTSFIELD',
      'ADAMS',
      'ASHLEY FALLS',
      'BECKET',
      'BERKSHIRE',
      'CHESHIRE',
      'DALTON',
      'GLENDALE',
      'GREAT BARRINGTON',
      'HINSDALE',
      'HOUSATONIC',
      'LANESBORO',
      'LEE',
      'LENOX',
      'LENOX DALE',
      'MIDDLEFIELD',
      'MILL RIVER',
      'MONTEREY',
      'NORTH ADAMS',
      'NORTH EGREMONT',
      'OTIS',
      'RICHMOND',
      'SANDISFIELD',
      'SAVOY',
      'SHEFFIELD',
      'SOUTH EGREMONT',
      'SOUTHFIELD',
      'SOUTH LEE',
      'STOCKBRIDGE',
      'TYRINGHAM',
      'WEST STOCKBRIDGE',
      'WILLIAMSTOWN',
      'WINDSOR',
      'GREENFIELD',
      'ASHFIELD',
      'ATHOL',
      'BERNARDSTON',
      'BUCKLAND',
      'CHARLEMONT',
      'COLRAIN',
      'CONWAY',
      'DEERFIELD',
      'DRURY',
      'ERVING',
      'HEATH',
      'LALASKAE PLEASANT',
      'TURNERS FALLS',
      'MONROE BRIDGE',
      'MONTAGUE',
      'NORTHFIELD',
      'NEW SALEM',
      'ORANGE',
      'PETERSHAM',
      'ROWE',
      'ROYALSTON',
      'SHATTUCKVILLE',
      'SHELBURNE FALLS',
      'SOUTH DEERFIELD',
      'SUNDERLAND',
      'WARWICK',
      'WENDELL',
      'WENDELL DEPOT',
      'FITCHBURG',
      'ASHBURNHAM',
      'ASHBY',
      'AYER',
      'BALDWINVILLE',
      'EAST TEMPLETON',
      'GARDNER',
      'WESTMINSTER',
      'GROTON',
      'HARVARD',
      'HUBBARDSTON',
      'LEOMINSTER',
      'LITTLETON',
      'LUNENBURG',
      'PEPPERELL',
      'SHIRLEY',
      'STILL RIVER',
      'TEMPLETON',
      'TOWNSEND',
      'WEST GROTON',
      'WEST TOWNSEND',
      'WINCHENDON',
      'WINCHENDON SPRINGS',
      'AUBURN',
      'BERLIN',
      'BLACKSTONE',
      'BOYLSTON',
      'BROOKFIELD',
      'CHARLTON',
      'CHARLTON CITY',
      'CHARLTON DEPOT',
      'CLINTON',
      'EAST BROOKFIELD',
      'DOUGLAS',
      'EAST PRINCETON',
      'FISKDALE',
      'GRAFTON',
      'HOLDEN',
      'HOLLAND',
      'JEFFERSON',
      'LANCASTER',
      'LEICESTER',
      'LINWOOD',
      'MANCHAUG',
      'MILLBURY',
      'MILLVILLE',
      'NEW BRAINTREE',
      'NORTHBOROUGH',
      'NORTHBRIDGE',
      'NORTH BROOKFIELD',
      'NORTH GRAFTON',
      'NORTH OXFORD',
      'NORTH UXBRIDGE',
      'OXFORD',
      'PRINCETON',
      'ROCHDALE',
      'RUTLAND',
      'SHREWSBURY',
      'SOUTHBRIDGE',
      'SOUTH GRAFTON',
      'SOUTH LANCASTER',
      'SPENCER',
      'STERLING',
      'STURBRIDGE',
      'UPTON',
      'UXBRIDGE',
      'WEBSTER',
      'DUDLEY',
      'WESTBOROUGH',
      'WEST BOYLSTON',
      'WEST BROOKFIELD',
      'WEST MILLBURY',
      'WHITINSVILLE',
      'SUTTON',
      'WORCESTER',
      'CHERRY VALLEY',
      'PAXTON',
      'FRAMINGHAM',
      'VILLAGE OF NAGOG WOODS',
      'BOXBOROUGH',
      'ACTON',
      'ASHLAND',
      'BEDFORD',
      'HANSCOM AFB',
      'BOLTON',
      'CARLISLE',
      'CONCORD',
      'FAYVILLE',
      'HOLLISTON',
      'HOPEDALE',
      'HOPKINTON',
      'HUDSON',
      'MARLBOROUGH',
      'MAYNARD',
      'MENDON',
      'MILFORD',
      'NATICK',
      'SHERBORN',
      'SOUTHBOROUGH',
      'LINCOLN',
      'STOW',
      'SUDBURY',
      'WAYLAND',
      'WOODVILLE',
      'WOBURN',
      'BURLINGTON',
      'ANDOVER',
      'BILLERICA',
      'CHELMSFORD',
      'DRACUT',
      'DUNSTABLE',
      'HAVERHILL',
      'GEORGETOWN',
      'GROVELAND',
      'LAWRENCE',
      'METHUEN',
      'NORTH ANDOVER',
      'LOWELL',
      'MERRIMAC',
      'NORTH BILLERICA',
      'NORTH CHELMSFORD',
      'NORTH READING',
      'NUTTING LALASKAE',
      'PINEHURST',
      'READING',
      'TEWKSBURY',
      'TYNGSBORO',
      'WALASKAEFIELD',
      'WEST BOXFORD',
      'WESTFORD',
      'WILMINGTON',
      'WINCHESTER',
      'LYNN',
      'SAUGUS',
      'SWAMPSCOTT',
      'NAHANT',
      'AMESBURY',
      'BEVERLY',
      'BOXFORD',
      'BYFIELD',
      'DANVERS',
      'ESSEX',
      'GLOUCESTER',
      'HAMILTON',
      'HATHORNE',
      'IPSWICH',
      'LYNNFIELD',
      'MANCHESTER',
      'MARBLEHEAD',
      'MIDDLETON',
      'NEWBURYPORT',
      'NEWBURY',
      'SALISBURY',
      'PEABODY',
      'PRIDES CROSSING',
      'ROCKPORT',
      'ROWLEY',
      'SALEM',
      'SOUTH HAMILTON',
      'TOPSFIELD',
      'WENHAM',
      'WEST NEWBURY',
      'ACCORD',
      'BELLINGHAM',
      'BRANT ROCK',
      'CANTON',
      'COHASSET',
      'DEDHAM',
      'DOVER',
      'EAST MANSFIELD',
      'EAST WALPOLE',
      'FOXBORO',
      'FRANKLIN',
      'GREENBUSH',
      'GREEN HARBOR',
      'HINGHAM',
      'HULL',
      'HUMAROCK',
      'MANSFIELD',
      'MARSHFIELD',
      'MARSHFIELD HILLS',
      'MEDFIELD',
      'MEDWAY',
      'MILLIS',
      'MINOT',
      'NORFOLK',
      'NORTH MARSHFIELD',
      'NORTH SCITUATE',
      'NORWELL',
      'NORWOOD',
      'OCEAN BLUFF',
      'SCITUATE',
      'SHARON',
      'SHELDONVILLE',
      'SOUTH WALPOLE',
      'STOUGHTON',
      'WALPOLE',
      'WESTWOOD',
      'WRENTHAM',
      'BOSTON',
      'MATTAPAN',
      'CHARLESTOWN',
      'JAMAICA PLAIN',
      'ROSLINDALE',
      'WEST ROXBURY',
      'ALLSTON',
      'BRIGHTON',
      'HYDE PARK',
      'READVILLE',
      'CAMBRIDGE',
      'SOMERVILLE',
      'MALDEN',
      'EVERETT',
      'CHELSEA',
      'REVERE',
      'WINTHROP',
      'MEDFORD',
      'WEST MEDFORD',
      'QUINCY',
      'MELROSE',
      'STONEHAM',
      'BRAINTREE',
      'MILTON',
      'MILTON VILLAGE',
      'WEYMOUTH',
      'EAST BOSTON',
      'BROCKTON',
      'AVON',
      'BRIDGEWATER',
      'BRYANTVILLE',
      'CARVER',
      'DUXBURY',
      'EAST BRIDGEWATER',
      'EASTON',
      'ELMWOOD',
      'HALIFAX',
      'HANOVER',
      'HANSON',
      'HOLBROOK',
      'MIDDLEBORO',
      'MANOMET',
      'LALASKAEVILLE',
      'MONPONSETT',
      'ABINGTON',
      'NORTH CARVER',
      'NORTH EASTON',
      'NORTH PEMBROKE',
      'PEMBROKE',
      'PLYMOUTH',
      'KINGSTON',
      'SOUTH CARVER',
      'PLYMPTON',
      'RANDOLPH',
      'ROCKLAND',
      'SOUTH EASTON',
      'WEST BRIDGEWATER',
      'WHITE HORSE BEACH',
      'WHITMAN',
      'LEXINGTON',
      'BROOKLINE',
      'BROOKLINE VILLAGE',
      'WALTHAM',
      'NORTH WALTHAM',
      'NEW TOWN',
      'BABSON PARK',
      'NEWTON',
      'NEWTON CENTER',
      'NEWTONVILLE',
      'NEWTON HIGHLANDS',
      'NEWTON LOWER FALLS',
      'NEWTON UPPER FALLS',
      'WEST NEWTON',
      'AUBURNDALE',
      'CHESTNUT HILL',
      'WABAN',
      'WATERTOWN',
      'ARLINGTON',
      'ARLINGTON HEIGHTS',
      'BELMONT',
      'WAVERLEY',
      'WELLESLEY HILLS',
      'WELLESLEY',
      'NEEDHAM',
      'WESTON',
      'NEEDHAM HEIGHTS',
      'NONANTUM',
      'BUZZARDS BAY',
      'CATAUMET',
      'CHILMARK',
      'EAST FALMOUTH',
      'EAST SANDWICH',
      'EAST WAREHAM',
      'EDGARTOWN',
      'FALMOUTH',
      'WOODS HOLE',
      'MENEMSHA',
      'MONUMENT BEACH',
      'NANTUCKET',
      'NORTH FALMOUTH',
      'OALASKA BLUFFS',
      'ONSET',
      'POCASSET',
      'SAGAMORE',
      'SAGAMORE BEACH',
      'SANDWICH',
      'SIASCONSET',
      'SILVER BEACH',
      'VINEYARD HAVEN',
      'WAREHAM',
      'WEST CHOP',
      'WEST FALMOUTH',
      'WEST TISBURY',
      'WEST WAREHAM',
      'HYANNIS',
      'BARNSTABLE',
      'BREWSTER',
      'CENTERVILLE',
      'CHATHAM',
      'COTUIT',
      'CUMMAQUID',
      'DENNIS',
      'DENNIS PORT',
      'EAST DENNIS',
      'EASTHAM',
      'EAST ORLEANS',
      'FORESTDALE',
      'HARWICH',
      'HARWICH PORT',
      'HYANNIS PORT',
      'MARSTONS MILLS',
      'MASHPEE',
      'NORTH CHATHAM',
      'NORTH EASTHAM',
      'NORTH TRURO',
      'ORLEANS',
      'OSTERVILLE',
      'PROVINCETOWN',
      'SOUTH CHATHAM',
      'SOUTH DENNIS',
      'SOUTH HARWICH',
      'SOUTH ORLEANS',
      'SOUTH WELLFLEET',
      'SOUTH YARMOUTH',
      'TRURO',
      'WELLFLEET',
      'WEST BARNSTABLE',
      'WEST CHATHAM',
      'WEST DENNIS',
      'WEST HARWICH',
      'WEST HYANNISPORT',
      'WEST YARMOUTH',
      'YARMOUTH PORT',
      'ASSONET',
      'ATTLEBORO',
      'CHARTLEY',
      'CUTTYHUNK',
      'DARTMOUTH',
      'DIGHTON',
      'EAST FREETOWN',
      'EAST TAUNTON',
      'FAIRHAVEN',
      'FALL RIVER',
      'SOMERSET',
      'MARION',
      'MATTAPOISETT',
      'NEW BEDFORD',
      'ACUSHNET',
      'NORTH DARTMOUTH',
      'SOUTH DARTMOUTH',
      'NORTH ATTLEBORO',
      'PLAINVILLE',
      'ATTLEBORO FALLS',
      'NORTH DIGHTON',
      'NORTON',
      'RAYNHAM',
      'RAYNHAM CENTER',
      'REHOBOTH',
      'ROCHESTER',
      'SEEKONK',
      'SWANSEA',
      'BERKLEY',
      'TAUNTON',
      'WESTPORT',
      'WESTPORT POINT'
    ]
  },
  MD: {
    code: 'MD',
    name: 'MARYLAND',
    cities: [
      'WALDORF',
      'ABELL',
      'ACCOKEEK',
      'AQUASCO',
      'AVENUE',
      'BARSTOW',
      'BEL ALTON',
      'BENEDICT',
      'BRANDYWINE',
      'BROOMES ISLAND',
      'BRYANS ROAD',
      'BRYANTOWN',
      'BUSHWOOD',
      'CALIFORNIA',
      'CALLAWAY',
      'CHAPTICO',
      'CHARLOTTE HALL',
      'CHELTENHAM',
      'CLEMENTS',
      'COBB ISLAND',
      'COLTONS POINT',
      'COMPTON',
      'DAMERON',
      'DOWELL',
      'DRAYDEN',
      'FAULKNER',
      'GREAT MILLS',
      'HELEN',
      'HOLLYWOOD',
      'HUGHESVILLE',
      'HUNTINGTOWN',
      'INDIAN HEAD',
      'IRONSIDES',
      'ISSUE',
      'LA PLATA',
      'LEONARDTOWN',
      'LEXINGTON PARK',
      'LOVEVILLE',
      'LUSBY',
      'MARBURY',
      'MECHANICSVILLE',
      'MORGANZA',
      'MOUNT VICTORIA',
      'NANJEMOY',
      'NEWBURG',
      'PARK HALL',
      'PATUXENT RIVER',
      'PINEY POINT',
      'POMFRET',
      'PORT REPUBLIC',
      'PORT TOBACCO',
      'PRINCE FREDERICK',
      'RIDGE',
      'ROCK POINT',
      'SAINT INIGOES',
      'SAINT LEONARD',
      'SAINT MARYS CITY',
      'SCOTLAND',
      'SOLOMONS',
      'SUNDERLAND',
      'TALL TIMBERS',
      'VALLEY LEE',
      'WELCOME',
      'WHITE PLAINS',
      'SOUTHERN MD FACILITY',
      'ANNAPOLIS JUNCTION',
      'LANHAM',
      'BELTSVILLE',
      'LAUREL',
      'BLADENSBURG',
      'LOTHIAN',
      'MOUNT RAINIER',
      'NORTH BEACH',
      'BOWIE',
      'BRENTWOOD',
      'CAPITOL HEIGHTS',
      'CHESAPEALASKAE BEACH',
      'CHURCHTON',
      'CLINTON',
      'OWINGS',
      'RIVERDALE',
      'COLLEGE PARK',
      'FORT WASHINGTON',
      'OXON HILL',
      'SUITLAND',
      'DISTRICT HEIGHTS',
      'TEMPLE HILLS',
      'DEALE',
      'DUNKIRK',
      'FORT GEORGE G MEADE',
      'FRIENDSHIP',
      'FULTON',
      'ANDREWS AIR FORCE BASE',
      'SAVAGE',
      'SHADY SIDE',
      'GALESVILLE',
      'GREENBELT',
      'GLENN DALE',
      'UPPER MARLBORO',
      'HARWOOD',
      'HIGHLAND',
      'WEST RIVER',
      'TRACYS LANDING',
      'HYATTSVILLE',
      'JESSUP',
      'GLEN ECHO',
      'BETHESDA',
      'CHEVY CHASE',
      'CABIN JOHN',
      'OLNEY',
      'BROOKEVILLE',
      'POOLESVILLE',
      'BARNESVILLE',
      'BEALLSVILLE',
      'BOYDS',
      'DICKERSON',
      'ROCKVILLE',
      'POTOMAC',
      'DERWOOD',
      'SANDY SPRING',
      'ASHTON',
      'BRINKLOW',
      'BURTONSVILLE',
      'SPENCERVILLE',
      'CLARKSBURG',
      'DAMASCUS',
      'GERMANTOWN',
      'GAITHERSBURG',
      'WASHINGTON GROVE',
      'MONTGOMERY VILLAGE',
      'KENSINGTON',
      'GARRETT PARK',
      'SUBURB MARYLAND FAC',
      'SILVER SPRING',
      'TALASKAOMA PARK',
      'ABERDEEN',
      'ABERDEEN PROVING GROUND',
      'ABINGDON',
      'GUNPOWDER',
      'ARNOLD',
      'BALDWIN',
      'BEL AIR',
      'BELCAMP',
      'BENSON',
      'BORING',
      'BROOKLANDVILLE',
      'BUTLER',
      'CHASE',
      'CHURCHVILLE',
      'CLARKSVILLE',
      'COCKEYSVILLE',
      'HUNT VALLEY',
      'CROWNSVILLE',
      'DARLINGTON',
      'DAVIDSONVILLE',
      'DAYTON',
      'EDGEWATER',
      'EDGEWOOD',
      'ELLICOTT CITY',
      'COLUMBIA',
      'FALLSTON',
      'FINKSBURG',
      'FOREST HILL',
      'FORK',
      'FORT HOWARD',
      'FREELAND',
      'GAMBRILLS',
      'GARRISON',
      'GIBSON ISLAND',
      'GLEN ARM',
      'GLEN BURNIE',
      'GLYNDON',
      'HAMPSTEAD',
      'ELKRIDGE',
      'HANOVER',
      'HARMANS',
      'HAVRE DE GRACE',
      'HENRYTON',
      'HYDES',
      'JARRETTSVILLE',
      'JOPPA',
      'KINGSVILLE',
      'LINEBORO',
      'LINTHICUM HEIGHTS',
      'LONG GREEN',
      'LUTHERVILLE TIMONIUM',
      'MANCHESTER',
      'MARRIOTTSVILLE',
      'MARYLAND LINE',
      'MAYO',
      'MILLERSVILLE',
      'MONKTON',
      'ODENTON',
      'CROFTON',
      'OWINGS MILLS',
      'PARKTON',
      'PASADENA',
      'PERRY HALL',
      'PERRYMAN',
      'PHOENIX',
      'PYLESVILLE',
      'RANDALLSTOWN',
      'REISTERSTOWN',
      'RIDERWOOD',
      'RIVA',
      'SEVERN',
      'SEVERNA PARK',
      'SIMPSONVILLE',
      'SPARKS GLENCOE',
      'STEVENSON',
      'STREET',
      'UPPERCO',
      'UPPER FALLS',
      'WESTMINSTER',
      'WHITEFORD',
      'WHITE HALL',
      'WHITE MARSH',
      'WOODSTOCK',
      'BALTIMORE',
      'TOWSON',
      'GWYNN OALASKA',
      'PIKESVILLE',
      'SPARROWS POINT',
      'MIDDLE RIVER',
      'ESSEX',
      'DUNDALK',
      'BROOKLYN',
      'CURTIS BAY',
      'HALETHORPE',
      'CATONSVILLE',
      'PARKVILLE',
      'NOTTINGHAM',
      'ROSEDALE',
      'WINDSOR MILL',
      'ANNAPOLIS',
      'CUMBERLAND',
      'ACCIDENT',
      'BARTON',
      'BITTINGER',
      'BLOOMINGTON',
      'CORRIGANVILLE',
      'ECKHART MINES',
      'ELLERSLIE',
      'FLINTSTONE',
      'FRIENDSVILLE',
      'FROSTBURG',
      'GRANTSVILLE',
      'KITZMILLER',
      'LONACONING',
      'LUKE',
      'MC HENRY',
      'MIDLAND',
      'MIDLOTHIAN',
      'MOUNT SAVAGE',
      'OALASKALAND',
      'OLDTOWN',
      'PINTO',
      'RAWLINGS',
      'SPRING GAP',
      'SWANTON',
      'WESTERNPORT',
      'EASTON',
      'BARCLAY',
      'BETHLEHEM',
      'BETTERTON',
      'BOZMAN',
      'CAMBRIDGE',
      'CENTREVILLE',
      'CHESTER',
      'CHESTERTOWN',
      'CHURCH CREEK',
      'CHURCH HILL',
      'CLAIBORNE',
      'CORDOVA',
      'CRAPO',
      'CROCHERON',
      'CRUMPTON',
      'DENTON',
      'EAST NEW MARKET',
      'FEDERALSBURG',
      'FISHING CREEK',
      'GALENA',
      'GOLDSBORO',
      'GRASONVILLE',
      'GREENSBORO',
      'HENDERSON',
      'HILLSBORO',
      'HURLOCK',
      'INGLESIDE',
      'KENNEDYVILLE',
      'MCDANIEL',
      'MADISON',
      'MARYDEL',
      'MASSEY',
      'MILLINGTON',
      'NEAVITT',
      'NEWCOMB',
      'OXFORD',
      'PRESTON',
      'PRICE',
      'QUEEN ANNE',
      'QUEENSTOWN',
      'RHODESDALE',
      'RIDGELY',
      'ROCK HALL',
      'ROYAL OALASKA',
      'SAINT MICHAELS',
      'SECRETARY',
      'SHERWOOD',
      'STEVENSVILLE',
      'STILL POND',
      'SUDLERSVILLE',
      'TAYLORS ISLAND',
      'TEMPLEVILLE',
      'TILGHMAN',
      'TODDVILLE',
      'TRAPPE',
      'WINGATE',
      'WITTMAN',
      'WOOLFORD',
      'WORTON',
      'WYE MILLS',
      'FREDERICK',
      'ADAMSTOWN',
      'BIG POOL',
      'BOONSBORO',
      'BRADDOCK HEIGHTS',
      'BROWNSVILLE',
      'BRUNSWICK',
      'BUCKEYSTOWN',
      'BURKITTSVILLE',
      'CASCADE',
      'CAVETOWN',
      'CHEWSVILLE',
      'CLEAR SPRING',
      'COOKSVILLE',
      'EMMITSBURG',
      'FAIRPLAY',
      'FUNKSTOWN',
      'GAPLAND',
      'GLENELG',
      'GLENWOOD',
      'HAGERSTOWN',
      'HANCOCK',
      'IJAMSVILLE',
      'JEFFERSON',
      'KEEDYSVILLE',
      'KEYMAR',
      'KNOXVILLE',
      'LADIESBURG',
      'LIBERTYTOWN',
      'LINWOOD',
      'LISBON',
      'LITTLE ORLEANS',
      'MAUGANSVILLE',
      'MIDDLETOWN',
      'MONROVIA',
      'MOUNT AIRY',
      'MYERSVILLE',
      'NEW MARKET',
      'NEW MIDWAY',
      'NEW WINDSOR',
      'POINT OF ROCKS',
      'ROCKY RIDGE',
      'ROHRERSVILLE',
      'SABILLASVILLE',
      'SAINT JAMES',
      'SHARPSBURG',
      'SMITHSBURG',
      'SYKESVILLE',
      'TANEYTOWN',
      'THURMONT',
      'TUSCARORA',
      'UNION BRIDGE',
      'UNIONVILLE',
      'WALKERSVILLE',
      'WEST FRIENDSHIP',
      'WILLIAMSPORT',
      'WOODBINE',
      'WOODSBORO',
      'SALISBURY',
      'ALLEN',
      'BERLIN',
      'BISHOPVILLE',
      'BIVALVE',
      'CRISFIELD',
      'DEAL ISLAND',
      'EDEN',
      'EWELL',
      'FRUITLAND',
      'GIRDLETREE',
      'HEBRON',
      'LINKWOOD',
      'MANOKIN',
      'MARDELA SPRINGS',
      'MARION STATION',
      'NANTICOKE',
      'NEWARK',
      'OCEAN CITY',
      'PARSONSBURG',
      'PITTSVILLE',
      'POCOMOKE CITY',
      'POWELLVILLE',
      'PRINCESS ANNE',
      'QUANTICO',
      'REHOBETH',
      'SHARPTOWN',
      'SHOWELL',
      'SNOW HILL',
      'STOCKTON',
      'TYASKIN',
      'TYLERTON',
      'UPPER FAIRMOUNT',
      'VIENNA',
      'WENONA',
      'WESTOVER',
      'WHALEYVILLE',
      'WILLARDS',
      'DELMAR',
      'NORTH EAST',
      'PERRY POINT',
      'PERRYVILLE',
      'PORT DEPOSIT',
      'RISING SUN',
      'WARWICK',
      'CECILTON',
      'CHARLESTOWN',
      'CHESAPEALASKAE CITY',
      'CHILDS',
      'COLORA',
      'CONOWINGO',
      'EARLEVILLE',
      'ELK MILLS',
      'ELKTON',
      'GEORGETOWN'
    ]
  },
  ME: {
    code: 'ME',
    name: 'MAINE',
    cities: [
      'BERWICK',
      'CAPE NEDDICK',
      'ELIOT',
      'KITTERY',
      'KITTERY POINT',
      'NORTH BERWICK',
      'OGUNQUIT',
      'SOUTH BERWICK',
      'YORK',
      'YORK BEACH',
      'YORK HARBOR',
      'ACTON',
      'ALFRED',
      'BAILEY ISLAND',
      'BAR MILLS',
      'BIDDEFORD',
      'BIDDEFORD POOL',
      'BOWDOINHAM',
      'BRIDGTON',
      'BROWNFIELD',
      'BRUNSWICK',
      'BUSTINS ISLAND',
      'CAPE PORPOISE',
      'CASCO',
      'CENTER LOVELL',
      'CHEBEAGUE ISLAND',
      'CLIFF ISLAND',
      'CORNISH',
      'CUMBERLAND CENTER',
      'DENMARK',
      'EAST BALDWIN',
      'LEBANON',
      'EAST PARSONFIELD',
      'SEBAGO',
      'EAST WATERBORO',
      'FREEPORT',
      'FRYEBURG',
      'GORHAM',
      'GRAY',
      'HARRISON',
      'HIRAM',
      'HOLLIS CENTER',
      'KENNEBUNK',
      'KENNEBUNKPORT',
      'PARSONSFIELD',
      'LIMERICK',
      'LIMINGTON',
      'LONG ISLAND',
      'LOVELL',
      'MEREPOINT',
      'MOODY',
      'NAPLES',
      'NEWFIELD',
      'NORTH BRIDGTON',
      'NORTH WATERBORO',
      'WINDHAM',
      'OCEAN PARK',
      'OLD ORCHARD BEACH',
      'ORRS ISLAND',
      'PORTER',
      'POWNAL',
      'SCARBOROUGH',
      'RAYMOND',
      'SACO',
      'SANFORD',
      'SEBAGO LALASKAE',
      'SHAPLEIGH',
      'SOUTH CASCO',
      'SOUTH FREEPORT',
      'HARPSWELL',
      'SOUTH WATERFORD',
      'SOUTH WINDHAM',
      'SPRINGVALE',
      'STANDISH',
      'STEEP FALLS',
      'TOPSHAM',
      'WATERBORO',
      'WATERFORD',
      'WELLS',
      'WEST BALDWIN',
      'WESTBROOK',
      'BUXTON',
      'WEST KENNEBUNK',
      'WEST NEWFIELD',
      'YARMOUTH',
      'NORTH YARMOUTH',
      'PORTLAND',
      'FALMOUTH',
      'SOUTH PORTLAND',
      'CAPE ELIZABETH',
      'PEALASKAS ISLAND',
      'CUMBERLAND FORESIDE',
      'AUBURN',
      'ANDOVER',
      'BETHEL',
      'BRYANT POND',
      'BUCKFIELD',
      'CANTON',
      'DURHAM',
      'DANVILLE',
      'DIXFIELD',
      'DRYDEN',
      'EAST ANDOVER',
      'EAST DIXFIELD',
      'EAST LIVERMORE',
      'EAST POLAND',
      'STONEHAM',
      'EAST WILTON',
      'GREENE',
      'HANOVER',
      'HEBRON',
      'JAY',
      'LEWISTON',
      'LISBON',
      'LISBON FALLS',
      'LIVERMORE',
      'LIVERMORE FALLS',
      'GREENWOOD',
      'MECHANIC FALLS',
      'MEXICO',
      'MINOT',
      'MONMOUTH',
      'NEW GLOUCESTER',
      'NEWRY',
      'NORTH JAY',
      'LEEDS',
      'NORTH MONMOUTH',
      'NORTH TURNER',
      'NORTH WATERFORD',
      'NORWAY',
      'OXFORD',
      'PARIS',
      'POLAND',
      'ROXBURY',
      'RUMFORD',
      'RUMFORD CENTER',
      'SABATTUS',
      'SOUTH PARIS',
      'TURNER',
      'TURNER CENTER',
      'WAYNE',
      'WELD',
      'WEST BETHEL',
      'BOWDOIN',
      'WEST MINOT',
      'WEST PARIS',
      'PERU',
      'WEST POLAND',
      'SUMNER',
      'WILTON',
      'AUGUSTA',
      'COOPERS MILLS',
      'DRESDEN',
      'EAST WINTHROP',
      'FARMINGDALE',
      'GARDINER',
      'RANDOLPH',
      'HALLOWELL',
      'JEFFERSON',
      'KENTS HILL',
      'LITCHFIELD',
      'MANCHESTER',
      'MOUNT VERNON',
      'WHITEFIELD',
      'PALERMO',
      'READFIELD',
      'RICHMOND',
      'SOUTH CHINA',
      'SOUTH GARDINER',
      'VIENNA',
      'WINDSOR',
      'WINTHROP',
      'BANGOR',
      'ABBOT',
      'AURORA',
      'BRADFORD',
      'BRADLEY',
      'BREWER',
      'BROOKTON',
      'BROWNVILLE',
      'BROWNVILLE JUNCTION',
      'BUCKSPORT',
      'BURLINGTON',
      'GREENBUSH',
      'CARMEL',
      'CASTINE',
      'CHARLESTON',
      'COSTIGAN',
      'DANFORTH',
      'DOVER FOXCROFT',
      'CORINTH',
      'EDDINGTON',
      'HOLDEN',
      'EAST MILLINOCKET',
      'EAST ORLAND',
      'ETNA',
      'EXETER',
      'FRANKFORT',
      'GREENVILLE',
      'GREENVILLE JUNCTION',
      'GUILFORD',
      'HAMPDEN',
      'HOWLAND',
      'HUDSON',
      'KENDUSKEAG',
      'KINGMAN',
      'LAGRANGE',
      'LAMBERT LALASKAE',
      'LEE',
      'LEVANT',
      'LINCOLN',
      'MATTAWAMKEAG',
      'MEDWAY',
      'MILFORD',
      'MILLINOCKET',
      'MILO',
      'MONSON',
      'OLAMON',
      'OLD TOWN',
      'ORONO',
      'ORIENT',
      'ORLAND',
      'ORRINGTON',
      'PASSADUMKEAG',
      'PENOBSCOT',
      'ROCKWOOD',
      'SANGERVILLE',
      'SEBEC',
      'SHIRLEY MILLS',
      'SPRINGFIELD',
      'STETSON',
      'STILLWATER',
      'TOPSFIELD',
      'VANCEBORO',
      'WAITE',
      'WEST ENFIELD',
      'WINN',
      'WINTERPORT',
      'WYTOPITLOCK',
      'BATH',
      'ALNA',
      'BAYVILLE',
      'BOOTHBAY',
      'BOOTHBAY HARBOR',
      'BRISTOL',
      'CHAMBERLAIN',
      'DAMARISCOTTA',
      'EAST BOOTHBAY',
      'FRIENDSHIP',
      'GEORGETOWN',
      'ISLE OF SPRINGS',
      'BREMEN',
      'NEWAGEN',
      'NEWCASTLE',
      'NEW HARBOR',
      'NOBLEBORO',
      'EDGECOMB',
      'PEMAQUID',
      'PHIPPSBURG',
      'CUSHING',
      'ROUND POND',
      'SEBASCO ESTATES',
      'SMALL POINT',
      'SOUTH BRISTOL',
      'SQUIRREL ISLAND',
      'TREVETT',
      'WALDOBORO',
      'WALPOLE',
      'WASHINGTON',
      'WEST BOOTHBAY HARBOR',
      'SOUTHPORT',
      'WISCASSET',
      'WOOLWICH',
      'ELLSWORTH',
      'ADDISON',
      'GOULDSBORO',
      'BAR HARBOR',
      'BEALS',
      'BERNARD',
      'BIRCH HARBOR',
      'BLUE HILL',
      'BLUE HILL FALLS',
      'BROOKLIN',
      'BROOKSVILLE',
      'CALAIS',
      'CHERRYFIELD',
      'COLUMBIA FALLS',
      'COREA',
      'CRANBERRY ISLES',
      'CUTLER',
      'DEER ISLE',
      'DENNYSVILLE',
      'EAST BLUE HILL',
      'EAST MACHIAS',
      'EASTPORT',
      'FRANKLIN',
      'FRENCHBORO',
      'GRAND LALASKAE STREAM',
      'HANCOCK',
      'HARBORSIDE',
      'HARRINGTON',
      'HULLS COVE',
      'ISLE AU HAUT',
      'ISLESFORD',
      'JONESBORO',
      'JONESPORT',
      'LITTLE DEER ISLE',
      'LUBEC',
      'BASS HARBOR',
      'MACHIAS',
      'MACHIASPORT',
      'MANSET',
      'MEDDYBEMPS',
      'MILBRIDGE',
      'MOUNT DESERT',
      'NORTHEAST HARBOR',
      'SULLIVAN',
      'OTTER CREEK',
      'PEMBROKE',
      'PERRY',
      'PRINCETON',
      'PROSPECT HARBOR',
      'ROBBINSTON',
      'SALSBURY COVE',
      'SARGENTVILLE',
      'SEAL COVE',
      'SEAL HARBOR',
      'SEDGWICK',
      'SORRENTO',
      'SOUTHWEST HARBOR',
      'STEUBEN',
      'STONINGTON',
      'SUNSET',
      'SURRY',
      'SWANS ISLAND',
      'WESLEY',
      'WEST TREMONT',
      'WHITING',
      'WINTER HARBOR',
      'BAILEYVILLE',
      'HOULTON',
      'ASHLAND',
      'BENEDICTA',
      'BLAINE',
      'BRIDGEWATER',
      'CARIBOU',
      'CLAYTON LALASKAE',
      'CROUSEVILLE',
      'EAGLE LALASKAE',
      'EASTON',
      'ESTCOURT STATION',
      'FORT FAIRFIELD',
      'FORT KENT',
      'FORT KENT MILLS',
      'FRENCHVILLE',
      'GRAND ISLE',
      'ISLAND FALLS',
      'LIMESTONE',
      'MADAWASKA',
      'MAPLETON',
      'MARS HILL',
      'MASARDIS',
      'MONTICELLO',
      'NEW LIMERICK',
      'NEW SWEDEN',
      'OALASKAFIELD',
      'OXBOW',
      'PATTEN',
      'PERHAM',
      'PORTAGE',
      'PRESQUE ISLE',
      'QUIMBY',
      'SAINT AGATHA',
      'SAINT DAVID',
      'SAINT FRANCIS',
      'SHERIDAN',
      'SHERMAN MILLS',
      'SHERMAN STATION',
      'SINCLAIR',
      'SMYRNA MILLS',
      'WALLAGRASS',
      'STACYVILLE',
      'STOCKHOLM',
      'VAN BUREN',
      'WASHBURN',
      'WESTFIELD',
      'WINTERVILLE',
      'ROCKLAND',
      'CAMDEN',
      'GLEN COVE',
      'HOPE',
      'ISLESBORO',
      'LINCOLNVILLE',
      'LINCOLNVILLE CENTER',
      'MATINICUS',
      'MONHEGAN',
      'NORTH HAVEN',
      'OWLS HEAD',
      'PORT CLYDE',
      'ROCKPORT',
      'SAINT GEORGE',
      'SOUTH THOMASTON',
      'SPRUCE HEAD',
      'TENANTS HARBOR',
      'THOMASTON',
      'UNION',
      'VINALHAVEN',
      'WARREN',
      'WEST ROCKPORT',
      'WATERVILLE',
      'ALBION',
      'ANSON',
      'ATHENS',
      'BELFAST',
      'BELGRADE',
      'BELGRADE LALASKAES',
      'BINGHAM',
      'BROOKS',
      'BURNHAM',
      'CAMBRIDGE',
      'CANAAN',
      'CARATUNK',
      'CHINA VILLAGE',
      'CLINTON',
      'CORINNA',
      'DETROIT',
      'DEXTER',
      'DIXMONT',
      'EAST NEWPORT',
      'EAST VASSALBORO',
      'EUSTIS',
      'FAIRFIELD',
      'FARMINGTON',
      'GARLAND',
      'FARMINGTON FALLS',
      'FREEDOM',
      'HARMONY',
      'HARTLAND',
      'HINCKLEY',
      'JACKMAN',
      'KINGFIELD',
      'LIBERTY',
      'MADISON',
      'MONROE',
      'MORRILL',
      'NEWPORT',
      'NEW PORTLAND',
      'NEW SHARON',
      'NEW VINEYARD',
      'NORRIDGEWOCK',
      'NORTH ANSON',
      'NORTH NEW PORTLAND',
      'NORTH VASSALBORO',
      'OALASKALAND',
      'OQUOSSOC',
      'PALMYRA',
      'PHILLIPS',
      'PITTSFIELD',
      'PLYMOUTH',
      'RANGELEY',
      'SAINT ALBANS',
      'SANDY POINT',
      'SEARSMONT',
      'SEARSPORT',
      'SHAWMUT',
      'SKOWHEGAN',
      'SMITHFIELD',
      'SOLON',
      'STOCKTON SPRINGS',
      'STRATTON',
      'STRONG',
      'TEMPLE',
      'WEST FORKS',
      'THORNDIKE',
      'TROY',
      'UNITY',
      'VASSALBORO',
      'WEST FARMINGTON'
    ]
  },
  MI: {
    code: 'MI',
    name: 'MICHIGAN',
    cities: [
      'ALGONAC',
      'ALLENTON',
      'ALMONT',
      'ANCHORVILLE',
      'ARMADA',
      'AVOCA',
      'TROY',
      'BIRMINGHAM',
      'CAPAC',
      'CENTER LINE',
      'CLAWSON',
      'EASTPOINTE',
      'EMMETT',
      'FAIR HAVEN',
      'FRANKLIN',
      'FRASER',
      'GOODELLS',
      'HARSENS ISLAND',
      'HAZEL PARK',
      'JEDDO',
      'SOUTHFIELD',
      'CLINTON TOWNSHIP',
      'MARINE CITY',
      'MARYSVILLE',
      'MEMPHIS',
      'MACOMB',
      'MOUNT CLEMENS',
      'HARRISON TOWNSHIP',
      'NEW BALTIMORE',
      'NEW HAVEN',
      'NORTH STREET',
      'EAST CHINA',
      'FORT GRATIOT',
      'PORT HURON',
      'RICHMOND',
      'COLUMBUS',
      'CASCO',
      'ROMEO',
      'ROSEVILLE',
      'ROYAL OALASKA',
      'PLEASANT RIDGE',
      'HUNTINGTON WOODS',
      'MADISON HEIGHTS',
      'BERKLEY',
      'SMITHS CREEK',
      'SAINT CLAIR',
      'SAINT CLAIR SHORES',
      'WARREN',
      'WASHINGTON',
      'RAY',
      'YALE',
      'ALLEN PARK',
      'ANN ARBOR',
      'AZALIA',
      'BELLEVILLE',
      'BRIGHTON',
      'BRIDGEWATER',
      'CARLETON',
      'CHELSEA',
      'DEARBORN',
      'MELVINDALE',
      'DEARBORN HEIGHTS',
      'DEXTER',
      'DUNDEE',
      'ERIE',
      'FLAT ROCK',
      'GARDEN CITY',
      'GREGORY',
      'GROSSE ILE',
      'HAMBURG',
      'IDA',
      'INKSTER',
      'LALASKAELAND',
      'LAMBERTVILLE',
      'LA SALLE',
      'LINCOLN PARK',
      'LIVONIA',
      'LUNA PIER',
      'MANCHESTER',
      'MAYBEE',
      'MILAN',
      'MONROE',
      'NEW BOSTON',
      'NEW HUDSON',
      'NEWPORT',
      'NORTHVILLE',
      'PINCKNEY',
      'PLYMOUTH',
      'ROCKWOOD',
      'ROMULUS',
      'SALEM',
      'SALINE',
      'SAMARIA',
      'SOUTH LYON',
      'SOUTH ROCKWOOD',
      'TAYLOR',
      'TEMPERANCE',
      'TRENTON',
      'WAYNE',
      'WESTLAND',
      'CANTON',
      'WHITMORE LALASKAE',
      'WHITTALASKAER',
      'WILLIS',
      'WYANDOTTE',
      'SOUTHGATE',
      'YPSILANTI',
      'DETROIT',
      'HIGHLAND PARK',
      'HAMTRAMCK',
      'RIVER ROUGE',
      'FERNDALE',
      'HARPER WOODS',
      'ECORSE',
      'GROSSE POINTE',
      'OALASKA PARK',
      'REDFORD',
      'BLOOMFIELD HILLS',
      'ROCHESTER',
      'STERLING HEIGHTS',
      'UTICA',
      'KEEGO HARBOR',
      'AUBURN HILLS',
      'WEST BLOOMFIELD',
      'WATERFORD',
      'DRAYTON PLAINS',
      'FARMINGTON',
      'PONTIAC',
      'CLARKSTON',
      'DAVISBURG',
      'HARTLAND',
      'HIGHLAND',
      'LALASKAE ORION',
      'OALASKALAND',
      'LALASKAEVILLE',
      'LEONARD',
      'OXFORD',
      'NOVI',
      'MILFORD',
      'COMMERCE TOWNSHIP',
      'WHITE LALASKAE',
      'UNION LALASKAE',
      'WALLED LALASKAE',
      'WIXOM',
      'APPLEGATE',
      'ARGYLE',
      'ATLAS',
      'ATTICA',
      'BAD AXE',
      'BANCROFT',
      'BIRCH RUN',
      'BROWN CITY',
      'BURT',
      'BYRON',
      'CARSONVILLE',
      'CLIO',
      'COLUMBIAVILLE',
      'CROSWELL',
      'DAVISON',
      'DECKER',
      'DECKERVILLE',
      'DRYDEN',
      'DURAND',
      'FENTON',
      'FILION',
      'FLUSHING',
      'FORESTVILLE',
      'FOSTORIA',
      'GAINES',
      'GENESEE',
      'GOODRICH',
      'GRAND BLANC',
      'HADLEY',
      'HARBOR BEACH',
      'HOLLY',
      'IMLAY CITY',
      'KINDE',
      'LAPEER',
      'LENNON',
      'LEXINGTON',
      'LINDEN',
      'MARLETTE',
      'MELVIN',
      'METAMORA',
      'MINDEN CITY',
      'MONTROSE',
      'MOUNT MORRIS',
      'NEW LOTHROP',
      'NORTH BRANCH',
      'ORTONVILLE',
      'OTISVILLE',
      'OTTER LALASKAE',
      'PALMS',
      'PECK',
      'PORT AUSTIN',
      'PORT HOPE',
      'PORT SANILAC',
      'RUTH',
      'SANDUSKY',
      'SNOVER',
      'SWARTZ CREEK',
      'UBLY',
      'VERNON',
      'FLINT',
      'BURTON',
      'SAGINAW',
      'ALGER',
      'AUBURN',
      'BEAVERTON',
      'BENTLEY',
      'BRANT',
      'BRECKENRIDGE',
      'CHESANING',
      'CLARE',
      'COLEMAN',
      'COMINS',
      'EDENVILLE',
      'FAIRVIEW',
      'FARWELL',
      'FREELAND',
      'GLADWIN',
      'HARRISON',
      'HEMLOCK',
      'HIGGINS LALASKAE',
      'HOPE',
      'HOUGHTON LALASKAE',
      'HOUGHTON LALASKAE HEIGHTS',
      'KAWKAWLIN',
      'LALASKAE',
      'LALASKAE GEORGE',
      'LINWOOD',
      'LUPTON',
      'LUZERNE',
      'MERRILL',
      'MIDLAND',
      'MIO',
      'OALASKALEY',
      'PINCONNING',
      'PRUDENVILLE',
      'RHODES',
      'ROSCOMMON',
      'ROSE CITY',
      'SAINT CHARLES',
      'SAINT HELEN',
      'SANFORD',
      'STANDISH',
      'STERLING',
      'WEST BRANCH',
      'WHEELER',
      'ALASKARON',
      'AU GRES',
      'BARTON CITY',
      'BAY CITY',
      'UNIVERSITY CENTER',
      'BAY PORT',
      'BLACK RIVER',
      'BRIDGEPORT',
      'CARO',
      'CARROLLTON',
      'CASEVILLE',
      'CASS CITY',
      'CLIFFORD',
      'CURRAN',
      'DEFORD',
      'EAST TAWAS',
      'ELKTON',
      'ESSEXVILLE',
      'FAIRGROVE',
      'FRANKENMUTH',
      'GAGETOWN',
      'GILFORD',
      'GLENNIE',
      'GREENBUSH',
      'HALE',
      'HARRISVILLE',
      'KINGSTON',
      'LINCOLN',
      'LONG LALASKAE',
      'MAYVILLE',
      'MIKADO',
      'MILLINGTON',
      'MUNGER',
      'NATIONAL CITY',
      'OMER',
      'OSCODA',
      'OWENDALE',
      'PIGEON',
      'PRESCOTT',
      'REESE',
      'RICHVILLE',
      'SEBEWAING',
      'SILVERWOOD',
      'SOUTH BRANCH',
      'SPRUCE',
      'TAWAS CITY',
      'TURNER',
      'TWINING',
      'UNIONVILLE',
      'VASSAR',
      'TUSCOLA',
      'WHITTEMORE',
      'ALMA',
      'MOUNT PLEASANT',
      'OKEMOS',
      'ASHLEY',
      'BANNISTER',
      'BATH',
      'BELDING',
      'CARSON CITY',
      'CEDAR LALASKAE',
      'CHARLOTTE',
      'CLARKSVILLE',
      'COHOCTAH',
      'CORUNNA',
      'CRYSTAL',
      'DANSVILLE',
      'DEWITT',
      'DIMONDALE',
      'EAGLE',
      'EAST LANSING',
      'EATON RAPIDS',
      'EDMORE',
      'ELM HALL',
      'ELSIE',
      'ELWELL',
      'EUREKA',
      'FENWICK',
      'FOWLER',
      'FOWLERVILLE',
      'GRAND LEDGE',
      'GREENVILLE',
      'HASLETT',
      'HENDERSON',
      'HOLT',
      'HOWELL',
      'HUBBARDSTON',
      'IONIA',
      'ITHACA',
      'LAINGSBURG',
      'LALASKAE ODESSA',
      'LALASKAEVIEW',
      'LYONS',
      'MCBRIDES',
      'MAPLE RAPIDS',
      'MASON',
      'MIDDLETON',
      'MORRICE',
      'MUIR',
      'MULLIKEN',
      'NORTH STAR',
      'OALASKA GROVE',
      'ORLEANS',
      'OVID',
      'OWOSSO',
      'PALO',
      'PERRINTON',
      'PERRY',
      'PEWAMO',
      'POMPEII',
      'PORTLAND',
      'POTTERVILLE',
      'RIVERDALE',
      'ROSEBUSH',
      'SAINT JOHNS',
      'SAINT LOUIS',
      'SARANAC',
      'SHAFTSBURG',
      'SHEPHERD',
      'SHERIDAN',
      'SIDNEY',
      'SIX LALASKAES',
      'SMYRNA',
      'STANTON',
      'SUMNER',
      'SUNFIELD',
      'VESTABURG',
      'WEBBERVILLE',
      'WEIDMAN',
      'WESTPHALIA',
      'WILLIAMSTON',
      'WINN',
      'WOODLAND',
      'LANSING',
      'KALAMAZOO',
      'PORTAGE',
      'ALLEGAN',
      'ATHENS',
      'AUGUSTA',
      'BANGOR',
      'BATTLE CREEK',
      'BEDFORD',
      'BELLEVUE',
      'BENTON HARBOR',
      'BLOOMINGDALE',
      'BREEDSVILLE',
      'BRONSON',
      'BURLINGTON',
      'BURR OALASKA',
      'CASSOPOLIS',
      'CENTREVILLE',
      'CERESCO',
      'CLIMAX',
      'CLOVERDALE',
      'COLDWATER',
      'COLOMA',
      'HAGAR SHORES',
      'COLON',
      'COMSTOCK',
      'CONSTANTINE',
      'COVERT',
      'DECATUR',
      'DELTON',
      'DOWAGIAC',
      'DOWLING',
      'EAST LEROY',
      'FULTON',
      'GALESBURG',
      'GOBLES',
      'GRAND JUNCTION',
      'HARTFORD',
      'HASTINGS',
      'HICKORY CORNERS',
      'JONES',
      'KENDALL',
      'LACOTA',
      'LAWRENCE',
      'LAWTON',
      'LEONIDAS',
      'MARCELLUS',
      'MARSHALL',
      'MARTIN',
      'MATTAWAN',
      'MENDON',
      'NASHVILLE',
      'NAZARETH',
      'NOTTAWA',
      'OLIVET',
      'OSHTEMO',
      'OTSEGO',
      'PAW PAW',
      'PLAINWELL',
      'QUINCY',
      'RICHLAND',
      'RIVERSIDE',
      'SAINT JOSEPH',
      'SCHOOLCRAFT',
      'SCOTTS',
      'SHERWOOD',
      'SOUTH HAVEN',
      'STURGIS',
      'TEKONSHA',
      'THREE RIVERS',
      'UNION CITY',
      'VANDALIA',
      'VERMONTVILLE',
      'VICKSBURG',
      'WATERVLIET',
      'WHITE PIGEON',
      'BARODA',
      'BERRIEN CENTER',
      'BERRIEN SPRINGS',
      'BRIDGMAN',
      'BUCHANAN',
      'EAU CLAIRE',
      'EDWARDSBURG',
      'GALIEN',
      'HARBERT',
      'LALASKAESIDE',
      'NEW BUFFALO',
      'NEW TROY',
      'NILES',
      'SAWYER',
      'SODUS',
      'STEVENSVILLE',
      'THREE OALASKAS',
      'UNION PIER',
      'UNION',
      'JACKSON',
      'ADDISON',
      'ADRIAN',
      'ALBION',
      'ALLEN',
      'BLISSFIELD',
      'BRITTON',
      'BROOKLYN',
      'CAMDEN',
      'CEMENT CITY',
      'CLARKLALASKAE',
      'CLAYTON',
      'CLINTON',
      'CONCORD',
      'DEERFIELD',
      'FRONTIER',
      'GRASS LALASKAE',
      'HANOVER',
      'HILLSDALE',
      'HOMER',
      'HORTON',
      'HUDSON',
      'JASPER',
      'JEROME',
      'JONESVILLE',
      'LESLIE',
      'LITCHFIELD',
      'MANITOU BEACH',
      'MICHIGAN CENTER',
      'MONTGOMERY',
      'MORENCI',
      'MOSCOW',
      'MOSHERVILLE',
      'MUNITH',
      'NAPOLEON',
      'NORTH ADAMS',
      'NORVELL',
      'ONONDAGA',
      'ONSTED',
      'OSSEO',
      'OTTAWA LALASKAE',
      'PALMYRA',
      'PARMA',
      'PETERSBURG',
      'PITTSFORD',
      'PLEASANT LALASKAE',
      'READING',
      'RIDGEWAY',
      'RIGA',
      'RIVES JUNCTION',
      'ROLLIN',
      'SAND CREEK',
      'SENECA',
      'SOMERSET',
      'SOMERSET CENTER',
      'SPRING ARBOR',
      'SPRINGPORT',
      'STOCKBRIDGE',
      'TECUMSEH',
      'TIPTON',
      'WALDRON',
      'WESTON',
      'ADA',
      'ALTO',
      'BAILEY',
      'BALDWIN',
      'BARRYTON',
      'BELMONT',
      'BIG RAPIDS',
      'BITELY',
      'BLANCHARD',
      'BRADLEY',
      'BROHMAN',
      'BURNIPS',
      'BYRON CENTER',
      'CALEDONIA',
      'CANNONSBURG',
      'CASNOVIA',
      'CEDAR SPRINGS',
      'CHIPPEWA LALASKAE',
      'COMSTOCK PARK',
      'CORAL',
      'DORR',
      'FREEPORT',
      'GOWEN',
      'GRANT',
      'HOPKINS',
      'HOWARD CITY',
      'KENT CITY',
      'LOWELL',
      'MECOSTA',
      'MIDDLEVILLE',
      'MOLINE',
      'MORLEY',
      'NEWAYGO',
      'PARIS',
      'PIERSON',
      'REMUS',
      'ROCKFORD',
      'RODNEY',
      'SAND LALASKAE',
      'SHELBYVILLE',
      'SPARTA',
      'STANWOOD',
      'TRUFANT',
      'WAYLAND',
      'WHITE CLOUD',
      'ALLENDALE',
      'BRANCH',
      'CONKLIN',
      'COOPERSVILLE',
      'CUSTER',
      'DOUGLAS',
      'FENNVILLE',
      'FERRYSBURG',
      'FOUNTAIN',
      'FREE SOIL',
      'FREMONT',
      'FRUITPORT',
      'GLENN',
      'GRAND HAVEN',
      'GRANDVILLE',
      'HAMILTON',
      'HART',
      'HESPERIA',
      'HOLLAND',
      'HOLTON',
      'HUDSONVILLE',
      'JAMESTOWN',
      'JENISON',
      'LAMONT',
      'LUDINGTON',
      'MACATAWA',
      'MARNE',
      'MEARS',
      'MONTAGUE',
      'MUSKEGON',
      'NEW ERA',
      'NUNICA',
      'PENTWATER',
      'PULLMAN',
      'RAVENNA',
      'ROTHBURY',
      'SAUGATUCK',
      'SCOTTVILLE',
      'SHELBY',
      'SPRING LALASKAE',
      'TWIN LALASKAE',
      'WALHALLA',
      'WALKERVILLE',
      'WEST OLIVE',
      'WHITEHALL',
      'WABANINGO',
      'ZEELAND',
      'GRAND RAPIDS',
      'CADILLAC',
      'ACME',
      'ALBA',
      'ALDEN',
      'ARCADIA',
      'BEAR LALASKAE',
      'BELLAIRE',
      'BENZONIA',
      'BEULAH',
      'BOON',
      'BRETHREN',
      'BUCKLEY',
      'CEDAR',
      'CENTRAL LALASKAE',
      'CHASE',
      'COPEMISH',
      'EASTLALASKAE',
      'EASTPORT',
      'ELBERTA',
      'ELK RAPIDS',
      'EMPIRE',
      'EVART',
      'FALMOUTH',
      'FIFE LALASKAE',
      'FILER CITY',
      'FRANKFORT',
      'GLEN ARBOR',
      'GRAWN',
      'HARRIETTA',
      'HERSEY',
      'HONOR',
      'IDLEWILD',
      'INTERLOCHEN',
      'IRONS',
      'KALEVA',
      'KALKASKA',
      'KEWADIN',
      'KINGSLEY',
      'LALASKAE ANN',
      'LALASKAE CITY',
      'LALASKAE LEELANAU',
      'LELAND',
      'LEROY',
      'LUTHER',
      'MC BAIN',
      'MANCELONA',
      'MANISTEE',
      'MANTON',
      'MAPLE CITY',
      'MARION',
      'MAYFIELD',
      'MERRITT',
      'MESICK',
      'NORTHPORT',
      'OLD MISSION',
      'OMENA',
      'ONEKAMA',
      'RAPID CITY',
      'REED CITY',
      'SEARS',
      'SOUTH BOARDMAN',
      'SUTTONS BAY',
      'THOMPSONVILLE',
      'TRAVERSE CITY',
      'TUSTIN',
      'WELLSTON',
      'WILLIAMSBURG',
      'MACKINAW CITY',
      'AFTON',
      'ALANSON',
      'ALPENA',
      'ATLANTA',
      'BARBEAU',
      'BAY SHORE',
      'BOYNE CITY',
      'BOYNE FALLS',
      'BRIMLEY',
      'BRUTUS',
      'BURT LALASKAE',
      'CARP LALASKAE',
      'CEDARVILLE',
      'CHARLEVOIX',
      'CHEBOYGAN',
      'CONWAY',
      'CROSS VILLAGE',
      'DAFTER',
      'DE TOUR VILLAGE',
      'DRUMMOND ISLAND',
      'EAST JORDAN',
      'ECKERMAN',
      'ELLSWORTH',
      'ELMIRA',
      'FREDERIC',
      'GAYLORD',
      'GOETZVILLE',
      'GOOD HART',
      'GRAYLING',
      'HARBOR SPRINGS',
      'HAWKS',
      'HERRON',
      'HESSEL',
      'HILLMAN',
      'HUBBARD LALASKAE',
      'HULBERT',
      'INDIAN RIVER',
      'JOHANNESBURG',
      'KINROSS',
      'LACHINE',
      'LEVERING',
      'LEWISTON',
      'MACKINAC ISLAND',
      'MILLERSBURG',
      'MORAN',
      'MULLETT LALASKAE',
      'NAUBINWAY',
      'ODEN',
      'ONAWAY',
      'OSSINEKE',
      'PARADISE',
      'PELLSTON',
      'PETOSKEY',
      'PICKFORD',
      'POINTE AUX PINS',
      'POSEN',
      'PRESQUE ISLE',
      'ROGERS CITY',
      'RUDYARD',
      'SAINT IGNACE',
      'BEAVER ISLAND',
      'SAULT SAINTE MARIE',
      'KINCHELOE',
      'STRONGS',
      'TOPINABEE',
      'TOWER',
      'TROUT LALASKAE',
      'VANDERBILT',
      'WALLOON LALASKAE',
      'WATERS',
      'WOLVERINE',
      'IRON MOUNTAIN',
      'KINGSFORD',
      'ALLOUEZ',
      'AU TRAIN',
      'BARK RIVER',
      'BIG BAY',
      'CARNEY',
      'CEDAR RIVER',
      'CHAMPION',
      'CHANNING',
      'CHATHAM',
      'COOKS',
      'CORNELL',
      'ARNOLD',
      'CURTIS',
      'DAGGETT',
      'DEERTON',
      'EBEN JUNCTION',
      'RUMELY',
      'ENGADINE',
      'ESCANABA',
      'FELCH',
      'LITTLE LALASKAE',
      'FOSTER CITY',
      'GARDEN',
      'GERMFASK',
      'GLADSTONE',
      'GOULD CITY',
      'GRAND MARAIS',
      'GULLIVER',
      'GWINN',
      'HARRIS',
      'HERMANSVILLE',
      'INGALLS',
      'ISHPEMING',
      'LORETTO',
      'MC MILLAN',
      'MANISTIQUE',
      'MARQUETTE',
      'MENOMINEE',
      'MICHIGAMME',
      'MUNISING',
      'NADEAU',
      'NAHMA',
      'NATIONAL MINE',
      'NEGAUNEE',
      'NEWBERRY',
      'NORWAY',
      'PALMER',
      'PERKINS',
      'PERRONVILLE',
      'POWERS',
      'QUINNESEC',
      'RALPH',
      'RAPID RIVER',
      'REPUBLIC',
      'ROCK',
      'SAGOLA',
      'SENEY',
      'SHINGLETON',
      'SKANDIA',
      'SPALDING',
      'STEPHENSON',
      'TRENARY',
      'VULCAN',
      'WALLACE',
      'WELLS',
      'WETMORE',
      'WILSON',
      'AHMEEK',
      'ALPHA',
      'AMASA',
      'ATLANTIC MINE',
      'BARAGA',
      'BERGLAND',
      'BESSEMER',
      'BRUCE CROSSING',
      'CALUMET',
      'CASPIAN',
      'CHASSELL',
      'COPPER CITY',
      'COPPER HARBOR',
      'COVINGTON',
      'CRYSTAL FALLS',
      'DODGEVILLE',
      'DOLLAR BAY',
      'EWEN',
      'GAASTRA',
      'GREENLAND',
      'HANCOCK',
      'HOUGHTON',
      'HUBBELL',
      'IRON RIVER',
      'IRONWOOD',
      'KEARSARGE',
      'LALASKAE LINDEN',
      'LANSE',
      'MARENISCO',
      'MASS CITY',
      'MOHAWK',
      'NISULA',
      'ONTONAGON',
      'PAINESDALE',
      'PELKIE',
      'RAMSAY',
      'ROCKLAND',
      'SIDNAW',
      'SKANEE',
      'SOUTH RANGE',
      'STAMBAUGH',
      'TOIVOLA',
      'TROUT CREEK',
      'WALASKAEFIELD',
      'WATERSMEET',
      'WATTON',
      'WHITE PINE'
    ]
  },
  MN: {
    code: 'MN',
    name: 'MINNESOTA',
    cities: [
      'AFTON',
      'ALMELUND',
      'BAYPORT',
      'BETHEL',
      'BRAHAM',
      'BROOK PARK',
      'CAMBRIDGE',
      'CANNON FALLS',
      'CASTLE ROCK',
      'CEDAR',
      'CENTER CITY',
      'CHISAGO CITY',
      'CIRCLE PINES',
      'COTTAGE GROVE',
      'DALBO',
      'DENNISON',
      'DUNDAS',
      'ELKO',
      'FARIBAULT',
      'FARMINGTON',
      'FOREST LALASKAE',
      'FRONTENAC',
      'GOODHUE',
      'GRANDY',
      'GRASSTON',
      'HAMPTON',
      'HARRIS',
      'HASTINGS',
      'HENRIETTE',
      'HINCKLEY',
      'HUGO',
      'ISANTI',
      'LALASKAE CITY',
      'LALASKAE ELMO',
      'LALASKAELAND',
      'LALASKAEVILLE',
      'LINDSTROM',
      'LONSDALE',
      'MARINE ON SAINT CROIX',
      'MEDFORD',
      'MORA',
      'MORRISTOWN',
      'NERSTRAND',
      'NEW MARKET',
      'NEWPORT',
      'NORTH BRANCH',
      'NORTHFIELD',
      'OWATONNA',
      'PINE CITY',
      'RANDOLPH',
      'RED WING',
      'ROCK CREEK',
      'ROSEMOUNT',
      'RUSH CITY',
      'SAINT FRANCIS',
      'SAINT PAUL PARK',
      'SANDSTONE',
      'SCANDIA',
      'SHAFER',
      'SOUTH SAINT PAUL',
      'INVER GROVE HEIGHTS',
      'STACY',
      'STANCHFIELD',
      'STILLWATER',
      'TAYLORS FALLS',
      'VERMILLION',
      'WARSAW',
      'WEBSTER',
      'WELCH',
      'WILLERNIE',
      'WYOMING',
      'SAINT PAUL',
      'MENDOTA',
      'ALBERTVILLE',
      'ANNANDALE',
      'ANOKA',
      'ANDOVER',
      'HOPKINS',
      'BURNSVILLE',
      'ARLINGTON',
      'BECKER',
      'BIG LALASKAE',
      'BIRD ISLAND',
      'OSSEO',
      'BROWNTON',
      'BUFFALO',
      'BUFFALO LALASKAE',
      'CARVER',
      'CHAMPLIN',
      'CHANHASSEN',
      'CHASKA',
      'CLEAR LALASKAE',
      'CLEARWATER',
      'COKATO',
      'COLOGNE',
      'CRYSTAL BAY',
      'DARWIN',
      'DASSEL',
      'DAYTON',
      'DELANO',
      'EDEN VALLEY',
      'ELK RIVER',
      'EXCELSIOR',
      'FAIRFAX',
      'FRANKLIN',
      'GAYLORD',
      'GIBBON',
      'GLENCOE',
      'GREEN ISLE',
      'HAMBURG',
      'HAMEL',
      'HANOVER',
      'HECTOR',
      'EDEN PRAIRIE',
      'MINNETONKA',
      'MAPLE PLAIN',
      'HOWARD LALASKAE',
      'HUTCHINSON',
      'JORDAN',
      'KIMBALL',
      'LESTER PRAIRIE',
      'LITCHFIELD',
      'LONG LALASKAE',
      'LORETTO',
      'MAPLE LALASKAE',
      'MAYER',
      'MINNETONKA BEACH',
      'MONTICELLO',
      'MONTROSE',
      'MOUND',
      'NEW AUBURN',
      'NEW GERMANY',
      'NORWOOD',
      'PLATO',
      'PRINCETON',
      'PRIOR LALASKAE',
      'ROCKFORD',
      'ROGERS',
      'SAINT BONIFACIUS',
      'SAINT MICHAEL',
      'SANTIAGO',
      'SAVAGE',
      'SHALASKAOPEE',
      'SILVER CREEK',
      'SILVER LALASKAE',
      'SOUTH HAVEN',
      'SPRING PARK',
      'STEWART',
      'VICTORIA',
      'WACONIA',
      'WATERTOWN',
      'WATKINS',
      'WAVERLY',
      'WAYZATA',
      'NAVARRE',
      'YOUNG AMERICA',
      'WINSTED',
      'WINTHROP',
      'ZIMMERMAN',
      'MINNEAPOLIS',
      'BEAVER BAY',
      'BRIMSON',
      'FINLAND',
      'GRAND MARAIS',
      'GRAND PORTAGE',
      'HOVLAND',
      'ISABELLA',
      'KNIFE RIVER',
      'LUTSEN',
      'SCHROEDER',
      'SILVER BAY',
      'TOFTE',
      'TWO HARBORS',
      'ADOLPH',
      'ALBORN',
      'ANGORA',
      'ASKOV',
      'AURORA',
      'BABBITT',
      'BARNUM',
      'BIWABIK',
      'BOVEY',
      'BRITT',
      'BROOKSTON',
      'BRUNO',
      'BUHL',
      'CALUMET',
      'CANYON',
      'CARLTON',
      'CHISHOLM',
      'CLOQUET',
      'COHASSET',
      'COLERAINE',
      'COOK',
      'COTTON',
      'CRANE LALASKAE',
      'CROMWELL',
      'GRAND RAPIDS',
      'ELY',
      'EMBARRASS',
      'ESKO',
      'EVELETH',
      'FINLAYSON',
      'FLOODWOOD',
      'FORBES',
      'GILBERT',
      'GOODLAND',
      'HIBBING',
      'HILL CITY',
      'HOLYOKE',
      'HOYT LALASKAES',
      'IRON',
      'JACOBSON',
      'KEEWATIN',
      'KERRICK',
      'KETTLE RIVER',
      'KINNEY',
      'MCGREGOR',
      'MALASKAINEN',
      'MARBLE',
      'MEADOWLANDS',
      'MELRUDE',
      'MOOSE LALASKAE',
      'MOUNTAIN IRON',
      'NASHWAUK',
      'ORR',
      'NETT LALASKAE',
      'PENGILLY',
      'VIRGINIA',
      'SAGINAW',
      'SAWYER',
      'SIDE LALASKAE',
      'SOUDAN',
      'STURGEON LALASKAE',
      'SWAN RIVER',
      'SWATARA',
      'TACONITE',
      'TAMARACK',
      'TOWER',
      'TWIG',
      'WARBA',
      'WILLOW RIVER',
      'WINTON',
      'WRENSHALL',
      'WRIGHT',
      'DULUTH',
      'ROCHESTER',
      'ADAMS',
      'ALTURA',
      'AUSTIN',
      'BLOOMING PRAIRIE',
      'BROWNSDALE',
      'BROWNSVILLE',
      'BYRON',
      'CALEDONIA',
      'CANTON',
      'CHATFIELD',
      'CLAREMONT',
      'DALASKAOTA',
      'DEXTER',
      'DODGE CENTER',
      'DOVER',
      'EITZEN',
      'ELGIN',
      'ELKTON',
      'EYOTA',
      'FOUNTAIN',
      'GRAND MEADOW',
      'HARMONY',
      'HAYFIELD',
      'HOKAH',
      'HOMER',
      'HOUSTON',
      'KASSON',
      'KELLOGG',
      'KENYON',
      'LA CRESCENT',
      'LANESBORO',
      'LANSING',
      'LE ROY',
      'LEWISTON',
      'LYLE',
      'MABEL',
      'MANTORVILLE',
      'MAZEPPA',
      'MILLVILLE',
      'MINNESOTA CITY',
      'ORONOCO',
      'OSTRANDER',
      'PETERSON',
      'PINE ISLAND',
      'PLAINVIEW',
      'PRESTON',
      'RACINE',
      'READS LANDING',
      'ROLLINGSTONE',
      'ROSE CREEK',
      'RUSHFORD',
      'SAINT CHARLES',
      'SARGEANT',
      'SPRING GROVE',
      'SPRING VALLEY',
      'STEWARTVILLE',
      'TAOPI',
      'UTICA',
      'WABASHA',
      'WALTHAM',
      'WANAMINGO',
      'WEST CONCORD',
      'WINONA',
      'STOCKTON',
      'WYKOFF',
      'ZUMBRO FALLS',
      'ZUMBROTA',
      'MANKATO',
      'ALBERT LEA',
      'ALDEN',
      'AMBOY',
      'BELLE PLAINE',
      'BLUE EARTH',
      'BRICELYN',
      'CLARKS GROVE',
      'CLEVELAND',
      'COMFREY',
      'CONGER',
      'COURTLAND',
      'DARFUR',
      'DELAVAN',
      'EAGLE LALASKAE',
      'EASTON',
      'ELLENDALE',
      'ELMORE',
      'ELYSIAN',
      'EMMONS',
      'ESSIG',
      'FAIRMONT',
      'FREEBORN',
      'FROST',
      'GARDEN CITY',
      'GENEVA',
      'GLENVILLE',
      'GOOD THUNDER',
      'GRANADA',
      'HANSKA',
      'HARTLAND',
      'HAYWARD',
      'HENDERSON',
      'HOLLANDALE',
      'HOPE',
      'HUNTLEY',
      'JANESVILLE',
      'KASOTA',
      'KIESTER',
      'KILKENNY',
      'LAFAYETTE',
      'LALASKAE CRYSTAL',
      'LA SALLE',
      'LE CENTER',
      'LE SUEUR',
      'LEWISVILLE',
      'MADELIA',
      'MADISON LALASKAE',
      'MANCHESTER',
      'MAPLETON',
      'MINNESOTA LALASKAE',
      'MONTGOMERY',
      'NEW PRAGUE',
      'NEW RICHLAND',
      'NEW ULM',
      'NICOLLET',
      'NORTHROP',
      'OALASKALAND',
      'PEMBERTON',
      'SAINT CLAIR',
      'SAINT JAMES',
      'SAINT PETER',
      'SANBORN',
      'SEARLES',
      'SLEEPY EYE',
      'SPRINGFIELD',
      'TRUMAN',
      'TWIN LALASKAES',
      'VERNON CENTER',
      'WALDORF',
      'WASECA',
      'WATERVILLE',
      'WELLS',
      'WINNEBAGO',
      'WINDOM',
      'ADRIAN',
      'ALPHA',
      'ARCO',
      'AVOCA',
      'BALATON',
      'BEAVER CREEK',
      'BIGELOW',
      'BINGHAM LALASKAE',
      'BREWSTER',
      'BUTTERFIELD',
      'CEYLON',
      'CHANDLER',
      'CURRIE',
      'DOVRAY',
      'DUNNELL',
      'EDGERTON',
      'ELLSWORTH',
      'FULDA',
      'GARVIN',
      'HARDWICK',
      'HENDRICKS',
      'HERON LALASKAE',
      'HILLS',
      'HOLLAND',
      'IHLEN',
      'IONA',
      'IVANHOE',
      'JACKSON',
      'JASPER',
      'JEFFERS',
      'KANARANZI',
      'KENNETH',
      'LALASKAE BENTON',
      'LALASKAEFIELD',
      'LALASKAE WILSON',
      'LAMBERTON',
      'LEOTA',
      'LISMORE',
      'LUVERNE',
      'LYND',
      'MAGNOLIA',
      'MOUNTAIN LALASKAE',
      'ODIN',
      'OKABENA',
      'ORMSBY',
      'PIPESTONE',
      'READING',
      'REVERE',
      'ROUND LALASKAE',
      'RUSHMORE',
      'RUSSELL',
      'RUTHTON',
      'SHERBURN',
      'SLAYTON',
      'STEEN',
      'STORDEN',
      'TRACY',
      'TRIMONT',
      'TROSKY',
      'TYLER',
      'WALNUT GROVE',
      'WELCOME',
      'WESTBROOK',
      'WILMONT',
      'WOODSTOCK',
      'WORTHINGTON',
      'WILLMAR',
      'ALBERTA',
      'APPLETON',
      'ATWATER',
      'BARRY',
      'BEARDSLEY',
      'BELLINGHAM',
      'BELVIEW',
      'BENSON',
      'BLOMKEST',
      'BOYD',
      'BROWNS VALLEY',
      'CANBY',
      'CHOKIO',
      'CLARA CITY',
      'CLARKFIELD',
      'CLEMENTS',
      'CLINTON',
      'CLONTARF',
      'CORRELL',
      'COSMOS',
      'COTTONWOOD',
      'DANUBE',
      'DANVERS',
      'DAWSON',
      'DONNELLY',
      'DUMONT',
      'ECHO',
      'GHENT',
      'GRACEVILLE',
      'GRANITE FALLS',
      'GROVE CITY',
      'HANCOCK',
      'HANLEY FALLS',
      'HAWICK',
      'HERMAN',
      'HOLLOWAY',
      'KANDIYOHI',
      'KERKHOVEN',
      'LALASKAE LILLIAN',
      'LUCAN',
      'MADISON',
      'MARIETTA',
      'MARSHALL',
      'MAYNARD',
      'MILAN',
      'MILROY',
      'MINNEOTA',
      'MONTEVIDEO',
      'MORGAN',
      'MORRIS',
      'MORTON',
      'MURDOCK',
      'NEW LONDON',
      'NORCROSS',
      'ODESSA',
      'OLIVIA',
      'ORTONVILLE',
      'PENNOCK',
      'PORTER',
      'PRINSBURG',
      'RAYMOND',
      'REDWOOD FALLS',
      'RENVILLE',
      'SACRED HEART',
      'SEAFORTH',
      'SPICER',
      'SUNBURG',
      'TAUNTON',
      'VESTA',
      'WABASSO',
      'WANDA',
      'WATSON',
      'WHEATON',
      'WOOD LALASKAE',
      'SAINT CLOUD',
      'ALBANY',
      'ALEXANDRIA',
      'ASHBY',
      'AVON',
      'BARRETT',
      'BELGRADE',
      'BOCK',
      'BOWLUS',
      'BRANDON',
      'BROOTEN',
      'BUCKMAN',
      'BURTRUM',
      'CARLOS',
      'COLD SPRING',
      'COLLEGEVILLE',
      'CYRUS',
      'DALTON',
      'ELROSA',
      'EVANSVILLE',
      'FARWELL',
      'FLENSBURG',
      'FOLEY',
      'FORESTON',
      'FREEPORT',
      'GARFIELD',
      'GILMAN',
      'GLENWOOD',
      'GREENWALD',
      'GREY EAGLE',
      'HILLMAN',
      'HOFFMAN',
      'HOLDINGFORD',
      'HOLMES CITY',
      'ISLE',
      'KENSINGTON',
      'LASTRUP',
      'LITTLE FALLS',
      'LONG PRAIRIE',
      'LOWRY',
      'MC GRATH',
      'MELROSE',
      'MILACA',
      'MILTONA',
      'NELSON',
      'NEW MUNICH',
      'OALASKA PARK',
      'OGILVIE',
      'ONAMIA',
      'OSALASKAIS',
      'PARKERS PRAIRIE',
      'PAYNESVILLE',
      'PEASE',
      'PIERZ',
      'RICE',
      'RICHMOND',
      'ROCKVILLE',
      'ROSCOE',
      'ROYALTON',
      'SAINT JOSEPH',
      'SAINT STEPHEN',
      'SAINT MARTIN',
      'SARTELL',
      'SAUK CENTRE',
      'SAUK RAPIDS',
      'STARBUCK',
      'SWANVILLE',
      'UPSALA',
      'VILLARD',
      'WAHKON',
      'WAITE PARK',
      'WEST UNION',
      'BRAINERD',
      'BAXTER',
      'AH GWAH CHING',
      'AITKIN',
      'ALASKAELEY',
      'ALDRICH',
      'BACKUS',
      'BENEDICT',
      'BERTHA',
      'BROWERVILLE',
      'CLARISSA',
      'CROSBY',
      'CROSSLALASKAE',
      'CUSHING',
      'DEERWOOD',
      'EAGLE BEND',
      'EMILY',
      'FIFTY LALASKAES',
      'FORT RIPLEY',
      'GARRISON',
      'HACKENSACK',
      'HEWITT',
      'IRONTON',
      'JENKINS',
      'LALASKAE GEORGE',
      'LALASKAE HUBERT',
      'LAPORTE',
      'MENAHGA',
      'MERRIFIELD',
      'MOTLEY',
      'NEVIS',
      'NISSWA',
      'PALISADE',
      'PARK RAPIDS',
      'PEQUOT LALASKAES',
      'PILLAGER',
      'PINE RIVER',
      'RANDALL',
      'SEBEKA',
      'NIMROD',
      'STAPLES',
      'VERNDALE',
      'WADENA',
      'WALKER',
      'DETROIT LALASKAES',
      'ADA',
      'AUDUBON',
      'BALASKAER',
      'BARNESVILLE',
      'BATTLE LALASKAE',
      'BEJOU',
      'BELTRAMI',
      'BLUFFTON',
      'BORUP',
      'BRECKENRIDGE',
      'CALLAWAY',
      'CAMPBELL',
      'CLIMAX',
      'CLITHERALL',
      'COMSTOCK',
      'DEER CREEK',
      'DENT',
      'DILWORTH',
      'ELBOW LALASKAE',
      'ELIZABETH',
      'ERHARD',
      'ERSKINE',
      'FELTON',
      'FERGUS FALLS',
      'FERTILE',
      'FLOM',
      'FOSSTON',
      'FOXHOME',
      'FRAZEE',
      'GARY',
      'GEORGETOWN',
      'GLYNDON',
      'HALSTAD',
      'HAWLEY',
      'HENDRUM',
      'HENNING',
      'HITTERDAL',
      'KENT',
      'LALASKAE PARK',
      'MCINTOSH',
      'MAHNOMEN',
      'MOORHEAD',
      'NASHUA',
      'NAYTAHWAUSH',
      'NEW YORK MILLS',
      'NIELSVILLE',
      'OGEMA',
      'OSAGE',
      'OTTERTAIL',
      'PELICAN RAPIDS',
      'PERHAM',
      'PERLEY',
      'PONSFORD',
      'RICHVILLE',
      'RICHWOOD',
      'ROCHERT',
      'ROTHSAY',
      'SABIN',
      'SHELLY',
      'TINTAH',
      'TWIN VALLEY',
      'ULEN',
      'UNDERWOOD',
      'VERGAS',
      'VINING',
      'WAUBUN',
      'WENDELL',
      'WHITE EARTH',
      'WINGER',
      'WOLF LALASKAE',
      'WOLVERTON',
      'BEMIDJI',
      'BAGLEY',
      'BAUDETTE',
      'BENA',
      'BIG FALLS',
      'BIGFORK',
      'BIRCHDALE',
      'BLACKDUCK',
      'BOWSTRING',
      'CASS LALASKAE',
      'CLEARBROOK',
      'DEER RIVER',
      'TALMOON',
      'EFFIE',
      'FEDERAL DAM',
      'GONVICK',
      'GULLY',
      'HINES',
      'INTERNATIONAL FALLS',
      'KELLIHER',
      'LENGBY',
      'LEONARD',
      'LITTLEFORK',
      'LOMAN',
      'LONGVILLE',
      'MARCELL',
      'MARGIE',
      'MAX',
      'MIZPAH',
      'NORTHOME',
      'OUTING',
      'PENNINGTON',
      'PONEMAH',
      'PUPOSKY',
      'RANIER',
      'RAY',
      'REDBY',
      'REDLALASKAE',
      'REMER',
      'ROOSEVELT',
      'SHEVLIN',
      'SOLWAY',
      'SOUTH INTERNATIONAL FALLS',
      'SPRING LALASKAE',
      'SQUAW LALASKAE',
      'SWIFT',
      'TENSTRIKE',
      'TRAIL',
      'WASKISH',
      'WILLIAMS',
      'WILTON',
      'WIRT',
      'THIEF RIVER FALLS',
      'ALVARADO',
      'ANGLE INLET',
      'ANGUS',
      'ARGYLE',
      'BADGER',
      'BROOKS',
      'CROOKSTON',
      'DONALDSON',
      'EAST GRAND FORKS',
      'EUCLID',
      'FISHER',
      'GATZKE',
      'GOODRIDGE',
      'GREENBUSH',
      'GRYGLA',
      'HALLOCK',
      'HALMA',
      'HUMBOLDT',
      'KARLSTAD',
      'KENNEDY',
      'LALASKAE BRONSON',
      'LANCASTER',
      'MENTOR',
      'MIDDLE RIVER',
      'NEWFOLDEN',
      'NOYES',
      'OALASKA ISLAND',
      'OKLEE',
      'OSLO',
      'PLUMMER',
      'RED LALASKAE FALLS',
      'ROSEAU',
      'SAINT HILAIRE',
      'SAINT VINCENT',
      'SALOL',
      'STEPHEN',
      'STRANDQUIST',
      'STRATHCONA',
      'VIKING',
      'WANNASKA',
      'WARREN',
      'WARROAD'
    ]
  },
  MO: {
    code: 'MO',
    name: 'MISSOURI',
    cities: [
      'ALLENTON',
      'CHESTERFIELD',
      'ARNOLD',
      'BALLWIN',
      'BARNHART',
      'BEAUFORT',
      'BERGER',
      'CATAWISSA',
      'CEDAR HILL',
      'CRYSTAL CITY',
      'DE SOTO',
      'DITTMER',
      'EUREKA',
      'FENTON',
      'FESTUS',
      'FLETCHER',
      'FLORISSANT',
      'FRENCH VILLAGE',
      'GERALD',
      'GLENCOE',
      'GRAY SUMMIT',
      'GROVER',
      'GRUBVILLE',
      'HAZELWOOD',
      'MARYLAND HEIGHTS',
      'BRIDGETON',
      'EARTH CITY',
      'HEMATITE',
      'HERCULANEUM',
      'HIGH RIDGE',
      'HILLSBORO',
      'HOUSE SPRINGS',
      'IMPERIAL',
      'KIMMSWICK',
      'LABADIE',
      'LESLIE',
      'LIGUORI',
      'LONEDELL',
      'LUEBBERING',
      'MAPAVILLE',
      'MORSE MILL',
      'NEW HAVEN',
      'PACIFIC',
      'PEVELY',
      'RICHWOODS',
      'ROBERTSVILLE',
      'SAINT ALBANS',
      'SAINT ANN',
      'SAINT CLAIR',
      'STANTON',
      'SULLIVAN',
      'UNION',
      'VALLES MINES',
      'VALLEY PARK',
      'VILLA RIDGE',
      'WASHINGTON',
      'ROSEBUD',
      'SAINT LOUIS',
      'SAINT CHARLES',
      'ANNADA',
      'AUGUSTA',
      'BELLFLOWER',
      'BOWLING GREEN',
      'CLARKSVILLE',
      'COTTLEVILLE',
      'CURRYVILLE',
      'DEFIANCE',
      'DUTZOW',
      'ELSBERRY',
      'EOLIA',
      'FARBER',
      'FLINTHILL',
      'FOLEY',
      'FORISTELL',
      'HAWK POINT',
      'HIGH HILL',
      'JONESBURG',
      'LADDONIA',
      'LOUISIANA',
      'MARTHASVILLE',
      'MIDDLETOWN',
      'MONTGOMERY CITY',
      'MOSCOW MILLS',
      'NEW FLORENCE',
      'NEW MELLE',
      'O FALLON',
      'LALASKAE SAINT LOUIS',
      'OLD MONROE',
      'OLNEY',
      'PORTAGE DES SIOUX',
      'SAINT PETERS',
      'SILEX',
      'TRELOAR',
      'TROY',
      'TRUXTON',
      'VANDALIA',
      'WARRENTON',
      'WELLSVILLE',
      'WENTZVILLE',
      'WEST ALTON',
      'WHITESIDE',
      'WILLIAMSBURG',
      'WINFIELD',
      'WRIGHT CITY',
      'HANNIBAL',
      'ALEXANDRIA',
      'ANABEL',
      'ARBELA',
      'ASHBURN',
      'BETHEL',
      'CANTON',
      'CENTER',
      'CLARENCE',
      'DURHAM',
      'EMDEN',
      'EWING',
      'FRANKFORD',
      'GRANGER',
      'HUNNEWELL',
      'KAHOKA',
      'KNOX CITY',
      'LA BELLE',
      'LA GRANGE',
      'LENTNER',
      'LEONARD',
      'LEWISTOWN',
      'LURAY',
      'MAYWOOD',
      'MONROE CITY',
      'MONTICELLO',
      'NEWARK',
      'NEW LONDON',
      'NOVELTY',
      'PALMYRA',
      'PERRY',
      'PHILADELPHIA',
      'PLEVNA',
      'REVERE',
      'SAINT PATRICK',
      'SAVERTON',
      'SHELBINA',
      'SHELBYVILLE',
      'TAYLOR',
      'WAYLAND',
      'WILLIAMSTOWN',
      'WYACONDA',
      'KIRKSVILLE',
      'ATLANTA',
      'BARING',
      'BEVIER',
      'BRASHEAR',
      'CALLAO',
      'COATSVILLE',
      'DOWNING',
      'EDINA',
      'ELMER',
      'ETHEL',
      'GIBBS',
      'GLENWOOD',
      'GORIN',
      'GREEN CASTLE',
      'GREEN CITY',
      'GREENTOP',
      'HURDLAND',
      'LANCASTER',
      'LA PLATA',
      'LIVONIA',
      'MACON',
      'MEMPHIS',
      'MILAN',
      'NEW BOSTON',
      'NEW CAMBRIA',
      'NOVINGER',
      'POLLOCK',
      'QUEEN CITY',
      'RUTLEDGE',
      'UNIONVILLE',
      'WINIGAN',
      'WORTHINGTON',
      'PARK HILLS',
      'ANNAPOLIS',
      'ARCADIA',
      'BELGRADE',
      'BELLEVIEW',
      'BISMARCK',
      'BLACK',
      'BLACKWELL',
      'BLOOMSDALE',
      'BONNE TERRE',
      'BUNKER',
      'CADET',
      'CALEDONIA',
      'CASCADE',
      'CENTERVILLE',
      'DES ARC',
      'DOE RUN',
      'ELLINGTON',
      'FARMINGTON',
      'FREDERICKTOWN',
      'GLOVER',
      'IRONDALE',
      'IRONTON',
      'KNOB LICK',
      'LEADWOOD',
      'LESTERVILLE',
      'MARQUAND',
      'MIDDLE BROOK',
      'MINERAL POINT',
      'NEW OFFENBURG',
      'PATTON',
      'PILOT KNOB',
      'POTOSI',
      'REDFORD',
      'REYNOLDS',
      'SAINTE GENEVIEVE',
      'SAINT MARY',
      'TIFF',
      'VULCAN',
      'CAPE GIRARDEAU',
      'ADVANCE',
      'ALTENBURG',
      'BELL CITY',
      'BENTON',
      'BRAZEAU',
      'BROWNWOOD',
      'BURFORDVILLE',
      'CHAFFEE',
      'COMMERCE',
      'DAISY',
      'DELTA',
      'DUTCHTOWN',
      'FARRAR',
      'FRIEDHEIM',
      'FROHNA',
      'GIPSY',
      'GLENALLEN',
      'GORDONVILLE',
      'GRASSY',
      'JACKSON',
      'KELSO',
      'LEOPOLD',
      'MC GEE',
      'MARBLE HILL',
      'MILLERSVILLE',
      'MORLEY',
      'OALASKA RIDGE',
      'OLD APPLETON',
      'ORAN',
      'PAINTON',
      'PERKINS',
      'PERRYVILLE',
      'MC BRIDE',
      'POCAHONTAS',
      'SCOTT CITY',
      'SEDGEWICKVILLE',
      'STURDIVANT',
      'UNIONTOWN',
      'VANDUSER',
      'WHITEWATER',
      'ZALMA',
      'SIKESTON',
      'ANNISTON',
      'ARBYRD',
      'BERNIE',
      'BERTRAND',
      'BLODGETT',
      'BLOOMFIELD',
      'BRAGGADOCIO',
      'BRAGG CITY',
      'CANALOU',
      'CARDWELL',
      'CARUTHERSVILLE',
      'CATRON',
      'CHARLESTON',
      'CLARKTON',
      'CONRAN',
      'COOTER',
      'DEERING',
      'DEXTER',
      'EAST PRAIRIE',
      'ESSEX',
      'GIBSON',
      'GIDEON',
      'GOBLER',
      'GRAYRIDGE',
      'HAYTI',
      'HOLCOMB',
      'HOLLAND',
      'HORNERSVILLE',
      'KENNETT',
      'KEWANEE',
      'LILBOURN',
      'MALDEN',
      'MARSTON',
      'MATTHEWS',
      'MOREHOUSE',
      'NEW MADRID',
      'PARMA',
      'PASCOLA',
      'PORTAGEVILLE',
      'RISCO',
      'RIVES',
      'SENATH',
      'STEELE',
      'TALLAPOOSA',
      'WARDELL',
      'WHITEOALASKA',
      'WOLF ISLAND',
      'WYATT',
      'POPLAR BLUFF',
      'BRIAR',
      'BROSELEY',
      'CAMPBELL',
      'CLUBB',
      'DONIPHAN',
      'DUDLEY',
      'ELLSINORE',
      'FAGUS',
      'FAIRDEALING',
      'FISK',
      'FREMONT',
      'GATEWOOD',
      'GRANDIN',
      'GREENVILLE',
      'HARVIELL',
      'HIRAM',
      'LODI',
      'LOWNDES',
      'MILL SPRING',
      'NAYLOR',
      'NEELYVILLE',
      'OXLY',
      'PATTERSON',
      'PIEDMONT',
      'PUXICO',
      'QULIN',
      'ROMBAUER',
      'SHOOK',
      'SILVA',
      'VAN BUREN',
      'WAPPAPELLO',
      'WILLIAMSVILLE',
      'ALMA',
      'BATES CITY',
      'BELTON',
      'BLUE SPRINGS',
      'BUCKNER',
      'CAMDEN',
      'CAMDEN POINT',
      'CENTERVIEW',
      'CONCORDIA',
      'CORDER',
      'DOVER',
      'EXCELSIOR SPRINGS',
      'FARLEY',
      'GRAIN VALLEY',
      'GRANDVIEW',
      'GREENWOOD',
      'HARDIN',
      'HENRIETTA',
      'HIGGINSVILLE',
      'HOLDEN',
      'HOLT',
      'INDEPENDENCE',
      'KEARNEY',
      'KINGSVILLE',
      'LAWSON',
      'LEES SUMMIT',
      'LEVASY',
      'LEXINGTON',
      'LIBERTY',
      'LONE JACK',
      'MAYVIEW',
      'MISSOURI CITY',
      'MOSBY',
      'NAPOLEON',
      'OALASKA GROVE',
      'ODESSA',
      'ORRICK',
      'PECULIAR',
      'PLATTE CITY',
      'PLEASANT HILL',
      'RAYMORE',
      'RAYVILLE',
      'RICHMOND',
      'SIBLEY',
      'SMITHVILLE',
      'STRASBURG',
      'WALDRON',
      'WARRENSBURG',
      'WAVERLY',
      'WELLINGTON',
      'WESTON',
      'KANSAS CITY',
      'RIVERSIDE',
      'AGENCY',
      'ALBANY',
      'ALLENDALE',
      'AMAZONIA',
      'AMITY',
      'BARNARD',
      'BETHANY',
      'BLYTHEDALE',
      'BOLCKOW',
      'BURLINGTON JUNCTION',
      'CAMERON',
      'CLARKSDALE',
      'CLEARMONT',
      'CLYDE',
      'CONCEPTION',
      'CONCEPTION JUNCTION',
      'COSBY',
      'CRAIG',
      'DARLINGTON',
      'DEARBORN',
      'DE KALB',
      'DENVER',
      'EAGLEVILLE',
      'EASTON',
      'EDGERTON',
      'ELMO',
      'FAIRFAX',
      'FAIRPORT',
      'FAUCETT',
      'FILLMORE',
      'FOREST CITY',
      'GENTRY',
      'GOWER',
      'GRAHAM',
      'GRANT CITY',
      'GUILFORD',
      'HATFIELD',
      'HELENA',
      'HOPKINS',
      'KING CITY',
      'LATHROP',
      'MAITLAND',
      'MARTINSVILLE',
      'MARYVILLE',
      'MAYSVILLE',
      'MOUND CITY',
      'NEW HAMPTON',
      'OREGON',
      'OSBORN',
      'PARNELL',
      'PICKERING',
      'PLATTSBURG',
      'QUITMAN',
      'RAVENWOOD',
      'REA',
      'RIDGEWAY',
      'ROCK PORT',
      'ROSENDALE',
      'RUSHVILLE',
      'SAVANNAH',
      'SHERIDAN',
      'SKIDMORE',
      'STANBERRY',
      'STEWARTSVILLE',
      'TARKIO',
      'TRIMBLE',
      'TURNEY',
      'UNION STAR',
      'WATSON',
      'WEATHERBY',
      'WESTBORO',
      'WORTH',
      'SAINT JOSEPH',
      'CHILLICOTHE',
      'ALTAMONT',
      'BOGARD',
      'BOSWORTH',
      'BRAYMER',
      'BRECKENRIDGE',
      'BROOKFIELD',
      'BROWNING',
      'BUCKLIN',
      'CAINSVILLE',
      'CARROLLTON',
      'CHULA',
      'COFFEY',
      'COWGILL',
      'DAWN',
      'DE WITT',
      'GALLATIN',
      'GALT',
      'GILMAN CITY',
      'HALE',
      'HAMILTON',
      'HARRIS',
      'HUMPHREYS',
      'JAMESON',
      'JAMESPORT',
      'KIDDER',
      'KINGSTON',
      'LACLEDE',
      'LAREDO',
      'LINNEUS',
      'LOCK SPRINGS',
      'LUCERNE',
      'LUDLOW',
      'MC FALL',
      'MARCELINE',
      'MEADVILLE',
      'MENDON',
      'MERCER',
      'MOORESVILLE',
      'NEWTOWN',
      'NORBORNE',
      'PATTONSBURG',
      'POLO',
      'POWERSVILLE',
      'PRINCETON',
      'PURDIN',
      'ROTHVILLE',
      'SPICKARD',
      'STET',
      'SUMNER',
      'TINA',
      'TRENTON',
      'UTICA',
      'WALASKAENDA',
      'WHEELING',
      'WINSTON',
      'HARRISONVILLE',
      'ADRIAN',
      'AMORET',
      'AMSTERDAM',
      'APPLETON CITY',
      'ARCHIE',
      'BLAIRSTOWN',
      'BRONAUGH',
      'BUTLER',
      'CHILHOWEE',
      'CLEVELAND',
      'CLINTON',
      'COLLINS',
      'CREIGHTON',
      'DEEPWATER',
      'DEERFIELD',
      'DREXEL',
      'EAST LYNNE',
      'EL DORADO SPRINGS',
      'FOSTER',
      'FREEMAN',
      'GARDEN CITY',
      'GOLDEN CITY',
      'HARWOOD',
      'HORTON',
      'HUME',
      'JASPER',
      'JERICO SPRINGS',
      'LAMAR',
      'LEETON',
      'LIBERAL',
      'LOWRY CITY',
      'METZ',
      'MILFORD',
      'MILO',
      'MINDENMINES',
      'MONTROSE',
      'MOUNDVILLE',
      'NEVADA',
      'OSCEOLA',
      'PASSAIC',
      'RICHARDS',
      'RICH HILL',
      'ROCKVILLE',
      'ROSCOE',
      'SCHELL CITY',
      'SHELDON',
      'URICH',
      'VISTA',
      'WALKER',
      'JOPLIN',
      'ALBA',
      'ANDERSON',
      'ASBURY',
      'AVILLA',
      'CARL JUNCTION',
      'CARTERVILLE',
      'CARTHAGE',
      'DIAMOND',
      'DUENWEG',
      'FAIRVIEW',
      'GOODMAN',
      'GRANBY',
      'LANAGAN',
      'LA RUSSELL',
      'NECK CITY',
      'NEOSHO',
      'NEWTONIA',
      'NOEL',
      'ORONOGO',
      'PINEVILLE',
      'PURCELL',
      'RACINE',
      'REEDS',
      'ROCKY COMFORT',
      'SARCOXIE',
      'SOUTH WEST CITY',
      'SAGINAW',
      'SENECA',
      'STARK CITY',
      'STELLA',
      'TIFF CITY',
      'WACO',
      'WEBB CITY',
      'WENTWORTH',
      'WHEATON',
      'ARGYLE',
      'ASHLAND',
      'BARNETT',
      'BELLE',
      'BLAND',
      'BONNOTS MILL',
      'BRUMLEY',
      'CALIFORNIA',
      'CAMDENTON',
      'CEDAR CITY',
      'CENTERTOWN',
      'CHAMOIS',
      'CLARKSBURG',
      'ELDON',
      'ETTERVILLE',
      'EUGENE',
      'FORTUNA',
      'FREEBURG',
      'GASCONADE',
      'GRAVOIS MILLS',
      'LAURIE',
      'HARTSBURG',
      'HENLEY',
      'HERMANN',
      'HIGH POINT',
      'HOLTS SUMMIT',
      'JAMESTOWN',
      'KAISER',
      'KOELTZTOWN',
      'LALASKAE OZARK',
      'LATHAM',
      'LINN',
      'LINN CREEK',
      'LOHMAN',
      'LOOSE CREEK',
      'MC GIRK',
      'META',
      'MOKANE',
      'MORRISON',
      'MOUNT STERLING',
      'NEW BLOOMFIELD',
      'OLEAN',
      'OSAGE BEACH',
      'OWENSVILLE',
      'PORTLAND',
      'PRAIRIE HOME',
      'RHINELAND',
      'ROCKY MOUNT',
      'RUSSELLVILLE',
      'SAINT ELIZABETH',
      'SAINT THOMAS',
      'STEEDMAN',
      'STOVER',
      'SUNRISE BEACH',
      'TEBBETTS',
      'TIPTON',
      'TUSCUMBIA',
      'ULMAN',
      'VERSAILLES',
      'WESTPHALIA',
      'JEFFERSON CITY',
      'COLUMBIA',
      'ARMSTRONG',
      'AUXVASSE',
      'BENTON CITY',
      'BOONVILLE',
      'BRUNSWICK',
      'BUNCETON',
      'CAIRO',
      'CENTRALIA',
      'CLARK',
      'CLIFTON HILL',
      'DALTON',
      'EXCELLO',
      'FAYETTE',
      'FRANKLIN',
      'FULTON',
      'GLASGOW',
      'HALLSVILLE',
      'HARRISBURG',
      'HIGBEE',
      'HOLLIDAY',
      'HUNTSVILLE',
      'JACKSONVILLE',
      'KEYTESVILLE',
      'KINGDOM CITY',
      'MADISON',
      'MARTINSBURG',
      'MEXICO',
      'MOBERLY',
      'NEW FRANKLIN',
      'PARIS',
      'PILOT GROVE',
      'RENICK',
      'ROCHEPORT',
      'RUSH HILL',
      'SALISBURY',
      'SANTA FE',
      'STOUTSVILLE',
      'STURGEON',
      'THOMPSON',
      'TRIPLETT',
      'WOOLDRIDGE',
      'SEDALIA',
      'WHITEMAN AIR FORCE BASE',
      'ARROW ROCK',
      'BLACKBURN',
      'BLACKWATER',
      'CALHOUN',
      'CLIMAX SPRINGS',
      'COLE CAMP',
      'EDWARDS',
      'EMMA',
      'FLORENCE',
      'GILLIAM',
      'GREEN RIDGE',
      'HOUSTONIA',
      'HUGHESVILLE',
      'IONIA',
      'KNOB NOSTER',
      'LA MONTE',
      'LINCOLN',
      'MALTA BEND',
      'MARSHALL',
      'MIAMI',
      'MORA',
      'NELSON',
      'OTTERVILLE',
      'SLATER',
      'SMITHTON',
      'SWEET SPRINGS',
      'SYRACUSE',
      'WARSAW',
      'WINDSOR',
      'ROLLA',
      'BENDAVIS',
      'BEULAH',
      'BIRCH TREE',
      'BIXBY',
      'BOSS',
      'BOURBON',
      'BRINKTOWN',
      'BUCYRUS',
      'CHERRYVILLE',
      'COOK STATION',
      'CROCKER',
      'CUBA',
      'DAVISVILLE',
      'DEVILS ELBOW',
      'DIXON',
      'DUKE',
      'EDGAR SPRINGS',
      'ELDRIDGE',
      'ELK CREEK',
      'EMINENCE',
      'EUNICE',
      'FALCON',
      'FORT LEONARD WOOD',
      'HARTSHORN',
      'HOUSTON',
      'HUGGINS',
      'IBERIA',
      'JADWIN',
      'JEROME',
      'LALASKAE SPRING',
      'LAQUEY',
      'LEASBURG',
      'LEBANON',
      'LECOMA',
      'LENOX',
      'LICKING',
      'LYNCHBURG',
      'MONTIER',
      'MOUNTAIN VIEW',
      'NEWBURG',
      'PLATO',
      'RAYMONDVILLE',
      'RICHLAND',
      'ROBY',
      'SAINT JAMES',
      'SALEM',
      'SOLO',
      'STEELVILLE',
      'VIBURNUM',
      'STOUTLAND',
      'SUCCESS',
      'SUMMERSVILLE',
      'SWEDEBORG',
      'TERESITA',
      'VICHY',
      'VIENNA',
      'WAYNESVILLE',
      'SAINT ROBERT',
      'WESCO',
      'WINONA',
      'YUKON',
      'LONG LANE',
      'MONTREAL',
      'ALDRICH',
      'ARCOLA',
      'ASH GROVE',
      'AURORA',
      'ALTON',
      'CAPLINGER MILLS',
      'AVA',
      'BALASKAERSFIELD',
      'BILLINGS',
      'BLUE EYE',
      'BOIS D ARC',
      'BOLIVAR',
      'BRADLEYVILLE',
      'BRANSON',
      'BRIGHTON',
      'BRIXEY',
      'BROOKLINE STATION',
      'BRUNER',
      'BUFFALO',
      'BUTTERFIELD',
      'CAPE FAIR',
      'CASSVILLE',
      'CAULFIELD',
      'CEDARCREEK',
      'CHADWICK',
      'CHESTNUTRIDGE',
      'CLEVER',
      'CONWAY',
      'CRANE',
      'CROSS TIMBERS',
      'DADEVILLE',
      'DIGGINS',
      'DORA',
      'DRURY',
      'DUNNEGAN',
      'EAGLE ROCK',
      'ELKLAND',
      'EUDORA',
      'EVERTON',
      'EXETER',
      'FAIR GROVE',
      'FAIR PLAY',
      'FLEMINGTON',
      'FORDLAND',
      'FORSYTH',
      'FREISTATT',
      'GAINESVILLE',
      'GALENA',
      'GARRISON',
      'GOLDEN',
      'GOODSON',
      'GRAFF',
      'GREENFIELD',
      'GROVESPRING',
      'HALF WAY',
      'HALLTOWN',
      'HARDENVILLE',
      'HARTVILLE',
      'HERMITAGE',
      'HIGHLANDVILLE',
      'HOLLISTER',
      'HUMANSVILLE',
      'HURLEY',
      'ISABELLA',
      'KIRBYVILLE',
      'KISSEE MILLS',
      'LAMPE',
      'LOCKWOOD',
      'LOUISBURG',
      'KIMBERLING CITY',
      'BRANDSVILLE',
      'CABOOL',
      'COUCH',
      'KOSHKONONG',
      'MC CLURG',
      'MACOMB',
      'MANSFIELD',
      'MARIONVILLE',
      'MARSHFIELD',
      'MILLER',
      'MONETT',
      'MORRISVILLE',
      'MOUNTAIN GROVE',
      'MOUNT VERNON',
      'NIANGUA',
      'NIXA',
      'NOBLE',
      'NORWOOD',
      'OLDFIELD',
      'OZARK',
      'PHILLIPSBURG',
      'PIERCE CITY',
      'PITTSBURG',
      'PLEASANT HOPE',
      'POINT LOOKOUT',
      'POLK',
      'PONCE DE LEON',
      'PONTIAC',
      'POWELL',
      'POWERSITE',
      'PRESTON',
      'PROTEM',
      'PURDY',
      'QUINCY',
      'REEDS SPRING',
      'REPUBLIC',
      'RIDGEDALE',
      'ROCKAWAY BEACH',
      'ROCKBRIDGE',
      'ROGERSVILLE',
      'RUETER',
      'SELIGMAN',
      'SEYMOUR',
      'SHELL KNOB',
      'SOUTH GREENFIELD',
      'SPARTA',
      'SPOKANE',
      'SQUIRES',
      'STOTTS CITY',
      'STRAFFORD',
      'TANEYVILLE',
      'TECUMSEH',
      'THEODOSIA',
      'THORNFIELD',
      'TUNAS',
      'TURNERS',
      'UDALL',
      'URBANA',
      'VANZANT',
      'VERONA',
      'WALNUT GROVE',
      'WALNUT SHADE',
      'WASHBURN',
      'WASOLA',
      'WEAUBLEAU',
      'WEST PLAINS',
      'SOUTH FORK',
      'MOODY',
      'MYRTLE',
      'WHEATLAND',
      'WILLARD',
      'WINDYVILLE',
      'ZANONI',
      'STOCKTON',
      'MACKS CREEK',
      'ROACH',
      'PEACE VALLEY',
      'POMONA',
      'POTTERSVILLE',
      'THAYER',
      'WILLOW SPRINGS',
      'SPRINGFIELD'
    ]
  },
  MS: {
    code: 'MS',
    name: 'MISSISSIPPI',
    cities: [
      'ABBEVILLE',
      'ARKABUTLA',
      'ASHLAND',
      'BATESVILLE',
      'BELEN',
      'BLUE MOUNTAIN',
      'BYHALIA',
      'CLARKSDALE',
      'COAHOMA',
      'COLDWATER',
      'COMO',
      'COURTLAND',
      'CRENSHAW',
      'CROWDER',
      'DARLING',
      'DUMAS',
      'DUNDEE',
      'ETTA',
      'FALCON',
      'FALKNER',
      'FARRELL',
      'FRIARS POINT',
      'HERNANDO',
      'HICKORY FLAT',
      'HOLLY SPRINGS',
      'HORN LALASKAE',
      'INDEPENDENCE',
      'JONESTOWN',
      'LALASKAE CORMORANT',
      'LAMAR',
      'LAMBERT',
      'LULA',
      'LYON',
      'MARKS',
      'MICHIGAN CITY',
      'MOUNT PLEASANT',
      'MYRTLE',
      'NESBIT',
      'NEW ALBANY',
      'OLIVE BRANCH',
      'OXFORD',
      'POPE',
      'POTTS CAMP',
      'RED BANKS',
      'RIPLEY',
      'ROBINSONVILLE',
      'SARAH',
      'SARDIS',
      'SENATOBIA',
      'SHERARD',
      'SLEDGE',
      'SOUTHAVEN',
      'TAYLOR',
      'TIPLERSVILLE',
      'TULA',
      'TUNICA',
      'UNIVERSITY',
      'VICTORIA',
      'WALLS',
      'WALNUT',
      'WATERFORD',
      'GREENVILLE',
      'ALLIGATOR',
      'ANGUILLA',
      'ARCOLA',
      'AVON',
      'BENOIT',
      'BEULAH',
      'BOYLE',
      'CHATHAM',
      'CLEVELAND',
      'DODDSVILLE',
      'DREW',
      'PARCHMAN',
      'DUBLIN',
      'DUNCAN',
      'GLEN ALLAN',
      'GRACE',
      'GUNNISON',
      'HOLLANDALE',
      'HOLLY RIDGE',
      'INDIANOLA',
      'INVERNESS',
      'ISOLA',
      'LELAND',
      'MATTSON',
      'MERIGOLD',
      'METCALFE',
      'MOORHEAD',
      'MOUND BAYOU',
      'NITTA YUMA',
      'PACE',
      'PANTHER BURN',
      'RENA LARA',
      'ROME',
      'ROSEDALE',
      'RULEVILLE',
      'SCOTT',
      'SHAW',
      'SHELBY',
      'STONEVILLE',
      'SUNFLOWER',
      'WAYSIDE',
      'WINSTONVILLE',
      'WINTERVILLE',
      'TUPELO',
      'ALGOMA',
      'AMORY',
      'BALDWYN',
      'BECKER',
      'BELDEN',
      'BELMONT',
      'BLUE SPRINGS',
      'BOONEVILLE',
      'BURNSVILLE',
      'CORINTH',
      'DENNIS',
      'DERMA',
      'ECRU',
      'FULTON',
      'GATTMAN',
      'GLEN',
      'GOLDEN',
      'GREENWOOD SPRINGS',
      'GUNTOWN',
      'HOULKA',
      'HOUSTON',
      'IUKA',
      'MC CONDY',
      'MANTACHIE',
      'MARIETTA',
      'MOOREVILLE',
      'NETTLETON',
      'NEW SITE',
      'OKOLONA',
      'PLANTERSVILLE',
      'PONTOTOC',
      'RANDOLPH',
      'RIENZI',
      'SALTILLO',
      'SHANNON',
      'SHERMAN',
      'SMITHVILLE',
      'THAXTON',
      'TISHOMINGO',
      'TOCCOPOLA',
      'TREBLOC',
      'TREMONT',
      'VAN VLEET',
      'VARDAMAN',
      'VERONA',
      'WHEELER',
      'GRENADA',
      'AVALON',
      'BANNER',
      'BIG CREEK',
      'BRUCE',
      'CALHOUN CITY',
      'CARROLLTON',
      'CASCILLA',
      'CHARLESTON',
      'COFFEEVILLE',
      'COILA',
      'CRUGER',
      'DUCK HILL',
      'ELLIOTT',
      'ENID',
      'GLENDORA',
      'GORE SPRINGS',
      'GREENWOOD',
      'HOLCOMB',
      'ITTA BENA',
      'MC CARLEY',
      'MINTER CITY',
      'MONEY',
      'MORGAN CITY',
      'NORTH CARROLLTON',
      'OALASKALAND',
      'PARIS',
      'PHILIPP',
      'PITTSBORO',
      'SCHLATER',
      'SCOBEY',
      'SIDON',
      'SLATE SPRING',
      'SUMNER',
      'SWAN LALASKAE',
      'SWIFTOWN',
      'TIE PLANT',
      'TILLATOBA',
      'TIPPO',
      'TUTWILER',
      'VANCE',
      'WATER VALLEY',
      'WEBB',
      'WINONA',
      'BELZONI',
      'BENTON',
      'BENTONIA',
      'BOLTON',
      'BRANDON',
      'BRAXTON',
      'CAMDEN',
      'CANTON',
      'CARTHAGE',
      'CARY',
      'CLINTON',
      'CONEHATTA',
      'CRYSTAL SPRINGS',
      'DELTA CITY',
      'D LO',
      'DURANT',
      'EDWARDS',
      'ETHEL',
      'FAYETTE',
      'FLORA',
      'POCAHONTAS',
      'FLORENCE',
      'FOREST',
      'GALLMAN',
      'GEORGETOWN',
      'GOODMAN',
      'HARPERVILLE',
      'HARRISTON',
      'HARRISVILLE',
      'HAZLEHURST',
      'HERMANVILLE',
      'HILLSBORO',
      'HOLLY BLUFF',
      'KOSCIUSKO',
      'LALASKAE',
      'LENA',
      'LEXINGTON',
      'LORMAN',
      'LOUISE',
      'LUDLOW',
      'MC ADAMS',
      'MC COOL',
      'MADDEN',
      'MADISON',
      'MAGEE',
      'SANATORIUM',
      'MAYERSVILLE',
      'MENDENHALL',
      'MIDNIGHT',
      'MIZE',
      'MORTON',
      'MOUNT OLIVE',
      'NATCHEZ',
      'NEWHEBRON',
      'PATTISON',
      'PELAHATCHIE',
      'PICKENS',
      'PINEY WOODS',
      'PINOLA',
      'PORT GIBSON',
      'PUCKETT',
      'PULASKI',
      'RALEIGH',
      'RAYMOND',
      'REDWOOD',
      'RIDGELAND',
      'ROLLING FORK',
      'SALLIS',
      'SANDHILL',
      'SATARTIA',
      'SHARON',
      'SIBLEY',
      'SILVER CITY',
      'STAR',
      'TAYLORSVILLE',
      'TCHULA',
      'TERRY',
      'THOMASTOWN',
      'TINSLEY',
      'TOUGALOO',
      'UTICA',
      'VAIDEN',
      'VALLEY PARK',
      'VAUGHAN',
      'VICKSBURG',
      'WALNUT GROVE',
      'WASHINGTON',
      'WESSON',
      'WEST',
      'WHITFIELD',
      'YAZOO CITY',
      'JACKSON',
      'PEARL',
      'RICHLAND',
      'FLOWOOD',
      'BYRAM',
      'MERIDIAN',
      'BAILEY',
      'BUCKATUNNA',
      'CHUNKY',
      'CLARA',
      'COLLINSVILLE',
      'DALEVILLE',
      'DECATUR',
      'DE KALB',
      'ENTERPRISE',
      'HICKORY',
      'LAUDERDALE',
      'LAWRENCE',
      'LITTLE ROCK',
      'LOUIN',
      'LOUISVILLE',
      'MACON',
      'MARION',
      'NEWTON',
      'NOXAPATER',
      'PACHUTA',
      'PAULDING',
      'PHILADELPHIA',
      'PORTERVILLE',
      'PRESTON',
      'QUITMAN',
      'ROSE HILL',
      'SCOOBA',
      'SEBASTOPOL',
      'SHUBUTA',
      'SHUQUALALASKA',
      'STATE LINE',
      'STONEWALL',
      'TOOMSUBA',
      'UNION',
      'VOSSBURG',
      'WAYNESBORO',
      'HATTIESBURG',
      'BASSFIELD',
      'BAY SPRINGS',
      'BEAUMONT',
      'BROOKLYN',
      'CARRIERE',
      'CARSON',
      'COLLINS',
      'COLUMBIA',
      'EASTABUCHIE',
      'ELLISVILLE',
      'HEIDELBERG',
      'LAUREL',
      'LEALASKAESVILLE',
      'LUCEDALE',
      'LUMBERTON',
      'MC LAIN',
      'MC NEILL',
      'MOSELLE',
      'MOSS',
      'NEELY',
      'NEW AUGUSTA',
      'NICHOLSON',
      'OVETT',
      'PETAL',
      'PICAYUNE',
      'POPLARVILLE',
      'PRENTISS',
      'PURVIS',
      'RICHTON',
      'SANDERSVILLE',
      'SANDY HOOK',
      'SEMINARY',
      'SOSO',
      'STRINGER',
      'SUMRALL',
      'FOXWORTH',
      'GULFPORT',
      'BAY SAINT LOUIS',
      'STENNIS SPACE CENTER',
      'DIAMONDHEAD',
      'BILOXI',
      'DIBERVILLE',
      'ESCATAWPA',
      'GAUTIER',
      'HURLEY',
      'KILN',
      'LALASKAESHORE',
      'LONG BEACH',
      'MC HENRY',
      'MOSS POINT',
      'OCEAN SPRINGS',
      'PASCAGOULA',
      'PASS CHRISTIAN',
      'PEARLINGTON',
      'PERKINSTON',
      'SAUCIER',
      'WAVELAND',
      'WIGGINS',
      'BROOKHAVEN',
      'BOGUE CHITTO',
      'BUDE',
      'CENTREVILLE',
      'CHATAWA',
      'CROSBY',
      'FERNWOOD',
      'GLOSTER',
      'JAYESS',
      'KOKOMO',
      'LIBERTY',
      'MC CALL CREEK',
      'MCCOMB',
      'MAGNOLIA',
      'MEADVILLE',
      'MONTICELLO',
      'OALASKA VALE',
      'OSYKA',
      'ROXIE',
      'RUTH',
      'SILVER CREEK',
      'SMITHDALE',
      'SONTAG',
      'SUMMIT',
      'TYLERTOWN',
      'UNION CHURCH',
      'WOODVILLE',
      'COLUMBUS',
      'ABERDEEN',
      'ACKERMAN',
      'ARTESIA',
      'BELLEFONTAINE',
      'BROOKSVILLE',
      'CALEDONIA',
      'CEDARBLUFF',
      'CRAWFORD',
      'EUPORA',
      'FRENCH CAMP',
      'HAMILTON',
      'KILMICHAEL',
      'MABEN',
      'MANTEE',
      'MATHISTON',
      'MAYHEW',
      'MONTPELIER',
      'PHEBA',
      'PRAIRIE',
      'STARKVILLE',
      'MISSISSIPPI STATE',
      'STEENS',
      'STEWART',
      'STURGIS',
      'WALTHALL',
      'WEIR',
      'WEST POINT',
      'WOODLAND'
    ]
  },
  MT: {
    code: 'MT',
    name: 'MONTANA',
    cities: [
      'ABSAROKEE',
      'ACTON',
      'ASHLAND',
      'BALLANTINE',
      'BEARCREEK',
      'BELFRY',
      'BIGHORN',
      'BIG TIMBER',
      'BIRNEY',
      'BOYD',
      'BRIDGER',
      'BROADVIEW',
      'BUSBY',
      'CLYDE PARK',
      'COLUMBUS',
      'COOKE CITY',
      'CROW AGENCY',
      'CUSTER',
      'DECKER',
      'EDGAR',
      'EMIGRANT',
      'FISHTAIL',
      'FROMBERG',
      'GARDINER',
      'GARRYOWEN',
      'GRASS RANGE',
      'GREYCLIFF',
      'HARDIN',
      'YELLOWTAIL',
      'HARLOWTON',
      'HUNTLEY',
      'HYSHAM',
      'INGOMAR',
      'JOLIET',
      'LAME DEER',
      'LAUREL',
      'LAVINA',
      'LIVINGSTON',
      'LODGE GRASS',
      'MC LEOD',
      'MARTINSDALE',
      'MELSTONE',
      'MELVILLE',
      'MOLT',
      'MOSBY',
      'MUSSELSHELL',
      'NYE',
      'OTTER',
      'PARK CITY',
      'POMPEYS PILLAR',
      'PRAY',
      'PRYOR',
      'RAPELJE',
      'RED LODGE',
      'REED POINT',
      'ROBERTS',
      'ROSCOE',
      'ROUNDUP',
      'RYEGATE',
      'SAINT XAVIER',
      'SANDERS',
      'SAND SPRINGS',
      'SHAWMUT',
      'SHEPHERD',
      'SILVER GATE',
      'SPRINGDALE',
      'SUMATRA',
      'TEIGEN',
      'TWO DOT',
      'WILSALL',
      'WINNETT',
      'WORDEN',
      'WYOLA',
      'BILLINGS',
      'WOLF POINT',
      'ANTELOPE',
      'BAINVILLE',
      'BROCKTON',
      'BROCKWAY',
      'CIRCLE',
      'CRANE',
      'CULBERTSON',
      'DAGMAR',
      'FAIRVIEW',
      'FLAXVILLE',
      'FORT PECK',
      'FRAZER',
      'FROID',
      'GLASGOW',
      'SAINT MARIE',
      'GLENTANA',
      'HINSDALE',
      'HOMESTEAD',
      'LAMBERT',
      'LARSLAN',
      'MC CABE',
      'MEDICINE LALASKAE',
      'NASHUA',
      'OPHEIM',
      'OUTLOOK',
      'PEERLESS',
      'PLENTYWOOD',
      'POPLAR',
      'RAYMOND',
      'REDSTONE',
      'RESERVE',
      'RICHEY',
      'RICHLAND',
      'SACO',
      'SAVAGE',
      'SCOBEY',
      'SIDNEY',
      'VANDALIA',
      'VIDA',
      'WESTBY',
      'WHITETAIL',
      'MILES CITY',
      'ALZADA',
      'ANGELA',
      'BALASKAER',
      'BIDDLE',
      'BLOOMFIELD',
      'BOYES',
      'BROADUS',
      'BRUSETT',
      'CAPITOL',
      'COHAGEN',
      'COLSTRIP',
      'EKALALASKAA',
      'FALLON',
      'FORSYTH',
      'GLENDIVE',
      'HAMMOND',
      'HATHAWAY',
      'ISMAY',
      'JORDAN',
      'KINSEY',
      'LINDSAY',
      'MILDRED',
      'OLIVE',
      'PLEVNA',
      'POWDERVILLE',
      'ROSEBUD',
      'SONNETTE',
      'TERRY',
      'VOLBORG',
      'WIBAUX',
      'WILLARD',
      'GREAT FALLS',
      'MALMSTROM A F B',
      'AUGUSTA',
      'BABB',
      'BELT',
      'BLACK EAGLE',
      'BRADY',
      'BROWNING',
      'BUFFALO',
      'BYNUM',
      'CARTER',
      'CASCADE',
      'CHOTEAU',
      'COFFEE CREEK',
      'CONRAD',
      'CUT BANK',
      'DENTON',
      'DUPUYER',
      'DUTTON',
      'EAST GLACIER PARK',
      'ETHRIDGE',
      'FAIRFIELD',
      'FLOWEREE',
      'FORESTGROVE',
      'FORT BENTON',
      'FORT SHAW',
      'GALATA',
      'GARNEILL',
      'GERALDINE',
      'GEYSER',
      'HEART BUTTE',
      'HIGHWOOD',
      'HILGER',
      'HOBSON',
      'JUDITH GAP',
      'KEVIN',
      'LEDGER',
      'LEWISTOWN',
      'LOMA',
      'LOTHAIR',
      'MOCCASIN',
      'MONARCH',
      'MOORE',
      'NEIHART',
      'OILMONT',
      'PENDROY',
      'POWER',
      'RAYNESFORD',
      'ROY',
      'SAND COULEE',
      'SANTA RITA',
      'SHELBY',
      'SIMMS',
      'STANFORD',
      'STOCKETT',
      'SUNBURST',
      'SUN RIVER',
      'SWEET GRASS',
      'ULM',
      'VALIER',
      'VAUGHN',
      'WINIFRED',
      'HAVRE',
      'BIG SANDY',
      'BOX ELDER',
      'CHESTER',
      'CHINOOK',
      'DODSON',
      'GILDFORD',
      'HARLEM',
      'HAYS',
      'HINGHAM',
      'HOGELAND',
      'INVERNESS',
      'JOPLIN',
      'KREMLIN',
      'LLOYD',
      'LORING',
      'MALTA',
      'RUDYARD',
      'TURNER',
      'WHITEWATER',
      'WHITLASH',
      'ZORTMAN',
      'ZURICH',
      'HELENA',
      'BASIN',
      'BOULDER',
      'CANYON CREEK',
      'CLANCY',
      'EAST HELENA',
      'FORT HARRISON',
      'JEFFERSON CITY',
      'LINCOLN',
      'MARYSVILLE',
      'RADERSBURG',
      'RINGLING',
      'TOSTON',
      'TOWNSEND',
      'WHITE SULPHUR SPRINGS',
      'WINSTON',
      'WOLF CREEK',
      'BUTTE',
      'ALDER',
      'ANACONDA',
      'AVON',
      'BELGRADE',
      'BOZEMAN',
      'BIG SKY',
      'CAMERON',
      'CARDWELL',
      'DEER LODGE',
      'DELL',
      'DILLON',
      'DIVIDE',
      'ELLISTON',
      'ENNIS',
      'GALLATIN GATEWAY',
      'GARRISON',
      'GLEN',
      'GOLD CREEK',
      'HARRISON',
      'JACKSON',
      'LIMA',
      'MC ALLISTER',
      'MANHATTAN',
      'MELROSE',
      'NORRIS',
      'POLARIS',
      'PONY',
      'RAMSAY',
      'SHERIDAN',
      'SILVER STAR',
      'THREE FORKS',
      'TWIN BRIDGES',
      'VIRGINIA CITY',
      'WARM SPRINGS',
      'WEST YELLOWSTONE',
      'WHITEHALL',
      'WILLOW CREEK',
      'WISDOM',
      'WISE RIVER',
      'MISSOULA',
      'ALBERTON',
      'ARLEE',
      'BONNER',
      'CHARLO',
      'CLINTON',
      'CONDON',
      'CONNER',
      'CORVALLIS',
      'DARBY',
      'DE BORGIA',
      'DIXON',
      'DRUMMOND',
      'FLORENCE',
      'FRENCHTOWN',
      'GRANTSDALE',
      'GREENOUGH',
      'HALL',
      'HAMILTON',
      'PINESDALE',
      'HAUGAN',
      'HELMVILLE',
      'HERON',
      'HOT SPRINGS',
      'HUSON',
      'LOLO',
      'LONEPINE',
      'MILLTOWN',
      'NOXON',
      'OVANDO',
      'PABLO',
      'PARADISE',
      'PHILIPSBURG',
      'PLAINS',
      'POLSON',
      'RAVALLI',
      'RONAN',
      'SAINT IGNATIUS',
      'SAINT REGIS',
      'SALTESE',
      'SEELEY LALASKAE',
      'STEVENSVILLE',
      'SULA',
      'SUPERIOR',
      'THOMPSON FALLS',
      'TROUT CREEK',
      'VICTOR',
      'KALISPELL',
      'BIG ARM',
      'BIGFORK',
      'COLUMBIA FALLS',
      'CORAM',
      'DAYTON',
      'ELMO',
      'ESSEX',
      'EUREKA',
      'FORTINE',
      'HUNGRY HORSE',
      'KILA',
      'LALASKAE MC DONALD',
      'LALASKAESIDE',
      'LIBBY',
      'MARION',
      'MARTIN CITY',
      'OLNEY',
      'POLEBRIDGE',
      'PROCTOR',
      'REXFORD',
      'ROLLINS',
      'SOMERS',
      'STRYKER',
      'TREGO',
      'TROY',
      'WEST GLACIER',
      'WHITEFISH'
    ]
  },
  NC: {
    code: 'NC',
    name: 'NORTH CAROLINA',
    cities: [
      'ADVANCE',
      'ARARAT',
      'BELEWS CREEK',
      'BETHANIA',
      'BOONVILLE',
      'CLEMMONS',
      'CLEVELAND',
      'COOLEEMEE',
      'DANBURY',
      'DOBSON',
      'EAST BEND',
      'GERMANTON',
      'HAMPTONVILLE',
      'KING',
      'LAWSONVILLE',
      'LEWISVILLE',
      'LOWGAP',
      'MADISON',
      'MAYODAN',
      'MOCKSVILLE',
      'MOUNT AIRY',
      'WHITE PLAINS',
      'PFAFFTOWN',
      'PILOT MOUNTAIN',
      'PINE HALL',
      'PINNACLE',
      'RURAL HALL',
      'SANDY RIDGE',
      'SILOAM',
      'STONEVILLE',
      'TOAST',
      'TOBACCOVILLE',
      'WALKERTOWN',
      'WALNUT COVE',
      'WESTFIELD',
      'WOODLEAF',
      'YADKINVILLE',
      'WINSTON SALEM',
      'ALAMANCE',
      'ALTAMAHAW',
      'ASHEBORO',
      'BEAR CREEK',
      'BENNETT',
      'BISCOE',
      'BLANCH',
      'BONLEE',
      'BROWNS SUMMIT',
      'BURLINGTON',
      'BYNUM',
      'CANDOR',
      'CEDAR FALLS',
      'CEDAR GROVE',
      'CLIMAX',
      'COLFAX',
      'CUMNOCK',
      'DENTON',
      'EAGLE SPRINGS',
      'EFLAND',
      'ELON COLLEGE',
      'ETHER',
      'FRANKLINVILLE',
      'GIBSONVILLE',
      'GOLDSTON',
      'GRAHAM',
      'GULF',
      'HAW RIVER',
      'HIGHFALLS',
      'HIGH POINT',
      'HILLSBOROUGH',
      'JACKSON SPRINGS',
      'JAMESTOWN',
      'JULIAN',
      'KERNERSVILLE',
      'EDEN',
      'LEASBURG',
      'LEXINGTON',
      'LIBERTY',
      'LINWOOD',
      'MC LEANSVILLE',
      'MEBANE',
      'MILTON',
      'MOUNT GILEAD',
      'OALASKA RIDGE',
      'PELHAM',
      'PITTSBORO',
      'PLEASANT GARDEN',
      'PROSPECT HILL',
      'PROVIDENCE',
      'RAMSEUR',
      'RANDLEMAN',
      'REIDSVILLE',
      'ROBBINS',
      'RUFFIN',
      'SANFORD',
      'SAXAPAHAW',
      'SEAGROVE',
      'SEDALIA',
      'SEMORA',
      'SILER CITY',
      'SNOW CAMP',
      'SOPHIA',
      'SOUTHMONT',
      'STALEY',
      'STAR',
      'STOKESDALE',
      'SUMMERFIELD',
      'SWEPSONVILLE',
      'THOMASVILLE',
      'TRINITY',
      'TROY',
      'WALLBURG',
      'WELCOME',
      'WENTWORTH',
      'WEST END',
      'WHITSETT',
      'YANCEYVILLE',
      'GREENSBORO',
      'ANGIER',
      'APEX',
      'BAHAMA',
      'BENSON',
      'BROADWAY',
      'BUIES CREEK',
      'BULLOCK',
      'BUNN',
      'BUTNER',
      'CARRBORO',
      'CARY',
      'CHAPEL HILL',
      'CLAYTON',
      'COATS',
      'CREEDMOOR',
      'FOUR OALASKAS',
      'FRANKLINTON',
      'FUQUAY VARINA',
      'GARNER',
      'GOLDSBORO',
      'HENDERSON',
      'HOLLY SPRINGS',
      'HURDLE MILLS',
      'KENLY',
      'KIPLING',
      'KITTRELL',
      'KNIGHTDALE',
      'LILLINGTON',
      'LOUISBURG',
      'MACON',
      'MAMERS',
      'MANSON',
      'MICRO',
      'MIDDLEBURG',
      'MIDDLESEX',
      'MONCURE',
      'MORRISVILLE',
      'NEW HILL',
      'NORLINA',
      'OXFORD',
      'PINE LEVEL',
      'PRINCETON',
      'RIDGEWAY',
      'ROLESVILLE',
      'ROUGEMONT',
      'ROXBORO',
      'SELMA',
      'SMITHFIELD',
      'STEM',
      'STOVALL',
      'TIMBERLALASKAE',
      'TOWNSVILLE',
      'VAUGHAN',
      'WALASKAE FOREST',
      'WARRENTON',
      'WENDELL',
      'WILLOW SPRING',
      'WILSONS MILLS',
      'WISE',
      'YOUNGSVILLE',
      'ZEBULON',
      'RALEIGH',
      'DURHAM',
      'ROCKY MOUNT',
      'AULANDER',
      'AURORA',
      'BAILEY',
      'BATH',
      'BATTLEBORO',
      'BELHAVEN',
      'BELLARTHUR',
      'BETHEL',
      'BLACK CREEK',
      'BLOUNTS CREEK',
      'CASTALIA',
      'CHOCOWINITY',
      'COMO',
      'CONETOE',
      'CONWAY',
      'EDWARD',
      'ELM CITY',
      'ENFIELD',
      'ENGELHARD',
      'EVERETTS',
      'FAIRFIELD',
      'FALKLAND',
      'FARMVILLE',
      'FOUNTAIN',
      'FREMONT',
      'GARYSBURG',
      'GASTON',
      'GREENVILLE',
      'GRIMESLAND',
      'GUMBERRY',
      'HALIFAX',
      'HAMILTON',
      'HASSELL',
      'HENRICO',
      'HOBGOOD',
      'HOLLISTER',
      'JACKSON',
      'JAMESVILLE',
      'KELFORD',
      'LEWISTON WOODVILLE',
      'LITTLETON',
      'LUCAMA',
      'MACCLESFIELD',
      'MARGARETTSVILLE',
      'MILWAUKEE',
      'MURFREESBORO',
      'NASHVILLE',
      'OALASKA CITY',
      'PANTEGO',
      'PARMELE',
      'PENDLETON',
      'PIKEVILLE',
      'PINETOPS',
      'PINETOWN',
      'PLEASANT HILL',
      'POTECASI',
      'RED OALASKA',
      'RICH SQUARE',
      'ROANOKE RAPIDS',
      'ROBERSONVILLE',
      'ROXOBEL',
      'SARATOGA',
      'SCOTLAND NECK',
      'SCRANTON',
      'SEABOARD',
      'SEVERN',
      'SHARPSBURG',
      'SIMPSON',
      'SIMS',
      'SPEED',
      'SPRING HOPE',
      'STANTONSBURG',
      'STOKES',
      'SWANQUARTER',
      'TARBORO',
      'TILLERY',
      'WALSTONBURG',
      'WASHINGTON',
      'WELDON',
      'WHITALASKAERS',
      'WILLIAMSTON',
      'WILSON',
      'WOODLAND',
      'ELIZABETH CITY',
      'AHOSKIE',
      'AVON',
      'AYDLETT',
      'BARCO',
      'BELVIDERE',
      'BUXTON',
      'CAMDEN',
      'COFIELD',
      'COINJOCK',
      'COLERAIN',
      'COLUMBIA',
      'CORAPEALASKAE',
      'COROLLA',
      'CRESWELL',
      'CURRITUCK',
      'DURANTS NECK',
      'EDENTON',
      'EURE',
      'FRISCO',
      'GATES',
      'GATESVILLE',
      'GRANDY',
      'HARBINGER',
      'HARRELLSVILLE',
      'HATTERAS',
      'HERTFORD',
      'HOBBSVILLE',
      'JARVISBURG',
      'KILL DEVIL HILLS',
      'KITTY HAWK',
      'KNOTTS ISLAND',
      'MANNS HARBOR',
      'MANTEO',
      'MAPLE',
      'MERRY HILL',
      'MOYOCK',
      'NAGS HEAD',
      'OCRACOKE',
      'PLYMOUTH',
      'POINT HARBOR',
      'POPLAR BRANCH',
      'POWELLS POINT',
      'POWELLSVILLE',
      'RODANTHE',
      'RODUCO',
      'ROPER',
      'SALVO',
      'SHAWBORO',
      'SHILOH',
      'SOUTH MILLS',
      'STUMPY POINT',
      'SUNBURY',
      'TYNER',
      'WANCHESE',
      'WAVES',
      'WINDSOR',
      'WINFALL',
      'WINTON',
      'ALBEMARLE',
      'ALEXIS',
      'ANSONVILLE',
      'BADIN',
      'BARIUM SPRINGS',
      'BELMONT',
      'BESSEMER CITY',
      'BOILING SPRINGS',
      'BOSTIC',
      'CAROLEEN',
      'CASAR',
      'CHERRYVILLE',
      'CHINA GROVE',
      'CLIFFSIDE',
      'CONCORD',
      'CORNELIUS',
      'CRAMERTON',
      'CROUSE',
      'DALLAS',
      'DAVIDSON',
      'DENVER',
      'EARL',
      'EAST SPENCER',
      'ELLENBORO',
      'FAITH',
      'FALLSTON',
      'FOREST CITY',
      'GASTONIA',
      'HUNTERSVILLE',
      'GOLD HILL',
      'GRANITE QUARRY',
      'GROVER',
      'HARRIS',
      'HARRISBURG',
      'HENRIETTA',
      'HIGH SHOALS',
      'INDIAN TRAIL',
      'IRON STATION',
      'KANNAPOLIS',
      'KINGS MOUNTAIN',
      'LANDIS',
      'LATTIMORE',
      'LAWNDALE',
      'LILESVILLE',
      'LINCOLNTON',
      'LOCUST',
      'LOWELL',
      'MC ADENVILLE',
      'MC FARLAN',
      'MARSHVILLE',
      'MATTHEWS',
      'MIDLAND',
      'MINERAL SPRINGS',
      'MISENHEIMER',
      'MONROE',
      'MOORESBORO',
      'MOORESVILLE',
      'MORVEN',
      'MOUNT HOLLY',
      'MOUNT MOURNE',
      'MOUNT PLEASANT',
      'MOUNT ULLA',
      'NEWELL',
      'NEW LONDON',
      'NORWOOD',
      'OALASKABORO',
      'PAW CREEK',
      'PEACHLAND',
      'PINEVILLE',
      'POLKTON',
      'POLKVILLE',
      'RICHFIELD',
      'ROCKWELL',
      'RUTHERFORDTON',
      'SALISBURY',
      'SHELBY',
      'SPENCER',
      'SPINDALE',
      'STANFIELD',
      'STANLEY',
      'TROUTMAN',
      'UNION MILLS',
      'VALE',
      'WACO',
      'WADESBORO',
      'WAXHAW',
      'WINGATE',
      'CHARLOTTE',
      'FAYETTEVILLE',
      'FORT BRAGG',
      'POPE A F B',
      'ABERDEEN',
      'AUTRYVILLE',
      'BARNESVILLE',
      'BLADENBORO',
      'BUNNLEVEL',
      'CALYPSO',
      'CAMERON',
      'CARTHAGE',
      'CLINTON',
      'CORDOVA',
      'CUMBERLAND',
      'DUBLIN',
      'DUDLEY',
      'DUNN',
      'ELIZABETHTOWN',
      'ELLERBE',
      'ERWIN',
      'FAIRMONT',
      'FAISON',
      'FALCON',
      'GIBSON',
      'GODWIN',
      'HAMLET',
      'HOFFMAN',
      'HOPE MILLS',
      'KENANSVILLE',
      'LALASKAEVIEW',
      'LAUREL HILL',
      'LAURINBURG',
      'LEMON SPRINGS',
      'LINDEN',
      'LUMBER BRIDGE',
      'LUMBERTON',
      'MCCAIN',
      'MARIETTA',
      'MARSTON',
      'MAXTON',
      'MOUNT OLIVE',
      'NEWTON GROVE',
      'NORMAN',
      'OLIVIA',
      'ORRUM',
      'PINEHURST',
      'PARKTON',
      'PEMBROKE',
      'PINEBLUFF',
      'PROCTORVILLE',
      'RAEFORD',
      'RED SPRINGS',
      'REX',
      'ROCKINGHAM',
      'ROSEBORO',
      'ROWLAND',
      'SAINT PAULS',
      'SALEMBURG',
      'SHANNON',
      'SOUTHERN PINES',
      'SPRING LALASKAE',
      'STEDMAN',
      'TAR HEEL',
      'TURKEY',
      'VASS',
      'WADE',
      'WAGRAM',
      'WARSAW',
      'WHITE OALASKA',
      'WILMINGTON',
      'ASH',
      'ATKINSON',
      'BOLIVIA',
      'BOLTON',
      'BRUNSWICK',
      'BURGAW',
      'CAROLINA BEACH',
      'CASTLE HAYNE',
      'CERRO GORDO',
      'CHADBOURN',
      'CLARENDON',
      'CLARKTON',
      'COUNCIL',
      'CURRIE',
      'DELCO',
      'EVERGREEN',
      'FAIR BLUFF',
      'GARLAND',
      'HALLSBORO',
      'HAMPSTEAD',
      'HARRELLS',
      'HOLLY RIDGE',
      'INGOLD',
      'IVANHOE',
      'KELLY',
      'KURE BEACH',
      'LALASKAE WACCAMAW',
      'LELAND',
      'LONGWOOD',
      'MAGNOLIA',
      'MAPLE HILL',
      'NALASKAINA',
      'RIEGELWOOD',
      'ROCKY POINT',
      'ROSE HILL',
      'SHALLOTTE',
      'SNEADS FERRY',
      'SOUTHPORT',
      'SUPPLY',
      'TABOR CITY',
      'TEACHEY',
      'OALASKA ISLAND',
      'WALLACE',
      'CALABASH',
      'SUNSET BEACH',
      'OCEAN ISLE BEACH',
      'WATHA',
      'WHITEVILLE',
      'WILLARD',
      'WINNABOW',
      'WRIGHTSVILLE BEACH',
      'KINSTON',
      'ALBERTSON',
      'ALLIANCE',
      'ARAPAHOE',
      'ATLANTIC',
      'ATLANTIC BEACH',
      'AYDEN',
      'BAYBORO',
      'BEAUFORT',
      'BEULAVILLE',
      'BRIDGETON',
      'CEDAR ISLAND',
      'CHINQUAPIN',
      'COMFORT',
      'COVE CITY',
      'DAVIS',
      'DEEP RUN',
      'DOVER',
      'ERNUL',
      'GLOUCESTER',
      'GRANTSBORO',
      'GRIFTON',
      'HARKERS ISLAND',
      'HAVELOCK',
      'CHERRY POINT',
      'HOBUCKEN',
      'HOOKERTON',
      'HUBERT',
      'JACKSONVILLE',
      'CAMP LEJEUNE',
      'TARAWA TERRACE',
      'MIDWAY PARK',
      'MCCUTCHEON FIELD',
      'LA GRANGE',
      'LOWLAND',
      'MARSHALLBERG',
      'MAURY',
      'MAYSVILLE',
      'MERRITT',
      'MOREHEAD CITY',
      'NEW BERN',
      'NEWPORT',
      'ORIENTAL',
      'PINK HILL',
      'POLLOCKSVILLE',
      'RICHLANDS',
      'SALTER PATH',
      'SEALEVEL',
      'SEVEN SPRINGS',
      'SMYRNA',
      'SNOW HILL',
      'STACY',
      'STELLA',
      'STONEWALL',
      'SWANSBORO',
      'TRENTON',
      'VANCEBORO',
      'VANDEMERE',
      'WILLISTON',
      'WINTERVILLE',
      'EMERALD ISLE',
      'HICKORY',
      'BANNER ELK',
      'BLOWING ROCK',
      'BOOMER',
      'BOONE',
      'CATAWBA',
      'CLAREMONT',
      'COLLETTSVILLE',
      'CONNELLYS SPRINGS',
      'CONOVER',
      'CRESTON',
      'CROSSNORE',
      'CRUMPLER',
      'DEEP GAP',
      'DREXEL',
      'ELKIN',
      'ELK PARK',
      'ENNICE',
      'FERGUSON',
      'STATESVILLE',
      'FLEETWOOD',
      'GLADE VALLEY',
      'GLEN ALPINE',
      'GLENDALE SPRINGS',
      'GRANITE FALLS',
      'GRASSY CREEK',
      'LENOIR',
      'HARMONY',
      'HAYS',
      'HIDDENITE',
      'HILDEBRAN',
      'HUDSON',
      'JEFFERSON',
      'JONAS RIDGE',
      'JONESVILLE',
      'LANSING',
      'LAUREL SPRINGS',
      'LINVILLE',
      'LINVILLE FALLS',
      'MC GRADY',
      'MAIDEN',
      'MILLERS CREEK',
      'MINNEAPOLIS',
      'MONTEZUMA',
      'MORAVIAN FALLS',
      'MORGANTON',
      'NORTH WILKESBORO',
      'NEWLAND',
      'NEWTON',
      'OLIN',
      'PATTERSON',
      'PINEOLA',
      'PINEY CREEK',
      'PLUMTREE',
      'PURLEAR',
      'ICARD',
      'RHODHISS',
      'ROARING GAP',
      'ROARING RIVER',
      'RONDA',
      'RUTHERFORD COLLEGE',
      'SCOTTVILLE',
      'SHERRILLS FORD',
      'SPARTA',
      'STATE ROAD',
      'STONY POINT',
      'SUGAR GROVE',
      'TAYLORSVILLE',
      'TERRELL',
      'THURMOND',
      'TODD',
      'TRAPHILL',
      'TURNERSBURG',
      'UNION GROVE',
      'VALDESE',
      'VALLE CRUCIS',
      'VILAS',
      'WARRENSVILLE',
      'WEST JEFFERSON',
      'WILKESBORO',
      'ZIONVILLE',
      'SCOTTS',
      'ALEXANDER',
      'ALMOND',
      'ARDEN',
      'BALASKAERSVILLE',
      'BALSAM',
      'BALSAM GROVE',
      'BARNARDSVILLE',
      'BAT CAVE',
      'BLACK MOUNTAIN',
      'BREVARD',
      'BRYSON CITY',
      'BURNSVILLE',
      'CANDLER',
      'CANTON',
      'CASHIERS',
      'CEDAR MOUNTAIN',
      'CHEROKEE',
      'CHIMNEY ROCK',
      'CLYDE',
      'COLUMBUS',
      'CULLOWHEE',
      'DANA',
      'DILLSBORO',
      'EAST FLAT ROCK',
      'EDNEYVILLE',
      'ENKA',
      'ETOWAH',
      'FAIRVIEW',
      'FLAT ROCK',
      'FLETCHER',
      'FONTANA DAM',
      'FRANKLIN',
      'GERTON',
      'GLENVILLE',
      'GLENWOOD',
      'HAZELWOOD',
      'HENDERSONVILLE',
      'GREEN MOUNTAIN',
      'HIGHLANDS',
      'HORSE SHOE',
      'HOT SPRINGS',
      'LALASKAE JUNALUSKA',
      'LALASKAE LURE',
      'LALASKAE TOXAWAY',
      'LEICESTER',
      'LITTLE SWITZERLAND',
      'LYNN',
      'MAGGIE VALLEY',
      'MARION',
      'MARSHALL',
      'MARS HILL',
      'MICAVILLE',
      'MILL SPRING',
      'MONTREAT',
      'MOUNTAIN HOME',
      'NAPLES',
      'NEBO',
      'OLD FORT',
      'OTTO',
      'PENLAND',
      'PENROSE',
      'PISGAH FOREST',
      'RIDGECREST',
      'ROBBINSVILLE',
      'ROSMAN',
      'SALUDA',
      'SAPPHIRE',
      'SCALY MOUNTAIN',
      'SKYLAND',
      'SPRUCE PINE',
      'SWANNANOA',
      'SYLVA',
      'TOPTON',
      'TRYON',
      'TUCKASEGEE',
      'TUXEDO',
      'WAYNESVILLE',
      'WEAVERVILLE',
      'WEBSTER',
      'WHITTIER',
      'ZIRCONIA',
      'ASHEVILLE',
      'ANDREWS',
      'BRASSTOWN',
      'CULBERSON',
      'HAYESVILLE',
      'MARBLE',
      'MURPHY',
      'WARNE'
    ]
  },
  ND: {
    code: 'ND',
    name: 'NORTH DAKOTA',
    cities: [
      'ABERCROMBIE',
      'ABSARALASKAA',
      'AMENIA',
      'ARGUSVILLE',
      'ARTHUR',
      'AYR',
      'BARNEY',
      'BLANCHARD',
      'BUFFALO',
      'CASSELTON',
      'CAYUGA',
      'CHAFFEE',
      'CHRISTINE',
      'CLIFFORD',
      'COGSWELL',
      'COLFAX',
      'DAVENPORT',
      'ENDERLIN',
      'ERIE',
      'FAIRMOUNT',
      'FINGAL',
      'FORMAN',
      'FORT RANSOM',
      'GALESBURG',
      'GARDNER',
      'GRANDIN',
      'GREAT BEND',
      'GWINNER',
      'HANKINSON',
      'HARWOOD',
      'HAVANA',
      'HILLSBORO',
      'HOPE',
      'HORACE',
      'HUNTER',
      'KATHRYN',
      'KINDRED',
      'LEONARD',
      'LIDGERWOOD',
      'LISBON',
      'LUVERNE',
      'MCLEOD',
      'MANTADOR',
      'MAPLETON',
      'MILNOR',
      'MOORETON',
      'NOME',
      'ORISKA',
      'PAGE',
      'PILLSBURY',
      'RUTLAND',
      'SHELDON',
      'STIRUM',
      'TOWER CITY',
      'VALLEY CITY',
      'WAHPETON',
      'WALCOTT',
      'WEST FARGO',
      'WHEATLAND',
      'WYNDMERE',
      'FARGO',
      'GRAND FORKS',
      'GRAND FORKS AFB',
      'ADAMS',
      'ANETA',
      'ARDOCH',
      'ARVILLA',
      'BATHGATE',
      'BUXTON',
      'CALEDONIA',
      'CAVALIER',
      'CRYSTAL',
      'CUMMINGS',
      'DAHLEN',
      'DRAYTON',
      'EDINBURG',
      'EMERADO',
      'FAIRDALE',
      'FINLEY',
      'FORDVILLE',
      'FOREST RIVER',
      'GILBY',
      'GLASSTON',
      'GRAFTON',
      'HAMILTON',
      'HANNAH',
      'HATTON',
      'HENSEL',
      'HOOPLE',
      'INKSTER',
      'LANGDON',
      'LANKIN',
      'LARIMORE',
      'MCVILLE',
      'MAIDA',
      'MANVEL',
      'MAYVILLE',
      'MEKINOCK',
      'MICHIGAN',
      'MILTON',
      'MINTO',
      'MOUNTAIN',
      'NECHE',
      'NIAGARA',
      'NORTHWOOD',
      'OSNABROCK',
      'PARK RIVER',
      'PEMBINA',
      'PETERSBURG',
      'PISEK',
      'PORTLAND',
      'REYNOLDS',
      'SAINT THOMAS',
      'SHARON',
      'THOMPSON',
      'WALES',
      'WALHALLA',
      'DEVILS LALASKAE',
      'AGATE',
      'ALSEN',
      'BALTA',
      'BELCOURT',
      'BISBEE',
      'BOTTINEAU',
      'BREMEN',
      'BRINSMADE',
      'BROCKET',
      'CALVIN',
      'CANDO',
      'CHURCHS FERRY',
      'CRARY',
      'DUNSEITH',
      'EDMORE',
      'EGELAND',
      'ESMOND',
      'FORT TOTTEN',
      'HAMBERG',
      'HAMPDEN',
      'HANSBORO',
      'HARVEY',
      'KNOX',
      'LALASKAOTA',
      'LAWTON',
      'LEEDS',
      'MADDOCK',
      'MINNEWAUKAN',
      'MUNICH',
      'MYLO',
      'NEKOMA',
      'NEW ROCKFORD',
      'OBERON',
      'ORRIN',
      'PEKIN',
      'PENN',
      'PERTH',
      'ROCKLALASKAE',
      'ROLETTE',
      'ROLLA',
      'RUGBY',
      'SAINT JOHN',
      'SAINT MICHAEL',
      'SARLES',
      'SHEYENNE',
      'STARKWEATHER',
      'TOKIO',
      'TOLNA',
      'WARWICK',
      'WEBSTER',
      'WILLOW CITY',
      'WOLFORD',
      'YORK',
      'JAMESTOWN',
      'ASHLEY',
      'BERLIN',
      'BINFORD',
      'BOWDON',
      'BUCHANAN',
      'CARRINGTON',
      'CATHAY',
      'CHASELEY',
      'CLEVELAND',
      'COOPERSTOWN',
      'COURTENAY',
      'DAWSON',
      'DAZEY',
      'DENHOFF',
      'DICKEY',
      'ECKELSON',
      'EDGELEY',
      'ELLENDALE',
      'FESSENDEN',
      'FORBES',
      'FREDONIA',
      'FULLERTON',
      'GACKLE',
      'GLENFIELD',
      'GOODRICH',
      'GRACE CITY',
      'HANNAFORD',
      'HURDSFIELD',
      'JESSIE',
      'JUD',
      'KENSAL',
      'KULM',
      'LAMOURE',
      'LEHR',
      'LITCHVILLE',
      'MCCLUSKY',
      'MCHENRY',
      'MARION',
      'MEDINA',
      'MONTPELIER',
      'OALASKAES',
      'PETTIBONE',
      'PINGREE',
      'REGAN',
      'ROBINSON',
      'ROGERS',
      'SANBORN',
      'SPIRITWOOD',
      'STEELE',
      'STREETER',
      'SUTTON',
      'SYKESTON',
      'TAPPEN',
      'TUTTLE',
      'VENTURIA',
      'VERONA',
      'WIMBLEDON',
      'WING',
      'WISHEK',
      'WOODWORTH',
      'YPSILANTI',
      'BISMARCK',
      'ALMONT',
      'BALDWIN',
      'BEULAH',
      'BRADDOCK',
      'CANNON BALL',
      'CARSON',
      'CENTER',
      'COLEHARBOR',
      'DRISCOLL',
      'ELGIN',
      'FLASHER',
      'FORT YATES',
      'GARRISON',
      'GOLDEN VALLEY',
      'HAGUE',
      'HAZELTON',
      'HAZEN',
      'KINTYRE',
      'LINTON',
      'MCKENZIE',
      'MANDAN',
      'MENOKEN',
      'MERCER',
      'MOFFIT',
      'NAPOLEON',
      'NEW LEIPZIG',
      'NEW SALEM',
      'RALEIGH',
      'RIVERDALE',
      'SAINT ANTHONY',
      'SELFRIDGE',
      'SHIELDS',
      'SOLEN',
      'STANTON',
      'STERLING',
      'STRASBURG',
      'TURTLE LALASKAE',
      'UNDERWOOD',
      'WASHBURN',
      'WILTON',
      'ZAP',
      'ZEELAND',
      'DICKINSON',
      'AMIDON',
      'BEACH',
      'BELFIELD',
      'BOWMAN',
      'DODGE',
      'DUNN CENTER',
      'FAIRFIELD',
      'GLADSTONE',
      'GLEN ULLIN',
      'GOLVA',
      'GRASSY BUTTE',
      'HALLIDAY',
      'HEBRON',
      'HETTINGER',
      'KILLDEER',
      'LEFOR',
      'MANNING',
      'MARMARTH',
      'MARSHALL',
      'MEDORA',
      'MOTT',
      'NEW ENGLAND',
      'REEDER',
      'REGENT',
      'RHAME',
      'RICHARDTON',
      'SCRANTON',
      'SENTINEL BUTTE',
      'SOUTH HEART',
      'TAYLOR',
      'MINOT',
      'MINOT AFB',
      'ANAMOOSE',
      'ANTLER',
      'BALFOUR',
      'BANTRY',
      'BENEDICT',
      'BERTHOLD',
      'BOWBELLS',
      'BURLINGTON',
      'BUTTE',
      'CARPIO',
      'COLUMBUS',
      'CROSBY',
      'DEERING',
      'DES LACS',
      'DONNYBROOK',
      'DOUGLAS',
      'DRALASKAE',
      'FLAXTON',
      'GLENBURN',
      'GRANVILLE',
      'KARLSRUHE',
      'KENMARE',
      'KIEF',
      'KRAMER',
      'LANSFORD',
      'LIGNITE',
      'MCGREGOR',
      'MALASKAOTI',
      'MANDAREE',
      'MARTIN',
      'MAX',
      'MAXBASS',
      'MOHALL',
      'NEWBURG',
      'NEW TOWN',
      'NOONAN',
      'NORWICH',
      'PALERMO',
      'PARSHALL',
      'PLAZA',
      'PORTAL',
      'POWERS LALASKAE',
      'ROSEGLEN',
      'ROSS',
      'RUSO',
      'RYDER',
      'SAWYER',
      'SHERWOOD',
      'SOURIS',
      'STANLEY',
      'SURREY',
      'TOLLEY',
      'TOWNER',
      'UPHAM',
      'VELVA',
      'VOLTAIRE',
      'WESTHOPE',
      'WHITE EARTH',
      'WILDROSE',
      'WILLISTON',
      'ALAMO',
      'ALEXANDER',
      'AMBROSE',
      'ARNEGARD',
      'CARTWRIGHT',
      'EPPING',
      'FORTUNA',
      'GRENORA',
      'KEENE',
      'RAY',
      'TIOGA',
      'TRENTON',
      'WATFORD CITY',
      'ZAHL'
    ]
  },
  NE: {
    code: 'NE',
    name: 'NEBRASKA',
    cities: [
      'ABIE',
      'ARLINGTON',
      'ASHLAND',
      'BANCROFT',
      'BELLEVUE',
      'BENNINGTON',
      'BLAIR',
      'BOYS TOWN',
      'BRUNO',
      'CEDAR BLUFFS',
      'CEDAR CREEK',
      'CERESCO',
      'COLON',
      'CRAIG',
      'DECATUR',
      'ELKHORN',
      'FORT CALHOUN',
      'FREMONT',
      'GRETNA',
      'HERMAN',
      'HOMER',
      'HOOPER',
      'ITHACA',
      'KENNARD',
      'LESHARA',
      'LINWOOD',
      'LOUISVILLE',
      'LYONS',
      'MACY',
      'MALMO',
      'MEAD',
      'MEMPHIS',
      'NICKERSON',
      'OALASKALAND',
      'PAPILLION',
      'PENDER',
      'PLATTSMOUTH',
      'PRAGUE',
      'RICHFIELD',
      'ROSALIE',
      'ST COLUMBANS',
      'SCRIBNER',
      'SOUTH BEND',
      'SPRINGFIELD',
      'TEKAMAH',
      'THURSTON',
      'UEHLING',
      'VALLEY',
      'VALPARAISO',
      'WAHOO',
      'WALTHILL',
      'WASHINGTON',
      'WATERLOO',
      'WESTON',
      'WINNEBAGO',
      'WINSLOW',
      'YUTAN',
      'OMAHA',
      'OFFUTT A F B',
      'LA VISTA',
      'ADAMS',
      'ALEXANDRIA',
      'ALVO',
      'AUBURN',
      'AVOCA',
      'BARNESTON',
      'BEATRICE',
      'BEAVER CROSSING',
      'BEE',
      'BELVIDERE',
      'BENEDICT',
      'BENNET',
      'BLUE SPRINGS',
      'BRADSHAW',
      'BROCK',
      'BROWNVILLE',
      'BRUNING',
      'BURCHARD',
      'BURR',
      'BYRON',
      'CARLETON',
      'CHESTER',
      'CLATONIA',
      'COOK',
      'CORDOVA',
      'CORTLAND',
      'CRAB ORCHARD',
      'CRETE',
      'DAVENPORT',
      'DAVEY',
      'DAWSON',
      'DAYKIN',
      'DENTON',
      'DESHLER',
      'DE WITT',
      'DILLER',
      'DORCHESTER',
      'DOUGLAS',
      'DU BOIS',
      'DUNBAR',
      'EAGLE',
      'ELK CREEK',
      'ELMWOOD',
      'ENDICOTT',
      'EXETER',
      'FAIRBURY',
      'FAIRMONT',
      'FALLS CITY',
      'FILLEY',
      'FIRTH',
      'FRIEND',
      'GARLAND',
      'GENEVA',
      'GILEAD',
      'GOEHNER',
      'GRAFTON',
      'GREENWOOD',
      'GRESHAM',
      'HALLAM',
      'HEBRON',
      'HENDERSON',
      'HICKMAN',
      'HUBBELL',
      'HUMBOLDT',
      'JANSEN',
      'JOHNSON',
      'LEWISTON',
      'LIBERTY',
      'LORTON',
      'MC COOL JUNCTION',
      'MALCOLM',
      'MANLEY',
      'MARTELL',
      'MILFORD',
      'MILLIGAN',
      'MURDOCK',
      'MURRAY',
      'NEBRASKA CITY',
      'NEHAWKA',
      'NEMAHA',
      'ODELL',
      'OHIOWA',
      'OTOE',
      'PALMYRA',
      'PANAMA',
      'PAWNEE CITY',
      'PERU',
      'PICKRELL',
      'PLEASANT DALE',
      'PLYMOUTH',
      'RAYMOND',
      'REYNOLDS',
      'ROCA',
      'RULO',
      'SALEM',
      'SEWARD',
      'SHICKLEY',
      'SHUBERT',
      'SPRAGUE',
      'STAPLEHURST',
      'STEELE CITY',
      'STEINAUER',
      'STELLA',
      'STERLING',
      'STRANG',
      'SWANTON',
      'SYRACUSE',
      'TABLE ROCK',
      'TALMAGE',
      'TECUMSEH',
      'ONG',
      'TOBIAS',
      'UNADILLA',
      'UNION',
      'UTICA',
      'VERDON',
      'VIRGINIA',
      'WACO',
      'WALTON',
      'WAVERLY',
      'WEEPING WATER',
      'WESTERN',
      'WILBER',
      'WYMORE',
      'YORK',
      'LINCOLN',
      'COLUMBUS',
      'ALBION',
      'AMES',
      'BARTLETT',
      'BELGRADE',
      'BELLWOOD',
      'BRAINARD',
      'CEDAR RAPIDS',
      'CLARKS',
      'CLARKSON',
      'CRESTON',
      'DAVID CITY',
      'DODGE',
      'DUNCAN',
      'DWIGHT',
      'ELGIN',
      'ERICSON',
      'FULLERTON',
      'GENOA',
      'HOWELLS',
      'HUMPHREY',
      'LEIGH',
      'LINDSAY',
      'MONROE',
      'MORSE BLUFF',
      'NORTH BEND',
      'OSCEOLA',
      'PETERSBURG',
      'PLATTE CENTER',
      'POLK',
      'PRIMROSE',
      'RISING CITY',
      'ROGERS',
      'SAINT EDWARD',
      'SCHUYLER',
      'SHELBY',
      'SILVER CREEK',
      'SNYDER',
      'SPALDING',
      'STROMSBURG',
      'SURPRISE',
      'ULYSSES',
      'NORFOLK',
      'ALLEN',
      'AMELIA',
      'ATKINSON',
      'BASSETT',
      'BATTLE CREEK',
      'BEEMER',
      'BELDEN',
      'BLOOMFIELD',
      'BRISTOW',
      'BRUNSWICK',
      'BUTTE',
      'CARROLL',
      'CENTER',
      'CHAMBERS',
      'CLEARWATER',
      'COLERIDGE',
      'CONCORD',
      'CREIGHTON',
      'CROFTON',
      'DALASKAOTA CITY',
      'DIXON',
      'EMERSON',
      'EMMET',
      'EWING',
      'FORDYCE',
      'FOSTER',
      'HADAR',
      'HARTINGTON',
      'HOSKINS',
      'HUBBARD',
      'INMAN',
      'JACKSON',
      'LAUREL',
      'LYNCH',
      'MCLEAN',
      'MADISON',
      'MAGNET',
      'MASKELL',
      'MEADOW GROVE',
      'MILLS',
      'NAPER',
      'NELIGH',
      'NEWCASTLE',
      'NEWMAN GROVE',
      'NEWPORT',
      'NIOBRARA',
      'OALASKADALE',
      'ONEILL',
      'ORCHARD',
      'OSMOND',
      'PAGE',
      'PIERCE',
      'PILGER',
      'PLAINVIEW',
      'PONCA',
      'RANDOLPH',
      'ROSE',
      'ROYAL',
      'SAINT HELENA',
      'SOUTH SIOUX CITY',
      'SPENCER',
      'SPRINGVIEW',
      'STANTON',
      'STUART',
      'TILDEN',
      'VERDIGRE',
      'WALASKAEFIELD',
      'WATERBURY',
      'WAUSA',
      'WAYNE',
      'WEST POINT',
      'WINNETOON',
      'WINSIDE',
      'WISNER',
      'WYNOT',
      'GRAND ISLAND',
      'ALDA',
      'AMHERST',
      'ANSELMO',
      'ANSLEY',
      'ARCADIA',
      'ARCHER',
      'ASHTON',
      'AURORA',
      'BERWYN',
      'BOELUS',
      'BREWSTER',
      'BROKEN BOW',
      'BURWELL',
      'CAIRO',
      'CALLAWAY',
      'CENTRAL CITY',
      'CHAPMAN',
      'COMSTOCK',
      'DANNEBROG',
      'DONIPHAN',
      'DUNNING',
      'EDDYVILLE',
      'ELBA',
      'ELM CREEK',
      'ELYRIA',
      'FARWELL',
      'GIBBON',
      'GILTNER',
      'GREELEY',
      'HAMPTON',
      'HAZARD',
      'KEARNEY',
      'HORDVILLE',
      'LEXINGTON',
      'LITCHFIELD',
      'LOUP CITY',
      'MARQUETTE',
      'MASON CITY',
      'MERNA',
      'MILLER',
      'NORTH LOUP',
      'OCONTO',
      'ODESSA',
      'ORD',
      'OVERTON',
      'PALMER',
      'PHILLIPS',
      'PLEASANTON',
      'RAVENNA',
      'RIVERDALE',
      'ROCKVILLE',
      'SAINT LIBORY',
      'SAINT PAUL',
      'SARGENT',
      'SCOTIA',
      'SHELTON',
      'SUMNER',
      'TAYLOR',
      'WEISSERT',
      'WESTERVILLE',
      'WOLBACH',
      'WOOD RIVER',
      'HASTINGS',
      'ALMA',
      'ARAPAHOE',
      'ATLANTA',
      'AXTELL',
      'AYR',
      'BEAVER CITY',
      'BERTRAND',
      'BLADEN',
      'BLOOMINGTON',
      'BLUE HILL',
      'CAMPBELL',
      'CLAY CENTER',
      'DEWEESE',
      'EDGAR',
      'EDISON',
      'ELWOOD',
      'FAIRFIELD',
      'FRANKLIN',
      'FUNK',
      'GLENVIL',
      'GUIDE ROCK',
      'HARDY',
      'HARVARD',
      'HEARTWELL',
      'HENDLEY',
      'HILDRETH',
      'HOLBROOK',
      'HOLDREGE',
      'HOLSTEIN',
      'INAVALE',
      'INLAND',
      'JUNIATA',
      'KENESAW',
      'LAWRENCE',
      'LOOMIS',
      'MINDEN',
      'NAPONEE',
      'NELSON',
      'NORMAN',
      'OALASKA',
      'ORLEANS',
      'OXFORD',
      'RAGAN',
      'RED CLOUD',
      'REPUBLICAN CITY',
      'RIVERTON',
      'ROSELAND',
      'RUSKIN',
      'SARONVILLE',
      'SMITHFIELD',
      'STAMFORD',
      'SUPERIOR',
      'SUTTON',
      'TRUMBULL',
      'UPLAND',
      'WILCOX',
      'MC COOK',
      'BARTLEY',
      'BENKELMAN',
      'CAMBRIDGE',
      'CHAMPION',
      'CULBERTSON',
      'CURTIS',
      'DANBURY',
      'ENDERS',
      'EUSTIS',
      'FARNAM',
      'HAIGLER',
      'HAMLET',
      'HAYES CENTER',
      'IMPERIAL',
      'INDIANOLA',
      'LEBANON',
      'MAX',
      'MAYWOOD',
      'MOOREFIELD',
      'PALISADE',
      'PARKS',
      'STOCKVILLE',
      'STRATTON',
      'TRENTON',
      'WAUNETA',
      'WILSONVILLE',
      'NORTH PLATTE',
      'ARNOLD',
      'ARTHUR',
      'BIG SPRINGS',
      'BRADY',
      'BROADWATER',
      'BRULE',
      'BUSHNELL',
      'CHAPPELL',
      'COZAD',
      'DALTON',
      'DICKENS',
      'DIX',
      'ELSIE',
      'ELSMERE',
      'GOTHENBURG',
      'GRANT',
      'GURLEY',
      'HALSEY',
      'HERSHEY',
      'KEYSTONE',
      'KIMBALL',
      'LEMOYNE',
      'LEWELLEN',
      'LISCO',
      'LODGEPOLE',
      'MADRID',
      'MAXWELL',
      'MULLEN',
      'OGALLALA',
      'OSHKOSH',
      'PAXTON',
      'POTTER',
      'PURDUM',
      'SIDNEY',
      'SENECA',
      'STAPLETON',
      'SUTHERLAND',
      'THEDFORD',
      'TRYON',
      'VENANGO',
      'WALLACE',
      'WELLFLEET',
      'WILLOW ISLAND',
      'VALENTINE',
      'AINSWORTH',
      'CODY',
      'CROOKSTON',
      'JOHNSTOWN',
      'KILGORE',
      'LONG PINE',
      'MERRIMAN',
      'NENZEL',
      'SPARKS',
      'WOOD LALASKAE',
      'ALLIANCE',
      'ANGORA',
      'ASHBY',
      'BAYARD',
      'BINGHAM',
      'BRIDGEPORT',
      'CHADRON',
      'CRAWFORD',
      'ELLSWORTH',
      'GERING',
      'GORDON',
      'HARRISBURG',
      'HARRISON',
      'HAY SPRINGS',
      'HEMINGFORD',
      'HENRY',
      'HYANNIS',
      'LALASKAESIDE',
      'LYMAN',
      'MCGREW',
      'MARSLAND',
      'MELBETA',
      'MINATARE',
      'MITCHELL',
      'MORRILL',
      'RUSHVILLE',
      'SCOTTSBLUFF',
      'WHITECLAY',
      'WHITMAN',
      'WHITNEY'
    ]
  },
  NH: {
    code: 'NH',
    name: 'NEW HAMPSHIRE',
    cities: [
      'AMHERST',
      'AUBURN',
      'BROOKLINE',
      'CANDIA',
      'CHESTER',
      'DEERFIELD',
      'DERRY',
      'EAST CANDIA',
      'EAST DERRY',
      'EPPING',
      'FRANCESTOWN',
      'FREMONT',
      'GOFFSTOWN',
      'DUNBARTON',
      'GREENFIELD',
      'GREENVILLE',
      'HOLLIS',
      'HUDSON',
      'LITCHFIELD',
      'LONDONDERRY',
      'MERRIMACK',
      'MILFORD',
      'MONT VERNON',
      'NASHUA',
      'NEW BOSTON',
      'NEW IPSWICH',
      'NORTH SALEM',
      'PELHAM',
      'RAYMOND',
      'SALEM',
      'LYNDEBOROUGH',
      'TEMPLE',
      'WILTON',
      'WINDHAM',
      'MANCHESTER',
      'HOOKSETT',
      'BEDFORD',
      'WATERVILLE VALLEY',
      'ANDOVER',
      'ASHLAND',
      'BARNSTEAD',
      'BELMONT',
      'BRADFORD',
      'BRISTOL',
      'CAMPTON',
      'CANTERBURY',
      'CENTER BARNSTEAD',
      'CENTER HARBOR',
      'CENTER SANDWICH',
      'CONTOOCOOK',
      'DANBURY',
      'EAST ANDOVER',
      'EAST HEBRON',
      'ELKINS',
      'EPSOM',
      'FRANKLIN',
      'GILMANTON',
      'GLENCLIFF',
      'GRAFTON',
      'HEBRON',
      'HENNIKER',
      'HILL',
      'HILLSBORO',
      'HOLDERNESS',
      'LACONIA',
      'GILFORD',
      'LINCOLN',
      'LOCHMERE',
      'MEREDITH',
      'MOULTONBOROUGH',
      'NEWBURY',
      'NEW HAMPTON',
      'NEW LONDON',
      'CHICHESTER',
      'NORTH SANDWICH',
      'NORTH SUTTON',
      'NORTHWOOD',
      'NORTH WOODSTOCK',
      'PITTSFIELD',
      'PLYMOUTH',
      'RUMNEY',
      'SALISBURY',
      'SANBORNTON',
      'SOUTH NEWBURY',
      'SOUTH SUTTON',
      'STINSON LALASKAE',
      'SUNCOOK',
      'TILTON',
      'WARNER',
      'WARREN',
      'WASHINGTON',
      'WEARE',
      'WENTWORTH',
      'SPRINGFIELD',
      'WILMOT',
      'WINNISQUAM',
      'NOTTINGHAM',
      'WEST NOTTINGHAM',
      'WOODSTOCK',
      'CONCORD',
      'BOW',
      'LOUDON',
      'KEENE',
      'ANTRIM',
      'ASHUELOT',
      'BENNINGTON',
      'CHESTERFIELD',
      'DUBLIN',
      'SULLIVAN',
      'SWANZEY',
      'FITZWILLIAM',
      'GILSUM',
      'HANCOCK',
      'HARRISVILLE',
      'HINSDALE',
      'JAFFREY',
      'MARLBOROUGH',
      'MARLOW',
      'NELSON',
      'PETERBOROUGH',
      'RINDGE',
      'SPOFFORD',
      'STODDARD',
      'TROY',
      'WEST CHESTERFIELD',
      'WESTMORELAND',
      'WEST PETERBOROUGH',
      'WEST SWANZEY',
      'WINCHESTER',
      'LITTLETON',
      'BERLIN',
      'BETHLEHEM',
      'BRETTON WOODS',
      'COLEBROOK',
      'ERROL',
      'FRANCONIA',
      'GORHAM',
      'GROVETON',
      'JEFFERSON',
      'LANCASTER',
      'LISBON',
      'MEADOWS',
      'MILAN',
      'MOUNT WASHINGTON',
      'NORTH STRATFORD',
      'PITTSBURG',
      'TWIN MOUNTAIN',
      'WEST STEWARTSTOWN',
      'WHITEFIELD',
      'ACWORTH',
      'ALSTEAD',
      'CHARLESTOWN',
      'DREWSVILLE',
      'LEMPSTER',
      'SOUTH ACWORTH',
      'WALPOLE',
      'NORTH WALPOLE',
      'BATH',
      'CANAAN',
      'CLAREMONT',
      'CORNISH',
      'CORNISH FLAT',
      'ENFIELD',
      'ENFIELD CENTER',
      'ETNA',
      'GEORGES MILLS',
      'GOSHEN',
      'GRANTHAM',
      'GUILD',
      'HANOVER',
      'LEBANON',
      'HAVERHILL',
      'LYME',
      'LYME CENTER',
      'MERIDEN',
      'MONROE',
      'NEWPORT',
      'NORTH HAVERHILL',
      'ORFORD',
      'PIERMONT',
      'PIKE',
      'PLAINFIELD',
      'SUNAPEE',
      'WEST LEBANON',
      'WOODSVILLE',
      'PORTSMOUTH',
      'ROLLINSFORD',
      'ALTON',
      'ALTON BAY',
      'ATKINSON',
      'BARTLETT',
      'CENTER CONWAY',
      'CENTER OSSIPEE',
      'CENTER STRAFFORD',
      'CENTER TUFTONBORO',
      'CHOCORUA',
      'CONWAY',
      'DANVILLE',
      'DOVER',
      'DURHAM',
      'BARRINGTON',
      'EAST HAMPSTEAD',
      'EAST KINGSTON',
      'EAST WALASKAEFIELD',
      'EATON CENTER',
      'EXETER',
      'FARMINGTON',
      'FREEDOM',
      'GILMANTON IRON WORKS',
      'GLEN',
      'ROCHESTER',
      'GREENLAND',
      'HAMPSTEAD',
      'HAMPTON',
      'HAMPTON FALLS',
      'INTERVALE',
      'JACKSON',
      'KEARSARGE',
      'KINGSTON',
      'MADISON',
      'MELVIN VILLAGE',
      'MILTON',
      'MILTON MILLS',
      'MIRROR LALASKAE',
      'NEW CASTLE',
      'NEW DURHAM',
      'NEWFIELDS',
      'NEWMARKET',
      'NEWTON',
      'NEWTON JUNCTION',
      'NORTH CONWAY',
      'NORTH HAMPTON',
      'OSSIPEE',
      'PLAISTOW',
      'RYE',
      'RYE BEACH',
      'SANBORNVILLE',
      'SANDOWN',
      'SEABROOK',
      'SILVER LALASKAE',
      'SOMERSWORTH',
      'EFFINGHAM',
      'SOUTH TAMWORTH',
      'STRAFFORD',
      'STRATHAM',
      'TAMWORTH',
      'UNION',
      'WEST OSSIPEE',
      'WOLFEBORO',
      'WOLFEBORO FALLS',
      'WONALANCET'
    ]
  },
  NJ: {
    code: 'NJ',
    name: 'NEW JERSEY',
    cities: [
      'AVENEL',
      'BAYONNE',
      'BLOOMFIELD',
      'FAIRFIELD',
      'BOONTON',
      'CALDWELL',
      'CARTERET',
      'CEDAR GROVE',
      'CLIFFSIDE PARK',
      'CLIFTON',
      'CRANFORD',
      'EAST ORANGE',
      'EDGEWATER',
      'ESSEX FELLS',
      'FAIRVIEW',
      'FANWOOD',
      'FORT LEE',
      'GARFIELD',
      'GARWOOD',
      'GLEN RIDGE',
      'HARRISON',
      'HOBOKEN',
      'NORTH ARLINGTON',
      'KEARNY',
      'KENILWORTH',
      'LALASKAE HIAWATHA',
      'LINCOLN PARK',
      'LINDEN',
      'LIVINGSTON',
      'MAPLEWOOD',
      'MILLBURN',
      'MONTCLAIR',
      'VERONA',
      'MONTVILLE',
      'MOUNTAIN LALASKAES',
      'NORTH BERGEN',
      'ORANGE',
      'WEST ORANGE',
      'PARSIPPANY',
      'PASSAIC',
      'WALLINGTON',
      'PINE BROOK',
      'WARREN',
      'PLAINFIELD',
      'PORT READING',
      'RAHWAY',
      'CLARK',
      'COLONIA',
      'ROSELAND',
      'WATCHUNG',
      'RUTHERFORD',
      'LYNDHURST',
      'CARLSTADT',
      'EAST RUTHERFORD',
      'MOONACHIE',
      'WOOD RIDGE',
      'SCOTCH PLAINS',
      'SEWAREN',
      'SHORT HILLS',
      'SOUTH ORANGE',
      'SOUTH PLAINFIELD',
      'SPRINGFIELD',
      'TOWACO',
      'UNION',
      'WEEHAWKEN',
      'UNION CITY',
      'VAUXHALL',
      'WESTFIELD',
      'MOUNTAINSIDE',
      'WEST NEW YORK',
      'SECAUCUS',
      'WOODBRIDGE',
      'JERSEY CITY',
      'NEWARK',
      'BELLEVILLE',
      'NUTLEY',
      'IRVINGTON',
      'ELIZABETH',
      'ROSELLE',
      'ROSELLE PARK',
      'HILLSIDE',
      'ALLENDALE',
      'BLOOMINGDALE',
      'BUTLER',
      'ELMWOOD PARK',
      'FAIR LAWN',
      'FRANKLIN',
      'FRANKLIN LALASKAES',
      'GLENWOOD',
      'HAMBURG',
      'HASKELL',
      'HEWITT',
      'HIGHLAND LALASKAES',
      'HO HO KUS',
      'LITTLE FALLS',
      'MC AFEE',
      'MAHWAH',
      'MIDLAND PARK',
      'NEWFOUNDLAND',
      'OALASKALAND',
      'OALASKA RIDGE',
      'OGDENSBURG',
      'PEQUANNOCK',
      'POMPTON LALASKAES',
      'POMPTON PLAINS',
      'RAMSEY',
      'RIDGEWOOD',
      'GLEN ROCK',
      'RINGWOOD',
      'RIVERDALE',
      'SADDLE RIVER',
      'STOCKHOLM',
      'SUSSEX',
      'VERNON',
      'WALDWICK',
      'WANAQUE',
      'WAYNE',
      'WEST MILFORD',
      'WYCKOFF',
      'PATERSON',
      'HAWTHORNE',
      'HALEDON',
      'TOTOWA',
      'HACKENSACK',
      'BOGOTA',
      'HASBROUCK HEIGHTS',
      'LEONIA',
      'SOUTH HACKENSACK',
      'MAYWOOD',
      'TETERBORO',
      'ALPINE',
      'BERGENFIELD',
      'CLOSTER',
      'CRESSKILL',
      'DEMAREST',
      'DUMONT',
      'EMERSON',
      'ENGLEWOOD',
      'ENGLEWOOD CLIFFS',
      'HARRINGTON PARK',
      'HAWORTH',
      'HILLSDALE',
      'LITTLE FERRY',
      'LODI',
      'MONTVALE',
      'NEW MILFORD',
      'NORTHVALE',
      'NORWOOD',
      'ORADELL',
      'PALISADES PARK',
      'PARAMUS',
      'PARK RIDGE',
      'RIDGEFIELD',
      'RIDGEFIELD PARK',
      'RIVER EDGE',
      'ROCHELLE PARK',
      'SADDLE BROOK',
      'TEANECK',
      'TENAFLY',
      'WESTWOOD',
      'TOWNSHIP OF WASHINGTON',
      'WOODCLIFF LALASKAE',
      'RED BANK',
      'SHREWSBURY',
      'FORT MONMOUTH',
      'FAIR HAVEN',
      'ALLENHURST',
      'ADELPHIA',
      'ASBURY PARK',
      'BELMAR',
      'ATLANTIC HIGHLANDS',
      'AVON BY THE SEA',
      'BELFORD',
      'BRADLEY BEACH',
      'CLIFFWOOD',
      'COLTS NECK',
      'DEAL',
      'EATONTOWN',
      'ENGLISHTOWN',
      'FARMINGDALE',
      'FREEHOLD',
      'HAZLET',
      'HOWELL',
      'HIGHLANDS',
      'HOLMDEL',
      'KEANSBURG',
      'KEYPORT',
      'LEONARDO',
      'LINCROFT',
      'LITTLE SILVER',
      'LONG BRANCH',
      'MARLBORO',
      'MATAWAN',
      'MIDDLETOWN',
      'MONMOUTH BEACH',
      'MORGANVILLE',
      'NAVESINK',
      'NEPTUNE',
      'OALASKAHURST',
      'OCEAN GROVE',
      'OCEANPORT',
      'PORT MONMOUTH',
      'RUMSON',
      'SPRING LALASKAE',
      'TENNENT',
      'WEST LONG BRANCH',
      'WICKATUNK',
      'DOVER',
      'MINE HILL',
      'PICATINNY ARSENAL',
      'ALLAMUCHY',
      'ANDOVER',
      'AUGUSTA',
      'BELVIDERE',
      'BLAIRSTOWN',
      'BRANCHVILLE',
      'MONTAGUE',
      'BUDD LALASKAE',
      'BUTTZVILLE',
      'CALIFON',
      'CHANGEWATER',
      'COLUMBIA',
      'DELAWARE',
      'DENVILLE',
      'FLANDERS',
      'GLASSER',
      'GREAT MEADOWS',
      'GREENDELL',
      'HACKETTSTOWN',
      'HIBERNIA',
      'HOPATCONG',
      'HOPE',
      'IRONIA',
      'JOHNSONBURG',
      'KENVIL',
      'LAFAYETTE',
      'LALASKAE HOPATCONG',
      'LANDING',
      'LAYTON',
      'LEDGEWOOD',
      'LONG VALLEY',
      'MIDDLEVILLE',
      'MOUNT ARLINGTON',
      'NETCONG',
      'NEWTON',
      'OXFORD',
      'PORT MURRAY',
      'ROCKAWAY',
      'RANDOLPH',
      'SCHOOLEYS MOUNTAIN',
      'SPARTA',
      'STANHOPE',
      'STILLWATER',
      'SUCCASUNNA',
      'SWARTSWOOD',
      'MOUNT TABOR',
      'TRANQUILITY',
      'VIENNA',
      'WALLPACK CENTER',
      'WASHINGTON',
      'WHARTON',
      'SUMMIT',
      'BASKING RIDGE',
      'BEDMINSTER',
      'BERKELEY HEIGHTS',
      'BERNARDSVILLE',
      'BROOKSIDE',
      'CEDAR KNOLLS',
      'CHATHAM',
      'CHESTER',
      'FAR HILLS',
      'FLORHAM PARK',
      'GILLETTE',
      'GLADSTONE',
      'GREEN VILLAGE',
      'EAST HANOVER',
      'LIBERTY CORNER',
      'LYONS',
      'MADISON',
      'MENDHAM',
      'MILLINGTON',
      'MORRIS PLAINS',
      'MORRISTOWN',
      'MOUNT FREEDOM',
      'NEW PROVIDENCE',
      'NEW VERNON',
      'PEAPACK',
      'PLUCKEMIN',
      'POTTERSVILLE',
      'STIRLING',
      'WHIPPANY',
      'ALLOWAY',
      'CHERRY HILL',
      'ATCO',
      'BARNEGAT',
      'BARNEGAT LIGHT',
      'BARRINGTON',
      'BEACH HAVEN',
      'BERLIN',
      'BEVERLY',
      'BIRMINGHAM',
      'BLACKWOOD',
      'BRIDGEPORT',
      'BROWNS MILLS',
      'BURLINGTON',
      'CEDAR BROOK',
      'CHATSWORTH',
      'CLARKSBORO',
      'CLEMENTON',
      'COLUMBUS',
      'DEEPWATER',
      'EWAN',
      'GIBBSBORO',
      'GIBBSTOWN',
      'GLASSBORO',
      'GLENDORA',
      'GLOUCESTER CITY',
      'BELLMAWR',
      'GRENLOCH',
      'HADDONFIELD',
      'HADDON HEIGHTS',
      'HAINESPORT',
      'HAMMONTON',
      'HANCOCKS BRIDGE',
      'HARRISONVILLE',
      'JOBSTOWN',
      'JULIUSTOWN',
      'VOORHEES',
      'LAWNSIDE',
      'WILLINGBORO',
      'LUMBERTON',
      'MAGNOLIA',
      'MANAHAWKIN',
      'MANTUA',
      'MAPLE SHADE',
      'MARLTON',
      'MOUNT LAUREL',
      'MEDFORD',
      'MICKLETON',
      'MOORESTOWN',
      'MOUNT EPHRAIM',
      'MOUNT HOLLY',
      'MOUNT ROYAL',
      'MULLICA HILL',
      'NATIONAL PARK',
      'NEW LISBON',
      'PALMYRA',
      'PAULSBORO',
      'PEDRICKTOWN',
      'PEMBERTON',
      'PENNS GROVE',
      'PENNSVILLE',
      'PITMAN',
      'QUINTON',
      'RANCOCAS',
      'RICHWOOD',
      'RIVERSIDE',
      'RIVERTON',
      'RUNNEMEDE',
      'SALEM',
      'SEWELL',
      'SICKLERVILLE',
      'SOMERDALE',
      'STRATFORD',
      'SWEDESBORO',
      'THOROFARE',
      'TUCKERTON',
      'VINCENTOWN',
      'WATERFORD WORKS',
      'WENONAH',
      'WEST BERLIN',
      'WEST CREEK',
      'WESTVILLE',
      'WILLIAMSTOWN',
      'WINSLOW',
      'WOODBURY',
      'WOODBURY HEIGHTS',
      'WOODSTOWN',
      'CAMDEN',
      'AUDUBON',
      'OALASKALYN',
      'COLLINGSWOOD',
      'MERCHANTVILLE',
      'PENNSAUKEN',
      'ABSECON',
      'AVALON',
      'BRIGANTINE',
      'CAPE MAY',
      'CAPE MAY COURT HOUSE',
      'CAPE MAY POINT',
      'COLOGNE',
      'DENNISVILLE',
      'EGG HARBOR CITY',
      'ELWOOD',
      'GOSHEN',
      'GREEN CREEK',
      'LEEDS POINT',
      'LINWOOD',
      'MARMORA',
      'NEW GRETNA',
      'NORTHFIELD',
      'OCEAN CITY',
      'OCEAN VIEW',
      'OCEANVILLE',
      'PLEASANTVILLE',
      'EGG HARBOR TOWNSHIP',
      'POMONA',
      'PORT REPUBLIC',
      'RIO GRANDE',
      'SEA ISLE CITY',
      'SOMERS POINT',
      'SOUTH DENNIS',
      'SOUTH SEAVILLE',
      'STONE HARBOR',
      'STRATHMERE',
      'TUCKAHOE',
      'VILLAS',
      'WHITESBORO',
      'WILDWOOD',
      'WOODBINE',
      'BRIDGETON',
      'BUENA',
      'CEDARVILLE',
      'CLAYTON',
      'DEERFIELD STREET',
      'DELMONT',
      'DIVIDING CREEK',
      'DORCHESTER',
      'DOROTHY',
      'ELMER',
      'ESTELL MANOR',
      'FAIRTON',
      'FORTESCUE',
      'FRANKLINVILLE',
      'GREENWICH',
      'HEISLERVILLE',
      'LANDISVILLE',
      'LEESBURG',
      'MALAGA',
      'MAURICETOWN',
      'MAYS LANDING',
      'MILLVILLE',
      'MILMAY',
      'MINOTOLA',
      'MIZPAH',
      'MONROEVILLE',
      'NEWFIELD',
      'NEWPORT',
      'NEWTONVILLE',
      'NORMA',
      'PORT ELIZABETH',
      'PORT NORRIS',
      'RICHLAND',
      'ROSENHAYN',
      'SHILOH',
      'VINELAND',
      'ATLANTIC CITY',
      'MARGATE CITY',
      'LONGPORT',
      'VENTNOR CITY',
      'ALLENTOWN',
      'BELLE MEAD',
      'BLAWENBURG',
      'BORDENTOWN',
      'CLARKSBURG',
      'COOKSTOWN',
      'CRANBURY',
      'CREAM RIDGE',
      'CROSSWICKS',
      'FLORENCE',
      'HIGHTSTOWN',
      'HOPEWELL',
      'IMLAYSTOWN',
      'JACKSON',
      'KINGSTON',
      'LAMBERTVILLE',
      'NEW EGYPT',
      'PENNINGTON',
      'PERRINEVILLE',
      'PLAINSBORO',
      'PRINCETON',
      'PRINCETON JUNCTION',
      'RINGOES',
      'ROCKY HILL',
      'ROEBLING',
      'ROOSEVELT',
      'ROSEMONT',
      'SERGEANTSVILLE',
      'SKILLMAN',
      'STOCKTON',
      'TITUSVILLE',
      'WINDSOR',
      'WRIGHTSTOWN',
      'TRENTON',
      'LALASKAEWOOD',
      'ALLENWOOD',
      'BAYVILLE',
      'BEACHWOOD',
      'BRICK',
      'BRIELLE',
      'FORKED RIVER',
      'ISLAND HEIGHTS',
      'LALASKAEHURST',
      'LANOKA HARBOR',
      'LAVALLETTE',
      'MANASQUAN',
      'MANTOLOKING',
      'NORMANDY BEACH',
      'OCEAN GATE',
      'PINE BEACH',
      'POINT PLEASANT BEACH',
      'SEA GIRT',
      'SEASIDE HEIGHTS',
      'SEASIDE PARK',
      'TOMS RIVER',
      'WARETOWN',
      'MANCHESTER TOWNSHIP',
      'ANNANDALE',
      'ASBURY',
      'BAPTISTOWN',
      'BLOOMSBURY',
      'BOUND BROOK',
      'BRIDGEWATER',
      'BROADWAY',
      'CLINTON',
      'DAYTON',
      'DUNELLEN',
      'EAST BRUNSWICK',
      'EDISON',
      'FLAGTOWN',
      'FLEMINGTON',
      'FRANKLIN PARK',
      'KENDALL PARK',
      'FRENCHTOWN',
      'GLEN GARDNER',
      'HAMPTON',
      'HELMETTA',
      'HIGH BRIDGE',
      'ISELIN',
      'MONROE TOWNSHIP',
      'KEASBEY',
      'LEBANON',
      'LITTLE YORK',
      'MANVILLE',
      'MARTINSVILLE',
      'METUCHEN',
      'HILLSBOROUGH',
      'MIDDLESEX',
      'MILFORD',
      'MILLTOWN',
      'MONMOUTH JUNCTION',
      'NESHANIC STATION',
      'PISCATAWAY',
      'OLD BRIDGE',
      'OLDWICK',
      'PARLIN',
      'PERTH AMBOY',
      'FORDS',
      'PHILLIPSBURG',
      'PITTSTOWN',
      'QUALASKAERTOWN',
      'RARITAN',
      'READINGTON',
      'SAYREVILLE',
      'SOMERSET',
      'SOMERVILLE',
      'SOUTH RIVER',
      'SOUTH AMBOY',
      'SOUTH BOUND BROOK',
      'SPOTSWOOD',
      'STANTON',
      'STEWARTSVILLE',
      'THREE BRIDGES',
      'WHITEHOUSE',
      'WHITEHOUSE STATION',
      'ZAREPHATH',
      'NEW BRUNSWICK',
      'NORTH BRUNSWICK',
      'HIGHLAND PARK'
    ]
  },
  NM: {
    code: 'NM',
    name: 'NEW MEXICO',
    cities: [
      'ALGODONES',
      'BELEN',
      'BERNALILLO',
      'BLUEWATER',
      'BOSQUE',
      'CASA BLANCA',
      'CEDAR CREST',
      'CEDARVALE',
      'CERRILLOS',
      'CLAUNCH',
      'COYOTE',
      'CUBA',
      'CUBERO',
      'EDGEWOOD',
      'ESTANCIA',
      'GALLINA',
      'COUNSELOR',
      'GRANTS',
      'MILAN',
      'ISLETA',
      'JARALES',
      'JEMEZ PUEBLO',
      'JEMEZ SPRINGS',
      'LAGUNA',
      'LA JARA',
      'LA JOYA',
      'LINDRITH',
      'LOS LUNAS',
      'MC INTOSH',
      'PUEBLO OF ACOMA',
      'MORIARTY',
      'MOUNTAINAIR',
      'NAGEEZI',
      'NEW LAGUNA',
      'PAGUATE',
      'PENA BLANCA',
      'PERALTA',
      'PLACITAS',
      'PONDEROSA',
      'PREWITT',
      'REGINA',
      'SANDIA PARK',
      'CORRALES',
      'SAN FIDEL',
      'SAN RAFAEL',
      'SANTO DOMINGO PUEBLO',
      'SAN YSIDRO',
      'STANLEY',
      'TAJIQUE',
      'TIJERAS',
      'TOME',
      'TORREON',
      'VEGUITA',
      'WILLARD',
      'YOUNGSVILLE',
      'BOSQUE FARMS',
      'CLINES CORNERS',
      'COCHITI PUEBLO',
      'COCHITI LALASKAE',
      'ALBUQUERQUE',
      'KIRTLAND AFB',
      'RIO RANCHO',
      'GALLUP',
      'BRIMHALL',
      'CHURCH ROCK',
      'CONTINENTAL DIVIDE',
      'CROWNPOINT',
      'FENCE LALASKAE',
      'FORT WINGATE',
      'GAMERCO',
      'MENTMORE',
      'MEXICAN SPRINGS',
      'RAMAH',
      'REHOBOTH',
      'THOREAU',
      'TOHATCHI',
      'VANDERWAGEN',
      'ZUNI',
      'NAVAJO',
      'JAMESTOWN',
      'PINEHILL',
      'SHEEP SPRINGS',
      'SMITH LALASKAE',
      'YATAHEY',
      'FARMINGTON',
      'AZTEC',
      'BLANCO',
      'BLOOMFIELD',
      'FLORA VISTA',
      'FRUITLAND',
      'KIRTLAND',
      'LA PLATA',
      'NAVAJO DAM',
      'SHIPROCK',
      'WATERFLOW',
      'NEWCOMB',
      'SANOSTEE',
      'SANTA FE',
      'ABIQUIU',
      'ALCALDE',
      'AMALIA',
      'ARROYO HONDO',
      'ARROYO SECO',
      'CANJILON',
      'CANONES',
      'CARSON',
      'CEBOLLA',
      'CERRO',
      'CHAMA',
      'CHAMISAL',
      'CHIMAYO',
      'CORDOVA',
      'COSTILLA',
      'TAOS SKI VALLEY',
      'DIXON',
      'DULCE',
      'EL PRADO',
      'EL RITO',
      'EMBUDO',
      'ESPANOLA',
      'GLORIETA',
      'HERNANDEZ',
      'ILFELD',
      'LA MADERA',
      'LAMY',
      'LLANO',
      'LOS ALAMOS',
      'MEDANALES',
      'OJO CALIENTE',
      'LOS OJOS',
      'PECOS',
      'PENASCO',
      'PETACA',
      'QUESTA',
      'RANCHOS DE TAOS',
      'RED RIVER',
      'RIBERA',
      'ROWE',
      'SAN CRISTOBAL',
      'SAN JOSE',
      'SAN JUAN PUEBLO',
      'SANTA CRUZ',
      'SERAFINA',
      'TAOS',
      'TERERRO',
      'TESUQUE',
      'TIERRA AMARILLA',
      'TRAMPAS',
      'TRES PIEDRAS',
      'TRUCHAS',
      'VADITO',
      'VALDEZ',
      'VALLECITOS',
      'VELARDE',
      'VILLANUEVA',
      'LAS VEGAS',
      'ANGEL FIRE',
      'ANTON CHICO',
      'BUENA VISTA',
      'CHACON',
      'CIMARRON',
      'CLEVELAND',
      'EAGLE NEST',
      'GUADALUPITA',
      'HOLMAN',
      'LA LOMA',
      'MAXWELL',
      'MIAMI',
      'MILLS',
      'MONTEZUMA',
      'MORA',
      'MOSQUERO',
      'OCATE',
      'OJO FELIZ',
      'RAINSVILLE',
      'RATON',
      'ROCIADA',
      'ROY',
      'SAPELLO',
      'SOLANO',
      'SPRINGER',
      'UTE PARK',
      'VALMORA',
      'WAGON MOUND',
      'WATROUS',
      'SOCORRO',
      'ARAGON',
      'DATIL',
      'LEMITAR',
      'LUNA',
      'MAGDALENA',
      'PIE TOWN',
      'POLVADERA',
      'QUEMADO',
      'RESERVE',
      'SAN ACACIA',
      'SAN ANTONIO',
      'TRUTH OR CONSEQUENCES',
      'ARREY',
      'CABALLO',
      'DERRY',
      'ELEPHANT BUTTE',
      'GARFIELD',
      'HATCH',
      'MONTICELLO',
      'RINCON',
      'SALEM',
      'WILLIAMSBURG',
      'WINSTON',
      'LAS CRUCES',
      'WHITE SANDS MISSILE RANGE',
      'SANTA TERESA',
      'PLAYAS',
      'ANIMAS',
      'ANTHONY',
      'ARENAS VALLEY',
      'BAYARD',
      'BERINO',
      'BUCKHORN',
      'SANTA CLARA',
      'CHAMBERINO',
      'CLIFF',
      'COLUMBUS',
      'DEMING',
      'DONA ANA',
      'FAIRACRES',
      'FAYWOOD',
      'FORT BAYARD',
      'GILA',
      'GLENWOOD',
      'HACHITA',
      'HANOVER',
      'HILLSBORO',
      'HURLEY',
      'LA MESA',
      'LORDSBURG',
      'MESILLA',
      'MESILLA PARK',
      'MESQUITE',
      'MIMBRES',
      'MULE CREEK',
      'ORGAN',
      'PINOS ALTOS',
      'RADIUM SPRINGS',
      'REDROCK',
      'RODEO',
      'SAN MIGUEL',
      'SILVER CITY',
      'SUNLAND PARK',
      'TYRONE',
      'VADO',
      'CLOVIS',
      'CANNON AFB',
      'BROADVIEW',
      'CAUSEY',
      'CROSSROADS',
      'DORA',
      'ELIDA',
      'FLOYD',
      'FORT SUMNER',
      'GRADY',
      'HOUSE',
      'KENNA',
      'LINGO',
      'MELROSE',
      'MILNESAND',
      'PEP',
      'PORTALES',
      'ROGERS',
      'SAINT VRAIN',
      'TAIBAN',
      'TEXICO',
      'YESO',
      'ROSWELL',
      'ARTESIA',
      'CAPROCK',
      'CARLSBAD',
      'DEXTER',
      'EUNICE',
      'HAGERMAN',
      'HOBBS',
      'HOPE',
      'JAL',
      'LALASKAE ARTHUR',
      'LALASKAEWOOD',
      'LOCO HILLS',
      'LOVING',
      'LOVINGTON',
      'MC DONALD',
      'MALAGA',
      'MALJAMAR',
      'MONUMENT',
      'TATUM',
      'WHITES CITY',
      'CARRIZOZO',
      'ALAMOGORDO',
      'ALTO',
      'BENT',
      'CAPITAN',
      'CLOUDCROFT',
      'CORONA',
      'ENCINO',
      'FORT STANTON',
      'GLENCOE',
      'HIGH ROLLS MOUNTAIN PARK',
      'HOLLOMAN AIR FORCE BASE',
      'HONDO',
      'LA LUZ',
      'LINCOLN',
      'MAYHILL',
      'MESCALERO',
      'NOGAL',
      'OROGRANDE',
      'PICACHO',
      'PINON',
      'RUIDOSO',
      'RUIDOSO DOWNS',
      'SACRAMENTO',
      'SAN PATRICIO',
      'SUNSPOT',
      'TIMBERON',
      'TINNIE',
      'TULAROSA',
      'VAUGHN',
      'WEED',
      'TUCUMCARI',
      'AMISTAD',
      'BARD',
      'CAPULIN',
      'CLAYTON',
      'CONCHAS DAM',
      'CUERVO',
      'DES MOINES',
      'FOLSOM',
      'GARITA',
      'GLADSTONE',
      'GRENVILLE',
      'LOGAN',
      'MC ALISTER',
      'MOUNT DORA',
      'NARA VISA',
      'NEWKIRK',
      'QUAY',
      'SAN JON',
      'SANTA ROSA',
      'SEDAN',
      'SENECA',
      'TREMENTINA',
      'BELL RANCH'
    ]
  },
  NV: {
    code: 'NV',
    name: 'NEVADA',
    cities: [
      'THE LALASKAES',
      'ALAMO',
      'BEATTY',
      'BLUE DIAMOND',
      'BOULDER CITY',
      'BUNKERVILLE',
      'CALIENTE',
      'HENDERSON',
      'DYER',
      'GOLDFIELD',
      'HIKO',
      'INDIAN SPRINGS',
      'JEAN',
      'AMARGOSA VALLEY',
      'LOGANDALE',
      'MANHATTAN',
      'MERCURY',
      'MESQUITE',
      'MOAPA',
      'LAUGHLIN',
      'NORTH LAS VEGAS',
      'CAL NEV ARI',
      'OVERTON',
      'PAHRUMP',
      'PANACA',
      'PIOCHE',
      'ROUND MOUNTAIN',
      'SEARCHLIGHT',
      'SILVERPEALASKA',
      'TONOPAH',
      'LAS VEGAS',
      'NELLIS AFB',
      'ELY',
      'AUSTIN',
      'BALASKAER',
      'DUCKWATER',
      'EUREKA',
      'LUND',
      'MC GILL',
      'RUTH',
      'CRYSTAL BAY',
      'DAYTON',
      'DENIO',
      'EMPIRE',
      'FALLON',
      'FERNLEY',
      'GABBS',
      'GARDNERVILLE',
      'GENOA',
      'GERLACH',
      'GLENBROOK',
      'GOLCONDA',
      'HAWTHORNE',
      'IMLAY',
      'LOVELOCK',
      'LUNING',
      'MC DERMITT',
      'MINA',
      'MINDEN',
      'NIXON',
      'OROVADA',
      'PARADISE VALLEY',
      'SCHURZ',
      'SILVER CITY',
      'SILVER SPRINGS',
      'SMITH',
      'SPARKS',
      'SUN VALLEY',
      'VALMY',
      'VERDI',
      'VIRGINIA CITY',
      'WADSWORTH',
      'WELLINGTON',
      'WINNEMUCCA',
      'YERINGTON',
      'ZEPHYR COVE',
      'STATELINE',
      'INCLINE VILLAGE',
      'RENO',
      'CARSON CITY',
      'WASHOE VALLEY',
      'ELKO',
      'SPRING CREEK',
      'BATTLE MOUNTAIN',
      'CRESCENT VALLEY',
      'CARLIN',
      'DEETH',
      'HALLECK',
      'JACKPOT',
      'JARBIDGE',
      'LAMOILLE',
      'MONTELLO',
      'MOUNTAIN CITY',
      'OWYHEE',
      'RUBY VALLEY',
      'TUSCARORA',
      'WELLS',
      'WEST WENDOVER'
    ]
  },
  NY: {
    code: 'NY',
    name: 'NEW YORK',
    cities: [
      'HOLTSVILLE',
      'FISHERS ISLAND',
      'NEW YORK',
      'STATEN ISLAND',
      'BRONX',
      'AMAWALK',
      'ARDSLEY',
      'ARDSLEY ON HUDSON',
      'ARMONK',
      'BALDWIN PLACE',
      'BEDFORD',
      'BEDFORD HILLS',
      'BREWSTER',
      'BRIARCLIFF MANOR',
      'BUCHANAN',
      'CARMEL',
      'CHAPPAQUA',
      'COLD SPRING',
      'CROMPOND',
      'CROSS RIVER',
      'CROTON FALLS',
      'CROTON ON HUDSON',
      'DOBBS FERRY',
      'ELMSFORD',
      'GARRISON',
      'GOLDENS BRIDGE',
      'GRANITE SPRINGS',
      'HARRISON',
      'HARTSDALE',
      'HAWTHORNE',
      'IRVINGTON',
      'JEFFERSON VALLEY',
      'KATONAH',
      'LALASKAE PEEKSKILL',
      'LARCHMONT',
      'LINCOLNDALE',
      'MAHOPAC',
      'MAHOPAC FALLS',
      'MAMARONECK',
      'MARYKNOLL',
      'MILLWOOD',
      'MOHEGAN LALASKAE',
      'MONTROSE',
      'MOUNT KISCO',
      'MOUNT VERNON',
      'NORTH SALEM',
      'OSSINING',
      'PEEKSKILL',
      'CORTLANDT MANOR',
      'PLEASANTVILLE',
      'PORT CHESTER',
      'POUND RIDGE',
      'PURCHASE',
      'PURDYS',
      'PUTNAM VALLEY',
      'RYE',
      'SCARSDALE',
      'SHENOROCK',
      'SHRUB OALASKA',
      'SOMERS',
      'SOUTH SALEM',
      'TARRYTOWN',
      'THORNWOOD',
      'VALHALLA',
      'VERPLANCK',
      'WACCABUC',
      'YORKTOWN HEIGHTS',
      'WHITE PLAINS',
      'WEST HARRISON',
      'YONKERS',
      'HASTINGS ON HUDSON',
      'TUCKAHOE',
      'BRONXVILLE',
      'EASTCHESTER',
      'NEW ROCHELLE',
      'PELHAM',
      'SUFFERN',
      'ARDEN',
      'BEAR MOUNTAIN',
      'BELLVALE',
      'BLAUVELT',
      'BLOOMING GROVE',
      'BULLVILLE',
      'CAMPBELL HALL',
      'CENTRAL VALLEY',
      'CHESTER',
      'CIRCLEVILLE',
      'CONGERS',
      'FLORIDA',
      'FORT MONTGOMERY',
      'GARNERVILLE',
      'GOSHEN',
      'GREENWOOD LALASKAE',
      'HARRIMAN',
      'HAVERSTRAW',
      'HIGHLAND FALLS',
      'HIGHLAND MILLS',
      'HILLBURN',
      'HOWELLS',
      'JOHNSON',
      'MIDDLETOWN',
      'MONROE',
      'MONSEY',
      'MOUNTAINVILLE',
      'NANUET',
      'NEW CITY',
      'NEW HAMPTON',
      'NEW MILFORD',
      'NYACK',
      'ORANGEBURG',
      'OTISVILLE',
      'PALISADES',
      'PEARL RIVER',
      'PIERMONT',
      'PINE ISLAND',
      'POMONA',
      'SLATE HILL',
      'SLOATSBURG',
      'SOUTHFIELDS',
      'SPARKILL',
      'SPRING VALLEY',
      'STERLING FOREST',
      'STONY POINT',
      'SUGAR LOAF',
      'TALLMAN',
      'TAPPAN',
      'THIELLS',
      'THOMPSON RIDGE',
      'TOMKINS COVE',
      'TUXEDO PARK',
      'UNIONVILLE',
      'VALLEY COTTAGE',
      'WARWICK',
      'WASHINGTONVILLE',
      'WEST HAVERSTRAW',
      'WEST NYACK',
      'WEST POINT',
      'WESTTOWN',
      'FLORAL PARK',
      'ELMONT',
      'GLEN OALASKAS',
      'FRANKLIN SQUARE',
      'GREAT NECK',
      'MANHASSET',
      'NEW HYDE PARK',
      'PORT WASHINGTON',
      'INWOOD',
      'LONG ISLAND CITY',
      'ASTORIA',
      'SUNNYSIDE',
      'BROOKLYN',
      'FLUSHING',
      'COLLEGE POINT',
      'WHITESTONE',
      'BAYSIDE',
      'LITTLE NECK',
      'OALASKALAND GARDENS',
      'FRESH MEADOWS',
      'CORONA',
      'EAST ELMHURST',
      'JACKSON HEIGHTS',
      'ELMHURST',
      'REGO PARK',
      'FOREST HILLS',
      'WOODSIDE',
      'MASPETH',
      'MIDDLE VILLAGE',
      'RIDGEWOOD',
      'JAMAICA',
      'CAMBRIA HEIGHTS',
      'SAINT ALBANS',
      'SPRINGFIELD GARDENS',
      'HOWARD BEACH',
      'KEW GARDENS',
      'OZONE PARK',
      'RICHMOND HILL',
      'SOUTH RICHMOND HILL',
      'SOUTH OZONE PARK',
      'WOODHAVEN',
      'ROSEDALE',
      'HOLLIS',
      'BELLEROSE',
      'QUEENS VILLAGE',
      'MINEOLA',
      'ALBERTSON',
      'ATLANTIC BEACH',
      'BALDWIN',
      'CARLE PLACE',
      'CEDARHURST',
      'EAST ROCKAWAY',
      'FREEPORT',
      'GARDEN CITY',
      'GLEN COVE',
      'GLEN HEAD',
      'GLENWOOD LANDING',
      'GREENVALE',
      'HEMPSTEAD',
      'WEST HEMPSTEAD',
      'UNIONDALE',
      'EAST MEADOW',
      'HEWLETT',
      'ISLAND PARK',
      'LAWRENCE',
      'LOCUST VALLEY',
      'LONG BEACH',
      'LYNBROOK',
      'MALVERNE',
      'MERRICK',
      'OLD WESTBURY',
      'POINT LOOKOUT',
      'ROCKVILLE CENTRE',
      'OCEANSIDE',
      'ROOSEVELT',
      'ROSLYN',
      'ROSLYN HEIGHTS',
      'SEA CLIFF',
      'VALLEY STREAM',
      'WESTBURY',
      'WILLISTON PARK',
      'WOODMERE',
      'FAR ROCKAWAY',
      'ARVERNE',
      'ROCKAWAY PARK',
      'BREEZY POINT',
      'AMITYVILLE',
      'BABYLON',
      'NORTH BABYLON',
      'WEST BABYLON',
      'BAYPORT',
      'BAY SHORE',
      'BAYVILLE',
      'BELLMORE',
      'BELLPORT',
      'BETHPAGE',
      'BLUE POINT',
      'BOHEMIA',
      'BRENTWOOD',
      'BRIGHTWATERS',
      'BROOKHAVEN',
      'CENTEREACH',
      'CENTERPORT',
      'CENTRAL ISLIP',
      'COLD SPRING HARBOR',
      'COMMACK',
      'COPIAGUE',
      'CORAM',
      'DEER PARK',
      'EAST ISLIP',
      'EAST NORTHPORT',
      'EAST NORWICH',
      'EAST SETAUKET',
      'FARMINGDALE',
      'FARMINGVILLE',
      'GREAT RIVER',
      'GREENLAWN',
      'HOLBROOK',
      'HUNTINGTON',
      'SMITHTOWN',
      'HUNTINGTON STATION',
      'MELVILLE',
      'ISLANDIA',
      'ISLIP',
      'ISLIP TERRACE',
      'JERICHO',
      'KINGS PARK',
      'LALASKAE GROVE',
      'LEVITTOWN',
      'LINDENHURST',
      'MASSAPEQUA',
      'MASSAPEQUA PARK',
      'MEDFORD',
      'MILLER PLACE',
      'MILL NECK',
      'MOUNT SINAI',
      'NESCONSET',
      'NORTHPORT',
      'OALASKADALE',
      'OCEAN BEACH',
      'OYSTER BAY',
      'PATCHOGUE',
      'SYOSSET',
      'PORT JEFFERSON STATION',
      'PORT JEFFERSON',
      'ROCKY POINT',
      'RONKONKOMA',
      'SAINT JAMES',
      'SAYVILLE',
      'SEAFORD',
      'SELDEN',
      'SHOREHAM',
      'HAUPPAUGE',
      'SOUND BEACH',
      'STONY BROOK',
      'WADING RIVER',
      'WANTAGH',
      'WEST ISLIP',
      'WEST SAYVILLE',
      'WOODBURY',
      'WYANDANCH',
      'HICKSVILLE',
      'PLAINVIEW',
      'OLD BETHPAGE',
      'MID ISLAND',
      'RIVERHEAD',
      'AMAGANSETT',
      'AQUEBOGUE',
      'BRIDGEHAMPTON',
      'CALVERTON',
      'CENTER MORICHES',
      'CUTCHOGUE',
      'EAST HAMPTON',
      'EAST MARION',
      'EAST MORICHES',
      'EASTPORT',
      'EAST QUOGUE',
      'GREENPORT',
      'HAMPTON BAYS',
      'JAMESPORT',
      'LAUREL',
      'MANORVILLE',
      'MASTIC',
      'MASTIC BEACH',
      'MATTITUCK',
      'MIDDLE ISLAND',
      'MONTAUK',
      'MORICHES',
      'NEW SUFFOLK',
      'ORIENT',
      'PECONIC',
      'QUOGUE',
      'REMSENBURG',
      'RIDGE',
      'SAGAPONACK',
      'SAG HARBOR',
      'SHELTER ISLAND',
      'SHELTER ISLAND HEIGHTS',
      'SHIRLEY',
      'SOUTHAMPTON',
      'SOUTH JAMESPORT',
      'SOUTHOLD',
      'SPEONK',
      'UPTON',
      'WAINSCOTT',
      'WATER MILL',
      'WESTHAMPTON',
      'WESTHAMPTON BEACH',
      'YAPHANK',
      'ALCOVE',
      'ALPLAUS',
      'ALTAMONT',
      'AMSTERDAM',
      'ATHENS',
      'AURIESVILLE',
      'AUSTERLITZ',
      'AVERILL PARK',
      'BALLSTON LALASKAE',
      'BALLSTON SPA',
      'BERLIN',
      'BERNE',
      'BRAINARD',
      'BROADALBIN',
      'BURNT HILLS',
      'BUSKIRK',
      'CANAAN',
      'CARLISLE',
      'CAROGA LALASKAE',
      'CASTLETON ON HUDSON',
      'CENTRAL BRIDGE',
      'CHARLOTTEVILLE',
      'CHATHAM',
      'CHERRY PLAIN',
      'CLARKSVILLE',
      'CLIMAX',
      'COBLESKILL',
      'COEYMANS',
      'COEYMANS HOLLOW',
      'COHOES',
      'COLUMBIAVILLE',
      'COXSACKIE',
      'CROPSEYVILLE',
      'DELANSON',
      'DELMAR',
      'DORMANSVILLE',
      'DUANESBURG',
      'EAGLE BRIDGE',
      'EARLTON',
      'EAST BERNE',
      'EAST CHATHAM',
      'EAST GREENBUSH',
      'EAST NASSAU',
      'EAST SCHODACK',
      'EAST WORCESTER',
      'CLIFTON PARK',
      'ESPERANCE',
      'FEURA BUSH',
      'FONDA',
      'FORT HUNTER',
      'FORT JOHNSON',
      'FULTONHAM',
      'FULTONVILLE',
      'GALLUPVILLE',
      'GALWAY',
      'GHENT',
      'GILBOA',
      'GLENMONT',
      'GLOVERSVILLE',
      'GRAFTON',
      'GREENVILLE',
      'GUILDERLAND',
      'GUILDERLAND CENTER',
      'HAGAMAN',
      'HANNACROIX',
      'HOOSICK',
      'HOOSICK FALLS',
      'HOWES CAVE',
      'JEFFERSON',
      'JOHNSONVILLE',
      'JOHNSTOWN',
      'KINDERHOOK',
      'KNOX',
      'LALASKAE PLEASANT',
      'LATHAM',
      'MALDEN BRIDGE',
      'MARYLAND',
      'MAYFIELD',
      'MECHANICVILLE',
      'MEDUSA',
      'MELROSE',
      'MIDDLEBURGH',
      'NASSAU',
      'NEW BALTIMORE',
      'NEW LEBANON',
      'NEWTONVILLE',
      'NIVERVILLE',
      'NORTH BLENHEIM',
      'NORTH CHATHAM',
      'NORTH HOOSICK',
      'NORTHVILLE',
      'OLD CHATHAM',
      'PATTERSONVILLE',
      'PETERSBURG',
      'PISECO',
      'POESTENKILL',
      'QUALASKAER STREET',
      'RAVENA',
      'RENSSELAER',
      'RENSSELAERVILLE',
      'REXFORD',
      'RICHMONDVILLE',
      'ROTTERDAM JUNCTION',
      'ROUND LALASKAE',
      'SAND LALASKAE',
      'SCHAGHTICOKE',
      'SCHENEVUS',
      'SCHODACK LANDING',
      'SCHOHARIE',
      'SELKIRK',
      'SLINGERLANDS',
      'SLOANSVILLE',
      'SOUTH BETHLEHEM',
      'SPECULATOR',
      'SPENCERTOWN',
      'SPRALASKAERS',
      'STAMFORD',
      'STEPHENTOWN',
      'STILLWATER',
      'STOTTVILLE',
      'STUYVESANT',
      'STUYVESANT FALLS',
      'SUMMIT',
      'SURPRISE',
      'TRIBES HILL',
      'TROY',
      'VALATIE',
      'VALLEY FALLS',
      'VOORHEESVILLE',
      'WARNERVILLE',
      'WATERFORD',
      'WATERVLIET',
      'WELLS',
      'WEST COXSACKIE',
      'WESTERLO',
      'WEST FULTON',
      'WEST LEBANON',
      'WEST SAND LALASKAE',
      'WORCESTER',
      'WYNANTSKILL',
      'ALBANY',
      'SCHENECTADY',
      'KINGSTON',
      'ACCORD',
      'ACRA',
      'ARKVILLE',
      'ASHLAND',
      'BEARSVILLE',
      'BIG INDIAN',
      'BLOOMINGTON',
      'BOICEVILLE',
      'CAIRO',
      'CATSKILL',
      'CHICHESTER',
      'CONNELLY',
      'CORNWALLVILLE',
      'COTTEKILL',
      'CRAGSMOOR',
      'DENVER',
      'DURHAM',
      'EAST DURHAM',
      'EAST JEWETT',
      'ELKA PARK',
      'ELLENVILLE',
      'ESOPUS',
      'FLEISCHMANNS',
      'FREEHOLD',
      'GLASCO',
      'GLENFORD',
      'GRAND GORGE',
      'GREENFIELD PARK',
      'HAINES FALLS',
      'HALCOTTSVILLE',
      'HENSONVILLE',
      'HIGH FALLS',
      'HIGHMOUNT',
      'HUNTER',
      'HURLEY',
      'JEWETT',
      'KERHONKSON',
      'LALASKAE HILL',
      'LALASKAE KATRINE',
      'LANESVILLE',
      'LEEDS',
      'LEXINGTON',
      'MALDEN ON HUDSON',
      'MAPLECREST',
      'MARGARETVILLE',
      'MOUNT MARION',
      'MOUNT TREMPER',
      'NAPANOCH',
      'NEW KINGSTON',
      'OALASKA HILL',
      'OLIVEBRIDGE',
      'PALENVILLE',
      'PHOENICIA',
      'PINE HILL',
      'PORT EWEN',
      'PRATTSVILLE',
      'PRESTON HOLLOW',
      'PURLING',
      'RIFTON',
      'ROSENDALE',
      'ROUND TOP',
      'ROXBURY',
      'RUBY',
      'SAUGERTIES',
      'SHANDALASKAEN',
      'SHOKAN',
      'SOUTH CAIRO',
      'SPRING GLEN',
      'STONE RIDGE',
      'TANNERSVILLE',
      'TILLSON',
      'ULSTER PARK',
      'WAWARSING',
      'WEST CAMP',
      'WEST HURLEY',
      'WEST KILL',
      'WEST PARK',
      'WEST SHOKAN',
      'WILLOW',
      'WINDHAM',
      'WOODSTOCK',
      'AMENIA',
      'ANCRAM',
      'ANCRAMDALE',
      'ANNANDALE ON HUDSON',
      'BANGALL',
      'BARRYTOWN',
      'BEACON',
      'BILLINGS',
      'CASTLE POINT',
      'CHELSEA',
      'CLAVERACK',
      'CLINTON CORNERS',
      'CLINTONDALE',
      'COPALASKAE',
      'COPALASKAE FALLS',
      'CORNWALL',
      'CORNWALL ON HUDSON',
      'CRARYVILLE',
      'DOVER PLAINS',
      'ELIZAVILLE',
      'FISHKILL',
      'GARDINER',
      'GERMANTOWN',
      'GLENHAM',
      'HIGHLAND',
      'HILLSDALE',
      'HOLLOWVILLE',
      'HOLMES',
      'HOPEWELL JUNCTION',
      'HUDSON',
      'HUGHSONVILLE',
      'HYDE PARK',
      'LAGRANGEVILLE',
      'LIVINGSTON',
      'MARLBORO',
      'MAYBROOK',
      'MELLENVILLE',
      'MILLBROOK',
      'MILLERTON',
      'MILTON',
      'MODENA',
      'MONTGOMERY',
      'NEWBURGH',
      'NEW WINDSOR',
      'MID HUDSON',
      'NEW PALTZ',
      'PATTERSON',
      'PAWLING',
      'PHILMONT',
      'PINE BUSH',
      'PINE PLAINS',
      'PLATTEKILL',
      'PLEASANT VALLEY',
      'POUGHQUAG',
      'RED HOOK',
      'RHINEBECK',
      'RHINECLIFF',
      'ROCK TAVERN',
      'SALISBURY MILLS',
      'SALT POINT',
      'STAATSBURG',
      'STANFORDVILLE',
      'STORMVILLE',
      'TIVOLI',
      'VAILS GATE',
      'VERBANK',
      'WALDEN',
      'WALKER VALLEY',
      'WALLKILL',
      'WAPPINGERS FALLS',
      'WASSAIC',
      'WEST COPALASKAE',
      'WINGDALE',
      'POUGHKEEPSIE',
      'MONTICELLO',
      'BARRYVILLE',
      'BETHEL',
      'BLOOMINGBURG',
      'BURLINGHAM',
      'CALLICOON',
      'CALLICOON CENTER',
      'CLARYVILLE',
      'COCHECTON',
      'COCHECTON CENTER',
      'CUDDEBACKVILLE',
      'ELDRED',
      'FALLSBURG',
      'FERNDALE',
      'FREMONT CENTER',
      'GLEN SPEY',
      'GLEN WILD',
      'GODEFFROY',
      'GRAHAMSVILLE',
      'HANKINS',
      'HARRIS',
      'HIGHLAND LALASKAE',
      'HORTONVILLE',
      'HUGUENOT',
      'HURLEYVILLE',
      'JEFFERSONVILLE',
      'KAUNEONGA LALASKAE',
      'KENOZA LALASKAE',
      'KIAMESHA LALASKAE',
      'LALASKAE HUNTINGTON',
      'LIBERTY',
      'LIVINGSTON MANOR',
      'LOCH SHELDRALASKAE',
      'LONG EDDY',
      'MONGAUP VALLEY',
      'MOUNTAIN DALE',
      'NARROWSBURG',
      'NEVERSINK',
      'NORTH BRANCH',
      'OBERNBURG',
      'PARKSVILLE',
      'PHILLIPSPORT',
      'POND EDDY',
      'PORT JERVIS',
      'ROCK HILL',
      'ROSCOE',
      'FORESTBURGH',
      'SMALLWOOD',
      'SOUTH FALLSBURG',
      'SPARROW BUSH',
      'SUMMITVILLE',
      'SUNDOWN',
      'SWAN LALASKAE',
      'THOMPSONVILLE',
      'WESTBROOKVILLE',
      'WHITE LALASKAE',
      'WHITE SULPHUR SPRINGS',
      'WOODBOURNE',
      'WOODRIDGE',
      'WURTSBORO',
      'YOUNGSVILLE',
      'YULAN',
      'GLENS FALLS',
      'SOUTH GLENS FALLS',
      'QUEENSBURY',
      'ADIRONDACK',
      'ARGYLE',
      'ATHOL',
      'BALASKAERS MILLS',
      'BLUE MOUNTAIN LALASKAE',
      'BOLTON LANDING',
      'BRANT LALASKAE',
      'CAMBRIDGE',
      'CHESTERTOWN',
      'CLEMONS',
      'CLEVERDALE',
      'COMSTOCK',
      'CORINTH',
      'COSSAYUNA',
      'DIAMOND POINT',
      'FORT ANN',
      'FORT EDWARD',
      'GANSEVOORT',
      'GRANVILLE',
      'GREENFIELD CENTER',
      'GREENWICH',
      'HADLEY',
      'HAGUE',
      'HAMPTON',
      'HARTFORD',
      'HUDSON FALLS',
      'HULETTS LANDING',
      'INDIAN LALASKAE',
      'JOHNSBURG',
      'KATTSKILL BAY',
      'LALASKAE GEORGE',
      'LALASKAE LUZERNE',
      'LONG LALASKAE',
      'MIDDLE FALLS',
      'MIDDLE GRANVILLE',
      'MIDDLE GROVE',
      'MINERVA',
      'NEWCOMB',
      'NORTH CREEK',
      'NORTH GRANVILLE',
      'NORTH HUDSON',
      'NORTH RIVER',
      'OLMSTEDVILLE',
      'PARADOX',
      'PORTER CORNERS',
      'POTTERSVILLE',
      'PUTNAM STATION',
      'RIPARIUS',
      'ROCK CITY FALLS',
      'SABAEL',
      'SALEM',
      'SARATOGA SPRINGS',
      'SCHROON LALASKAE',
      'SCHUYLERVILLE',
      'SEVERANCE',
      'SHUSHAN',
      'SILVER BAY',
      'STONY CREEK',
      'TICONDEROGA',
      'VICTORY MILLS',
      'WARRENSBURG',
      'WEVERTOWN',
      'WHITEHALL',
      'PLATTSBURGH',
      'ALTONA',
      'KEESEVILLE',
      'AU SABLE FORKS',
      'BLOOMINGDALE',
      'BOMBAY',
      'BRAINARDSVILLE',
      'BRUSHTON',
      'BURKE',
      'CADYVILLE',
      'CHAMPLAIN',
      'CHATEAUGAY',
      'CHAZY',
      'CHILDWOLD',
      'CHURUBUSCO',
      'CONSTABLE',
      'CRANBERRY LALASKAE',
      'CROWN POINT',
      'DANNEMORA',
      'DICKINSON CENTER',
      'ELIZABETHTOWN',
      'ELLENBURG',
      'ELLENBURG CENTER',
      'ELLENBURG DEPOT',
      'ESSEX',
      'FORT COVINGTON',
      'GABRIELS',
      'JAY',
      'KEENE',
      'KEENE VALLEY',
      'LALASKAE CLEAR',
      'LALASKAE PLACID',
      'LAWRENCEVILLE',
      'LEWIS',
      'LYON MOUNTAIN',
      'MALONE',
      'MINEVILLE',
      'MOIRA',
      'MOOERS',
      'MOOERS FORKS',
      'MORIAH',
      'MORIAH CENTER',
      'MORRISONVILLE',
      'NEW RUSSIA',
      'NICHOLVILLE',
      'NORTH BANGOR',
      'NORTH LAWRENCE',
      'OWLS HEAD',
      'PAUL SMITHS',
      'PERU',
      'PIERCEFIELD',
      'PORT HENRY',
      'PORT KENT',
      'RAINBOW LALASKAE',
      'RAY BROOK',
      'REDFORD',
      'ROUSES POINT',
      'SAINT REGIS FALLS',
      'SARANAC',
      'SARANAC LALASKAE',
      'SCHUYLER FALLS',
      'TUPPER LALASKAE',
      'UPPER JAY',
      'VERMONTVILLE',
      'WEST CHAZY',
      'WESTPORT',
      'WHIPPLEVILLE',
      'WILLSBORO',
      'WILMINGTON',
      'WITHERBEE',
      'APULIA STATION',
      'AUBURN',
      'AURORA',
      'BALDWINSVILLE',
      'BERNHARDS BAY',
      'BREWERTON',
      'BRIDGEPORT',
      'CAMILLUS',
      'CANASTOTA',
      'CATO',
      'CAYUGA',
      'CAZENOVIA',
      'CENTRAL SQUARE',
      'CHITTENANGO',
      'CICERO',
      'CINCINNATUS',
      'CLAY',
      'CLEVELAND',
      'CLOCKVILLE',
      'CONSTANTIA',
      'CORTLAND',
      'DELPHI FALLS',
      'DE RUYTER',
      'DRYDEN',
      'DURHAMVILLE',
      'EAST HOMER',
      'EAST SYRACUSE',
      'ELBRIDGE',
      'ERIEVILLE',
      'ETNA',
      'FABIUS',
      'FAIR HAVEN',
      'FAYETTE',
      'FAYETTEVILLE',
      'FREEVILLE',
      'FULTON',
      'GENOA',
      'GEORGETOWN',
      'GROTON',
      'HANNIBAL',
      'HASTINGS',
      'HOMER',
      'JAMESVILLE',
      'JORDAN',
      'KING FERRY',
      'KIRKVILLE',
      'LACONA',
      'LA FAYETTE',
      'LITTLE YORK',
      'LIVERPOOL',
      'LOCKE',
      'LYCOMING',
      'MC GRAW',
      'MC LEAN',
      'MALLORY',
      'MANLIUS',
      'MAPLE VIEW',
      'MARCELLUS',
      'MARIETTA',
      'MARTVILLE',
      'MEMPHIS',
      'MERIDIAN',
      'MEXICO',
      'MINETTO',
      'MINOA',
      'MONTEZUMA',
      'MORAVIA',
      'MOTTVILLE',
      'NEDROW',
      'NEW HAVEN',
      'NEW WOODSTOCK',
      'NORTH BAY',
      'NORTH PITCHER',
      'OSWEGO',
      'PARISH',
      'PENNELLVILLE',
      'PETERBORO',
      'PHOENIX',
      'PITCHER',
      'PLAINVILLE',
      'POMPEY',
      'POPLAR RIDGE',
      'PORT BYRON',
      'PREBLE',
      'PULASKI',
      'RED CREEK',
      'RICHLAND',
      'SANDY CREEK',
      'SAVANNAH',
      'SCIPIO CENTER',
      'SENECA FALLS',
      'SKANEATELES',
      'SKANEATELES FALLS',
      'SOUTH BUTLER',
      'SOUTH OTSELIC',
      'STERLING',
      'SYLVAN BEACH',
      'TRUXTON',
      'TULLY',
      'UNION SPRINGS',
      'VERONA BEACH',
      'WAMPSVILLE',
      'WARNERS',
      'WATERLOO',
      'WEEDSPORT',
      'WEST MONROE',
      'SYRACUSE',
      'ALDER CREEK',
      'ALTMAR',
      'AVA',
      'BARNEVELD',
      'BEAVER FALLS',
      'BLOSSVALE',
      'BOONVILLE',
      'BOUCKVILLE',
      'BRANTINGHAM',
      'BRIDGEWATER',
      'BROOKFIELD',
      'BURLINGTON FLATS',
      'CAMDEN',
      'CANAJOHARIE',
      'CASSVILLE',
      'CHADWICKS',
      'CHERRY VALLEY',
      'CLARK MILLS',
      'CLAYVILLE',
      'CLINTON',
      'COLD BROOK',
      'CONSTABLEVILLE',
      'COOPERSTOWN',
      'CROGHAN',
      'DEANSBORO',
      'DOLGEVILLE',
      'EAGLE BAY',
      'EARLVILLE',
      'EAST SPRINGFIELD',
      'EATON',
      'EDMESTON',
      'FLY CREEK',
      'FORESTPORT',
      'FORT PLAIN',
      'FRANKFORT',
      'FRANKLIN SPRINGS',
      'GARRATTSVILLE',
      'GLENFIELD',
      'GREIG',
      'HAMILTON',
      'HARTWICK',
      'HERKIMER',
      'HINCKLEY',
      'HOFFMEISTER',
      'HOLLAND PATENT',
      'HUBBARDSVILLE',
      'ILION',
      'INLET',
      'JORDANVILLE',
      'KNOXBORO',
      'LEE CENTER',
      'LEONARDSVILLE',
      'LITTLE FALLS',
      'LOWVILLE',
      'LYONS FALLS',
      'MC CONNELLSVILLE',
      'MADISON',
      'MARCY',
      'MARTINSBURG',
      'MIDDLEVILLE',
      'MOHAWK',
      'MORRISVILLE',
      'MUNNSVILLE',
      'NELLISTON',
      'NEW BERLIN',
      'NEW HARTFORD',
      'NEW LISBON',
      'NEWPORT',
      'NEW YORK MILLS',
      'NORTH BROOKFIELD',
      'OLD FORGE',
      'ONEIDA',
      'ORISKANY',
      'ORISKANY FALLS',
      'ORWELL',
      'PALATINE BRIDGE',
      'POLAND',
      'PORT LEYDEN',
      'PROSPECT',
      'RAQUETTE LALASKAE',
      'REDFIELD',
      'REMSEN',
      'RICHFIELD SPRINGS',
      'ROME',
      'ROSEBOOM',
      'SAINT JOHNSVILLE',
      'SALISBURY CENTER',
      'SANGERFIELD',
      'SAUQUOIT',
      'SCHUYLER LALASKAE',
      'SHARON SPRINGS',
      'SHERBURNE',
      'SHERRILL',
      'SMYRNA',
      'SOLSVILLE',
      'SPRINGFIELD CENTER',
      'STITTVILLE',
      'STRATFORD',
      'TABERG',
      'THENDARA',
      'TURIN',
      'VAN HORNESVILLE',
      'VERNON',
      'VERNON CENTER',
      'VERONA',
      'WASHINGTON MILLS',
      'WATERVILLE',
      'WEST BURLINGTON',
      'WESTDALE',
      'WEST EATON',
      'WEST EDMESTON',
      'WESTERNVILLE',
      'WESTFORD',
      'WEST LEYDEN',
      'WESTMORELAND',
      'WEST WINFIELD',
      'WHITESBORO',
      'WILLIAMSTOWN',
      'WOODGATE',
      'YORKVILLE',
      'UTICA',
      'WATERTOWN',
      'FORT DRUM',
      'ADAMS',
      'ADAMS CENTER',
      'ALEXANDRIA BAY',
      'ANTWERP',
      'BELLEVILLE',
      'BLACK RIVER',
      'BRASHER FALLS',
      'BRIER HILL',
      'BROWNVILLE',
      'CALCIUM',
      'CANTON',
      'CAPE VINCENT',
      'CARTHAGE',
      'CASTORLAND',
      'CHASE MILLS',
      'CHAUMONT',
      'CHIPPEWA BAY',
      'CLAYTON',
      'COLTON',
      'COPENHAGEN',
      'DEER RIVER',
      'DEFERIET',
      'DE KALB JUNCTION',
      'DENMARK',
      'DEPAUVILLE',
      'DE PEYSTER',
      'DEXTER',
      'EDWARDS',
      'ELLISBURG',
      'EVANS MILLS',
      'FELTS MILLS',
      'FINE',
      'WELLESLEY ISLAND',
      'FISHERS LANDING',
      'GOUVERNEUR',
      'GREAT BEND',
      'HAILESBORO',
      'HAMMOND',
      'HANNAWA FALLS',
      'HARRISVILLE',
      'HELENA',
      'HENDERSON',
      'HENDERSON HARBOR',
      'HERMON',
      'HEUVELTON',
      'HOGANSBURG',
      'LA FARGEVILLE',
      'LIMERICK',
      'LISBON',
      'LORRAINE',
      'MADRID',
      'MANNSVILLE',
      'MASSENA',
      'MORRISTOWN',
      'NATURAL BRIDGE',
      'NEWTON FALLS',
      'NORFOLK',
      'NORWOOD',
      'OGDENSBURG',
      'OSWEGATCHIE',
      'OXBOW',
      'PARISHVILLE',
      'PHILADELPHIA',
      'PIERREPONT MANOR',
      'PLESSIS',
      'POTSDAM',
      'PYRITES',
      'RAYMONDVILLE',
      'REDWOOD',
      'RENSSELAER FALLS',
      'RICHVILLE',
      'RODMAN',
      'ROOSEVELTOWN',
      'RUSSELL',
      'SACKETS HARBOR',
      'SOUTH COLTON',
      'SOUTH RUTLAND',
      'STAR LALASKAE',
      'THERESA',
      'THOUSAND ISLAND PARK',
      'THREE MILE BAY',
      'WADDINGTON',
      'WANALASKAENA',
      'WEST STOCKHOLM',
      'WINTHROP',
      'AFTON',
      'ANDES',
      'APALACHIN',
      'BAINBRIDGE',
      'BARTON',
      'BERKSHIRE',
      'BIBLE SCHOOL PARK',
      'BLODGETT MILLS',
      'BLOOMVILLE',
      'BOVINA CENTER',
      'CANDOR',
      'CASTLE CREEK',
      'CHENANGO BRIDGE',
      'CHENANGO FORKS',
      'COLLIERSVILLE',
      'CONKLIN',
      'CORBETTSVILLE',
      'DAVENPORT',
      'DAVENPORT CENTER',
      'DE LANCEY',
      'DELHI',
      'DEPOSIT',
      'DOWNSVILLE',
      'EAST BRANCH',
      'EAST MEREDITH',
      'EAST PHARSALIA',
      'ENDICOTT',
      'ENDWELL',
      'FISHS EDDY',
      'FRANKLIN',
      'GILBERTSVILLE',
      'GLEN AUBREY',
      'GREENE',
      'GUILFORD',
      'HAMDEN',
      'HANCOCK',
      'HARFORD',
      'HARPERSFIELD',
      'HARPURSVILLE',
      'HOBART',
      'JOHNSON CITY',
      'KILLAWOG',
      'KIRKWOOD',
      'LAURENS',
      'LISLE',
      'MC DONOUGH',
      'MAINE',
      'MARATHON',
      'MASONVILLE',
      'MERIDALE',
      'MILFORD',
      'MORRIS',
      'MOUNT UPTON',
      'MOUNT VISION',
      'NEWARK VALLEY',
      'NICHOLS',
      'NINEVEH',
      'NORTH NORWICH',
      'NORWICH',
      'ONEONTA',
      'OTEGO',
      'OUAQUAGA',
      'OWEGO',
      'OXFORD',
      'PLYMOUTH',
      'PORT CRANE',
      'PORTLANDVILLE',
      'RICHFORD',
      'SHINHOPPLE',
      'SIDNEY',
      'SIDNEY CENTER',
      'SMITHBORO',
      'SMITHVILLE FLATS',
      'SOUTH KORTRIGHT',
      'SOUTH NEW BERLIN',
      'SOUTH PLYMOUTH',
      'TIOGA CENTER',
      'TREADWELL',
      'TROUT CREEK',
      'TUNNEL',
      'UNADILLA',
      'VESTAL',
      'WALTON',
      'WELLS BRIDGE',
      'WEST DAVENPORT',
      'WEST ONEONTA',
      'WHITNEY POINT',
      'WILLET',
      'WILLSEYVILLE',
      'WINDSOR',
      'BINGHAMTON',
      'ALASKARON',
      'ALABAMA',
      'ALDEN',
      'ALEXANDER',
      'ANGOLA',
      'APPLETON',
      'ARCADE',
      'ATHOL SPRINGS',
      'ATTICA',
      'BARKER',
      'BASOM',
      'BATAVIA',
      'BLISS',
      'BOSTON',
      'BOWMANSVILLE',
      'BRANT',
      'BURT',
      'CENTERVILLE',
      'CHAFFEE',
      'CLARENCE',
      'CLARENCE CENTER',
      'COLDEN',
      'COLLINS',
      'COLLINS CENTER',
      'CORFU',
      'COWLESVILLE',
      'CRITTENDEN',
      'DALE',
      'DARIEN CENTER',
      'DAYTON',
      'DELEVAN',
      'DEPEW',
      'DERBY',
      'DUNKIRK',
      'EAST AMHERST',
      'EAST AURORA',
      'EAST BETHANY',
      'EAST CONCORD',
      'EAST PEMBROKE',
      'EDEN',
      'ELBA',
      'ELMA',
      'FARMERSVILLE STATION',
      'FARNHAM',
      'FORESTVILLE',
      'FREDONIA',
      'FREEDOM',
      'GAINESVILLE',
      'GASPORT',
      'GETZVILLE',
      'GLENWOOD',
      'GOWANDA',
      'GRAND ISLAND',
      'HAMBURG',
      'HOLLAND',
      'IRVING',
      'JAVA CENTER',
      'JAVA VILLAGE',
      'LALASKAE VIEW',
      'LANCASTER',
      'LAWTONS',
      'LEWISTON',
      'LOCKPORT',
      'LYNDONVILLE',
      'MACHIAS',
      'MARILLA',
      'MEDINA',
      'MIDDLEPORT',
      'MODEL CITY',
      'NEWFANE',
      'NIAGARA UNIVERSITY',
      'NORTH BOSTON',
      'NORTH COLLINS',
      'NORTH EVANS',
      'NORTH JAVA',
      'NORTH TONAWANDA',
      'OALASKAFIELD',
      'OLCOTT',
      'ORCHARD PARK',
      'PERRYSBURG',
      'PIKE',
      'RANSOMVILLE',
      'SANBORN',
      'SANDUSKY',
      'SARDINIA',
      'SHERIDAN',
      'SILVER CREEK',
      'SOUTH DAYTON',
      'SOUTH WALES',
      'SPRING BROOK',
      'SPRINGVILLE',
      'STAFFORD',
      'STELLA NIAGARA',
      'STRYKERSVILLE',
      'TONAWANDA',
      'VAN BUREN POINT',
      'VARYSBURG',
      'VERSAILLES',
      'WALES CENTER',
      'WEST FALLS',
      'WEST VALLEY',
      'WILSON',
      'YORKSHIRE',
      'YOUNGSTOWN',
      'BUFFALO',
      'NIAGARA FALLS',
      'ADAMS BASIN',
      'ALBION',
      'ALTON',
      'AVON',
      'BELLONA',
      'BERGEN',
      'BRANCHPORT',
      'BROCKPORT',
      'BYRON',
      'CALEDONIA',
      'CANANDAIGUA',
      'FARMINGTON',
      'CASTILE',
      'CHURCHVILLE',
      'CLARENDON',
      'CLARKSON',
      'CLIFTON SPRINGS',
      'CLYDE',
      'CONESUS',
      'DANSVILLE',
      'DRESDEN',
      'EAST BLOOMFIELD',
      'EAST ROCHESTER',
      'EAST WILLIAMSON',
      'FAIRPORT',
      'FANCHER',
      'FISHERS',
      'GENESEO',
      'GENEVA',
      'GORHAM',
      'GROVELAND',
      'HALL',
      'HAMLIN',
      'HEMLOCK',
      'HENRIETTA',
      'HILTON',
      'BLOOMFIELD',
      'HOLLEY',
      'HONEOYE',
      'HONEOYE FALLS',
      'IONIA',
      'KENDALL',
      'KENT',
      'KEUKA PARK',
      'KNOWLESVILLE',
      'LALASKAEVILLE',
      'LEICESTER',
      'LE ROY',
      'LIMA',
      'LINWOOD',
      'LIVONIA',
      'LIVONIA CENTER',
      'LYONS',
      'MACEDON',
      'MANCHESTER',
      'MARION',
      'MENDON',
      'MIDDLESEX',
      'MORTON',
      'MOUNT MORRIS',
      'MUMFORD',
      'NAPLES',
      'NEWARK',
      'NORTH CHILI',
      'NORTH GREECE',
      'NORTH ROSE',
      'NUNDA',
      'OALASKAS CORNERS',
      'ONTARIO',
      'ONTARIO CENTER',
      'OVID',
      'PALMYRA',
      'PAVILION',
      'PENFIELD',
      'PENN YAN',
      'PERKINSVILLE',
      'PERRY',
      'PHELPS',
      'PIFFARD',
      'PITTSFORD',
      'PORTAGEVILLE',
      'PORT GIBSON',
      'PULTNEYVILLE',
      'RETSOF',
      'ROMULUS',
      'ROSE',
      'RUSH',
      'RUSHVILLE',
      'SCOTTSBURG',
      'SCOTTSVILLE',
      'SENECA CASTLE',
      'SHORTSVILLE',
      'SILVER LALASKAE',
      'SILVER SPRINGS',
      'SODUS',
      'SODUS POINT',
      'SONYEA',
      'SOUTH BYRON',
      'SOUTH LIMA',
      'SPENCERPORT',
      'SPRINGWATER',
      'STANLEY',
      'UNION HILL',
      'VICTOR',
      'WALWORTH',
      'WARSAW',
      'WATERPORT',
      'WAYLAND',
      'WEBSTER',
      'WEST BLOOMFIELD',
      'WEST HENRIETTA',
      'WILLARD',
      'WILLIAMSON',
      'WOLCOTT',
      'WYOMING',
      'YORK',
      'ROCHESTER',
      'JAMESTOWN',
      'ALLEGANY',
      'ALLENTOWN',
      'ALMA',
      'ANGELICA',
      'ASHVILLE',
      'BELFAST',
      'BEMUS POINT',
      'BLACK CREEK',
      'BOLIVAR',
      'BROCTON',
      'CANEADEA',
      'CASSADAGA',
      'CATTARAUGUS',
      'CELORON',
      'CERES',
      'CHAUTAUQUA',
      'CHERRY CREEK',
      'CLYMER',
      'CONEWANGO VALLEY',
      'CUBA',
      'DEWITTVILLE',
      'EAST OTTO',
      'EAST RANDOLPH',
      'ELLICOTTVILLE',
      'ELLINGTON',
      'FALCONER',
      'FILLMORE',
      'FINDLEY LALASKAE',
      'FRANKLINVILLE',
      'FREWSBURG',
      'FRIENDSHIP',
      'GERRY',
      'GREAT VALLEY',
      'GREENHURST',
      'HINSDALE',
      'HOUGHTON',
      'HUME',
      'KENNEDY',
      'KILL BUCK',
      'LALASKAEWOOD',
      'LEON',
      'LILY DALE',
      'LIMESTONE',
      'LITTLE GENESEE',
      'LITTLE VALLEY',
      'MAPLE SPRINGS',
      'MAYVILLE',
      'NIOBE',
      'OLEAN',
      'OTTO',
      'PANAMA',
      'PORTLAND',
      'PORTVILLE',
      'RANDOLPH',
      'RICHBURG',
      'RIPLEY',
      'RUSHFORD',
      'SAINT BONAVENTURE',
      'SALAMANCA',
      'SHERMAN',
      'SINCLAIRVILLE',
      'STEAMBURG',
      'STOCKTON',
      'STOW',
      'WEST CLARKSVILLE',
      'WESTFIELD',
      'WESTONS MILLS',
      'ADDISON',
      'ALFRED',
      'ALFRED STATION',
      'ALMOND',
      'ALPINE',
      'ANDOVER',
      'ARKPORT',
      'ATLANTA',
      'AVOCA',
      'BATH',
      'BEAVER DAMS',
      'BELMONT',
      'BIG FLATS',
      'BRADFORD',
      'BREESPORT',
      'BROOKTONDALE',
      'BURDETT',
      'CAMERON',
      'CAMERON MILLS',
      'CAMPBELL',
      'CANASERAGA',
      'CANISTEO',
      'CAYUTA',
      'CHEMUNG',
      'COHOCTON',
      'COOPERS PLAINS',
      'CORNING',
      'DALTON',
      'DUNDEE',
      'ERIN',
      'GREENWOOD',
      'HAMMONDSPORT',
      'HECTOR',
      'HIMROD',
      'HORNELL',
      'HORSEHEADS',
      'HUNT',
      'INTERLALASKAEN',
      'ITHACA',
      'JACKSONVILLE',
      'JASPER',
      'KANONA',
      'LALASKAEMONT',
      'LINDLEY',
      'LOCKWOOD',
      'LODI',
      'LOWMAN',
      'MECKLENBURG',
      'MILLPORT',
      'MONTOUR FALLS',
      'NEWFIELD',
      'ODESSA',
      'PAINTED POST',
      'PINE CITY',
      'PINE VALLEY',
      'PRATTSBURGH',
      'PULTENEY',
      'READING CENTER',
      'REXVILLE',
      'ROCK STREAM',
      'SAVONA',
      'SCIO',
      'SLATERVILLE SPRINGS',
      'LANSING',
      'SPENCER',
      'SWAIN',
      'TROUPSBURG',
      'TRUMANSBURG',
      'TYRONE',
      'VAN ETTEN',
      'WATKINS GLEN',
      'WAVERLY',
      'WAYNE',
      'WELLSBURG',
      'WELLSVILLE',
      'WHITESVILLE',
      'WOODHULL',
      'ELMIRA'
    ]
  },
  OH: {
    code: 'OH',
    name: 'OHIO',
    cities: [
      'ALEXANDRIA',
      'AMLIN',
      'ASHLEY',
      'BLACKLICK',
      'BLADENSBURG',
      'BRINKHAVEN',
      'BROADWAY',
      'BUCKEYE LALASKAE',
      'CABLE',
      'CATAWBA',
      'CENTERBURG',
      'CROTON',
      'DANVILLE',
      'DELAWARE',
      'DUBLIN',
      'ETNA',
      'FREDERICKTOWN',
      'GALENA',
      'GAMBIER',
      'GRANVILLE',
      'HEBRON',
      'HILLIARD',
      'HOMER',
      'HOWARD',
      'IRWIN',
      'JACKSONTOWN',
      'JOHNSTOWN',
      'KILBOURNE',
      'KIRKERSVILLE',
      'LEWIS CENTER',
      'MAGNETIC SPRINGS',
      'MARTINSBURG',
      'MARYSVILLE',
      'MECHANICSBURG',
      'MILFORD CENTER',
      'MILLERSPORT',
      'MINGO',
      'MOUNT LIBERTY',
      'MOUNT VERNON',
      'NEW ALBANY',
      'NEWARK',
      'HEATH',
      'NORTH LEWISBURG',
      'OSTRANDER',
      'PATASKALA',
      'PLAIN CITY',
      'POWELL',
      'RADNOR',
      'RAYMOND',
      'REYNOLDSBURG',
      'ROSEWOOD',
      'SAINT LOUISVILLE',
      'SAINT PARIS',
      'SUMMIT STATION',
      'SUNBURY',
      'THORNVILLE',
      'UNIONVILLE CENTER',
      'URBANA',
      'UTICA',
      'WESTERVILLE',
      'WESTVILLE',
      'WOODSTOCK',
      'COLUMBUS',
      'ADELPHI',
      'AMANDA',
      'ASHVILLE',
      'BALTIMORE',
      'BLOOMINGBURG',
      'BREMEN',
      'BRICE',
      'CANAL WINCHESTER',
      'CARBON HILL',
      'CARROLL',
      'CIRCLEVILLE',
      'CLARKSBURG',
      'COMMERCIAL POINT',
      'DERBY',
      'GALLOWAY',
      'GROVE CITY',
      'GROVEPORT',
      'HARRISBURG',
      'HAYDENVILLE',
      'JEFFERSONVILLE',
      'LANCASTER',
      'LAURELVILLE',
      'LITHOPOLIS',
      'LOCKBOURNE',
      'LOGAN',
      'LONDON',
      'MILLEDGEVILLE',
      'MOUNT STERLING',
      'MURRAY CITY',
      'NEW HOLLAND',
      'ORIENT',
      'PICKERINGTON',
      'PLEASANTVILLE',
      'ROCKBRIDGE',
      'RUSHVILLE',
      'SEDALIA',
      'SOUTH BLOOMINGVILLE',
      'SOUTH SOLON',
      'STOUTSVILLE',
      'SUGAR GROVE',
      'TARLTON',
      'THURSTON',
      'UNION FURNACE',
      'WASHINGTON COURT HOUSE',
      'WEST JEFFERSON',
      'WEST RUSHVILLE',
      'WILLIAMSPORT',
      'MARION',
      'BELLE CENTER',
      'BELLEFONTAINE',
      'CALEDONIA',
      'CARDINGTON',
      'CAREY',
      'CHESTERVILLE',
      'DE GRAFF',
      'EAST LIBERTY',
      'EDISON',
      'FULTON',
      'GREEN CAMP',
      'HARPSTER',
      'HUNTSVILLE',
      'IBERIA',
      'KENTON',
      'KIRBY',
      'LALASKAEVIEW',
      'LA RUE',
      'LEWISTOWN',
      'MARENGO',
      'MARTEL',
      'MIDDLEBURG',
      'MORRAL',
      'MOUNT GILEAD',
      'MOUNT VICTORY',
      'NEW BLOOMINGTON',
      'PROSPECT',
      'QUINCY',
      'RICHWOOD',
      'RIDGEWAY',
      'ROUNDHEAD',
      'RUSHSYLVANIA',
      'RUSSELLS POINT',
      'SHAUCK',
      'SPARTA',
      'UPPER SANDUSKY',
      'WALDO',
      'WEST LIBERTY',
      'WEST MANSFIELD',
      'WHARTON',
      'ZANESFIELD',
      'BOWLING GREEN',
      'BRADNER',
      'BURGOON',
      'CLAY CENTER',
      'CLYDE',
      'CURTICE',
      'CYGNET',
      'DUNBRIDGE',
      'ELMORE',
      'FREMONT',
      'GENOA',
      'GIBSONBURG',
      'GRAYTOWN',
      'GYPSUM',
      'HARBOR VIEW',
      'HELENA',
      'ISLE SAINT GEORGE',
      'JERRY CITY',
      'KELLEYS ISLAND',
      'LACARNE',
      'LALASKAESIDE MARBLEHEAD',
      'LEMOYNE',
      'LINDSEY',
      'LUCKEY',
      'MARTIN',
      'MIDDLE BASS',
      'MILLBURY',
      'OALASKA HARBOR',
      'PEMBERVILLE',
      'PORTAGE',
      'PORT CLINTON',
      'PUT IN BAY',
      'RISINGSUN',
      'ROCKY RIDGE',
      'ROSSFORD',
      'RUDOLPH',
      'STONY RIDGE',
      'VICKERY',
      'WALBRIDGE',
      'WAYNE',
      'WEST MILLGROVE',
      'WILLISTON',
      'WOODVILLE',
      'ALVORDTON',
      'ARCHBOLD',
      'BERKEY',
      'BLALASKAESLEE',
      'BRYAN',
      'COLTON',
      'CUSTAR',
      'DEFIANCE',
      'DELTA',
      'DESHLER',
      'EDGERTON',
      'EDON',
      'EVANSPORT',
      'FARMER',
      'FAYETTE',
      'GRAND RAPIDS',
      'GRELTON',
      'HAMLER',
      'HASKINS',
      'HICKSVILLE',
      'HOLGATE',
      'HOLLAND',
      'HOYTVILLE',
      'JEWELL',
      'KUNKLE',
      'LIBERTY CENTER',
      'LYONS',
      'MC CLURE',
      'MALINTA',
      'MARK CENTER',
      'MAUMEE',
      'METAMORA',
      'MILTON CENTER',
      'MONCLOVA',
      'MONTPELIER',
      'NAPOLEON',
      'NEAPOLIS',
      'NEW BAVARIA',
      'NEY',
      'OKOLONA',
      'PERRYSBURG',
      'PETTISVILLE',
      'PIONEER',
      'RIDGEVILLE CORNERS',
      'SHERWOOD',
      'STRYKER',
      'SWANTON',
      'SYLVANIA',
      'TONTOGANY',
      'WATERVILLE',
      'WAUSEON',
      'WESTON',
      'WEST UNITY',
      'WHITEHOUSE',
      'TOLEDO',
      'OREGON',
      'NORTHWOOD',
      'ZANESVILLE',
      'AVA',
      'BARNESVILLE',
      'BEALLSVILLE',
      'BELLE VALLEY',
      'BELMONT',
      'BETHESDA',
      'BLUE ROCK',
      'BROWNSVILLE',
      'BUFFALO',
      'BYESVILLE',
      'CALDWELL',
      'CAMBRIDGE',
      'CHANDLERSVILLE',
      'CHESTERHILL',
      'CORNING',
      'CROOKSVILLE',
      'CUMBERLAND',
      'DERWENT',
      'DUNCAN FALLS',
      'EAST FULTONHAM',
      'FAIRVIEW',
      'FULTONHAM',
      'GLENFORD',
      'GRATIOT',
      'HOPEWELL',
      'JERUSALEM',
      'JUNCTION CITY',
      'KIMBOLTON',
      'KIPLING',
      'LAINGS',
      'LEWISVILLE',
      'LORE CITY',
      'MC CONNELSVILLE',
      'MALAGA',
      'MALTA',
      'MORRISTOWN',
      'MOUNT PERRY',
      'MOXAHALA',
      'NEW CONCORD',
      'NEW LEXINGTON',
      'NEW STRAITSVILLE',
      'NORWICH',
      'OLD WASHINGTON',
      'PHILO',
      'PLEASANT CITY',
      'QUALASKAER CITY',
      'ROSEVILLE',
      'SALESVILLE',
      'SARAHSVILLE',
      'SENECAVILLE',
      'SHAWNEE',
      'SOMERSET',
      'STAFFORD',
      'STOCKPORT',
      'SUMMERFIELD',
      'SYCAMORE VALLEY',
      'WHITE COTTAGE',
      'WOODSFIELD',
      'ADAMSVILLE',
      'BALASKAERSVILLE',
      'BALTIC',
      'BLISSFIELD',
      'CONESVILLE',
      'COSHOCTON',
      'DRESDEN',
      'FRAZEYSBURG',
      'FRESNO',
      'KEENE',
      'NASHPORT',
      'NEWCOMERSTOWN',
      'PLAINFIELD',
      'PORT WASHINGTON',
      'STONE CREEK',
      'TRINWAY',
      'WALHONDING',
      'WARSAW',
      'WEST LAFAYETTE',
      'ADENA',
      'ALLEDONIA',
      'AMSTERDAM',
      'BARTON',
      'BELLAIRE',
      'CADIZ',
      'BERGHOLZ',
      'BLAINE',
      'BLOOMINGDALE',
      'BRIDGEPORT',
      'BRILLIANT',
      'CAMERON',
      'CLARINGTON',
      'COLERAIN',
      'DILLONVALE',
      'EAST LIVERPOOL',
      'EAST SPRINGFIELD',
      'EMPIRE',
      'FAIRPOINT',
      'GLENCOE',
      'HAMMONDSVILLE',
      'HANNIBAL',
      'IRONDALE',
      'JACOBSBURG',
      'LANSING',
      'MARTINS FERRY',
      'MAYNARD',
      'MINGO JUNCTION',
      'MOUNT PLEASANT',
      'NEFFS',
      'PINEY FORK',
      'POWHATAN POINT',
      'RAYLAND',
      'RICHMOND',
      'SALINEVILLE',
      'SARDIS',
      'SHADYSIDE',
      'SMITHFIELD',
      'SAINT CLAIRSVILLE',
      'LAFFERTY',
      'STEUBENVILLE',
      'STRATTON',
      'SUMMITVILLE',
      'TILTONSVILLE',
      'TORONTO',
      'UNIONPORT',
      'WARNOCK',
      'WELLSVILLE',
      'WOLF RUN',
      'YORKVILLE',
      'BANNOCK',
      'FREEPORT',
      'HARRISVILLE',
      'HOPEDALE',
      'FLUSHING',
      'NEW ATHENS',
      'PIEDMONT',
      'NEW RUMLEY',
      'HOLLOWAY',
      'JEWETT',
      'SCIO',
      'SHORT CREEK',
      'AMHERST',
      'ANDOVER',
      'ASHTABULA',
      'AUSTINBURG',
      'AVON',
      'AVON LALASKAE',
      'BEREA',
      'BURTON',
      'CHAGRIN FALLS',
      'CHARDON',
      'CHESTERLAND',
      'COLUMBIA STATION',
      'CONNEAUT',
      'DORSET',
      'EAST CLARIDON',
      'ELYRIA',
      'NORTH RIDGEVILLE',
      'GATES MILLS',
      'GENEVA',
      'GRAFTON',
      'GRAND RIVER',
      'HUNTSBURG',
      'JEFFERSON',
      'KINGSVILLE',
      'KIPTON',
      'LAGRANGE',
      'LORAIN',
      'SHEFFIELD LALASKAE',
      'MACEDONIA',
      'MADISON',
      'MENTOR',
      'MIDDLEFIELD',
      'MONTVILLE',
      'NEWBURY',
      'NORTHFIELD',
      'NORTH KINGSVILLE',
      'NORTH OLMSTED',
      'NOVELTY',
      'OBERLIN',
      'ORWELL',
      'PAINESVILLE',
      'PARKMAN',
      'PERRY',
      'PIERPONT',
      'ROCK CREEK',
      'ROME',
      'THOMPSON',
      'TWINSBURG',
      'UNIONVILLE',
      'VERMILION',
      'WELLINGTON',
      'WICKLIFFE',
      'WILLIAMSFIELD',
      'WILLOUGHBY',
      'EASTLALASKAE',
      'WINDSOR',
      'CLEVELAND',
      'LALASKAEWOOD',
      'ROCKY RIVER',
      'EUCLID',
      'BEACHWOOD',
      'INDEPENDENCE',
      'NORTH ROYALTON',
      'STRONGSVILLE',
      'MAPLE HEIGHTS',
      'OLMSTED FALLS',
      'SOLON',
      'BAY VILLAGE',
      'BRECKSVILLE',
      'BROOKPARK',
      'WESTLALASKAE',
      'BEDFORD',
      'BROADVIEW HEIGHTS',
      'ATWATER',
      'AURORA',
      'BARBERTON',
      'BATH',
      'BRADY LALASKAE',
      'BRUNSWICK',
      'BURBANK',
      'CHIPPEWA LALASKAE',
      'CLINTON',
      'CRESTON',
      'CUYAHOGA FALLS',
      'STOW',
      'DOYLESTOWN',
      'GARRETTSVILLE',
      'GREEN',
      'HINCKLEY',
      'HIRAM',
      'HOMERVILLE',
      'HUDSON',
      'KENT',
      'STREETSBORO',
      'LALASKAEMORE',
      'WESTFIELD CENTER',
      'LITCHFIELD',
      'LODI',
      'MANTUA',
      'MEDINA',
      'MOGADORE',
      'MUNROE FALLS',
      'PENINSULA',
      'RANDOLPH',
      'RAVENNA',
      'RITTMAN',
      'ROOTSTOWN',
      'SEVILLE',
      'SHARON CENTER',
      'SPENCER',
      'STERLING',
      'TALLMADGE',
      'VALLEY CITY',
      'WADSWORTH',
      'WAYLAND',
      'RICHFIELD',
      'WEST SALEM',
      'WINDHAM',
      'ALASKARON',
      'FAIRLAWN',
      'BERLIN CENTER',
      'BRISTOLVILLE',
      'BROOKFIELD',
      'BURGHILL',
      'CAMPBELL',
      'CANFIELD',
      'COLUMBIANA',
      'CORTLAND',
      'DEERFIELD',
      'DIAMOND',
      'EAST PALESTINE',
      'ELKTON',
      'ELLSWORTH',
      'FARMDALE',
      'FOWLER',
      'GIRARD',
      'GREENFORD',
      'HANOVERTON',
      'HARTFORD',
      'HUBBARD',
      'KENSINGTON',
      'KINSMAN',
      'LALASKAE MILTON',
      'LEAVITTSBURG',
      'LEETONIA',
      'LISBON',
      'LOWELLVILLE',
      'MC DONALD',
      'MASURY',
      'MESOPOTAMIA',
      'MINERAL RIDGE',
      'NEGLEY',
      'NEW MIDDLETOWN',
      'NEW SPRINGFIELD',
      'NEWTON FALLS',
      'NEW WATERFORD',
      'NILES',
      'NORTH BENTON',
      'NORTH BLOOMFIELD',
      'NORTH JACKSON',
      'NORTH LIMA',
      'ORANGEVILLE',
      'PETERSBURG',
      'ROGERS',
      'SALEM',
      'SOUTHINGTON',
      'STRUTHERS',
      'VIENNA',
      'WARREN',
      'WASHINGTONVILLE',
      'WEST FARMINGTON',
      'WEST POINT',
      'WINONA',
      'YOUNGSTOWN',
      'ALLIANCE',
      'APPLE CREEK',
      'AUGUSTA',
      'BEACH CITY',
      'BELOIT',
      'BERLIN',
      'BIG PRAIRIE',
      'BOLIVAR',
      'BREWSTER',
      'CANAL FULTON',
      'CARROLLTON',
      'CHARM',
      'DALTON',
      'DAMASCUS',
      'DELLROY',
      'DENNISON',
      'DOVER',
      'DUNDEE',
      'EAST ROCHESTER',
      'EAST SPARTA',
      'FREDERICKSBURG',
      'GLENMONT',
      'GNADENHUTTEN',
      'GREENTOWN',
      'HARLEM SPRINGS',
      'HARTVILLE',
      'HOLMESVILLE',
      'HOMEWORTH',
      'KIDRON',
      'KILLBUCK',
      'LALASKAEVILLE',
      'LEESVILLE',
      'LIMAVILLE',
      'LOUISVILLE',
      'MAGNOLIA',
      'MALVERN',
      'MARSHALLVILLE',
      'MASSILLON',
      'MAXIMO',
      'MECHANICSTOWN',
      'MIDDLEBRANCH',
      'MIDVALE',
      'MILLERSBURG',
      'MINERAL CITY',
      'MINERVA',
      'MOUNT EATON',
      'MOUNT HOPE',
      'NASHVILLE',
      'NAVARRE',
      'NEW PHILADELPHIA',
      'NORTH GEORGETOWN',
      'NORTH LAWRENCE',
      'ORRVILLE',
      'PARIS',
      'ROBERTSVILLE',
      'SANDYVILLE',
      'SEBRING',
      'SHERRODSVILLE',
      'SHREVE',
      'SMITHVILLE',
      'SOMERDALE',
      'STILLWATER',
      'STRASBURG',
      'SUGARCREEK',
      'TUSCARAWAS',
      'UHRICHSVILLE',
      'UNIONTOWN',
      'WALNUT CREEK',
      'WAYNESBURG',
      'WILMOT',
      'WINESBURG',
      'WOOSTER',
      'DEERSVILLE',
      'BOWERSTON',
      'ZOAR',
      'TIPPECANOE',
      'CANTON',
      'ADRIAN',
      'ALVADA',
      'AMSDEN',
      'ARCADIA',
      'ASHLAND',
      'ATTICA',
      'BASCOM',
      'BELLEVUE',
      'BELLVILLE',
      'BERLIN HEIGHTS',
      'BETTSVILLE',
      'BIRMINGHAM',
      'BLOOMDALE',
      'BLOOMVILLE',
      'BUCYRUS',
      'BUTLER',
      'CASTALIA',
      'CHATFIELD',
      'COLLINS',
      'CRESTLINE',
      'FLAT ROCK',
      'FOSTORIA',
      'GALION',
      'GREEN SPRINGS',
      'GREENWICH',
      'HAYESVILLE',
      'HURON',
      'JEROMESVILLE',
      'KANSAS',
      'LOUDONVILLE',
      'LUCAS',
      'MC CUTCHENVILLE',
      'MELMORE',
      'MILAN',
      'MONROEVILLE',
      'NANKIN',
      'NEVADA',
      'NEW HAVEN',
      'NEW LONDON',
      'NEW RIEGEL',
      'NEW WASHINGTON',
      'NORTH FAIRFIELD',
      'NORTH ROBINSON',
      'NORWALK',
      'NOVA',
      'OCEOLA',
      'OLD FORT',
      'ONTARIO',
      'PERRYSVILLE',
      'PLYMOUTH',
      'POLK',
      'REPUBLIC',
      'SANDUSKY',
      'SAVANNAH',
      'SHELBY',
      'SHILOH',
      'SULLIVAN',
      'SULPHUR SPRINGS',
      'SYCAMORE',
      'TIFFIN',
      'TIRO',
      'WILLARD',
      'WALASKAEMAN',
      'MANSFIELD',
      'ADDYSTON',
      'CLEVES',
      'COLLEGE CORNER',
      'COLLINSVILLE',
      'FRANKLIN',
      'HAMILTON',
      'FAIRFIELD',
      'HARRISON',
      'HARVEYSBURG',
      'HOOVEN',
      'KINGS MILLS',
      'LEBANON',
      'MAINEVILLE',
      'MASON',
      'MIAMITOWN',
      'MIDDLETOWN',
      'MONROE',
      'MOUNT SAINT JOSEPH',
      'NORTH BEND',
      'OKEANA',
      'OREGONIA',
      'OVERPECK',
      'OXFORD',
      'ROSS',
      'SEVEN MILE',
      'SHANDON',
      'SOMERVILLE',
      'SOUTH LEBANON',
      'SPRINGBORO',
      'TRENTON',
      'WAYNESVILLE',
      'WEST CHESTER',
      'WEST ELKTON',
      'ABERDEEN',
      'AMELIA',
      'BATAVIA',
      'BENTONVILLE',
      'BETHEL',
      'BLANCHESTER',
      'BUFORD',
      'CAMP DENNISON',
      'CHILO',
      'CLARKSVILLE',
      'CUBA',
      'DECATUR',
      'FAYETTEVILLE',
      'FEESBURG',
      'FELICITY',
      'GEORGETOWN',
      'GOSHEN',
      'GREENFIELD',
      'HAMERSVILLE',
      'HIGGINSPORT',
      'HIGHLAND',
      'HILLSBORO',
      'LEESBURG',
      'LEES CREEK',
      'LOVELAND',
      'LYNCHBURG',
      'MANCHESTER',
      'MARATHON',
      'MARTINSVILLE',
      'MIAMIVILLE',
      'MIDLAND',
      'MILFORD',
      'MORROW',
      'MOSCOW',
      'MOUNT ORAB',
      'MOWRYSTOWN',
      'NEVILLE',
      'NEW RICHMOND',
      'NEWTONSVILLE',
      'NEW VIENNA',
      'OWENSVILLE',
      'PLEASANT PLAIN',
      'PORT WILLIAM',
      'REESVILLE',
      'RIPLEY',
      'RUSSELLVILLE',
      'SABINA',
      'SARDINIA',
      'SINKING SPRING',
      'TERRACE PARK',
      'WILLIAMSBURG',
      'WILMINGTON',
      'CINCINNATI',
      'ALPHA',
      'ANNA',
      'ANSONIA',
      'ARCANUM',
      'BELLBROOK',
      'BOTKINS',
      'BOWERSVILLE',
      'BRADFORD',
      'BROOKVILLE',
      'BURKETTSVILLE',
      'CAMDEN',
      'CASSTOWN',
      'CEDARVILLE',
      'CLAYTON',
      'CLIFTON',
      'CONOVER',
      'COVINGTON',
      'DONNELSVILLE',
      'EATON',
      'ELDORADO',
      'ENGLEWOOD',
      'ENON',
      'FAIRBORN',
      'FARMERSVILLE',
      'FLETCHER',
      'GERMANTOWN',
      'GETTYSBURG',
      'GRATIS',
      'GREENVILLE',
      'HOLLANSBURG',
      'HOUSTON',
      'JACKSON CENTER',
      'JAMESTOWN',
      'KETTLERSVILLE',
      'LAURA',
      'LEWISBURG',
      'LUDLOW FALLS',
      'MAPLEWOOD',
      'MEDWAY',
      'MIAMISBURG',
      'NEW CARLISLE',
      'NEW LEBANON',
      'NEW MADISON',
      'NEW PARIS',
      'NEW WESTON',
      'NORTH HAMPTON',
      'NORTH STAR',
      'OSGOOD',
      'PALESTINE',
      'PEMBERTON',
      'PHILLIPSBURG',
      'PIQUA',
      'PITSBURG',
      'PLEASANT HILL',
      'PORT JEFFERSON',
      'POTSDAM',
      'ROSSBURG',
      'RUSSIA',
      'SIDNEY',
      'SOUTH CHARLESTON',
      'SOUTH VIENNA',
      'SPRING VALLEY',
      'TIPP CITY',
      'TREMONT CITY',
      'TROY',
      'VANDALIA',
      'VERONA',
      'VERSAILLES',
      'WEST ALEXANDRIA',
      'WEST MANCHESTER',
      'WEST MILTON',
      'WILBERFORCE',
      'XENIA',
      'YELLOW SPRINGS',
      'YORKSHIRE',
      'CHRISTIANSBURG',
      'UNION CITY',
      'DAYTON',
      'SPRINGFIELD',
      'CHILLICOTHE',
      'BAINBRIDGE',
      'BEAVER',
      'BIDWELL',
      'BLUE CREEK',
      'BOURNEVILLE',
      'CHERRY FORK',
      'CHESAPEALASKAE',
      'CHESHIRE',
      'COALTON',
      'CREOLA',
      'CROWN CITY',
      'CYNTHIANA',
      'FRANKFORT',
      'FRANKLIN FURNACE',
      'FRIENDSHIP',
      'GALLIPOLIS',
      'HALLSVILLE',
      'HAMDEN',
      'HAVERHILL',
      'IRONTON',
      'JACKSON',
      'JASPER',
      'KERR',
      'KINGSTON',
      'KITTS HILL',
      'LATHAM',
      'LONDONDERRY',
      'LUCASVILLE',
      'LYNX',
      'MC ARTHUR',
      'MC DERMOTT',
      'MINFORD',
      'NEW PLYMOUTH',
      'OALASKA HILL',
      'OTWAY',
      'PATRIOT',
      'PEDRO',
      'PEEBLES',
      'PIKETON',
      'PORTSMOUTH',
      'WEST PORTSMOUTH',
      'PROCTORVILLE',
      'RARDEN',
      'RAY',
      'RICHMOND DALE',
      'RIO GRANDE',
      'ROCK CAMP',
      'SCIOTO FURNACE',
      'SCOTTOWN',
      'SEAMAN',
      'SOUTH POINT',
      'SOUTH SALEM',
      'SOUTH WEBSTER',
      'STOCKDALE',
      'STOUT',
      'THURMAN',
      'VINTON',
      'WALASKAEFIELD',
      'WATERLOO',
      'WAVERLY',
      'WELLSTON',
      'WEST UNION',
      'WHEELERSBURG',
      'WILKESVILLE',
      'WILLOW WOOD',
      'WINCHESTER',
      'ZALESKI',
      'ATHENS',
      'ALBANY',
      'AMESVILLE',
      'BARLOW',
      'BARTLETT',
      'BELPRE',
      'BEVERLY',
      'BUCHTEL',
      'CARBONDALE',
      'CHAUNCEY',
      'CHESTER',
      'COAL RUN',
      'COOLVILLE',
      'CUTLER',
      'DEXTER CITY',
      'FLEMING',
      'GLOUSTER',
      'GRAYSVILLE',
      'GUYSVILLE',
      'HOCKINGPORT',
      'JACKSONVILLE',
      'LANGSVILLE',
      'LITTLE HOCKING',
      'LONG BOTTOM',
      'LOWELL',
      'LOWER SALEM',
      'MACKSBURG',
      'MARIETTA',
      'MIDDLEPORT',
      'MILLFIELD',
      'NELSONVILLE',
      'NEW MARSHFIELD',
      'NEW MATAMORAS',
      'NEWPORT',
      'POMEROY',
      'PORTLAND',
      'RACINE',
      'REEDSVILLE',
      'RENO',
      'RUTLAND',
      'SHADE',
      'SHARPSBURG',
      'STEWART',
      'SYRACUSE',
      'THE PLAINS',
      'TORCH',
      'TRIMBLE',
      'TUPPERS PLAINS',
      'VINCENT',
      'WATERFORD',
      'WATERTOWN',
      'WHIPPLE',
      'WINGETT RUN',
      'LIMA',
      'BEAVERDAM',
      'GOMER',
      'ADA',
      'ALGER',
      'ANTWERP',
      'ARLINGTON',
      'BELMORE',
      'BENTON RIDGE',
      'BLUFFTON',
      'BUCKLAND',
      'CAIRO',
      'CECIL',
      'CELINA',
      'CHICKASAW',
      'CLOVERDALE',
      'COLDWATER',
      'COLUMBUS GROVE',
      'CONTINENTAL',
      'CONVOY',
      'DELPHOS',
      'DOLA',
      'DUNKIRK',
      'DUPONT',
      'ELGIN',
      'FINDLAY',
      'JENERA',
      'FOREST',
      'FORT JENNINGS',
      'FORT LORAMIE',
      'FORT RECOVERY',
      'GLANDORF',
      'GROVER HILL',
      'HARROD',
      'HAVILAND',
      'KALIDA',
      'LAFAYETTE',
      'LATTY',
      'LEIPSIC',
      'MC COMB',
      'MC GUFFEY',
      'MARIA STEIN',
      'MELROSE',
      'MENDON',
      'MIDDLE POINT',
      'MILLER CITY',
      'MINSTER',
      'MONTEZUMA',
      'MOUNT BLANCHARD',
      'MOUNT CORY',
      'NEW BREMEN',
      'NEW HAMPSHIRE',
      'NEW KNOXVILLE',
      'NORTH BALTIMORE',
      'OALASKAWOOD',
      'OHIO CITY',
      'OTTAWA',
      'OTTOVILLE',
      'PANDORA',
      'PAULDING',
      'PAYNE',
      'RAWSON',
      'ROCKFORD',
      'SAINT HENRY',
      'SAINT JOHNS',
      'SAINT MARYS',
      'SCOTT',
      'SPENCERVILLE',
      'UNIOPOLIS',
      'VAN BUREN',
      'VANLUE',
      'VAN WERT',
      'VAUGHNSVILLE',
      'VENEDOCIA',
      'WAPALASKAONETA',
      'WAYNESFIELD',
      'WILLIAMSTOWN',
      'WILLSHIRE',
      'WREN'
    ]
  },
  OK: {
    code: 'OK',
    name: 'OKLAHOMA',
    cities: [
      'ALBERT',
      'ALEX',
      'EDMOND',
      'AMBER',
      'ANADARKO',
      'APACHE',
      'ARCADIA',
      'BETHANY',
      'BINGER',
      'BLANCHARD',
      'BRADLEY',
      'BRAY',
      'CALUMET',
      'CARNEGIE',
      'CASHION',
      'CEMENT',
      'CHICKASHA',
      'NORMAN',
      'CHOCTAW',
      'COLONY',
      'CONCHO',
      'CORN',
      'COYLE',
      'CRESCENT',
      'CYRIL',
      'DAVIS',
      'DIBBLE',
      'DOUGHERTY',
      'EALASKALY',
      'EL RENO',
      'FORT COBB',
      'GEARY',
      'GOTEBO',
      'GRACEMONT',
      'GREENFIELD',
      'GUTHRIE',
      'HARRAH',
      'HINTON',
      'HYDRO',
      'JONES',
      'LANGSTON',
      'LEXINGTON',
      'LINDSAY',
      'LOOKEBA',
      'LUTHER',
      'MARLOW',
      'MARSHALL',
      'MAYSVILLE',
      'MERIDIAN',
      'MINCO',
      'MORRISON',
      'MOUNTAIN VIEW',
      'MULHALL',
      'MUSTANG',
      'NEWCASTLE',
      'NICOMA PARK',
      'NINNEKAH',
      'NOBLE',
      'ORLANDO',
      'PAOLI',
      'PAULS VALLEY',
      'PERRY',
      'PIEDMONT',
      'POCASSET',
      'PURCELL',
      'RUSH SPRINGS',
      'SPENCER',
      'YUKON',
      'SULPHUR',
      'TUTTLE',
      'UNION CITY',
      'VERDEN',
      'WASHINGTON',
      'WASHITA',
      'WAYNE',
      'WEATHERFORD',
      'WHEATLAND',
      'WYNNEWOOD',
      'OKLAHOMA CITY',
      'ARDMORE',
      'COUNTYLINE',
      'BURNEYVILLE',
      'COLEMAN',
      'ELMORE CITY',
      'FOSTER',
      'FOX',
      'GENE AUTRY',
      'GRAHAM',
      'HEALDTON',
      'KINGSTON',
      'LEBANON',
      'LEON',
      'LOCO',
      'LONE GROVE',
      'HENNEPIN',
      'MADILL',
      'MANNSVILLE',
      'MARIETTA',
      'MEAD',
      'MILBURN',
      'OVERBROOK',
      'RAVIA',
      'RINGLING',
      'SPRINGER',
      'THACKERVILLE',
      'TISHOMINGO',
      'WAPANUCKA',
      'WILSON',
      'PERNELL',
      'RATLIFF CITY',
      'TATUMS',
      'TUSSY',
      'VELMA',
      'LAWTON',
      'FORT SILL',
      'ADDINGTON',
      'ALTUS',
      'ALTUS AFB',
      'BLAIR',
      'CACHE',
      'CHATTANOOGA',
      'COMANCHE',
      'DAVIDSON',
      'DEVOL',
      'DUKE',
      'DUNCAN',
      'ELDORADO',
      'ELGIN',
      'ELMER',
      'FAXON',
      'FLETCHER',
      'FREDERICK',
      'GERONIMO',
      'GOULD',
      'GRANDFIELD',
      'GRANITE',
      'HASTINGS',
      'HEADRICK',
      'HOLLIS',
      'HOLLISTER',
      'INDIAHOMA',
      'LOVELAND',
      'MANGUM',
      'MANITOU',
      'MARTHA',
      'MEDICINE PARK',
      'MEERS',
      'MOUNTAIN PARK',
      'OLUSTEE',
      'OSCAR',
      'RANDLETT',
      'ROOSEVELT',
      'RYAN',
      'SNYDER',
      'STERLING',
      'TEMPLE',
      'TERRAL',
      'TIPTON',
      'VINSON',
      'WALTERS',
      'WAURIKA',
      'CLINTON',
      'ARAPAHO',
      'BESSIE',
      'BURNS FLAT',
      'BUTLER',
      'CANUTE',
      'CARTER',
      'CHEYENNE',
      'CORDELL',
      'CRAWFORD',
      'CUSTER CITY',
      'DILL CITY',
      'DURHAM',
      'ELK CITY',
      'ERICK',
      'FAY',
      'FOSS',
      'HAMMON',
      'HOBART',
      'LEEDEY',
      'LONE WOLF',
      'OALASKAWOOD',
      'PUTNAM',
      'REYDON',
      'ROCKY',
      'SAYRE',
      'SEILING',
      'SENTINEL',
      'SWEETWATER',
      'TALOGA',
      'TEXOLA',
      'THOMAS',
      'WILLOW',
      'ENID',
      'ALINE',
      'ALVA',
      'AMES',
      'AMORITA',
      'BISON',
      'BURLINGTON',
      'CANTON',
      'CARMEN',
      'CARRIER',
      'CHEROKEE',
      'CLEO SPRINGS',
      'COVINGTON',
      'DACOMA',
      'DOUGLAS',
      'DOVER',
      'DRUMMOND',
      'FAIRMONT',
      'FAIRVIEW',
      'GARBER',
      'GOLTRY',
      'HELENA',
      'HENNESSEY',
      'HILLSDALE',
      'HITCHCOCK',
      'HOPETON',
      'ISABELLA',
      'JET',
      'KINGFISHER',
      'KREMLIN',
      'LAHOMA',
      'LONGDALE',
      'LOYAL',
      'LUCIEN',
      'MANCHESTER',
      'MEDFORD',
      'MENO',
      'NASH',
      'OKARCHE',
      'OKEENE',
      'OMEGA',
      'POND CREEK',
      'RINGWOOD',
      'SOUTHARD',
      'WALASKAITA',
      'WATONGA',
      'WAUKOMIS',
      'WOODWARD',
      'ARNETT',
      'BUFFALO',
      'CAMARGO',
      'CHESTER',
      'FARGO',
      'FORT SUPPLY',
      'FREEDOM',
      'GAGE',
      'GATE',
      'KNOWLES',
      'LAVERNE',
      'MAY',
      'MOORELAND',
      'MUTUAL',
      'ROSSTON',
      'SHARON',
      'SHATTUCK',
      'VICI',
      'WAYNOKA',
      'ADAMS',
      'BALKO',
      'BEAVER',
      'BOISE CITY',
      'FELT',
      'FORGAN',
      'GOODWELL',
      'GUYMON',
      'HARDESTY',
      'HOOKER',
      'KENTON',
      'KEYES',
      'TEXHOMA',
      'TURPIN',
      'TYRONE',
      'AVANT',
      'BARNSDALL',
      'BARTLESVILLE',
      'BIXBY',
      'BOWRING',
      'BRISTOW',
      'BROKEN ARROW',
      'CATOOSA',
      'CHELSEA',
      'CLAREMORE',
      'CLEVELAND',
      'COLLINSVILLE',
      'COPAN',
      'CUSHING',
      'DAVENPORT',
      'DELAWARE',
      'DEPEW',
      'DEWEY',
      'DRUMRIGHT',
      'FOYIL',
      'GLENCOE',
      'GLENPOOL',
      'HALLETT',
      'HOMINY',
      'INOLA',
      'JENKS',
      'JENNINGS',
      'KELLYVILLE',
      'KIEFER',
      'LENAPAH',
      'LEONARD',
      'MANNFORD',
      'MARAMEC',
      'MILFAY',
      'MOUNDS',
      'NOWATA',
      'OALASKAHURST',
      'OCHELATA',
      'OILTON',
      'OOLOGAH',
      'OSAGE',
      'OWASSO',
      'PAWHUSKA',
      'PAWNEE',
      'PERKINS',
      'PRUE',
      'RAMONA',
      'RIPLEY',
      'SAND SPRINGS',
      'SAPULPA',
      'SHAMROCK',
      'SKIATOOK',
      'SLICK',
      'S COFFEYVILLE',
      'SPERRY',
      'STILLWATER',
      'STROUD',
      'TALALA',
      'TERLTON',
      'VERA',
      'WANN',
      'WYNONA',
      'YALE',
      'TULSA',
      'VINITA',
      'ADAIR',
      'AFTON',
      'BIG CABIN',
      'BLUEJACKET',
      'CARDIN',
      'CHOUTEAU',
      'COLCORD',
      'COMMERCE',
      'DISNEY',
      'EUCHA',
      'FAIRLAND',
      'GROVE',
      'JAY',
      'KANSAS',
      'KETCHUM',
      'LANGLEY',
      'LOCUST GROVE',
      'MIAMI',
      'NORTH MIAMI',
      'OALASKAS',
      'PICHER',
      'PRYOR',
      'QUAPAW',
      'ROSE',
      'SALINA',
      'SPAVINAW',
      'STRANG',
      'TWIN OALASKAS',
      'WELCH',
      'WYANDOTTE',
      'MUSKOGEE',
      'BEGGS',
      'BOYNTON',
      'BRAGGS',
      'CANADIAN',
      'CHECOTAH',
      'COOKSON',
      'COUNCIL HILL',
      'COWETA',
      'CROWDER',
      'DEWAR',
      'EUFAULA',
      'FORT GIBSON',
      'GORE',
      'HASKELL',
      'HENRYETTA',
      'HITCHITA',
      'HOYT',
      'HULBERT',
      'INDIANOLA',
      'MOODYS',
      'MORRIS',
      'OKAY',
      'OKMULGEE',
      'OKTAHA',
      'PARK HILL',
      'PEGGS',
      'PORTER',
      'PORUM',
      'PRESTON',
      'PROCTOR',
      'REDBIRD',
      'RENTIESVILLE',
      'SCHULTER',
      'STIDHAM',
      'STIGLER',
      'TAFT',
      'TAHLEQUAH',
      'TULLAHASSEE',
      'WAGONER',
      'WAINWRIGHT',
      'WARNER',
      'WEBBERS FALLS',
      'WELLING',
      'WHITEFIELD',
      'MCALESTER',
      'ALBION',
      'ALDERSON',
      'ANTLERS',
      'ATOKA',
      'BLANCO',
      'BLOCKER',
      'BROMIDE',
      'CALVIN',
      'CANEY',
      'CENTRAHOMA',
      'CLARITA',
      'CLAYTON',
      'COALGATE',
      'DAISY',
      'FINLEY',
      'GOWEN',
      'HAILEYVILLE',
      'HARTSHORNE',
      'HONOBIA',
      'KINTA',
      'KIOWA',
      'KREBS',
      'LANE',
      'LEHIGH',
      'MOYERS',
      'NASHOBA',
      'PANOLA',
      'PITTSBURG',
      'QUINTON',
      'RATTAN',
      'RED OALASKA',
      'SAVANNA',
      'SNOW',
      'STRINGTOWN',
      'STUART',
      'TALIHINA',
      'TUPELO',
      'TUSKAHOMA',
      'WARDVILLE',
      'WHITESBORO',
      'WILBURTON',
      'PONCA CITY',
      'BILLINGS',
      'BLACKWELL',
      'BRAMAN',
      'BURBANK',
      'DEER CREEK',
      'FAIRFAX',
      'HUNTER',
      'KAW CITY',
      'LAMONT',
      'MARLAND',
      'NARDIN',
      'NEWKIRK',
      'RALSTON',
      'RED ROCK',
      'SHIDLER',
      'TONKAWA',
      'DURANT',
      'ACHILLE',
      'ALBANY',
      'BATTIEST',
      'BENNINGTON',
      'BETHEL',
      'BOKCHITO',
      'BOSWELL',
      'BROKEN BOW',
      'CADDO',
      'CALERA',
      'CARTWRIGHT',
      'COLBERT',
      'EAGLETOWN',
      'FORT TOWSON',
      'GARVIN',
      'GOLDEN',
      'GRANT',
      'HAWORTH',
      'HENDRIX',
      'HUGO',
      'IDABEL',
      'KEMP',
      'KENEFIC',
      'MILLERTON',
      'PICKENS',
      'PLATTER',
      'RINGOLD',
      'RUFE',
      'SAWYER',
      'SOPER',
      'SPENCERVILLE',
      'SWINK',
      'VALLIANT',
      'WRIGHT CITY',
      'SHAWNEE',
      'SEMINOLE',
      'ADA',
      'AGRA',
      'ALLEN',
      'ASHER',
      'ATWOOD',
      'BOLEY',
      'BOWLEGS',
      'BYARS',
      'CARNEY',
      'CASTLE',
      'CHANDLER',
      'CONNERVILLE',
      'CROMWELL',
      'DUSTIN',
      'EARLSBORO',
      'FITTSTOWN',
      'FITZHUGH',
      'FRANCIS',
      'HANNA',
      'HOLDENVILLE',
      'KONAWA',
      'LAMAR',
      'MCLOUD',
      'MACOMB',
      'MAUD',
      'MEEKER',
      'MILL CREEK',
      'NEWALLA',
      'OKEMAH',
      'PADEN',
      'PRAGUE',
      'ROFF',
      'SAINT LOUIS',
      'SASALASKAWA',
      'SPARKS',
      'STONEWALL',
      'STRATFORD',
      'TECUMSEH',
      'TRYON',
      'WANETTE',
      'WELEETKA',
      'WELLSTON',
      'WETUMKA',
      'WEWOKA',
      'ARKOMA',
      'POCOLA',
      'BOKOSHE',
      'BUNCH',
      'CAMERON',
      'FANSHAWE',
      'GANS',
      'HEAVENER',
      'HODGEN',
      'HOWE',
      'KEOTA',
      'LEFLORE',
      'LEQUIRE',
      'MCCURTAIN',
      'MARBLE CITY',
      'MOFFETT',
      'MONROE',
      'MULDROW',
      'MUSE',
      'PANAMA',
      'POTEAU',
      'ROLAND',
      'SALLISAW',
      'SHADY POINT',
      'SMITHVILLE',
      'SPIRO',
      'STILWELL',
      'VIAN',
      'WATSON',
      'WATTS',
      'WESTVILLE',
      'WISTER'
    ]
  },
  OR: {
    code: 'OR',
    name: 'OREGON',
    cities: [
      'ANTELOPE',
      'AURORA',
      'BEAVERCREEK',
      'BEAVERTON',
      'BORING',
      'BRIDAL VEIL',
      'BRIGHTWOOD',
      'CANBY',
      'CASCADE LOCKS',
      'CLACKAMAS',
      'CLATSKANIE',
      'COLTON',
      'COLUMBIA CITY',
      'CORBETT',
      'DONALD',
      'DUFUR',
      'EAGLE CREEK',
      'ESTACADA',
      'FAIRVIEW',
      'GERVAIS',
      'GLADSTONE',
      'GOVERNMENT CAMP',
      'GRASS VALLEY',
      'GRESHAM',
      'HOOD RIVER',
      'HUBBARD',
      'KENT',
      'LALASKAE OSWEGO',
      'MARYLHURST',
      'MAUPIN',
      'MOLALLA',
      'MORO',
      'MOSIER',
      'MOUNT HOOD PARKDALE',
      'MULINO',
      'ODELL',
      'OREGON CITY',
      'RAINIER',
      'RHODODENDRON',
      'RUFUS',
      'SAINT HELENS',
      'WARREN',
      'DEER ISLAND',
      'SANDY',
      'SCAPPOOSE',
      'SHANIKO',
      'THE DALLES',
      'TROUTDALE',
      'TUALATIN',
      'TYGH VALLEY',
      'VERNONIA',
      'WASCO',
      'WELCHES',
      'WEST LINN',
      'WILSONVILLE',
      'WOODBURN',
      'AMITY',
      'ARCH CAPE',
      'ASTORIA',
      'BANKS',
      'BAY CITY',
      'BEAVER',
      'BUXTON',
      'CANNON BEACH',
      'CARLTON',
      'CLOVERDALE',
      'CORNELIUS',
      'DAYTON',
      'DUNDEE',
      'FOREST GROVE',
      'GALES CREEK',
      'GARIBALDI',
      'GASTON',
      'HAMMOND',
      'HEBO',
      'HILLSBORO',
      'MANNING',
      'LAFAYETTE',
      'MCMINNVILLE',
      'MANZANITA',
      'NEHALEM',
      'NEWBERG',
      'NORTH PLAINS',
      'OCEANSIDE',
      'PACIFIC CITY',
      'ROCKAWAY BEACH',
      'SAINT PAUL',
      'SEASIDE',
      'SHERWOOD',
      'TILLAMOOK',
      'NETARTS',
      'TIMBER',
      'TOLOVANA PARK',
      'WARRENTON',
      'WHEELER',
      'YAMHILL',
      'NESKOWIN',
      'PORTLAND',
      'SALEM',
      'KEIZER',
      'ALBANY',
      'ALSEA',
      'AUMSVILLE',
      'BLODGETT',
      'BROWNSVILLE',
      'CASCADIA',
      'CORVALLIS',
      'CRABTREE',
      'CRAWFORDSVILLE',
      'DALLAS',
      'DEPOE BAY',
      'DETROIT',
      'EDDYVILLE',
      'FALLS CITY',
      'FOSTER',
      'GATES',
      'GRAND RONDE',
      'HALSEY',
      'IDANHA',
      'INDEPENDENCE',
      'JEFFERSON',
      'LEBANON',
      'LOGSDEN',
      'LYONS',
      'MARION',
      'MILL CITY',
      'MONMOUTH',
      'MOUNT ANGEL',
      'NEOTSU',
      'NEWPORT',
      'SOUTH BEACH',
      'LINCOLN CITY',
      'OTIS',
      'OTTER ROCK',
      'PHILOMATH',
      'RICKREALL',
      'ROSE LODGE',
      'SAINT BENEDICT',
      'SCIO',
      'SCOTTS MILLS',
      'SEAL ROCK',
      'SHEDD',
      'SHERIDAN',
      'SILETZ',
      'SILVERTON',
      'STAYTON',
      'MEHAMA',
      'SUBLIMITY',
      'SWEET HOME',
      'GLENEDEN BEACH',
      'TANGENT',
      'TIDEWATER',
      'TOLEDO',
      'TURNER',
      'WALDPORT',
      'WILLAMINA',
      'EUGENE',
      'AGNESS',
      'ALLEGANY',
      'ALVADORE',
      'AZALEA',
      'BANDON',
      'BLACHLY',
      'BLUE RIVER',
      'BROADBENT',
      'BROOKINGS',
      'CAMAS VALLEY',
      'CANYONVILLE',
      'CHESHIRE',
      'COOS BAY',
      'COQUILLE',
      'COTTAGE GROVE',
      'CRESCENT LALASKAE',
      'CRESWELL',
      'CULP CREEK',
      'CURTIN',
      'DAYS CREEK',
      'DEADWOOD',
      'DEXTER',
      'DILLARD',
      'DORENA',
      'DRAIN',
      'ELKTON',
      'ELMIRA',
      'FALL CREEK',
      'FLORENCE',
      'GARDINER',
      'GLENDALE',
      'GLIDE',
      'GOLD BEACH',
      'HARRISBURG',
      'IDLEYLD PARK',
      'JUNCTION CITY',
      'LALASKAESIDE',
      'LANGLOIS',
      'LORANE',
      'LOWELL',
      'MAPLETON',
      'MARCOLA',
      'PLEASANT HILL',
      'MONROE',
      'MYRTLE CREEK',
      'MYRTLE POINT',
      'NORTH BEND',
      'NORWAY',
      'NOTI',
      'OALASKALAND',
      'OALASKARIDGE',
      'OPHIR',
      'PORT ORFORD',
      'POWERS',
      'REEDSPORT',
      'RIDDLE',
      'ROSEBURG',
      'SAGINAW',
      'SCOTTSBURG',
      'SIXES',
      'SPRINGFIELD',
      'SUTHERLIN',
      'SWISSHOME',
      'TENMILE',
      'THURSTON',
      'TILLER',
      'UMPQUA',
      'VENETA',
      'VIDA',
      'WALTERVILLE',
      'WALTON',
      'WEDDERBURN',
      'WESTFIR',
      'WESTLALASKAE',
      'WILBUR',
      'WINCHESTER',
      'WINSTON',
      'WOLF CREEK',
      'YACHATS',
      'YONCALLA',
      'MEDFORD',
      'CENTRAL POINT',
      'WHITE CITY',
      'ASHLAND',
      'BUTTE FALLS',
      'CAVE JUNCTION',
      'EAGLE POINT',
      'GOLD HILL',
      'GRANTS PASS',
      'JACKSONVILLE',
      'KERBY',
      'MERLIN',
      'MURPHY',
      'O BRIEN',
      'PHOENIX',
      'PROSPECT',
      'ROGUE RIVER',
      'SELMA',
      'SHADY COVE',
      'TALENT',
      'TRAIL',
      'WILDERVILLE',
      'WILLIAMS',
      'KLAMATH FALLS',
      'CRATER LALASKAE',
      'ADEL',
      'BEATTY',
      'BLY',
      'BONANZA',
      'CHILOQUIN',
      'DAIRY',
      'FORT KLAMATH',
      'KENO',
      'LALASKAEVIEW',
      'MALIN',
      'MERRILL',
      'MIDLAND',
      'NEW PINE CREEK',
      'PAISLEY',
      'PLUSH',
      'SILVER LALASKAE',
      'SPRAGUE RIVER',
      'SUMMER LALASKAE',
      'CHRISTMAS VALLEY',
      'BEND',
      'FIELDS',
      'ASHWOOD',
      'BROTHERS',
      'BURNS',
      'PRINCETON',
      'DIAMOND',
      'CAMP SHERMAN',
      'CHEMULT',
      'CRANE',
      'CRESCENT',
      'CULVER',
      'FORT ROCK',
      'FRENCHGLEN',
      'GILCHRIST',
      'HINES',
      'LA PINE',
      'LAWEN',
      'MADRAS',
      'MITCHELL',
      'PAULINA',
      'POST',
      'POWELL BUTTE',
      'PRINEVILLE',
      'REDMOND',
      'RILEY',
      'SISTERS',
      'TERREBONNE',
      'WARM SPRINGS',
      'PENDLETON',
      'ADAMS',
      'ARLINGTON',
      'ATHENA',
      'BALASKAER CITY',
      'BATES',
      'BOARDMAN',
      'BRIDGEPORT',
      'CANYON CITY',
      'CAYUSE',
      'CONDON',
      'COVE',
      'DAYVILLE',
      'ECHO',
      'ELGIN',
      'ENTERPRISE',
      'FOSSIL',
      'FOX',
      'HAINES',
      'HALFWAY',
      'HELIX',
      'HEPPNER',
      'HEREFORD',
      'HERMISTON',
      'LEXINGTON',
      'OXBOW',
      'IMBLER',
      'IMNAHA',
      'IONE',
      'IRRIGON',
      'JOHN DAY',
      'JOSEPH',
      'KIMBERLY',
      'LA GRANDE',
      'LONG CREEK',
      'LOSTINE',
      'MEACHAM',
      'MIKKALO',
      'MILTON FREEWATER',
      'MONUMENT',
      'MOUNT VERNON',
      'NORTH POWDER',
      'PILOT ROCK',
      'PRAIRIE CITY',
      'RICHLAND',
      'RITTER',
      'SENECA',
      'SPRAY',
      'STANFIELD',
      'SUMMERVILLE',
      'SUMPTER',
      'UKIAH',
      'UMATILLA',
      'UNION',
      'UNITY',
      'WALLOWA',
      'WESTON',
      'ADRIAN',
      'AROCK',
      'BROGAN',
      'DREWSEY',
      'DURKEE',
      'HARPER',
      'HUNTINGTON',
      'IRONSIDE',
      'JAMIESON',
      'JORDAN VALLEY',
      'JUNTURA',
      'NYSSA',
      'ONTARIO',
      'RIVERSIDE',
      'VALE',
      'WESTFALL'
    ]
  },
  PA: {
    code: 'PA',
    name: 'PENNSYLVANIA',
    cities: [
      'ALIQUIPPA',
      'AMBRIDGE',
      'ATLASBURG',
      'BADEN',
      'BAIRDFORD',
      'BALASKAERSTOWN',
      'BEAVER',
      'BEAVER FALLS',
      'BELLE VERNON',
      'BRACKENRIDGE',
      'BRADFORDWOODS',
      'BRIDGEVILLE',
      'BUENA VISTA',
      'BULGER',
      'BUNOLA',
      'BURGETTSTOWN',
      'CHARLEROI',
      'CHESWICK',
      'CLAIRTON',
      'CLINTON',
      'CONWAY',
      'COULTERS',
      'CREIGHTON',
      'CUDDY',
      'CURTISVILLE',
      'DONORA',
      'DRAVOSBURG',
      'EAST MC KEESPORT',
      'ELDERSVILLE',
      'ELIZABETH',
      'ELRAMA',
      'FREEDOM',
      'GEORGETOWN',
      'GIBSONIA',
      'GLASSPORT',
      'CRESCENT',
      'GREENOCK',
      'HARWICK',
      'HOOKSTOWN',
      'INDIANOLA',
      'INDUSTRY',
      'JOFFRE',
      'LANGELOTH',
      'LAWRENCE',
      'LEETSDALE',
      'MC DONALD',
      'MIDLAND',
      'MIDWAY',
      'MONACA',
      'MONESSEN',
      'MONONGAHELA',
      'MORGAN',
      'NATRONA HEIGHTS',
      'NEW BRIGHTON',
      'NEW EAGLE',
      'NEW KENSINGTON',
      'OALASKADALE',
      'PRICEDALE',
      'ROCHESTER',
      'RURAL RIDGE',
      'RUSSELLTON',
      'SHIPPINGPORT',
      'SLOVAN',
      'SOUTH HEIGHTS',
      'STURGEON',
      'SUTERSVILLE',
      'TARENTUM',
      'TRAFFORD',
      'WARRENDALE',
      'WEBSTER',
      'WEST ELIZABETH',
      'WEST NEWTON',
      'WEXFORD',
      'WILDWOOD',
      'ALLISON PARK',
      'BETHEL PARK',
      'BRADDOCK',
      'CARNEGIE',
      'CORAOPOLIS',
      'DUQUESNE',
      'EAST PITTSBURGH',
      'GLENSHAW',
      'HOMESTEAD',
      'WEST MIFFLIN',
      'IMPERIAL',
      'INGOMAR',
      'SOUTH PARK',
      'MC KEESPORT',
      'MC KEES ROCKS',
      'NORTH VERSAILLES',
      'OALASKAMONT',
      'PITCAIRN',
      'PRESTO',
      'SEWICKLEY',
      'SPRINGDALE',
      'TURTLE CREEK',
      'MONROEVILLE',
      'VERONA',
      'WILMERDING',
      'PITTSBURGH',
      'WASHINGTON',
      'ALEPPO',
      'AMITY',
      'AVELLA',
      'BEALLSVILLE',
      'BENTLEYVILLE',
      'BOBTOWN',
      'BRAVE',
      'CANONSBURG',
      'CARMICHAELS',
      'CECIL',
      'CLARKSVILLE',
      'CLAYSVILLE',
      'COKEBURG',
      'CRUCIBLE',
      'DILLINER',
      'PROSPERITY',
      'EIGHTY FOUR',
      'ELLSWORTH',
      'FINLEYVILLE',
      'FREDERICKTOWN',
      'GARARDS FORT',
      'GASTONVILLE',
      'GRAYSVILLE',
      'GREENSBORO',
      'HENDERSONVILLE',
      'HICKORY',
      'HOLBROOK',
      'HOUSTON',
      'JEFFERSON',
      'MARIANNA',
      'MATHER',
      'MEADOW LANDS',
      'MILLSBORO',
      'MOUNT MORRIS',
      'MUSE',
      'NEMACOLIN',
      'NEW FREEPORT',
      'NINEVEH',
      'PINE BANK',
      'RICES LANDING',
      'RICHEYVILLE',
      'ROGERSVILLE',
      'SCENERY HILL',
      'SOUTHVIEW',
      'SPRAGGS',
      'STRABANE',
      'SYCAMORE',
      'TAYLORSTOWN',
      'VAN VOORHIS',
      'VENETIA',
      'VESTABURG',
      'WAYNESBURG',
      'WEST ALEXANDER',
      'WEST FINLEY',
      'WESTLAND',
      'WEST MIDDLETOWN',
      'WIND RIDGE',
      'UNIONTOWN',
      'ADAH',
      'ADDISON',
      'ALLENPORT',
      'ALLISON',
      'BRIER HILL',
      'BROWNFIELD',
      'BROWNSVILLE',
      'CALIFORNIA',
      'CARDALE',
      'CHALK HILL',
      'CHESTNUT RIDGE',
      'COAL CENTER',
      'CONFLUENCE',
      'CONNELLSVILLE',
      'DAISYTOWN',
      'DAWSON',
      'DENBO',
      'DICKERSON RUN',
      'DUNBAR',
      'DUNLEVY',
      'EAST MILLSBORO',
      'ELCO',
      'FAIRBANK',
      'FAIRCHANCE',
      'FARMINGTON',
      'FAYETTE CITY',
      'GANS',
      'GIBBON GLADE',
      'GRINDSTONE',
      'HIBBS',
      'HILLER',
      'HOPWOOD',
      'INDIAN HEAD',
      'ISABELLA',
      'JACOBS CREEK',
      'KEISTERVILLE',
      'LA BELLE',
      'LALASKAE LYNN',
      'LECKRONE',
      'LEISENRING',
      'LEMONT FURNACE',
      'MC CLELLANDTOWN',
      'MARKLEYSBURG',
      'MARTIN',
      'MASONTOWN',
      'MELCROFT',
      'MERRITTSTOWN',
      'MILL RUN',
      'MOUNT BRADDOCK',
      'NEWELL',
      'NEW GENEVA',
      'NEW SALEM',
      'NORMALVILLE',
      'OHIOPYLE',
      'OLIVER',
      'PERRYOPOLIS',
      'POINT MARION',
      'REPUBLIC',
      'RONCO',
      'ROSCOE',
      'SMITHFIELD',
      'SMITHTON',
      'SMOCK',
      'STAR JUNCTION',
      'STOCKDALE',
      'ULEDI',
      'URSINA',
      'VANDERBILT',
      'WALTERSBURG',
      'WEST LEISENRING',
      'WHITE',
      'WICKHAVEN',
      'SOMERSET',
      'HIDDEN VALLEY',
      'ACOSTA',
      'ALUM BANK',
      'BEDFORD',
      'BERLIN',
      'BOSWELL',
      'BOYNTON',
      'BREEZEWOOD',
      'BUFFALO MILLS',
      'CLEARVILLE',
      'CRYSTAL SPRING',
      'EVERETT',
      'FAIRHOPE',
      'FISHERTOWN',
      'FORT HILL',
      'FRIEDENS',
      'GARRETT',
      'GRAY',
      'HYNDMAN',
      'JENNERS',
      'JENNERSTOWN',
      'KANTNER',
      'LISTIE',
      'MANNS CHOICE',
      'MARKLETON',
      'MEYERSDALE',
      'NEW BALTIMORE',
      'NEW PARIS',
      'QUECREEK',
      'ROCKWOOD',
      'SALISBURY',
      'SCHELLSBURG',
      'SHANKSVILLE',
      'SIPESVILLE',
      'SPRINGS',
      'STOYSTOWN',
      'WELLERSBURG',
      'WEST SALISBURY',
      'GREENSBURG',
      'ACME',
      'ADAMSBURG',
      'ALVERTON',
      'APOLLO',
      'ARDARA',
      'ARMBRUST',
      'ARONA',
      'AVONMORE',
      'BOVARD',
      'BRADENVILLE',
      'CALUMET',
      'CHAMPION',
      'CLARIDGE',
      'CRABTREE',
      'DARRAGH',
      'DELMONT',
      'DERRY',
      'DONEGAL',
      'EAST VANDERGRIFT',
      'EDMON',
      'EVERSON',
      'EXPORT',
      'FORBES ROAD',
      'GRAPEVILLE',
      'HANNASTOWN',
      'HARRISON CITY',
      'HERMINIE',
      'HOSTETTER',
      'HUNKER',
      'HUTCHINSON',
      'HYDE PARK',
      'IRWIN',
      'JEANNETTE',
      'JONES MILLS',
      'LARIMER',
      'LATROBE',
      'LAUGHLINTOWN',
      'LEECHBURG',
      'LIGONIER',
      'LOWBER',
      'LOYALHANNA',
      'LUXOR',
      'MADISON',
      'MAMMOTH',
      'MANOR',
      'MOUNT PLEASANT',
      'MURRYSVILLE',
      'NEW ALEXANDRIA',
      'NEW DERRY',
      'NEW STANTON',
      'NORTH APOLLO',
      'NORVELT',
      'PENN',
      'PLEASANT UNITY',
      'RECTOR',
      'RILLTON',
      'RUFFS DALE',
      'SALINA',
      'SALTSBURG',
      'SCHENLEY',
      'SCOTTDALE',
      'SLICKVILLE',
      'SOUTHWEST',
      'SPRING CHURCH',
      'STAHLSTOWN',
      'TARRS',
      'UNITED',
      'VANDERGRIFT',
      'WENDEL',
      'WESTMORELAND CITY',
      'WHITNEY',
      'WYANO',
      'YOUNGSTOWN',
      'YOUNGWOOD',
      'YUKON',
      'INDIANA',
      'ALVERDA',
      'ANITA',
      'ARCADIA',
      'AULTMAN',
      'NORTHERN CAMBRIA',
      'BIG RUN',
      'BLACK LICK',
      'BLAIRSVILLE',
      'BRUSH VALLEY',
      'BURNSIDE',
      'CARROLLTOWN',
      'CHAMBERSVILLE',
      'CHERRY TREE',
      'CLARKSBURG',
      'CLUNE',
      'CLYMER',
      'COMMODORE',
      'COOLSPRING',
      'CORAL',
      'CREEKSIDE',
      'DE LANCEY',
      'DIXONVILLE',
      'ELDERTON',
      'ELMORA',
      'EMEIGH',
      'ERNEST',
      'FROSTBURG',
      'GIPSY',
      'GLEN CAMPBELL',
      'HAMILTON',
      'HEILWOOD',
      'HILLSDALE',
      'HOME',
      'HOMER CITY',
      'JOSEPHINE',
      'JUNEAU',
      'KENT',
      'LA JOSE',
      'LUCERNEMINES',
      'MC INTYRE',
      'MAHAFFEY',
      'MARCHAND',
      'MARION CENTER',
      'MARSTELLER',
      'MENTCLE',
      'NICKTOWN',
      'NORTHPOINT',
      'OLIVEBURG',
      'PENN RUN',
      'PUNXSUTAWNEY',
      'RINGGOLD',
      'ROCHESTER MILLS',
      'ROSSITER',
      'SAINT BENEDICT',
      'SHELOCTA',
      'SPANGLER',
      'SPRANKLE MILLS',
      'STARFORD',
      'TIMBLIN',
      'TORRANCE',
      'VALIER',
      'WALSTON',
      'WEST LEBANON',
      'WORTHVILLE',
      'DU BOIS',
      'BENEZETT',
      'BRANDY CAMP',
      'BROCKPORT',
      'BROCKWAY',
      'BROOKVILLE',
      'BYRNEDALE',
      'CLARINGTON',
      'CORSICA',
      'DAGUS MINES',
      'DRIFTWOOD',
      'EMPORIUM',
      'FALLS CREEK',
      'FORCE',
      'JOHNSONBURG',
      'KERSEY',
      'KNOX DALE',
      'LUTHERSBURG',
      'PENFIELD',
      'REYNOLDSVILLE',
      'RIDGWAY',
      'ROCKTON',
      'SAINT MARYS',
      'SIGEL',
      'SINNAMAHONING',
      'STUMP CREEK',
      'SUMMERVILLE',
      'SYKESVILLE',
      'TROUTVILLE',
      'WEEDVILLE',
      'WILCOX',
      'JOHNSTOWN',
      'ARMAGH',
      'BEAVERDALE',
      'BELSANO',
      'BOLIVAR',
      'CAIRNBROOK',
      'CASSANDRA',
      'CENTRAL CITY',
      'COLVER',
      'DAVIDSVILLE',
      'DILLTOWN',
      'DUNLO',
      'EBENSBURG',
      'ELTON',
      'HOLLSOPPLE',
      'HOOVERSVILLE',
      'JEROME',
      'LILLY',
      'LORETTO',
      'MINERAL POINT',
      'NANTY GLO',
      'NEW FLORENCE',
      'PARKHILL',
      'PORTAGE',
      'REVLOC',
      'ROBINSON',
      'SAINT MICHAEL',
      'SALIX',
      'SEANOR',
      'SEWARD',
      'SIDMAN',
      'SOUTH FORK',
      'STRONGSTOWN',
      'SUMMERHILL',
      'TIRE HILL',
      'TWIN ROCKS',
      'VINTONDALE',
      'WILMORE',
      'WINDBER',
      'BUTLER',
      'BOYERS',
      'BRANCHTON',
      'BRUIN',
      'CABOT',
      'CALLERY',
      'CHICORA',
      'CONNOQUENESSING',
      'EAST BRADY',
      'EAST BUTLER',
      'EAU CLAIRE',
      'EVANS CITY',
      'FENELTON',
      'FORESTVILLE',
      'FOXBURG',
      'HARMONY',
      'HARRISVILLE',
      'HERMAN',
      'HILLIARDS',
      'KARNS CITY',
      'LYNDORA',
      'MARS',
      'NORTH WASHINGTON',
      'PARKER',
      'PETROLIA',
      'PORTERSVILLE',
      'PROSPECT',
      'RENFREW',
      'SAINT PETERSBURG',
      'SARVER',
      'SAXONBURG',
      'SLIPPERY ROCK',
      'TURKEY CITY',
      'VALENCIA',
      'WEST SUNBURY',
      'ZELIENOPLE',
      'CRANBERRY TWP',
      'NEW CASTLE',
      'ADAMSVILLE',
      'ATLANTIC',
      'BESSEMER',
      'CLARK',
      'CLARKS MILLS',
      'DARLINGTON',
      'EDINBURG',
      'ELLWOOD CITY',
      'ENON VALLEY',
      'FARRELL',
      'FOMBELL',
      'FREDONIA',
      'GREENVILLE',
      'GROVE CITY',
      'HADLEY',
      'HARTSTOWN',
      'HILLSVILLE',
      'JACKSON CENTER',
      'JAMESTOWN',
      'KOPPEL',
      'MERCER',
      'NEW BEDFORD',
      'NEW GALILEE',
      'NEW WILMINGTON',
      'PULASKI',
      'SANDY LALASKAE',
      'SHARON',
      'HERMITAGE',
      'SHARPSVILLE',
      'SHEALASKALEYVILLE',
      'STONEBORO',
      'TRANSFER',
      'VILLA MARIA',
      'VOLANT',
      'WAMPUM',
      'WEST MIDDLESEX',
      'WEST PITTSBURG',
      'WHEATLAND',
      'KITTANNING',
      'ADRIAN',
      'BEYER',
      'CADOGAN',
      'CALLENSBURG',
      'CLARION',
      'CLIMAX',
      'COOKSBURG',
      'COWANSVILLE',
      'CROWN',
      'CURLLSVILLE',
      'DAYTON',
      'DISTANT',
      'FAIRMOUNT CITY',
      'FISHER',
      'FORD CITY',
      'FORD CLIFF',
      'FREEPORT',
      'HAWTHORN',
      'KNOX',
      'LEEPER',
      'LIMESTONE',
      'LUCINDA',
      'MC GRANN',
      'MANORVILLE',
      'MARIENVILLE',
      'MAYPORT',
      'NEW BETHLEHEM',
      'NU MINE',
      'OALASKA RIDGE',
      'PLUMVILLE',
      'RIMERSBURG',
      'RURAL VALLEY',
      'SAGAMORE',
      'SEMINOLE',
      'SHIPPENVILLE',
      'SLIGO',
      'SMICKSBURG',
      'SNYDERSBURG',
      'STRATTANVILLE',
      'TEMPLETON',
      'VOWINCKEL',
      'WIDNOON',
      'WORTHINGTON',
      'YATESBORO',
      'OIL CITY',
      'CARLTON',
      'CHANDLERS VALLEY',
      'CLARENDON',
      'COCHRANTON',
      'CONNEAUT LALASKAE',
      'COOPERSTOWN',
      'CRANBERRY',
      'EAST HICKORY',
      'ENDEAVOR',
      'FRANKLIN',
      'FRYBURG',
      'GUYS MILLS',
      'HYDETOWN',
      'IRVINE',
      'KOSSUTH',
      'LICKINGVILLE',
      'LUDLOW',
      'MARBLE',
      'MEADVILLE',
      'PITTSFIELD',
      'PLEASANTVILLE',
      'POLK',
      'RENO',
      'ROUSEVILLE',
      'RUSSELL',
      'SENECA',
      'SHEFFIELD',
      'SUGAR GROVE',
      'TIDIOUTE',
      'TIONA',
      'TIONESTA',
      'TITUSVILLE',
      'TOWNVILLE',
      'TYLERSBURG',
      'UTICA',
      'VENUS',
      'WARREN',
      'WEST HICKORY',
      'YOUNGSVILLE',
      'CLINTONVILLE',
      'EMLENTON',
      'KENNERDELL',
      'LAMARTINE',
      'ALBION',
      'BEAR LALASKAE',
      'CAMBRIDGE SPRINGS',
      'CENTERVILLE',
      'COLUMBUS',
      'CONNEAUTVILLE',
      'CORRY',
      'CRANESVILLE',
      'EAST SPRINGFIELD',
      'EDINBORO',
      'ELGIN',
      'FAIRVIEW',
      'GARLAND',
      'GIRARD',
      'GRAND VALLEY',
      'HARBORCREEK',
      'HARMONSBURG',
      'LALASKAE CITY',
      'LINESVILLE',
      'MC KEAN',
      'MILL VILLAGE',
      'NORTH EAST',
      'NORTH SPRINGFIELD',
      'RICEVILLE',
      'SAEGERTOWN',
      'SPARTANSBURG',
      'SPRINGBORO',
      'SPRING CREEK',
      'UNION CITY',
      'VENANGO',
      'WATERFORD',
      'WATTSBURG',
      'WEST SPRINGFIELD',
      'ERIE',
      'ALTOONA',
      'ALEXANDRIA',
      'ASHVILLE',
      'BALASKAERS SUMMIT',
      'BECCARIA',
      'BELLWOOD',
      'BLANDBURG',
      'BRISBIN',
      'BROAD TOP',
      'CALVIN',
      'CASSVILLE',
      'CHEST SPRINGS',
      'CLAYSBURG',
      'COALPORT',
      'COUPON',
      'CRESSON',
      'CURRYVILLE',
      'DEFIANCE',
      'DUDLEY',
      'DUNCANSVILLE',
      'DYSART',
      'EAST FREEDOM',
      'ENTRIKEN',
      'FALLENTIMBER',
      'FLINTON',
      'GALLITZIN',
      'GLASGOW',
      'GLEN HOPE',
      'HASTINGS',
      'HESSTON',
      'HOLLIDAYSBURG',
      'HOPEWELL',
      'HOUTZDALE',
      'HUNTINGDON',
      'IMLER',
      'IRVONA',
      'JAMES CREEK',
      'LOYSBURG',
      'MC CONNELLSTOWN',
      'MADERA',
      'MARTINSBURG',
      'MORANN',
      'NEW ENTERPRISE',
      'NEWRY',
      'OSCEOLA MILLS',
      'OSTERBURG',
      'PATTON',
      'PETERSBURG',
      'QUEEN',
      'RAMEY',
      'RIDDLESBURG',
      'ROARING SPRING',
      'ROBERTSDALE',
      'SAINT BONIFACE',
      'SANDY RIDGE',
      'SAXTON',
      'SIX MILE RUN',
      'SMITHMILL',
      'SMOKERUN',
      'SPROUL',
      'SPRUCE CREEK',
      'TIPTON',
      'TODD',
      'TYRONE',
      'WATERFALL',
      'WELLS TANNERY',
      'WESTOVER',
      'WILLIAMSBURG',
      'WOOD',
      'WOODBURY',
      'BRADFORD',
      'AUSTIN',
      'CROSBY',
      'CUSTER CITY',
      'CYCLONE',
      'DERRICK CITY',
      'DE YOUNG',
      'DUKE CENTER',
      'EAST SMETHPORT',
      'ELDRED',
      'GIFFORD',
      'HAZEL HURST',
      'JAMES CITY',
      'KANE',
      'LEWIS RUN',
      'MOUNT JEWETT',
      'PORT ALLEGANY',
      'REW',
      'RIXFORD',
      'ROULETTE',
      'SHINGLEHOUSE',
      'SMETHPORT',
      'TURTLEPOINT',
      'WESTLINE',
      'STATE COLLEGE',
      'UNIVERSITY PARK',
      'AARONSBURG',
      'ALLPORT',
      'BEECH CREEK',
      'BELLEFONTE',
      'BIGLER',
      'BLANCHARD',
      'BOALSBURG',
      'CENTRE HALL',
      'CLARENCE',
      'CLEARFIELD',
      'COBURN',
      'CURWENSVILLE',
      'DRIFTING',
      'FLEMING',
      'FRENCHVILLE',
      'GLEN RICHEY',
      'GRAMPIAN',
      'GRASSFLAT',
      'HAWK RUN',
      'HOWARD',
      'HYDE',
      'JULIAN',
      'KARTHAUS',
      'KYLERTOWN',
      'LAMAR',
      'LANSE',
      'LECONTES MILLS',
      'LEMONT',
      'MADISONBURG',
      'MILESBURG',
      'MILLHEIM',
      'MINERAL SPRINGS',
      'MINGOVILLE',
      'MORRISDALE',
      'MOSHANNON',
      'MUNSON',
      'NEW MILLPORT',
      'OLANTA',
      'ORVISTON',
      'PENNSYLVANIA FURNACE',
      'PHILIPSBURG',
      'PINE GROVE MILLS',
      'PORT MATILDA',
      'POTTERSDALE',
      'REBERSBURG',
      'SHAWVILLE',
      'SNOW SHOE',
      'SPRING MILLS',
      'WALLACETON',
      'WARRIORS MARK',
      'WEST DECATUR',
      'WINBURNE',
      'WOODLAND',
      'WOODWARD',
      'WELLSBORO',
      'ALBA',
      'ARNOT',
      'BLOSSBURG',
      'COLUMBIA CROSS ROADS',
      'COUDERSPORT',
      'COVINGTON',
      'COWANESQUE',
      'ELKLAND',
      'GAINES',
      'GALETON',
      'GENESEE',
      'GILLETT',
      'GRANVILLE SUMMIT',
      'HARRISON VALLEY',
      'KNOXVILLE',
      'LAWRENCEVILLE',
      'LIBERTY',
      'MAINESBURG',
      'MANSFIELD',
      'MIDDLEBURY CENTER',
      'MILLERTON',
      'MILLS',
      'MORRIS',
      'MORRIS RUN',
      'NELSON',
      'OSCEOLA',
      'SABINSVILLE',
      'SYLVANIA',
      'TIOGA',
      'TROY',
      'ULYSSES',
      'WESTFIELD',
      'CAMP HILL',
      'ALLENSVILLE',
      'ANNVILLE',
      'BELLEVILLE',
      'BERRYSBURG',
      'BLAIN',
      'BOILING SPRINGS',
      'BOWMANSDALE',
      'BURNHAM',
      'CAMPBELLTOWN',
      'CARLISLE',
      'COCOLAMUS',
      'CORNWALL',
      'DALMATIA',
      'DAUPHIN',
      'DILLSBURG',
      'DUNCANNON',
      'EAST WATERFORD',
      'ELIZABETHTOWN',
      'ELIZABETHVILLE',
      'ELLIOTTSBURG',
      'ENOLA',
      'FREDERICKSBURG',
      'GRANTHAM',
      'GRANTVILLE',
      'GRANVILLE',
      'GRATZ',
      'GREEN PARK',
      'HALIFAX',
      'HERSHEY',
      'HIGHSPIRE',
      'HONEY GROVE',
      'HUMMELSTOWN',
      'ICKESBURG',
      'JONESTOWN',
      'KLEINFELTERSVILLE',
      'LANDISBURG',
      'LAWN',
      'LEBANON',
      'LEMOYNE',
      'LEWISTOWN',
      'LIVERPOOL',
      'LOYSVILLE',
      'LYKENS',
      'MC ALISTERVILLE',
      'MECHANICSBURG',
      'MC VEYTOWN',
      'MAPLETON DEPOT',
      'MARYSVILLE',
      'MATTAWANA',
      'MEXICO',
      'MIDDLETOWN',
      'MIFFLIN',
      'MIFFLINTOWN',
      'MILL CREEK',
      'MILLERSBURG',
      'MILLERSTOWN',
      'MILROY',
      'MOUNT GRETNA',
      'MOUNT HOLLY SPRINGS',
      'MOUNT UNION',
      'MYERSTOWN',
      'NEW BLOOMFIELD',
      'NEW BUFFALO',
      'NEW CUMBERLAND',
      'NEW GERMANTOWN',
      'NEW KINGSTOWN',
      'NEWMANSTOWN',
      'NEWPORT',
      'NEWTON HAMILTON',
      'OALASKALAND MILLS',
      'ONO',
      'PALMYRA',
      'PILLOW',
      'PLAINFIELD',
      'PORT ROYAL',
      'QUENTIN',
      'REEDSVILLE',
      'REXMONT',
      'RICHFIELD',
      'RICHLAND',
      'SCHAEFFERSTOWN',
      'SHERMANS DALE',
      'SUMMERDALE',
      'THOMPSONTOWN',
      'WICONISCO',
      'WILLIAMSTOWN',
      'YEAGERTOWN',
      'HARRISBURG',
      'CHAMBERSBURG',
      'AMBERSON',
      'ARTEMAS',
      'BIG COVE TANNERY',
      'BLAIRS MILLS',
      'BLUE RIDGE SUMMIT',
      'BURNT CABINS',
      'CONCORD',
      'DOYLESBURG',
      'DRY RUN',
      'FANNETTSBURG',
      'FAYETTEVILLE',
      'FORT LITTLETON',
      'FORT LOUDON',
      'GREENCASTLE',
      'HARRISONVILLE',
      'HUSTONTOWN',
      'LEMASTERS',
      'LURGAN',
      'MC CONNELLSBURG',
      'MARION',
      'MERCERSBURG',
      'MONT ALTO',
      'NEEDMORE',
      'NEELYTON',
      'NEWBURG',
      'NEWVILLE',
      'ORBISONIA',
      'ORRSTOWN',
      'PLEASANT HALL',
      'QUINCY',
      'ROCKHILL FURNACE',
      'ROUZERVILLE',
      'ROXBURY',
      'SAINT THOMAS',
      'SALTILLO',
      'SCOTLAND',
      'SHADE GAP',
      'SHADY GROVE',
      'SHIPPENSBURG',
      'SHIRLEYSBURG',
      'SOUTH MOUNTAIN',
      'SPRING RUN',
      'STATE LINE',
      'THREE SPRINGS',
      'UPPERSTRASBURG',
      'WALNUT BOTTOM',
      'WARFORDSBURG',
      'WAYNESBORO',
      'WILLIAMSON',
      'WILLOW HILL',
      'ZULLINGER',
      'ABBOTTSTOWN',
      'AIRVILLE',
      'ARENDTSVILLE',
      'ASPERS',
      'BENDERSVILLE',
      'BIGLERVILLE',
      'BROGUE',
      'CASHTOWN',
      'CODORUS',
      'CRALEY',
      'DALLASTOWN',
      'DELTA',
      'DOVER',
      'EAST BERLIN',
      'EAST PROSPECT',
      'EMIGSVILLE',
      'ETTERS',
      'FAIRFIELD',
      'FAWN GROVE',
      'FELTON',
      'FRANKLINTOWN',
      'GARDNERS',
      'GETTYSBURG',
      'GLEN ROCK',
      'GLENVILLE',
      'HANOVER',
      'IDAVILLE',
      'LEWISBERRY',
      'LITTLESTOWN',
      'LOGANVILLE',
      'MC KNIGHTSTOWN',
      'MC SHERRYSTOWN',
      'MANCHESTER',
      'MOUNT WOLF',
      'NEW FREEDOM',
      'NEW OXFORD',
      'NEW PARK',
      'ORRTANNA',
      'PORTERS SIDELING',
      'RAILROAD',
      'RED LION',
      'ROSSVILLE',
      'SEVEN VALLEYS',
      'SHREWSBURY',
      'SPRING GROVE',
      'STEWARTSTOWN',
      'THOMASVILLE',
      'WELLSVILLE',
      'WINDSOR',
      'WRIGHTSVILLE',
      'YORK HAVEN',
      'YORK NEW SALEM',
      'YORK SPRINGS',
      'PEACH GLEN',
      'YORK',
      'ALASKARON',
      'BAINBRIDGE',
      'BART',
      'BAUSMAN',
      'BIRD IN HAND',
      'BLUE BALL',
      'BOWMANSVILLE',
      'BROWNSTOWN',
      'CHRISTIANA',
      'COLUMBIA',
      'CONESTOGA',
      'DENVER',
      'DRUMORE',
      'EAST EARL',
      'EAST PETERSBURG',
      'ELM',
      'EPHRATA',
      'GAP',
      'GOODVILLE',
      'GORDONVILLE',
      'HOLTWOOD',
      'HOPELAND',
      'INTERCOURSE',
      'KINZERS',
      'KIRKWOOD',
      'LAMPETER',
      'LANDISVILLE',
      'LEOLA',
      'LITITZ',
      'MANHEIM',
      'MARIETTA',
      'MARTINDALE',
      'MAYTOWN',
      'MILLERSVILLE',
      'MOUNT JOY',
      'MOUNTVILLE',
      'NARVON',
      'NEW HOLLAND',
      'NEW PROVIDENCE',
      'PARADISE',
      'PEACH BOTTOM',
      'PENRYN',
      'PEQUEA',
      'QUARRYVILLE',
      'REAMSTOWN',
      'REFTON',
      'REINHOLDS',
      'RHEEMS',
      'RONKS',
      'SILVER SPRING',
      'SMOKETOWN',
      'SOUDERSBURG',
      'STEVENS',
      'STRASBURG',
      'TALMAGE',
      'TERRE HILL',
      'WASHINGTON BORO',
      'WEST WILLOW',
      'WILLOW STREET',
      'WITMER',
      'LANCASTER',
      'WILLIAMSPORT',
      'ANTES FORT',
      'AVIS',
      'BODINES',
      'JERSEY SHORE',
      'CANTON',
      'CASTANEA',
      'CEDAR RUN',
      'COGAN STATION',
      'CROSS FORK',
      'DEWART',
      'EAGLES MERE',
      'GROVER',
      'HUGHESVILLE',
      'HYNER',
      'JERSEY MILLS',
      'LAIRDSVILLE',
      'LEROY',
      'LINDEN',
      'LOCK HAVEN',
      'LOGANTON',
      'MC ELHATTAN',
      'MC EWENSVILLE',
      'MACKEYVILLE',
      'MILL HALL',
      'MONTGOMERY',
      'MONTOURSVILLE',
      'MUNCY',
      'MUNCY VALLEY',
      'NISBET',
      'NORTH BEND',
      'PICTURE ROCKS',
      'RALSTON',
      'RENOVO',
      'ROARING BRANCH',
      'SALONA',
      'SHUNK',
      'SLATE RUN',
      'TROUT RUN',
      'TURBOTVILLE',
      'TYLERSVILLE',
      'UNITYVILLE',
      'WATERVILLE',
      'WATSONTOWN',
      'WESTPORT',
      'WOOLRICH',
      'SUNBURY',
      'ALLENWOOD',
      'BEAVER SPRINGS',
      'BEAVERTOWN',
      'BENTON',
      'BLOOMSBURG',
      'CATAWISSA',
      'DANVILLE',
      'DORNSIFE',
      'ELYSBURG',
      'EXCELSIOR',
      'FREEBURG',
      'GOWEN CITY',
      'HARTLETON',
      'HERNDON',
      'HUMMELS WHARF',
      'MARION HEIGHTS',
      'KREAMER',
      'KULPMONT',
      'LAURELTON',
      'LECK KILL',
      'LEWISBURG',
      'LIGHT STREET',
      'LOCUST GAP',
      'MC CLURE',
      'MIDDLEBURG',
      'MIFFLINBURG',
      'MILLMONT',
      'MILLVILLE',
      'MILTON',
      'MONTANDON',
      'MOUNT CARMEL',
      'MOUNT PLEASANT MILLS',
      'NEW BERLIN',
      'NEW COLUMBIA',
      'NORTHUMBERLAND',
      'NUMIDIA',
      'ORANGEVILLE',
      'PAXINOS',
      'PAXTONVILLE',
      'PENNS CREEK',
      'PORT TREVORTON',
      'POTTS GROVE',
      'COAL TOWNSHIP',
      'REBUCK',
      'RIVERSIDE',
      'SELINSGROVE',
      'SHAMOKIN',
      'SHAMOKIN DAM',
      'SNYDERTOWN',
      'STILLWATER',
      'SWENGEL',
      'TREVORTON',
      'TROXELVILLE',
      'VICKSBURG',
      'WASHINGTONVILLE',
      'WEIKERT',
      'WEST MILTON',
      'WHITE DEER',
      'WILBURTON',
      'WINFIELD',
      'POTTSVILLE',
      'ARISTES',
      'ASHLAND',
      'AUBURN',
      'BRANCHDALE',
      'BROCKTON',
      'CENTRALIA',
      'CRESSONA',
      'CUMBOLA',
      'FRACKVILLE',
      'FRIEDENSBURG',
      'GILBERTON',
      'GIRARDVILLE',
      'GORDON',
      'HEGINS',
      'KLINGERSTOWN',
      'LANDINGVILLE',
      'LAVELLE',
      'LLEWELLYN',
      'LOCUSTDALE',
      'LOST CREEK',
      'MAHANOY CITY',
      'MAHANOY PLANE',
      'MAR LIN',
      'MARY D',
      'MIDDLEPORT',
      'MINERSVILLE',
      'MUIR',
      'NEW PHILADELPHIA',
      'NEW RINGGOLD',
      'ORWIGSBURG',
      'PINE GROVE',
      'PITMAN',
      'PORT CARBON',
      'RAVINE',
      'RINGTOWN',
      'SACRAMENTO',
      'SAINT CLAIR',
      'SCHUYLKILL HAVEN',
      'SELTZER',
      'SHENANDOAH',
      'SPRING GLEN',
      'SUMMIT STATION',
      'TOWER CITY',
      'TREMONT',
      'TUSCARORA',
      'VALLEY VIEW',
      'ZION GROVE',
      'LEHIGH VALLEY',
      'ACKERMANVILLE',
      'ALBURTIS',
      'AQUASHICOLA',
      'BANGOR',
      'BATH',
      'BETHLEHEM',
      'BOWMANSTOWN',
      'BREINIGSVILLE',
      'CATASAUQUA',
      'CENTER VALLEY',
      'CHERRYVILLE',
      'COOPERSBURG',
      'COPLAY',
      'DANIELSVILLE',
      'DURHAM',
      'EASTON',
      'EAST GREENVILLE',
      'EAST TEXAS',
      'EMMAUS',
      'FLICKSVILLE',
      'FOGELSVILLE',
      'WHITEHALL',
      'GERMANSVILLE',
      'GREEN LANE',
      'HELLERTOWN',
      'HEREFORD',
      'KUNKLETOWN',
      'LAURYS STATION',
      'LIMEPORT',
      'MACUNGIE',
      'MARTINS CREEK',
      'NAZARETH',
      'NEFFS',
      'NEW TRIPOLI',
      'NORTHAMPTON',
      'OLD ZIONSVILLE',
      'OREFIELD',
      'PALM',
      'PALMERTON',
      'PEN ARGYL',
      'PENNSBURG',
      'PERKIOMENVILLE',
      'RED HILL',
      'RIEGELSVILLE',
      'SCHNECKSVILLE',
      'SLATEDALE',
      'SLATINGTON',
      'SPRINGTOWN',
      'STOCKERTOWN',
      'SUMNEYTOWN',
      'TATAMY',
      'TREICHLERS',
      'TREXLERTOWN',
      'WALNUTPORT',
      'WIND GAP',
      'ZIONSVILLE',
      'ALLENTOWN',
      'HAZLETON',
      'ALBRIGHTSVILLE',
      'ANDREAS',
      'ASHFIELD',
      'BARNESVILLE',
      'BEAVER MEADOWS',
      'COALDALE',
      'CONYNGHAM',
      'DELANO',
      'DRIFTON',
      'DRUMS',
      'EBERVALE',
      'FREELAND',
      'HARLEIGH',
      'JIM THORPE',
      'JUNEDALE',
      'KELAYRES',
      'LANSFORD',
      'LATTIMER MINES',
      'LEHIGHTON',
      'MCADOO',
      'MILNESVILLE',
      'NESQUEHONING',
      'NUREMBERG',
      'ONEIDA',
      'PARDEESVILLE',
      'PARRYVILLE',
      'QUALASKAALASKAE',
      'ROCK GLEN',
      'SAINT JOHNS',
      'SHEPPTON',
      'SUGARLOAF',
      'SUMMIT HILL',
      'SYBERTSVILLE',
      'TAMAQUA',
      'TRESCKOW',
      'WEATHERLY',
      'WESTON',
      'EAST STROUDSBURG',
      'ANALOMINK',
      'BARTONSVILLE',
      'BRODHEADSVILLE',
      'BUCK HILL FALLS',
      'BUSHKILL',
      'CANADENSIS',
      'CRESCO',
      'DELAWARE WATER GAP',
      'DINGMANS FERRY',
      'EFFORT',
      'GILBERT',
      'HENRYVILLE',
      'KRESGEVILLE',
      'LONG POND',
      'MARSHALLS CREEK',
      'MATAMORAS',
      'MILFORD',
      'MILLRIFT',
      'MINISINK HILLS',
      'MOUNTAINHOME',
      'MOUNT BETHEL',
      'MOUNT POCONO',
      'POCONO SUMMIT',
      'POCONO LALASKAE',
      'POCONO LALASKAE PRESERVE',
      'POCONO MANOR',
      'POCONO PINES',
      'PORTLAND',
      'REEDERS',
      'SAYLORSBURG',
      'SCIOTA',
      'SCOTRUN',
      'SHAWNEE ON DELAWARE',
      'SKYTOP',
      'STROUDSBURG',
      'SWIFTWATER',
      'TAMIMENT',
      'TANNERSVILLE',
      'UNITY HOUSE',
      'ALDENVILLE',
      'ARCHBALD',
      'BEACH LALASKAE',
      'CARBONDALE',
      'CHINCHILLA',
      'CLARKS SUMMIT',
      'CLIFFORD',
      'DALTON',
      'DAMASCUS',
      'ELMHURST',
      'EQUINUNK',
      'FACTORYVILLE',
      'FLEETVILLE',
      'FOREST CITY',
      'GOULDSBORO',
      'GREELEY',
      'GREENTOWN',
      'HAMLIN',
      'HAWLEY',
      'HERRICK CENTER',
      'HONESDALE',
      'JERMYN',
      'JESSUP',
      'LACKAWAXEN',
      'LALASKAE ARIEL',
      'LALASKAE COMO',
      'LALASKAEVILLE',
      'LALASKAEWOOD',
      'LA PLUME',
      'LENOXVILLE',
      'MILANVILLE',
      'MOSCOW',
      'NEWFOUNDLAND',
      'NICHOLSON',
      'OLYPHANT',
      'ORSON',
      'PAUPACK',
      'PECKVILLE',
      'PLEASANT MOUNT',
      'POYNTELLE',
      'PRESTON PARK',
      'PROMPTON',
      'ROWLAND',
      'SHOHOLA',
      'SOUTH CANAAN',
      'SOUTH STERLING',
      'STARLIGHT',
      'STARRUCCA',
      'STERLING',
      'TAFTON',
      'THOMPSON',
      'TOBYHANNA',
      'TYLER HILL',
      'UNION DALE',
      'WAVERLY',
      'WAYMART',
      'WHITE MILLS',
      'SCRANTON',
      'MOOSIC',
      'TAYLOR',
      'OLD FORGE',
      'DICKSON CITY',
      'BEACH HAVEN',
      'BEAR CREEK',
      'BERWICK',
      'BLALASKAESLEE',
      'CAMBRA',
      'DALLAS',
      'DUSHORE',
      'FALLS',
      'FORKSVILLE',
      'GLEN LYON',
      'HARVEYS LALASKAE',
      'HILLSGROVE',
      'HUNLOCK CREEK',
      'HUNTINGTON MILLS',
      'LACEYVILLE',
      'LALASKAE HARMONY',
      'LALASKAE WINOLA',
      'LAPORTE',
      'LEHMAN',
      'LOPEZ',
      'MEHOOPANY',
      'MESHOPPEN',
      'MIFFLINVILLE',
      'MILDRED',
      'NANTICOKE',
      'NESCOPECK',
      'NOXEN',
      'PITTSTON',
      'DURYEA',
      'WYOMING',
      'PLYMOUTH',
      'RANSOM',
      'SHAWANESE',
      'SHICKSHINNY',
      'SWEET VALLEY',
      'TUNKHANNOCK',
      'WAPWALLOPEN',
      'WHITE HAVEN',
      'WILKES BARRE',
      'KINGSTON',
      'MOUNTAIN TOP',
      'SHAVERTOWN',
      'LUZERNE',
      'MONTROSE',
      'ATHENS',
      'BRACKNEY',
      'BROOKLYN',
      'BURLINGTON',
      'CAMPTOWN',
      'DIMOCK',
      'EAST SMITHFIELD',
      'FRIENDSVILLE',
      'GIBSON',
      'GREAT BEND',
      'HALLSTEAD',
      'HARFORD',
      'HOP BOTTOM',
      'JACKSON',
      'KINGSLEY',
      'LANESBORO',
      'LAWTON',
      'LE RAYSVILLE',
      'LITTLE MEADOWS',
      'MILAN',
      'MONROETON',
      'NEW ALBANY',
      'NEW MILFORD',
      'ROME',
      'RUSHVILLE',
      'SAYRE',
      'SOUTH GIBSON',
      'SOUTH MONTROSE',
      'SPRINGVILLE',
      'STEVENSVILLE',
      'SUGAR RUN',
      'SUSQUEHANNA',
      'TOWANDA',
      'ULSTER',
      'WARREN CENTER',
      'WYALUSING',
      'WYSOX',
      'DOYLESTOWN',
      'BEDMINSTER',
      'BLOOMING GLEN',
      'BUCKINGHAM',
      'CARVERSVILLE',
      'CHALFONT',
      'COLMAR',
      'DANBORO',
      'DUBLIN',
      'EARLINGTON',
      'ERWINNA',
      'FERNDALE',
      'FOREST GROVE',
      'FOUNTAINVILLE',
      'FRANCONIA',
      'FURLONG',
      'GARDENVILLE',
      'HILLTOWN',
      'HOLICONG',
      'JAMISON',
      'KINTNERSVILLE',
      'LAHASKA',
      'LINE LEXINGTON',
      'LUMBERVILLE',
      'MECHANICSVILLE',
      'MILFORD SQUARE',
      'MONTGOMERYVILLE',
      'NEW HOPE',
      'NEWTOWN',
      'OTTSVILLE',
      'PENNS PARK',
      'PERKASIE',
      'PINEVILLE',
      'PIPERSVILLE',
      'PLUMSTEADVILLE',
      'POINT PLEASANT',
      'QUALASKAERTOWN',
      'REVERE',
      'RICHBORO',
      'RICHLANDTOWN',
      'RUSHLAND',
      'SALFORD',
      'SALFORDVILLE',
      'SELLERSVILLE',
      'SILVERDALE',
      'SOLEBURY',
      'SOUDERTON',
      'SOUTHAMPTON',
      'SPINNERSTOWN',
      'TELFORD',
      'TRUMBAUERSVILLE',
      'TYLERSPORT',
      'UPPER BLACK EDDY',
      'WARMINSTER',
      'WARRINGTON',
      'WASHINGTON CROSSING',
      'WOXALL',
      'WYCOMBE',
      'ZIONHILL',
      'ABINGTON',
      'AMBLER',
      'ARDMORE',
      'BALA CYNWYD',
      'HUNTINGDON VALLEY',
      'BRISTOL',
      'BROOMALL',
      'BRYN ATHYN',
      'BRYN MAWR',
      'CHELTENHAM',
      'CHESTER',
      'ASTON',
      'BROOKHAVEN',
      'CHESTER HEIGHTS',
      'CLIFTON HEIGHTS',
      'PHILADELPHIA',
      'BENSALEM',
      'CROYDON',
      'CRUM LYNNE',
      'DARBY',
      'DRESHER',
      'DREXEL HILL',
      'ELKINS PARK',
      'EDGEMONT',
      'ESSINGTON',
      'FAIRLESS HILLS',
      'FLOURTOWN',
      'FOLCROFT',
      'FOLSOM',
      'FORT WASHINGTON',
      'GLADWYNE',
      'GLENOLDEN',
      'GLEN RIDDLE LIMA',
      'GLENSIDE',
      'GRADYVILLE',
      'HATBORO',
      'HAVERFORD',
      'HOLMES',
      'HORSHAM',
      'JENKINTOWN',
      'LANGHORNE',
      'LANSDOWNE',
      'LENNI',
      'FEASTERVILLE TREVOSE',
      'LEVITTOWN',
      'MARCUS HOOK',
      'MEDIA',
      'SPRINGFIELD',
      'MERION STATION',
      'MORRISVILLE',
      'MORTON',
      'NARBERTH',
      'NEWTOWN SQUARE',
      'NORWOOD',
      'ORELAND',
      'PROSPECT PARK',
      'RIDLEY PARK',
      'SHARON HILL',
      'WAYNE',
      'SWARTHMORE',
      'UPPER DARBY',
      'HAVERTOWN',
      'VILLANOVA',
      'WALLINGFORD',
      'WILLOW GROVE',
      'WOODLYN',
      'WYNCOTE',
      'WYNNEWOOD',
      'PAOLI',
      'ATGLEN',
      'AVONDALE',
      'BERWYN',
      'BRANDAMORE',
      'CHADDS FORD',
      'CHATHAM',
      'CHEYNEY',
      'COATESVILLE',
      'COCHRANVILLE',
      'CONCORDVILLE',
      'DEVON',
      'DOWNINGTOWN',
      'EXTON',
      'GLEN MILLS',
      'GLENMOORE',
      'HONEY BROOK',
      'IMMACULATA',
      'KELTON',
      'KEMBLESVILLE',
      'KENNETT SQUARE',
      'LANDENBERG',
      'LEWISVILLE',
      'LINCOLN UNIVERSITY',
      'LIONVILLE',
      'LYNDELL',
      'MALVERN',
      'MENDENHALL',
      'MODENA',
      'NEW LONDON',
      'NOTTINGHAM',
      'OXFORD',
      'PARKESBURG',
      'POCOPSON',
      'POMEROY',
      'SADSBURYVILLE',
      'STEELVILLE',
      'SUPLEE',
      'THORNDALE',
      'THORNTON',
      'TOUGHKENAMON',
      'UNIONVILLE',
      'WAGONTOWN',
      'WEST CHESTER',
      'WEST GROVE',
      'WESTTOWN',
      'SOUTHEASTERN',
      'NORRISTOWN',
      'BRIDGEPORT',
      'KING OF PRUSSIA',
      'AUDUBON',
      'EAGLEVILLE',
      'FAIRVIEW VILLAGE',
      'ARCOLA',
      'BIRCHRUNVILLE',
      'BLUE BELL',
      'CEDARS',
      'CHESTER SPRINGS',
      'COLLEGEVILLE',
      'CONSHOHOCKEN',
      'CREAMERY',
      'DEVAULT',
      'FREDERICK',
      'GWYNEDD',
      'GWYNEDD VALLEY',
      'HARLEYSVILLE',
      'HATFIELD',
      'KIMBERTON',
      'KULPSVILLE',
      'LAFAYETTE HILL',
      'LANSDALE',
      'LEDERACH',
      'MAINLAND',
      'MIQUON',
      'MONT CLARE',
      'NORTH WALES',
      'OALASKAS',
      'PARKER FORD',
      'PHOENIXVILLE',
      'PLYMOUTH MEETING',
      'POTTSTOWN',
      'ROYERSFORD',
      'SAINT PETERS',
      'SASSAMANSVILLE',
      'SCHWENKSVILLE',
      'SKIPPACK',
      'SPRING CITY',
      'SPRING HOUSE',
      'SPRING MOUNT',
      'UWCHLAND',
      'VALLEY FORGE',
      'WEST POINT',
      'WORCESTER',
      'ZIEGLERVILLE',
      'ADAMSTOWN',
      'BALLY',
      'BARTO',
      'BECHTELSVILLE',
      'BERNVILLE',
      'BETHEL',
      'BIRDSBORO',
      'BLANDON',
      'BOWERS',
      'BOYERTOWN',
      'CENTERPORT',
      'DOUGLASSVILLE',
      'EARLVILLE',
      'ELVERSON',
      'FLEETWOOD',
      'GEIGERTOWN',
      'GILBERTSVILLE',
      'HAMBURG',
      'KEMPTON',
      'KUTZTOWN',
      'LEESPORT',
      'LENHARTSVILLE',
      'LIMEKILN',
      'LYON STATION',
      'MAXATAWNY',
      'MERTZTOWN',
      'MOHNTON',
      'MOHRSVILLE',
      'MONOCACY STATION',
      'MORGANTOWN',
      'MOUNT AETNA',
      'NEW BERLINVILLE',
      'OLEY',
      'PINE FORGE',
      'PORT CLINTON',
      'REHRERSBURG',
      'ROBESONIA',
      'SHARTLESVILLE',
      'SHOEMALASKAERSVILLE',
      'STONY RUN',
      'STRAUSSTOWN',
      'TEMPLE',
      'TOPTON',
      'VIRGINVILLE',
      'WERNERSVILLE',
      'WOMELSDORF',
      'READING'
    ]
  },
  RI: {
    code: 'RI',
    name: 'Rhode Island',
    cities: [
      'ADAMSVILLE',
      'ALBION',
      'ASHAWAY',
      'BARRINGTON',
      'BLOCK ISLAND',
      'BRADFORD',
      'BRISTOL',
      'CAROLINA',
      'CHARLESTOWN',
      'CHEPACHET',
      'CLAYVILLE',
      'COVENTRY',
      'WEST GREENWICH',
      'EAST GREENWICH',
      'EXETER',
      'FISKEVILLE',
      'FORESTDALE',
      'FOSTER',
      'GLENDALE',
      'GREENE',
      'GREENVILLE',
      'HARMONY',
      'HARRISVILLE',
      'HOPE',
      'HOPE VALLEY',
      'HOPKINTON',
      'JAMESTOWN',
      'KENYON',
      'LITTLE COMPTON',
      'MANVILLE',
      'MAPLEVILLE',
      'NEWPORT',
      'MIDDLETOWN',
      'NORTH KINGSTOWN',
      'NORTH SCITUATE',
      'OALASKALAND',
      'PASCOAG',
      'PAWTUCKET',
      'CENTRAL FALLS',
      'CUMBERLAND',
      'LINCOLN',
      'PORTSMOUTH',
      'PRUDENCE ISLAND',
      'ROCKVILLE',
      'SAUNDERSTOWN',
      'SHANNOCK',
      'SLATERSVILLE',
      'SLOCUM',
      'TIVERTON',
      'WALASKAEFIELD',
      'KINGSTON',
      'NARRAGANSETT',
      'PEACE DALE',
      'WARREN',
      'WARWICK',
      'WESTERLY',
      'WEST KINGSTON',
      'WEST WARWICK',
      'WOOD RIVER JUNCTION',
      'WOONSOCKET',
      'NORTH SMITHFIELD',
      'WYOMING',
      'PROVIDENCE',
      'CRANSTON',
      'NORTH PROVIDENCE',
      'EAST PROVIDENCE',
      'RIVERSIDE',
      'RUMFORD',
      'SMITHFIELD',
      'JOHNSTON'
    ]
  },
  SC: {
    code: 'SC',
    name: 'South Carolina',
    cities: [
      'ALCOLU',
      'BALLENTINE',
      'BAMBERG',
      'BATESBURG',
      'BETHUNE',
      'BISHOPVILLE',
      'BLACKSTOCK',
      'BLAIR',
      'BLYTHEWOOD',
      'BOWMAN',
      'CAMDEN',
      'CAMERON',
      'CARLISLE',
      'CASSATT',
      'CAYCE',
      'CHAPIN',
      'CHAPPELLS',
      'COPE',
      'CORDOVA',
      'DALZELL',
      'DAVIS STATION',
      'DENMARK',
      'EASTOVER',
      'ELGIN',
      'ELLIOTT',
      'ELLOREE',
      'EUTAWVILLE',
      'GABLE',
      'GADSDEN',
      'GASTON',
      'GILBERT',
      'GREAT FALLS',
      'GREELEYVILLE',
      'HEATH SPRINGS',
      'HOLLY HILL',
      'HOPKINS',
      'HORATIO',
      'IRMO',
      'JENKINSVILLE',
      'KERSHAW',
      'LAMAR',
      'LEESVILLE',
      'LEXINGTON',
      'LIBERTY HILL',
      'LITTLE MOUNTAIN',
      'LUGOFF',
      'LYDIA',
      'LYNCHBURG',
      'EHRHARDT',
      'LODGE',
      'MC BEE',
      'MANNING',
      'MAYESVILLE',
      'MONETTA',
      'MONTICELLO',
      'NEESES',
      'NEWBERRY',
      'NEW ZION',
      'NORTH',
      'NORWAY',
      'OLANTA',
      'ORANGEBURG',
      'PEALASKA',
      'PELION',
      'PINEWOOD',
      'POMARIA',
      'PROSPERITY',
      'REMBERT',
      'RIDGE SPRING',
      'RIDGEWAY',
      'RION',
      'ROWESVILLE',
      'SAINT MATTHEWS',
      'SALLEY',
      'SALUDA',
      'SANTEE',
      'SARDINIA',
      'SILVERSTREET',
      'SPRINGFIELD',
      'STATE PARK',
      'SUMMERTON',
      'SUMTER',
      'SHAW A F B',
      'SWANSEA',
      'TIMMONSVILLE',
      'TURBEVILLE',
      'VANCE',
      'WAGENER',
      'WARD',
      'WEDGEFIELD',
      'WEST COLUMBIA',
      'WESTVILLE',
      'WHITE OALASKA',
      'WHITE ROCK',
      'WHITMIRE',
      'WINNSBORO',
      'COLUMBIA',
      'SPARTANBURG',
      'ARCADIA',
      'BUFFALO',
      'CAMPOBELLO',
      'CHESNEE',
      'CLIFTON',
      'CLINTON',
      'CONVERSE',
      'COWPENS',
      'CROSS ANCHOR',
      'CROSS HILL',
      'DRAYTON',
      'DUNCAN',
      'ENOREE',
      'FAIRFOREST',
      'FINGERVILLE',
      'GAFFNEY',
      'GLENDALE',
      'GRAMLING',
      'INMAN',
      'JOANNA',
      'JONESVILLE',
      'KINARDS',
      'LANDRUM',
      'LAURENS',
      'LOCKHART',
      'LYMAN',
      'MAYO',
      'MOORE',
      'MOUNTVILLE',
      'PACOLET',
      'PACOLET MILLS',
      'PAULINE',
      'REIDVILLE',
      'ROEBUCK',
      'STARTEX',
      'UNA',
      'UNION',
      'WATERLOO',
      'WELLFORD',
      'WHITE STONE',
      'WOODRUFF',
      'CHARLESTON',
      'CHARLESTON AFB',
      'NORTH CHARLESTON',
      'ADAMS RUN',
      'AWENDAW',
      'BETHERA',
      'BONNEAU',
      'BRANCHVILLE',
      'CANADYS',
      'CORDESVILLE',
      'COTTAGEVILLE',
      'CROSS',
      'DORCHESTER',
      'EDISTO ISLAND',
      'FOLLY BEACH',
      'GEORGETOWN',
      'GOOSE CREEK',
      'GREEN POND',
      'GROVER',
      'HARLEYVILLE',
      'HOLLYWOOD',
      'HUGER',
      'ISLE OF PALMS',
      'JACKSONBORO',
      'JAMESTOWN',
      'JOHNS ISLAND',
      'LADSON',
      'MC CLELLANVILLE',
      'MONCKS CORNER',
      'MOUNT PLEASANT',
      'PINEVILLE',
      'PINOPOLIS',
      'RAVENEL',
      'REEVESVILLE',
      'RIDGEVILLE',
      'ROUND O',
      'RUFFIN',
      'RUSSELLVILLE',
      'SAINT GEORGE',
      'SAINT STEPHEN',
      'SMOALASKAS',
      'SULLIVANS ISLAND',
      'SUMMERVILLE',
      'WADMALAW ISLAND',
      'WALTERBORO',
      'WILLIAMS',
      'FLORENCE',
      'ANDREWS',
      'AYNOR',
      'BENNETTSVILLE',
      'BLENHEIM',
      'CADES',
      'CENTENARY',
      'CHERAW',
      'CLIO',
      'CONWAY',
      'COWARD',
      'DARLINGTON',
      'DILLON',
      'EFFINGHAM',
      'FLOYD DALE',
      'FORK',
      'GALIVANTS FERRY',
      'GREEN SEA',
      'GRESHAM',
      'HAMER',
      'HARTSVILLE',
      'HEMINGWAY',
      'JOHNSONVILLE',
      'KINGSTREE',
      'LALASKAE CITY',
      'LALASKAE VIEW',
      'LANE',
      'LATTA',
      'LITTLE RIVER',
      'LITTLE ROCK',
      'LONGS',
      'LORIS',
      'MC COLL',
      'MARION',
      'MYRTLE BEACH',
      'MINTURN',
      'MULLINS',
      'MURRELLS INLET',
      'NESMITH',
      'NICHOLS',
      'NORTH MYRTLE BEACH',
      'PAMPLICO',
      'PATRICK',
      'PAWLEYS ISLAND',
      'RAINS',
      'SALTERS',
      'SCRANTON',
      'SELLERS',
      'SOCIETY HILL',
      'TATUM',
      'WALLACE',
      'GREENVILLE',
      'ABBEVILLE',
      'ANDERSON',
      'BELTON',
      'CALHOUN FALLS',
      'CENTRAL',
      'CLEMSON',
      'CLEVELAND',
      'CONESTEE',
      'DONALDS',
      'DUE WEST',
      'EASLEY',
      'FAIR PLAY',
      'FOUNTAIN INN',
      'GRAY COURT',
      'GREENWOOD',
      'GREER',
      'HODGES',
      'HONEA PATH',
      'IVA',
      'LA FRANCE',
      'LIBERTY',
      'LONG CREEK',
      'LOWNDESVILLE',
      'MARIETTA',
      'MAULDIN',
      'MOUNTAIN REST',
      'NEWRY',
      'NINETY SIX',
      'NORRIS',
      'PELZER',
      'PENDLETON',
      'PICKENS',
      'SENECA',
      'PIEDMONT',
      'RICHLAND',
      'SALEM',
      'SANDY SPRINGS',
      'SIMPSONVILLE',
      'SIX MILE',
      'SLATER',
      'STARR',
      'SUNSET',
      'TAMASSEE',
      'TAYLORS',
      'TIGERVILLE',
      'TOWNVILLE',
      'TRAVELERS REST',
      'WALHALLA',
      'WARE SHOALS',
      'WESTMINSTER',
      'WEST UNION',
      'WILLIAMSTON',
      'BLACKSBURG',
      'BOWLING GREEN',
      'CATAWBA',
      'CHESTER',
      'FORT MILL',
      'CHESTERFIELD',
      'CLOVER',
      'EDGEMOOR',
      'FORT LAWN',
      'HICKORY GROVE',
      'JEFFERSON',
      'LANCASTER',
      'LANDO',
      'MC CONNELLS',
      'MOUNT CROGHAN',
      'PAGELAND',
      'RICHBURG',
      'ROCK HILL',
      'RUBY',
      'SHARON',
      'SMYRNA',
      'VAN WYCK',
      'YORK',
      'AIKEN',
      'NEW ELLENTON',
      'ALLENDALE',
      'BARNWELL',
      'HILDA',
      'BATH',
      'BLACKVILLE',
      'BRADLEY',
      'CLARKS HILL',
      'CLEARWATER',
      'EDGEFIELD',
      'ELKO',
      'FAIRFAX',
      'GLOVERVILLE',
      'GRANITEVILLE',
      'JACKSON',
      'JOHNSTON',
      'LANGLEY',
      'MC CORMICK',
      'MARTIN',
      'MODOC',
      'MONTMORENCI',
      'MOUNT CARMEL',
      'NORTH AUGUSTA',
      'BEECH ISLAND',
      'OLAR',
      'PARKSVILLE',
      'PLUM BRANCH',
      'SYCAMORE',
      'TRENTON',
      'TROY',
      'ULMER',
      'VAUCLUSE',
      'WARRENVILLE',
      'WILLISTON',
      'WINDSOR',
      'BEAUFORT',
      'BLUFFTON',
      'BRUNSON',
      'COOSAWATCHIE',
      'CROCKETVILLE',
      'DALE',
      'DAUFUSKIE ISLAND',
      'EARLY BRANCH',
      'ESTILL',
      'SAINT HELENA ISLAND',
      'FURMAN',
      'GARNETT',
      'GIFFORD',
      'HAMPTON',
      'HILTON HEAD ISLAND',
      'HARDEEVILLE',
      'ISLANDTON',
      'LOBECO',
      'LURAY',
      'MILEY',
      'PINELAND',
      'PORT ROYAL',
      'RIDGELAND',
      'SCOTIA',
      'SEABROOK',
      'SHELDON',
      'TILLMAN',
      'VARNVILLE',
      'YEMASSEE'
    ]
  },
  SD: {
    code: 'SD',
    name: 'South Dakota',
    cities: [
      'ALCESTER',
      'AURORA',
      'BALTIC',
      'BERESFORD',
      'BRANDON',
      'BROOKINGS',
      'BURBANK',
      'CANISTOTA',
      'CANTON',
      'CENTERVILLE',
      'CHANCELLOR',
      'CHESTER',
      'COLMAN',
      'COLTON',
      'CROOKS',
      'DAVIS',
      'DELL RAPIDS',
      'EGAN',
      'ELK POINT',
      'ELKTON',
      'FAIRVIEW',
      'FLANDREAU',
      'FREEMAN',
      'GARRETSON',
      'GAYVILLE',
      'HARRISBURG',
      'HARTFORD',
      'HUDSON',
      'HUMBOLDT',
      'HURLEY',
      'IRENE',
      'JEFFERSON',
      'LENNOX',
      'LESTERVILLE',
      'LYONS',
      'MADISON',
      'MARION',
      'MECKLING',
      'MENNO',
      'MISSION HILL',
      'MONROE',
      'MONTROSE',
      'NORTH SIOUX CITY',
      'NUNDA',
      'OLDHAM',
      'OLIVET',
      'PARKER',
      'RAMONA',
      'RENNER',
      'ROWENA',
      'RUTLAND',
      'SALEM',
      'SCOTLAND',
      'SINAI',
      'SPRINGFIELD',
      'TABOR',
      'TEA',
      'TRENT',
      'TYNDALL',
      'UTICA',
      'VALLEY SPRINGS',
      'VERMILLION',
      'VIBORG',
      'VOLGA',
      'VOLIN',
      'WALASKAONDA',
      'WENTWORTH',
      'WINFRED',
      'WORTHING',
      'YANKTON',
      'SIOUX FALLS',
      'BUFFALO RIDGE',
      'WATERTOWN',
      'WAVERLY',
      'ARLINGTON',
      'ASTORIA',
      'BADGER',
      'BIG STONE CITY',
      'BRADLEY',
      'BRANDT',
      'BRISTOL',
      'BRUCE',
      'BRYANT',
      'CASTLEWOOD',
      'CLAIRE CITY',
      'CLARK',
      'CLEAR LALASKAE',
      'CORONA',
      'DE SMET',
      'EDEN',
      'ERWIN',
      'ESTELLINE',
      'FLORENCE',
      'GARDEN CITY',
      'GARY',
      'GOODWIN',
      'GRENVILLE',
      'HAYTI',
      'HAZEL',
      'HENRY',
      'HETLAND',
      'KRANZBURG',
      'LABOLT',
      'LALASKAE CITY',
      'LALASKAE NORDEN',
      'LALASKAE PRESTON',
      'MARVIN',
      'MILBANK',
      'NEW EFFINGTON',
      'ORTLEY',
      'PEEVER',
      'RAYMOND',
      'REVILLO',
      'ROSHOLT',
      'ROSLYN',
      'SISSETON',
      'SOUTH SHORE',
      'STOCKHOLM',
      'STRANDBURG',
      'SUMMIT',
      'TORONTO',
      'TWIN BROOKS',
      'VEBLEN',
      'VIENNA',
      'WALLACE',
      'WAUBAY',
      'WEBSTER',
      'WHITE',
      'WILLOW LALASKAE',
      'WILMOT',
      'MITCHELL',
      'ALEXANDRIA',
      'ALPENA',
      'ARMOUR',
      'ARTESIAN',
      'AVON',
      'BONESTEEL',
      'BRIDGEWATER',
      'CANOVA',
      'CARPENTER',
      'CARTHAGE',
      'CAVOUR',
      'CHAMBERLAIN',
      'CORSICA',
      'DANTE',
      'DELMONT',
      'DIMOCK',
      'EMERY',
      'ETHAN',
      'FAIRFAX',
      'FEDORA',
      'FORT THOMPSON',
      'FULTON',
      'GANN VALLEY',
      'GEDDES',
      'HARRISON',
      'HIGHMORE',
      'STEPHAN',
      'HITCHCOCK',
      'HOWARD',
      'HURON',
      'IROQUOIS',
      'KAYLOR',
      'KIMBALL',
      'LALASKAE ANDES',
      'RAVINIA',
      'LANE',
      'LETCHER',
      'MARTY',
      'MILLER',
      'MOUNT VERNON',
      'NEW HOLLAND',
      'OACOMA',
      'PARKSTON',
      'PICKSTOWN',
      'PLANKINTON',
      'PLATTE',
      'PUKWANA',
      'REE HEIGHTS',
      'SAINT LAWRENCE',
      'SPENCER',
      'STICKNEY',
      'TRIPP',
      'VIRGIL',
      'WAGNER',
      'WESSINGTON',
      'WESSINGTON SPRINGS',
      'WHITE LALASKAE',
      'WOLSEY',
      'WOONSOCKET',
      'YALE',
      'ABERDEEN',
      'ALASKAASKA',
      'AMHERST',
      'ANDOVER',
      'ASHTON',
      'BARNARD',
      'BATH',
      'BOWDLE',
      'BRENTFORD',
      'BRITTON',
      'CLAREMONT',
      'COLUMBIA',
      'CONDE',
      'CRESBARD',
      'DOLAND',
      'EUREKA',
      'FAULKTON',
      'FERNEY',
      'FRANKFORT',
      'FREDERICK',
      'GETTYSBURG',
      'GROTON',
      'HECLA',
      'HOSMER',
      'HOUGHTON',
      'HOVEN',
      'IPSWICH',
      'JAVA',
      'LANGFORD',
      'LEBANON',
      'LEOLA',
      'LONGLALASKAE',
      'MANSFIELD',
      'MELLETTE',
      'MINA',
      'NORTHVILLE',
      'ONALASKAA',
      'ORIENT',
      'PIERPONT',
      'REDFIELD',
      'ROCKHAM',
      'ROSCOE',
      'SELBY',
      'SENECA',
      'STRATFORD',
      'TOLSTOY',
      'TULARE',
      'TURTON',
      'WARNER',
      'WESTPORT',
      'PIERRE',
      'AGAR',
      'BELVIDERE',
      'BLUNT',
      'BURKE',
      'CARTER',
      'COLOME',
      'DALLAS',
      'DRAPER',
      'FORT PIERRE',
      'GREGORY',
      'HAMILL',
      'HARROLD',
      'HAYES',
      'HERRICK',
      'HOLABIRD',
      'IDEAL',
      'IONA',
      'KADOKA',
      'KENNEBEC',
      'LONG VALLEY',
      'LOWER BRULE',
      'MARTIN',
      'MIDLAND',
      'MILESVILLE',
      'MISSION',
      'MISSION RIDGE',
      'MURDO',
      'NORRIS',
      'OKATON',
      'OKREEK',
      'ONIDA',
      'PARMELEE',
      'PHILIP',
      'PRESHO',
      'RELIANCE',
      'ROSEBUD',
      'SAINT CHARLES',
      'SAINT FRANCIS',
      'TUTHILL',
      'VIVIAN',
      'WANBLEE',
      'WEWELA',
      'WHITE RIVER',
      'WINNER',
      'WITTEN',
      'WOOD',
      'MOBRIDGE',
      'BISON',
      'BULLHEAD',
      'CHERRY CREEK',
      'DUPREE',
      'EAGLE BUTTE',
      'FAITH',
      'FIRESTEEL',
      'GLAD VALLEY',
      'GLENCROSS',
      'GLENHAM',
      'HERREID',
      'ISABEL',
      'KELDRON',
      'LANTRY',
      'LEMMON',
      'LITTLE EAGLE',
      'LODGEPOLE',
      'MC INTOSH',
      'MC LAUGHLIN',
      'MAHTO',
      'MEADOW',
      'MORRISTOWN',
      'MOUND CITY',
      'PARADE',
      'POLLOCK',
      'PRAIRIE CITY',
      'RALPH',
      'REVA',
      'RIDGEVIEW',
      'SHADEHILL',
      'TIMBER LALASKAE',
      'TRAIL CITY',
      'WALASKAPALA',
      'WALKER',
      'WATAUGA',
      'WHITEHORSE',
      'RAPID CITY',
      'ELLSWORTH AFB',
      'ALLEN',
      'BATESLAND',
      'BELLE FOURCHE',
      'BLACK HAWK',
      'BOX ELDER',
      'BUFFALO',
      'BUFFALO GAP',
      'CAMP CROOK',
      'CAPUTA',
      'CREIGHTON',
      'CUSTER',
      'DEADWOOD',
      'EDGEMONT',
      'ELM SPRINGS',
      'ENNING',
      'FAIRBURN',
      'FORT MEADE',
      'FRUITDALE',
      'HERMOSA',
      'HILL CITY',
      'HOT SPRINGS',
      'HOWES',
      'INTERIOR',
      'KEYSTONE',
      'KYLE',
      'LEAD',
      'LUDLOW',
      'MANDERSON',
      'MUD BUTTE',
      'NEMO',
      'NEWELL',
      'NEW UNDERWOOD',
      'NISLAND',
      'OELRICHS',
      'OGLALA',
      'OPAL',
      'ORAL',
      'OWANKA',
      'PIEDMONT',
      'PINE RIDGE',
      'PORCUPINE',
      'PRINGLE',
      'PROVO',
      'QUINN',
      'REDIG',
      'RED OWL',
      'ROCHFORD',
      'SAINT ONGE',
      'SCENIC',
      'SMITHWICK',
      'SPEARFISH',
      'STURGIS',
      'UNION CENTER',
      'VALE',
      'WALL',
      'WASTA',
      'WHITE OWL',
      'WHITEWOOD',
      'WOUNDED KNEE'
    ]
  },
  TN: {
    code: 'TN',
    name: 'Tennessee',
    cities: [
      'ADAMS',
      'ANTIOCH',
      'ALEXANDRIA',
      'ARRINGTON',
      'ASHLAND CITY',
      'AUBURNTOWN',
      'BEECHGROVE',
      'BELFAST',
      'BELL BUCKLE',
      'BETHPAGE',
      'BIG ROCK',
      'BRENTWOOD',
      'BON AQUA',
      'BRADYVILLE',
      'BUMPUS MILLS',
      'BURNS',
      'CARTHAGE',
      'CASTALIAN SPRINGS',
      'CEDAR HILL',
      'CENTERVILLE',
      'CHAPEL HILL',
      'CHAPMANSBORO',
      'CHARLOTTE',
      'CHRISTIANA',
      'CLARKSVILLE',
      'COLLEGE GROVE',
      'CORNERSVILLE',
      'COTTONTOWN',
      'CROSS PLAINS',
      'CUMBERLAND CITY',
      'CUMBERLAND FURNACE',
      'CUNNINGHAM',
      'DICKSON',
      'DIXON SPRINGS',
      'DOVER',
      'DOWELLTOWN',
      'EAGLEVILLE',
      'ERIN',
      'FAIRVIEW',
      'FOSTERVILLE',
      'FRANKLIN',
      'GALLATIN',
      'GOODLETTSVILLE',
      'GLADEVILLE',
      'GREENBRIER',
      'HARTSVILLE',
      'HENDERSONVILLE',
      'HERMITAGE',
      'HURRICANE MILLS',
      'INDIAN MOUND',
      'JOELTON',
      'KINGSTON SPRINGS',
      'LAFAYETTE',
      'LASCASSAS',
      'LA VERGNE',
      'LEBANON',
      'LEWISBURG',
      'LIBERTY',
      'LINDEN',
      'LOBELVILLE',
      'LYLES',
      'MC EWEN',
      'MC MINNVILLE',
      'MADISON',
      'MILTON',
      'MITCHELLVILLE',
      'MOUNT JULIET',
      'MURFREESBORO',
      'NEW JOHNSONVILLE',
      'NOLENSVILLE',
      'NORENE',
      'NUNNELLY',
      'OLD HICKORY',
      'ONLY',
      'ORLINDA',
      'PALMYRA',
      'PEGRAM',
      'PETERSBURG',
      'PLEASANT SHADE',
      'PLEASANT VIEW',
      'PLEASANTVILLE',
      'PORTLAND',
      'READYVILLE',
      'RED BOILING SPRINGS',
      'RIDDLETON',
      'RIDGETOP',
      'ROCKVALE',
      'SAINT BETHLEHEM',
      'SHELBYVILLE',
      'SLAYDEN',
      'SMITHVILLE',
      'SMYRNA',
      'SOUTHSIDE',
      'SPRINGFIELD',
      'SPRING HILL',
      'STEWART',
      'TENNESSEE RIDGE',
      'THOMPSONS STATION',
      'UNIONVILLE',
      'VANLEER',
      'WARTRACE',
      'WATERTOWN',
      'WAVERLY',
      'WESTMORELAND',
      'WHITE BLUFF',
      'WHITE HOUSE',
      'WHITES CREEK',
      'WOODBURY',
      'WOODLAWN',
      'NASHVILLE',
      'ALTAMONT',
      'APISON',
      'ATHENS',
      'BALASKAEWELL',
      'BEERSHEBA SPRINGS',
      'BELVIDERE',
      'BENTON',
      'BIRCHWOOD',
      'CALHOUN',
      'CHARLESTON',
      'CLEVELAND',
      'COALMONT',
      'COKERCREEK',
      'COLLEGEDALE',
      'CONASAUGA',
      'COPPERHILL',
      'COWAN',
      'DAYTON',
      'DECATUR',
      'DECHERD',
      'DELANO',
      'DUCKTOWN',
      'DUNLAP',
      'ELORA',
      'ENGLEWOOD',
      'ESTILL SPRINGS',
      'ETOWAH',
      'EVENSVILLE',
      'FARNER',
      'FAYETTEVILLE',
      'FLINTVILLE',
      'GEORGETOWN',
      'GRANDVIEW',
      'GRAYSVILLE',
      'GRUETLI LAAGER',
      'GUILD',
      'HARRISON',
      'HILLSBORO',
      'HIXSON',
      'HUNTLAND',
      'JASPER',
      'KELSO',
      'MANCHESTER',
      'LOOKOUT MOUNTAIN',
      'LUPTON CITY',
      'LYNCHBURG',
      'MC DONALD',
      'MADISONVILLE',
      'MONTEAGLE',
      'MORRISON',
      'MULBERRY',
      'NORMANDY',
      'OCOEE',
      'OLDFORT',
      'OOLTEWAH',
      'PALMER',
      'PELHAM',
      'PIKEVILLE',
      'RELIANCE',
      'RICEVILLE',
      'SAINT ANDREWS',
      'SALE CREEK',
      'SEQUATCHIE',
      'SEWANEE',
      'SHERWOOD',
      'SIGNAL MOUNTAIN',
      'SMARTT',
      'SODDY DAISY',
      'SOUTH PITTSBURG',
      'SPRING CITY',
      'SUMMITVILLE',
      'TELLICO PLAINS',
      'TRACY CITY',
      'TULLAHOMA',
      'ARNOLD AFB',
      'TURTLETOWN',
      'VIOLA',
      'WATTS BAR DAM',
      'WHITESIDE',
      'WHITWELL',
      'WINCHESTER',
      'CHATTANOOGA',
      'MEMPHIS',
      'JOHNSON CITY',
      'AFTON',
      'BLOUNTVILLE',
      'BLUFF CITY',
      'BRISTOL',
      'BUTLER',
      'CHUCKEY',
      'CHURCH HILL',
      'ELIZABETHTON',
      'MOUNT CARMEL',
      'ERWIN',
      'FALL BRANCH',
      'FLAG POND',
      'HAMPTON',
      'JONESBOROUGH',
      'KINGSPORT',
      'LAUREL BLOOMERY',
      'LIMESTONE',
      'MILLIGAN COLLEGE',
      'MOUNTAIN CITY',
      'MOUNTAIN HOME',
      'PINEY FLATS',
      'ROAN MOUNTAIN',
      'SHADY VALLEY',
      'TELFORD',
      'TRADE',
      'UNICOI',
      'WATAUGA',
      'ALCOA',
      'ANDERSONVILLE',
      'ARTHUR',
      'BEAN STATION',
      'BLAINE',
      'BRICEVILLE',
      'BULLS GAP',
      'BYBEE',
      'CARYVILLE',
      'CLAIRFIELD',
      'CLINTON',
      'COALFIELD',
      'CORRYTON',
      'COSBY',
      'CRAB ORCHARD',
      'CUMBERLAND GAP',
      'DANDRIDGE',
      'DEER LODGE',
      'DEL RIO',
      'DUFF',
      'EAGAN',
      'EIDSON',
      'ELGIN',
      'RUGBY',
      'FRIENDSVILLE',
      'GATLINBURG',
      'GREENBACK',
      'GREENEVILLE',
      'HARRIMAN',
      'HARROGATE',
      'HARTFORD',
      'HEISKELL',
      'HELENWOOD',
      'HUNTSVILLE',
      'JACKSBORO',
      'JEFFERSON CITY',
      'JELLICO',
      'KINGSTON',
      'KODALASKA',
      'KYLES FORD',
      'LA FOLLETTE',
      'LALASKAE CITY',
      'LANCING',
      'LENOIR CITY',
      'LONE MOUNTAIN',
      'LOUDON',
      'LOUISVILLE',
      'LOWLAND',
      'LUTTRELL',
      'MARYVILLE',
      'MASCOT',
      'MAYNARDVILLE',
      'MIDWAY',
      'MOHAWK',
      'MOORESBURG',
      'MORRISTOWN',
      'MOSHEIM',
      'NEWCOMB',
      'NEW MARKET',
      'NEWPORT',
      'NEW TAZEWELL',
      'NIOTA',
      'NORRIS',
      'OALASKADALE',
      'OALASKA RIDGE',
      'OLIVER SPRINGS',
      'ONEIDA',
      'OZONE',
      'PARROTTSVILLE',
      'PETROS',
      'PHILADELPHIA',
      'PIONEER',
      'POWDER SPRINGS',
      'POWELL',
      'PRUDEN',
      'ROBBINS',
      'ROCKFORD',
      'ROCKWOOD',
      'ROGERSVILLE',
      'RUSSELLVILLE',
      'RUTLEDGE',
      'SEVIERVILLE',
      'PIGEON FORGE',
      'SEYMOUR',
      'SHARPS CHAPEL',
      'SHAWANEE',
      'SNEEDVILLE',
      'SPEEDWELL',
      'STRAWBERRY PLAINS',
      'SUNBRIGHT',
      'SURGOINSVILLE',
      'SWEETWATER',
      'TALBOTT',
      'TALLASSEE',
      'TAZEWELL',
      'TEN MILE',
      'THORN HILL',
      'TOWNSEND',
      'VONORE',
      'WALLAND',
      'WARTBURG',
      'WASHBURN',
      'WHITE PINE',
      'WHITESBURG',
      'WINFIELD',
      'KNOXVILLE',
      'ALAMO',
      'ARLINGTON',
      'ATOKA',
      'BELLS',
      'BOGOTA',
      'BOLIVAR',
      'BRADEN',
      'BRIGHTON',
      'BROWNSVILLE',
      'BRUNSWICK',
      'BURLISON',
      'CORDOVA',
      'COLLIERVILLE',
      'COVINGTON',
      'CROCKETT MILLS',
      'DRUMMONDS',
      'DYERSBURG',
      'EADS',
      'ELLENDALE',
      'FINLEY',
      'FRIENDSHIP',
      'GALLAWAY',
      'GATES',
      'GRAND JUNCTION',
      'HALLS',
      'HENNING',
      'HICKORY VALLEY',
      'HORNSBY',
      'LACONIA',
      'LA GRANGE',
      'LENOX',
      'MACON',
      'MASON',
      'MAURY CITY',
      'MIDDLETON',
      'MILLINGTON',
      'MISTON',
      'MOSCOW',
      'MUNFORD',
      'NEWBERN',
      'OALASKALAND',
      'POCAHONTAS',
      'RIPLEY',
      'ROSSVILLE',
      'SAULSBURY',
      'SOMERVILLE',
      'STANTON',
      'TIGRETT',
      'TIPTON',
      'WHITEVILLE',
      'WILLISTON',
      'WYNNBURG',
      'TIPTONVILLE',
      'RIDGELY',
      'GERMANTOWN',
      'MC KENZIE',
      'ATWOOD',
      'BIG SANDY',
      'BUCHANAN',
      'COMO',
      'COTTAGE GROVE',
      'DRESDEN',
      'DUKEDOM',
      'GLEASON',
      'GREENFIELD',
      'HENRY',
      'HORNBEALASKA',
      'KENTON',
      'MC LEMORESVILLE',
      'MANSFIELD',
      'MARTIN',
      'OBION',
      'PALMERSVILLE',
      'PARIS',
      'PURYEAR',
      'RIVES',
      'SAMBURG',
      'SHARON',
      'SPRINGVILLE',
      'SOUTH FULTON',
      'TREZEVANT',
      'TRIMBLE',
      'TROY',
      'UNION CITY',
      'WOODLAND MILLS',
      'JACKSON',
      'ADAMSVILLE',
      'BATH SPRINGS',
      'BEECH BLUFF',
      'BETHEL SPRINGS',
      'BRADFORD',
      'BRUCETON',
      'BUENA VISTA',
      'CAMDEN',
      'CEDAR GROVE',
      'CLARKSBURG',
      'COUNCE',
      'CRUMP',
      'DARDEN',
      'DECATURVILLE',
      'DYER',
      'EATON',
      'ENVILLE',
      'EVA',
      'FINGER',
      'FRUITVALE',
      'GADSDEN',
      'GIBSON',
      'GUYS',
      'HENDERSON',
      'HOLLADAY',
      'HOLLOW ROCK',
      'HUMBOLDT',
      'HUNTINGDON',
      'HURON',
      'IDLEWILD',
      'JACKS CREEK',
      'LAVINIA',
      'LEXINGTON',
      'LURAY',
      'MEDINA',
      'MEDON',
      'MICHIE',
      'MILAN',
      'MILLEDGEVILLE',
      'MORRIS CHAPEL',
      'OALASKAFIELD',
      'PARSONS',
      'PICKWICK DAM',
      'PINSON',
      'RAMER',
      'REAGAN',
      'RUTHERFORD',
      'SALTILLO',
      'SARDIS',
      'SAVANNAH',
      'SCOTTS HILL',
      'SELMER',
      'SHILOH',
      'SILERTON',
      'SPRING CREEK',
      'STANTONVILLE',
      'SUGAR TREE',
      'TOONE',
      'TRENTON',
      'WESTPORT',
      'WILDERSVILLE',
      'YORKVILLE',
      'YUMA',
      'DENMARK',
      'MERCER',
      'CHEWALLA',
      'COLUMBIA',
      'CLIFTON',
      'ARDMORE',
      'COLLINWOOD',
      'CULLEOKA',
      'CYPRESS INN',
      'DELLROSE',
      'DUCK RIVER',
      'ELKTON',
      'ETHRIDGE',
      'FIVE POINTS',
      'FRANKEWING',
      'GOODSPRING',
      'HAMPSHIRE',
      'HOHENWALD',
      'IRON CITY',
      'LAWRENCEBURG',
      'LEOMA',
      'LORETTO',
      'LUTTS',
      'LYNNVILLE',
      'MINOR HILL',
      'MOUNT PLEASANT',
      'OLIVEHILL',
      'PRIMM SPRINGS',
      'PROSPECT',
      'PULASKI',
      'SAINT JOSEPH',
      'SANTA FE',
      'SUMMERTOWN',
      'WAYNESBORO',
      'WESTPOINT',
      'WILLIAMSPORT',
      'TAFT',
      'COOKEVILLE',
      'ALLARDT',
      'ALLONS',
      'ALLRED',
      'ALPINE',
      'BAXTER',
      'BLOOMINGTON SPRINGS',
      'BRUSH CREEK',
      'BUFFALO VALLEY',
      'BYRDSTOWN',
      'CAMPAIGN',
      'CELINA',
      'CHESTNUT MOUND',
      'CLARKRANGE',
      'CRAWFORD',
      'CROSSVILLE',
      'JAMESTOWN',
      'DOYLE',
      'ELMWOOD',
      'GAINESBORO',
      'GORDONSVILLE',
      'GRANVILLE',
      'GRIMSLEY',
      'HICKMAN',
      'HILHAM',
      'LANCASTER',
      'LIVINGSTON',
      'MONROE',
      'MONTEREY',
      'MOSS',
      'PALL MALL',
      'PLEASANT HILL',
      'QUEBECK',
      'RICKMAN',
      'ROCK ISLAND',
      'SILVER POINT',
      'SPARTA',
      'SPENCER',
      'WALLING',
      'WHITLEYVILLE',
      'WILDER'
    ]
  },
  TX: {
    code: 'TX',
    name: 'Texas',
    cities: [
      'AUSTIN',
      'ADDISON',
      'ALLEN',
      'CARROLLTON',
      'CELINA',
      'IRVING',
      'COPPELL',
      'DENISON',
      'FLOWER MOUND',
      'PLANO',
      'LEWISVILLE',
      'ROWLETT',
      'ROCKWALL',
      'FRISCO',
      'GARLAND',
      'SACHSE',
      'GRAND PRAIRIE',
      'THE COLONY',
      'GUNTER',
      'LALASKAE DALLAS',
      'LITTLE ELM',
      'MC KINNEY',
      'POTTSBORO',
      'PROSPER',
      'RICHARDSON',
      'SHERMAN',
      'WESTON',
      'WYLIE',
      'BARDWELL',
      'BARRY',
      'CANTON',
      'CEDAR HILL',
      'CHATFIELD',
      'CORSICANA',
      'CRANDALL',
      'DESOTO',
      'DUNCANVILLE',
      'EDGEWOOD',
      'ELMO',
      'ENNIS',
      'COPEVILLE',
      'EUSTACE',
      'FERRIS',
      'FORNEY',
      'FRUITVALE',
      'FATE',
      'LANCASTER',
      'CADDO MILLS',
      'GRAND SALINE',
      'HUTCHINS',
      'KAUFMAN',
      'KEMP',
      'KERENS',
      'MABANK',
      'MALALASKAOFF',
      'MESQUITE',
      'PALMER',
      'POWELL',
      'RED OALASKA',
      'RICE',
      'ROSSER',
      'SCURRY',
      'SEAGOVILLE',
      'TERRELL',
      'TRINIDAD',
      'JOSEPHINE',
      'WAXAHACHIE',
      'LAVON',
      'WILLS POINT',
      'WILMER',
      'NEVADA',
      'SUNNYVALE',
      'ROYSE CITY',
      'DALLAS',
      'GREENVILLE',
      'PRINCETON',
      'ANNA',
      'ALBA',
      'ARTHUR CITY',
      'BAGWELL',
      'BAILEY',
      'BELLS',
      'BEN FRANKLIN',
      'BLOSSOM',
      'BOGATA',
      'BONHAM',
      'BRASHEAR',
      'BROOKSTON',
      'CAMPBELL',
      'CELESTE',
      'BLUE RIDGE',
      'CHICOTA',
      'CLARKSVILLE',
      'COMMERCE',
      'COMO',
      'COOPER',
      'CUMBY',
      'CUNNINGHAM',
      'DEPORT',
      'DETROIT',
      'DIKE',
      'DODD CITY',
      'ECTOR',
      'EMORY',
      'ENLOE',
      'FARMERSVILLE',
      'GOBER',
      'GOLDEN',
      'HONEY GROVE',
      'IVANHOE',
      'KLONDIKE',
      'LADONIA',
      'LALASKAE CREEK',
      'LEESBURG',
      'LEONARD',
      'LONE OALASKA',
      'MELISSA',
      'MOUNT PLEASANT',
      'MOUNT VERNON',
      'MERIT',
      'HOWE',
      'PARIS',
      'PATTONVILLE',
      'PECAN GAP',
      'PETTY',
      'PICKTON',
      'POINT',
      'POWDERLY',
      'QUINLAN',
      'RANDOLPH',
      'RAVENNA',
      'ROXTON',
      'SALTILLO',
      'SAVOY',
      'SCROGGINS',
      'SULPHUR BLUFF',
      'SULPHUR SPRINGS',
      'WESTMINSTER',
      'SUMNER',
      'TALCO',
      'TELEPHONE',
      'TOM BEAN',
      'TRENTON',
      'WHITEWRIGHT',
      'WINDOM',
      'WINFIELD',
      'WINNSBORO',
      'VAN ALSTYNE',
      'WOLFE CITY',
      'YANTIS',
      'TEXARKANA',
      'ANNONA',
      'ATLANTA',
      'AVERY',
      'BIVINS',
      'BLOOMBURG',
      'COOKVILLE',
      'DE KALB',
      'DOUGLASSVILLE',
      'HOOKS',
      'KILDARE',
      'LINDEN',
      'LODI',
      'MC LEOD',
      'MARIETTA',
      'MAUD',
      'NAPLES',
      'NASH',
      'NEW BOSTON',
      'OMAHA',
      'QUEEN CITY',
      'REDWATER',
      'SIMMS',
      'LONGVIEW',
      'AVINGER',
      'BECKVILLE',
      'CARTHAGE',
      'CASON',
      'CLAYTON',
      'DAINGERFIELD',
      'DE BERRY',
      'DIANA',
      'EASTON',
      'ELYSIAN FIELDS',
      'GARY',
      'GILMER',
      'GLADEWATER',
      'HALLSVILLE',
      'HARLETON',
      'HENDERSON',
      'HUGHES SPRINGS',
      'JEFFERSON',
      'JOINERVILLE',
      'JONESVILLE',
      'JUDSON',
      'KARNACK',
      'KILGORE',
      'LAIRD HILL',
      'LANEVILLE',
      'LONE STAR',
      'LONG BRANCH',
      'MARSHALL',
      'MINDEN',
      'MOUNT ENTERPRISE',
      'NEW LONDON',
      'ORE CITY',
      'OVERTON',
      'PANOLA',
      'PITTSBURG',
      'PRICE',
      'SCOTTSVILLE',
      'SELMAN CITY',
      'TATUM',
      'WASKOM',
      'WHITE OALASKA',
      'WOODLAWN',
      'TYLER',
      'ARP',
      'ATHENS',
      'BEN WHEELER',
      'BIG SANDY',
      'BROWNSBORO',
      'BULLARD',
      'CHANDLER',
      'CUNEY',
      'CUSHING',
      'FLINT',
      'FRANKSTON',
      'GALLATIN',
      'HAWKINS',
      'JACKSONVILLE',
      'LARUE',
      'LINDALE',
      'MAYDELLE',
      'MINEOLA',
      'MURCHISON',
      'NECHES',
      'NEW SUMMERFIELD',
      'POYNOR',
      'QUITMAN',
      'REKLAW',
      'RUSK',
      'SACUL',
      'TROUP',
      'VAN',
      'WHITEHOUSE',
      'WINONA',
      'PALESTINE',
      'BUFFALO',
      'CAYUGA',
      'CENTERVILLE',
      'CENTRALIA',
      'CROCKETT',
      'DONIE',
      'ELKHART',
      'FAIRFIELD',
      'GRAPELAND',
      'GROVETON',
      'JEWETT',
      'KENNARD',
      'KIRVIN',
      'LATEXO',
      'LEONA',
      'LOVELADY',
      'MIDWAY',
      'MONTALBA',
      'OALASKAWOOD',
      'PENNINGTON',
      'RATCLIFF',
      'STREETMAN',
      'TEAGUE',
      'TENNESSEE COLONY',
      'TRINITY',
      'WOODLALASKAE',
      'LUFKIN',
      'ALTO',
      'APPLE SPRINGS',
      'BON WIER',
      'BROADDUS',
      'BRONSON',
      'BROOKELAND',
      'BURKEVILLE',
      'CALL',
      'CAMDEN',
      'CENTER',
      'CHESTER',
      'CHIRENO',
      'COLMESNEIL',
      'CORRIGAN',
      'DIBOLL',
      'DOUCETTE',
      'DOUGLASS',
      'ETOILE',
      'GARRISON',
      'GENEVA',
      'HEMPHILL',
      'HUNTINGTON',
      'JASPER',
      'JOAQUIN',
      'KIRBYVILLE',
      'MARTINSVILLE',
      'MILAM',
      'MOSCOW',
      'NACOGDOCHES',
      'NEWTON',
      'PINELAND',
      'POLLOK',
      'SAN AUGUSTINE',
      'SHELBYVILLE',
      'TENAHA',
      'TIMPSON',
      'WELLS',
      'WIERGATE',
      'WODEN',
      'WOODVILLE',
      'ZAVALLA',
      'ARLINGTON',
      'ALEDO',
      'ALVARADO',
      'AZLE',
      'BEDFORD',
      'BOYD',
      'BURLESON',
      'CLEBURNE',
      'COLLEYVILLE',
      'CRESSON',
      'CROWLEY',
      'EULESS',
      'FORRESTON',
      'GLEN ROSE',
      'GODLEY',
      'GRANBURY',
      'GRANDVIEW',
      'GRAPEVINE',
      'HASLET',
      'HURST',
      'ITASCA',
      'JOSHUA',
      'KEENE',
      'KENNEDALE',
      'LILLIAN',
      'MANSFIELD',
      'MAYPEARL',
      'MIDLOTHIAN',
      'MILLSAP',
      'MINERAL WELLS',
      'NEMO',
      'NEWARK',
      'PARADISE',
      'RAINBOW',
      'RHOME',
      'SPRINGTOWN',
      'VENUS',
      'WEATHERFORD',
      'SOUTHLALASKAE',
      'RIO VISTA',
      'FORT WORTH',
      'HALTOM CITY',
      'NAVAL AIR STATION/ JRB',
      'NORTH RICHLAND HILLS',
      'DENTON',
      'ALVORD',
      'ARGYLE',
      'AUBREY',
      'BELLEVUE',
      'BOWIE',
      'COLLINSVILLE',
      'DECATUR',
      'ERA',
      'FORESTBURG',
      'GAINESVILLE',
      'KELLER',
      'GORDONVILLE',
      'GREENWOOD',
      'JUSTIN',
      'KRUM',
      'LINDSAY',
      'MONTAGUE',
      'MUENSTER',
      'MYRA',
      'NOCONA',
      'PILOT POINT',
      'PONDER',
      'RINGGOLD',
      'ROANOKE',
      'ROSSTON',
      'SADLER',
      'SAINT JO',
      'SANGER',
      'SLIDELL',
      'SOUTHMAYD',
      'SUNSET',
      'TIOGA',
      'VALLEY VIEW',
      'WHITESBORO',
      'WICHITA FALLS',
      'SHEPPARD AFB',
      'ARCHER CITY',
      'BLUEGROVE',
      'BURKBURNETT',
      'BYERS',
      'ELECTRA',
      'GOREE',
      'HARROLD',
      'HENRIETTA',
      'HOLLIDAY',
      'IOWA PARK',
      'KAMAY',
      'MEGARGEL',
      'MUNDAY',
      'NEWCASTLE',
      'OKLAUNION',
      'OLNEY',
      'PETROLIA',
      'SCOTLAND',
      'SEYMOUR',
      'VERNON',
      'WEINERT',
      'WINDTHORST',
      'STEPHENVILLE',
      'BRECKENRIDGE',
      'BRIDGEPORT',
      'BRYSON',
      'CADDO',
      'ALBANY',
      'CHICO',
      'BLANKET',
      'BLUFF DALE',
      'CARBON',
      'CARLTON',
      'CISCO',
      'DENNIS',
      'COMANCHE',
      'CROSS PLAINS',
      'DE LEON',
      'DESDEMONA',
      'DUBLIN',
      'EASTLAND',
      'GRAFORD',
      'GRAHAM',
      'ENERGY',
      'GORDON',
      'GORMAN',
      'GUSTINE',
      'HICO',
      'JACKSBORO',
      'JERMYN',
      'LOVING',
      'LINGLEVILLE',
      'LIPAN',
      'MINGUS',
      'MORAN',
      'MORGAN MILL',
      'OLDEN',
      'PALUXY',
      'PROCTOR',
      'PUTNAM',
      'RANGER',
      'RISING STAR',
      'SANTO',
      'SIDNEY',
      'STRAWN',
      'TOLAR',
      'SOUTH BEND',
      'THROCKMORTON',
      'PALO PINTO',
      'PEASTER',
      'PERRIN',
      'POOLVILLE',
      'WHITT',
      'WOODSON',
      'TEMPLE',
      'BARTLETT',
      'BELTON',
      'BUCKHOLTS',
      'BURLINGTON',
      'CAMERON',
      'COPPERAS COVE',
      'DAVILLA',
      'EDDY',
      'EVANT',
      'FLAT',
      'FLORENCE',
      'GATESVILLE',
      'GRANGER',
      'HAMILTON',
      'HEIDENHEIMER',
      'HOLLAND',
      'JARRELL',
      'JONESBORO',
      'KEMPNER',
      'KILLEEN',
      'HARKER HEIGHTS',
      'LAMPASAS',
      'LEON JUNCTION',
      'LITTLE RIVER',
      'MAYSFIELD',
      'MILANO',
      'MOODY',
      'MOUND',
      'NOLANVILLE',
      'OGLESBY',
      'PENDLETON',
      'POTTSVILLE',
      'PURMELA',
      'ROCKDALE',
      'ROGERS',
      'ROSEBUD',
      'SALADO',
      'SCHWERTNER',
      'TAYLOR',
      'THORNDALE',
      'THRALL',
      'TROY',
      'ABBOTT',
      'AQUILLA',
      'AVALON',
      'AXTELL',
      'BLOOMING GROVE',
      'BLUM',
      'BRANDON',
      'BREMOND',
      'BRUCEVILLE',
      'BYNUM',
      'CHILTON',
      'CHINA SPRING',
      'CLIFTON',
      'COOLIDGE',
      'COVINGTON',
      'CRANFILLS GAP',
      'CRAWFORD',
      'DAWSON',
      'ELM MOTT',
      'FROST',
      'GROESBECK',
      'HEWITT',
      'LAGUNA PARK',
      'HILLSBORO',
      'HUBBARD',
      'IREDELL',
      'IRENE',
      'ITALY',
      'KOPPERL',
      'KOSSE',
      'LEROY',
      'LORENA',
      'LOTT',
      'MC GREGOR',
      'MALONE',
      'MARLIN',
      'MART',
      'MERIDIAN',
      'MERTENS',
      'MEXIA',
      'MILFORD',
      'MORGAN',
      'MOUNT CALM',
      'OTTO',
      'PENELOPE',
      'PERRY',
      'PRAIRIE HILL',
      'PURDON',
      'REAGAN',
      'RICHLAND',
      'RIESEL',
      'ROSS',
      'SATIN',
      'TEHUACANA',
      'THORNTON',
      'VALLEY MILLS',
      'WALNUT SPRINGS',
      'WEST',
      'WHITNEY',
      'WORTHAM',
      'WACO',
      'WOODWAY',
      'BROWNWOOD',
      'EARLY',
      'ART',
      'BALLINGER',
      'BANGS',
      'BEND',
      'BRADY',
      'BROOKESMITH',
      'BURKETT',
      'CASTELL',
      'CHEROKEE',
      'COLEMAN',
      'DOOLE',
      'EDEN',
      'FORT MC KAVETT',
      'FREDONIA',
      'GOLDTHWAITE',
      'GOULDBUSK',
      'HEXT',
      'JUNCTION',
      'LOHN',
      'LOMETA',
      'LONDON',
      'LOWALASKAE',
      'MASON',
      'MAY',
      'MELVIN',
      'MENARD',
      'MILES',
      'MILLERSVIEW',
      'MULLIN',
      'NORTON',
      'PAINT ROCK',
      'PEAR VALLEY',
      'PONTOTOC',
      'PRIDDY',
      'RICHLAND SPRINGS',
      'ROCHELLE',
      'ROCKWOOD',
      'ROOSEVELT',
      'ROWENA',
      'SAN SABA',
      'SANTA ANNA',
      'STAR',
      'TALPA',
      'TELEGRAPH',
      'VALERA',
      'VALLEY SPRING',
      'VERIBEST',
      'VOCA',
      'VOSS',
      'ZEPHYR',
      'SAN ANGELO',
      'GOODFELLOW AFB',
      'BARNHART',
      'BIG LALASKAE',
      'BRONTE',
      'CARLSBAD',
      'CHRISTOVAL',
      'ELDORADO',
      'EOLA',
      'KNICKERBOCKER',
      'MERETA',
      'MERTZON',
      'OZONA',
      'ROBERT LEE',
      'SILVER',
      'SONORA',
      'STERLING CITY',
      'TENNYSON',
      'VANCOURT',
      'WALL',
      'WATER VALLEY',
      'HOUSTON',
      'CONROE',
      'NORTH HOUSTON',
      'MONTGOMERY',
      'WILLIS',
      'HUNTSVILLE',
      'HUMBLE',
      'ACE',
      'CLEVELAND',
      'COLDSPRING',
      'DALLARDSVILLE',
      'DOBBIN',
      'DODGE',
      'GOODRICH',
      'HUFFMAN',
      'HUFSMITH',
      'LEGGETT',
      'LIVINGSTON',
      'MAGNOLIA',
      'NEW CANEY',
      'NEW WAVERLY',
      'OALASKAHURST',
      'ONALASKA',
      'PINEHURST',
      'PLANTERSVILLE',
      'POINTBLANK',
      'PORTER',
      'RIVERSIDE',
      'ROMAYOR',
      'RYE',
      'SHEPHERD',
      'SPLENDORA',
      'SPRING',
      'THICKET',
      'TOMBALL',
      'VOTAW',
      'BELLAIRE',
      'BAY CITY',
      'RICHMOND',
      'CYPRESS',
      'ALIEF',
      'ALTAIR',
      'BARKER',
      'CEDAR LANE',
      'BEASLEY',
      'BELLVILLE',
      'BLESSING',
      'BOLING',
      'BRAZORIA',
      'BROOKSHIRE',
      'CHAPPELL HILL',
      'COLLEGEPORT',
      'DAMON',
      'DANCIGER',
      'DANEVANG',
      'EAGLE LALASKAE',
      'EAST BERNARD',
      'EGYPT',
      'EL CAMPO',
      'ELMATON',
      'FULSHEAR',
      'GARWOOD',
      'GLEN FLORA',
      'GUY',
      'HEMPSTEAD',
      'PRAIRIE VIEW',
      'HOCKLEY',
      'HUNGERFORD',
      'KATY',
      'KENDLETON',
      'KENNEY',
      'LANE CITY',
      'LISSIE',
      'LOUISE',
      'MARKHAM',
      'MATAGORDA',
      'MIDFIELD',
      'MISSOURI CITY',
      'NADA',
      'NEEDVILLE',
      'NEWGULF',
      'OLD OCEAN',
      'ORCHARD',
      'PALACIOS',
      'PATTISON',
      'PIERCE',
      'PLEDGER',
      'ROCK ISLAND',
      'ROSENBERG',
      'SAN FELIPE',
      'SEALY',
      'SHERIDAN',
      'SIMONTON',
      'STAFFORD',
      'SUGAR LAND',
      'SWEENY',
      'THOMPSONS',
      'VAN VLECK',
      'WADSWORTH',
      'WALLER',
      'WALLIS',
      'WEST COLUMBIA',
      'WHARTON',
      'PASADENA',
      'SANTA FE',
      'ALVIN',
      'ANAHUAC',
      'ANGLETON',
      'BACLIFF',
      'BATSON',
      'BAYTOWN',
      'CHANNELVIEW',
      'CLUTE',
      'CROSBY',
      'DAISETTA',
      'DANBURY',
      'DAYTON',
      'DEER PARK',
      'DEVERS',
      'DICKINSON',
      'FREEPORT',
      'FRESNO',
      'FRIENDSWOOD',
      'GALENA PARK',
      'GALVESTON',
      'HANKAMER',
      'HARDIN',
      'HIGHLANDS',
      'HITCHCOCK',
      'HULL',
      'KEMAH',
      'LALASKAE JACKSON',
      'LA MARQUE',
      'LA PORTE',
      'LEAGUE CITY',
      'LIBERTY',
      'LIVERPOOL',
      'MANVEL',
      'MONT BELVIEU',
      'PEARLAND',
      'RAYWOOD',
      'ROSHARON',
      'SARATOGA',
      'SEABROOK',
      'SOUTH HOUSTON',
      'TEXAS CITY',
      'WALLISVILLE',
      'WEBSTER',
      'BRIDGE CITY',
      'BUNA',
      'CHINA',
      'DEWEYVILLE',
      'EVADALE',
      'FRED',
      'GILCHRIST',
      'GROVES',
      'HAMSHIRE',
      'HIGH ISLAND',
      'HILLISTER',
      'KOUNTZE',
      'MAURICEVILLE',
      'NEDERLAND',
      'NOME',
      'ORANGE',
      'ORANGEFIELD',
      'PORT ARTHUR',
      'PORT BOLIVAR',
      'PORT NECHES',
      'SABINE PASS',
      'SILSBEE',
      'LUMBERTON',
      'SOUR LALASKAE',
      'SPURGER',
      'STOWELL',
      'VIDOR',
      'VILLAGE MILLS',
      'WARREN',
      'WINNIE',
      'BEAUMONT',
      'VOTH',
      'BRYAN',
      'ANDERSON',
      'BEDIAS',
      'BRENHAM',
      'BURTON',
      'CALDWELL',
      'CALVERT',
      'CHRIESMAN',
      'CLAY',
      'COLLEGE STATION',
      'CONCORD',
      'DEANVILLE',
      'DIME BOX',
      'FLYNN',
      'FRANKLIN',
      'GAUSE',
      'HEARNE',
      'IOLA',
      'KURTEN',
      'LYONS',
      'MADISONVILLE',
      'MARQUEZ',
      'MILLICAN',
      'MUMFORD',
      'NAVASOTA',
      'NEW BADEN',
      'NORMANGEE',
      'NORTH ZULCH',
      'RICHARDS',
      'ROANS PRAIRIE',
      'SHIRO',
      'SNOOK',
      'SOMERVILLE',
      'WASHINGTON',
      'WELLBORN',
      'WHEELOCK',
      'VICTORIA',
      'AUSTWELL',
      'BLOOMINGTON',
      'CUERO',
      'EDNA',
      'FANNIN',
      'FRANCITAS',
      'GANADO',
      'GOLIAD',
      'HALLETTSVILLE',
      'HOCHHEIM',
      'INEZ',
      'LA SALLE',
      'LA WARD',
      'LOLITA',
      'LONG MOTT',
      'MCFADDIN',
      'MEYERSVILLE',
      'MOULTON',
      'NURSERY',
      'PLACEDO',
      'POINT COMFORT',
      'PORT LAVACA',
      'PORT O CONNOR',
      'SEADRIFT',
      'SHINER',
      'SPEALASKAS',
      'SUBLIME',
      'SWEET HOME',
      'TELFERNER',
      'THOMASTON',
      'TIVOLI',
      'VANDERBILT',
      'WEESATCHE',
      'WESTHOFF',
      'YOALASKAUM',
      'ARTESIA WELLS',
      'ATASCOSA',
      'BANDERA',
      'BERGHEIM',
      'BIGFOOT',
      'BOERNE',
      'CALLIHAM',
      'CAMPBELLTON',
      'CASTROVILLE',
      'CENTER POINT',
      'CHARLOTTE',
      'CHRISTINE',
      'COMFORT',
      'COTULLA',
      'DEVINE',
      'DILLEY',
      'ENCINAL',
      'FOWLERTON',
      'GEORGE WEST',
      'HELOTES',
      'HUNT',
      'INGRAM',
      'JOURDANTON',
      'KENDALIA',
      'KERRVILLE',
      'LA COSTE',
      'LAREDO',
      'LEMING',
      'LYTLE',
      'MC COY',
      'MACDONA',
      'MEDINA',
      'MICO',
      'MOORE',
      'MOUNTAIN HOME',
      'NATALIA',
      'OALASKAVILLE',
      'PEARSALL',
      'PEGGY',
      'PIPE CREEK',
      'PLEASANTON',
      'POTEET',
      'RIO MEDINA',
      'SAN YGNACIO',
      'SOMERSET',
      'SPRING BRANCH',
      'THREE RIVERS',
      'TILDEN',
      'VON ORMY',
      'WARING',
      'WHITSETT',
      'ZAPATA',
      'ADKINS',
      'BEEVILLE',
      'BERCLAIR',
      'CIBOLO',
      'CONVERSE',
      'ECLETO',
      'ELMENDORF',
      'FALLS CITY',
      'FLORESVILLE',
      'GERONIMO',
      'GILLETT',
      'HOBSON',
      'KARNES CITY',
      'KENEDY',
      'LA VERNIA',
      'LEESVILLE',
      'MC QUEENEY',
      'MARION',
      'MINERAL',
      'NEW BRAUNFELS',
      'CANYON LALASKAE',
      'NIXON',
      'NORDHEIM',
      'NORMANNA',
      'PANDORA',
      'PANNA MARIA',
      'PAWNEE',
      'PETTUS',
      'POTH',
      'UNIVERSAL CITY',
      'RUNGE',
      'SAINT HEDWIG',
      'SCHERTZ',
      'SEGUIN',
      'SMILEY',
      'STOCKDALE',
      'SUTHERLAND SPRINGS',
      'TULETA',
      'BULVERDE',
      'YORKTOWN',
      'SAN ANTONIO',
      'AGUA DULCE',
      'ALICE',
      'ARANSAS PASS',
      'ARMSTRONG',
      'BANQUETE',
      'BAYSIDE',
      'BENAVIDES',
      'BEN BOLT',
      'BISHOP',
      'BRUNI',
      'CHAPMAN RANCH',
      'CONCEPCION',
      'DINERO',
      'DRISCOLL',
      'EDROY',
      'ENCINO',
      'FALFURRIAS',
      'FREER',
      'FULTON',
      'GREGORY',
      'GUERRA',
      'HEBBRONVILLE',
      'INGLESIDE',
      'KINGSVILLE',
      'MATHIS',
      'MIRANDO CITY',
      'ODEM',
      'OILTON',
      'ORANGE GROVE',
      'PORT ARANSAS',
      'PORTLAND',
      'PREMONT',
      'REALITOS',
      'REFUGIO',
      'RIVIERA',
      'ROBSTOWN',
      'ROCKPORT',
      'SANDIA',
      'SAN DIEGO',
      'SARITA',
      'SINTON',
      'SKIDMORE',
      'TAFT',
      'TYNAN',
      'WOODSBORO',
      'CORPUS CHRISTI',
      'MCALLEN',
      'ALAMO',
      'BROWNSVILLE',
      'COMBES',
      'DELMITA',
      'DONNA',
      'EDCOUCH',
      'EDINBURG',
      'ELSA',
      'FALCON HEIGHTS',
      'GARCIASVILLE',
      'GRULLA',
      'HARGILL',
      'HARLINGEN',
      'HIDALGO',
      'LA BLANCA',
      'LA FERIA',
      'LA JOYA',
      'LASARA',
      'LA VILLA',
      'LINN',
      'LOPENO',
      'LOS EBANOS',
      'LOS FRESNOS',
      'LOS INDIOS',
      'LOZANO',
      'LYFORD',
      'MERCEDES',
      'MISSION',
      'OLMITO',
      'PENITAS',
      'PHARR',
      'PORT ISABEL',
      'PROGRESO',
      'RAYMONDVILLE',
      'RIO GRANDE CITY',
      'RIO HONDO',
      'ROMA',
      'SALINENO',
      'SAN BENITO',
      'SAN ISIDRO',
      'SAN JUAN',
      'SAN PERLITA',
      'SANTA ELENA',
      'SANTA MARIA',
      'SANTA ROSA',
      'SEBASTIAN',
      'SULLIVAN CITY',
      'WESLACO',
      'SOUTH PADRE ISLAND',
      'PORT MANSFIELD',
      'BASTROP',
      'BEBE',
      'BELMONT',
      'BERTRAM',
      'BLANCO',
      'BLUFFTON',
      'BRIGGS',
      'BUCHANAN DAM',
      'BUDA',
      'BURNET',
      'CEDAR CREEK',
      'CEDAR PARK',
      'COST',
      'COUPLAND',
      'DALE',
      'DEL VALLE',
      'DOSS',
      'DRIFTWOOD',
      'DRIPPING SPRINGS',
      'ELGIN',
      'FENTRESS',
      'FISCHER',
      'FREDERICKSBURG',
      'GEORGETOWN',
      'GONZALES',
      'HARPER',
      'HARWOOD',
      'HUTTO',
      'HYE',
      'JOHNSON CITY',
      'KINGSBURY',
      'KINGSLAND',
      'KYLE',
      'LEANDER',
      'LIBERTY HILL',
      'LLANO',
      'LOCKHART',
      'LULING',
      'MC DADE',
      'MC NEIL',
      'MANCHACA',
      'MANOR',
      'MARBLE FALLS',
      'MARTINDALE',
      'MAXWELL',
      'OTTINE',
      'PAIGE',
      'PFLUGERVILLE',
      'PRAIRIE LEA',
      'RED ROCK',
      'ROUND MOUNTAIN',
      'ROUND ROCK',
      'SANDY',
      'SAN MARCOS',
      'SPICEWOOD',
      'STAPLES',
      'STONEWALL',
      'TOW',
      'WALBURG',
      'WEIR',
      'WILLOW CITY',
      'WIMBERLEY',
      'WRIGHTSBORO',
      'UVALDE',
      'ASHERTON',
      'BARKSDALE',
      'BATESVILLE',
      'BIG WELLS',
      'BRACKETTVILLE',
      'CAMP WOOD',
      'CARRIZO SPRINGS',
      'CATARINA',
      'COMSTOCK',
      'CONCAN',
      'CRYSTAL CITY',
      'DEL RIO',
      'LAUGHLIN A F B',
      'D HANIS',
      'DRYDEN',
      'EAGLE PASS',
      'EL INDIO',
      'HONDO',
      'KNIPPA',
      'LANGTRY',
      'LA PRYOR',
      'LEALASKAEY',
      'QUEMADO',
      'RIO FRIO',
      'ROCKSPRINGS',
      'SABINAL',
      'TARPLEY',
      'UTOPIA',
      'VANDERPOOL',
      'YANCEY',
      'BLEIBLERVILLE',
      'CARMINE',
      'CAT SPRING',
      'COLUMBUS',
      'ALLEYTON',
      'ELLINGER',
      'FAYETTEVILLE',
      'FLATONIA',
      'GIDDINGS',
      'GLIDDEN',
      'INDUSTRY',
      'LA GRANGE',
      'LEDBETTER',
      'LEXINGTON',
      'LINCOLN',
      'MULDOON',
      'NEW ULM',
      'OALASKALAND',
      'PLUM',
      'ROSANKY',
      'ROUND TOP',
      'SCHULENBURG',
      'SMITHVILLE',
      'WAELDER',
      'WARDA',
      'WEIMAR',
      'WEST POINT',
      'ADRIAN',
      'ALANREED',
      'ALLISON',
      'BOOKER',
      'BORGER',
      'BOVINA',
      'BOYS RANCH',
      'BRISCOE',
      'BUSHLAND',
      'CACTUS',
      'CANADIAN',
      'CANYON',
      'CHANNING',
      'CLAUDE',
      'COTTON CENTER',
      'DALHART',
      'DARROUZETT',
      'DAWN',
      'DIMMITT',
      'DUMAS',
      'EARTH',
      'EDMONSON',
      'FARNSWORTH',
      'FOLLETT',
      'FRIONA',
      'FRITCH',
      'GROOM',
      'GRUVER',
      'HALE CENTER',
      'HAPPY',
      'HART',
      'HARTLEY',
      'HEREFORD',
      'HIGGINS',
      'KERRICK',
      'KRESS',
      'LAZBUDDIE',
      'LEFORS',
      'LIPSCOMB',
      'MCLEAN',
      'MASTERSON',
      'MIAMI',
      'MOBEETIE',
      'MORSE',
      'NAZARETH',
      'OLTON',
      'PAMPA',
      'PANHANDLE',
      'PERRYTON',
      'PLAINVIEW',
      'SAMNORWOOD',
      'SANFORD',
      'SHAMROCK',
      'SKELLYTOWN',
      'SPEARMAN',
      'SPRINGLALASKAE',
      'STINNETT',
      'STRATFORD',
      'SUMMERFIELD',
      'SUNRAY',
      'TEXLINE',
      'TULIA',
      'UMBARGER',
      'VEGA',
      'WALASKAA',
      'WAYSIDE',
      'WELLINGTON',
      'WHEELER',
      'WHITE DEER',
      'WILDORADO',
      'AMARILLO',
      'CHILDRESS',
      'AFTON',
      'AIKEN',
      'CAREY',
      'CEE VEE',
      'CHALK',
      'CHILLICOTHE',
      'CLARENDON',
      'CROWELL',
      'DICKENS',
      'DODSON',
      'DOUGHERTY',
      'DUMONT',
      'ESTELLINE',
      'FLOMOT',
      'FLOYDADA',
      'GUTHRIE',
      'HEDLEY',
      'KIRKLAND',
      'LALASKAEVIEW',
      'LELIA LALASKAE',
      'LOCKNEY',
      'MCADOO',
      'MATADOR',
      'MEMPHIS',
      'ODELL',
      'PADUCAH',
      'PETERSBURG',
      'QUAIL',
      'QUANAH',
      'QUITAQUE',
      'ROARING SPRINGS',
      'SILVERTON',
      'SOUTH PLAINS',
      'TELL',
      'TURKEY',
      'ABERNATHY',
      'AMHERST',
      'ANTON',
      'BLEDSOE',
      'BROWNFIELD',
      'BULA',
      'CROSBYTON',
      'DENVER CITY',
      'ENOCHS',
      'FARWELL',
      'FIELDTON',
      'IDALOU',
      'JUSTICEBURG',
      'LAMESA',
      'LEVELLAND',
      'LITTLEFIELD',
      'LOOP',
      'LORENZO',
      'MAPLE',
      'MEADOW',
      'MORTON',
      'MULESHOE',
      'NEW DEAL',
      'ODONNELL',
      'PEP',
      'PLAINS',
      'POST',
      'RALLS',
      'ROPESVILLE',
      'SEAGRAVES',
      'SEMINOLE',
      'SHALLOWATER',
      'SLATON',
      'RANSOM CANYON',
      'SMYER',
      'SPADE',
      'SPUR',
      'SUDAN',
      'SUNDOWN',
      'TAHOKA',
      'TOKIO',
      'WELCH',
      'WELLMAN',
      'WHITEFACE',
      'WHITHARRAL',
      'WILSON',
      'WOLFFORTH',
      'NEW HOME',
      'LUBBOCK',
      'ANSON',
      'ASPERMONT',
      'AVOCA',
      'BAIRD',
      'BENJAMIN',
      'BLACKWELL',
      'BUFFALO GAP',
      'CLYDE',
      'COAHOMA',
      'COLORADO CITY',
      'DUNN',
      'FLUVANNA',
      'GIRARD',
      'GOLDSBORO',
      'HAMLIN',
      'HASKELL',
      'HAWLEY',
      'HERMLEIGH',
      'IRA',
      'JAYTON',
      'KNOX CITY',
      'LAWN',
      'LORAINE',
      'LUEDERS',
      'MC CAULLEY',
      'MARYNEAL',
      'MERKEL',
      'NOLAN',
      'NOVICE',
      'O BRIEN',
      'OLD GLORY',
      'OVALO',
      'ROBY',
      'ROCHESTER',
      'ROSCOE',
      'ROTAN',
      'RULE',
      'SNYDER',
      'STAMFORD',
      'SWEETWATER',
      'SYLVESTER',
      'TRENT',
      'TUSCOLA',
      'TYE',
      'WESTBROOK',
      'WINGATE',
      'WINTERS',
      'ABILENE',
      'DYESS AFB',
      'MIDLAND',
      'ACKERLY',
      'ANDREWS',
      'BALMORHEA',
      'BARSTOW',
      'BIG SPRING',
      'COYANOSA',
      'CRANE',
      'FORSAN',
      'FORT DAVIS',
      'FORT STOCKTON',
      'GAIL',
      'GARDEN CITY',
      'GIRVIN',
      'GOLDSMITH',
      'GRANDFALLS',
      'IMPERIAL',
      'IRAAN',
      'KERMIT',
      'KNOTT',
      'LENORAH',
      'MC CAMEY',
      'MENTONE',
      'MIDKIFF',
      'MONAHANS',
      'GARDENDALE',
      'NOTREES',
      'ODESSA',
      'ORLA',
      'PECOS',
      'PENWELL',
      'PYOTE',
      'RANKIN',
      'ROYALTY',
      'SARAGOSA',
      'SHEFFIELD',
      'STANTON',
      'TARZAN',
      'TOYAH',
      'TOYAHVALE',
      'WICKETT',
      'WINK',
      'ANTHONY',
      'ALPINE',
      'BIG BEND NATIONAL PARK',
      'CANUTILLO',
      'CLINT',
      'DELL CITY',
      'FABENS',
      'FORT HANCOCK',
      'MARATHON',
      'MARFA',
      'PRESIDIO',
      'REDFORD',
      'SALT FLAT',
      'SANDERSON',
      'SAN ELIZARIO',
      'SHAFTER',
      'SIERRA BLANCA',
      'TERLINGUA',
      'TORNILLO',
      'VALENTINE',
      'VAN HORN',
      'EL PASO'
    ]
  },
  UT: {
    code: 'UT',
    name: 'Utah',
    cities: [
      'ALTAMONT',
      'ALTONAH',
      'AMERICAN FORK',
      'ALPINE',
      'BINGHAM CANYON',
      'BLUEBELL',
      'BONANZA',
      'BOUNTIFUL',
      'CEDAR VALLEY',
      'CENTERVILLE',
      'CLEARFIELD',
      'COALVILLE',
      'CROYDON',
      'DRAPER',
      'DUCHESNE',
      'DUGWAY',
      'DUTCH JOHN',
      'ECHO',
      'FARMINGTON',
      'FORT DUCHESNE',
      'FRUITLAND',
      'GARDEN CITY',
      'GRANTSVILLE',
      'GUSHER',
      'HANNA',
      'HEBER CITY',
      'HENEFER',
      'IBAPAH',
      'JENSEN',
      'KAMAS',
      'KAYSVILLE',
      'LALASKAETOWN',
      'LAPOINT',
      'LAYTON',
      'LINDON',
      'LEHI',
      'MAGNA',
      'MANILA',
      'MIDVALE',
      'MIDWAY',
      'MORGAN',
      'MOUNTAIN HOME',
      'MYTON',
      'NEOLA',
      'NORTH SALT LALASKAE',
      'OALASKALEY',
      'HILL AFB',
      'OREM',
      'PARK CITY',
      'PEOA',
      'PLEASANT GROVE',
      'RANDLETT',
      'RANDOLPH',
      'RIVERTON',
      'ROOSEVELT',
      'ROY',
      'RUSH VALLEY',
      'SANDY',
      'STOCKTON',
      'TABIONA',
      'TALMAGE',
      'TOOELE',
      'SYRACUSE',
      'TRIDELL',
      'VERNAL',
      'VERNON',
      'WALLSBURG',
      'WENDOVER',
      'WEST JORDAN',
      'WHITEROCKS',
      'WOODRUFF',
      'WOODS CROSS',
      'SOUTH JORDAN',
      'SALT LALASKAE CITY',
      'OGDEN',
      'BEAR RIVER CITY',
      'BRIGHAM CITY',
      'CACHE JUNCTION',
      'CLARKSTON',
      'COLLINSTON',
      'CORINNE',
      'CORNISH',
      'DEWEYVILLE',
      'EDEN',
      'FIELDING',
      'GARLAND',
      'GROUSE CREEK',
      'HONEYVILLE',
      'HOOPER',
      'HOWELL',
      'HUNTSVILLE',
      'HYDE PARK',
      'HYRUM',
      'LEWISTON',
      'LOGAN',
      'MANTUA',
      'MENDON',
      'MILLVILLE',
      'NEWTON',
      'PARADISE',
      'PARK VALLEY',
      'PLYMOUTH',
      'PORTAGE',
      'PROVIDENCE',
      'RICHMOND',
      'RIVERSIDE',
      'SMITHFIELD',
      'SNOWVILLE',
      'TREMONTON',
      'TRENTON',
      'WELLSVILLE',
      'WILLARD',
      'PRICE',
      'ANETH',
      'BLANDING',
      'BLUFF',
      'CASTLE DALE',
      'CISCO',
      'CLAWSON',
      'CLEVELAND',
      'EAST CARBON',
      'ELMO',
      'EMERY',
      'FERRON',
      'GREEN RIVER',
      'HELPER',
      'HIAWATHA',
      'HUNTINGTON',
      'KENILWORTH',
      'LA SAL',
      'MEXICAN HAT',
      'MOAB',
      'LALASKAE POWELL',
      'MONTEZUMA CREEK',
      'MONTICELLO',
      'MONUMENT VALLEY',
      'ORANGEVILLE',
      'SUNNYSIDE',
      'THOMPSON',
      'WELLINGTON',
      'PROVO',
      'AURORA',
      'AXTELL',
      'CENTERFIELD',
      'CHESTER',
      'DELTA',
      'ELBERTA',
      'EPHRAIM',
      'EUREKA',
      'FAIRVIEW',
      'FAYETTE',
      'FILLMORE',
      'FOUNTAIN GREEN',
      'GOSHEN',
      'GUNNISON',
      'HINCKLEY',
      'HOLDEN',
      'KANOSH',
      'LEAMINGTON',
      'LEVAN',
      'LYNNDYL',
      'MANTI',
      'MAYFIELD',
      'MEADOW',
      'MONA',
      'MORONI',
      'MOUNT PLEASANT',
      'NEPHI',
      'OALASKA CITY',
      'OASIS',
      'PAYSON',
      'REDMOND',
      'SALEM',
      'SALINA',
      'SANTAQUIN',
      'SCIPIO',
      'SIGURD',
      'SPANISH FORK',
      'SPRING CITY',
      'SPRINGVILLE',
      'MAPLETON',
      'STERLING',
      'WALES',
      'RICHFIELD',
      'ALTON',
      'ANNABELLA',
      'ANTIMONY',
      'BEAVER',
      'BERYL',
      'BICKNELL',
      'BOULDER',
      'BRYCE CANYON',
      'CANNONVILLE',
      'BRIAN HEAD',
      'CEDAR CITY',
      'CENTRAL',
      'CIRCLEVILLE',
      'ELSINORE',
      'ENTERPRISE',
      'ESCALANTE',
      'GARRISON',
      'GLENDALE',
      'GLENWOOD',
      'GREENVILLE',
      'GREENWICH',
      'GUNLOCK',
      'HANKSVILLE',
      'HATCH',
      'HENRIEVILLE',
      'HURRICANE',
      'IVINS',
      'JOSEPH',
      'JUNCTION',
      'KANAB',
      'KANARRAVILLE',
      'KINGSTON',
      'KOOSHAREM',
      'LA VERKIN',
      'LEEDS',
      'LOA',
      'LYMAN',
      'MARYSVALE',
      'MILFORD',
      'MINERSVILLE',
      'MODENA',
      'MONROE',
      'MOUNT CARMEL',
      'NEWCASTLE',
      'NEW HARMONY',
      'ORDERVILLE',
      'PANGUITCH',
      'PARAGONAH',
      'PAROWAN',
      'DUCK CREEK VILLAGE',
      'ROCKVILLE',
      'BRYCE',
      'SANTA CLARA',
      'SEVIER',
      'SPRINGDALE',
      'SAINT GEORGE',
      'SUMMIT',
      'TEASDALE',
      'TOQUERVILLE',
      'TORREY',
      'TROPIC',
      'VIRGIN',
      'WASHINGTON',
      'PINE VALLEY',
      'VEYO',
      'DAMMERON VALLEY',
      'HILDALE'
    ]
  },
  VA: {
    code: 'VA',
    name: 'Virginia',
    cities: [
      'DULLES',
      'ALDIE',
      'AMISSVILLE',
      'ARCOLA',
      'MANASSAS',
      'MARSHALL',
      'MIDDLEBURG',
      'CATLETT',
      'CENTREVILLE',
      'CLIFTON',
      'ORLEAN',
      'PAEONIAN SPRINGS',
      'PARIS',
      'PHILOMONT',
      'PURCELLVILLE',
      'BLUEMONT',
      'BRISTOW',
      'BROAD RUN',
      'CALVERTON',
      'CASANOVA',
      'RECTORTOWN',
      'ROUND HILL',
      'CATHARPIN',
      'DELAPLANE',
      'ASHBURN',
      'CHANTILLY',
      'GAINESVILLE',
      'HAMILTON',
      'LINCOLN',
      'STERLING',
      'HAYMARKET',
      'HERNDON',
      'LEESBURG',
      'LOVETTSVILLE',
      'NOKESVILLE',
      'UPPERVILLE',
      'WARRENTON',
      'RESTON',
      'WATERFORD',
      'THE PLAINS',
      'ANNANDALE',
      'BURKE',
      'DUMFRIES',
      'DUNN LORING',
      'FAIRFAX',
      'FAIRFAX STATION',
      'FALLS CHURCH',
      'FORT BELVOIR',
      'GREAT FALLS',
      'GREENWAY',
      'LORTON',
      'MERRIFIELD',
      'MC LEAN',
      'WEST MCLEAN',
      'MOUNT VERNON',
      'NEWINGTON',
      'OALASKATON',
      'OCCOQUAN',
      'QUANTICO',
      'SPRINGFIELD',
      'TRIANGLE',
      'VIENNA',
      'WOODBRIDGE',
      'ARLINGTON',
      'FT MYER',
      'ALEXANDRIA',
      'FREDERICKSBURG',
      'BOWLING GREEN',
      'BROOKE',
      'BURGESS',
      'BURR HILL',
      'CALLAO',
      'CARET',
      'CENTER CROSS',
      'CHAMPLAIN',
      'COLES POINT',
      'COLONIAL BEACH',
      'CORBIN',
      'DAHLGREN',
      'DOGUE',
      'DUNNSVILLE',
      'EDWARDSVILLE',
      'FARNHAM',
      'GARRISONVILLE',
      'HAGUE',
      'HARTWOOD',
      'HAYNESVILLE',
      'HEATHSVILLE',
      'HUSTLE',
      'IRVINGTON',
      'JERSEY',
      'KILMARNOCK',
      'KING GEORGE',
      'KINSALE',
      'LADYSMITH',
      'LANCASTER',
      'LANEVIEW',
      'LIVELY',
      'LOCUST GROVE',
      'LORETTO',
      'LOTTSBURG',
      'MERRY POINT',
      'MILFORD',
      'MOLLUSK',
      'MONTROSS',
      'MORATTICO',
      'MOUNT HOLLY',
      'NINDE',
      'NUTTSVILLE',
      'OLDHAMS',
      'OPHELIA',
      'PARTLOW',
      'PORT ROYAL',
      'RAPPAHANNOCK ACADEMY',
      'REEDVILLE',
      'RHOADESVILLE',
      'ROLLINS FORK',
      'RUBY',
      'RUTHER GLEN',
      'SEALSTON',
      'SHARPS',
      'SPARTA',
      'SPOTSYLVANIA',
      'STAFFORD',
      'STRATFORD',
      'TAPPAHANNOCK',
      'THORNBURG',
      'UNIONVILLE',
      'VILLAGE',
      'WARSAW',
      'WEEMS',
      'SANDY POINT',
      'WHITE STONE',
      'WICOMICO CHURCH',
      'WOODFORD',
      'ZACATA',
      'WINCHESTER',
      'BENTONVILLE',
      'BERRYVILLE',
      'BOYCE',
      'BRUCETOWN',
      'CHESTER GAP',
      'CLEAR BROOK',
      'CROSS JUNCTION',
      'FISHERS HILL',
      'FLINT HILL',
      'FRONT ROYAL',
      'GORE',
      'HUME',
      'HUNTLY',
      'STRASBURG',
      'LINDEN',
      'MARKHAM',
      'MAURERTOWN',
      'MIDDLETOWN',
      'MILLWOOD',
      'RILEYVILLE',
      'FORT VALLEY',
      'STAR TANNERY',
      'STEPHENS CITY',
      'STEPHENSON',
      'TOMS BROOK',
      'WHITE POST',
      'WOODSTOCK',
      'CULPEPER',
      'ARODA',
      'BANCO',
      'BEALETON',
      'BOSTON',
      'BRANDY STATION',
      'BRIGHTWOOD',
      'CASTLETON',
      'ELKWOOD',
      'ETLAN',
      'GOLDVEIN',
      'GRAVES MILL',
      'HAYWOOD',
      'HOOD',
      'JEFFERSONTON',
      'LEON',
      'LIGNUM',
      'MADISON',
      'MIDLAND',
      'MITCHELLS',
      'OALASKAPARK',
      'PRATTS',
      'RADIANT',
      'RAPIDAN',
      'REMINGTON',
      'REVA',
      'RICHARDSVILLE',
      'RIXEYVILLE',
      'ROCHELLE',
      'SOMERVILLE',
      'SPERRYVILLE',
      'STEVENSBURG',
      'SUMERDUCK',
      'SYRIA',
      'VIEWTOWN',
      'WASHINGTON',
      'WOLFTOWN',
      'WOODVILLE',
      'HARRISONBURG',
      'BASYE',
      'BERGTON',
      'BRIDGEWATER',
      'BROADWAY',
      'CRIDERS',
      'DAYTON',
      'EDINBURG',
      'ELKTON',
      'FULKS RUN',
      'HINTON',
      'KEEZLETOWN',
      'LACEY SPRING',
      'LINVILLE',
      'LURAY',
      'MC GAHEYSVILLE',
      'MOUNT CRAWFORD',
      'MOUNT JACKSON',
      'MOUNT SOLON',
      'NEW MARKET',
      'ORKNEY SPRINGS',
      'PENN LAIRD',
      'QUICKSBURG',
      'PLEASANT VALLEY',
      'SHENANDOAH',
      'SINGERS GLEN',
      'STANLEY',
      'TIMBERVILLE',
      'CHARLOTTESVILLE',
      'AFTON',
      'ARRINGTON',
      'BARBOURSVILLE',
      'BATESVILLE',
      'COVESVILLE',
      'CROZET',
      'DYKE',
      'EARLYSVILLE',
      'ESMONT',
      'FABER',
      'FISHERSVILLE',
      'FREE UNION',
      'GORDONSVILLE',
      'GREENWOOD',
      'IVY',
      'KEENE',
      'KESWICK',
      'LOCUST DALE',
      'LOVINGSTON',
      'LYNDHURST',
      'MADISON MILLS',
      'MASSIES MILL',
      'MONTPELIER STATION',
      'NELLYSFORD',
      'NORTH GARDEN',
      'ORANGE',
      'PALMYRA',
      'PINEY RIVER',
      'QUINQUE',
      'ROSELAND',
      'RUCKERSVILLE',
      'SCHUYLER',
      'SHIPMAN',
      'SOMERSET',
      'STANARDSVILLE',
      'TROY',
      'TYRO',
      'WAYNESBORO',
      'WHITE HALL',
      'WOODBERRY FOREST',
      'ACHILLES',
      'AMELIA COURT HOUSE',
      'ARK',
      'ARVONIA',
      'ASHLAND',
      'AYLETT',
      'BARHAMSVILLE',
      'BEAUMONT',
      'BEAVERDAM',
      'BELLAMY',
      'BENA',
      'BOHANNON',
      'BREMO BLUFF',
      'BRUINGTON',
      'BUMPASS',
      'CARDINAL',
      'CARTERSVILLE',
      'CHARLES CITY',
      'CHRISTCHURCH',
      'CHURCH VIEW',
      'COBBS CREEK',
      'COLUMBIA',
      'CROZIER',
      'CUMBERLAND',
      'DELTAVILLE',
      'DIGGS',
      'DOSWELL',
      'DUTTON',
      'FIFE',
      'FORK UNION',
      'FOSTER',
      'GLEN ALLEN',
      'GLOUCESTER',
      'GLOUCESTER POINT',
      'GOOCHLAND',
      'GRIMSTEAD',
      'GUM SPRING',
      'GWYNN',
      'HADENSVILLE',
      'HALLIEFORD',
      'HANOVER',
      'HARDYVILLE',
      'HARTFIELD',
      'HAYES',
      'HIGHLAND SPRINGS',
      'HUDGINS',
      'JAMAICA',
      'JAMESTOWN',
      'JETERSVILLE',
      'KENTS STORE',
      'KING AND QUEEN COURT HOUS',
      'KING WILLIAM',
      'LANEXA',
      'LIGHTFOOT',
      'LITTLE PLYMOUTH',
      'LOCUST HILL',
      'LOUISA',
      'MACON',
      'MAIDENS',
      'MANALASKAIN SABOT',
      'MANNBORO',
      'MANQUIN',
      'MARYUS',
      'MASCOT',
      'MATHEWS',
      'MATTAPONI',
      'MECHANICSVILLE',
      'MIDLOTHIAN',
      'MILLERS TAVERN',
      'MINERAL',
      'MOON',
      'MOSELEY',
      'NEW CANTON',
      'NEW KENT',
      'NEW POINT',
      'NEWTOWN',
      'NORGE',
      'NORTH',
      'OILVILLE',
      'ONEMO',
      'ORDINARY',
      'PORT HAYWOOD',
      'POWHATAN',
      'PROVIDENCE FORGE',
      'QUINTON',
      'ROCKVILLE',
      'RUTHVILLE',
      'SAINT STEPHENS CHURCH',
      'SALUDA',
      'SANDSTON',
      'SANDY HOOK',
      'SCHLEY',
      'SEVERN',
      'SHACKLEFORDS',
      'STATE FARM',
      'STEVENSVILLE',
      'STUDLEY',
      'SUSAN',
      'TOANO',
      'TOPPING',
      'TREVILIANS',
      'UNIVERSITY OF RICHMOND',
      'URBANNA',
      'WALASKAE',
      'WALKERTON',
      'WARE NECK',
      'WATER VIEW',
      'WEST POINT',
      'WHITE MARSH',
      'WICOMICO',
      'WILLIAMSBURG',
      'WOODS CROSS ROADS',
      'ZANONI',
      'MONTPELIER',
      'RICHMOND',
      'ACCOMAC',
      'ASSAWOMAN',
      'ATLANTIC',
      'BATTERY PARK',
      'BELLE HAVEN',
      'BIRDSNEST',
      'BLOXOM',
      'CAPE CHARLES',
      'CAPEVILLE',
      'CARROLLTON',
      'CARRSVILLE',
      'CHERITON',
      'CHESAPEALASKAE',
      'CHINCOTEAGUE ISLAND',
      'WALLOPS ISLAND',
      'CRADDOCKVILLE',
      'DAVIS WHARF',
      'EASTVILLE',
      'EXMORE',
      'FRANKTOWN',
      'GREENBACKVILLE',
      'GREENBUSH',
      'HACKSNECK',
      'HALLWOOD',
      'HARBORTON',
      'HORNTOWN',
      'OALASKA HALL',
      'ISLE OF WIGHT',
      'JAMESVILLE',
      'JENKINS BRIDGE',
      'KELLER',
      'LOCUSTVILLE',
      'MACHIPONGO',
      'MAPPSVILLE',
      'MARIONVILLE',
      'MEARS',
      'MELFA',
      'MODEST TOWN',
      'NASSAWADOX',
      'NELSONIA',
      'NEW CHURCH',
      'ONANCOCK',
      'ONLEY',
      'OYSTER',
      'PAINTER',
      'PARKSLEY',
      'PUNGOTEAGUE',
      'QUINBY',
      'RESCUE',
      'SANFORD',
      'SAXIS',
      'SEAVIEW',
      'SMITHFIELD',
      'SUFFOLK',
      'TANGIER',
      'TASLEY',
      'TEMPERANCEVILLE',
      'TOWNSEND',
      'VIRGINIA BEACH',
      'WACHAPREAGUE',
      'WARDTOWN',
      'WATTSVILLE',
      'WILLIS WHARF',
      'WINDSOR',
      'WITHAMS',
      'NORFOLK',
      'NEWPORT NEWS',
      'FORT EUSTIS',
      'HAMPTON',
      'FORT MONROE',
      'POQUOSON',
      'YORKTOWN',
      'LACKEY',
      'SEAFORD',
      'PORTSMOUTH',
      'FORT LEE',
      'PETERSBURG',
      'ALBERTA',
      'AMMON',
      'BLACKSTONE',
      'BOYKINS',
      'BRANCHVILLE',
      'CAPRON',
      'CARSON',
      'CHESTER',
      'CHESTERFIELD',
      'CHURCH ROAD',
      'COLONIAL HEIGHTS',
      'COURTLAND',
      'DENDRON',
      'DEWITT',
      'DINWIDDIE',
      'DISPUTANTA',
      'DOLPHIN',
      'DREWRYVILLE',
      'EBONY',
      'ELBERON',
      'EMPORIA',
      'FORD',
      'FRANKLIN',
      'FREEMAN',
      'GASBURG',
      'HOPEWELL',
      'IVOR',
      'JARRATT',
      'LAWRENCEVILLE',
      'MC KENNEY',
      'MEREDITHVILLE',
      'NEWSOMS',
      'PRINCE GEORGE',
      'RAWLINGS',
      'SEDLEY',
      'SKIPPERS',
      'SPRING GROVE',
      'STONY CREEK',
      'SURRY',
      'SUSSEX',
      'SUTHERLAND',
      'VALENTINES',
      'WALASKAEFIELD',
      'WARFIELD',
      'WAVERLY',
      'WHITE PLAINS',
      'WILSONS',
      'YALE',
      'ZUNI',
      'CLAREMONT',
      'FARMVILLE',
      'BASKERVILLE',
      'BOYDTON',
      'BRACEY',
      'BRODNAX',
      'BUCKINGHAM',
      'BURKEVILLE',
      'CHARLOTTE COURT HOUSE',
      'CHASE CITY',
      'CLARKSVILLE',
      'CREWE',
      'CULLEN',
      'DILLWYN',
      'DRALASKAES BRANCH',
      'DUNDAS',
      'EVERGREEN',
      'FORT MITCHELL',
      'GREEN BAY',
      'HAMPDEN SYDNEY',
      'KENBRIDGE',
      'KEYSVILLE',
      'LA CROSSE',
      'LUNENBURG',
      'MEHERRIN',
      'NOTTOWAY',
      'PAMPLIN',
      'PHENIX',
      'PROSPECT',
      'RANDOLPH',
      'RED HOUSE',
      'RED OALASKA',
      'RICE',
      'SAXE',
      'SKIPWITH',
      'SOUTH HILL',
      'VICTORIA',
      'WYLLIESBURG',
      'ROANOKE',
      'ARARAT',
      'AXTON',
      'BASSETT',
      'BELSPRING',
      'BENT MOUNTAIN',
      'BLACKSBURG',
      'BLUE RIDGE',
      'BOONES MILL',
      'BUCHANAN',
      'CALLAWAY',
      'CHRISTIANSBURG',
      'CASCADE',
      'CATAWBA',
      'CHECK',
      'CLAUDVILLE',
      'CLOVERDALE',
      'COLLINSVILLE',
      'COPPER HILL',
      'CRITZ',
      'DALEVILLE',
      'DUBLIN',
      'EAGLE ROCK',
      'EGGLESTON',
      'ELLISTON',
      'FERRUM',
      'FIELDALE',
      'FINCASTLE',
      'FLOYD',
      'GLADE HILL',
      'GLEN LYN',
      'GOLDBOND',
      'GOODVIEW',
      'HARDY',
      'HENRY',
      'HUDDLESTON',
      'INDIAN VALLEY',
      'MC COY',
      'MARTINSVILLE',
      'MEADOWS OF DAN',
      'MONETA',
      'MONTVALE',
      'NARROWS',
      'NEWBERN',
      'NEW CASTLE',
      'NEWPORT',
      'NEW RIVER',
      'ORISKANY',
      'PAINT BANK',
      'PARROTT',
      'PATRICK SPRINGS',
      'PEARISBURG',
      'PEMBROKE',
      'PENHOOK',
      'PILOT',
      'PITTSVILLE',
      'RADFORD',
      'REDWOOD',
      'RICH CREEK',
      'RIDGEWAY',
      'RINER',
      'RIPPLEMEAD',
      'ROCKY MOUNT',
      'SALEM',
      'SANDY LEVEL',
      'SHAWSVILLE',
      'SPENCER',
      'STAFFORDSVILLE',
      'STANLEYTOWN',
      'STUART',
      'THAXTON',
      'TROUTVILLE',
      'UNION HALL',
      'VESTA',
      'VILLAMONT',
      'VINTON',
      'WIRTZ',
      'WOOLWINE',
      'BRISTOL',
      'ABINGDON',
      'ANDOVER',
      'APPALACHIA',
      'BEE',
      'BEN HUR',
      'BIG STONE GAP',
      'BIRCHLEAF',
      'BLACKWATER',
      'CASTLEWOOD',
      'CLEVELAND',
      'CLINCHCO',
      'CLINTWOOD',
      'COEBURN',
      'DAMASCUS',
      'DANTE',
      'DAVENPORT',
      'DRYDEN',
      'DUFFIELD',
      'DUNGANNON',
      'EAST STONE GAP',
      'EWING',
      'FORT BLACKMORE',
      'GATE CITY',
      'HAYSI',
      'HILTONS',
      'HONALASKAER',
      'JONESVILLE',
      'KEOKEE',
      'LEBANON',
      'MC CLURE',
      'MENDOTA',
      'NICKELSVILLE',
      'NORA',
      'NORTON',
      'PENNINGTON GAP',
      'POUND',
      'ROSEDALE',
      'ROSE HILL',
      'SAINT CHARLES',
      'SAINT PAUL',
      'STONEGA',
      'TRAMMEL',
      'WEBER CITY',
      'WHITETOP',
      'WISE',
      'PULASKI',
      'ATKINS',
      'AUSTINVILLE',
      'BARREN SPRINGS',
      'BASTIAN',
      'BLAND',
      'BROADFORD',
      'CANA',
      'CERES',
      'CHILHOWIE',
      'CRIPPLE CREEK',
      'CROCKETT',
      'DRAPER',
      'DUGSPUR',
      'ELK CREEK',
      'EMORY',
      'FANCY GAP',
      'FRIES',
      'GALAX',
      'GLADE SPRING',
      'HILLSVILLE',
      'HIWASSEE',
      'INDEPENDENCE',
      'IVANHOE',
      'LAMBSBURG',
      'LAUREL FORK',
      'MARION',
      'MAX MEADOWS',
      'MEADOWVIEW',
      'MOUTH OF WILSON',
      'ROCKY GAP',
      'RURAL RETREAT',
      'SALTVILLE',
      'SEVEN MILE FORD',
      'SPEEDWELL',
      'SUGAR GROVE',
      'TANNERSVILLE',
      'TROUT DALE',
      'VOLNEY',
      'WILLIS',
      'WOODLAWN',
      'WYTHEVILLE',
      'STAUNTON',
      'AUGUSTA SPRINGS',
      'BACOVA',
      'BLUE GRASS',
      'BROWNSBURG',
      'BUENA VISTA',
      'CHURCHVILLE',
      'CLIFTON FORGE',
      'COVINGTON',
      'CRAIGSVILLE',
      'CRIMORA',
      'DEERFIELD',
      'DOE HILL',
      'FAIRFIELD',
      'FORT DEFIANCE',
      'GLEN WILTON',
      'GOSHEN',
      'GREENVILLE',
      'GROTTOES',
      'HEAD WATERS',
      'HOT SPRINGS',
      'IRON GATE',
      'LEXINGTON',
      'LOW MOOR',
      'MC DOWELL',
      'MIDDLEBROOK',
      'MILLBORO',
      'MINT SPRING',
      'MONTEBELLO',
      'MONTEREY',
      'MOUNT SIDNEY',
      'MUSTOE',
      'NEW HOPE',
      'PORT REPUBLIC',
      'RAPHINE',
      'ROCKBRIDGE BATHS',
      'SELMA',
      'STEELES TAVERN',
      'STUARTS DRAFT',
      'SWOOPE',
      'VERONA',
      'VESUVIUS',
      'WARM SPRINGS',
      'WEST AUGUSTA',
      'WEYERS CAVE',
      'WILLIAMSVILLE',
      'LYNCHBURG',
      'ALTAVISTA',
      'ALTON',
      'AMHERST',
      'APPOMATTOX',
      'BEDFORD',
      'BIG ISLAND',
      'BLAIRS',
      'BROOKNEAL',
      'BUFFALO JUNCTION',
      'CALLANDS',
      'CHATHAM',
      'CLIFFORD',
      'CLOVER',
      'CLUSTER SPRINGS',
      'COLEMAN FALLS',
      'CONCORD',
      'CRYSTAL HILL',
      'DANVILLE',
      'DRY FORK',
      'EVINGTON',
      'FOREST',
      'GLADSTONE',
      'GLADYS',
      'GLASGOW',
      'GOODE',
      'GRETNA',
      'HALIFAX',
      'HOWARDSVILLE',
      'HURT',
      'JAVA',
      'KEELING',
      'LONG ISLAND',
      'LOWRY',
      'LYNCH STATION',
      'MADISON HEIGHTS',
      'MONROE',
      'NARUNA',
      'NATHALIE',
      'NATURAL BRIDGE',
      'NATURAL BRIDGE STATION',
      'NELSON',
      'NORWOOD',
      'REPUBLICAN GROVE',
      'RINGGOLD',
      'RUSTBURG',
      'SCOTTSBURG',
      'SCOTTSVILLE',
      'SOUTH BOSTON',
      'SPOUT SPRING',
      'SUTHERLIN',
      'SWEET BRIAR',
      'VERNON HILL',
      'VIRGILINA',
      'WINGINA',
      'AMONATE',
      'BANDY',
      'BIG ROCK',
      'BISHOP',
      'BLUEFIELD',
      'BOISSEVAIN',
      'BREALASKAS',
      'BURKES GARDEN',
      'CEDAR BLUFF',
      'DORAN',
      'FALLS MILLS',
      'GRUNDY',
      'HARMAN',
      'HORSEPEN',
      'HURLEY',
      'JEWELL RIDGE',
      'KEEN MOUNTAIN',
      'MAVISDALE',
      'MAXIE',
      'NORTH TAZEWELL',
      'OALASKAWOOD',
      'PILGRIMS KNOB',
      'POCAHONTAS',
      'POUNDING MILL',
      'RAVEN',
      'RED ASH',
      'RICHLANDS',
      'ROWE',
      'SHORTT GAP',
      'SWORDS CREEK',
      'TAZEWELL',
      'VANSANT',
      'WHITEWOOD',
      'WOLFORD'
    ]
  },
  VT: {
    code: 'VT',
    name: 'Vermont',
    cities: [
      'WHITE RIVER JUNCTION',
      'ASCUTNEY',
      'BARNARD',
      'BETHEL',
      'BRADFORD',
      'BRIDGEWATER',
      'BRIDGEWATER CORNERS',
      'BROOKFIELD',
      'BROWNSVILLE',
      'CHELSEA',
      'CORINTH',
      'EAST CORINTH',
      'EAST RANDOLPH',
      'EAST RYEGATE',
      'EAST THETFORD',
      'FAIRLEE',
      'GROTON',
      'HARTFORD',
      'HARTLAND',
      'HARTLAND FOUR CORNERS',
      'MC INDOE FALLS',
      'NEWBURY',
      'NORTH HARTLAND',
      'NORTH POMFRET',
      'NORTH THETFORD',
      'NORWICH',
      'PLYMOUTH',
      'POST MILLS',
      'QUECHEE',
      'RANDOLPH',
      'RANDOLPH CENTER',
      'READING',
      'SHARON',
      'SOUTH POMFRET',
      'SOUTH ROYALTON',
      'SOUTH RYEGATE',
      'SOUTH STRAFFORD',
      'SOUTH WOODSTOCK',
      'STRAFFORD',
      'TAFTSVILLE',
      'THETFORD',
      'THETFORD CENTER',
      'TUNBRIDGE',
      'VERSHIRE',
      'WELLS RIVER',
      'WEST FAIRLEE',
      'WEST HARTFORD',
      'WEST NEWBURY',
      'WEST TOPSHAM',
      'WILDER',
      'WINDSOR',
      'WOODSTOCK',
      'BELLOWS FALLS',
      'CAMBRIDGEPORT',
      'CAVENDISH',
      'CHESTER',
      'CHESTER DEPOT',
      'GRAFTON',
      'LONDONDERRY',
      'LUDLOW',
      'NORTH SPRINGFIELD',
      'PERKINSVILLE',
      'PERU',
      'PROCTORSVILLE',
      'SAXTONS RIVER',
      'SOUTH LONDONDERRY',
      'SPRINGFIELD',
      'WESTMINSTER',
      'WESTMINSTER STATION',
      'WESTON',
      'BENNINGTON',
      'ARLINGTON',
      'DORSET',
      'EAST ARLINGTON',
      'EAST DORSET',
      'MANCHESTER',
      'MANCHESTER CENTER',
      'NORTH BENNINGTON',
      'NORTH POWNAL',
      'POWNAL',
      'SHAFTSBURY',
      'BRATTLEBORO',
      'BONDVILLE',
      'EAST DOVER',
      'JACKSONVILLE',
      'JAMAICA',
      'MARLBORO',
      'NEWFANE',
      'PUTNEY',
      'READSBORO',
      'SOUTH NEWFANE',
      'STAMFORD',
      'TOWNSHEND',
      'VERNON',
      'WARDSBORO',
      'WEST DOVER',
      'WEST DUMMERSTON',
      'WEST HALIFAX',
      'WEST TOWNSHEND',
      'WEST WARDSBORO',
      'WHITINGHAM',
      'WILLIAMSVILLE',
      'WILMINGTON',
      'BURLINGTON',
      'SOUTH BURLINGTON',
      'WINOOSKI',
      'COLCHESTER',
      'ALBURG',
      'BALASKAERSFIELD',
      'BELVIDERE CENTER',
      'BRISTOL',
      'CAMBRIDGE',
      'CHARLOTTE',
      'EAST BERKSHIRE',
      'EAST FAIRFIELD',
      'ENOSBURG FALLS',
      'ESSEX',
      'ESSEX JUNCTION',
      'FAIRFAX',
      'FAIRFIELD',
      'FERRISBURG',
      'FRANKLIN',
      'GRAND ISLE',
      'HIGHGATE CENTER',
      'HIGHGATE SPRINGS',
      'HINESBURG',
      'HUNTINGTON',
      'ISLE LA MOTTE',
      'JEFFERSONVILLE',
      'JERICHO',
      'JONESVILLE',
      'MILTON',
      'MONKTON',
      'MONTGOMERY',
      'MONTGOMERY CENTER',
      'NEW HAVEN',
      'NORTH FERRISBURG',
      'NORTH HERO',
      'RICHFORD',
      'RICHMOND',
      'SAINT ALBANS',
      'SAINT ALBANS BAY',
      'SHELBURNE',
      'SHELDON',
      'SHELDON SPRINGS',
      'SOUTH HERO',
      'STARKSBORO',
      'SWANTON',
      'UNDERHILL',
      'UNDERHILL CENTER',
      'VERGENNES',
      'WATERVILLE',
      'WESTFORD',
      'WILLISTON',
      'MONTPELIER',
      'ADAMANT',
      'BARRE',
      'CABOT',
      'CALAIS',
      'EAST BARRE',
      'EAST CALAIS',
      'EAST MONTPELIER',
      'EDEN',
      'EDEN MILLS',
      'GRANITEVILLE',
      'HYDE PARK',
      'JOHNSON',
      'LALASKAE ELMORE',
      'MARSHFIELD',
      'MORETOWN',
      'MORRISVILLE',
      'MOSCOW',
      'NORTHFIELD',
      'NORTHFIELD FALLS',
      'NORTH HYDE PARK',
      'NORTH MONTPELIER',
      'PLAINFIELD',
      'ROXBURY',
      'SOUTH BARRE',
      'WATERBURY',
      'STOWE',
      'WAITSFIELD',
      'WARREN',
      'WASHINGTON',
      'WATERBURY CENTER',
      'WEBSTERVILLE',
      'WILLIAMSTOWN',
      'WOLCOTT',
      'WOODBURY',
      'WORCESTER',
      'RUTLAND',
      'BELMONT',
      'BENSON',
      'BOMOSEEN',
      'BRANDON',
      'BRIDPORT',
      'CASTLETON',
      'CENTER RUTLAND',
      'CHITTENDEN',
      'CUTTINGSVILLE',
      'DANBY',
      'EAST MIDDLEBURY',
      'EAST POULTNEY',
      'EAST WALLINGFORD',
      'FAIR HAVEN',
      'FLORENCE',
      'FOREST DALE',
      'GAYSVILLE',
      'GRANVILLE',
      'HANCOCK',
      'HYDEVILLE',
      'KILLINGTON',
      'MIDDLEBURY',
      'MIDDLETOWN SPRINGS',
      'MOUNT HOLLY',
      'NORTH CLARENDON',
      'ORWELL',
      'PAWLET',
      'PITTSFIELD',
      'PITTSFORD',
      'POULTNEY',
      'PROCTOR',
      'RIPTON',
      'ROCHESTER',
      'RUPERT',
      'SALISBURY',
      'SHOREHAM',
      'STOCKBRIDGE',
      'WALLINGFORD',
      'WELLS',
      'WEST PAWLET',
      'WEST RUPERT',
      'WEST RUTLAND',
      'WHITING',
      'SAINT JOHNSBURY',
      'ALBANY',
      'BARNET',
      'BARTON',
      'BEEBE PLAIN',
      'CONCORD',
      'COVENTRY',
      'CRAFTSBURY',
      'CRAFTSBURY COMMON',
      'DANVILLE',
      'DERBY',
      'DERBY LINE',
      'EAST BURKE',
      'EAST CHARLESTON',
      'EAST HARDWICK',
      'EAST HAVEN',
      'EAST SAINT JOHNSBURY',
      'GLOVER',
      'GRANBY',
      'GREENSBORO',
      'GREENSBORO BEND',
      'HARDWICK',
      'IRASBURG',
      'ISLAND POND',
      'LOWELL',
      'LOWER WATERFORD',
      'LYNDON',
      'LYNDON CENTER',
      'LYNDONVILLE',
      'MORGAN',
      'NEWPORT',
      'NEWPORT CENTER',
      'NORTH CONCORD',
      'NORTH TROY',
      'ORLEANS',
      'PASSUMPSIC',
      'PEACHAM',
      'SAINT JOHNSBURY CENTER',
      'SHEFFIELD',
      'SUTTON',
      'TROY',
      'WEST BURKE',
      'WEST CHARLESTON',
      'WEST DANVILLE',
      'WESTFIELD',
      'WEST GLOVER',
      'AVERILL',
      'BEECHER FALLS',
      'CANAAN',
      'GILMAN',
      'GUILDHALL',
      'LUNENBURG',
      'NORTON'
    ]
  },
  WA: {
    code: 'WA',
    name: 'Washington',
    cities: [
      'AUBURN',
      'FEDERAL WAY',
      'BELLEVUE',
      'BLACK DIAMOND',
      'BOTHELL',
      'BURTON',
      'CARNATION',
      'DUVALL',
      'EDMONDS',
      'ENUMCLAW',
      'FALL CITY',
      'HOBART',
      'ISSAQUAH',
      'KENMORE',
      'KENT',
      'KIRKLAND',
      'LYNNWOOD',
      'MAPLE VALLEY',
      'MEDINA',
      'MERCER ISLAND',
      'MOUNTLALASKAE TERRACE',
      'NORTH BEND',
      'PACIFIC',
      'PRESTON',
      'RAVENSDALE',
      'REDMOND',
      'REDONDO',
      'RENTON',
      'ROLLINGBAY',
      'SEAHURST',
      'SNOQUALMIE',
      'SNOQUALMIE PASS',
      'VASHON',
      'WOODINVILLE',
      'SAMMAMISH',
      'SEATTLE',
      'BAINBRIDGE ISLAND',
      'EVERETT',
      'ACME',
      'ANACORTES',
      'BLALASKAELY ISLAND',
      'ARLINGTON',
      'BARING',
      'BELLINGHAM',
      'BLAINE',
      'BOW',
      'BURLINGTON',
      'CLEARLALASKAE',
      'CLINTON',
      'CONCRETE',
      'CONWAY',
      'COUPEVILLE',
      'CUSTER',
      'DARRINGTON',
      'DEER HARBOR',
      'DEMING',
      'EASTSOUND',
      'EVERSON',
      'FERNDALE',
      'FREELAND',
      'FRIDAY HARBOR',
      'GOLD BAR',
      'GRANITE FALLS',
      'GREENBANK',
      'HAMILTON',
      'INDEX',
      'LA CONNER',
      'LALASKAE STEVENS',
      'NORTH LALASKAEWOOD',
      'LANGLEY',
      'LOPEZ ISLAND',
      'LUMMI ISLAND',
      'LYMAN',
      'LYNDEN',
      'MAPLE FALLS',
      'MARBLEMOUNT',
      'MARYSVILLE',
      'MONROE',
      'MOUNT VERNON',
      'MUKILTEO',
      'NOOKSACK',
      'OALASKA HARBOR',
      'OLGA',
      'ORCAS',
      'POINT ROBERTS',
      'CAMANO ISLAND',
      'ROCKPORT',
      'SEDRO WOOLLEY',
      'SHAW ISLAND',
      'SILVANA',
      'SKYKOMISH',
      'SNOHOMISH',
      'STANWOOD',
      'STARTUP',
      'SULTAN',
      'SUMAS',
      'WALDRON',
      'ANDERSON ISLAND',
      'ASHFORD',
      'BEAVER',
      'BREMERTON',
      'SILVERDALE',
      'BRINNON',
      'BUCKLEY',
      'BURLEY',
      'CARBONADO',
      'CARLSBORG',
      'CHIMACUM',
      'CLALLAM BAY',
      'DUPONT',
      'EATONVILLE',
      'GIG HARBOR',
      'ELBE',
      'FORKS',
      'FOX ISLAND',
      'GLENOMA',
      'GRAHAM',
      'PORT HADLOCK',
      'HANSVILLE',
      'INDIANOLA',
      'JOYCE',
      'KAPOWSIN',
      'KEYPORT',
      'KINGSTON',
      'LA GRANDE',
      'LALASKAEBAY',
      'LA PUSH',
      'LONGBRANCH',
      'SUMNER',
      'MANCHESTER',
      'MILTON',
      'MINERAL',
      'MORTON',
      'NEAH BAY',
      'NORDLAND',
      'OLALLA',
      'ORTING',
      'PACKWOOD',
      'PORT ANGELES',
      'PORT GAMBLE',
      'PORT LUDLOW',
      'PORT ORCHARD',
      'PORT TOWNSEND',
      'POULSBO',
      'PUYALLUP',
      'QUILCENE',
      'RANDLE',
      'RETSIL',
      'SEABECK',
      'SEKIU',
      'SEQUIM',
      'SOUTH COLBY',
      'SOUTH PRAIRIE',
      'SOUTHWORTH',
      'SPANAWAY',
      'STEILACOOM',
      'SUQUAMISH',
      'TRACYTON',
      'VAUGHN',
      'WAUNA',
      'WILKESON',
      'LONGMIRE',
      'PARADISE INN',
      'TACOMA',
      'CAMP MURRAY',
      'MCCHORD AFB',
      'LALASKAEWOOD',
      'UNIVERSITY PLACE',
      'OLYMPIA',
      'LACEY',
      'TUMWATER',
      'ABERDEEN',
      'ADNA',
      'ALLYN',
      'AMANDA PARK',
      'BAY CENTER',
      'BELFAIR',
      'BUCODA',
      'CENTRALIA',
      'CHEHALIS',
      'CINEBAR',
      'COPALIS BEACH',
      'COPALIS CROSSING',
      'COSMOPOLIS',
      'CURTIS',
      'DOTY',
      'EAST OLYMPIA',
      'ELMA',
      'ETHEL',
      'GALVIN',
      'GRAPEVIEW',
      'GRAYLAND',
      'HOODSPORT',
      'HOQUIAM',
      'HUMPTULIPS',
      'LEBAM',
      'LILLIWAUP',
      'LITTLEROCK',
      'MCCLEARY',
      'MCKENNA',
      'MALONE',
      'MATLOCK',
      'MENLO',
      'MOCLIPS',
      'MONTESANO',
      'MOSSYROCK',
      'NAPAVINE',
      'NEILTON',
      'OALASKAVILLE',
      'OCEAN SHORES',
      'ONALASKA',
      'PACIFIC BEACH',
      'PE ELL',
      'QUINAULT',
      'RAINIER',
      'RAYMOND',
      'ROCHESTER',
      'ROY',
      'RYDERWOOD',
      'SALKUM',
      'SATSOP',
      'SHELTON',
      'SILVER CREEK',
      'SOUTH BEND',
      'TAHOLAH',
      'TAHUYA',
      'TENINO',
      'TOKELAND',
      'TOLEDO',
      'UNION',
      'VADER',
      'WESTPORT',
      'WINLOCK',
      'YELM',
      'AMBOY',
      'APPLETON',
      'ARIEL',
      'BATTLE GROUND',
      'BINGEN',
      'BRUSH PRAIRIE',
      'CAMAS',
      'CARROLLS',
      'CARSON',
      'CASTLE ROCK',
      'CATHLAMET',
      'CENTERVILLE',
      'CHINOOK',
      'COUGAR',
      'DALLESPORT',
      'GLENWOOD',
      'GOLDENDALE',
      'GRAYS RIVER',
      'HEISSON',
      'HUSUM',
      'ILWACO',
      'KALAMA',
      'KELSO',
      'KLICKITAT',
      'LA CENTER',
      'LONG BEACH',
      'LONGVIEW',
      'LYLE',
      'NAHCOTTA',
      'NASELLE',
      'NORTH BONNEVILLE',
      'OCEAN PARK',
      'OYSTERVILLE',
      'RIDGEFIELD',
      'ROSBURG',
      'SEAVIEW',
      'SILVERLALASKAE',
      'SKAMOKAWA',
      'STEVENSON',
      'TOUTLE',
      'TROUT LALASKAE',
      'UNDERWOOD',
      'VANCOUVER',
      'WAHKIACUS',
      'WASHOUGAL',
      'WHITE SALMON',
      'WISHRAM',
      'WOODLAND',
      'YACOLT',
      'WENATCHEE',
      'EAST WENATCHEE',
      'ARDENVOIR',
      'BREWSTER',
      'BRIDGEPORT',
      'CARLTON',
      'CASHMERE',
      'CHELAN',
      'CHELAN FALLS',
      'CONCONULLY',
      'DRYDEN',
      'ENTIAT',
      'EPHRATA',
      'GEORGE',
      'LEAVENWORTH',
      'LOOMIS',
      'MALAGA',
      'MALOTT',
      'MANSFIELD',
      'MANSON',
      'MARLIN',
      'MAZAMA',
      'METHOW',
      'MONITOR',
      'MOSES LALASKAE',
      'OKANOGAN',
      'OMALASKA',
      'ORONDO',
      'OROVILLE',
      'PALISADES',
      'PATEROS',
      'PESHASTIN',
      'QUINCY',
      'RIVERSIDE',
      'ROCK ISLAND',
      'SOAP LALASKAE',
      'STEHEKIN',
      'STRATFORD',
      'TONASKET',
      'TWISP',
      'WARDEN',
      'WATERVILLE',
      'WAUCONDA',
      'WILSON CREEK',
      'WINTHROP',
      'YALASKAIMA',
      'BROWNSTOWN',
      'BUENA',
      'CLE ELUM',
      'COWICHE',
      'EASTON',
      'ELLENSBURG',
      'GOOSE PRAIRIE',
      'GRANDVIEW',
      'GRANGER',
      'HARRAH',
      'KITTITAS',
      'MABTON',
      'MOXEE',
      'NACHES',
      'OUTLOOK',
      'PARKER',
      'RONALD',
      'ROSLYN',
      'SELAH',
      'SOUTH CLE ELUM',
      'SUNNYSIDE',
      'THORP',
      'TIETON',
      'TOPPENISH',
      'VANTAGE',
      'WAPATO',
      'WHITE SWAN',
      'ZILLAH',
      'AIRWAY HEIGHTS',
      'CHATTAROY',
      'CHENEY',
      'COLBERT',
      'DEER PARK',
      'EDWALL',
      'ELK',
      'FAIRCHILD AIR FORCE BASE',
      'FAIRFIELD',
      'FORD',
      'FOUR LALASKAES',
      'FREEMAN',
      'GREENACRES',
      'LAMONT',
      'LATAH',
      'LIBERTY LALASKAE',
      'MARSHALL',
      'MEAD',
      'MEDICAL LALASKAE',
      'MICA',
      'NEWMAN LALASKAE',
      'NINE MILE FALLS',
      'OTIS ORCHARDS',
      'REARDAN',
      'ROCKFORD',
      'SPANGLE',
      'SPRAGUE',
      'TEKOA',
      'TUMTUM',
      'VALLEYFORD',
      'VERADALE',
      'WAVERLY',
      'WELLPINIT',
      'ADDY',
      'ALBION',
      'ALMIRA',
      'BELMONT',
      'BENGE',
      'BOYDS',
      'CHEWELAH',
      'CLAYTON',
      'COLFAX',
      'COLTON',
      'COLVILLE',
      'COULEE CITY',
      'COULEE DAM',
      'CRESTON',
      'CURLEW',
      'CUSICK',
      'DANVILLE',
      'DAVENPORT',
      'ELECTRIC CITY',
      'ELMER CITY',
      'ENDICOTT',
      'EVANS',
      'SAINT JOHN',
      'FARMINGTON',
      'FRUITLAND',
      'GARFIELD',
      'GIFFORD',
      'GRAND COULEE',
      'HARRINGTON',
      'HARTLINE',
      'HAY',
      'HUNTERS',
      'INCHELIUM',
      'IONE',
      'KELLER',
      'KETTLE FALLS',
      'LACROSSE',
      'LAMONA',
      'LAURIER',
      'LINCOLN',
      'LOON LALASKAE',
      'MALDEN',
      'MALO',
      'MARCUS',
      'METALINE',
      'METALINE FALLS',
      'MOHLER',
      'NESPELEM',
      'NEWPORT',
      'NORTHPORT',
      'OALASKAESDALE',
      'ODESSA',
      'ORIENT',
      'PALOUSE',
      'PULLMAN',
      'REPUBLIC',
      'RICE',
      'RITZVILLE',
      'ROSALIA',
      'SPRINGDALE',
      'STEPTOE',
      'THORNTON',
      'UNIONTOWN',
      'USK',
      'VALLEY',
      'WILBUR',
      'SPOKANE',
      'PASCO',
      'BENTON CITY',
      'BEVERLY',
      'BICKLETON',
      'BURBANK',
      'COLLEGE PLACE',
      'CONNELL',
      'CUNNINGHAM',
      'DAYTON',
      'DIXIE',
      'ELTOPIA',
      'HATTON',
      'HOOPER',
      'KAHLOTUS',
      'KENNEWICK',
      'LIND',
      'MESA',
      'OTHELLO',
      'PATERSON',
      'PLYMOUTH',
      'POMEROY',
      'PRESCOTT',
      'MATTAWA',
      'PROSSER',
      'RICHLAND',
      'WEST RICHLAND',
      'ROOSEVELT',
      'ROYAL CITY',
      'STARBUCK',
      'TOUCHET',
      'WAITSBURG',
      'WALLA WALLA',
      'WALLULA',
      'WASHTUCNA',
      'ANATONE',
      'ASOTIN',
      'CLARKSTON'
    ]
  },
  WI: {
    code: 'WI',
    name: 'Wisconsin',
    cities: [
      'ADELL',
      'ALLENTON',
      'ASHIPPUN',
      'BELGIUM',
      'BROOKFIELD',
      'BROWNSVILLE',
      'BUTLER',
      'CAMPBELLSPORT',
      'CASCADE',
      'CEDARBURG',
      'CEDAR GROVE',
      'CHILTON',
      'CLEVELAND',
      'CLYMAN',
      'COLGATE',
      'DELAFIELD',
      'EDEN',
      'ELKHART LALASKAE',
      'FREDONIA',
      'GERMANTOWN',
      'GLENBEULAH',
      'GRAFTON',
      'GREENBUSH',
      'HARTFORD',
      'HARTLAND',
      'HINGHAM',
      'HORICON',
      'HUBERTUS',
      'HUSTISFORD',
      'IRON RIDGE',
      'IXONIA',
      'JACKSON',
      'JOHNSON CREEK',
      'JUNEAU',
      'KEWASKUM',
      'KIEL',
      'KOHLER',
      'LANNON',
      'LEBANON',
      'LOMIRA',
      'MALONE',
      'MAYVILLE',
      'MENOMONEE FALLS',
      'MERTON',
      'MOUNT CALVARY',
      'NASHOTAH',
      'NEOSHO',
      'NEWBURG',
      'NEW HOLSTEIN',
      'NEWTON',
      'NORTH LALASKAE',
      'OALASKAFIELD',
      'OCONOMOWOC',
      'OKAUCHEE',
      'OOSTBURG',
      'PEWAUKEE',
      'PLYMOUTH',
      'PORT WASHINGTON',
      'RANDOM LALASKAE',
      'RICHFIELD',
      'RUBICON',
      'SAINT CLOUD',
      'SAUKVILLE',
      'SHEBOYGAN',
      'SHEBOYGAN FALLS',
      'SLINGER',
      'STOCKBRIDGE',
      'SUSSEX',
      'WEST BEND',
      'THERESA',
      'THIENSVILLE',
      'WALDO',
      'WATERTOWN',
      'MEQUON',
      'WOODLAND',
      'BASSETT',
      'BENET LALASKAE',
      'BIG BEND',
      'BRISTOL',
      'BURLINGTON',
      'CALEDONIA',
      'CAMP LALASKAE',
      'CUDAHY',
      'DARIEN',
      'DELAVAN',
      'DOUSMAN',
      'EAGLE',
      'EAST TROY',
      'ELKHORN',
      'ELM GROVE',
      'FONTANA',
      'FRANKSVILLE',
      'GENESEE DEPOT',
      'GENOA CITY',
      'GREENDALE',
      'HALES CORNERS',
      'FRANKLIN',
      'HELENVILLE',
      'HONEY CREEK',
      'KANSASVILLE',
      'KENOSHA',
      'NEW BERLIN',
      'LALASKAE GENEVA',
      'LYONS',
      'MUKWONAGO',
      'MUSKEGO',
      'NEW MUNSTER',
      'NORTH PRAIRIE',
      'OALASKA CREEK',
      'PALMYRA',
      'PELL LALASKAE',
      'PLEASANT PRAIRIE',
      'POWERS LALASKAE',
      'ROCHESTER',
      'SALEM',
      'SILVER LALASKAE',
      'SOMERS',
      'SOUTH MILWAUKEE',
      'SPRINGFIELD',
      'STURTEVANT',
      'SULLIVAN',
      'TREVOR',
      'TWIN LALASKAES',
      'UNION GROVE',
      'WALES',
      'WALWORTH',
      'WATERFORD',
      'WAUKESHA',
      'WHITEWATER',
      'WILLIAMS BAY',
      'WILMOT',
      'WOODWORTH',
      'ZENDA',
      'MILWAUKEE',
      'RACINE',
      'AFTON',
      'ALBANY',
      'ARENA',
      'ARGYLE',
      'AVALON',
      'AVOCA',
      'BARNEVELD',
      'BELLEVILLE',
      'BELMONT',
      'BELOIT',
      'BLACK EARTH',
      'BLANCHARDVILLE',
      'BLUE MOUNDS',
      'BLUE RIVER',
      'BRODHEAD',
      'BROOKLYN',
      'BROWNTOWN',
      'CAMBRIDGE',
      'CLINTON',
      'COBB',
      'COTTAGE GROVE',
      'CROSS PLAINS',
      'DANE',
      'DARLINGTON',
      'DEERFIELD',
      'DE FOREST',
      'DODGEVILLE',
      'EDGERTON',
      'EDMUND',
      'EVANSVILLE',
      'FOOTVILLE',
      'FORT ATKINSON',
      'GOTHAM',
      'GRATIOT',
      'HANOVER',
      'HIGHLAND',
      'HOLLANDALE',
      'JANESVILLE',
      'JEFFERSON',
      'JUDA',
      'LALASKAE MILLS',
      'LINDEN',
      'LIVINGSTON',
      'LODI',
      'LONE ROCK',
      'LOWELL',
      'MC FARLAND',
      'MARSHALL',
      'MAZOMANIE',
      'MERRIMAC',
      'MIDDLETON',
      'MILTON',
      'MINERAL POINT',
      'MONROE',
      'MONTFORT',
      'MONTICELLO',
      'MORRISONVILLE',
      'MOUNT HOREB',
      'MUSCODA',
      'NEW GLARUS',
      'OREGON',
      'ORFORDVILLE',
      'PLAIN',
      'PRAIRIE DU SAC',
      'REESEVILLE',
      'REWEY',
      'RICHLAND CENTER',
      'RIDGEWAY',
      'SAUK CITY',
      'SEXTONVILLE',
      'SHARON',
      'SHULLSBURG',
      'SOUTH WAYNE',
      'SPRING GREEN',
      'STOUGHTON',
      'SUN PRAIRIE',
      'VERONA',
      'WATERLOO',
      'WAUNALASKAEE',
      'WINDSOR',
      'WOODFORD',
      'MADISON',
      'BAGLEY',
      'BEETOWN',
      'BENTON',
      'BLOOMINGTON',
      'BOSCOBEL',
      'CASSVILLE',
      'CUBA CITY',
      'DICKEYVILLE',
      'FENNIMORE',
      'GLEN HAVEN',
      'HAZEL GREEN',
      'KIELER',
      'LANCASTER',
      'MOUNT HOPE',
      'PATCH GROVE',
      'PLATTEVILLE',
      'POTOSI',
      'PRAIRIE DU CHIEN',
      'SINSINAWA',
      'STITZER',
      'WAUZEKA',
      'WOODMAN',
      'PORTAGE',
      'ADAMS',
      'ARLINGTON',
      'BARABOO',
      'BEAVER DAM',
      'BRANDON',
      'BRIGGSVILLE',
      'BURNETT',
      'CAMBRIA',
      'CAZENOVIA',
      'COLUMBUS',
      'DALTON',
      'DELLWOOD',
      'DOYLESTOWN',
      'ELROY',
      'ENDEAVOR',
      'FAIRWATER',
      'FALL RIVER',
      'FOX LALASKAE',
      'FRIENDSHIP',
      'FRIESLAND',
      'GRAND MARSH',
      'HILLPOINT',
      'KINGSTON',
      'LALASKAE DELTON',
      'LA VALLE',
      'LIME RIDGE',
      'LOGANVILLE',
      'LYNDON STATION',
      'MARKESAN',
      'MARQUETTE',
      'MAUSTON',
      'MONTELLO',
      'NEW LISBON',
      'NORTH FREEDOM',
      'OXFORD',
      'PACKWAUKEE',
      'PARDEEVILLE',
      'POYNETTE',
      'RANDOLPH',
      'REEDSBURG',
      'RIO',
      'ROCK SPRINGS',
      'UNION CENTER',
      'WAUPUN',
      'WESTFIELD',
      'WISCONSIN DELLS',
      'WONEWOC',
      'WYOCENA',
      'AMERY',
      'BALDWIN',
      'BELDENVILLE',
      'CLAYTON',
      'CLEAR LALASKAE',
      'CUSHING',
      'DEER PARK',
      'DRESSER',
      'EAST ELLSWORTH',
      'ELLSWORTH',
      'EMERALD',
      'GLENWOOD CITY',
      'HAGER CITY',
      'HAMMOND',
      'HUDSON',
      'NEW RICHMOND',
      'OSCEOLA',
      'PRESCOTT',
      'RIVER FALLS',
      'ROBERTS',
      'SAINT CROIX FALLS',
      'SOMERSET',
      'STAR PRAIRIE',
      'WILSON',
      'WOODVILLE',
      'HOULTON',
      'ABRAMS',
      'AMBERG',
      'ARMSTRONG CREEK',
      'ATHELSTANE',
      'BLACK CREEK',
      'BONDUEL',
      'BRILLION',
      'CECIL',
      'COLEMAN',
      'COMBINED LOCKS',
      'CRIVITZ',
      'DE PERE',
      'DUNBAR',
      'FENCE',
      'FLORENCE',
      'FOREST JUNCTION',
      'GILLETT',
      'GOODMAN',
      'GREENLEAF',
      'GREEN VALLEY',
      'GRESHAM',
      'HILBERT',
      'KAUKAUNA',
      'FREEDOM',
      'KESHENA',
      'KIMBERLY',
      'KRALASKAOW',
      'LALASKAEWOOD',
      'LENA',
      'LITTLE CHUTE',
      'LITTLE SUAMICO',
      'MARINETTE',
      'MOUNTAIN',
      'NEOPIT',
      'NIAGARA',
      'NICHOLS',
      'OCONTO',
      'OCONTO FALLS',
      'ONEIDA',
      'PEMBINE',
      'PESHTIGO',
      'PORTERFIELD',
      'POTTER',
      'POUND',
      'PULASKI',
      'SEYMOUR',
      'SHAWANO',
      'SHERWOOD',
      'SHIOCTON',
      'SOBIESKI',
      'SUAMICO',
      'SURING',
      'TOWNSEND',
      'WAUSAUKEE',
      'WRIGHTSTOWN',
      'ZACHOW',
      'ALGOMA',
      'BAILEYS HARBOR',
      'BRANCH',
      'BRUSSELS',
      'CASCO',
      'COLLINS',
      'DENMARK',
      'EGG HARBOR',
      'ELLISON BAY',
      'EPHRAIM',
      'FISH CREEK',
      'FORESTVILLE',
      'FRANCIS CREEK',
      'KELLNERSVILLE',
      'KEWAUNEE',
      'LUXEMBURG',
      'MANITOWOC',
      'MAPLEWOOD',
      'MARIBEL',
      'MISHICOT',
      'NEW FRANKEN',
      'REEDSVILLE',
      'SAINT NAZIANZ',
      'SISTER BAY',
      'STURGEON BAY',
      'TISCH MILLS',
      'TWO RIVERS',
      'VALDERS',
      'WASHINGTON ISLAND',
      'WHITELAW',
      'GREEN BAY',
      'WAUSAU',
      'MARSHFIELD',
      'ABBOTSFORD',
      'AMHERST',
      'AMHERST JUNCTION',
      'ANIWA',
      'ANTIGO',
      'ARPIN',
      'ATHENS',
      'AUBURNDALE',
      'BABCOCK',
      'BIRNAMWOOD',
      'BLENKER',
      'BOWLER',
      'BROKAW',
      'BRYANT',
      'CHILI',
      'COLBY',
      'CURTISS',
      'CUSTER',
      'DEERBROOK',
      'DORCHESTER',
      'EDGAR',
      'ELAND',
      'ELCHO',
      'ELDERON',
      'ELTON',
      'GALLOWAY',
      'GILMAN',
      'JUMP RIVER',
      'GLEASON',
      'GRANTON',
      'GREENWOOD',
      'HANNIBAL',
      'HATLEY',
      'HEWITT',
      'IRMA',
      'JUNCTION CITY',
      'KEMPSTER',
      'LOYAL',
      'LUBLIN',
      'MARATHON',
      'MATTOON',
      'MEDFORD',
      'MERRILL',
      'MILLADORE',
      'MOSINEE',
      'NEILLSVILLE',
      'NEKOOSA',
      'NELSONVILLE',
      'OGEMA',
      'OWEN',
      'PEARSON',
      'PELICAN LALASKAE',
      'PHLOX',
      'PICKEREL',
      'PITTSVILLE',
      'PLOVER',
      'PORT EDWARDS',
      'RIB LALASKAE',
      'RINGLE',
      'ROSHOLT',
      'ROTHSCHILD',
      'RUDOLPH',
      'SCHOFIELD',
      'SPENCER',
      'STETSONVILLE',
      'STEVENS POINT',
      'STRATFORD',
      'SUMMIT LALASKAE',
      'TIGERTON',
      'TOMAHAWK',
      'UNITY',
      'VESPER',
      'WESTBORO',
      'WHITE LALASKAE',
      'WILLARD',
      'WISCONSIN RAPIDS',
      'WITHEE',
      'WITTENBERG',
      'RHINELANDER',
      'ARGONNE',
      'BOULDER JUNCTION',
      'BRANTWOOD',
      'BUTTERNUT',
      'CATAWBA',
      'CLAM LALASKAE',
      'CONOVER',
      'CRANDON',
      'EAGLE RIVER',
      'FIFIELD',
      'GILE',
      'GLEN FLORA',
      'GLIDDEN',
      'HARSHAW',
      'HAWKINS',
      'HAZELHURST',
      'HEAFFORD JUNCTION',
      'HURLEY',
      'IRON BELT',
      'KENNAN',
      'LAC DU FLAMBEAU',
      'LALASKAE TOMAHAWK',
      'LAND O LALASKAES',
      'LAONA',
      'LONG LALASKAE',
      'MC NAUGHTON',
      'MANITOWISH WATERS',
      'MELLEN',
      'MERCER',
      'MINOCQUA',
      'MONTREAL',
      'PARK FALLS',
      'PHELPS',
      'PHILLIPS',
      'PRENTICE',
      'PRESQUE ISLE',
      'SAINT GERMAIN',
      'SAXON',
      'SAYNER',
      'STAR LALASKAE',
      'THREE LALASKAES',
      'TONY',
      'TRIPOLI',
      'UPSON',
      'WABENO',
      'WOODRUFF',
      'LA CROSSE',
      'ALMA',
      'ALMA CENTER',
      'ARCADIA',
      'ARKDALE',
      'BANGOR',
      'BLACK RIVER FALLS',
      'BLAIR',
      'CAMP DOUGLAS',
      'CASHTON',
      'CATARACT',
      'CHASEBURG',
      'COCHRANE',
      'COON VALLEY',
      'DE SOTO',
      'DODGE',
      'EASTMAN',
      'ETTRICK',
      'FERRYVILLE',
      'FOUNTAIN CITY',
      'GALESVILLE',
      'GAYS MILLS',
      'GENOA',
      'HILLSBORO',
      'HIXTON',
      'HOLMEN',
      'HUSTLER',
      'KENDALL',
      'LA FARGE',
      'LYNXVILLE',
      'MATHER',
      'MELROSE',
      'MILLSTON',
      'MINDORO',
      'MOUNT STERLING',
      'NECEDAH',
      'NORWALK',
      'OALASKADALE',
      'ONALASKA',
      'ONTARIO',
      'READSTOWN',
      'ROCKLAND',
      'SENECA',
      'SOLDIERS GROVE',
      'SPARTA',
      'STEUBEN',
      'STODDARD',
      'TAYLOR',
      'TOMAH',
      'TREMPEALEAU',
      'TUNNEL CITY',
      'VIOLA',
      'VIROQUA',
      'WARRENS',
      'WESTBY',
      'WEST SALEM',
      'WILTON',
      'EAU CLAIRE',
      'ALTOONA',
      'ARKANSAW',
      'AUGUSTA',
      'BAY CITY',
      'BLOOMER',
      'BOYCEVILLE',
      'BOYD',
      'CADOTT',
      'CHETEK',
      'CHIPPEWA FALLS',
      'COLFAX',
      'CONRATH',
      'CORNELL',
      'DALLAS',
      'DOWNING',
      'DOWNSVILLE',
      'DURAND',
      'EAU GALLE',
      'ELEVA',
      'ELK MOUND',
      'ELMWOOD',
      'FAIRCHILD',
      'FALL CREEK',
      'GILMANTON',
      'HILLSDALE',
      'HOLCOMBE',
      'HUMBIRD',
      'INDEPENDENCE',
      'JIM FALLS',
      'KNAPP',
      'MAIDEN ROCK',
      'MENOMONIE',
      'MERRILLAN',
      'MONDOVI',
      'NELSON',
      'NEW AUBURN',
      'OSSEO',
      'PEPIN',
      'PIGEON FALLS',
      'PLUM CITY',
      'PRAIRIE FARM',
      'RIDGELAND',
      'ROCK FALLS',
      'SAND CREEK',
      'SHELDON',
      'SPRING VALLEY',
      'STANLEY',
      'STOCKHOLM',
      'STRUM',
      'THORP',
      'WHEELER',
      'WHITEHALL',
      'SPOONER',
      'ALMENA',
      'ASHLAND',
      'BALSAM LALASKAE',
      'BARRON',
      'BARRONETT',
      'BAYFIELD',
      'BENOIT',
      'BIRCHWOOD',
      'BRILL',
      'BRUCE',
      'BRULE',
      'CABLE',
      'CAMERON',
      'CENTURIA',
      'COMSTOCK',
      'CORNUCOPIA',
      'COUDERAY',
      'CUMBERLAND',
      'DANBURY',
      'DRUMMOND',
      'EDGEWATER',
      'EXELAND',
      'FOXBORO',
      'FREDERIC',
      'GORDON',
      'GRAND VIEW',
      'GRANTSBURG',
      'HAUGEN',
      'HAWTHORNE',
      'HAYWARD',
      'HERBSTER',
      'HERTEL',
      'HIGH BRIDGE',
      'IRON RIVER',
      'LADYSMITH',
      'LALASKAE NEBAGAMON',
      'LA POINTE',
      'LEWIS',
      'LUCK',
      'MAPLE',
      'MARENGO',
      'MASON',
      'MIKANA',
      'MILLTOWN',
      'MINONG',
      'ODANAH',
      'OJIBWA',
      'POPLAR',
      'PORT WING',
      'RADISSON',
      'RICE LALASKAE',
      'SARONA',
      'SHELL LALASKAE',
      'SIREN',
      'SOLON SPRINGS',
      'SOUTH RANGE',
      'SPRINGBROOK',
      'STONE LALASKAE',
      'SUPERIOR',
      'TREGO',
      'TURTLE LALASKAE',
      'WASCOTT',
      'WASHBURN',
      'WEBSTER',
      'WEYERHAEUSER',
      'WINTER',
      'OSHKOSH',
      'ALMOND',
      'APPLETON',
      'BANCROFT',
      'BEAR CREEK',
      'BERLIN',
      'BIG FALLS',
      'BUTTE DES MORTS',
      'CAROLINE',
      'CLINTONVILLE',
      'COLOMA',
      'DALE',
      'ELDORADO',
      'EMBARRASS',
      'EUREKA',
      'FOND DU LAC',
      'FREMONT',
      'GREEN LALASKAE',
      'GREENVILLE',
      'HANCOCK',
      'HORTONVILLE',
      'IOLA',
      'KING',
      'LARSEN',
      'LEOPOLIS',
      'MANAWA',
      'MARION',
      'MEDINA',
      'MENASHA',
      'NEENAH',
      'NESHKORO',
      'NEW LONDON',
      'OGDENSBURG',
      'OMRO',
      'PICKETT',
      'PINE RIVER',
      'PLAINFIELD',
      'POY SIPPI',
      'PRINCETON',
      'READFIELD',
      'REDGRANITE',
      'RIPON',
      'ROSENDALE',
      'ROYALTON',
      'SAXEVILLE',
      'SCANDINAVIA',
      'TILLEDA',
      'VAN DYNE',
      'WAUKAU',
      'WAUPACA',
      'WAUTOMA',
      'WEYAUWEGA',
      'WILD ROSE',
      'WINNEBAGO',
      'WINNECONNE'
    ]
  },
  WV: {
    code: 'WV',
    name: 'West Virginia',
    cities: [
      'BLUEFIELD',
      'ATHENS',
      'BEESON',
      'BRAMWELL',
      'BUD',
      'COVEL',
      'FREEMAN',
      'HERNDON',
      'HIAWATHA',
      'KEGLEY',
      'KELLYSVILLE',
      'LASHMEET',
      'MATOALASKAA',
      'MONTCALM',
      'NEMOURS',
      'OALASKAVALE',
      'PRINCETON',
      'ROCK',
      'WOLFE',
      'WELCH',
      'ANAWALT',
      'AVONDALE',
      'BARTLEY',
      'BERWIND',
      'BIG SANDY',
      'BRADSHAW',
      'BRENTON',
      'CAPELS',
      'CARETTA',
      'CLEAR FORK',
      'COAL MOUNTAIN',
      'COALWOOD',
      'CRUMPLER',
      'CUCUMBER',
      'CYCLONE',
      'DAVY',
      'ECKMAN',
      'ELBERT',
      'ELKHORN',
      'ENGLISH',
      'FANROCK',
      'GARY',
      'HANOVER',
      'HAVACO',
      'HEMPHILL',
      'HENSLEY',
      'IAEGER',
      'IKES FORK',
      'ISABAN',
      'ITMANN',
      'JENKINJONES',
      'JESSE',
      'JOLO',
      'JUSTICE',
      'KEYSTONE',
      'KIMBALL',
      'KOPPERSTON',
      'KYLE',
      'LECKIE',
      'LYNCO',
      'MARIANNA',
      'MATHENY',
      'MAYBEURY',
      'MOHAWK',
      'NEWHALL',
      'NEW RICHMOND',
      'NORTHFORK',
      'NORTH SPRING',
      'OCEANA',
      'PAGETON',
      'PANTHER',
      'PAYNESVILLE',
      'PINEVILLE',
      'POWHATAN',
      'PREMIER',
      'RAYSAL',
      'ROCK VIEW',
      'RODERFIELD',
      'SIMON',
      'SKYGUSTY',
      'SQUIRE',
      'SWITCHBACK',
      'THORPE',
      'TWIN BRANCH',
      'WAR',
      'WARRIORMINE',
      'WILCOE',
      'WOLF PEN',
      'WORTH',
      'WYOMING',
      'YUKON',
      'LEWISBURG',
      'FAIRLEA',
      'ALDERSON',
      'ARBOVALE',
      'ASBURY',
      'AUTO',
      'BALLARD',
      'BALLENGEE',
      'BARTOW',
      'BUCKEYE',
      'CALDWELL',
      'CASS',
      'CRAWLEY',
      'DUNMORE',
      'FOREST HILL',
      'FORT SPRING',
      'FRANKFORD',
      'GAP MILLS',
      'GLACE',
      'GRASSY MEADOWS',
      'GREEN BANK',
      'GREENVILLE',
      'HILLSBORO',
      'KIEFFER',
      'LINDSIDE',
      'MARLINTON',
      'MAXWELTON',
      'MEADOW BLUFF',
      'NEOLA',
      'PENCE SPRINGS',
      'PETERSTOWN',
      'RENICK',
      'RONCEVERTE',
      'SECONDCREEK',
      'SINKS GROVE',
      'SMOOT',
      'TALCOTT',
      'UNION',
      'WAITEVILLE',
      'WAYSIDE',
      'WHITE SULPHUR SPRINGS',
      'WILLIAMSBURG',
      'WOLFCREEK',
      'ALLOY',
      'ALUM CREEK',
      'AMEAGLE',
      'AMMA',
      'ARNETT',
      'ARTIE',
      'ASHFORD',
      'BALD KNOB',
      'BANCROFT',
      'BELLE',
      'BENTREE',
      'BICKMORE',
      'BIM',
      'BLAIR',
      'BLOOMINGROSE',
      'BLOUNT',
      'BLUE CREEK',
      'BOB WHITE',
      'BOMONT',
      'BOOMER',
      'BUFFALO',
      'CABIN CREEK',
      'CANNELTON',
      'CEDAR GROVE',
      'CHARLTON HEIGHTS',
      'CLAY',
      'CLEAR CREEK',
      'CLENDENIN',
      'CLIO',
      'CLOTHIER',
      'COLCORD',
      'COMFORT',
      'COSTA',
      'DANVILLE',
      'DAWES',
      'DEEP WATER',
      'DIXIE',
      'DOROTHY',
      'DRYBRANCH',
      'DRY CREEK',
      'DUCK',
      'DUNBAR',
      'EAST BANK',
      'ELEANOR',
      'ELKVIEW',
      'ESKDALE',
      'ETHEL',
      'FALLING ROCK',
      'FOSTER',
      'FRAZIERS BOTTOM',
      'GALLAGHER',
      'GAULEY BRIDGE',
      'GLASGOW',
      'GLEN',
      'GLEN FERRIS',
      'GORDON',
      'GRIMMS LANDING',
      'HANDLEY',
      'HANSFORD',
      'HENDERSON',
      'HERNSHAW',
      'HEWETT',
      'HOMETOWN',
      'HUGHESTON',
      'INDORE',
      'INSTITUTE',
      'IVYDALE',
      'JEFFREY',
      'KANAWHA FALLS',
      'KIMBERLY',
      'KINCAID',
      'LALASKAE',
      'LEEWOOD',
      'LEON',
      'LIBERTY',
      'LIZEMORES',
      'LONDON',
      'MADISON',
      'MAMMOTH',
      'MAYSEL',
      'MIAMI',
      'MONTGOMERY',
      'MOUNT CARBON',
      'NAOMA',
      'NEBO',
      'NELLIS',
      'NITRO',
      'OHLEY',
      'ORGAS',
      'OTTAWA',
      'OVAPA',
      'PAGE',
      'PEYTONA',
      'PINCH',
      'POCA',
      'POND GAP',
      'POWELLTON',
      'PRATT',
      'PROCIOUS',
      'RACINE',
      'RED HOUSE',
      'RIDGEVIEW',
      'ROBSON',
      'ROCK CREEK',
      'SAINT ALBANS',
      'SAXON',
      'SETH',
      'SHARON',
      'SHARPLES',
      'MOUNT OLIVE',
      'SMITHERS',
      'SOUTHSIDE',
      'SYLVESTER',
      'TAD',
      'TORNADO',
      'TURTLE CREEK',
      'TWILIGHT',
      'UNEEDA',
      'VAN',
      'WHARTON',
      'WHITESVILLE',
      'WIDEN',
      'WINFIELD',
      'WINIFREDE',
      'ADVENT',
      'ARNOLDSBURG',
      'CHLOE',
      'COTTAGEVILLE',
      'EVANS',
      'GANDEEVILLE',
      'GAY',
      'GIVEN',
      'HARTFORD',
      'KENNA',
      'LALASKAIN',
      'LEFT HAND',
      'LE ROY',
      'LETART',
      'LINDEN',
      'LOCKNEY',
      'LOONEYVILLE',
      'MASON',
      'MILLSTONE',
      'MILLWOOD',
      'MOUNT ALTO',
      'NEW HAVEN',
      'NEWTON',
      'NORMANTOWN',
      'ORMA',
      'REEDY',
      'RIPLEY',
      'SANDYVILLE',
      'SPENCER',
      'STATTS MILLS',
      'TARIFF',
      'VALLEY FORK',
      'WALLBACK',
      'WALTON',
      'WEST COLUMBIA',
      'CHARLESTON',
      'MARTINSBURG',
      'BALASKAERTON',
      'BERKELEY SPRINGS',
      'BUNKER HILL',
      'CHARLES TOWN',
      'FALLING WATERS',
      'GERRARDSTOWN',
      'GLENGARY',
      'GREAT CACAPON',
      'HALLTOWN',
      'HARPERS FERRY',
      'HEDGESVILLE',
      'INWOOD',
      'KEARNEYSVILLE',
      'LEVELS',
      'MILLVILLE',
      'PAW PAW',
      'POINTS',
      'RANSON',
      'RIDGEWAY',
      'RIPPON',
      'SHENANDOAH JUNCTION',
      'SHEPHERDSTOWN',
      'SLANESVILLE',
      'SUMMIT POINT',
      'ALKOL',
      'APPLE GROVE',
      'ASHTON',
      'BARBOURSVILLE',
      'BIG CREEK',
      'BRANCHLAND',
      'CEREDO',
      'CHAPMANVILLE',
      'CULLODEN',
      'DUNLOW',
      'EAST LYNN',
      'FORT GAY',
      'GALLIPOLIS FERRY',
      'GENOA',
      'GLENHAYES',
      'GLENWOOD',
      'GRIFFITHSVILLE',
      'HAMLIN',
      'HARTS',
      'HURRICANE',
      'JULIAN',
      'KENOVA',
      'KIAHSVILLE',
      'LAVALETTE',
      'LESAGE',
      'MIDKIFF',
      'MILTON',
      'MYRA',
      'ONA',
      'PECKS MILL',
      'POINT PLEASANT',
      'PRICHARD',
      'RANGER',
      'SALT ROCK',
      'SCOTT DEPOT',
      'SHOALS',
      'SOD',
      'SPURLOCKVILLE',
      'SUMERCO',
      'TEAYS',
      'WAYNE',
      'WEST HAMLIN',
      'WOODVILLE',
      'YAWKEY',
      'LOGAN',
      'ACCOVILLE',
      'AMHERSTDALE',
      'BAISDEN',
      'BRUNO',
      'CHAUNCEY',
      'CORA',
      'DAVIN',
      'GILBERT',
      'HAMPDEN',
      'HENLAWSON',
      'HOLDEN',
      'KISTLER',
      'LORADO',
      'LYBURN',
      'MALLORY',
      'MAN',
      'MONAVILLE',
      'MOUNT GAY',
      'OMAR',
      'PEACH CREEK',
      'SARAH ANN',
      'STIRRAT',
      'STOLLINGS',
      'SWITZER',
      'VERDUNVILLE',
      'VERNER',
      'WHARNCLIFFE',
      'WHITMAN',
      'WILKINSON',
      'YOLYN',
      'WILLIAMSON',
      'BORDERLAND',
      'BREEDEN',
      'CHATTAROY',
      'CRUM',
      'DELBARTON',
      'DINGESS',
      'EDGARTON',
      'KERMIT',
      'LENORE',
      'MATEWAN',
      'MEADOR',
      'NAUGATUCK',
      'NEWTOWN',
      'NOLAN',
      'NORTH MATEWAN',
      'RAGLAND',
      'RAWL',
      'RED JACKET',
      'THACKER',
      'VARNEY',
      'VULCAN',
      'WILSONDALE',
      'HUNTINGTON',
      'BECKLEY',
      'ALLEN JUNCTION',
      'AMIGO',
      'ANSTED',
      'BEAVER',
      'BLUE JAY',
      'BOLT',
      'BRADLEY',
      'CAMP CREEK',
      'COAL CITY',
      'COOL RIDGE',
      'CORINNE',
      'CRAB ORCHARD',
      'DANESE',
      'DANIELS',
      'DOTHAN',
      'ECCLES',
      'EDMOND',
      'FAIRDALE',
      'FAYETTEVILLE',
      'FLAT TOP',
      'GHENT',
      'GLEN DANIEL',
      'GLEN FORK',
      'GLEN JEAN',
      'GLEN MORGAN',
      'GLEN ROGERS',
      'GLEN WHITE',
      'HARPER',
      'HELEN',
      'HICO',
      'HILLTOP',
      'JONBEN',
      'JOSEPHINE',
      'KILSYTH',
      'LANARK',
      'LANSING',
      'LAYLAND',
      'LESTER',
      'LOCHGELLY',
      'LOOKOUT',
      'MABEN',
      'MABSCOTT',
      'MAC ARTHUR',
      'MC GRAWS',
      'SAULSVILLE',
      'MIDWAY',
      'MINDEN',
      'MOUNT HOPE',
      'MULLENS',
      'OALASKA HILL',
      'ODD',
      'PAX',
      'PINEY VIEW',
      'PRINCE',
      'PRINCEWICK',
      'PROSPERITY',
      'RALEIGH',
      'RAMSEY',
      'RAVENCLIFF',
      'REDSTAR',
      'RHODELL',
      'SABINE',
      'SCARBRO',
      'SHADY SPRING',
      'SKELTON',
      'SLAB FORK',
      'SOPHIA',
      'SPANISHBURG',
      'SPRAGUE',
      'STANAFORD',
      'STEPHENSON',
      'SUMMERLEE',
      'SURVEYOR',
      'TERRY',
      'THURMOND',
      'VICTOR',
      'WINONA',
      'WYCO',
      'HINTON',
      'CHARMCO',
      'CRICHTON',
      'RAINELLE',
      'ELTON',
      'GREEN SULPHUR SPRINGS',
      'HINES',
      'JUMPING BRANCH',
      'LERONA',
      'LESLIE',
      'MEADOW BRIDGE',
      'MEADOW CREEK',
      'NIMITZ',
      'PIPESTEM',
      'QUINWOOD',
      'RUPERT',
      'SANDSTONE',
      'SPRING DALE',
      'TRUE',
      'WHITE OALASKA',
      'WHEELING',
      'BEECH BOTTOM',
      'BENWOOD',
      'BETHANY',
      'CAMERON',
      'CHESTER',
      'COLLIERS',
      'DALLAS',
      'FOLLANSBEE',
      'GLEN DALE',
      'GLEN EASTON',
      'MC MECHEN',
      'MOUNDSVILLE',
      'NEW CUMBERLAND',
      'NEWELL',
      'PROCTOR',
      'NEW MANCHESTER',
      'SHORT CREEK',
      'TRIADELPHIA',
      'VALLEY GROVE',
      'WEIRTON',
      'WELLSBURG',
      'WEST LIBERTY',
      'WINDSOR HEIGHTS',
      'PARKERSBURG',
      'VIENNA',
      'MINERAL WELLS',
      'BELLEVILLE',
      'BELMONT',
      'BENS RUN',
      'BIG BEND',
      'BIG SPRINGS',
      'BROHARD',
      'CRESTON',
      'DAVISVILLE',
      'ELIZABETH',
      'FRIENDLY',
      'GRANTSVILLE',
      'MACFARLAN',
      'MIDDLEBOURNE',
      'MOUNT ZION',
      'MUNDAY',
      'NEW MARTINSVILLE',
      'PADEN CITY',
      'PALESTINE',
      'PETROLEUM',
      'PORTERS FALLS',
      'RAVENSWOOD',
      'READER',
      'ROCKPORT',
      'SAINT MARYS',
      'SHERMAN',
      'SISTERSVILLE',
      'SMITHVILLE',
      'WALKER',
      'WASHINGTON',
      'WAVERLY',
      'WILEYVILLE',
      'WILLIAMSTOWN',
      'BUCKHANNON',
      'FENWICK',
      'ERBACON',
      'CRAIGSVILLE',
      'COWEN',
      'CAMDEN ON GAULEY',
      'SNOWSHOE',
      'ADRIAN',
      'CLEVELAND',
      'DIANA',
      'FRENCH CREEK',
      'FRENCHTON',
      'HACKER VALLEY',
      'HELVETIA',
      'KANAWHA HEAD',
      'LORENTZ',
      'PICKENS',
      'ROCK CAVE',
      'SELBYVILLE',
      'TALLMANSVILLE',
      'VOLGA',
      'ELKINS',
      'BELINGTON',
      'BEVERLY',
      'BOWDEN',
      'COALTON',
      'DAILEY',
      'DAVIS',
      'RICHWOOD',
      'DRYFORK',
      'DURBIN',
      'UPPERGLADE',
      'ELLAMORE',
      'GLADY',
      'HAMBLETON',
      'HARMAN',
      'HENDRICKS',
      'HUTTONSVILLE',
      'JUNIOR',
      'KERENS',
      'MABIE',
      'MILL CREEK',
      'MONTERVILLE',
      'MONTROSE',
      'NORTON',
      'PARSONS',
      'WEBSTER SPRINGS',
      'RED CREEK',
      'SLATYFORK',
      'THOMAS',
      'VALLEY BEND',
      'VALLEY HEAD',
      'WHITMER',
      'BERGOO',
      'CLARKSBURG',
      'ALMA',
      'ALUM BRIDGE',
      'ANMOORE',
      'AUBURN',
      'BEREA',
      'BLANDVILLE',
      'BRIDGEPORT',
      'BRISTOL',
      'BROWNTON',
      'BURNSVILLE',
      'CAIRO',
      'CAMDEN',
      'CENTER POINT',
      'COXS MILLS',
      'CRAWFORD',
      'ELLENBORO',
      'FLEMINGTON',
      'FOLSOM',
      'GALLOWAY',
      'GILMER',
      'GLENVILLE',
      'GRAFTON',
      'GYPSY',
      'HARRISVILLE',
      'HAYWOOD',
      'HEPZIBAH',
      'HORNER',
      'INDEPENDENCE',
      'INDUSTRIAL',
      'IRELAND',
      'JACKSONBURG',
      'JANE LEW',
      'LINN',
      'LOST CREEK',
      'LUMBERPORT',
      'MEADOWBROOK',
      'MOATSVILLE',
      'MOUNTAIN',
      'MOUNT CLARE',
      'NEWBURG',
      'NEW MILTON',
      'ORLANDO',
      'PENNSBORO',
      'PHILIPPI',
      'PINE GROVE',
      'PULLMAN',
      'REYNOLDSVILLE',
      'ROSEMONT',
      'ROWLESBURG',
      'SALEM',
      'SAND FORK',
      'SHINNSTON',
      'SHIRLEY',
      'SIMPSON',
      'SMITHBURG',
      'SMITHFIELD',
      'SPELTER',
      'THORNTON',
      'TROY',
      'TUNNELTON',
      'WALKERSVILLE',
      'WALLACE',
      'WEST MILFORD',
      'WESTON',
      'WEST UNION',
      'WILSONBURG',
      'WYATT',
      'MORGANTOWN',
      'ALBRIGHT',
      'ARTHURDALE',
      'BLACKSVILLE',
      'BOOTH',
      'BRETZ',
      'BRUCETON MILLS',
      'CASSVILLE',
      'CORE',
      'DELLSLOW',
      'EVERETTVILLE',
      'GRANVILLE',
      'HAZELTON',
      'KINGWOOD',
      'MAIDSVILLE',
      'MASONTOWN',
      'OSAGE',
      'PENTRESS',
      'PURSGLOVE',
      'REEDSVILLE',
      'FAIRMONT',
      'BARRACKVILLE',
      'BAXTER',
      'BIG RUN',
      'BURTON',
      'CAROLINA',
      'COLFAX',
      'ENTERPRISE',
      'FAIRVIEW',
      'FARMINGTON',
      'FOUR STATES',
      'GRANT TOWN',
      'HUNDRED',
      'IDAMAY',
      'KINGMONT',
      'LITTLETON',
      'MANNINGTON',
      'METZ',
      'MONTANA MINES',
      'RACHEL',
      'RIVESVILLE',
      'WADESTOWN',
      'WANA',
      'WORTHINGTON',
      'SUTTON',
      'BIRCH RIVER',
      'CEDARVILLE',
      'CENTRALIA',
      'COPEN',
      'DILLE',
      'ELMIRA',
      'EXCHANGE',
      'FLATWOODS',
      'FRAMETOWN',
      'GASSAWAY',
      'HEATERS',
      'LITTLE BIRCH',
      'NAPIER',
      'PERKINS',
      'ROSEDALE',
      'SHOCK',
      'STRANGE CREEK',
      'WILSIE',
      'SUMMERSVILLE',
      'BELVA',
      'CALVIN',
      'CANVAS',
      'DRENNEN',
      'GILBOA',
      'JODIE',
      'KESLERS CROSS LANES',
      'LEIVASY',
      'MOUNT LOOKOUT',
      'MOUNT NEBO',
      'NALLEN',
      'NETTIE',
      'POOL',
      'SWISS',
      'TIOGA',
      'AUGUSTA',
      'AURORA',
      'BAYARD',
      'BURLINGTON',
      'CAPON BRIDGE',
      'DELRAY',
      'EGLON',
      'ELK GARDEN',
      'FORT ASHBY',
      'GORMANIA',
      'GREEN SPRING',
      'KEYSER',
      'LAHMANSVILLE',
      'MEDLEY',
      'MOUNT STORM',
      'NEW CREEK',
      'PIEDMONT',
      'RIDGELEY',
      'RIO',
      'ROMNEY',
      'SHANKS',
      'SPRINGFIELD',
      'TERRA ALTA',
      'WILEY FORD',
      'BALASKAER',
      'BRANDYWINE',
      'CIRCLEVILLE',
      'FRANKLIN',
      'HIGH VIEW',
      'LOST CITY',
      'MATHIAS',
      'RIVERTON',
      'SUGAR GROVE',
      'BLOOMERY',
      'FISHER',
      'CAPON SPRINGS',
      'JUNCTION',
      'MAYSVILLE',
      'MOOREFIELD',
      'MILAM',
      'OLD FIELDS',
      'PETERSBURG',
      'WARDENSVILLE',
      'PURGITSVILLE',
      'CABINS',
      'YELLOW SPRING',
      'UPPER TRACT',
      'SENECA ROCKS',
      'ONEGO'
    ]
  },
  WY: {
    code: 'WY',
    name: 'Wyoming',
    cities: [
      'CHEYENNE',
      'FE WARREN AFB',
      'ALBIN',
      'BOSLER',
      'BUFORD',
      'BURNS',
      'CARPENTER',
      'CENTENNIAL',
      'GARRETT',
      'GRANITE CANON',
      'HILLSDALE',
      'HORSE CREEK',
      'JELM',
      'LARAMIE',
      'MERIDEN',
      'PINE BLUFFS',
      'ROCK RIVER',
      'TIE SIDING',
      'YELLOWSTONE NATIONAL PARK',
      'WHEATLAND',
      'CHUGWATER',
      'FORT LARAMIE',
      'GLENDO',
      'GUERNSEY',
      'HARTVILLE',
      'HAWK SPRINGS',
      'HUNTLEY',
      'JAY EM',
      'LAGRANGE',
      'LANCE CREEK',
      'LINGLE',
      'LOST SPRINGS',
      'LUSK',
      'MANVILLE',
      'SHAWNEE',
      'TORRINGTON',
      'VAN TASSELL',
      'VETERAN',
      'YODER',
      'RAWLINS',
      'JEFFREY CITY',
      'BAGGS',
      'BAIROIL',
      'DIXON',
      'ELK MOUNTAIN',
      'ENCAMPMENT',
      'HANNA',
      'MEDICINE BOW',
      'SARATOGA',
      'SAVERY',
      'SINCLAIR',
      'WALCOTT',
      'WAMSUTTER',
      'WORLAND',
      'BASIN',
      'BURLINGTON',
      'BYRON',
      'CODY',
      'COWLEY',
      'DEAVER',
      'EMBLEM',
      'FRANNIE',
      'GREYBULL',
      'HAMILTON DOME',
      'HYATTVILLE',
      'KIRBY',
      'LOVELL',
      'MANDERSON',
      'MEETEETSE',
      'OTTO',
      'POWELL',
      'RALSTON',
      'SHELL',
      'TEN SLEEP',
      'THERMOPOLIS',
      'WAPITI',
      'RIVERTON',
      'ARAPAHOE',
      'CROWHEART',
      'DUBOIS',
      'FORT WASHALASKAIE',
      'HUDSON',
      'KINNEAR',
      'LANDER',
      'PAVILLION',
      'SAINT STEPHENS',
      'CASPER',
      'SHIRLEY BASIN',
      'ALCOVA',
      'ARMINTO',
      'BILL',
      'DOUGLAS',
      'EDGERTON',
      'EVANSVILLE',
      'GLENROCK',
      'HILAND',
      'KAYCEE',
      'LINCH',
      'LYSITE',
      'MIDWEST',
      'MILLS',
      'NATRONA',
      'POWDER RIVER',
      'SHOSHONI',
      'NEWCASTLE',
      'ALADDIN',
      'ALVA',
      'BEULAH',
      'CARLILE',
      'DEVILS TOWER',
      'FOUR CORNERS',
      'GILLETTE',
      'HULETT',
      'MOORCROFT',
      'OSAGE',
      'RECLUSE',
      'ROZET',
      'SUNDANCE',
      'UPTON',
      'WESTON',
      'WRIGHT',
      'SHERIDAN',
      'ARVADA',
      'BANNER',
      'BIG HORN',
      'BUFFALO',
      'CLEARMONT',
      'DAYTON',
      'LEITER',
      'PARKMAN',
      'RANCHESTER',
      'SADDLESTRING',
      'STORY',
      'WOLF',
      'WYARNO',
      'ROCK SPRINGS',
      'BONDURANT',
      'BOULDER',
      'CORA',
      'LITTLE AMERICA',
      'EVANSTON',
      'FARSON',
      'FORT BRIDGER',
      'GRANGER',
      'GREEN RIVER',
      'LONETREE',
      'LYMAN',
      'MC KINNON',
      'MOUNTAIN VIEW',
      'PINEDALE',
      'POINT OF ROCKS',
      'RELIANCE',
      'ROBERTSON',
      'SUPERIOR',
      'JACKSON',
      'KELLY',
      'MOOSE',
      'MORAN',
      'WILSON',
      'TETON VILLAGE',
      'KEMMERER',
      'AFTON',
      'AUBURN',
      'BEDFORD',
      'BIG PINEY',
      'COKEVILLE',
      'DANIEL',
      'DIAMONDVILLE',
      'ETNA',
      'FAIRVIEW',
      'FREEDOM',
      'FRONTIER',
      'GROVER',
      'LA BARGE',
      'OPAL',
      'SMOOT',
      'THAYNE',
      'ALPINE'
    ]
  }
};


export const roles = [
  {
    id: 0,
    value: 'Office Agent',
    defaulturl: '/docs_stream',
  },
  {
    id: 1,
    value: 'Senior Office Agent',
    defaulturl: '/docs_stream',
  },
  {
    id: 2,
    value: 'Warehouse Agent',
    defaulturl: '/my_flights',
  },
  {
    id: 3,
    value: 'Senior Warehouse Agent',
    defaulturl: '/my_flights',
  },
  {
    id: 4,
    value: 'Supervisor',
    defaulturl: '/my_flights',
  },
  {
    id: 5,
    value: 'Manager',
    defaulturl: '/admin/manage_users',
  },
  {
    id: 6,
    value: 'System Admin',
    defaulturl: '/admin/manage_users',
  },
  {
    id: 7,
    value: 'Accounting',
    defaulturl: '/my_flights',
  },
  {
    id: 8,
    value: 'Airline Rep',
    defaulturl: '/my_flights',
  },
  {
    id: 9,
    value: 'Senior Accounting',
    defaulturl: '/my_flights',
  },
];

export const checklistRoles = [
  {
    id: 0,
    value: 'Office Agent',
  },
  {
    id: 1,
    value: 'Senior Office Agent',
  },
  {
    id: 2,
    value: 'Supervisor',
  },
  {
    id: 3,
    value: 'Manager',
  },
  {
    id: 4,
    value: 'System Admin',
  },
  {
    id: 5,
    value: 'Accounting',
  },
  {
    id: 6,
    value: 'Airline Rep',
  },
];

export const statisticsRoles = [
  {
    id: 0,
    role: 'System Admin',
    access: ['System Admin']
  },
  {
    id: 1,
    role: 'Manager',
    access: ['Manager', 'System Admin']
  },
  {
    id: 2,
    role: 'Supervisor',
    access: ['Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 3,
    role: 'Senior Office Agent',
    access: ['Senior Office Agent', 'Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 4,
    role: 'Office Agent',
    access: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 5,
    role: 'Senior Warehouse Agent',
    access: ['Senior Warehouse Agent', 'Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 6,
    role: 'Warehouse Agent',
    access: ['Warehouse Agent', 'Senior Warehouse Agent', 'Supervisor', 'Manager', 'System Admin']
  },
  {
    id: 7,
    role: 'Senior Accounting',
    access: ['Senior Accounting', 'Manager', 'System Admin']
  },
  {
    id: 8,
    role: 'Accounting',
    access: ['Accounting', 'Senior Accounting', 'Manager', 'System Admin']
  },
  {
    id: 9,
    role: 'Airline Rep',
    access: ['Airline Rep', 'Manager', 'System Admin']
  },
  {
    id: 10,
    role: 'N/A',
    access: ['Senior Office Agent', 'Office Agent', 'Senior Warehouse Agent', 'Warehouse Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Senior Accounting', 'Airline Rep']
  }
];

export const menulist = [
  {
    link: '/admin/manage_users',
    title: 'Admin',
    icon: <img src={Admin} alt='admin' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager'],
  },
  {
    link: '/my_flights',
    title: 'My Flights',
    icon: <img src={Flights} alt='Flights' className={`${styles.whiteSvg}`} />,
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Airline Rep', 'Accounting', 'Senior Accounting'],
  },
  {
    link: '/flight_folder/notes',
    title: 'Flight Folder',
    icon: <img src={Flights} alt='Flights' className={`${styles.whiteSvg}`} />,
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Airline Rep', 'Accounting', 'Senior Accounting'],
  },
  {
    link: '/service_log',
    title: 'Service Logs',
    icon: <WebStories className={styles.whiteIcon} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/billing/flight_load_info',
    title: 'Billing Summary',
    icon: <Receipt className={styles.whiteIcon} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/bulk_approvals/flight_report',
    title: 'Approvals',
    icon: <Verified className={styles.whiteIcon} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  // {
  //   key: '6',
  //   link: '/screening_reports',
  //   title: 'Reports',
  //   icon: <img src={Reports} alt='reports' className={`${styles.whiteSvg}`} />,
  //   className: 'sider-menu-item',
  //   env: ['development', 'stage', 'production'],
  //   roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent']
  // },
  {
    link: '/docs_stream',
    title: 'Docs Stream',
    icon: <img src={Docs} alt='Docs Stream' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent']
  },
  {
    link: '/doc_archive/airwaybills',
    title: 'Doc Archive',
    icon: <img src={DocArchive} alt='doc archive' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/awb_search',
    title: 'AWB Search',
    icon: <img src={Docs} alt='Docs Stream' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/gatekeeper',
    title: 'Gatekeeper',
    icon: <img src={DocArchive} alt='gatekeeper' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/import_notification',
    title: 'Import Notification',
    icon: <img src={DocArchive} alt='gatekeeper' className={`${styles.whiteSvg}`} />,
    className: 'sider-menu-item',
    env: ['development', 'stage', 'production'],
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  }
].map((item, index) => ({
  ...item,
  key: index
}));

export const statusList = {
  not_finalized: {
    label: 'Not Finalized',
    color: 'grey',
  },
  in_progress: {
    label: 'In Progress',
    color: 'amber',
  },
  finalized: {
    label: 'Finalized',
    color: 'green',
  },
  approved: {
    label: 'Approved',
    color: 'green',
  },
  rejected: {
    label: 'Rejected',
    color: 'red',
  },
};

export const pagelist = [
  {
    link: '/admin/manage_users',
    title: 'Admin | Manage Users',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: '/admin/handled_airlines',
    title: 'Admin | Handled Airlines',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: '/admin/flight_schedule',
    title: 'Admin | Flight Schedule',
    //Roles Done
    roles: ['Senior Office Agent', 'System Admin', 'Manager']
  },
  {
    link: '/admin/white_list',
    title: 'Admin | White List - Docs Stream',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: '/admin/spl_mapping',
    title: 'Admin | SPL Mapping',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: '/admin/reports_checklist',
    title: 'Admin | Reports Checklist',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: 'admin/export',
    title: 'Admin | Export',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: '/admin/manage_contract',
    title: 'Admin | Manage Contract',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: '/admin/invoicing',
    title: 'Admin | Invoicing',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Accounting']
  },
  {
    link: '/admin/import',
    title: 'Admin | Import',
    //Roles Done
    roles: ['System Admin', 'Manager']
  },
  {
    link: '/my_flights',
    title: 'My Flights',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/docs_stream',
    title: 'Docs Stream',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent']
  },
  // {
  //   key: '11',
  //   link: '/doc_archive',
  //   title: 'Doc Archive',
  //   //Roles Done
  //   roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep']
  // },
  // {
  //   key: '12',
  //   link: '/screening_reports',
  //   title: 'Screening Reports',
  //   roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent']
  // },
  {
    link: '/flight_folder/notes',
    title: 'My Flights | Notes',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/flight_folder/buildup',
    title: 'My Flights | Buildup',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/flight_folder/import_notification',
    title: 'My Flights | Import Notification',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/flight_folder/manifest',
    title: 'My Flights | Manifest',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/flight_folder/discrepancies',
    title: 'My Flights | Discrepancies',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Airline Rep']
  },
  {
    link: '/flight_folder/flt_report',
    title: 'My Flights | Flight Report',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  // {
  //   key: '16',
  //   link: '/flight_folder/review_status',
  //   title: 'Flight Folder | Review Status',
  //   roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  // },
  {
    link: '/billing/flight_load_info',
    title: 'Billing | Flight Load Info',
    //Roles Done
    roles: ['System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/billing/service_logs',
    title: 'Billing | Service Logs',
    //Roles Done
    roles: ['System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/awb_search',
    title: 'AWB Search',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Warehouse Agent', 'Airline Rep', 'Accounting', 'Senior Accounting']
  },
  {
    link: '/doc_archive/airwaybills',
    title: 'Doc Archive | Airwaybills',
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/doc_archive/flight_manifest',
    title: 'Doc Archive | Flight Manifest',
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/doc_archive/user_training_records',
    title: 'Doc Archive | User Training Records',
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/doc_archive/other_documents',
    title: 'Doc Archive | Other Documents',
    //Roles Done
    roles: ['Office Agent', 'Senior Office Agent', 'Supervisor', 'Manager', 'System Admin', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/doc_archive/service_logs',
    title: 'Doc Archive | Service Logs',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/service_log',
    title: 'Service Logs',
    //Roles Done
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/bulk_approvals/flight_report',
    title: 'Approval | Flight Report',
    //Roles Done
    roles: ['System Admin', 'Airline Rep', 'Accounting', 'Senior Accounting']
  },
  {
    link: '/bulk_approvals/service_logs',
    title: 'Approval | Service Logs',
    //Roles Done
    roles: ['System Admin', 'Airline Rep', 'Accounting', 'Senior Accounting']
  },
  {
    link: '/product_config',
    title: 'Product Configuration',
    roles: ['System Admin']
  },
  {
    link: '/gatekeeper',
    title: 'Gatekeeper',
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  },
  {
    link: '/import_notification',
    title: 'Import Notification',
    roles: ['System Admin', 'Manager', 'Supervisor', 'Office Agent', 'Senior Office Agent', 'Warehouse Agent', 'Senior Warehouse Agent', 'Accounting', 'Airline Rep', 'Senior Accounting']
  }
].map((item, index) => ({
  ...item,
  key: index
}));

export const pageHandler = (url, role) => {
  // console.log('pageHandler', url, role);
  if (!role) {
    //logout()
    // window.location.href = '/dashboard';
    signout();
  } else {
    let row = pagelist.find((item) => (item?.link === url || item?.link?.includes(url)));
    if (row && row.roles.includes(role)) {
      if (row.roles.indexOf(role) > '-1') {
        //do nothing
      } else {
        let url = roles.find((item) => item?.value === role);
        if (url) {
          window.location.href = url.defaulturl;
        } else {
          signout();
        }
      }
    } else {
      let url = roles.find((item) => item?.value === role);
      if (url) {
        window.location.href = url.defaulturl;
      } else {
        signout();
      }
    }
  }
};

export const signout = () => {
  http.get('/user/logout').then(() => {
    cookies.remove('company_name');
    cookies.remove('company_mail');
    cookies.remove('login');
    cookies.remove('timezone');
    cookies.remove('stations');
    cookies.remove('role');
    cookies.remove('station');
    cookies.remove('company');
    cookies.remove('station_code');
    cookies.remove('user');
    cookies.remove('name');
    cookies.remove('my_flights');
    cookies.remove('ismanagec');
    cookies.remove('managecdt');
    cookies.remove('contract_types');
    cookies.remove('currency');
    window.location.href = '/login';
  });
};

export const allAirlines = {
  id: -1,
  select: '',
  airlines: 'ALL',
  code: 'ALL'
};

export const contractType = {
  cargo_handling: 'Cargo Handling',
  flight_ops: 'Flight Ops',
  drayage: 'Drayage',
};

export const adminTabs = {
  manage_users: 'Manage Users',
  handled_airlines: 'Handled Airlines',
  flight_schedule: 'Flight Schedule',
  white_list: 'White List - Docs Stream',
  spl_mapping: 'SPL Mapping',
  reports_checklist: 'Reports Checklist',
  manage_contract: 'manage Contract',
  invoicing: 'Invoicing',
  export: 'Export Operations',
  import: 'Import Operations',
};

export const screeningTabs = {
  screening_details: 'Screening Details',
  consignment_security: 'Consignment Security Declaration',
};

export const docArchiveTabs = {
  awbs: 'Airwaybills',
  flight_manifest: 'Flight Manifest',
  user_training_records: 'User Training Records',
  other_docs: 'Other Documents',
  service_logs: 'Service Logs',
};

export const flightTabs = {
  notes: 'Notes',
  buildup: 'Buildup',
  manifest: 'Manifest',
  discrepancies: 'Discrepancies',
  flt_report: 'Flight Report',
  import_notification: 'Import Notification',
};

export const appliedOnItems = [
  {
    value: 'Loose KGs | Auto',
    label: 'Loose KGs | Auto',
    desc: 'This is the description of Loose KGs | Auto',
    disable_product: true,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Loose KGs Gen | Auto',
    label: 'Loose KGs Gen | Auto',
    desc: 'This is the description of Loose KGs | Auto',
    disable_product: true,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Loose KGs SPL | Auto',
    label: 'Loose KGs SPL | Auto',
    desc: 'This is the description of Loose KGs | Auto',
    disable_product: false,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Total KGs | Auto',
    label: 'Total KGs | Auto',
    desc: 'This is the description of Total KGs | Auto',
    disable_product: true,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Intact ULD KGs | Auto',
    label: 'Intact ULD KGs | Auto',
    desc: 'This is the description of Intact ULD KGs | Auto',
    disable_product: true,
    rate_type_options: 'All',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Intact ULD LD3 | Auto',
    label: 'Intact ULD LD3 | Auto',
    desc: 'This is the description of Intact ULD LD3 | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Intact ULD LD7 | Auto',
    label: 'Intact ULD LD7 | Auto',
    desc: 'This is the description of Intact ULD LD7 | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Intact ULD MD | Auto',
    label: 'Intact ULD MD | Auto',
    desc: 'This is the description of Intact ULD MD | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Total Intact ULDs | Auto',
    label: 'Total Intact ULDs | Auto',
    desc: 'This is the description of Total Intact ULDs | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Total ULDs | Auto',
    label: 'Total ULDs | Auto',
    desc: 'This is the description of Total ULDs | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Flight | Auto',
    label: 'Flight | Auto',
    desc: 'This is the description of Flight | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: [],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  // {
  //   value: 'Flight | Manual',
  //   label: 'Flight | Manual',
  //   desc: 'This is the description of Flight | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: [],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  // {
  //   value: 'Turnaround | Manual',
  //   label: 'Turnaround | Manual',
  //   desc: 'This is the description of Turnaround | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: ['Import', 'Export', 'Transit'],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  {
    value: 'Air Waybill | Auto',
    label: 'Air Waybill | Auto',
    desc: 'This is the description of Air Waybill | Auto',
    disable_product: false,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Air Waybill (Pcs) | Auto',
    label: 'Air Waybill (Pcs) | Auto',
    desc: 'This is the description of Air Waybill (Pcs) | Auto',
    disable_product: false,
    rate_type_options: 'Stable',
    segments: ['Import', 'Export', 'Transit'],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  // {
  //   value: 'Day | Manual',
  //   label: 'Day | Manual',
  //   desc: 'This is the description of Day | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: [],
  //   recordOns: ['Day Log', 'Month Log'],
  // },
  // {
  //   value: 'Month | Manual',
  //   label: 'Month | Manual',
  //   desc: 'This is the description of Month | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: [],
  //   recordOns: ['Day Log', 'Month Log'],
  // },
  // {
  //   value: 'UN Number | Manual',
  //   label: 'UN Number | Manual',
  //   desc: ' This is the description of UN Number | Manual',
  //   disable_product: false,
  //   rate_type_options: 'Stable',
  //   segments: ['Import', 'Export', 'Transit'],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  // {
  //   value: 'Screened KG | Manual',
  //   label: 'Screened KG | Manual',
  //   desc: 'This is the description of Screened KG | Manual',
  //   disable_product: true,
  //   rate_type_options: 'All',
  //   segments: ['Import', 'Export', 'Transit'],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  // {
  //   value: 'Units (Pcs) | Manual',
  //   label: 'Units (Pcs) | Manual',
  //   desc: 'This is the description of Units (Pcs) | Manual',
  //   disable_product: true,
  //   rate_type_options: 'Stable',
  //   segments: [],
  //   recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  // },
  {
    value: 'Units | Manual',
    label: 'Units | Manual',
    desc: 'This is the description of Units (Hourly) | Manual',
    disable_product: true,
    rate_type_options: 'All',
    segments: [],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
  {
    value: 'Rent | Auto',
    label: 'Rent | Auto',
    desc: 'This is the description of Rent | Auto',
    disable_product: true,
    rate_type_options: 'Stable',
    segments: [],
    recordOns: ['Flight Report', 'Inbound Flight Report', 'Outbound Flight Report', 'Day Log', 'Month Log'],
  },
];

export const discrepancyType = [
  'Missing Cargo',
  'Found Cargo',
  'Missing Airwaybill',
  'Found Airwaybill',
  'Short Shipped',
  'Over Carried',
  'Damaged Cargo',
  'Other'
];

export const jsonTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#0000',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

export const timezones = {
  ATL: 'America/New_York',
  LAX: 'America/Los_Angeles',
  BOS: 'America/New_York',
  JFK: 'America/New_York',
  SEA: 'America/Los_Angeles',
  DTW: 'America/New_York',
  MSP: 'America/Chicago',
  SLC: 'America/Denver',
  ORD: 'America/Chicago',
  HNL: 'Pacific/Honolulu',
  SFO: 'America/Los_Angeles',
  FLL: 'America/New_York',
  MIA: 'America/New_York',
  MCO: 'America/New_York',
};

export const uldTypes = [
  {
    id: 'LD3',
    value: 'LD3',
  }, {
    id: '2X LD3',
    value: '2X LD3',
  }, {
    id: 'LD7',
    value: 'LD7',
  }, {
    id: 'MD',
    value: 'MD',
  }, {
    id: '2X MD',
    value: '2X MD',
  }
];
export const handledAs = [
  {
    id: 'Loose',
    value: 'Loose',
  },
  {
    id: 'Intact',
    value: 'Intact',
  }
];

export const AddIcon = (props) =>
  <IconButton disableRipple title={props.title || 'Add'} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={Add} alt={props.title} className={`${styles.pointer} ${styles.addIcon}`} />
  </IconButton>;
export const DollarRateIcon = (props) => 
  <IconButton disableRipple title={props.title} aria-label='rate-icon' size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.squareIcon} ${styles.mr2} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={DollarRate} alt={props.title} className={`${styles.pointer}`} width={21} height={21} />
  </IconButton>;
export const EditIcon = (props) => 
  <IconButton disableRipple title={props.title || 'Edit'} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={Edit} alt='Edit' className={styles.pointer} />
  </IconButton>;
export const LockIcon = (props) =>
  <IconButton disableRipple title={props.title} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={Lock} alt={props.title} className={styles.pointer} />
  </IconButton>;
export const UnlockIcon = (props) =>
  <IconButton disableRipple title={props.title} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={UnLock} alt={props.title} className={styles.pointer} />
  </IconButton>;
export const KeyIcon = (props) =>

  <IconButton disableRipple title={props.title} aria-label='Password' size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={Key} alt={props.title} className={styles.pointer} />
  </IconButton>;

export const LocationIcon = (props) =>
  <IconButton disableRipple title={props.title} aria-label='Location' size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={Location} alt='Location' className={styles.pointer} />
  </IconButton>;
export const DeleteIcon = (props) => 
  <IconButton disableRipple title={props.title || 'Delete Record'} aria-label='delete' size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={Delete} alt='Delete' className={`${styles.pointer} ${styles.primarySvg}`} />
  </IconButton>;
export const BinoIcon = (props) => 
  <IconButton disableRipple title={props.title} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={Bino} alt={props.title} className={`${styles.pointer}`} />
  </IconButton>;
export const AttachmentIcon = (props) => 
  <IconButton disableRipple title={props.title} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <AttachFile fontSize='small' />
  </IconButton>;
export const ExcelIcon = (props) => 
  <IconButton disableRipple title={props.title} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={Excel} alt={props.title} className={`${styles.pointer} ${styles.greenSvg}`} />
  </IconButton>;
export const PDFIcon = (props) =>
  <IconButton disableRipple title={props.title} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={PDF} alt={props.title} className={`${styles.pointer}`} />
  </IconButton>;
export const StickyNoteIcon = (props) => 
  <IconButton disableRipple title={props.title} aria-label={props.title} size='small' disabled={props.disabled} onClick={props.onClick} className={`${styles.p0} ${props.className}`} classes={{ disabled: styles.disabledImg }}>
    <img src={StickyNote} alt={'sticky note'} className={`${styles.pointer}`} height={'15px'} />
  </IconButton>;
export const CloseIconBtn = (props) =>
  <IconButton
    aria-label='close'
    onClick={props.onClick}
    sx={{ ...CloseIconProps }}
    size='small'
  >
    <CloseIcon />
  </IconButton>;
export const DocIcon = (props) => <Tooltip title={props.title} {...props}><img src={Doc} alt='doc-icon' className={`${styles.pointer} ${styles.primarySvg}`} /></Tooltip>;
export const ViewGreenIcon = () => <Tooltip title=''><img src={ViewGreen} alt='view-icon' className={`${styles.pointer}`} /></Tooltip>;
export const ViewIcon = () => <Tooltip title=''><img src={View} alt='view-icon' className={`${styles.pointer}`} /></Tooltip>;
export const ArrowTopIcon = (props) => <Tooltip title='' {...props}><img src={ArrowTop} alt='arrow-icon' className={`${styles.pointer}`} /></Tooltip>;
export const SaveTickIcon = (props) => <Tooltip title='' {...props}><img src={SaveTick} alt='save-icon' className={`${styles.pointer}`} /></Tooltip>;
export const ClearXIcon = (props) => <Tooltip title='Clear'{...props}><span className={`${styles.pointer} ${styles.formClear}`}>x</span></Tooltip>;
export const TickIcon = () => <Tooltip title=''><img src={Tick} alt='tick-icon' className={`${styles.pointer}`} /></Tooltip>;
export const UnTickIcon = () => <Tooltip title=''><img src={UnTick} alt='icon' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteCameraIcon = () => <Tooltip title=''><img src={WhiteCamera} alt='camera-icon' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteEditIcon = () => <Tooltip title=''><img src={WhiteEdit} alt='edit-icon' className={`${styles.pointer}`} /></Tooltip>;
export const WhiteDocIcon = () => <Tooltip title=''><img src={WhiteDoc} alt='doc-icon' className={`${styles.pointer}`} /></Tooltip>;
export const BellIcon = () => <Tooltip title=''><img src={Bell} alt='bell-icon' className={`${styles.pointer}`} /></Tooltip>;
export const UploadIcon = () => <Tooltip title='Upload File'><img src={Upload} alt='Upload' className={`${styles.pointer}`} /></Tooltip>; // Do not change to Iconbutton - creates button within button issue on Tabs
export const DownloadIcon = (props) => <Tooltip title='Download' {...props}><img src={Download} alt='download' className={`${styles.pointer}`} /></Tooltip>;
export const PageIcon = () => <Tooltip title=''><img src={Page} alt='page-icon' className={`${styles.pointer}`} /></Tooltip>;
export const CameraIcon = () => <Tooltip title=''><img src={Camera} alt='camera-icon' className={`${styles.pointer}`} /></Tooltip>;
export const EcoIcon = () => <Tooltip title=''><img src={Eco} alt='eco-icon' className={`${styles.pointer}`} /></Tooltip>;
export const BrowseIcon = () => <Tooltip title='Browse file'><MoreHoriz /></Tooltip>;
export const ExclamationIcon = (props) => <Tooltip title={props.title} {...props}><img src={Exclamation} alt='warning-icon' /></Tooltip>;

export const DateTimeFormat = 'DDMMMYY HH:mm';
export const DateTimeSecFormat = 'DDMMMYY HH:mm:ss';
export const DateFormat = 'DDMMMYY';
export const MonthFormat = 'MMM YY';


export const platforms = [
  'Pax',
  'Freighter',
  'Domestic Pax',
  'Domestic Freighter',
  'Truck'
];

export const LOCALE_OBJ = {
  locale: 'en-US',
  // options: {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // },
};

export const statusColor = (status) => {
  let color = 'var(--not_finalized)';
  if (status) {
    if (status === 'Finalized') {
      color = 'var(--finalized)';
    } else if (status === 'Submitted') { //inprogress
      color = 'var(--in_progress)';
    } else if (status === 'Rejected') {
      color = 'var(--rejected)';
    } else if (status === 'Reopen') {
      color = 'var(--rejected)';
    } else if (status === 'Approved') {
      color = 'var(--approved)';
    }
  }
  return color;
};