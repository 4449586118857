import React from 'react';
import { Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, Skeleton, Tooltip, Typography } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import styles from '../../../custom/style.module.css';
import flightStyles from './tabs/flightreport/reports.module.css';
import { dateTolocale } from 'utils/converter';
class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      anchorEl: null,
    };
  }
  componentDidMount() {}

  openHistory = (e=null) => {
    this.setState({ anchorEl: e.currentTarget }, () => this.props.fetchHistory());
  };

  closeHistory = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    return (<>
      <Tooltip title='View History'>
        <IconButton 
          disableRipple
          disableFocusRipple
          sx={{ zIndex: 1 }}
          onClick={(e) => this.openHistory(e)}><HistoryIcon color='primary' fontSize='small' />
          <Typography variant='span' color='primary' fontSize='0.8rem' fontWeight={500} lineHeight={'normal'} pl={1}>View History</Typography>
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(this.state.anchorEl)}
        anchorEl={this.state.anchorEl}
        onClose={this.closeHistory} 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List sx={{
          width: '100%',
          maxWidth: 360,
          minWidth: 320,
          bgcolor: 'background.paper', 
          p: 2,
          maxHeight: 400,
          minHeight: 200,
        }}>
          {this.props.loadingHistory ? (this.props.history_data &&  this.props.history_data.length > 0 ? this.props.history_data.map((row, index) => (
            <ListItem key={index} className={flightStyles.historyListItem}>
              <ListItemAvatar className={styles.mr2}>
                <Typography variant='span' color='white' bgcolor={row.bgColor &&row.bgColor} textTransform={'uppercase'} fontSize='10px' fontWeight={800} px={0.5} py={0.25} borderRadius={'4px'} lineHeight={'normal'} minWidth={'70px'} display={'block'} textAlign={'center'}>{row.type}</Typography>
              </ListItemAvatar>
              <ListItemText 
                className={flightStyles.historyListText} 
                primaryTypographyProps={{
                  fontSize: 12,
                  color: 'primary.main',
                  fontWeight: 500,
                }}
                secondaryTypographyProps={{ fontSize: 12 }}
                primary={row.operation_desc ? row.operation_desc : 'N/A'} 
                secondary={`${dateTolocale(row.created, 'DD MMM YYYY HH:mm')} | ${ row?.user?.first_name + ' ' + row?.user?.last_name + ` (${row?.user?.role})`}`} />
            </ListItem>
          )) : 
            <Box sx={{
              textAlign: 'center',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center', 
            }}>
              <Typography variant='span' color='textSecondary' fontSize='0.8rem'>No History Found</Typography></Box>)
            :
            Array.from({ length: 3 }).map((_, index) => <ListItem key={index}>
              <Skeleton variant='rectangular'  width={300} height={100} /> 
            </ListItem>)
          }
        </List>
      </Popover>
    </>);
  }
}


export default History;