import React from 'react';
import {
  Box,
  Dialog,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Cancel, MailOutline } from '@mui/icons-material';
import styles from '../../../custom/style.module.css';
import notificationStyles from './notification.module.css';
import PDF from '../../../assets/images/pdf.svg';
import { CustomDialogTitle, CustomDialogContent } from '../../../custom/components';
import { CloseIconBtn, DocIcon, DownloadIcon, TickIcon } from '../../../custom/constants';
import environment from 'custom/environments';
import { dateTolocale } from 'utils/converter';

const NotificationHistoryModal = (props) => {
  const { data } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title'
        open={props.modal == 'notification_history'}
        onClose={props.handleClose}
        maxWidth={'sm'}
        fullWidth
        classes={{ paper: notificationStyles.notfyHistoryModal }}
      >
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          Notification History
          <CloseIconBtn onClick={props.handleClose} />
        </CustomDialogTitle>
        <CustomDialogContent className={styles.p3}>
          <Grid container spacing={2}>
            {data && data?.map((d, index) => (
              <Grid item key={index} xs={12} spacing={2}>
                <Box sx={{
                  backgroundColor: '#e8e8e8',
                  padding: '18px',
                  borderRadius: '5px',
                }}>
                  <Typography variant='body2' color={'primary'} fontWeight='bold' mb={2}>
                    {dateTolocale(d.created, 'DD MMM YY HH:mm')} | {d.user ? d.user?.first_name + ' ' + d.user?.last_name : 'N/A'}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4.2}>
                      <Stack direction={'column'} className={`${notificationStyles.pdfWrapper} ${styles.mx0}`} maxWidth={'auto'}>
                        <img src={PDF} alt='pdf' width={'50px'} height={'60px'} />
                        <Typography variant='caption' component={'span'} textAlign={'center'} fontSize={'0.65rem'} fontWeight={500} mt={1}>{data.length - index}{data.length - index == 1 ? 'st' : data.length - index == 2 ? 'nd' : data.length - index == 3 ? 'rd' : 'th'} Arrival Notification</Typography>
                      </Stack>
                      <Stack direction='row' spacing={1} mt={1.5} mx='auto' maxWidth={'80px'} justifyContent='space-around' className={notificationStyles.historyStatusActionIcons}>
                        <DownloadIcon onClick={() => window.open(`${environment.apiService}document?key=${d.src}`, '_blank')} />
                        <DocIcon onClick={() => window.open(`${environment.apiService}document?key=${d.src}`, '_blank')} />
                      </Stack>
                    </Grid>
                    <Grid item xs>
                      <Typography variant='caption' color={'#fff'} bgcolor={'#092b4a'} fontWeight={'bold'} textAlign={'center'} display={'block'} mb={1}> Send Status</Typography>
                      {d?.mail && d?.mail?.map((mail, index) => (
                        <Stack direction='row' key={index} spacing={1} justifyContent={'space-between'}>
                          <Stack direction='row' spacing={1} alignItems='center'>
                            <MailOutline color='primary' sx={{ fontSize: 14 }} />
                            <Typography variant='caption' color={'primary'}>
                              {mail.email}
                            </Typography>
                          </Stack>
                          <div className={notificationStyles.historyStatusIcons}>
                            {mail.status === 'delivered' ? <TickIcon /> : (mail.status === 'pending' ? <Cancel sx={{ fontSize: 14 }} color='disabled' /> : <Cancel sx={{ fontSize: 14 }} color='error' />)}
                          </div>
                        </Stack>)
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
          {data.length === 0 && <Typography variant='body1' color={'textSecondary'} textAlign={'center'} pt={2}>No history found</Typography>}
        </CustomDialogContent>
      </Dialog>
    </div>
  );
};
export default NotificationHistoryModal;