import React from 'react';
import { Checkbox, Divider, Grid, Popover, Stack, Typography } from '@mui/material';
import styles from '../../../custom/style.module.css';
import notificationStyles from './notification.module.css';
import { CustomCheckbox } from 'custom/components';
import { CloseIconBtn } from 'custom/constants';
import { grey } from '@mui/material/colors';

const PartArrivalInfo = (props) => {
  const { popover, anchorEl, onClose, data } = props;

  const DETAIL_PROPS = {
    variant: 'body2',
    fontSize: '0.86rem',
    fontWeight: 500,
    color: 'inherit',
  };
  const DIVIDER_RIGHT = { borderColor: grey[300] };

  return (
    <Popover
      open={popover === 'part_arrival_info'}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{ paper: { className: notificationStyles.partArrivalModal, } }}
    >
      <Typography variant='body1' color={grey[300]} px={2} py={1} fontWeight={600}>Part Arrival Info</Typography>
      <CloseIconBtn onClick={onClose} />
      <Divider className={notificationStyles.popoverDarkTitleDivider} />

      <Grid container spacing={1} px={3} py={2}>
        {data?.parts && data.parts.map((part, index) => (
          <Grid item container spacing={2} xs={12} key={index}>
            <Grid item xs={3}>
              <Stack direction={'row'} justifyContent={'center'}>
                <Typography {...DETAIL_PROPS} minWidth={'30px'} textAlign={'right'}>{part.pcs} </Typography>
                <Typography {...DETAIL_PROPS}> &nbsp;Pcs</Typography>
              </Stack>
            </Grid>
            <Grid item xs={'auto'}>
              <Divider orientation='vertical' sx={{ ...DIVIDER_RIGHT }} />
            </Grid>
            {part.flight && (
              <Grid item xs={5}>
                <Typography {...DETAIL_PROPS} textAlign={'center'} >{part.flight?.flight_no}</Typography>
              </Grid>
            )}
            {part.flight && (<Grid item xs={'auto'}>
              <Divider orientation='vertical' sx={{ ...DIVIDER_RIGHT }} />
            </Grid>)}
            {part.flight && (
              <Grid item xs>
                <Typography {...DETAIL_PROPS}>{part.flight?.flight_date}</Typography>
              </Grid>
            )}
            {!part.flight && (
              <Grid item xs={5}>
                <Typography {...DETAIL_PROPS} textAlign={'center'} >Unknown</Typography>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>

      <Stack px={2} py={1}>
        <CustomCheckbox
          control={
            <Checkbox
              className={styles.whiteCheckbox}
              sx={{
                paddingTop: '6px',
                paddingBottom: '6px',
                color: '#fff',
                '&.Mui-checked': { color: '#fff' },
              }}
              checked={data?.next}
              onChange={(e) => props.partNotification(data.id, e.target.checked)}
              name='next' />
          }
          label='Suppress notification until complete shipment arrives.'
          className={`${notificationStyles.whiteCheckbox}`}
          classes={{ label: notificationStyles.whiteCheckboxLabel }}
        />
      </Stack>

    </Popover>
  );
};
export default PartArrivalInfo;