import React from 'react';
import evemts from 'utils/evemts';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import SelectHolidayModal from './SelectHolidayModal';
import ServiceCatalog from './ServiceCatalog';
import AdditionalServiceModal from './AdditionalServiceModal';

class ServiceCatalogContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      modal: false,
      action: false,
      serviceFields: {},
      serviceFieldsErrors: {},
      selectedHolidays: [],
      holidayDates: [],
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchServices();
    this.fetchHolidays();
    this.eventSource = evemts('admin', 'import');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.data) {
        if (msg.action == 'ADD') {
          this.setState({
            services: [...this.state.services || [], msg.data],
            serviceFields: msg.data,
          });
        }
        if (msg.action == 'UPDATE') {
          const services = [...this.state.services || []];
          const index = services.findIndex(c => msg.data && c.id == msg.data.id);
          if (index > -1) {
            services[index] = { ...msg.data };
            this.setState({
              services,
              serviceFields: msg.data,
            });
          }
          if (this.state.serviceFields.id == msg.data.id) {
            this.setState({ serviceFields: { ...msg.data } });
          }
        }
      }
      if (msg.data && msg.action && msg.action == 'DELETE') {
        const services = [...this.state.services || []];
        const index = services.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          services.splice(index, 1);
          this.setState({
            services,
            serviceFields: services[index - 1] || services[0],
          });
        }
      }
      if (msg.data && msg.action && msg.action == 'holidays') {
        this.setState({ selectedHolidays: msg.data?.value || [] });
      }
    };
  }

  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  fetchServices = () => {
    http.get('additional_services').then((res) => {
      if (res.data) {
        this.setState({ services: res.data?.data || [] });
        this.handleOpen('', 'edit', res.data?.data[0]);
      }
    });
  };

  handleOpen = (modal, action, row = { value: [{}] }) => {
    this.setState({
      modal: action == 'add' ? modal : false,
      action,
      serviceFields: row || {},
      serviceFieldsErrors: {},
    });
  };

  handleOpenDialog = (modal) => {
    this.setState({ modal });
  };

  addOperation = () => {
    this.setState({
      serviceFields: {
        ...this.state.serviceFields,
        value: [...this.state.serviceFields.value, {}]
      },
    });
  };

  removeOperation = (index) => {
    let value = [...this.state.serviceFields.value];
    value.splice(index, 1);
    this.setState({
      serviceFields: {
        ...this.state.serviceFields,
        value
      },
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
      serviceFields: {},  
      serviceFieldsErrors: {},
    });
  };

  onCloseCalendar = () => {
    if (this.state.modal === 'holidays') {
      if (JSON.stringify(this.state.holidayDates) != JSON.stringify(this.state.selectedHolidays)) {
        this.setState({ selectedHolidays: this.state.holidayDates });
      }
    }
    this.setState({
      modal: false,
      action: false,
    });
  };

  onChange = (e) => {
    let value = e.target.value;
    if (e.target.type == 'checkbox') {
      value = e.target.checked;
    }
    this.setState({
      serviceFields: {
        ...this.state.serviceFields,
        [e.target.name]: value,
      },
    });
  };

  onChangeOperation = (index, e) => {
    let value = e.target.value;
    if (e.target.type == 'checkbox') {
      value = e.target.checked;
    }
    let operations = [...this.state.serviceFields.value];
    operations[index][e.target.name] = value;
    if (e.target.name == 'unit' && value == 'kg_per_day') {
      operations[index].unit_divisor = 1;
      operations[index].free_days = 0;
    }
    this.setState({
      serviceFields: {
        ...this.state.serviceFields,
        value: operations,
      },
    });
  };

  validateForm = () => {
    let serviceFieldsErrors = {};
    let isValid = true;
    if (!this.state.serviceFields.description) {
      serviceFieldsErrors.description = 'Description is required';
      isValid = false;
    }
    if (!this.state.serviceFields.key) {
      serviceFieldsErrors.key = 'Service is required';
      isValid = false;
    }
    serviceFieldsErrors.value = [];
    if (this.state.serviceFields.value && this.state.serviceFields.value.length > 0) {
      this.state.serviceFields.value.forEach((operation, index) => {
        serviceFieldsErrors.value[index] = {};
        if (!operation.type) {
          serviceFieldsErrors.value[index] = { type: 'Type is required' };
          isValid = false;
        }
        if (!operation.unit) {
          serviceFieldsErrors.value[index].unit = 'Unit is required';
          isValid = false;
        } else {
          if (operation.unit == 'number') {
            if (!operation.unit_key) {
              serviceFieldsErrors.value[index].unit_key = 'Unit Name is required';
              isValid = false;
            }
            if (!operation.cost_per_unit) {
              serviceFieldsErrors.value[index].cost_per_unit = 'Cost Per Unit is required';
              isValid = false;
            }
          }
          if (operation.unit == 'kg') {
            if (!operation.cost_per_unit) {
              serviceFieldsErrors.value[index].cost_per_unit = 'Cost Per Unit is required';
              isValid = false;
            }
          }
          if (operation.unit == 'cost') {
            if (!operation.cost) {
              serviceFieldsErrors.value[index].cost = 'Cost is required';
              isValid = false;
            }
          }
          if (operation.unit == 'percentage') {
            if (!operation.percent) {
              serviceFieldsErrors.value[index].percent = 'Percentage is required';
              isValid = false;
            }
            if (!operation.from_service) {
              serviceFieldsErrors.value[index].from_service = 'From Service is required';
              isValid = false;
            }
          }
          if (operation.unit == 'kg_per_day') {
            if (!operation.unit_divisor) {
              serviceFieldsErrors.value[index].unit_divisor = 'Unit Divisor is required';
              isValid = false;
            }
            if (!operation.cost_per_unit) {
              serviceFieldsErrors.value[index].cost_per_unit = 'Cost Per Unit is required';
              isValid = false;
            }
            if (!operation.mim_cost_per_day) {
              serviceFieldsErrors.value[index].mim_cost_per_day = 'Minimum Cost Per Day is required';
              isValid = false;
            }
          }
        }
      });
    } else {
      serviceFieldsErrors.value_remark = 'At least one operation is required';
      isValid = false;
    }
    this.setState({ serviceFieldsErrors });
    return isValid;
  };

  addService = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let data = {
        description: this.state.serviceFields.description,
        key: this.state.serviceFields.key,
        master: this.state.serviceFields.master || null,
        default: this.state.serviceFields.default || false,
        reason: this.state.serviceFields.reason || false,
        add_info: this.state.serviceFields.add_info || false,
        value: this.state.serviceFields.value,
      };
      http.post('additional_services', data).then((res) => {
        if (res.data) {
          this.setState({
            modal: false,
            action: false,
          });
        }
      });
    }
  };

  editService = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let data = {
        description: this.state.serviceFields.description,
        key: this.state.serviceFields.key,
        master: this.state.serviceFields.master || null,
        default: this.state.serviceFields.default || false,
        reason: this.state.serviceFields.reason || false,
        add_info: this.state.serviceFields.add_info || false,
        value: this.state.serviceFields.value,
      };
      http.put(`additional_services/${this.state.serviceFields.id}`, data).then((res) => {
        if (res.data) {
          //success
        }
      });
    }
  };

  handleDeleteService = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteService(id),
    });
  };

  deleteService = (id) => {
    http.delete(`additional_services/${id}`).then((res) => {
      if (res.data) {
        this.props.setDelete({
          modal: false,
          id: false,
          anchorEl: false,
          onConfirm: () => { },
        });
      }
    });
  };

  fetchHolidays = () => {
    http.get('settings?key=holidays').then(({ data }) => {
      if (data.data) {
        let holidays = data.data.find((item) => item.key === 'holidays');
        if (holidays) {
          this.setState({
            holidayDates: holidays?.value || [],
            selectedHolidays: holidays?.value || []
          });
        }
      }
    });
  };

  handleSubmitHolidays = (e) => {
    e.preventDefault();
    http.put('setting/holidays', { value: this.state.selectedHolidays }).then(() => {
      this.onClose();
    });
  };

  handleHolidays = (e) => {
    let value = dayjs(e.$d).format('YYYY-MM-DD');
    let selectedHolidays = [...this.state.selectedHolidays];
    if (selectedHolidays.includes(value)) {
      selectedHolidays = selectedHolidays.filter(day => day !== value);
    } else {
      selectedHolidays.push(value);
    }

    this.setState({ selectedHolidays });
  };

  removeHoliday = (date) => {
    let selectedHolidays = [...this.state.selectedHolidays];
    let dateIndex = selectedHolidays.findIndex(item => item === date);
    selectedHolidays.splice(dateIndex, 1);
    this.setState({ selectedHolidays });
  };

  render() {
    return (
      <>
        <ServiceCatalog
          consignees={this.state.consignees}
          onDelteConsignee={this.onDelteConsignee}
          mails={this.state.mails}
          onChangeMail={this.onChangeMail}
          editMail={this.editMail}
          mailFields={this.state.mailFields}
          mailFieldsErrors={this.state.mailFieldsErrors}
          onRowMailClick={this.onRowMailClick}
          swap={this.swap}
          footer={this.state.footer}
          footerErrors={this.state.footerErrors}
          onChangeFooter={this.onChangeFooter}
          saveMailFooter={this.saveMailFooter}
          handleDeleteMail={this.handleDeleteMail}

          modal={this.state.modal}
          onClose={this.onClose}

          action={this.state.action}
          serviceFields={this.state.serviceFields}
          serviceFieldsErrors={this.state.serviceFieldsErrors}
          onChange={this.onChange}
          addService={this.addService}
          editService={this.editService}
          onChangeOperation={this.onChangeOperation}
          handleOpen={this.handleOpen}
          handleDeleteService={this.handleDeleteService}
          services={this.state.services}
          addOperation={this.addOperation}
          removeOperation={this.removeOperation}
          handleOpenDialog={this.handleOpenDialog}
        />
        
        <AdditionalServiceModal
          modal={this.state.modal}
          action={this.state.action}
          serviceFields={this.state.serviceFields}
          serviceFieldsErrors={this.state.serviceFieldsErrors}
          onChange={this.onChange}
          addService={this.addService}
          editService={this.editService}
          onClose={this.onClose}
          services={this.state.services}
          addOperation={this.addOperation}
          removeOperation={this.removeOperation}
          onChangeOperation={this.onChangeOperation}
        />

        <SelectHolidayModal
          modal={this.state.modal}
          action={this.state.action}
          onClose={this.onCloseCalendar}
          handleHolidays={this.handleHolidays}
          selectedHolidays={this.state.selectedHolidays}
          handleSubmitHolidays={this.handleSubmitHolidays}
          removeHoliday={this.removeHoliday}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(null, mapDispatchToProps)(ServiceCatalogContainer);