import React from 'react';
import {
  Dialog,
  Box,
  Grid,
  Stack,
  Popover,
  Typography,
} from '@mui/material';
import styles from '../../../custom/style.module.css';
import notificationStyles from './notification.module.css';
import { CustomDialogTitle, CustomDialogContent, StyledDataGrid, DataGridProps, StyledCard, CustomInputLabel, CustomTextField, CustomButton } from '../../../custom/components';
import { CloseIconBtn, DeleteIcon, EditIcon } from '../../../custom/constants';

const AddHouseAwbModal = (props) => {

  const columns = [
    {
      field: 'house_awb_no',
      headerName: 'House AWB#',
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'pcs',
      headerName: 'Pieces',
      flex: 1,
    },
    {
      field: 'wgt',
      headerName: 'Weight',
      flex: 1,
    },
    {
      field: 'o_d',
      headerName: 'Org-Dest',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div>
            {row.org + '-' + row.dest}
          </div>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      minWidth: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return !row.deleted?(
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <EditIcon onClick={() => props.onEditHouseAwb(row)} />
            <DeleteIcon onClick={(e) => props.handlePopoverOpen('delete_house_awb', e.currentTarget, row)} />
          </Stack>
        ): 'Deleted';
      }
    },
  ];


  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal == 'add_house_awb'} onClose={props.handleClose} maxWidth={'md'} fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          Add House Airwaybill
          <CloseIconBtn onClick={props.handleClose} />
        </CustomDialogTitle>
        <CustomDialogContent>
          <StyledCard>
            <Grid container spacing={3}>
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} sm={5} md={3}>
                  <CustomInputLabel htmlFor='house_awb_no'>House AWB#</CustomInputLabel>
                  <CustomTextField
                    id='house_awb_no'
                    fullWidth
                    autoFocus
                    placeholder='House AWB'
                    type='text'
                    name='house_awb_no'
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*-?\d*$/.test(value)) {
                        props.onChangeHouseAwb(e);
                      }
                    }}
                    value={props.houseAwbFields.house_awb_no || ''}
                    error={props.houseAwbErrors.house_awb_no}
                    helperText={props.houseAwbErrors.house_awb_no ? props.houseAwbErrors.house_awb_no : ''}
                    InputProps={{
                      inputProps:{
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={2.4}>
                  <CustomInputLabel htmlFor='pcs'>Pieces</CustomInputLabel>
                  <CustomTextField
                    id='pcs'
                    fullWidth
                    placeholder='Pieces'
                    type='text'
                    name='pcs'
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        props.onChangeHouseAwb(e);
                      }
                    }}
                    value={props.houseAwbFields.pcs || ''}
                    error={props.houseAwbErrors.pcs}
                    helperText={props.houseAwbErrors.pcs ? props.houseAwbErrors.pcs : ''}
                    InputProps={{
                      inputProps: {
                        inputMode: 'decimal',
                        pattern: '[0-9]*[.]?[0-9]*'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={2.4}>
                  <CustomInputLabel htmlFor='wgt'>Weight</CustomInputLabel>
                  <CustomTextField
                    id='wgt'
                    fullWidth
                    placeholder='Weight'
                    type='text'
                    name='wgt'
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*\.?\d*$/.test(value)) {
                        props.onChangeHouseAwb(e);
                      }
                    }}
                    value={props.houseAwbFields.wgt || ''}
                    error={props.houseAwbErrors.wgt}
                    helperText={props.houseAwbErrors.wgt ? props.houseAwbErrors.wgt : ''}
                    InputProps={{
                      inputProps: {
                        inputMode: 'decimal',
                        pattern: '[0-9]*[.]?[0-9]*'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={2.4}>
                  <CustomInputLabel htmlFor='o_d'>Org-Dest</CustomInputLabel>
                  <CustomTextField
                    id='o_d'
                    fullWidth
                    placeholder='Org-Dest'
                    type='text'
                    name='o_d'
                    onChange={props.onChangeHouseAwb}
                    value={props.houseAwbFields.o_d?.toUpperCase() || ''}
                    error={props.houseAwbErrors.o_d}
                    helperText={props.houseAwbErrors.o_d ? props.houseAwbErrors.o_d : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={'auto'} display={'flex'} justifyContent={'flex-end'} alignItems='flex-end'>
                  <CustomButton variant='contained' color='primary' className={notificationStyles.addButton} fullWidth onClick={() => props.addHouseAwb()}>
                    {!props.editHouseAwb ? 'Add' : 'Update'}</CustomButton>
                </Grid>
              </Grid>
              <Grid item xs={12} mt={1}>
                <div className={styles.tableWrapper}>
                  <Box className={`${styles.tableOuter} ${styles.customTable} ${notificationStyles.houseAwbTableOuter}`}>
                    <StyledDataGrid
                      {...DataGridProps}
                      rows={props.data && props.data.house_awbs && props.data.house_awbs.length > 0 ? props.data.house_awbs : []}
                      columns={columns}
                      param={props?.data?.house_awbs?.length}
                      getRowId={(row, index) => row.house_awb_no + index}
                      getRowClassName={ ({ row }) => row?.deleted ? styles.deletedRow : ''}
                    />
                  </Box>
                </div>
              </Grid>
              <Grid item xs></Grid>

              <Grid item xs={12} sm={3} md={3} lg={2}>
                <CustomButton variant='contained' color='primary' fullWidth onClick={() => props.submitHouseAwb()}>Update</CustomButton>
              </Grid>
            </Grid>
          </StyledCard>
        </CustomDialogContent>
      </Dialog>
      <Popover
        id={props.id}
        open={props.popover== 'delete_house_awb'}
        anchorEl={props.popoverAnchorEl}
        onClose={props.handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      ><div className={styles.p3}>
          <Typography variant='caption' >Are you sure you want to delete this record?</Typography>
          <Stack className={styles.pt3} spacing={1} direction='row'>
            <CustomButton variant='contained' size='small' onClick={()  => props.onDeleteHouseAwb(props.popoverData && props.popoverData)}> <Typography variant='caption' lineHeight='normal' fontWeight='bold'>Yes</Typography></CustomButton>
            <CustomButton variant='outlined' size='small' onClick={props.handlePopoverClose}> <Typography variant='caption' lineHeight='normal'>No</Typography></CustomButton>
          </Stack>
        </div>
      </Popover>
    </div>
  );
};
export default AddHouseAwbModal;