import React from 'react';
import {
  Checkbox,
  Chip,
  Dialog,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import Slider from 'react-slick';
import styles from '../../../custom/style.module.css';
import notificationStyles from './notification.module.css';
import PDF from '../../../assets/images/pdf.svg';
import { CustomDialogTitle, CustomDialogContent, StyledCard, CustomInputLabel, CustomButton, CustomTextField, InputProps, CustomCheckbox } from '../../../custom/components';
import { CloseIconBtn } from '../../../custom/constants';
import cookies from 'utils/cookies';
import { getAwbDetails } from 'utils/import';
import calculateFees from 'utils/feeCalculator';

const NextArrow = (props) => {
  const { newClass, onClick } = props;
  return (
    <IconButton
      aria-label='Next'
      onClick={onClick}
      className={newClass}
      classes={{ root: notificationStyles.sliderRightArrow }}
    >
      <ArrowRight color='primary' />
    </IconButton>
  );
};

const PrevArrow = (props) => {
  const { newClass, onClick } = props;
  return (
    <IconButton
      aria-label='Prev'
      onClick={onClick}
      className={newClass}
      classes={{ root: notificationStyles.sliderLeftArrow }}
    >
      <ArrowLeft color='primary' />
    </IconButton>
  );
};

const settings = {
  infinite: false,
  dots: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
  ]
};

const SendNotificationModal = (props) => {
  const isFirefox = navigator?.userAgent?.toLowerCase().includes('firefox');

  const awbData = getAwbDetails(props.data);
  const fees = calculateFees(props.data?.fee || [], awbData);
  const { mails, docs, mail_count, mail_footer, mail_temp } = props.data;

  const minWidth = 50; 
  const awb = 'Airwaybill'; 
  let message = `Dear Customer,\nA shipment consigned to you has arrived ${cookies.get('station_code')} at 
  ${cookies.get('company_name')}\n${awb.padEnd(minWidth, ' ')}\t: ${awbData.awb_no}`;

  if (mail_temp && mail_temp?.value) {
    mail_temp?.value?.map((item) => {
      let txt = '';
      const itemType = item.type.padEnd(minWidth, ' ');
      switch (item.type) {
        case 'Text':
          txt = `${item.name}: ${item.value}`;
          break;
        case 'Pieces':
          txt = `${itemType} ${isFirefox? '\t': '\t\t'}: ${awbData.part? `${awbData.pcs} of ${awbData.total_pcs}` : awbData.pcs}`;
          break;
        case 'Weight':
          txt = `${itemType}\t: ${parseFloat(awbData.wgt)?.toFixed(1)}K`;
          break;
        case 'O-D':
          txt = `${itemType}\t\t: ${awbData.org}-${awbData.dest}`;
          break;
        case 'Flight Number':
          txt = `${itemType}\t: ${awbData?.flight_no}`;
          break;
        case 'Arrival Date':
          txt = `${itemType}\t: ${awbData?.flight_arr}`;
          break;
        case 'Storage Starts':
          txt = `${itemType}\t: ${awbData?.storage_start}`;
          break;
        case 'Commodity':
          txt = `${itemType}\t: ${awbData?.remark}`;
          break;
      }
      message += `\n${txt}`;
    }
    );
  }

  if(fees.services?.length > 0){
    let invoice = 'Invoice';
    message += '\n\nCharges Due\n—————————';
    message += `\n${invoice.padEnd(minWidth, ' ')}${isFirefox? '\t': '\t\t'}: ${props.data?.id}`;
    fees.services.map((service) => {
      const serviceKey = service.key.padEnd(minWidth, ' ');
      message += `\n${serviceKey}\t: $ ${service.cost}`;
    });
  }


  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title'
        open={props.modal == 'send_notification'}
        onClose={props.handleClose} maxWidth={'sm'} fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          Send Notification | {awbData.awb_no}
          <CloseIconBtn onClick={props.handleClose} />
        </CustomDialogTitle>
        <CustomDialogContent>
          <form onSubmit={props.handleSubmitNotification}>
            <Grid container spacing={1.5}>
              <Grid item xs={12}>
                <Stack direction='column'>
                  <CustomInputLabel htmlFor='send_to'>To</CustomInputLabel>
                  <Stack direction='row' spacing={2} flexWrap='wrap'>
                    {mails?.map((mail, index) => (
                      <Chip key={index} label={mail.email} size='small' classes={{ label: notificationStyles.chipLabel }} />
                    ))}
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <CustomInputLabel htmlFor='cc' aria-label='Enter cc'>CC</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  variant='outlined'
                  id='cc'
                  fullWidth
                  name='cc'
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInputLabel htmlFor='subject' aria-label='Enter subject'>Subject</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  id='subject'
                  fullWidth
                  disabled
                  name='subject'
                  value={`${mail_count == 0 ? '1st' : mail_count == 1 ? '2nd' : mail_count == 2 ? '3rd' : mail_count + 'th'} Arrival Notification - Airwaybill ${awbData.awb_no} |${awbData.pcs}Pcs | ${parseFloat(awbData?.wgt || 0)?.toFixed(1)}K| ${awbData.org}-${awbData.dest}`}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInputLabel htmlFor='message_body' aria-label='Enter message body'>Message Body</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  variant='outlined'
                  id='message_body'
                  fullWidth
                  name='message_body'
                  multiline
                  disabled
                  rows={20}
                  defaultValue={message}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInputLabel htmlFor='message_footer' aria-label='Enter message footer'>Message Footer (Additional Info)</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  variant='outlined'
                  id='message_footer'
                  fullWidth
                  name='message_footer'
                  defaultValue={mail_footer && mail_footer?.value ? mail_footer?.value?.footer : ''}
                />
              </Grid>


              <Grid item xs={12}>
                <CustomInputLabel>Attachments</CustomInputLabel>
                <StyledCard className={`${styles.modalCard}`}>

                  <Slider {...settings}>
                    {docs?.map((doc, index) => (
                      <div key={index} className={styles.textCenter}>
                        <div className={`${notificationStyles.pdfWrapper}`}>
                          <img src={PDF} alt='pdf' width={'50px'} height={'60px'} />
                          <Typography variant='caption' component={'small'} textAlign={'center'}>{doc.type == 'AWB' ? 'Airwaybill' : doc.name}</Typography>
                        </div>
                        <CustomCheckbox
                          control={
                            <Checkbox inputProps={{ 'aria-label': 'include', }} disableRipple value={doc.id} name='docs' />
                          }
                          label='Include'
                          className={styles.mr0}
                        />
                      </div>
                    ))}
                  </Slider>
                </StyledCard>

              </Grid>
              <Grid item xs={12} display='flex' justifyContent='flex-end' mt={2}>
                <CustomButton variant='contained' color='primary' type='submit'>Send</CustomButton>
              </Grid>
            </Grid>
          </form>
        </CustomDialogContent>
      </Dialog>
    </div>
  );
};
export default SendNotificationModal;