
import { Button, Card, Tab, FormControlLabel, Typography, Badge, Select, InputLabel, DialogTitle, DialogActions, TextField, Divider, TableContainer, TableRow, LinearProgress, linearProgressClasses, Autocomplete, DialogContent, Table } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { styled, createTheme } from '@mui/material/styles';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import styles from '../custom/style.module.css';
import { grey } from '@mui/material/colors';

let theme = createTheme();

const DATAGRID_ROW_HEIGHT = 42;

const inputFieldProps = {
  padding: '6px 14px',
  fontSize: 'var(--input-fs)',
  lineHeight: 'normal',
  borderRadius: 'var(--ip-br)',
  '&:focus': {
    backgroundColor: theme.palette.common.white,
    // outline: '1px solid lightgrey', //check if needed
    borderRadius: 'inherit' 
  },
};

const inputDisabledProps = {
  border: 'none',
  backgroundColor: theme.palette.grey[300],
};

const inputProps = {
  borderRadius: 'var(--ip-br)',
  '& .MuiInputBase-input': { ...inputFieldProps, },
  '&.Mui-disabled': {
    ...inputDisabledProps,
    borderRadius: 'var(--ip-br)',
    WebkitTextFillColor: 'var(--primaryblue)'
  },
  '& .Mui-disabled': {
    ...inputDisabledProps,
    borderRadius: 'var(--ip-br)',
    WebkitTextFillColor: 'var(--primaryblue)'
  },
};

export const NUMBERINPUT_PROPS = {
  // '&[type=number]': { '-moz-appearance': 'textfield', },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': { display: 'none', },
  '& input[type=number]': { MozAppearance: 'textfield', },
};

export const DataGridProps = {
  columnHeaderHeight: 32,
  getRowHeight: () => DATAGRID_ROW_HEIGHT,
  rowHeight: DATAGRID_ROW_HEIGHT,
  stickyHeader: true,
  hideFooter: true,
  disableRowSelectionOnClick: true,
};

export const InputProps = {
  variant: 'standard',
  size: 'small',
  InputLabelProps: { shrink: false },
  InputProps: { disableUnderline: true }
};

export const SelectInputProps = {
  variant: 'standard',
  disableUnderline: true,
  MenuProps: { classes: { paper: styles.selectPaperIp } }
};

export const CloseIconProps = {
  position: 'absolute',
  right: 24,
  top: 12,
  color: (theme) => theme.palette.grey[500],
};

// CUSTOM BUTTON
export const CustomButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-contained:not(.Mui-disabled)': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderColor: 'transparent',
    opacity: 0.96,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: '0.85rem',
  fontWeight: 600,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  minWidth: '90px',
  textShadow: '0 0 white',
  '&.Mui-disabled': { ...inputDisabledProps },
}));

// CUSTOM LOCATION SELECTOR 
export const SelectLocation = styled(Select)(({ theme }) => ({
  maxHeight: theme.spacing(6),
  color: theme.palette.primary.contrastText,
  borderRadius: '10px',
  fontSize: '2rem',
  fontWeight: 500,
}));

// TAB STRUCRURE WITH ICON
export const IconTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'capitalize',
  fontSize: '0.7rem',
  fontWeight: '600',
  justifyContent: 'space-between',
  minHeight: 'auto',
  lineHeight: 1.2,
  '&:not(.Mui-selected)': {
    filter: 'var(--disabledfilter)',
    opacity: 'var(--disabledop)',
  },
  '& .MuiTab-iconWrapper': {
    width: 'auto',
    marginBottom: '8px',
    maxHeight: '25px',
  }
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  textTransform: 'capitalize',
  fontSize: '0.85rem',
  fontWeight: '600',
  justifyContent: 'space-between',
  borderRadius: '10px 10px 0 0',
  width: 'calc(100% - 1px)',
  minWidth: '150px',
  marginRight: '1px',
  minHeight: '30px',
  flex: '1 1 auto',  
  [`& .${styles.activeTabIcon}`]: {
    filter: 'var(--disabledfilter)',
    opacity: 'var(--disabledop)',
  },
  '&.Mui-selected': {
    color: theme.palette.primary.contrastText,
    borderBottom: '3px solid var(--secondarygreen)',
    fontWeight: 'bold',
    [`& .${styles.activeTabIcon}`]: {
      filter: 'invert(98%) sepia(2%) saturate(0%) hue-rotate(174deg) brightness(102%) contrast(102%)',
      opacity: 1,
    },
  },
  '&:not(.Mui-selected)': {
    backgroundColor: 'var(--greybkg)',
    color: 'black',
    fontWeight: 'normal'
  },
}));

// CUSTOM CARD
export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: 'var(--greybkg)',
  padding: theme.spacing(3),
  borderRadius: 'var(--headerbr)',
  boxShadow: 'none'
}));

export const StyledWhiteCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3),
  borderRadius: '20px',
  boxShadow: 'none'
}));

export const StyledBlueCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(3),
  borderRadius: '12px'
}));

// CUSTOM CARD TITLE
export const StyledCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginBottom: 0,
  paddingBottom: theme.spacing(2),
  textShadow: '0 0 rgb(0 0 0 / 20%)'
}));

export const StyledBlueCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.primary.contrastText,
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: 0
}));

// CUSTOM TABLE
export const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  width: 'auto',
  marginRight: `-${theme.spacing(3)}`,
  '& > .MuiTable-root': {
    borderCollapse: 'initial',
    borderSpacing: '0 20px',
    marginTop: '-20px',
    paddingRight: theme.spacing(3),
    backgroundColor: 'transparent',
    '& > .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 'var(--tblheadfs)',
        whiteSpace: 'nowrap',
        padding: theme.spacing(0.5, 1),
        fontWeight: 600,
        lineHeight: 'normal',
        position: 'sticky',
        zIndex: '10',
        height: DataGridProps.columnHeaderHeight
      }
    },
    '& > .MuiTableBody-root ': {
      '& > .MuiTableRow-root': {
        color: theme.palette.common.black,
        whiteSpace: 'nowrap',
        '& > .MuiTableCell-root': {
          padding: theme.spacing(0.5, 1),
          fontSize: 'var(--tblbodyfs)',
          '&:first-of-type': { borderRadius: 'var(--tbbr) 0 0 var(--tbbr)' },
          '&:last-of-type': { borderRadius: '0 var(--tbbr) var(--tbbr) 0' },
          '&:only-child': { borderRadius: 'var(--tbbr)' }
        }
      }
    },
  },
  color: theme.palette.common.black
}));

export const NestedTableCellContainer = styled(TableRow)(({ theme }) => ({
  '&.MuiTableRow-root': {
    backgroundColor: 'transparent !important',
    '& > .MuiTableCell-root': {
      padding: '0 !important',
      '& .MuiTable-root': {
        borderCollapse: 'inherit',
        borderRadius: 'var(--tbbr)',
        marginLeft: theme.spacing(8),
        width: `calc(100% - ${theme.spacing(8)})`,
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: 'transparent',
        fontSize: '12px',
        '& .MuiTableHead-root': {
          backgroundColor: 'transparent',
          '& .MuiTableCell-root': {
            color: theme.palette.primary.main,
            fontSize: '12px',
            whiteSpace: 'nowrap',
            padding: theme.spacing(1, 1.5),
            fontWeight: 600,
            borderBottom: `2px solid ${theme.palette.primary.main}`,
            lineHeight: 'normal'
          }
        },
        '& .MuiTableBody-root ': {
          '& .MuiTableRow-root': {
            color: theme.palette.common.black,
            fontSize: '12px',
            whiteSpace: 'nowrap',
            '& > .MuiTableCell-root': {
              borderBottom: 'none',
              padding: theme.spacing(1, 1.5),
              fontSize: '12px',
            }
          }
        },
      },
    }
  }
}));

export const TableBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    fontSize: '8px',
    fontWeight: 'bolder',
    minWidth: '14px',
    height: '14px',
    padding: '0 4px',
  }
}));

// CUSTOM POPOVER
export const PopoverHead = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const PopoverContent = styled('div')(({ theme }) => ({ padding: theme.spacing(2, 3), }));

export const PopoverFooter = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 3),
  paddingTop: '8px'
}));

// CUSTOM DATAGRID
export const StyledDataGrid = styled(DataGrid)(({ theme, param, rowHeight }) => {
  const ROW_HEIGHT = rowHeight;
  // const ROW_HEIGHT = DATAGRID_ROW_HEIGHT;
  const ROW_MARGIN = 16;
  const containerHeight = ((param) * (ROW_HEIGHT + ROW_MARGIN) || 140);
  return {
    // MENU ICON AND SORT ICON STYLES
    '& .MuiDataGrid-iconButtonContainer': { visibility: 'visible',  },
    '& .MuiDataGrid-sortIcon': { opacity: '0.6 !important' },
    // '.MuiDataGrid-menuIcon': { //dnd
    //   opacity: 'inherit !important',
    //   visibility: 'visible', 
    //   width: 'auto',
    // }, //dnd
    '& .MuiDataGrid-columnHeaders .MuiSvgIcon-root': {
      color: 'white',
      opacity: '0.6' 
    },
    // xx
    '& .MuiDataGrid-overlayWrapperInner': { height: '130px !important' },
    '& .MuiDataGrid-main': {
      rowGap: ROW_MARGIN,
      overflow: 'visible'
    },
    '& .MuiDataGrid-virtualScrollerContent': { height: `${containerHeight}px !important`, },
    '& .MuiDataGrid-virtualScrollerRenderZone': { rowGap: ROW_MARGIN, },
    '& .MuiDataGrid-virtualScroller': {
      overflow: 'visible',
      overflowX: 'visible !important'
    },
    '& .MuiDataGrid-cell:focus-within': { outline: 'none !important' },
    borderColor: 'transparent',
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: theme.spacing(1, 2),
      paddingRight: 0,
      // backgroundColor: theme.palette.primary.main,
      // borderRadius: '4px'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      textShadow: '0 0 white',
      opacity: 0.9,
      textOverflow: 'clip',
      whiteSpace: 'break-spaces',
      lineHeight: 1 
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 'var(--tblheadfs)',
      whiteSpace: 'nowrap',
      fontWeight: 700,
      borderRadius: '4px',
      overflow: 'visible',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      '&:hover .MuiSvgIcon-root': { color: 'white', },
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: theme.palette.primary.main,
      paddingLeft: 0,
      '&:last-child .MuiDataGrid-columnHeaderTitleContainer': { flex: 0.9 },
      [`&.${styles.hideRightSeparator} .MuiDataGrid-columnSeparator`]: { display: 'none' },  //hide column separator
    },
    '& .MuiDataGrid-row': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 'var(--tblbodyfs)',
      whiteSpace: 'nowrap',
      rowGap: theme.spacing(3),
      lineHeight: 1,
      borderRadius: 'var(--tbbr)',
      maxHeight: 'initial !important',
      [`&.${styles.disabledRow}`]: { 
        backgroundColor: theme.palette.grey[200],
        position: 'relative',
        '&:after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 1,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: 'inherit',
        } 
      },

      '& .MuiDataGrid-cellCheckbox svg': { transform: 'scale(0.8)' },
      '& .MuiDataGrid-cell': {
        padding: theme.spacing('2px', 2),
        maxHeight: 'initial !important',
        borderBottom: 'none',
        [`&.${styles.cellTextWrap}`]: {
          whiteSpace: 'wrap',
          lineHeight: 1.2 
        },
        '&:last-child.MuiDataGrid-withBorderColor:empty': { display: 'none', } //extra column at the end of the table 
      },
      '&.Mui-selected': {
        // backgroundColor: theme.palette.primary.main,
        // color: theme.palette.primary.contrastText,
        '& .MuiFormControlLabel-root:not(.Mui-disabled)': {
          color: theme.palette.common.white,
          '& .MuiCheckbox-root:not(.Mui-disabled)': { color: 'white' },
        },
        '& .MuiFormControlLabel-root': {
          '& .MuiFormControlLabel-label.Mui-disabled': { color: grey[500], },
          '&.Mui-disabled': { '& .MuiCheckbox-root': { '&.Mui-disabled': { color: grey[500] } }, },
        },
        '& img': { filter: 'invert(98%) sepia(2%) saturate(0%) hue-rotate(174deg) brightness(102%) contrast(102%)', },
        '& .MuiRadio-root': { color: 'white', }
      },
      '&:hover, &.Mui-hovered': { backgroundColor: theme.palette.grey[200], },
    },
    //add ellipsis to the header title
    [`& .${styles.colEllipse}`]: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      lineHeight: 1
    },
    color: theme.palette.common.black,
  };});

export const StyledTable = styled(Table)(({ theme }) => ({
  borderCollapse: 'initial',
  borderSpacing: '0 20px',
  marginTop: '-40px',
  paddingRight: 0,
  backgroundColor: 'transparent',
  '& .MuiTableHead-root': {
    '& tr:first-of-type': { transform: 'translateY(20px)' },
    '& .MuiTableCell-root.MuiTableCell-head': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontSize: 'var(--tblheadfs)',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.5, 1),
      fontWeight: 600,
      borderBottom: 'transparent',
      [`&.${styles.nestedhead}`]: { 
        borderBottom: '1px solid #59b362',
        borderRight: '4px solid #092b4a',
        borderLeft: '4px solid #092b4a',
        // borderRight: '40px solid #092b4a',
        // borderLeft: '40px solid #092b4a',
      },
    }
  },
  '& .MuiTableBody-root ': {
    '& .MuiTableCell-root.MuiTableCell-body': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      fontSize: 'var(--tblbodyfs)',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.5, 1),
      minHeight: '38px',
      height: '38px',
      '&:first-of-type': { borderRadius: 'var(--tbbr) 0 0 var(--tbbr)' },
      '&:last-of-type': { borderRadius: '0 var(--tbbr) var(--tbbr) 0' },
    } 
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover, },
  '&:last-of-type td, &:last-of-type th': { border: 0, },
}));


// CUSTOM INPUT
export const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.75rem',
  fontWeight: '600',
  paddingLeft: '2px',
  paddingBottom: '2px',
  whiteSpace: 'normal',
  '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': { // these are the classes used from material-ui library for the asterisk element
    color: 'var(--redText)',
  },
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    ...inputProps
  }
}));

export const CustomSearch = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '50px',
    paddingRight: '10px',
    ...inputProps
  }
}));

export const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    paddingRight: '14px',
    ...inputProps,
    '& .MuiInputBase-input': {
      ...inputFieldProps,
      paddingRight: 0,
      minWidth: '70px',
    },
    '& .MuiInputAdornment-root': { '& .MuiSvgIcon-root': { fontSize: '1rem', } }
  }
}));

export const CustomTimePicker = styled(TimePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    paddingRight: '14px',
    ...inputProps,
    '& .MuiInputBase-input': {
      ...inputFieldProps,
      paddingRight: 0,
    },
    '& .MuiInputAdornment-root': { '& .MuiSvgIcon-root': { fontSize: '1rem' } }
  }
}));

export const CustomDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    paddingRight: '14px',
    ...inputProps,
    '& .MuiInputBase-input': {
      ...inputFieldProps,
      paddingRight: 0,
    },
    '& .MuiInputAdornment-root': { '& .MuiSvgIcon-root': { fontSize: '1rem' } }
  }
}));

export const CustomAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.common.white,
    ...inputProps,
    '&:before, &:hover:not(.Mui-disabled, .Mui-error)::before': { borderBottom: 'none' },
    '&.Mui-disabled:before': { borderBottom: 'none' },
  }
}));

export const CustomSelect = styled(Select)(() => ({
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.common.white,
    ...inputProps
  },
  ...inputProps,
  '& .MuiInputBase-input': {
    ...inputFieldProps,
    backgroundColor: theme.palette.common.white,
    lineHeight: 'normal',
    [`&.${styles.colorPlaceholder}`]: { color: grey[500], }
  },
}));

export const CustomSelectRounded = styled(Select)(({ theme }) => ({
  '& .MuiInputBase-root': { ...inputProps, },
  '& .MuiInputBase-input': {
    ...inputFieldProps,
    backgroundColor: theme.palette.common.white,
    lineHeight: 'normal',
    borderRadius: '20px', 
    boxSizing: 'border-box',
    [`&.${styles.colorPlaceholder}`]: { color: grey[500], } 
  },
  borderRadius: '20px',
}));

export const CustomCheckbox = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.8rem',
    // color: theme.palette.primary.main 
  },
  '& .MuiCheckbox-root': { '& .MuiSvgIcon-root ': { fontSize: '1rem' }, },
}));

export const CustomLabelCheckbox = styled(FormControlLabel)(() => ({
  marginTop: '-10px',
  marginBottom: '-10px',
  marginRight: 0,
  '& .MuiFormControlLabel-label': { fontSize: '0.8rem' },
  '& .MuiCheckbox-root': { '& .MuiSvgIcon-root ': { fontSize: '1rem' }, },
}));

export const CustomRadio = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.8rem',
    fontWeight: 500,
    color: '#353535' 
  },
  '& .MuiRadio-root': { '& .MuiSvgIcon-root ': { fontSize: '1rem' }, }
}));

export const SectionDivider = styled(Divider)(() => ({
  borderColor: 'lightgrey',
  borderBottomWidth: '16px',
  // borderBottomWidth: '12px',
  marginLeft: '-24px',
  marginRight: '-24px',
}));

export const GreySectionDivider = styled(Divider)(() => ({
  borderColor: 'var(--greybkg)',
  borderBottomWidth: '16px',
  marginLeft: '-24px',
  marginRight: '-24px',
}));

export const WhiteSectionDivider = styled(Divider)(() => ({
  borderColor: 'white',
  borderBottomWidth: '8px',
  marginLeft: '-24px',
  marginRight: '-24px',
}));

// CUSTOM DIALOG
export const DialogHR = styled('hr')(() => ({ marginBottom: 0 }));
export const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: '600',
}));

export const CustomDialogContent = styled(DialogContent)(() => ({
  padding: '24px',
  paddingTop: '24px !important'
}));

export const CustomDialogAction = styled(DialogActions)(() => ({
  padding: '24px',
  paddingTop: 0
}));

// CUSTOM PROGRESS BAR
export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: { backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800], },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.main,
  },
}));

export const CustomStyle = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    ...state.isDisabled ? inputDisabledProps : '',
    fontSize: '13px',
    minHeight: '29px !important',
    height: '29px !important',
    boxShadow: 'none',
    border: 'none',
    borderRadius: 'var(--ip-br)', // NOT WORKING
    cursor: 'pointer'
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 'var(--ip-br)',
    height: '29px !important',
    backgroundColor: 'white',
    zIndex: 2,
    cursor: 'pointer'
  }),
  indicatorsContainer: (baseStyles) => ({
    ...baseStyles,
    height: '29px !important',
  }),
  valueContainer: () => ({
    padding: '6px 8px',
    height: '29px !important',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isDisabled
      ? 'transparent'
      : state.isSelected
        ? '#092b4a14'
        : state.isFocused ? '#092b4a14'
          : 'fff',
    color: state.isDisabled
      ? 'lightgrey'
      : state.isSelected
        ? 'black'
        : state.isFocused ? 'black'
          : 'black',
    fontSize: '13px',
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'transform 0s',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 2 
  })
};

