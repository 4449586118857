import React from 'react';
import { Stack, Box, Grid, Radio } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, DataGridProps, } from '../../../../../../../custom/components';
import { EditIcon, DeleteIcon, AddIcon, } from '../../../../../../../custom/constants';
import styles from '../../../../../../../custom/style.module.css';
import importStyles from './consignee.module.css';
import ConsigneeDetails from './ConsigneeDetails';

const Consignee = (props) => {

  const { consigneeFields, handleOpen, onDelteConsignee, consignees, } = props;

  const consigneeColumns = [
    {
      field: '',
      headerName: '',
      width: 50,
      minWidth: 50,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Radio size='small' className={styles.p0}
            checked={consigneeFields?.id === row?.id}
          />
        );
      }
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 100,
      flex: 2
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'right',
      headerAlign: 'right',
      // minWidth: 100, 
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <EditIcon onClick={() => { handleOpen('consignee', 'edit', row); }} />
            <DeleteIcon type='button' onClick={(e) => { onDelteConsignee(row.id, e.target); }} />
          </Stack>

        );
      }
    },
  ];

  return (
    <div>
      <Grid container direction={'row'} spacing={3}>
        <Grid item xs={12} md={4}>
          <StyledCard className={styles.h100}>
            <div className={`${styles.mb2} ${styles.fSpaceBtw}`} >
              <StyledCardTitle gutterBottom variant='cardTitle'>Consignee</StyledCardTitle>
              <AddIcon className={styles.addIcon} title='Add Consignee' 
                onClick={() => handleOpen('consignee', 'add')} 
              />
            </div>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${importStyles.consigneeTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={consignees}
                  columns={consigneeColumns}
                  onRowClick={({ row }) => {
                    handleOpen('consignee', 'edit', row);
                  }}
                  param={consignees?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={8}>
          <ConsigneeDetails
            consigneeFields={props.consigneeFields}
            consigneeFieldsErrors={props.consigneeFieldsErrors}
            onChangeConsignee={props.onChangeConsignee}
            addConsignee={props.addConsignee}
            editConsignee={props.editConsignee}
            onClose={props.onClose}
            onAddCMail={props.onAddCMail}
            onChangeCMail={props.onChangeCMail}
            onDeleteCMail={props.onDeleteCMail}
            updateExisting={props.updateExisting}
          />
        </Grid>

      </Grid>
    </div>
  );
};

export default Consignee;