import React from 'react';
import {
  Dialog,
  FormControl,
  // Box,
  Grid,
  InputAdornment,
  // IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  // Tooltip,
} from '@mui/material';
import styles from '../../../custom/style.module.css';
import notificationStyles from './notification.module.css';
import { CustomDialogTitle, CustomDialogContent, StyledCard, CustomInputLabel, CustomTextField, InputProps, CustomButton, CustomRadio, StyledCardTitle, CustomSelect, SelectInputProps/*, TableBadge*/ } from '../../../custom/components';
import { AddIcon, CloseIconBtn, DeleteIcon,/* CloseIconBtn,*/ states_city } from '../../../custom/constants';
import { Search } from '@mui/icons-material';
// import { MailOutline } from '@mui/icons-material';

const UpdateConsigneeModal = (props) => {

  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' 
        open={props.modal == 'update_consignee'}
        onClose={props.handleClose} maxWidth={'md'} fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          Update Consignee / Notify Party
          <CloseIconBtn onClick={props.handleClose} />
        </CustomDialogTitle>
        <CustomDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack direction='row' spacing={2} alignSelf='end' alignItems={'flex-end'}>
                <Stack direction='column' spacing={0} width='100%'>
                  <CustomInputLabel htmlFor='consignee'>Consignee / Notify Party</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    variant='outlined'
                    id='consigneeSearch'
                    fullWidth
                    autoFocus
                    placeholder='Consignee / Notify Party'
                    type='text'
                    name='consignee'
                    value={props.consigneeSearch}
                    onChange={props.onConsigneeSearch}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Search fontSize='small' />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <CustomButton variant='contained' color='primary' sx={{ alignItems: 'flex-end' }} onClick={props.addNewButtonHandler} >Add New</CustomButton>
              </Stack>
            </Grid>
            
            {props.consignees && props.consignees.length > 0 &&
            <Grid item xs={12}>
              <StyledCard className={`${styles.modalCard} ${notificationStyles.radioGroupCard}`} >
                <RadioGroup aria-labelledby='' name='rate_type' id='rate_type' value={props.selectedConsignee?.id ? props.selectedConsignee?.id : null} onChange={props.onSelectedConsignee}>
                  {/* <CustomRadio 
                    control={<Radio size='small' className={styles.p2} />} 
                    value='' 
                    label='American Cruise Aid | 1200 Doral Ave, Room 700, Miami. FL 33131' />
                  <CustomRadio 
                    control={<Radio size='small' className={styles.p2} />} 
                    value='' 
                    label='American Aid Partners | 1926 Biscayne Blvd, ste 206, Miami. FL 33131' />
                  <CustomRadio 
                    control={<Radio size='small' className={styles.p2} />} 
                    value='' 
                    label='American Aid Partners | 1926 Biscayne Blvd, ste 206, Miami. FL 33131' />
                  <CustomRadio 
                    control={<Radio size='small' className={styles.p2} />} 
                    value='' 
                    label='Aid America Again | 16 SW 2nd Ave, Ste 119 , Miami. FL 33131' /> */}
                  {
                    //map through props.consignees and create the items
                    props.consignees.map((consignee, index) => (
                      <CustomRadio 
                        control={<Radio size='small' className={styles.p2} />} 
                        value={consignee.id} 
                        label={consignee.name + ' | ' + consignee.address + ', ' + consignee.city + ', ' + consignee.state + ' ' + consignee.zip} 
                        key={index} />
                    ))
                    
                  }
                </RadioGroup>
              </StyledCard>
            </Grid>
            }

            <Grid item xs={6}>
              <StyledCard className={styles.modalCard}>
                <Stack direction='column' columnGap={2} rowGap={2}>
                  <Stack direction='row' spacing={2} justifyContent='space-between' mb={'4px'}>
                    <StyledCardTitle className={styles.pb0}> Consignee / Notify Party Details</StyledCardTitle>
                    {/* <EditIcon className={notificationStyles.titleEditIcon} title='Edit' onClick={()=>props.editConsignee('edit')} /> */}
                  </Stack>

                  <Grid container spacing={1} alignItems='center'>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel htmlFor='name' aria-label='Enter consignee'>Consignee / Notify Party</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='name'
                        fullWidth
                        disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                        name='name'
                        value={props.consigneeFields?.name}
                        onChange={props.onConsigneeFieldsChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel htmlFor='address' aria-label='Enter address'>Address 1</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='address'
                        fullWidth
                        disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                        name='address'
                        value={props.consigneeFields?.address || ''}
                        onChange={props.onConsigneeFieldsChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <CustomInputLabel htmlFor='address2' aria-label='Enter address'>Address 2</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='address2'
                        fullWidth
                        disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                        name='address2'
                        value={props.consigneeFields?.address2 || ''}
                        onChange={props.onConsigneeFieldsChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <CustomInputLabel id='state-label' aria-label='Enter State'>State</CustomInputLabel>
                      <CustomSelect
                        {...SelectInputProps}
                        id='state'
                        labelId='state-label'
                        fullWidth
                        name='state'
                        disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                        value={props.consigneeFields?.state || ''}
                        onChange={props.onConsigneeFieldsChange}
                      >
                        {Object.keys(states_city)?.sort(
                          (a, b) => a.localeCompare(b)
                        )?.map((state, index) =>
                          <MenuItem key={index} value={state}>{state}</MenuItem>
                        )}
                      </CustomSelect>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <CustomInputLabel id='city-label' aria-label='Enter City'>City</CustomInputLabel>
                      <FormControl variant='standard' fullWidth >
                        <CustomSelect
                          {...SelectInputProps}
                          id='city'
                          labelId='city-label'
                          fullWidth
                          name='city'
                          disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                          value={props.consigneeFields?.city || ''}
                          onChange={props.onConsigneeFieldsChange}
                        >
                          {props.consigneeFields.state && states_city[props.consigneeFields.state] && states_city[props.consigneeFields.state].cities?.sort(
                            (a, b) => a.localeCompare(b)
                          ).map((city, index) =>
                            <MenuItem key={index} value={city}>{city}</MenuItem>
                          )}
                        </CustomSelect>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <CustomInputLabel htmlFor='zip' aria-label='Enter zip'>Zip</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='zip'
                        fullWidth
                        disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                        name='zip'
                        value={props.consigneeFields?.zip || ''}
                        onChange={props.onConsigneeFieldsChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <CustomInputLabel htmlFor='email' aria-label='Enter email'>E-mail</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='email'
                        fullWidth
                        disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                        type='email'
                        name='email'
                        value={props.consigneeFields?.email || ''}
                        onChange={props.onConsigneeFieldsChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <CustomInputLabel htmlFor='phone' aria-label='Enter phone'>Phone</CustomInputLabel>
                      <CustomTextField
                        {...InputProps}
                        id='phone'
                        fullWidth
                        disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                        name='phone'
                        value={props.consigneeFields?.phone || ''}
                        onChange={props.onConsigneeFieldsChange}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </StyledCard>
            </Grid>

            <Grid item xs={6}>
              <StyledCard className={`${styles.h100} ${styles.modalCard}`}>
                <Stack direction='column' columnGap={2} rowGap={2} height='100%'>

                  <Stack direction='row' spacing={2} justifyContent='space-between' mb={'24px'}>
                    <StyledCardTitle className={styles.p0}>Notification Email List</StyledCardTitle>
                    <Stack direction='row' spacing={2}>
                      <AddIcon className={notificationStyles.titleAddIcon} title='Add' onClick={props.onAddCMail} disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false} />
                      {/* <EditIcon className={notificationStyles.titleEditIcon} title='Edit' /> */}
                    </Stack>
                  </Stack>

                  <Grid container spacing={2} alignItems='center'>
                    {/* {Array.from({ length: 4 }).map((_, index) => (
                      <Grid item key={index} xs={12} sm={12} md={12} lg={12}>
                        <Stack direction='row' spacing={2} justifyContent='space-between' alignItems={'center'}>
                          <CustomTextField
                            {...InputProps}
                            id='consignee'
                            fullWidth
                            disabled
                            name='consignee'
                            aria-label='Enter consignee'
                          />
                          <Stack direction='row' spacing={2} alignItems={'center'} justifyContent='flex-end' minWidth='50px'>
                            {index === 0 && 
                            <Tooltip title='Mail'>
                              <TableBadge badgeContent={2} color='error' overlap='circular'>
                                <IconButton size='small' color='primary' aria-label='mail' disableRipple className={`${styles.p0} ${notificationStyles.mailIcon}`}>
                                  <MailOutline fontSize='inherit' />
                                </IconButton>
                              </TableBadge>
                            </Tooltip>
                            }
                            <DeleteIcon className={notificationStyles.delIcon} disabled />
                          </Stack>
                        </Stack>
                      </Grid>)
                    )} */}
                    {
                      props.consigneeFields?.mails && props.consigneeFields?.mails.map((mail, index) => !mail?.deleted && (
                        <Grid item key={index} xs={12} sm={12} md={12} lg={12}>
                          <Stack direction='row' spacing={2} justifyContent='space-between' alignItems={'center'}>
                            <CustomTextField
                              {...InputProps}
                              id='email'
                              fullWidth
                              disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                              name='email'
                              value={mail.email || ''}
                              aria-label='Enter consignee'
                              onChange={(e) => props.onConsigneeMailChange(e, index)}
                            />
                            <Stack direction='row' spacing={2} alignItems={'center'} justifyContent='flex-end' minWidth='50px'>
                              {/* {index === 0 && 
                              <Tooltip title='Mail'>
                                <TableBadge badgeContent={2} color='error' overlap='circular'>
                                  <IconButton size='small' color='primary' aria-label='mail' disableRipple className={`${styles.p0} ${notificationStyles.mailIcon}`}>
                                    <MailOutline fontSize='inherit' />
                                  </IconButton>
                                </TableBadge>
                              </Tooltip>
                              } */}
                              <DeleteIcon className={notificationStyles.delIcon} 
                                onClick={() => props.onDeleteCMail(index)}
                                disabled={props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? true : false}
                              />
                            </Stack>
                          </Stack>
                        </Grid>
                      ))
                    }
                  </Grid>

                  {/* <CustomButton variant='contained' color='primary' disabled fullWidth className={`${styles.mtauto} ${styles.mlauto} ${notificationStyles.updateButton}`}>Update</CustomButton> */}
                
                </Stack>
              </StyledCard>
            </Grid>

            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
              <CustomButton variant='contained' color='primary' disabled={props.consigneeOperation === '' ? true : false } fullWidth className={`${styles.mtauto} ${styles.mlauto} ${notificationStyles.updateButton}`}
                onClick={(e) => {
                  console.log('props.consigneeOperation', props.consigneeOperation);
                  if (props.consigneeOperation === '' || props.consigneeOperation === 'disabled') {
                    props.handleassignConsignee(e,'Assign');
                  } else if (props.consigneeOperation === 'add') {
                    props.handleassignConsignee(e,'Add');
                  } else {
                    props.handleassignConsignee(e,'Update');
                  }
                }}
              >
                {
                  props.consigneeOperation === '' || props.consigneeOperation === 'disabled' ? 'Assign' : props.consigneeOperation === 'add' ? 'Add and Assign' : 'Update and Assign'
                }
              </CustomButton>
            </Grid>
          </Grid>
        </CustomDialogContent>
      </Dialog>
    </div>
  );
};
export default UpdateConsigneeModal;