import React from 'react';
import { Stack, Box, Grid, MenuItem, Typography } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, DataGridProps, CustomInputLabel, CustomTextField, InputProps, CustomButton, CustomSelect, SelectInputProps } from '../../../../../../../custom/components';
import { EditIcon, DeleteIcon } from '../../../../../../../custom/constants';
import styles from '../../../../../../../custom/style.module.css';
import importStyles from './emailtemp.module.css';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const EmailTemplate = (props) => {
  const { mails, onChangeMail, mailFields, mailFieldsErrors, swap, onRowMailClick, handleDeleteMail, editMail, saveMailFooter, onChangeFooter, footer, footerError  } = props;

  const SUB_TITLE_FS = '0.7rem';
  const SUB_TITLE_LH = 1;
  const SUB_TITLE_COLOR = '#000000c4';

  const mailColumns = [
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 100,
      flex: 1
    },
    {
      field: 'value',
      headerName: 'Position',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      minWidth: 80,
      flex: 0.5,
      renderCell: ({ id }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='space-between' width='100%'>
            {id > 0 && <ArrowUpward color='primary' className={ importStyles.arrowIcon } onClick={() => { swap('up', id); }} />}
            {id < (mails?.length - 1) && <ArrowDownward color='primary' className={`${importStyles.arrowIcon} ${styles.mlauto}`} onClick={() => { swap('down', id); }} />}
          </Stack>
        );
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      align: 'right',
      headerAlign: 'right',
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ id, row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='flex-end' width='100%' className={styles.actionIcons}>
            {row.type == 'Text' && <EditIcon onClick={() => { onRowMailClick(row); }} />}
            <DeleteIcon type='button' onClick={(e) => { handleDeleteMail(id, e.target); }} />
          </Stack>
        );
      }
    },
  ];

  return (
    <div>
      <Grid container spacing={3} sx={{ justifyContent: 'center' }}>
        <Grid item xs={12} md={10} lg={6} xl={6}>
          <StyledCard className={`${styles.h100} ${styles.mxauto}`}>
            <Stack className={`${styles.mb3}`}>
              <StyledCardTitle gutterBottom variant='cardTitle' className={styles.pb0}>Mail Template</StyledCardTitle>
              <Typography component='span' fontSize={SUB_TITLE_FS} lineHeight={SUB_TITLE_LH} color={SUB_TITLE_COLOR} pt={0.5}>
                Content below will be used as the email template</Typography>
            </Stack>
            <div className={styles.tableWrapper}>
              <form onSubmit={editMail}>
                <Box className={`${styles.tableOuter}`}>
                  <Grid container spacing={1}>
                    <Grid item xs>
                      <CustomSelect
                        {...SelectInputProps}
                        id='type'
                        name='type'
                        fullWidth
                        value={mailFields.type}
                        onChange={onChangeMail}
                        error={mailFieldsErrors.type}
                        helperText={mailFieldsErrors.type}
                      >
                        <MenuItem value=''>Select Field</MenuItem>
                        <MenuItem value='Pieces'>Pieces</MenuItem>
                        <MenuItem value='Weight'>Weight</MenuItem>
                        <MenuItem value='O-D'>O-D</MenuItem>
                        <MenuItem value='Commodity'>Commodity</MenuItem>
                        <MenuItem value='Flight Number'>Flight Number</MenuItem>
                        <MenuItem value='Arrival Date'>Arrival Date</MenuItem>
                        <MenuItem value='Storage Starts'>Storage Starts</MenuItem>
                        <MenuItem value='Text'>Text</MenuItem>
                      </CustomSelect>
                    </Grid>
                    {mailFields.type === 'Text' && (
                      <Grid item xs={12}>
                        <CustomInputLabel htmlFor='name'>Name</CustomInputLabel>
                        <CustomTextField
                          {...InputProps}
                          fullWidth
                          id='name'
                          name='name'
                          value={mailFields.name}
                          onChange={onChangeMail}
                          error={mailFieldsErrors.name}
                          helperText={mailFieldsErrors.name}
                        />
                      </Grid>
                    )}
                    {mailFields.type === 'Text' && (
                      <Grid item xs={12}>
                        <CustomInputLabel htmlFor='value'>Value</CustomInputLabel>
                        <CustomTextField
                          {...InputProps}
                          fullWidth
                          id='value'
                          name='value'
                          value={mailFields.value}
                          onChange={onChangeMail}
                          error={mailFieldsErrors.value}
                          helperText={mailFieldsErrors.value}
                        />
                      </Grid>
                    )}
                    <Grid item xs={mailFields.type === 'Text' ? 12 : 'auto'} display={'flex'}>
                      <CustomButton type='submit' variant='contained' className={`${importStyles.addBtn} ${mailFields.type === 'Text' ? styles.mt2 : ''}`}>Add</CustomButton>
                    </Grid>
                  </Grid>
                </Box>
              </form>
              <Box className={`${styles.tableOuter}`} mt={2}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={mails}
                  columns={mailColumns}
                  param={mails?.length}
                  getRowId={(row) => mails.findIndex((item) => JSON.stringify(item) === JSON.stringify(row))}
                />
              </Box>
              <form onSubmit={saveMailFooter}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomInputLabel htmlFor='footer'>Footer</CustomInputLabel>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='footer'
                      name='footer'
                      type=''
                      value={footer}
                      onChange={onChangeFooter}
                      error={footerError}
                      helperText={footerError}
                    />
                  </Grid>
                  <Grid item xs={12} display={'flex'}>
                    <CustomButton type='submit' variant='contained' className={styles.mlauto}>Save</CustomButton>
                  </Grid>
                </Grid>
              </form>
            </div>
          </StyledCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default EmailTemplate;