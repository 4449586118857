import React from 'react';
import evemts from 'utils/evemts';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
import EmailTemplate from './EmailTemplate';

class EmailTemplateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mails: [],
      mailFields: {},
      mailFieldsErrors: {},
      footer: '',
      footerErrors: false,
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchMailTemp();
    this.fetchMailFooter();
    this.eventSource = evemts('admin', 'import');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');
      if (msg.data && msg.action && msg.action == 'mail_temp') {
        this.setState({ mails: msg.data?.value || [] });
      }

      if (msg.data && msg.action && msg.action == 'mail_footer') {
        this.setState({ footer: msg.data?.value?.footer || '' });
      }
    };
  }

  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  fetchMailTemp = () => {
    http.get('/settings?key=mail_temp').then(({ data }) => {
      if (data.data) {
        let mail = data.data?.find((item) => item.key === 'mail_temp');
        if (mail) {
          this.setState({ mails: mail?.value || [] });
        }
      }
    }
    );
  };

  fetchMailFooter = () => {
    http.get('/settings?key=mail_footer').then(({ data }) => {
      if (data.data) {
        let footer = data.data?.find((item) => item.key === 'mail_footer');
        if (footer) {
          this.setState({ footer: footer?.value?.footer || '' });
        }
      }
    });
  };

  handleOpen = (modal, action, row = { value: [{}] }) => {
    // extra phone_code and phone
    if (modal == 'consignee') {
      const regex = /^(\+?(\d{1,4}))(\d{10,})$/;
      const phone = row?.phone && row?.phone?.match(regex);
      row.phone_code = phone?.[1] || '+1';
      row.phone = phone?.[3];
    }
    this.setState({
      modal,
      action,
      serviceFields: modal == 'additionalservices' ? row : {},
      serviceFieldsErrors: {},
      consigneeFields: modal == 'consignee' ? row : {},
      consigneeFieldsErrors: {},
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
      serviceFields: {},
      serviceFieldsErrors: {},
      consigneeFields: {},
      consigneeFieldsErrors: {},
    });
  };

  onChangeMail = (e) => {
    let value = e.target.value;
    if (e.target.type == 'checkbox') {
      value = e.target.checked;
    }
    this.setState({
      mailFields: {
        ...this.state.mailFields,
        [e.target.name]: value,
      },
    });
  };

  validateMailForm = () => {
    let mailFieldsErrors = {};
    let isValid = true;
    if (!this.state.mailFields.type) {
      mailFieldsErrors.type = 'Type is required';
      isValid = false;
    }
    if (this.state.mailFields.type == 'Text') {
      if (!this.state.mailFields.name) {
        mailFieldsErrors.name = 'Name is required';
        isValid = false;
      }
      if (!this.state.mailFields.value) {
        mailFieldsErrors.value = 'Value is required';
        isValid = false;
      }
    }
    this.setState({ mailFieldsErrors });
    return isValid;
  };

  swap = (direction, index) => {
    let data = {
      index: index,
      up: direction == 'up' ? true : false,
      down: direction == 'down' ? true : false,
    };
    http.put('/setting/mail_temp/swap', data);
  };

  onRowMailClick = (row) => {
    this.setState({
      mailFields: row,
      mailFieldsErrors: {}
    });
  };

  editMail = (e) => {
    e.preventDefault();
    if (this.validateMailForm()) {
      let data = {
        type: this.state.mailFields.type,
        name: this.state.mailFields.name || null,
        value: this.state.mailFields.value || null
      };
      http.put('setting/mail_temp', data).then(({ data }) => {
        if (data.status == 'success') {
          this.setState(prev=>({
            mailFields: {
              ...prev.mailFields,
              name: '',
              value: '',
            }, 
          }));
        }
      }).catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  };

  handleDeleteMail = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteMail(id),
    });
  };

  deleteMail = (id) => {
    http.delete('setting/mail_temp/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete({
          modal: false,
          id: false,
          anchorEl: false,
          onConfirm: () => { },
        });
      }
    });
  };

  onChangeFooter = (e) => {
    let value = e.target.value;
    if (e.target.type == 'checkbox') {
      value = e.target.checked;
    }
    this.setState({
      footer: value,
      footerErrors: false,
    });
  };

  saveMailFooter = (e) => {
    e.preventDefault();
    if (!this.state.footer) {
      this.setState({ footerErrors: true });
    } else {
      http.put('setting/mail_footer', { value: { footer: this.state.footer } });
    }
  };

  render() {
    return (
      <>
        <EmailTemplate
          mails={this.state.mails}
          onChangeMail={this.onChangeMail}
          mailFields={this.state.mailFields}
          mailFieldsErrors={this.state.mailFieldsErrors}
          onRowMailClick={this.onRowMailClick}
          swap={this.swap}
          handleDeleteMail={this.handleDeleteMail} 
          editMail={this.editMail}
          footer={this.state.footer}
          footerErrors={this.state.footerErrors}
          onChangeFooter={this.onChangeFooter}
          saveMailFooter={this.saveMailFooter}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(null, mapDispatchToProps)(EmailTemplateContainer);