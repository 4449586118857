import React from 'react';

const WeightInputTest2 = () => {
  return (
    <div style={{
      width: '100%',
      height: '100vh' 
    }}>
      <iframe
        src='/serial-comm.html'
        style={{
          width: '100%',
          height: '100%',
          border: 'none' 
        }}
        title='Embedded HTML Page'
      />
    </div>
  );
};

export default WeightInputTest2;