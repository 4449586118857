import React from 'react';
import { Divider, List, ListItem, ListItemAvatar, ListItemText, Popover, Typography, Box } from '@mui/material';
import styles from '../../../custom/style.module.css';
import commonStyles from './common.module.css';
import moment from 'moment';
import { CloseIconBtn } from 'custom/constants';

const History = (props) => {
  const { popover, anchorEl, onClose, data } = props;

  return (
    <Popover
      open={popover === 'history'}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Typography variant='body2' color='primary' p={2}>ULD History</Typography>
      <CloseIconBtn onClick={props.onClose} />
      <Divider />
      <List sx={{
        width: '100%',
        // maxWidth: 320,
        minWidth: 320,
        bgcolor: 'background.paper',
        maxHeight: 400,
        minHeight: 200,
        p: 2,
      }}>
        {data ? data.map((item, index) => {
          let uld_keys = ['uld_no', 'closed', 'contour', 'dangerous', 'dest', 'dolly_tare', 'scale_wgt', 'wgt'];
          let uld_keys_label = {
            uld_no: 'ULD No',
            closed: 'Closed',
            contour: 'Contour',
            dangerous: 'Dangerous',
            dest: 'Destination',
            dolly_tare: 'Dolly Tare',
            scale_wgt: 'Scale Weight',
            wgt: 'Weight',
          };

          let awb_keys = ['pcs', 'remark', 'spl_code'];
          let awb_keys_label = {
            pcs: 'Pcs',
            remark: 'Remark',
            spl_code: 'SPLs',
          };
          let primary = '';
          if (item.operation == 'create') {
            primary = 'ULD Created';
          }
          if (item.operation == 'update') {
            primary = 'Update -> ';
            uld_keys.forEach(key => {
              if (item.prev_details[key] != item.new_details[key]) {
                primary += ' ' + uld_keys_label[key] + ' -> ' + item.prev_details[key] + ' -> ' + item.new_details[key];
              }
            });
          }
          if(item.operation == 'accessorie') {
            primary = 'Update -> ';
            primary += ' ' + props.accessories.find(acc => acc.id == item.new_details?.loadingaccessoriesId)?.description + ' -> ' + (item.prev_details?.value || 0) + ' -> ' + item.new_details?.value;
          }
          if (item.operation == 'move') {
            primary = 'ULD Moved: ' + item.prev_details?.flight + ' - ' + item.new_details?.flight;
          }
          if(item.operation == 'finalize') {
            primary = 'ULD Finalized: Scale Weight '+ (item.new_details?.scale_wgt || '0') + 'K';
          }
          if(item.operation == 'create_awb') {
            primary = 'AWB Added: ' + item.new_details?.awb_no + ' - ' + item.new_details?.pcs + ' Pcs';
          }
          if(item.operation == 'update_awb') {
            primary = 'AWB Updated: ' + item.new_details?.awb_no + ' ';
            awb_keys.forEach(key => {
              console.log(key, item.prev_details[key], item.new_details[key]);
              if (item.prev_details[key] != item.new_details[key]) {
                primary += ' ' + awb_keys_label[key] + ' ' + item.prev_details[key] + ' -> ' + item.new_details[key];
              }
            });
          }
          if(item.operation == 'delete_awb') {
            primary = 'Deleted: ' + item.prev_details?.awb_no + ' - ' + item.prev_details?.pcs + ' Pcs';
          }
          return <ListItem key={index} className={commonStyles.historyListItem}>
            <ListItemAvatar className={styles.mr2}>
              <Typography variant='span' color='white'
                bgcolor={'green'}
                textTransform={'uppercase'} fontSize='10px' fontWeight={800} px={0.5} py={0.25} borderRadius={'4px'} lineHeight={'normal'} minWidth={'70px'} display={'block'} textAlign={'center'}>
                {item.type}
              </Typography>
            </ListItemAvatar>
            <ListItemText
              className={commonStyles.historyListText}
              secondaryTypographyProps={{
                fontSize: 12,
                color: 'primary.main',
                fontWeight: 500,
              }}
              primaryTypographyProps={{ fontSize: 12 }}
              secondary={moment(item.created).format('DDMMMYY HHmm') + ' | ' + item.user?.first_name + ' ' + item.user?.last_name + ' (' + item.user?.role + ')'}
              primary={primary} />
          </ListItem>;
        }) :
          <Box sx={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Typography variant='span' color='textSecondary' fontSize='0.8rem'>No History Found</Typography></Box>}
        {/* {data ? data.map((item, index) => (
          <ListItem key={index} className={flightStyles.historyListItem}>
            <ListItemAvatar className={styles.mr2}>
              <Typography variant='span' color='white' 
                bgcolor={'green'}
                textTransform={'uppercase'} fontSize='10px' fontWeight={800} px={0.5} py={0.25} borderRadius={'4px'} lineHeight={'normal'} minWidth={'70px'} display={'block'} textAlign={'center'}>
                {item.type}
              </Typography>
            </ListItemAvatar>
            <ListItemText   
              className={flightStyles.historyListText} 
              secondaryTypographyProps={{
                fontSize: 12,
                color: 'primary.main',
                fontWeight: 500,
              }}
              primaryTypographyProps={{ fontSize: 12 }}
              primary={item.primary}
              secondary={item.secondary} />
          </ListItem>
        )) : 
          <Box sx={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', 
          }}>
            <Typography variant='span' color='textSecondary' fontSize='0.8rem'>No History Found</Typography></Box>)
        <ListItem className={flightStyles.historyListItem}>
          <ListItemAvatar className={styles.mr2}>
            <Typography variant='span' color='white' 
              bgcolor={'green'}
              textTransform={'uppercase'} fontSize='10px' fontWeight={800} px={0.5} py={0.25} borderRadius={'4px'} lineHeight={'normal'} minWidth={'70px'} display={'block'} textAlign={'center'}>
              AWB
            </Typography>
          </ListItemAvatar>
          <ListItemText   
            className={flightStyles.historyListText} 
            secondaryTypographyProps={{
              fontSize: 12,
              color: 'primary.main',
              fontWeight: 500,
            }}
            primaryTypographyProps={{ fontSize: 12 }}
            primary={'21JAN21 1840 | Jose Ferreira (System Admin)'}
            secondary={'AWB Added: 020-12345675 - 12 Pcs'} />
        </ListItem>
        {/* )) : 
          <Box sx={{
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', 
          }}>
            <Typography variant='span' color='textSecondary' fontSize='0.8rem'>No History Found</Typography></Box>)
          :
          Array.from({ length: 3 }).map((_, index) => <ListItem key={index}>
            <Skeleton variant='rectangular'  width={300} height={100} /> 
          </ListItem>)
        } */}
      </List>
    </Popover>
  );
};
export default History;