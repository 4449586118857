import React, { useState, useEffect } from 'react';
import { Button, Select, MenuItem, Typography, Box } from '@mui/material';

const WeightInputTest1 = () => {
  const [ports, setPorts] = useState([]);
  const [selectedPort, setSelectedPort] = useState('');
  const [port, setPort] = useState(null);
  const [reader, setReader] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('Not Connected');
  const [weightDisplay, setWeightDisplay] = useState('Waiting for data...');
  const [isConnected, setIsConnected] = useState(false);
  const [isReading, setIsReading] = useState(false);

  const listAvailablePorts = async () => {
    try {
      const availablePorts = await navigator.serial.getPorts();
      setPorts(availablePorts);

      if (availablePorts.length === 0) {
        setSelectedPort('');
      }
    } catch (error) {
      console.error('Error listing ports:', error);
    }
  };

  const connectToPort = async () => {
    if (isConnected) {
      // Disconnect logic
      if (reader) {
        await reader.cancel();
        setReader(null);
      }
      if (port) {
        await port.close();
        setPort(null);
      }
      setIsConnected(false);
      setConnectionStatus('Not Connected');
      return;
    }

    try {
      const selectedPortObj = ports[selectedPort];
      await selectedPortObj.open({ baudRate: 9600 });
      setPort(selectedPortObj);

      const newReader = selectedPortObj.readable.getReader();
      setReader(newReader);
      setIsConnected(true);
      setConnectionStatus('Connected');

      // Start reading data
      readSerialData(newReader);
    } catch (error) {
      console.error('Connection error:', error);
      setConnectionStatus('Connection Failed');
    }
  };

  const readSerialData = async (currentReader) => {
    setIsReading(true);
    while (isReading) {
      try {
        const { value, done } = await currentReader.read();
        if (done) {
          currentReader.releaseLock();
          setIsReading(false);
          break;
        }
        const decoded = new TextDecoder().decode(value);
        setWeightDisplay(decoded);
      } catch (error) {
        console.error('Read error:', error);
        setIsReading(false);
        break;
      }
    }
  };

  useEffect(() => {
    listAvailablePorts();
    // Cleanup function
    return async () => {
      setIsReading(false); // Add this line
      if (reader) {
        await reader.cancel();
      }
      if (port) {
        await port.close();
      }
    };
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 2 }}>
        <Typography component='label' htmlFor='portSelect' sx={{ mr: 2 }}>
          Select COM Port:
        </Typography>
        <Select
          id='portSelect'
          value={selectedPort}
          onChange={(e) => setSelectedPort(e.target.value)}
          sx={{
            mr: 2,
            minWidth: 120,
          }}
        >
          {ports.length === 0 ? (
            <MenuItem value=''>No ports found</MenuItem>
          ) : (
            ports.map((_, index) => (
              <MenuItem key={index} value={index}>
                Port {index + 1}
              </MenuItem>
            ))
          )}
        </Select>
        <Button variant='contained' onClick={listAvailablePorts} sx={{ mr: 2 }}>
          Refresh Ports
        </Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button
          variant='contained'
          onClick={connectToPort}
          disabled={!selectedPort && !isConnected}
          sx={{ mr: 2 }}
        >
          {isConnected ? 'Disconnect' : 'Connect'}
        </Button>
        <Typography component='span'>Status: {connectionStatus}</Typography>
      </Box>

      <Box>
        <Typography>Weight: {weightDisplay}</Typography>
      </Box>
    </Box>
  );
};

export default WeightInputTest1;
