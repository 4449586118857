import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const WeightInputTest3 = () => {
  const [ports, setPorts] = useState([]);
  const [selectedPort, setSelectedPort] = useState('');
  const [port, setPort] = useState(null);
  const [reader, setReader] = useState(null);
  const [inputData, setInputData] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [isReading, setIsReading] = useState(false);

  // List available ports
  const listPorts = async () => {
    try {
      const availablePorts = await navigator.serial.getPorts();
      setPorts(availablePorts);

      if (availablePorts.length === 0) {
        // Request port if none available
        const port = await navigator.serial.requestPort();
        setPorts([port]);
      }
    } catch (error) {
      console.error('Error listing ports:', error);
    }
  };

  const readSerialData = async (newReader) => {
    setIsReading(true);
    while (isReading) {
      try {
        const { value, done } = await newReader.read();
        if (done) {
          newReader.releaseLock();
          setIsReading(false);
          break;
        }
        const decoded = new TextDecoder().decode(value);
        setInputData((prev) => [
          ...prev,
          {
            timestamp: new Date().toLocaleTimeString(),
            value: decoded.trim(),
          },
        ]);
      } catch (error) {
        console.error('Read error:', error);
        setIsReading(false);
        break;
      }
    }
  };

  // Handle port connection
  const connectToPort = async () => {
    if (isConnected) {
      // Disconnect logic
      if (reader) {
        await reader.cancel();
        setReader(null);
      }
      if (port) {
        await port.close();
        setPort(null);
      }
      setIsConnected(false);
      return;
    }

    try {
      const selectedPortObj = ports[selectedPort];
      await selectedPortObj.open({ baudRate: 9600 });
      setPort(selectedPortObj);
      setIsConnected(true);

      // Start reading data
      const newReader = selectedPortObj.readable.getReader();
      setReader(newReader);
      readSerialData(newReader);
    } catch (error) {
      console.error('Connection error:', error);
      setIsConnected(false);
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return async () => {
      if (reader) {
        await reader.cancel();
      }
      if (port) {
        await port.close();
      }
    };
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Paper elevation={3} sx={{ 
        p: 2, 
        mb: 2 
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          mb: 2 
        }}>
          <Typography variant='h6' sx={{ mr: 2 }}>
            Serial Port Connection
          </Typography>
          <Button variant='contained' onClick={listPorts} sx={{ mr: 2 }}>
            Refresh Ports
          </Button>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center' 
        }}>
          <Select
            value={selectedPort}
            onChange={(e) => setSelectedPort(e.target.value)}
            sx={{ 
              mr: 2, 
              minWidth: 200 
            }}
            disabled={isConnected}
          >
            {ports.length === 0 ? (
              <MenuItem value=''>No ports available</MenuItem>
            ) : (
              ports.map((_, index) => (
                <MenuItem key={index} value={index}>
                  Port {index + 1}
                </MenuItem>
              ))
            )}
          </Select>

          <Button
            variant='contained'
            onClick={connectToPort}
            disabled={!selectedPort && !isConnected}
            color={isConnected ? 'error' : 'primary'}
          >
            {isConnected ? 'Disconnect' : 'Connect'}
          </Button>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant='h6' sx={{ mb: 2 }}>
          Input Data
        </Typography>
        <List sx={{ 
          maxHeight: 400, 
          overflow: 'auto' 
        }}>
          {inputData.map((data, index) => (
            <ListItem key={index}>
              <ListItemText primary={data.value} secondary={data.timestamp} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default WeightInputTest3;
