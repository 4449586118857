import React from 'react';
import buildupStyles from './buildup.module.css';
import styles from '../../../../../custom/style.module.css';
import { Badge, Box, Button, Checkbox, Chip, Divider, FormControl, FormHelperText, Grid, IconButton, InputAdornment, Popover, Radio, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { CustomAutocomplete, CustomButton, CustomCheckbox, CustomInputLabel, CustomSearch, CustomTextField, DataGridProps, InputProps, StyledCard, StyledCardTitle, StyledDataGrid, TableBadge } from 'custom/components';
import { AddIcon, ClearXIcon, CloseIconBtn, DeleteIcon, DocIcon, EditIcon } from 'custom/constants';
import { Balance, Circle, FormatListBulleted, Forward, Refresh, Search } from '@mui/icons-material';
import AwbDetailModal from './AwbDetailsModal';
import History from 'components/pages/common/History';
import MoveUldToDiffFlight from './MoveUldToDiffFlight';
import cookies from 'utils/cookies';
import CommentIcon from '@mui/icons-material/Comment';
import Lock from '../../../../../assets/images/actionIcons/lock.svg';
import Unlock from '../../../../../assets/images/actionIcons/unlock.svg';
import Status from './Status';

const Buildup = (props) => {

  const CHARACTER_LIMIT = 200;
  const role = cookies.get('role');
  const canEditWgt = role === 'Supervisor' || role === 'System Admin' || role === 'Manager';
  console.log(role, canEditWgt);

  const uldListColumns = [
    {
      field: '',
      headerName: '',
      width: 30,
      minWidth: 30,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Radio size='small' className={styles.p0}
            checked={props.editUldFields?.id === row?.id}
          />
        );
      }
    },
    {
      field: 'uld_no',
      headerName: 'ULD ID',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => {
        return props.editUld == row.id ? (
          <Stack direction='row' alignItems={'center'} width={'100%'} spacing={1}>
            <CustomTextField
              id=''
              fullWidth
              value={props.editUldFields.uld_no}
              name='uld_no'
              onChange={props.onChangeEditUld}
              onBlur={props.onEditUld}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              className={buildupStyles.uldIpTextfield}
              InputProps={{
                classes: {
                  root: buildupStyles.uldIpRoot,
                  input: buildupStyles.uldIp
                },
              }}
            />
            <ClearXIcon onClick={() => props.onedit(false)} />
          </Stack>
        ) : <Stack direction='row' alignItems={'center'} width={'100%'} spacing={1}>
          <div className={buildupStyles.uldNumber}>{row.uld_no}</div>
          <div className={`${styles.smActionIcons} ${styles.ml2}`}><EditIcon onClick={() => props.onedit(row.id)} /></div>
        </Stack>;
      }
    },
    {
      field: 'o_c',
      headerName: 'O/C',
      align: 'center',
      headerAlign: 'center',
      minWidth: 60,
      flex: 1,
      renderCell: ({ row }) => {
        return row.closed ? 'C' : 'O';
      }
    },
    {
      field: 'scale_wgt',
      headerName: 'Scale wgt',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (row?.scale_wgt || 0) + ' K';
      }
    },
    {
      field: 'mnfst_wgt',
      headerName: 'Mnfst wgt',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (row?.buildup_awb?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr?.wgt || 0), 0) || 0) + ' K';
      }
    },
    {
      field: 'dev',
      headerName: 'Dev',
      minWidth: 100,
      flex: 1,
      renderCell: ({ row }) => {
        let scale_wgt = row?.scale_wgt || 0;
        let mnfst_wgt = row?.buildup_awb?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr?.wgt || 0), 0) || 0;
        if (scale_wgt > 0) {
          return ((scale_wgt - mnfst_wgt) / scale_wgt * 100).toFixed(2) + '%';
        } else {
          return '0%';
        }
      }
    },
    {
      field: 'dest',
      headerName: 'ULD dest',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'contour',
      headerName: 'Cont',
      width: 80,
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      width: 72,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.remark ? <Badge color='secondary' variant='dot'>
          <Tooltip title={row?.remark}><CommentIcon sx={{ fontSize: '1rem' }} color='primary' /></Tooltip>
        </Badge>
          : <CommentIcon sx={{ fontSize: '1rem' }} color='primary' />;
      },
    },

    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 140,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <Button title='History' size='small' variant='outlined' className={buildupStyles.historyIcon} disableElevation
              onClick={(e) => props.handleOpen('history', e.currentTarget)}> H</Button>
            <Button title='Move ULD to different flight' size='small' variant='outlined' className={buildupStyles.historyIcon} disableElevation
              onClick={(e) => props.handleOpen('move_uld_to_diff_flight', e.currentTarget)}><Forward /></Button>
            <IconButton title='Download ULD PDF' size='small' className={styles.p0}
              onClick={() => props.handleUldPdf(row)}><DocIcon /></IconButton>
            <DeleteIcon title='Delete' onClick={(e) => props.handleDelete('uld', row.id, e.target)} />
          </Stack>
        );
      }
    },
  ];


  const uldContentColumns = [
    {
      field: 'awb_no',
      headerName: 'AWB#',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'pcs',
      headerName: 'Pcs',
      flex: 1,
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return <Typography variant='inherit' className={buildupStyles.pcsCell}>{row.pcs}</Typography>;
      }

    },
    {
      field: 'wgt',
      headerName: 'Wgt',
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography variant='inherit' className={buildupStyles.pcsCell}>{(row.wgt || 0) + ' K'}</Typography>;
      }
    },
    {
      field: 'org_dest',
      headerName: 'Org-Dest',
      headerAlign: 'left',
      flex: 1,
      renderCell: ({ row }) => {
        return row.org + '-' + row.dest;
      }
    },
    {
      field: 'spl_code',
      headerName: 'SPLs',
      flex: 1,
      cellClassName: styles.cellTextWrap,
      renderCell: ({ row }) => {
        return row.spl_code?.split(',').join(', ');
      }
    },
    {
      field: 'remark',
      headerName: 'Remarks',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return row?.remark ? <Badge color='secondary' variant='dot'>
          <Tooltip title={row?.remark}><CommentIcon sx={{ fontSize: '1rem' }} color='primary' /></Tooltip>
        </Badge>
          : <CommentIcon sx={{ fontSize: '1rem' }} color='primary' />;
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 70,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={1} justifyContent='center' className={styles.actionIcons}>
            <EditIcon disabled={props.editUldFields.closed} onClick={() => props.onSearch(row.awb_no)} />
            <DeleteIcon disabled={props.editUldFields.closed} onClick={(e) => props.handleDelete('awb', row.id, e.target)} />
          </Stack>
        );
      }
    },
  ];

  let ULDContentRows = [];
  for (let i = 0; i < 5; i++) {
    ULDContentRows.push({
      id: i,
      awb_number: `123456789${i + 1}`,
      pcs: '1',
      wgt: '100',
      org_dest: 'DEL-BOM',
      spls: 'SPLs',
      remarks: 'Remarks',
      action: '',
    });
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={7}>
          <StyledCard className={styles.h100}>
            <Stack direction='row' spacing={2} alignItems={'center'} justifyContent={'space-between'} mb={2}>
              <StyledCardTitle component='div' className={styles.pb0}>ULD List </StyledCardTitle>
              <Stack direction='row' spacing={1} alignItems='center'>
                {/* <CustomInputLabel htmlFor='uld_id' aria-label='Enter ULD ID'>ULD ID</CustomInputLabel> */}
                <CustomTextField
                  {...InputProps}
                  id='uld_id'
                  placeholder='Enter ULD ID'
                  type='text'
                  name='uld_no'
                  onChange={props.onChangeAddUld}
                  value={props.addUldFields.uld_no || ''}
                  className={`${styles.brInput}`}
                // InputProps={{
                //   disableUnderline: true,
                //   endAdornment: <InputAdornment position='end'>
                //     <IconButton
                //       size='large'
                //       aria-label='search'
                //       onClick={props.onAddUld}
                //       edge='end'
                //       disableRipple
                //       color='primary'
                //     >
                //       <AddCircle fontSize='inherit' />
                //     </IconButton>
                //   </InputAdornment>,
                // }}
                />
                <AddIcon className={styles.addIcon} title='Add ULD' onClick={props.onAddUld} />
              </Stack>
            </Stack>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${buildupStyles.uldListTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.ulds}
                  columns={uldListColumns}
                  onRowClick={({ row }) => {
                    props.setActiveUld(row);
                  }}
                  getRowClassName={({ row }) => props.editUldFields?.id === row?.id ? buildupStyles.selectedRow : ''}
                  param={props.ulds?.length}
                  classes={{
                    root: buildupStyles.uldListTable,
                    row: buildupStyles.uldListRow,
                    cell: buildupStyles.uldListCell,
                    columnHeaderTitleContainer: buildupStyles.columnHeaderTitleContainer,
                  }}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>

        <Grid item container spacing={2} xs={12} sm={12} md={12} lg={12} xl={5}>
          <Grid item xs={12} sm={12} md={12} lg={7} xl={12} sx={{ minHeight: 'calc(100% - 44%)' }}>
            <StyledCard className={styles.h100}>
              <Stack direction='row' spacing={2} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                <StyledCardTitle component='div' className={styles.pb0}>ULD Contents {props.editUldFields?.uld_no && (`- ${props.editUldFields?.uld_no}`)}</StyledCardTitle>
                <CustomSearch
                  {...InputProps}
                  id=''
                  placeholder='Enter Awb Number'
                  type='search'
                  disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                  name='awb'
                  onChange={props.onChangeSearch}
                  value={props.search?.awb || ''}
                  className={`${styles.brInput}`}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: <InputAdornment position='end'>
                      <IconButton
                        size='small'
                        aria-label='search'
                        onClick={props.onSearch}
                        disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                        edge='end'
                      >
                        <Search fontSize='inherit' />
                      </IconButton>
                    </InputAdornment>,
                  }}
                />
              </Stack>

              <div className={`${styles.tableWrapper} ${buildupStyles.containerEnd}`}>
                <Box className={`${styles.tableOuter} ${buildupStyles.uldContentTableOuter}`}>
                  <StyledDataGrid
                    {...DataGridProps}
                    rows={props.editUldFields?.buildup_awb || []}
                    columns={uldContentColumns}
                    param={props.editUldFields?.buildup_awb}
                    classes={{
                      root: buildupStyles.uldContentTable,
                      row: buildupStyles.uldContentRow,
                      cell: buildupStyles.uldContentCell,
                      columnHeaderTitleContainer: buildupStyles.columnHeaderTitleContainer,
                      overlayWrapperInner: buildupStyles.overlayWrapperInner,
                      virtualScrollerContent: buildupStyles.virtualScrollerContent,
                    }}
                  />
                </Box>
              </div>
              <Grid container spacing={3} pl={0.5} mt={'auto'}>
                <Grid item xs={12} sm={3} md={3} lg={2}>
                  <Typography variant='body2' fontWeight={700} color={'primary'}>Totals:</Typography>
                </Grid>
                <Grid item xs={4} sm={3} md={3} lg={2}>
                  <Typography variant='body2' fontWeight={700} color={'primary'}>{props.editUldFields?.buildup_awb?.length || 0} AWBS</Typography>
                </Grid>
                <Grid item xs={4} sm={3} md={3} lg={2}>
                  <Typography variant='body2' fontWeight={700} color={'primary'}>{props.editUldFields?.buildup_awb?.reduce((acc, curr) => parseInt(acc) + parseInt(curr.pcs), 0) || 0} PCS</Typography>
                </Grid>
                <Grid item xs={4} sm={3} md={3} lg={2}>
                  <Typography variant='body2' fontWeight={700} color={'primary'}>{props.editUldFields?.buildup_awb?.reduce((acc, curr) => parseFloat(acc) + parseFloat(curr.wgt), 0) || 0} K</Typography>
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={5} xl={12} sx={{ minHeight: '44%' }}>
            <StyledCard className={styles.h100}>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <StyledCardTitle gutterBottom>Finalize ULD</StyledCardTitle>
                {/* <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                  {props.error && (
                    <Typography color='error' variant='body2'>
                      {props.error}
                    </Typography>
                  )}
                  <Chip
                    icon={<Circle className={ buildupStyles.circleIcon} />}
                    label={
                      <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8 
                      }}>
                        {`Port ${props.isConnecting ? 'Connecting...' : props.isConnected ? 'Connected' : 'Disconnected'}`}
                        <Divider orientation='vertical' flexItem />
                      </span>
                    }
                    color={props.isConnecting ? 'default' : props.isConnected ? 'secondary' : 'error'}
                    variant='filled'
                    size='small'
                    onDelete={props.openPort}
                    deleteIcon={<Refresh />}
                    className={buildupStyles.portChip}
                  />
                </Stack> */}
                <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                  {props.error && (
                    <Typography color='error' variant='body2'>
                      {props.error}
                    </Typography>
                  )}
                  <IconButton
                    size='small'
                    onClick={(e) => props.handleOpen('port_monitor', e.currentTarget)}
                    title='Port Monitor'
                  >
                    <code className={buildupStyles.infoIcon}> i </code>
                  </IconButton>
                  <Chip
                    icon={<Circle className={buildupStyles.circleIcon} />}
                    label={
                      <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8
                      }}>
                        {`Port ${props.isConnecting ? 'Connecting...' : props.connected ? 'Connected' : 'Disconnected'}`}
                        <Divider orientation='vertical' flexItem />
                      </span>
                    }
                    color={props.isConnecting ? 'default' : props.connected ? 'secondary' : 'error'}
                    variant='filled'
                    size='small'
                    onDelete={() => props.openPort(true)}
                    deleteIcon={<Refresh />}
                    className={buildupStyles.portChip}
                  />
                </Stack>
              </Stack>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={6} md={2} lg={6} xl={2.4} marginBottom={'auto'}>
                  <Stack direction='row' justifyContent={'space-between'} alignItems={'flex-end'} spacing={2}>
                    <CustomInputLabel htmlFor='uld_dest' aria-label='Enter Uld Dest' className={buildupStyles.lineHeight}>ULD Dest</CustomInputLabel>
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': '', }}
                          name='tu'
                          disableRipple
                          className={`${styles.py0} ${styles.pl0}`}
                          onChange={props.onChangeEditUld}
                          checked={props.editUldFields?.tu || false}
                          value={props.editUldFields?.tu || false}
                        />
                      }
                      label='TU'
                      className={`${styles.mr0} ${styles.pb1}`}
                      classes={{ label: buildupStyles.lineHeight }}
                      disabled={!props.editUldFields?.id || props.editUldFields?.closed}

                    />
                  </Stack>
                  <CustomTextField
                    {...InputProps}
                    id='uld_dest'
                    fullWidth
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    name='dest'
                    value={props.editUldFields?.dest || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                    error={props.editUldErrors?.dest}
                    helperText={props.editUldErrors?.dest}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={6} xl={2.4} marginBottom={'auto'}>
                  <CustomInputLabel htmlFor='contour' aria-label='Enter Contour'>Contour / Height</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='contour'
                    fullWidth
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    name='contour'
                    value={props.editUldFields?.contour || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                    error={props.editUldErrors?.contour}
                    helperText={props.editUldErrors?.contour}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={12} xl={7.2} marginBottom={'auto'}>
                  <Stack direction='row' justifyContent={'space-between'} alignItems={'flex-end'} spacing={2}>
                    <CustomInputLabel htmlFor='specials' aria-label='Enter specials' className={buildupStyles.lineHeight}>Specials/Remarks</CustomInputLabel>
                    <CustomCheckbox
                      control={
                        <Checkbox inputProps={{ 'aria-label': '', }}
                          name='dangerous'
                          disableRipple
                          className={`${styles.py0} ${styles.pl0}`}
                          onChange={props.onChangeEditUld}
                          checked={props.editUldFields?.dangerous || false}
                          value={props.editUldFields?.dangerous || false}
                        />
                      }
                      label='Contains Dangerous Goods'
                      className={`${styles.mr0} ${styles.pb1}`}
                      classes={{ label: buildupStyles.lineHeight }}
                      disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    />
                  </Stack>
                  <CustomTextField
                    {...InputProps}
                    id='remark'
                    fullWidth
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    name='remark'
                    value={props.editUldFields?.remark || ''}
                    inputProps={{ maxLength: CHARACTER_LIMIT }}
                    helperText={props.editUldFields?.remark?.length > 0 && `${(CHARACTER_LIMIT - props.editUldFields?.remark?.length)} characters remaining`}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                    sx={{ '& .MuiFormHelperText-root': { textAlign: 'right' } }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={6} xl={2.4} marginBottom={'auto'} alignSelf={'stretch'}>
                  <CustomInputLabel htmlFor='transfer_at' aria-label='Enter transfer at'>Transfer at</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='transfer_at'
                    fullWidth
                    name='transfer_at'
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed || !props.editUldFields?.tu}
                    value={props.editUldFields?.transfer_at || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                    error={props.editUldErrors?.transfer_at}
                    helperText={props.editUldErrors?.transfer_at}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2} lg={6} xl={2.4} marginBottom={'auto'} alignSelf={'stretch'}>
                  <CustomInputLabel htmlFor='onward_flight' aria-label='Enter Onward Flight'>Onward Flight</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='onward_flight'
                    fullWidth
                    name='onward_flight'
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed || !props.editUldFields?.tu}
                    value={props.editUldFields?.onward_flight || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                    error={props.editUldErrors?.onward_flight}
                    helperText={props.editUldErrors?.onward_flight}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={6} xl={2.4} marginBottom={'auto'} alignSelf={'stretch'}>
                  <CustomInputLabel htmlFor='wgt' aria-label='Enter scale wgt gross'>Scale Wgt Gross</CustomInputLabel>
                  <Stack direction='row' spacing={0} alignItems={'center'} justifyContent={'space-between'}>
                    <CustomTextField
                      {...InputProps}
                      id='scale_wgt'
                      fullWidth
                      // disabled={!props.editUldFields?.id || props.editUldFields?.closed || props.editUldFields?.lock}
                      disabled
                      name='scale_wgt'
                      value={props.editUldFields?.scale_wgt || ''}
                      onChange={props.onChangeEditUld}
                      onBlur={props.onEditUld}
                      error={props.editUldErrors?.scale_wgt}
                      helperText={props.editUldErrors?.scale_wgt}
                    />
                    <IconButton className={`${buildupStyles.scanIcon} ${styles.dFlex} `}
                      onClick={() => props.onChangeEditUld({
                        target: {
                          name: 'lock',
                          value: props.editUldFields?.lock ? false : true
                        }
                      })}
                      size='small'
                      color='primary'
                      disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    >
                      {props.editUldFields?.lock ? <img src={Lock} alt={'open uld'} width={12} className={(!props.editUldFields?.id || props.editUldFields?.closed) ? styles.greySvg : ''} /> :
                        <img src={Unlock} alt={'close uld'} width={12} className={(!props.editUldFields?.id || props.editUldFields?.closed) ? styles.greySvg : ''} />}
                    </IconButton>
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={2} lg={6} xl={2.4} marginBottom={'auto'} alignSelf={'stretch'}>
                  <CustomInputLabel htmlFor='dolly_tare' aria-label='Enter Dolly tare'>Dolly Tare</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='dolly_tare'
                    fullWidth
                    disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    name='dolly_tare'
                    value={props.editUldFields?.dolly_tare || ''}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                    error={props.editUldErrors?.dolly_tare}
                    helperText={props.editUldErrors?.dolly_tare}
                  />
                </Grid> */}
                <Grid item xs={12} sm={6} md={2} lg={6} xl={2.4} marginBottom={'auto'} alignSelf={'stretch'}>
                  <CustomInputLabel htmlFor='dolly_tare' aria-label='Enter Dolly tare'>Dolly Tare</CustomInputLabel>
                  <FormControl variant='standard' fullWidth error={props.editUldErrors?.dolly_tare} >
                    <CustomAutocomplete
                      disablePortal
                      id='dolly_tare'
                      options={props.dollys}
                      getOptionLabel={(option) => option.prefix + option.number}
                      onChange={(e, value) => props.onChangeEditUld({
                        target: {
                          name: 'dolly_prefix',
                          value
                        }
                      })}
                      name='dolly_prefix'
                      filterOptions={(options, params) => {
                        console.log(options, params);
                        const filtered = options.filter(option => {
                          if (params.inputValue == option.number || !params.inputValue) {
                            return option;
                          }
                        });
                        return filtered;
                      }
                      }
                      value={props.editUldFields?.dolly_prefix ? props.dollys.find(dolly => dolly.prefix + '' + dolly.number === props.editUldFields?.dolly_prefix) : null}
                      renderInput={(params) =>
                        <CustomTextField
                          {...InputProps}
                          {...params}
                          placeholder='Dolly Tare'
                        />}
                      fullWidth
                      slotProps={{ paper: { className: styles.selectPaperIp }, }}
                      disabled={!props.editUldFields?.id || props.editUldFields?.closed}
                    />
                    <FormHelperText>{props.editUldErrors?.dolly_tare}</FormHelperText>
                    <FormHelperText>{props.editUldFields?.dolly_prefix ? `Weight: ${props.dollys.find(dolly => dolly.prefix + '' + dolly.number === props.editUldFields?.dolly_prefix)?.wgt} K` : null}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2} lg={6} xl={2.4} marginBottom={'auto'}>
                  <CustomInputLabel htmlFor='uld_wgt' aria-label='Enter uld wgt'>ULD Wgt</CustomInputLabel>
                  <CustomTextField
                    {...InputProps}
                    id='wgt'
                    fullWidth
                    disabled={!props.editUldFields?.id || !props.editUldFields?.add_wgt || props.editUldFields?.closed || !canEditWgt}
                    name='wgt'
                    value={props.editUldFields?.wgt || (props.editUldFields?.scale_wgt || 0) - (props.editUldFields?.dolly_tare || 0)}
                    onChange={props.onChangeEditUld}
                    onBlur={props.onEditUld}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: <InputAdornment position='end'>
                        <div className={`${styles.actionIcons} ${styles.mr2}`}
                          onClick={() => props.onChangeEditUld({
                            target: {
                              name: 'add_wgt',
                              value: !props.editUldFields?.add_wgt
                            }
                          })}
                        ><EditIcon /></div>
                      </InputAdornment>,
                    }}
                    error={props.editUldErrors?.wgt}
                    helperText={props.editUldErrors?.wgt}
                  />
                  {
                    props.editUldFields?.edited_by &&
                    <FormHelperText className={buildupStyles.helperText}>Edited By: {props.editUldFields?.edited_by ? props.editUldFields?.edited_by?.first_name + ' ' + props.editUldFields?.edited_by?.last_name : ''}</FormHelperText>
                  }
                </Grid>

                <Grid item xs={12} sm={12} md marginTop={3}>
                  <Stack direction='row' spacing={3} justifyContent='flex-end'>
                    <TableBadge color='error' badgeContent={props.editUldFields?.uld_loadingaccessories?.filter((a) => a.value).length}>
                      <CustomButton startIcon={<FormatListBulleted fontSize='1.7rem' />}
                        disableRipple
                        title='Loading Accessories Used'
                        classes={{ startIcon: styles.mauto }}
                        onClick={(e) => props.handleOpen('loading_accesories', e.currentTarget)}
                        disabled={!props.editUldFields?.id}
                        variant='contained'
                        color='primary'
                        className={buildupStyles.tagBtn} />
                    </TableBadge>
                    {!props.editUldFields?.closed &&
                      <CustomButton startIcon={
                        <img src={Unlock} alt={'close uld'} width={15} className={styles.whiteSvg} />}
                      disableRipple
                      title='Close ULD'
                      classes={{
                        startIcon: styles.mauto,
                        disabled: styles.disabledIconImg
                      }}
                      onClick={() => props.onChangeEditUld({
                        target: {
                          name: 'closed',
                          value: true
                        }
                      })}
                      disabled={!props.editUldFields?.id}
                      variant='contained'
                      color='primary'
                      className={buildupStyles.tagBtn} />
                    }
                    {props.editUldFields?.closed &&
                      <CustomButton startIcon={
                        <img src={Lock} alt={'open uld'} width={15} className={styles.whiteSvg} />}
                      disableRipple
                      title='Open ULD'
                      classes={{
                        startIcon: styles.mauto,
                        disabled: styles.disabledIconImg
                      }}
                      onClick={() => props.onChangeEditUld({
                        target: {
                          name: 'closed',
                          value: false
                        }
                      })}
                      disabled={!props.editUldFields?.id}
                      variant='contained'
                      color='primary'
                      className={buildupStyles.tagBtn} />
                    }
                    <CustomButton title='Print ULD Tag' disabled={!props.editUldFields?.id} variant='contained' size='small' color='primary' onClick={props.onTag} className={buildupStyles.tagBtn}>
                      TAG
                    </CustomButton>
                    <CustomButton startIcon={<Balance />}
                      disableRipple
                      title='Print'
                      classes={{
                        startIcon: `${styles.mxauto} ${buildupStyles.balanceIcon}`,
                        disabled: styles.disabledIconImg
                      }}
                      variant='contained'
                      color='primary'
                      onClick={() => props.writeToSerialPort('print')}
                      className={`${buildupStyles.tagBtn} ${buildupStyles.printBtn}`} >
                      <span className={buildupStyles.printBtnText}>Print</span>
                    </CustomButton>
                  </Stack>
                </Grid>
              </Grid>
            </StyledCard>
          </Grid>
        </Grid>
      </Grid>

      {props.modal === 'awb_details' &&
        <AwbDetailModal
          modal={props.modal}
          onClose={props.handleClose}
          searchAwb={props.searchAwb}
          awbFields={props.awbFields}
          onChangeAwb={props.onChangeAwb}
          onLoadAwb={props.onLoadAwb}
          onChnageSearchAwb={props.onChnageSearchAwb}
          onSaveSearchAwb={props.onSaveSearchAwb}
          handleDelete={props.handleDelete}
          editLoadAwb={props.editLoadAwb}
          onLoadEditAwb={props.onLoadEditAwb}
          onChangeLoadAwb={props.onChangeLoadAwb}
          onSaveLoadAwb={props.onSaveLoadAwb}
          uldNo={props.editUldFields?.uld_no}
        />
      }

      {
        props.modal === 'history' &&
        <History
          popover={props.modal}
          anchorEl={props.anchorEl}
          onClose={props.handleClose}
          data={props.history}
          accessories={props.accessories}
        />
      }

      {props.modal === 'move_uld_to_diff_flight' &&
        <MoveUldToDiffFlight
          popover={props.modal}
          anchorEl={props.anchorEl}
          onClose={props.handleClose}
          uld={props.editUldFields}
          flight={props.flight}
          flights={props.flights}
          airlines={props.airlines}
          flightFields={props.flightFields}
          onChangeFlight={props.onChangeFlight}
          onSaveFlight={props.onSaveFlight}
        />
      }

      {<Status
        statusModal={props.wgtError}
        onClose={props.hideWgtError}
        statusMessage= {{
          type: 'warning',
          size: 'sm',
          title: 'Warning',
          mainMessage:'Update Scale',
          firstMessage:'Please change the scale to kg',
          secondMessage:''
        }}
      />}

      <Popover
        open={props.modal === 'loading_accesories'}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{ paper: { className: buildupStyles.loadAccessoriesPopover, } }}
      >
        <Typography variant='body1' color='primary' px={3} py={2} fontWeight={600}>Loading Accessories Used</Typography>
        <CloseIconBtn onClick={props.handleClose} />
        <Divider />

        <Grid container spacing={2} px={3} py={2}>
          {props.accessories.map((a, index, self) => (
            <Grid item key={index} xs={6} className={(self.length === 1) || (index % 2) ? '' : buildupStyles.accRowOdd}>
              <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                <Typography variant='caption' maxWidth={'150px'}>{a.description}</Typography>
                <TextField
                  variant='outlined'
                  id='spreader_boards'
                  defaultValue={props.editUldFields?.uld_loadingaccessories?.find(b => b.loadingaccessoriesId === a.id)?.value || ''}
                  name={a.id}
                  className={buildupStyles.tableTextField}
                  onBlur={props.onChangeAccessories}
                />
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Popover>

      <Popover
        open={props.modal === 'port_monitor'}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{ paper: { className: buildupStyles.portMonitorPopover, } }}
      >
        <Typography variant='body1' color='primary' px={3} py={2} fontWeight={600}>Port Monitor</Typography>
        <CloseIconBtn onClick={props.handleClose} />
        <Divider />
        <Grid container spacing={2} px={3} py={2}>
          <Grid item xs={4}>
            <Button onClick={() => props.writeToSerialPort('clear')} variant='contained' color='primary' size='small' className={buildupStyles.portMonitorBtn}>Clear</Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={() => props.writeToSerialPort('gross')} variant='contained' color='primary' size='small' className={buildupStyles.portMonitorBtn}>XG</Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={() => props.writeToSerialPort('tare')} variant='contained' color='primary' size='small' className={buildupStyles.portMonitorBtn}>XT</Button>
          </Grid>
        </Grid>
        <Typography variant='body2' px={3} py={2}>{props.render_txt ? props.render_txt : 'No data to display'}</Typography>
      </Popover>
    </>
  );
};

export default Buildup;

