import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import notificationStyle from './notification.module.css';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

class PDFSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: 1,
      currentPage: 1,
      isDragging: false,
      startX: 0,
      startY: 0,
      scrollLeft: 0,
      scrollTop: 0,
    };
    this.pdfContainerRefs = {}; 
  }

  // Dynamically create refs for each page
  getPdfContainerRef = (index) => {
    if (!this.pdfContainerRefs[index]) {
      this.pdfContainerRefs[index] = React.createRef();
    }
    return this.pdfContainerRefs[index];
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage });
  };

  handleMouseDown = (e, index) => {
    const container = this.getPdfContainerRef(index).current;
    if (!container) return;

    this.setState({
      isDragging: true,
      startX: e.pageX - container.offsetLeft,
      startY: e.pageY - container.offsetTop,
      scrollLeft: container.scrollLeft,
      scrollTop: container.scrollTop,
    });
    container.style.cursor = 'grabbing';
  };

  handleMouseMove = (e, index) => {
    if (!this.state.isDragging) return;
    e.preventDefault();

    const container = this.getPdfContainerRef(index).current;
    if (!container) return;

    const scale = this.props.scale || 1; // Adjust drag speed based on scale
    const x = e.pageX - container.offsetLeft;
    const y = e.pageY - container.offsetTop;

    const walkX = ((x - this.state.startX) * 1.5) / scale;
    const walkY = ((y - this.state.startY) * 1.5) / scale;

    container.scrollLeft = this.state.scrollLeft - walkX;
    container.scrollTop = this.state.scrollTop - walkY;
  };

  handleMouseUp = (index) => {
    const container = this.getPdfContainerRef(index).current;
    if (container) container.style.cursor = 'grab'; // Reset cursor
    this.setState({ isDragging: false });
  };

  render() {
    const { numPages, currentPage } = this.state;

    const NextArrow = (props) => {
      const { onClick } = props;
      return (
        <IconButton
          aria-label='Next'
          onClick={onClick}
          className={notificationStyle.tsrightArrow}
          size='large'
        >
          <ArrowRight color={currentPage === numPages ? 'disabled' : 'primary'} fontSize='large' />
        </IconButton>
      );
    };

    const PrevArrow = (props) => {
      const { onClick } = props;
      return (
        <IconButton
          aria-label='Prev'
          onClick={onClick}
          className={notificationStyle.tsleftArrow}
          size='large'
        >
          <ArrowLeft color={currentPage === 1 ? 'disabled' : 'primary'} fontSize='large' />
        </IconButton>
      );
    };

    const settings = {
      arrows: true,
      infinite: false,
      dots: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      swipe: false,
      draggable: false,
      beforeChange: (oldIndex, newIndex) => {
        this.handlePageChange(newIndex + 1);
      },
    };

    return (
      <Box>
        <div className={notificationStyle.pdfParentContainer}>
          <Slider {...settings}>
            {Array.from({ length: numPages }, (_, pageIndex) => (
              <div
                key={pageIndex}
                className={notificationStyle.pdfContainer}
                ref={this.getPdfContainerRef(pageIndex)}
                onMouseDown={(e) => this.handleMouseDown(e, pageIndex)}
                onMouseMove={(e) => this.handleMouseMove(e, pageIndex)}
                onMouseUp={() => this.handleMouseUp(pageIndex)}
                onMouseLeave={() => this.handleMouseUp(pageIndex)}
              >
                <Document file={this.props.file} onLoadSuccess={this.onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pageIndex + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    className={notificationStyle.attachmentPage}
                    width={500}
                    scale={this.props.scale || 1.0}
                  />
                </Document>
              </div>
            ))}
          </Slider>
        </div>
        <div className={notificationStyle.pagination}>
          <Typography variant='caption' component={'p'} textAlign={'center'} color={grey[600]}>
            {currentPage} / {numPages}
          </Typography>
        </div>
      </Box>
    );
  }
}

export default PDFSlider;
