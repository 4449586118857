import React from 'react';
import evemts from 'utils/evemts';
import http from 'utils/http';
import { setDeleteAction } from 'store/actions/deleteAction';
import { connect } from 'react-redux';
import Consignee from './Consignee';
import ConsigneeModal from './ConsigneeModal';

class ConsigneeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // state goes here
      consignees: [],
      consigneeFields: {},
      consigneeFieldsErrors: {},
    };
    this.eventSource = null;
  }

  componentDidMount() {
    this.fetchConsignee();
    this.eventSource = evemts('admin', 'import');
    this.eventSource.onmessage = (event) => {
      const msg = JSON.parse(event.data || '{}');

      if (msg.data && msg.action && msg.action == 'add_consignee') {
        this.setState({ 
          consignees: [...this.state.consignees || [], msg.data] ,
          consigneeFields: msg.data ,
        });
      }
      if (msg.data && msg.action && msg.action == 'update_consignee') {
        const consignees = [...this.state.consignees || []];
        const index = consignees.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          consignees[index] = { ...msg.data };
          this.setState({
            consignees,
            consigneeFields: msg.data,
          });
        }
        if (this.state.consigneeFields.id == msg.data.id) {
          if(msg.data.phone) {
            let phone = msg.data.phone.match(/^(\+?(\d{1,4}))(\d{10,})$/);
            msg.data.phone_code = phone[1];
            msg.data.phone = phone[3];
            this.setState({ consigneeFields: { ...msg.data } });
          }
        }
      }
      if (msg.data && msg.action && msg.action == 'delete_consignee') {
        const consignees = [...this.state.consignees || []];
        const index = consignees.findIndex(c => msg.data && c.id == msg.data.id);
        if (index > -1) {
          consignees.splice(index, 1);
          this.setState({
            consignees,
            consigneeFields: consignees[index - 1] || consignees[0],
          });
        }
      }
    };
  }
  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  fetchConsignee = () => {
    http.get('consignee').then((res) => {
      if (res.data) {
        this.setState({ consignees: res.data?.data || [] , }, () => {
          this.handleOpen('', 'edit', res.data?.data[0]);
        });
      }
    });
  };


  handleOpen = (modal, action, row = { value: [{}] }) => {
    // extra phone_code and phone
    if (row?.phone && row?.phone?.includes('+')) {
      const regex = /^(\+?(\d{1,4}))(\d{10,})$/;
      const phone = row?.phone && row?.phone?.match(regex);
      row.phone_code = phone?.[1] || '+1';
      row.phone = phone?.[3];
    }
    this.setState({
      modal: action == 'add' || action == 'existing' ? modal : false,
      action,
      consigneeFields: row || {},
      consigneeFieldsErrors: {},
    });
  };

  onClose = () => {
    this.setState({
      modal: false,
      action: false,
      consigneeFields: {},
      consigneeFieldsErrors: {},
    });
  };


  // onRowMailClick = (row) => {
  //   this.setState({
  //     mailFields: row,
  //     mailFieldsErrors: {}
  //   });
  // };

  onChangeConsignee = (e) => {
    let value = e.target.value;
    if (e.target.type == 'checkbox') {
      value = e.target.checked;
    }
    this.setState({
      consigneeFields: {
        ...this.state.consigneeFields,
        [e.target.name]: value,
      },
    });
  };

  addConsignee = (e) => {
    e.preventDefault();
    http.post('consignee', {
      ...this.state.consigneeFields,
      phone: this.state.consigneeFields.phone_code + this.state.consigneeFields.phone,
      existing: []
    }).then(({ data }) => {
      if (data.status == 'success') {
        this.setState({
          modal: false,
          action: false,
        });
      } else {
        console.log(data);
        if (data.message?.includes('already exists')) {
          this.setState({
            consigneeFields: {
              ...this.state.consigneeFields,
              exist: true,
              existing: data.data
            }
          });
        }
      }
    }).catch((err) => {
      if (err?.response?.data?.message?.includes('already exists')) {
        this.setState({
          consigneeFields: {
            ...this.state.consigneeFields,
            exist: true,
            existing: err?.response?.data?.data
          }
        });
      }
    });
  };

  editConsignee = (e) => {
    e.preventDefault();
    http.put('consignee/' + this.state.consigneeFields.id, {
      ...this.state.consigneeFields,
      phone: this.state.consigneeFields.phone_code + this.state.consigneeFields.phone
    }).then(({ data }) => {
      if (data.status == 'success') {
        // success
      }
    });
  };

  updateExisting = () => {
    if (this.state.consigneeFields.existing_id) {
      let existing = this.state.consigneeFields.existing.find(c => c.id == this.state.consigneeFields.existing_id);
      this.handleOpen('consignee', 'existing', existing);
    }
  };

  onAddCMail = () => {
    this.setState({
      consigneeFields: {
        ...this.state.consigneeFields,
        mails: [...this.state.consigneeFields.mails || [], {
          email: '',
          deleted: false,
          failed: false
        }]
      }
    });
  };

  onChangeCMail = (index, e) => {
    let value = e.target.value;
    if (e.target.type == 'checkbox') {
      value = e.target.checked;
    }
    let mails = [...this.state.consigneeFields.mails];
    mails[index][e.target.name] = value;
    this.setState({
      consigneeFields: {
        ...this.state.consigneeFields,
        mails,
      },
    });
  };

  onDeleteCMail = (index) => {
    let mails = [...this.state.consigneeFields.mails];
    mails[index].deleted = true;
    this.setState({
      consigneeFields: {
        ...this.state.consigneeFields,
        mails,
      },
    });
  };

  onDelteConsignee = (id, anchorEl) => {
    this.props.setDelete({
      modal: true,
      id,
      anchorEl,
      onConfirm: () => this.deleteConsignee(id),
    });
  };

  deleteConsignee = (id) => {
    http.delete('consignee/' + id).then(({ data }) => {
      if (data.status == 'success') {
        this.props.setDelete({
          modal: false,
          id: false,
          anchorEl: false,
          onConfirm: () => { },
        });
      }
    });
  };

  fetchConsigneeDetails = (row = {}) => {
    this.setState({ activeAirline: row });
    let id = row?.id;
    if (row?.id === -1 || !row?.id) {
      id = 'ALL';
    }
  
    // http.get('/airlineaccessoriess')
    http.get(`/loading_accessories?airlineId=${id}`)
      .then(response => {
        const { data } = response;
        if (data.status === 'success') {
          this.setState({ accessories: data.data });
  
        }
      })
      .catch(error => {
        this.setState({ accessories: [] });
        console.error('Error fetching data:', error);
      });
  };

  render() {
    return (
      <>
        <Consignee
          onChangeCMail={this.onChangeCMail}
          action={this.state.action}
          consigneeFields={this.state.consigneeFields}
          consigneeFieldsErrors={this.state.consigneeFieldsErrors}
          onChangeConsignee={this.onChangeConsignee}
          addConsignee={this.addConsignee}
          editConsignee={this.editConsignee}
          handleOpen={this.handleOpen}
          onDeleteCMail={this.onDeleteCMail}
          consignees={this.state.consignees}
          onDelteConsignee={this.onDelteConsignee}
          onAddCMail={this.onAddCMail}
          updateExisting={this.updateExisting}
        />
        <ConsigneeModal
          modal={this.state.modal}
          action={this.state.action}
          consigneeFields={this.state.consigneeFields}
          consigneeFieldsErrors={this.state.consigneeFieldsErrors}
          onChangeConsignee={this.onChangeConsignee}
          addConsignee={this.addConsignee}
          editConsignee={this.editConsignee}
          onClose={this.onClose}
          onAddCMail={this.onAddCMail}
          onChangeCMail={this.onChangeCMail}
          onDeleteCMail={this.onDeleteCMail}
          updateExisting={this.updateExisting}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return { setDelete: (data) => dispatch(setDeleteAction(data)) };
};

export default connect(null, mapDispatchToProps)(ConsigneeContainer);