import React from 'react';
import { Dialog } from '@mui/material';
import styles from '../../../../../../../custom/style.module.css';
import { CustomDialogContent, CustomDialogTitle, StyledCard } from '../../../../../../../custom/components';
import { CloseIconBtn } from 'custom/constants';
import AdditionalService from './AdditionalService';

const AdditionalServiceModal = (props) => {
  const { action } = props;
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal == 'additionalservices'} onClose={props.onClose} maxWidth={'lg'} fullWidth>
        <CustomDialogTitle id='form-dialog-title' className={styles.modalTitle}>
          {action == 'add' ? 'Add' : 'Edit'} Service Details
          <CloseIconBtn onClick={props.onClose} />
        </CustomDialogTitle>
        <CustomDialogContent>
          <StyledCard>
            <AdditionalService {...props} />
          </StyledCard>
        </CustomDialogContent>
      </Dialog>
    </div>
  );
};
export default AdditionalServiceModal;