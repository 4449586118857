import React from 'react';
import { Divider, Grid, MenuItem, Radio, Stack } from '@mui/material';
import styles from '../../../../../../../custom/style.module.css';
import importStyles from './consignee.module.css';
import { CustomButton, CustomInputLabel, CustomRadio, CustomSelect, CustomTextField, InputProps, SelectInputProps, StyledCard, StyledCardTitle } from '../../../../../../../custom/components';
import { AddIcon, countryCode, DeleteIcon, states_city } from 'custom/constants';

const ConsigneeDetails = (props) => {
  const { action, consigneeFields, consigneeFieldsErrors, onChangeConsignee, addConsignee, editConsignee, updateExisting } = props;
  return (
    <StyledCard className={styles.h100}>
      <form onSubmit={action == 'add' ? addConsignee : editConsignee} onReset={props.onClose} className={`${styles.w100} ${styles.h100}`}>
        <Grid container spacing={3} className={styles.h100}>
          <Grid item xs={12} md>
            <StyledCardTitle>Consignee / Notify Party Details</StyledCardTitle>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomInputLabel htmlFor='name' required>Consignee / Notify Party</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  autoFocus
                  id='name'
                  placeholder='Consignee / Notify Party'
                  type='text'
                  onChange={onChangeConsignee}
                  name='name'
                  value={consigneeFields.name || ''}
                  error={consigneeFieldsErrors.name}
                  helperText={consigneeFieldsErrors.name}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInputLabel htmlFor='address' required>Address 1</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='address'
                  placeholder='Address 1'
                  type='text'
                  onChange={onChangeConsignee}
                  name='address'
                  value={consigneeFields.address || ''}
                  error={consigneeFieldsErrors.address}
                  helperText={consigneeFieldsErrors.address}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInputLabel htmlFor='address2'>Address 2</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='address2'
                  placeholder='Address 2'
                  type='text'
                  onChange={onChangeConsignee}
                  name='address2'
                  value={consigneeFields.address2 || ''}
                  error={consigneeFieldsErrors.address2}
                  helperText={consigneeFieldsErrors.address2}
                />
                      
              </Grid>
              <Grid item xs={3} md={3}>
                <CustomInputLabel htmlFor='state' required>State</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  fullWidth
                  id='state'
                  name='state'
                  value={consigneeFields.state || ''}
                  onChange={onChangeConsignee}
                  error={consigneeFieldsErrors.state}
                  helperText={consigneeFieldsErrors.state}
                >
                  {Object.keys(states_city)?.sort(
                    (a, b) => a.localeCompare(b)
                  )?.map((state, index) =>
                    <MenuItem key={index} value={state}>{state}</MenuItem>
                  )}
                </CustomSelect>
              </Grid>
              <Grid item xs={5} md={5}>
                <CustomInputLabel htmlFor='city' required>City</CustomInputLabel>
                <CustomSelect
                  {...SelectInputProps}
                  fullWidth
                  id='city'
                  name='city'
                  value={consigneeFields.city || ''}
                  onChange={onChangeConsignee}
                  error={consigneeFieldsErrors.city}
                  helperText={consigneeFieldsErrors.city}
                >
                  {consigneeFields.state && states_city[consigneeFields.state] && states_city[consigneeFields.state].cities?.sort(
                    (a, b) => a.localeCompare(b)
                  ).map((city, index) =>
                    <MenuItem key={index} value={city}>{city}</MenuItem>
                  )}
                </CustomSelect>
              </Grid>
              <Grid item xs={4} md={4}>
                <CustomInputLabel htmlFor='zip' required>Zip</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='zip'
                  placeholder='Zip'
                  type='text'
                  onChange={onChangeConsignee}
                  name='zip'
                  value={consigneeFields.zip}
                  error={consigneeFieldsErrors.zip}
                  helperText={consigneeFieldsErrors.zip}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <CustomInputLabel htmlFor='email' required>E-mail</CustomInputLabel>
                <CustomTextField
                  {...InputProps}
                  fullWidth
                  id='email'
                  placeholder='E-mail'
                  type='email'
                  onChange={onChangeConsignee}
                  name='email'
                  value={consigneeFields.email || ''}
                  error={consigneeFieldsErrors.email}
                  helperText={consigneeFieldsErrors.email}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInputLabel htmlFor='phone'>Phone</CustomInputLabel>
                <Stack direction={'row'} spacing={2}>
                  <CustomSelect
                    {...SelectInputProps}
                    id='phone_code'
                    name='phone_code'
                    value={consigneeFields.phone_code  || ''}
                    renderValue={(selected) => {
                      return selected;
                    }}
                    onChange={onChangeConsignee}
                    error={consigneeFieldsErrors.phone_code}
                    helperText={consigneeFieldsErrors.phone_code}
                  >
                    {countryCode?.map((code, index) =>
                      <MenuItem key={index} value={code.dial_code}>{code.name} ({code.dial_code})</MenuItem>
                    )}
                  </CustomSelect>
                  <CustomTextField
                    {...InputProps}
                    fullWidth
                    id='phone'
                    placeholder='Phone'
                    type='text'
                    onChange={onChangeConsignee}
                    name='phone'
                    value={consigneeFields.phone || ''}
                    error={consigneeFieldsErrors.phone}
                    helperText={consigneeFieldsErrors.phone}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Divider orientation='vertical' variant='middle' flexItem className={importStyles.sectionDivider} />
          </Grid>
          <Grid item xs={12} md>
            <div className={`${styles.mb3} ${styles.fSpaceBtw}`} >
              <StyledCardTitle>Notification Email List</StyledCardTitle>
              <AddIcon className={`${styles.addIcon} ${styles.mbauto}`} title='Add Email' onClick={props.onAddCMail} />
            </div>
            <Grid container spacing={2}>
              {consigneeFields?.mails && consigneeFields?.mails.map((mail, index) => !mail?.deleted && (
                <Grid item container spacing={2} key={index} alignItems={'center'}>
                  <Grid item xs={11} md={11}>
                    <CustomTextField
                      {...InputProps}
                      fullWidth
                      id='mail'
                      placeholder='E-mail'
                      type='email'
                      onChange={(e) => props.onChangeCMail(index, e)}
                      name='email'
                      value={mail?.email}
                      error={consigneeFieldsErrors?.mails?.[index]}
                      helperText={consigneeFieldsErrors?.mails?.[index]}
                    />
                  </Grid>
                  <Grid item xs={1} md={1}>
                    <DeleteIcon className={importStyles.delIcon} onClick={() => props.onDeleteCMail(index)} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {consigneeFields?.exist && (
            <Grid item xs={12} md={12}>
              <StyledCard>
                <StyledCardTitle>Existing Consignee / Notify Party</StyledCardTitle>
                <Grid container spacing={2}>
                  {consigneeFields?.existing?.map((consignee, index) => (
                    <Grid item xs={12} md={12} key={index}>
                      <CustomRadio
                        id={consignee.id}
                        control={<Radio />}
                        name='existing_id'
                        value={consignee.id}
                        checked={consigneeFields.existing_id && consigneeFields.existing_id == consignee.id}
                        onChange={onChangeConsignee}
                        label={consignee.name}
                      />
                    </Grid>
                  ))}
                </Grid>
              </StyledCard>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Stack direction={'row'} spacing={2} alignItems={'flex-end'} justifyContent={'flex-end'} height={'100%'}>
              {!consigneeFields?.exist && <CustomButton variant='contained' type='submit' 
                className={styles.mlauto}>{action == 'add' ? 'Submit' : 'Update'}</CustomButton>}
              {consigneeFields?.exist && <CustomButton variant='contained' onClick={updateExisting} > Update exisitng</CustomButton>}
              {consigneeFields?.exist && <CustomButton variant='contained' type='submit'> Create Anyway</CustomButton>}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </StyledCard>
          
  );
};
export default ConsigneeDetails;