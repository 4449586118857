import React from 'react';
import { Button, Card, CardActions, CardContent, Dialog, Divider, Grid, Stack, Typography } from '@mui/material';
import styles from 'custom/style.module.css';
import flightStyles from './flightsfolder.module.css';
import { CustomButton, CustomDialogTitle, CustomDialogAction, CustomDialogContent } from 'custom/components';
import { CloseIconBtn, DateFormat } from 'custom/constants';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import moment from 'moment';

const FlightModal = (props) => {
  return (
    <div>
      <Dialog aria-labelledby='form-dialog-title' open={props.modal} onClose={props.closeModal} maxWidth='md' fullWidth>
        <CustomDialogTitle id='form-dialog-title'>
          Choose Flight
          <CloseIconBtn onClick={props.closeModal} />
        </CustomDialogTitle>
        <CustomDialogContent className={flightStyles.flightModal}>

          {props.modalData && props.modalData.length === 0 && 
          <div className={`${styles.fMiddle} ${styles.fColumn} ${flightStyles.noFlight}`}>
            <Typography component={'h5'} variant={'h5'} color='primary'>Flight not found</Typography>
            <Typography component={'span'} variant={'caption'} color='text.secondary'>Please try another flight</Typography>
          </div>
          }

          {props.modalData && props.modalData.length > 0 && 
            <Grid container spacing={3}> 
              {props.modalData && props.modalData.map((item, index) => {
                return (
                  <Grid item sm={3} md={2} key={index}>
                    <Card className={flightStyles.flightInfo}
                      sx={{
                        '&:hover': {
                          transform: 'scale(1.02)',
                          transition: 'transform 0.2s ease-in-out', 
                        },
                      }}>
                      <CardContent sx={props.selected_flight.id === item.id ? {
                        '&': {
                          backgroundColor: 'var(--primaryblue)',
                          color: 'white',
                        },
                      }: ''}>
                        <Typography variant='h6' component='div'>
                          {item?.airline?.code}{item.flight_no}
                        </Typography>
                        <Typography variant='body2' sx={{
                          opacity: 0.6,
                          mb: 1.5 
                        }}>{moment(item.flight_date, 'YYYY MM DD').format(DateFormat).toUpperCase()}</Typography>
                        <Stack direction='row' spacing={1} alignItems={'center'} flexWrap={'wrap'}>
                          <Typography variant='body2' fontWeight={500}>{item.org}</Typography>
                          <AirplanemodeActiveIcon className={flightStyles.flightIcon} />
                          <Typography variant='body2' fontWeight={500}>{item.dest}</Typography>
                        </Stack>
                      </CardContent>
                      <Divider />
                      <CardActions sx={{ justifyContent: 'center' }}>
                        <Button fullWidth onClick={() => props.handleFlight('flight', item)} size='small'>Select</Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })
              }
            </Grid>
          }
        </CustomDialogContent>
        <Divider />

        {props.modalData && props.modalData.length > 0 &&
        <CustomDialogAction className={`${styles.fSpaceBtw} ${flightStyles.actionButtonContainer}`}>
          <CustomButton variant='contained' className={flightStyles.modalButton} onClick={props.closeModal}>Close</CustomButton>
          <CustomButton variant='contained' className={flightStyles.modalButton} onClick={()=>props.handleFlight('confirm', {})} >Confirm Flight</CustomButton>
        </CustomDialogAction>}
      </Dialog>
    </div>
  );
};
export default FlightModal;