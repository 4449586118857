import React from 'react';
import { Stack, Grid, Box, } from '@mui/material';
import { StyledDataGrid, StyledCard, StyledCardTitle, DataGridProps } from '../../../../../../../custom/components';
import dollyTareStyle from './dollytare.module.css';
import styles from '../../../../../../../custom/style.module.css';
import {  AddIcon, DateFormat, DeleteIcon, EditIcon,  } from '../../../../../../../custom/constants';
import { dateTolocale } from 'utils/converter';

const DollyTare = (props) => {

  const dollyTareColumn = [
    {
      field: 'prefix',
      headerName: 'Prefix',
      flex: 1,
    },
    {
      field: 'number',
      headerName: 'Number',
      flex: 1,
    },
    {
      field: 'wgt',
      headerName: 'Weight',
      flex: 1,
    },
    {
      field: 'mail',
      headerName: 'Email',
      flex: 1.5,
    },
    {
      field: 'expiry',
      headerName: 'Expiry',
      flex: 1,
      renderCell: ({ row }) => {
        return dateTolocale(row.expiry, DateFormat);
      }
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' spacing={2} justifyContent='center' className={styles.actionIcons}>
            <EditIcon onClick={() => props.handleOpen('dolly_tare', 'edit', row)} />
            <DeleteIcon onClick={(e) => props.handleDelete(row.id, e.target)} />
          </Stack>
        );
      }
    },
  ];


  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <StyledCard>
            <Stack className={`${styles.mb3}`} direction='row' justifyContent='space-between' spacing={1}>
              <StyledCardTitle gutterBottom >Dolly Tare</StyledCardTitle>
              <AddIcon className={styles.addIcon} title='Add Accessories' onClick={() => props.handleOpen('dolly_tare', 'add')} />
            </Stack>
            <div className={styles.tableWrapper}>
              <Box className={`${styles.tableOuter} ${dollyTareStyle.dollyTareTableOuter}`}>
                <StyledDataGrid
                  {...DataGridProps}
                  rows={props.dollys}
                  columns={dollyTareColumn}
                  param={props.dollys?.length}
                />
              </Box>
            </div>
          </StyledCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default DollyTare;
